import * as THREE from 'three';
import _3dBase from './Base.js'
import {CORE} from '../_spec.js'
import { MeshBasicMaterial, Vector3 } from 'three';
import CollisionHelper from '../helpers/CollisionHelper.js';
import MemHelper from '../helpers/memHelper.js';
import layerHelper from '../helpers/layerHelper.js';

export default class ConstraintMarker extends _3dBase{
    constructor(length, color){		
		super();
		this.group.name = 'Constraint Marker';
        let width = 10;
        let depth = 1;
        var geo = new THREE.PlaneBufferGeometry( length, width, 1, 1);
		geo.name = 'colored mesh'
        let nColor = Number(color)
        const material = new THREE.MeshBasicMaterial( { color: nColor, side: THREE.DoubleSide} );

        var mesh = new THREE.Mesh( geo, material);
        
        mesh.name = 'constraint marker'
        mesh.position.x-= width/2;
		mesh.position.y+= length/2;
        mesh.rotation.x = -Math.PI/2;

		mesh.castShadow =false;
		this.group.add(
			mesh
		);
		
		this.group.castShadow=false;
        
        layerHelper.setGroup(this.group, CORE.layers.constraint, true);
    }

    
}