import * as THREE from 'three';
import { CORE, rebuildTypes,impactTypes } from '../_spec.js'
import { Vector2, Vector3 } from 'three';
import { OBB } from 'three/examples/jsm/math/OBB.js'; // https://threejs.org/examples/?q=bounding#webgl_math_obb
import CollisionHelper from '../helpers/CollisionHelper.js';
import Grabject from './grabject.js'
import Door from './Door.js'
import OptionHelper from '../helpers/optionHelper.js'
import vHelper from '../helpers/vHelper.js'
import MemHelper from '../helpers/memHelper.js';
import Util from '../utility.js';
import TreeNode from '../helpers/TreeNode.js'
import UpdateHelper from '../helpers/UpdateHelper.js';
import _3dWindowBase from '../3d/WindowBase.js'
import _3dGirtHoriC from '../3d/GirtHoriC.js'
import layerHelper from '../helpers/layerHelper.js';
import _3dTrimOpeningFace from '../3d/TrimOpeningFace.js';
import _3dTrimOpeningJamb from '../3d/TrimOpeningJamb.js';
import materialHelper from '../helpers/materialHelper.js';
import _3dDoorBarn from '../3d/DoorBarn.js';
import DoorResizable from './DoorResizable.js';

export default class DoorSliding extends DoorResizable {
    constructor(design, config) {        
        super(design);
        if(this.design.dir) 
            delete this.design.dir;

        
    }
  
    migrate(design){
        if(!design)
            return;

        if(typeof design.slideOpen === 'undefined')
            design.slideOpen=false;        
    }

    applyDesign(design){
        this.design.collided= design.collided,
        this.design.pos = new Vector3( design.pos.x, design.pos.y, design.pos.z),
        this.design.type = design.type,
        //this.design.id = design.id,
        this.design.parent = {};
        this.design.parent.id = design.parent.id;
        this.design.dim= {
            width: design.dim.width,
            height: design.dim.height
        };
        this.design.slideOpen = design.slideOpen;
        this.design.slideDirection = design.slideDirection;
        this.design.pos.y = (this.design.dim.height)/2;
        this.design.selected= false;
    }
    
    defaultDesign(limits){

        let des = {
            collided : false,
            pos : new Vector3(0,(9*12)/2,0), 
            parent: {
                type: 'frontSide'
            },
            type: CORE.components.doorSliding,
            id: -1,
            dim:{
                width: 9*12,
                height: 9*12
            },
            slideOpen:false,
            slideDirection: 'double', // double, left, right
            selected:false
        }
        if(limits){
            if(des.dim.height > limits.dim.height.max)
            des.dim.height = limits.dim.height.max;
        }
        des.pos.y = (des.dim.height)/2; //foundation is 6" tall
        return des;
    }

    processImpactFromSelf(impact){
        super.processImpactFromSelf(impact);

        switch(impact.change.name)
        {
            case impactTypes.doorSlidingDetail:
                this.addRebuildNeeded(rebuildTypes.full);
                break;
        }
    }
    
    getOptionsSpec(){
        let options = super.getOptionsSpec();
        options.push(OptionHelper.checkbox("slideOpen", "Open", impactTypes.doorSlidingDetail, true));
        options.push(OptionHelper.radios(
            'slideDirection', //key 
            'Direction', //name
            impactTypes.doorSlidingDetail, //impactType
            [{  //selOptions,
                value: 'double',
                text:"Double"
            },
            {
                value: 'left',
                text:"Left"
            },
            {
                value: 'right',
                text:"Right"
            }],
            (v) => {//fnIn,
                return this.design.slideDirection;
            }, 
            (v) => { //fnOut
                this.design.slideDirection = v;
            },
            undefined) //fnChange)
        );
        return options;
    }
    
    // required by object interface
    getDescription(){

        let widthFt = (this.design.dim.width/12).toFixed(2);
        let heightFt = (this.design.dim.height/12).toFixed(2);
        //return `Sliding (${widthFt}' x ${heightFt}')`;
        return `Sliding (${this.getDimensionText()})`;
    } 

    build() {
        // material
        let doorTrimMaterial = this.structureConfig.trimMaterials.door;

        let jambTrimThickness = .5; 
        let jambTrimWidthZ = 8;
        let halfFrameDepth = jambTrimThickness/2;
        let faceTrimWidth = 2;
        let dimTrimMaterial = new THREE.Vector3(faceTrimWidth,2,1);
        
        let dim = new THREE.Vector3(this.design.dim.width, this.design.dim.height, 1);
        let halfDim = dim.clone().multiplyScalar(.5);
        
        this.sizeDoor = new THREE.Vector3(dim.x, dim.y, 1);

        this.group.position.copy(this.design.pos)

        
        let ohJambLiner = this.options && this.options['ohJambLiner']!==false;
        
        // left = -1, right = 1, double = 0
        let directionOffsetMultiplier = this.design.slideDirection == 'left' ? -1 : this.design.slideDirection == 'right' ? 1 : 0;
        
        let panelColor = this.structureConfig.wallColor;

        if (this.design.slideDirection == 'double') {
            let leftDoorX = -dim.x/4 - faceTrimWidth/2;
            let rightDoorX = dim.x/4 + faceTrimWidth/2;
            if (this.design.slideOpen) {
                leftDoorX = leftDoorX*3;
                rightDoorX = rightDoorX*3;
            }
            let doorLeft = new _3dDoorBarn(CORE.preferences.des_levelOfDetail.value, dim.x/2+faceTrimWidth, dim.y, panelColor, doorTrimMaterial);
            doorLeft.group.position.x += leftDoorX;
            doorLeft.group.position.z += dimTrimMaterial.z*1;
            this.group.add(doorLeft.group)

            let doorRight = new _3dDoorBarn(CORE.preferences.des_levelOfDetail.value, dim.x/2+faceTrimWidth, dim.y, panelColor, doorTrimMaterial);
            doorRight.group.position.x += rightDoorX;
            doorRight.group.position.z += dimTrimMaterial.z*1;
            this.group.add(doorRight.group)
        } else {
            let door = new _3dDoorBarn(CORE.preferences.des_levelOfDetail.value, dim.x+(2*faceTrimWidth), dim.y, panelColor, doorTrimMaterial);
            if (this.design.slideOpen)
                door.group.position.x += dim.x * directionOffsetMultiplier;
            door.group.position.z += dimTrimMaterial.z*1;
            this.group.add(door.group)
        }
        // opening trim
        let trim = new _3dTrimOpeningFace(CORE.preferences.des_levelOfDetail.value, dim, dimTrimMaterial, doorTrimMaterial, false, true);
        
        trim.group.position.z = -.5
        
        this.group.position.z +=.7

        let jamb 
        if (ohJambLiner)
        {
          jamb = new _3dTrimOpeningJamb(CORE.preferences.des_levelOfDetail.value, dim, jambTrimThickness, jambTrimWidthZ, doorTrimMaterial);
        }
        
        /// FRAMING 
        let gFrame = new THREE.Group();
        gFrame.name = 'barn door frame'
        let frameInset = 1;
        let header = new _3dGirtHoriC(CORE.preferences.des_levelOfDetail.value, new THREE.Vector3(0, 0, 0), new THREE.Vector3(dim.x, 0, 0), CORE.dir.rel.up, false, this.parent.girtColor);
        header.build();
        gFrame.add(header.group);
        header.group.position.set(0,dim.y/2,-header.dim.web/2-frameInset);
        this.group.add(gFrame)

        // slide cover   
        let geoCover = new THREE.BoxGeometry(dim.x*2, dimTrimMaterial.y, dimTrimMaterial.z/2);
        let meshCover = new THREE.Mesh(geoCover, doorTrimMaterial.clone())
        meshCover.rotateX(-Math.PI/8)
        meshCover.position.set(dim.x/2*directionOffsetMultiplier, dim.y/2 + 2*2, 1.5)
        meshCover.name = 'sliding door bar cover'
        meshCover.layers.set(CORE.layers.doorsOverhead)
        this.group.add(meshCover);
        
        this.contextMenuObject = new THREE.Mesh()
        let trc = this.getTopRightCorner();
        this.contextMenuObject.position.set(trc.x, trc.y, trc.z);
        this.contextMenuObject.visible = false; // this 

        // we need a separate pickMesh on overhead doors because they're largest feature (the door mesh)
        // is inset into the wall, which "hides" the door from rayCasting because the wall pickMesh
        // must be without holes for the sake of component editing.
        
        if(typeof this.pickDetectionMesh === 'undefined'){
            let zOffset = 3; // pick detection needs to come out past the door
            var geoPick = new THREE.BoxGeometry(this.sizeDoor.x, this.sizeDoor.y, jambTrimThickness*2);
            this.pickDetectionMesh = new THREE.Mesh(geoPick, CORE.materials.transparent);
            this.pickDetectionMesh.position.z += zOffset;
            this.pickDetectionMesh.name=`pickMesh for sliding door ${this.design.id}`;
            this.pickDetectionMesh.visible=false;
        }
        this.pickDetectionMesh.userData = {
            type: this.design.type,
            id: this.design.id
        };
        this.outlineMesh = this.pickDetectionMesh;//.clone();      
        
        layerHelper.setGroup(this.group, CORE.layers.doorsOverhead)
        layerHelper.setGroup(gFrame, CORE.layers.frame);
        this.group.add(trim.group);
        if(jamb)
        trim.group.add(jamb.group); 
        layerHelper.enableLayer(this.group, CORE.layers.quote);
        this.group.add(this.pickDetectionMesh)
        this.group.add(this.contextMenuObject);
        this.group.visible = this.visible;

        this.group.rotation.y = this.rot;

        halfDim.z+=3;
        this.buildGrabjects(halfDim)
        this.showGrabjects(this.design.selected);
              
        
        //this.applyPosition();
        this.setCollisionZone();
        
        this.built=true;
    }

    getTypeDisplayName(){
        return "Sliding Door"
    }

}
