<template>
    <div>
      <select :disabled="disable" v-model="selectedBusiness" @change="$emit('business-selected', selectedBusiness)">
        <option value="">Select a business</option>
        <option v-for="business in businesses" :key="business.id" :value="business.id">
          {{ business.businessName }}
        </option>
      </select>
    </div>
  </template>
  
  <script>
  export default {
    name: 'BusinessPicker',
    props: {
      businesses: {
        type: Array,
        required: true
      },
      disable: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        selectedBusiness: '',
        // isDisabled: false
      }
    },
    watch:{
        'businesses'(newVal) {
            if (newVal) {
                console.log('business picker', this.businesses)
            }
        },

    }
  }
  </script>