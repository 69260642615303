export default class mathHelper{
    
    static ftToFtIn(decimalFt){

       return this.inToFtIn(decimalFt*12)
    }

    static inToFtIn(decimalIn){
        let decimalFt = decimalIn/12;
        let wholeFtComponent = Math.floor(decimalFt);

        let decInchComponent = decimalIn - (wholeFtComponent*12);
        
        let wholeInchComponent = Math.floor(decInchComponent);


        let decSixteenths = (decInchComponent - wholeInchComponent)*16;

        let roundedSixteenthsComponent = Math.round(decSixteenths); 
        let fracInchNumerator = roundedSixteenthsComponent;
        if(fracInchNumerator == 16)
        {
            fracInchNumerator=0;
            wholeInchComponent+=1;
        }
        let fracInchValue = this.getSixteenthsFraction(fracInchNumerator);

        let result ="";
        if(wholeFtComponent>0)
            result+=wholeFtComponent+`'`

        if(wholeFtComponent && wholeInchComponent)
            result+=" "

        if(wholeInchComponent>0)
            result+=wholeInchComponent+``;

        if(wholeInchComponent && fracInchValue!="")
            result+=" "

        if(fracInchValue!="")
            result += fracInchValue;
        
        if(wholeInchComponent || fracInchValue!="")
            result+=`"`
        
        return result;
    }


    static getSixteenthsFraction(numerator){
        switch(numerator){
            case 0:
                return "";
            case 2:
                return "1/8";
            case 4:
                return "1/4";
            case 6:
                return "3/8";
            case 8:
                return "1/2";
            case 10:
                return "5/8";
            case 12:
                return "3/4";
            case 14:
                return "7/8";
            default:
                return `${numerator}/16`;
        }
    }

    static getSlopeFromCoordinates(c1, c2){
        // m = (y1 - y2) / (x1 x2)
        return (c1.y - c2.y) / (c1.x - c2.x);
    }

    static getYIntercept(slope, coordinate){
        // b = -(slope)x + y
        return (-slope*coordinate.x) + coordinate.y;
    }

    static getXFromY(y, b, m){
        // slope intercept y = mx+b
        // x = (b-y)/m
        return (y-b)/m;
    }
}