<template><div>
    <v-dialog transition="pop-out" />
    <modal name="project-copy-modal" :height="400" :adaptive="true" @closed="closeCopyModal">
        <div class="quote-adjust-content">
            <h3 class="modal-header">Copy Project</h3>
            <div class="modal-body">
            <div v-if="input.error.show" style="color:red; margin: 10px">{{input.error.msg}}</div>
            <div>Project Name: <input type="text" v-model="quoteName" v-on:input="inputfieldChanged"></input></div>

            <div class="input-group">
                <span for="projectName">Supplier Owner</span>
                <BusinessUserPicker :businesses="suppliers" :isSupplierPicker="true" ref="businessPicker" @user-selected="onSupplierUserSelected"></BusinessUserPicker>
            </div>
            <div class="input-group">
                <span for="projectName">Contractor Owner</span>
                <BusinessUserPicker :businesses="contractors" :isSupplierPicker="false" ref="contractorPicker" @user-selected="onContractorUserSelected"></BusinessUserPicker>
            </div>
            <div v-if="isSouthWest && hasManualEdits">
                <label for="manualEdits"><input type="checkbox" v-model="keepManualEdits" aria-label="..." id="manualEdits"><span>Keep manual edits</span></label>
            </div>
            </div> 
            <div class="modal-footer">
                <!-- <div v-if="copySave.showCopyResultMessage">
                    <p v-if="copySave.successful">Project copied successfully!</p>
                    <p v-else style="color:red">An error occured while copying the project.</p>
                </div> -->
                <span>
                    <button v-on:click="closeCopyModal" type="button" class="btn btn-primary">Close</button>
                    <button style="background-color: green;" :disabled="copySave.inProgress" type="button" v-on:click="saveCopy()" class="btn btn-primary">Save copy</button><StatusIndicator ref="si_copyProject" :successText="'Saved'"></StatusIndicator>
                </span>
            </div>
        </div>
    </modal>
</div></template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import VModal from 'vue-js-modal'
import Vue from 'vue'
import Vuex from 'vuex'
import { CORE } from '@/constants';
import BusinessUserPicker from '@/components/BusinessUserPicker.vue';
import StatusIndicator from '@/components/StatusIndicator.vue';
Vue.use(Vuex)
Vue.use(VModal, {dialog:true});
export default{
    components:{
        BusinessUserPicker,
        StatusIndicator
    },
    props: {
        copyData: {
            type: Object,
        }
    },
    data(){
        return{
            copySave:{
                showCopyResultMessage: false,
                inProgress: false,
                successful: true,
            },
            suppliers: [],
            contractors: [],
            // businessPicker:{
            //     entity: {
            //     label: 'company',
            //     visible: true
            //     },
            //     user:{
            //     disabled: false,
            //     visible: true
            //     },
            // },
            contractorPicker:{
                entity:{
                label: 'contractor',
                visible: true,
                },
                user:{
                disabled: false,
                visible: true,
                },
            
            },      
            quote: null,
            applyOwnerSettings: false,
            keepManualEdits: false,
            isSouthWest: false,
            hasManualEdits: false,
            quoteName: '',
            input: {
                error:{
                    show: false,
                    msg: ""
                }
            },
        }
    },
    computed:{
        ...mapState('contextModule', ['contractorParentData']),
    },
    methods: {
        ...mapActions('projectModule', ['fetchProject', 'copyProject']),
        ...mapActions('businessModule', ['fetchBusiness', 'fetchAllSuppliers', 'fetchUser', 'fetchContractorsByRep']),
        ...mapActions('userModule', ['fetchUser']),
        closeCopyModal(){
            console.log('hit close customer modal')
            this.copyData.show = false;
            this.copyData.ref = null;
            this.copySave.showCopyResultMessage = false;
            this.$modal.hide('project-copy-modal');
        },
        async saveCopy(){
            let si = this.$refs["si_copyProject"];
            si.loading();
            this.copySave.inProgress = true;

            let payload = {
                metaData: this.quote,
                applyOwnerSettings: this.applyOwnerSettings,
                keepManualEdits: this.keepManualEdits
            }
            let response = await this.copyProject(payload)
            
            if(response.isError){
                this.copySave.successful = false;
                this.copySave.inProgress = false;
                this.copySave.showCopyResultMessage = true;
                this.closeCopyModal();
                this.updateCopyError(response.data);
                si.failed();
                this.$toast.failed(`Failed to copy project.`);
                
            }
            else{
                this.copySave.inProgress = false;
                this.copySave.successful = true;
                this.copySave.showCopyResultMessage = true;
                this.$emit('afterCopy');
                si.success();
                this.$toast.success(`Project copied successfully!`);
            }
        },
        updateCopyError(errData){
            this.$modal.show('dialog', {
                title: 'Warning',
                text: `${errData.msg}`,
                buttons:[
                {
                    title: 'OK',
                    handler: () => {
                    this.$modal.hide('dialog')
                    }
                },
                ]
            })
        },
        inputfieldChanged(){
            let msg = null;

            if(!this.quoteName)
                msg = "Project Name cannot be blank"

            if (/[\\/:*?"<>|]/.test(this.quoteName))
               msg = "Project name cannot contain any of the following characters: \\ / : * ? \" < > |";

            this.quote.name = this.quoteName;

            this.input.error.show = false;

            if (msg) {
                this.input.error = {
                    msg,
                    show: true
                }
            } else {
                this.input.error = {
                    msg: null,
                    show: false
                }
            }
        },
        async initPickerData(userBusinessId){
      
            // Use context to figure out who the heck this project is being made for.
                if(this.entityType == CORE.entities.enterprise){
                    this.$refs.businessPicker.showBusiness = true;
                    this.$refs.businessPicker.showUser = true;
                    this.$refs.contractorPicker.showBusiness = true;
                    this.$refs.contractorPicker.showUser = true;

                    // We have absolutely no clue.
                    this.suppliers = await this.fetchAllSuppliers();


                    // If it's a pre-exisitng project, we have some clue who the supplier is
                    // because every project should have a supplier attached to it
                            if(this.quote.lvl1UserId > -1){
                            let lvl1User = await this.fetchUser(this.quote.lvl1UserId);
                            this.$refs.businessPicker.onBusinessSelected(lvl1User.businessId);
                            this.$refs.businessPicker.onUserSelected(this.quote.lvl1UserId);
                            }
                            if(this.quote.lvl2UserId > -1){
                            let lvl2User = await this.fetchUser(this.quote.lvl2UserId);
                            this.$refs.contractorPicker.onBusinessSelected(lvl2User.businessId);
                            this.$refs.contractorPicker.onUserSelected(this.quote.lvl2UserId);
                            }

                        
                        
                }
                else if(this.entityType == CORE.entities.supplier){
                    // We know who the project supplier is, but not the supplier user nor the contractor user the project is assigned to.
                    this.$refs.businessPicker.showBusiness = false;
                    this.$refs.businessPicker.showUser = true;
                    this.$refs.contractorPicker.showBusiness = true;
                    this.$refs.contractorPicker.showUser = true;

                    // get the users from the supplier
                    this.$refs.businessPicker.users = await this.fetchUsersFromSupplier(userBusinessId);

                    // If it's a pre-exisitng project, we have some clue who the supplier is
                    // because every project should have a supplier attached to it

                        if(this.quote.lvl1UserId > -1){
                        //let lvl1User = await this.fetchUser(this.quote.lvl1UserId);
                        this.$refs.businessPicker.onUserSelected(this.quote.lvl1UserId);
                        }
                        if(this.quote.lvl2UserId > -1){
                        let lvl2User = await this.fetchUser(this.quote.lvl2UserId);
                        this.$refs.contractorPicker.onBusinessSelected(lvl2User.businessId);
                        this.$refs.contractorPicker.onUserSelected(this.quote.lvl2UserId);
                        }

                    
                }
                else{
                    // We know who the project supplier, supplier user, and contractor is, but not the contractor user the project is assigned to.
                    this.quote.lvl1UserId = this.contractorParentData.rep.sub

                    this.$refs.businessPicker.showEntity = false;
                    this.$refs.businessPicker.showUser = false;
                    this.$refs.contractorPicker.showEntity = false;
                    this.$refs.contractorPicker.showUser = true;
                    this.$refs.contractorPicker.users = await this.fetchUsersFromContractor(userBusinessId);     
                    
                    // If it's a pre-exisitng project, we have some clue who the supplier is
                    // because every project should have a supplier attached to it
        
                        if(this.quote.lvl2UserId > -1){
                        this.$refs.contractorPicker.onUserSelected(this.quote.lvl2UserId);
                        }

                    
                }
            
        },
        async onSupplierUserSelected(id){
            this.contractors = await this.fetchContractorsByRep(id);
            this.quote.lvl1UserId = id;
        },
        async onContractorUserSelected(userId){
            this.quote.lvl2UserId = userId;
        },
        async initCopyModal(){
            this.quote = await this.fetchProject(this.copyData.ref);

            if(this.quote)
                this.quoteName = this.quote.name;

            this.isAuthenticated = this.$auth.isAuthenticated;
            let currentUser;
            let currentuserBusiness;
            if(this.isAuthenticated){
                currentUser = JSON.parse(sessionStorage.getItem('user'));
                currentuserBusiness = await this.$store.dispatch('businessModule/fetchBusiness', currentUser.businessId);
                this.entityType = currentuserBusiness.type;
                let swFeatureflag = currentuserBusiness.featureFlags.find(flag => flag.name == 'isSouthwestMetal');
                this.isSouthWest = (swFeatureflag) ? swFeatureflag.enabled : false;
                this.hasManualEdits = this.quote.manualEdits;
            }
   
            await this.initPickerData(currentuserBusiness);  
        },
    },
    watch:{
        'copyData.show'(newVal) {
            console.log('copy data show', newVal)
            if (newVal) {
                this.$modal.show('project-copy-modal');
            }
        },
         'copyData.ref'(newVal) {
            if (newVal) {
                this.initCopyModal();
            }
        }
    },
    async created(){
      
    }
}

</script>

<style lang="scss" scoped>

.modal-header{
    padding: 2%;
    border-bottom: 1px solid lightgrey;
    text-align: center;
}

.modal-body{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
}

.modal-footer{
    margin-top: 5%;
}

.btn{
    padding: 2%;
    margin: 2%;
}

</style>