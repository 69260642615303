import * as THREE from 'three'
import _3dBase from './Base.js'
import {CORE} from '../_spec.js'
import MemHelper from '../helpers/memHelper.js';

export default class TrimEdgeVert extends _3dBase {
/*
Reference material:
    https://trello.com/c/ZfK3v0rL/209-fix-vertical-wall-corner-trim-behavior

Diagram:
    Opens toward X-
    built along Y+/-
    * = origin

        |   |
        |   |
        |   |   Heignt
        |_*_|
        Depth
*/

    constructor(length, height, depth,  material){
        super()
        // length: length in the y dimension (as built)
        // height: length in the x dimension (as built)
        //  depth: length in the z dimension (as built)

        if(typeof height === 'undefined')
            height = 4;
        
        // geometry
        let geoFace = new THREE.PlaneGeometry(height, length, 1,1);
        let geoBase = new THREE.PlaneGeometry(depth, length, 1,1);
        // meshes
        let meshFront = new THREE.Mesh(geoFace, material);
        let meshBack =  new THREE.Mesh(geoFace, material);
        let meshBase = new THREE.Mesh(geoBase, material);
        // orientation
        meshFront.position.set(-height/2,0,depth/2);
        meshBack.position.set(-height/2,0,-depth/2);
        meshBase.rotation.y = Math.PI/2;
        // layers
        meshFront.layers.set(CORE.layers.walls)
        meshBack.layers.set(CORE.layers.walls)
        meshBase.layers.set(CORE.layers.walls)
        // add
        this.group.add(meshFront);
        this.group.add(meshBack);        
        this.group.add(meshBase);        
    }

}