<template>
      <div id="sidebar2Section" style="font-weight: bold; font-size: 26px;">
        <slot></slot>
      </div>        
  </template>
  
  <script>
  export default {
    name: 'Sidebar2Section',
    props: ['text', 'active'],
    data() {
      return {
      };
    },
    computed: {
    },
    methods: {
    }
  };
  </script>
  
  <style scoped>
  
  </style>