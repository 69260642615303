<template>
    <div class="ms_tooltip">
          <slot></slot>
          <span class="tooltiptext">{{helpText }}</span>
    </div>  
  </template>
  
  <script>

  export default {
    props: {
        helpText: String
    },
    data: ()=>{
        return {
            
        };
    },
    methods: {      
    }
  }
  </script> 
  
  <style scoped>
  
  .ms_tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  }
  

  /* Tooltip text 
  https://www.w3schools.com/css/css_tooltip.asp
  */
  .ms_tooltip .tooltiptext {
    visibility: hidden;
    
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    bottom: 24px;
    /* left: 50%; */

   
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 100; 
      max-width: 200px;
    display: inline-block;
    left: -79px;
    width: 200px;
    white-space: normal;
  }
  .ms_tooltip:hover .tooltiptext {
    visibility: visible;
  }
  
  .ms_tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

  
  </style>
  
  
  
  