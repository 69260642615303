<template>
   <div class="subPanel">
          <!-- <div style="text-align: center;"><b>Visuals</b></div> -->
          <div id="newCameraContainer" style="">
            
            <!--<span class ='subheader'>Camera Mode</span>-->
            <div style='display:block;text-align: center;'>

              <button class='viewPreset btn' @click="useLayoutCamera" :class="{selected: inCameraModeLayout }" title="Adjust layout of structures."  style='margin:2px'>
                  <font-awesome-icon  icon="fa-duotone fa-grid-2-plus" size="lg" />
              </button>

                <button class='viewPreset btn' @click="cycleOrbitCamera" :class="{selected: inCameraModeOrbit }" title="Focus on one structure." style='margin:2px;'>
                  <div class='orbit' style=''></div>
                  <div style="position: relative; margin-top: -7px; z-index: 12; ">
                  <font-awesome-icon icon="fa-duotone fa-warehouse" style='padding:0 1px 0 1px; z-index: 10;' />                
                  </div>
                </button>

                <button class='viewPreset btn' @click="useFirstPersonCamera" :class="{selected: inCameraModeFirst }" title="Walk around!"  style='margin:2px'>
                  <font-awesome-icon  icon="fa-duotone fa-person-shelter " size="lg" />
                </button>

                <button class='btn btn-default' :disabled="!showQuote" :class="{selected: shouldShowQuoteLayer }" style='display:inline-block;width:40px;' @click="toggleQuoteView" title="Show only quoted items.">
                  <i class="fa-solid fa-file-invoice-dollar fa-xl"></i> 
                </button>
              </div>
            </div>

            <div style="text-align: center;">
              
              <div class='' style='text-align: center;'>
                    <div class="lvl2Header" style="display:inline-block">Level of Detail: </div>
                    <ToggleButton id='lod' v-model="lod" :width='60' :sync="true" :font-size="12" @change="toggleLod" :labels="{ unchecked: 'Low', checked: 'High'}"/> 
                </div>
                
            <div style='display:inline-block;' >
              <div style="position:relative;">

                <button title="Select a view preset" :disabled="!inCameraModeOrbit" @click="showViewingAngles=true;"  class="viewPreset btn" style="text-align:center;"> 
                  <font-awesome-icon icon="fa-duotone fa-camera-viewfinder"  size="2xl" /> View Presets
                </button>                  
                
                <table v-if="showViewingAngles" style="" class="viewingAngles" @mouseleave="showViewingAngles=false;">
                  <tr>
                      <td><button class='viewPreset btn' @click="_showBackLeftPerspective"><font-awesome-icon  icon="fa-duotone fa-arrow-up " size='xl' style='transform: rotate(135deg)' /></button></td>
                      <td><button class='viewPreset btn' @click="_showBack">Back</button></td>
                      <td><button class='viewPreset btn' @click="_showBackRightPerspective"><font-awesome-icon  icon="fa-duotone fa-arrow-up" size='xl' style='transform: rotate(-135deg)' /></button></td>
                  </tr>
                  <tr>
                      <td><button class='viewPreset btn' @click="_showLeft" >Left</button></td>
                      <td><font-awesome-icon @click="showViewingAngles=false;" style="cursor:pointer" title="Close" icon="fa-duotone fa-camera-viewfinder"  size="2xl" /></td>
                      <td><button class='viewPreset btn' @click="_showRight"> Right</button></td>
                  </tr>
                  <tr>
                      <td><button class='viewPreset btn' @click="_showFrontLeftPerspective"><font-awesome-icon  icon="fa-duotone fa-arrow-up" size='xl'  style='transform: rotate(45deg)' /></button></td>
                      <td><button class='viewPreset btn' @click="_showFront">Front</button></td>
                      <td><button class='viewPreset btn' @click="_showFrontRightPerspective"><font-awesome-icon  icon=" fa-duotone fa-arrow-up" size='xl'  style='transform: rotate(-45deg)' /></button></td>
                  </tr>
                </table>
              </div>
              
            </div>
          </div>

            <div id='viewWrapper'  style="position:relative; user-select: none;" >              
              <div class="lvl2Header" style='margin:0 2px' >Layers</div>
              <div class="trLayers">
                  <div v-if="shouldShowQuoteLayer">                    
                    <div style='display: inline-block;    white-space: normal;    text-align: center;' >
                      <span>Toggle off quote view to control individual layers.</span>
                    </div>
                  </div>
                  <div v-else>                    
                    <div style='text-align:left' >
                      <VisibilityToggle :vis="currentLayers.walls" text='Walls' layer='walls' />
                      <VisibilityToggle :vis="currentLayers.frame" text='Frame' layer='frame'/>
                      <VisibilityToggle :vis="currentLayers.roof" text='Roof' layer='roof'/>
                      <VisibilityToggle :vis="currentLayers.windows" text='Windows' layer='windows'/>
                      <VisibilityToggle :vis="currentLayers.doorsWalkin" text='Walkin Doors' layer='doorsWalkin'/>
                      <VisibilityToggle :vis="currentLayers.doorsOverhead" text='Overhead Doors' layer='doorsOverhead'/>
                      <VisibilityToggle :vis="currentLayers.dimensions" text='Dimensions' layer='dimensions'/>
                      <VisibilityToggle v-if="editable" :vis="currentLayers.openingDimensions" text='Opening Dimensions' layer='openingDimensions'/>            
                      <VisibilityToggle :vis="currentLayers.buildingLabels" text='Structure Names' layer="buildingLabels" />
                    </div>
                  </div>
             
              </div>
            </div>

        </div>
</template>

<script>
import { CORE, cameraModes} from '../js/_spec.js'
import VisibilityToggle from './visibilityToggle.vue'
import { ToggleButton } from 'vue-js-toggle-button' //https://www.npmjs.com/package/vue-js-toggle-button
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
export default {
    data: ()=>{
        return {            
            CORE,
            showViewingAngles:false,
        }
    },
    computed:{
      ...mapGetters('designModule',[
        'inCameraModeFirst',
        'inCameraModeOrbit',
        'inCameraModeLayout',
        'currentLayers',
        'shouldShowQuoteLayer',
        'showQuote'
      ]),
      lod(){
            let v = this.$store.getters.lod == 'high'
            console.log(v);
            return v;
        },
    
        isInLayoutMode(){
      return this.$store.getters.isInLayoutView;
    },
    editable(){
      return this.$store.state.editable;
    },
    },
    methods:{
      ...mapActions('designModule',[
        'showFront',
        'showBack',
        'showLeft',
        'showRight',
        'showFrontLeftPerspective',
        'showFrontRightPerspective',
        'showBackLeftPerspective',
        'showBackRightPerspective',
        'showToggleLayers',
        'showQuoteLayer',
        'switchToCamera',
        'cycleOrbitCamera', 
      ]),
      ...mapMutations('designModule',[
        'setDetail',
      ]),
      toggleLod(){
            if(CORE.preferences.des_levelOfDetail.value == CORE.levelOfDetail.low)
                this.setDetail(CORE.levelOfDetail.high);
            else
                this.setDetail(CORE.levelOfDetail.low);
        },
    hideViewingAngles(){
      this.showViewingAngles=false;
    },
    _showFront(){
        this.showFront()
        this.hideViewingAngles();
    },
    _showBack(){
        this.showBack()
        this.hideViewingAngles();
    },
    _showLeft(){
        this.showLeft()
        this.hideViewingAngles();
    },
    _showRight(){
        this.showRight()
        this.hideViewingAngles();
    },
    _showFrontLeftPerspective(){
        this.showFrontLeftPerspective()
        this.hideViewingAngles();
    },
    _showFrontRightPerspective(){
        this.showFrontRightPerspective()
        this.hideViewingAngles();
    },
    _showBackLeftPerspective(){
        this.showBackLeftPerspective()
        this.hideViewingAngles();
    },
    _showBackRightPerspective(){
        this.showBackRightPerspective()
        this.hideViewingAngles();
    },
    useToggleView(){
            this.showToggleLayers();
    },
    useQuoteView(){
        this.showQuoteLayer();
    },
    toggleQuoteView(){
      console.log('should show quote layer', this.shouldShowQuoteLayer)
        if(this.shouldShowQuoteLayer)
            this.useToggleView();
        else    
            this.useQuoteView();
    },    
        useLayoutCamera(){
      this.switchToCamera(cameraModes.layout);
    } ,       
    useFirstPersonCamera(){
      this.switchToCamera(cameraModes.first);
    },
    useOrbitCamera(){
      // this is used simply to switch from another camera mode (first or layout) to orbit (no rotation)
      this.switchToCamera(cameraModes.orbit);
    },
    // cycleOrbitCamera(){
    //   // this is used to iterate through three orbit modes (no rotation, slow rotation, fast rotation)
    //   this.cycleOrbitCamera();
    // },
    },
    components:{
        VisibilityToggle,
        ToggleButton,
        }
}
</script>

<style scoped>


#viewWrapper{ 
  white-space:nowrap;
}


.trLayers{
   /* margin-top: 10px; */
   padding-left: 5px;
}

.viewPreset{
  border: 1px solid gray;
  border-radius: 4px;
  min-width:45px;
  margin: 1px;
}

.viewingAngles{  
		box-shadow: 0px 0px 30px 15px #000000;
    position:absolute;
    left:-10px;
    top:-30px;
    z-index:100;
}

.viewingAngles td {
  background:gray;
}

.viewingAngles td.center{
  background:unset;
}

</style>