import cBase from './Base.js'
import { Vector2, Shape } from 'three';
import {impactTypes, rebuildTypes} from '../_spec.js'
import TreeNode from '../helpers/TreeNode.js';

export default class Opening extends cBase{
   // required by wall sub-component interface
   getHoleShape(offset) {
        let halfWidth = this.design.dim.width / 2;
        let halfHeight = this.design.dim.height / 2;

        let xDim =this.design.pos.x;

        let botLeft = new Vector2(xDim - halfWidth, this.design.pos.y - halfHeight);
        let topLeft = new Vector2(xDim - halfWidth, this.design.pos.y + halfHeight);
        let topRight = new Vector2(xDim + halfWidth, this.design.pos.y + halfHeight);
        let botRight = new Vector2(xDim + halfWidth, this.design.pos.y - halfHeight);

        var hole = new Shape();
        hole.moveTo(botLeft.x-offset.x, botLeft.y);
        hole.lineTo(topLeft.x-offset.x, topLeft.y);
        hole.lineTo(topRight.x-offset.x, topRight.y);
        hole.lineTo(botRight.x-offset.x, botRight.y);
        hole.lineTo(botLeft.x-offset.x, botLeft.y);
        return hole;
    }

    
    initRebuildHierarchy(){        
        this.rebuildTypeRoot = new TreeNode(null, rebuildTypes.full);
        this.rebuildTypeRoot.addChildNode(rebuildTypes.move);
    }

    detectImpact(impact){
        
        if(this.shouldProcessOwnImpact(impact, this.design.id))
            this.processImpactFromSelf(impact);
    }
    
    getDeleteImpactType(){
        return impactTypes.deleteOpening;
    }
    getAddImpactType(){
        return impactTypes.addOpening;
    }

    static canCenter(){return true;}
}