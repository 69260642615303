
import * as THREE from 'three';
import {CORE} from '../_spec.js'
import { Vector3, Quaternion, Matrix4, Matrix3, Color } from 'three';
import MatrixHelper from '../helpers/matrixHelper.js';
import CollisionHelper from '../helpers/CollisionHelper.js';
import MemHelper from '../helpers/memHelper.js';
import layerHelper from '../helpers/layerHelper.js'
import _3dBase from './Base.js';
import BlueprintHelper from '../helpers/blueprintHelper.js';
import materialHelper from '../helpers/materialHelper.js';
import Sphere from './Sphere.js';


/* https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Ibeam.svg/520px-Ibeam.svg.png

		|---------FLANGE---------|__
		************************** |
	___	************************** |
	|				            ** | 
	|				            ** |              
	|				            ** | 
	WEB				            ** DEPTH
	|				            ** | 
	|				            ** | 
	|				            ** | 
	___				            ** | 
		************************** |
		**************************__
*/

export default class Eave extends _3dBase {


    constructor(lod, len, pos, qRot, collisionZones, pitchRatio, color){
        super(undefined, pos, qRot, lod);        
        this.czqRot = qRot;
        this.length = len;
        //this.pos = pos;
		this.pitchRad = BlueprintHelper.pitchRatioToRadians(pitchRatio);
        this.purlinDimY = BlueprintHelper.pitchedPurlinDimY(pitchRatio);
        this.halfPurlinHeight = this.purlinDimY/2;
        this.collisionZones = collisionZones;
        this.color = color ? color : CORE.frame.redOxideColor        
        this.build();
    }


    build(){
        if(this.isLowDetail())
            this.build_LD();
        else
            this.build_HD();
    }

    build_LD(){       
        let dimZ = 3.5 ;
        let halfDimZ = dimZ/2;
        let halfPurlinHeight = this.halfPurlinHeight;
        
        let szOffset = Math.sin(this.pitchRad)*halfPurlinHeight; // use radian angle to calculate 

        // Create a custom texture for this object
        this.texture = CORE.textures.beam.clone();
        this.texture.wrapT = this.texture.wrapS = THREE.MirroredRepeatWrapping;
        //this.texture.wrapT = THREE.ClampToEdgeWrapping
        this.texture.encoding = THREE.sRGBEncoding;
        //this.texture.anisotropy = 32;
        let texScale = .333 // 720 inch long purlin /3 => 240, which is the length of 1 gabled rafter of a 40' wide barn @ repeat 1,1
        this.texture.repeat.set(this.length/texScale, 3.5/texScale);

        this.material = materialHelper.getFrameMaterial_LD(this.color, this.texture);
        
        var gFlange = new THREE.PlaneBufferGeometry(this.length, dimZ,1,1);
        //TOP FLANGE
        var mFlangeTop = new THREE.Mesh( gFlange, this.material);
        mFlangeTop.position.y=Math.cos(this.pitchRad)*halfPurlinHeight;
        mFlangeTop.position.z = -halfDimZ -szOffset;
        mFlangeTop.rotation.x=Math.PI/2;

        //BOTTOM FLANGE
        var mFlangeBot = new THREE.Mesh( gFlange, this.material );
        mFlangeBot.position.y=-Math.cos(this.pitchRad)*halfPurlinHeight;
        mFlangeBot.position.z = -halfDimZ + szOffset;// - halfThickness;
        mFlangeBot.rotation.x=Math.PI/2;

        // WEB
        let hMid = 2*halfPurlinHeight;
		var gWeb = new THREE.PlaneBufferGeometry(this.length, hMid, 1,1);
        var mWeb = new THREE.Mesh( gWeb, this.material );

        let rMid = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1,0,0),-this.pitchRad)
            
        let qMid = mWeb.quaternion
        
        qMid.multiplyQuaternions(rMid,qMid);

        
		this.group.add(			
			mFlangeTop,
            mWeb,
            mFlangeBot
		);
        
        layerHelper.setGroup(this.group,CORE.layers.frame);
        this.group.name = "eave strut";
        
        //collision zones
        
        // this must be done after adding to the scene
        // but before the collisionZone is built so 
        // grp.matrixWorld values consider parent groups 
        // component hierarchy (building > structure > sideFrame > porch > structure > wall)
        this.group.updateWorldMatrix(true);

        if(this.collisionZones){
            let size = new Vector3(this.length, this.purlinDimY, dimZ);
            let margin = CollisionHelper.getMargin(0,0,-1,0,4,4);
            
            let zone = CollisionHelper.getZone2(
                this.group,
                undefined,
                false,
                [
                    CORE.collisions.classes.window,
			        CORE.collisions.classes.emptyFramedOpening,		
                    CORE.collisions.classes.louverVent,
                    CORE.collisions.classes.doorWalkin3o7o,
                    CORE.collisions.classes.doorWalkin4o7o,
                    CORE.collisions.classes.doorWalkin6o7o,
                    CORE.collisions.classes.doorRollup,
                    CORE.collisions.classes.doorSliding,
                    CORE.collisions.classes.doorCommGlass,
                    CORE.collisions.classes.doorSectional,
                    CORE.collisions.classes.doorHangar,
                    CORE.collisions.classes.wideOpening,
                ],
                size, 
                margin,
                'eave strut'
            );
            
            mWeb.userData = {
                collisionZones: [zone]
            };        

            zone.rotation = new Matrix3();
        }
    }

    build_HD(){       
        let thickness = CORE.roof.purlin.dim.thickness;
        let halfThickness =  thickness/2;
        
        let dimZ = 3.5 ;
        let halfDimZ = dimZ/2;
        let halfPurlinHeight = this.halfPurlinHeight;
        
        let szOffset = Math.sin(this.pitchRad)*halfPurlinHeight; // use radian angle to calculate 

        // Create a custom texture for this object
        this.texture = CORE.textures.beam.clone();
        this.texture.wrapT = this.texture.wrapS = THREE.MirroredRepeatWrapping;
        //this.texture.wrapT = THREE.ClampToEdgeWrapping
        this.texture.encoding = THREE.sRGBEncoding;
        //this.texture.anisotropy = 32;
        let texScale = .333 // 720 inch long purlin /3 => 240, which is the length of 1 gabled rafter of a 40' wide barn @ repeat 1,1
        this.texture.repeat.set(this.length/texScale, 3.5/texScale);

        this.material = materialHelper.getFrameMaterial_HD(this.color, this.texture);

        
        var gFlange = new THREE.BoxGeometry(this.length, thickness, dimZ);
        gFlange.name = 'Eave strut flange'
        //TOP FLANGE
        var mFlangeTop = new THREE.Mesh( gFlange, this.material);        
        mFlangeTop.position.y=Math.cos(this.pitchRad)*halfPurlinHeight;
        mFlangeTop.position.z = -halfDimZ -szOffset - halfThickness;
        mFlangeTop.position.y -= halfThickness

        //BOTTOM FLANGE
        var mFlangeBot = new THREE.Mesh( gFlange, this.material );
        mFlangeBot.position.y=-Math.cos(this.pitchRad)*halfPurlinHeight;
        mFlangeBot.position.z = -halfDimZ + szOffset; // - halfThickness;
        mFlangeBot.position.y += halfThickness

        // WEB
        let hMid = 2*halfPurlinHeight;
		var gWeb = new THREE.BoxGeometry(this.length, hMid, thickness);
        gWeb.name = 'eave strut web'
        //let debugMat = new THREE.MeshBasicMaterial({color: 0xff0000});
        var mWeb = new THREE.Mesh( gWeb, this.material );
        mWeb.position.z -= halfThickness;

        let rMid = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1,0,0),-this.pitchRad)
            
        let qMid = mWeb.quaternion
        
        qMid.multiplyQuaternions(rMid,qMid);
        let origin = new Sphere().group;
        
		this.group.add(			
			mFlangeTop,
            mWeb,
            mFlangeBot,
            //origin
		);
        
       
        layerHelper.setGroup(this.group,CORE.layers.frame);
        this.group.name = "eave strut";
        
        //collision zones
        
        // this must be done after adding to the scene
        // but before the collisionZone is built so 
        // grp.matrixWorld values consider parent groups 
        // component hierarchy (building > structure > sideFrame > porch > structure > wall)
        this.group.updateWorldMatrix(true);

        if(this.collisionZones){
            let size = new Vector3(this.length, this.purlinDimY, dimZ);
            let margin = CollisionHelper.getMargin(0,0,-1,0,4,4);
            
            let zone = CollisionHelper.getZone2(
                this.group,
                undefined,
                false,
                [
                    CORE.collisions.classes.window,
                    CORE.collisions.classes.emptyFramedOpening,	
                    CORE.collisions.classes.louverVent,
                    CORE.collisions.classes.doorWalkin3o7o,
                    CORE.collisions.classes.doorWalkin4o7o,
                    CORE.collisions.classes.doorWalkin6o7o,
                    CORE.collisions.classes.doorRollup,
                    CORE.collisions.classes.doorCommGlass,
                    CORE.collisions.classes.doorSectional,
                    CORE.collisions.classes.doorHangar,
                    CORE.collisions.classes.wideOpening,
                ],
                size, 
                margin,
                'eave strut'
            );
                    
            
            mWeb.userData = {
                collisionZones: [zone]
            };        

            
            zone.rotation = new Matrix3();
        }
    }
}
