import Util from '../utility.js'
export default class FramelineHelper{
    

    

    

    static generate(fDes, o, length){
        // the sides are identical
        // start from the center and work your way out

        let maxSpacing =Util.Convert.ftToIn(fDes.sides.baySpacing.maxSize);
        // we need to fix this so that as the frame lines are placed,
        // we are taking up the correct amount of space by 
        // consulting what time of frame line it is.
         // this is wrong for debuggin only
        let columnHalfWidth = o.outsideFrameLineInset;

        //let leftSide = columnHalfWidth;
        //let rightSide = length - columnHalfWidth;
        // use full outer to outer length to make the bay sizes
        let leftSide = 0;
        let rightSide = length;
        let lengthCtoC =rightSide - leftSide;        
        
        let lewSpacing=0;
        if(fDes.sides.baySpacing.specialLeftSize)
            lewSpacing=Util.Convert.ftToIn(fDes.sides.baySpacing.specialLeftSize);

        let rewSpacing=0;
        if(fDes.sides.baySpacing.specialRightSize)
            rewSpacing=Util.Convert.ftToIn(fDes.sides.baySpacing.specialRightSize);

        let lengthToDistribute = lengthCtoC-lewSpacing-rewSpacing;

        // if the left and right special bays take more length than there is
        if(lengthToDistribute<0)
        {
            // if both are special
            if(lewSpacing >0 && rewSpacing > 0){
                // if the left fits
                if(lengthCtoC > lewSpacing)
                    // force the right side to fit
                    rewSpacing = lengthCtoC-lewSpacing;
                else  // otherwise, if the right side fits
                    if (lengthCtoC > rewSpacing)
                    // force the left side to fit
                    lewSpacing = lengthCtoC-rewSpacing;
                else // otherwise, there's no space for either 
                    {
                        // completely ignore the input because it's nonsense
                        lewSpacing = 0;
                        rewSpacing = 0;                        
                    }
            }                
            else // otherwise, if only lew is special
             if(lewSpacing > 0){
                 // ignore it
                lewSpacing = 0;
             }
             else
             if( rewSpacing > 0)
             {
                 rewSpacing = 0;
             }
        }
        // re-calculate with the revised spacings
        lengthToDistribute = lengthCtoC-lewSpacing-rewSpacing;


        // add middles 
        let equalSizeBayCount = Math.ceil(lengthToDistribute/maxSpacing);
        let equalSizeBaySpacing = lengthToDistribute/equalSizeBayCount;
        
        /*
        use cases

        Left / Equal / Right
        00 / 25 / 00 => equals to REW
        00 / 25 / 10  => equals to REW - 10, right
        10 / 25 / 00 => left, equals to REW - 0
        10 / 25 / 10 => left, equals to REW - right, right
        */
       



        // if there is no special left bay 
        if(lewSpacing===0){
            //lewSpacing=equalSizeBaySpacing;
            //equalSizeBayCount--;
        }
            
        // if the right bay spacing should automatically size 
        if(rewSpacing===0){
            //rewSpacing=equalSizeBaySpacing;
            //equalSizeBayCount--;
        }
        
        if(equalSizeBayCount === -0 )
        {
            console.log('ok')
        }

        ////////////////////////////////////
        // OLD CALCULATION for migration conflict detection
        if(Util.isDefined(fDes.sideBaySpacing)){
            let midSpacing=Util.Convert.ftToIn(fDes.sideBaySpacing);
            let old_lewSpacing;
            let LeftBaySpaceOverride = fDes.leftMostBayOverride;
            if(LeftBaySpaceOverride)
                old_lewSpacing=Util.Convert.ftToIn(fDes.leftMostBaySpacing);
            else
                old_lewSpacing=midSpacing;
            let middleBays = Math.floor((lengthCtoC-lewSpacing)/midSpacing);
            // add remnant
            let rightBay = lengthCtoC - old_lewSpacing - (middleBays*midSpacing);
            // between 1" and 5 feet isn't valid in the new bay spacing method
            if(rightBay > 0 && rightBay < Util.Convert.ftToIn(5)){
                alert("A conflict during design migration requires the right end-wall frameline be moved.")
            }
            delete fDes.sideBaySpacing;
            delete fDes.leftMostBayOverride;
            delete fDes.leftMostBaySpacing;
        }

        ////////////////////////////////////

        
        this.framePoints = [];
        if(fDes.sides.baySpacing.specialLeftSize && fDes.sides.baySpacing.specialRightSize && length < lewSpacing + rewSpacing)
            console.error("Invalid length, given special left and special right")
        else
        if(fDes.sides.baySpacing.specialLeftSize && length < lewSpacing )
            console.error("Invalid length, given special left")
        else 
        if(fDes.sides.baySpacing.specialRightSize && length < rewSpacing )
            console.error("Invalid length, given special right")        

        let point = leftSide; // start on the Left end of the side                
        this.framePoints.push(point);// left end

        if(lewSpacing > 0){
            point+=lewSpacing;//
            this.framePoints.push(point);
        }
        // seems like I can either make lewSpacing always valid and reduce the count of equalSizeBays, like I was. 
        //OR
        // I can handle the various use cases with flow control.


        for(let b=0;b<equalSizeBayCount;b++) {
            if(point<0) continue;
            point+=equalSizeBaySpacing; 
            this.framePoints.push(point);
        }

        if(rewSpacing > 0)
        {
            point+=rewSpacing;// 
            if(point>=0)
                this.framePoints.push(point);
        }

        // offset the first and last frame point to point to the center of the frame instead of outer and outer
        this.framePoints[0] += columnHalfWidth;
        this.framePoints[this.framePoints.length-1] -= columnHalfWidth;
        
        let result = {
            points: this.framePoints,
            nominalBayLengths: this.GetBayLengths(fDes)
        }
        
        
        return result;
    }

    static GetBayLengths(fDes){
        
        let totalLength = Util.Convert.ftToIn(fDes.length);
        let workingLength = totalLength;        
        let maxSpacing =Util.Convert.ftToIn(fDes.sides.baySpacing.maxSize);

        let lewSpacing=0;
        if(fDes.sides.baySpacing.specialLeftSize)
            lewSpacing=Util.Convert.ftToIn(fDes.sides.baySpacing.specialLeftSize);

        let rewSpacing=0;
        if(fDes.sides.baySpacing.specialRightSize)
            rewSpacing=Util.Convert.ftToIn(fDes.sides.baySpacing.specialRightSize);

        workingLength -= lewSpacing;
        workingLength -= rewSpacing;

        let equalSizeBayCount = Math.ceil(workingLength/maxSpacing);
        let equalSizeBaySpacing = workingLength/equalSizeBayCount;

        let points = [];
        let point =0;
        points.push(point);
        if(lewSpacing > 0){
            point+=lewSpacing;//
            points.push(point);
        }

        for(let b=0;b<equalSizeBayCount;b++) {
            if(point<0) continue;
            point+=equalSizeBaySpacing; 
            points.push(point);
        }

        if(rewSpacing > 0)
        {
            point+=rewSpacing;// 
            if(point>=0)
                points.push(point);
        }

        return points;
    }
}