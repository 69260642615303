import * as THREE from 'three'
import _3dBase from './Base.js'
import {CORE} from '../_spec.js'
import MemHelper from '../helpers/memHelper.js';
import FoldedGeometryHelper from '../helpers/foldedGeometryHelper.js';
import layerHelper from '../helpers/layerHelper.js';

export default class TrimEaveAngle extends _3dBase {
//reference material:
//https://www.jagmetalsllc.com/wp-content/uploads/2018/04/rake-trim_catalogfinal.png

    constructor(length, pitchAngleDeg, material, 
        leftVertAngle, 
        rightVertAngle, 
        leftHoriAngle, 
        rightHoriAngle,
        height=6,
        ){
        super();
        
        let folds = [
            //{mdist:-2},
            //{dist:3.5},
            //{deg:-30},
            //{dist:.2},
            //{deg:60},
            {dist:.2},
            //{deg:-30},
            {deg:pitchAngleDeg},
            {dist:height},
            {deg:-45},
            {dist:5/8},
            {deg:90},
            {dist:.1},
            {deg:90},
            {dist:4/8},
        ];

        let fgh = new FoldedGeometryHelper(folds, material, false, false, false, length, leftVertAngle, rightVertAngle, leftHoriAngle, rightHoriAngle);
        layerHelper.setGroup(fgh.group, CORE.layers.wall);
        this.group.add(fgh.group);
        this.group.name = 'eave angle trim'
    }

    
}