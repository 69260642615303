import _3dBase from './Base.js'
import * as THREE from 'three';
import {Vector2,  Vector3 } from 'three';
import MemHelper from '../helpers/memHelper.js';
import ShapeHelper from '../helpers/shapeHelper.js'
import { CORE } from '../_spec.js'
import _3dGirtHoriC from './GirtHoriC.js'
import _3dTrimOpeningFace from './TrimOpeningFace.js';
import _3dTrimOpeningJamb from './TrimOpeningJamb.js';
import materialHelper from '../helpers/materialHelper.js';
import layerHelper from '../helpers/layerHelper.js';

export default class DoorBarn extends _3dBase{

    constructor(lod, width, height, panelColor, doorTrimMaterial){
        super(undefined, undefined, undefined, lod)

        this.build(width, height, panelColor, doorTrimMaterial)
    }

    build(width, height, panelColor, doorTrimMaterial) {

        // exterior of panel
        let matExtDoor = materialHelper.getExteriorPanelPbrMaterial(panelColor, width);
        matExtDoor.map.repeat.set(width/36,1)
        //matExtDoor.bumpMap.repeat.set(width/36,1)
        let panelThickness = .1;
        let geoDoor = new THREE.PlaneBufferGeometry(width-.01, height)
        let meshDoorExt = new THREE.Mesh(geoDoor, matExtDoor);
        meshDoorExt.name = 'barn door panel'
        meshDoorExt.position.z+=.5;
        meshDoorExt.layers.set(CORE.layers.doorsOverhead);
        this.group.add(meshDoorExt);

        // interior of panel
        let matDoorInt = materialHelper.getInteriorPanelPbrMaterial(0xEEEEED, width);        
        matDoorInt.map.repeat.set(width/36,1);
        matDoorInt.bumpMap.repeat.set(width/36,1);

        let geoDoorInt = new THREE.PlaneBufferGeometry(width-.02, height);
        let meshDoorInt = new THREE.Mesh(geoDoorInt, matDoorInt);        
        meshDoorInt.layers.set(CORE.layers.doorsOverhead)
	    this.group.add(meshDoorInt);
        
        this.buildDoorTrim(width, height, doorTrimMaterial);
        layerHelper.enableLayer(this.group, CORE.layers.quote); //https://trello.com/c/a0Wx4VQK/531-sliding-door-appearance-in-instant-quote-mode
        
    }

    buildDoorTrim(width, height, doorTrimMaterial) {
        // door trim        
        let dim = new THREE.Vector3(width, height, 1);
        let dimTrimMaterial = new THREE.Vector3(2,2,1.5);
        
        // exterior border trim
        let trim = new _3dTrimOpeningFace(this.lod, dim, dimTrimMaterial, doorTrimMaterial, true, false);
        this.group.add(trim.group);
        
        // interior border trim
        let borderTrimInterior = new _3dTrimOpeningFace(this.lod, dim, dimTrimMaterial, doorTrimMaterial, true, false);
        borderTrimInterior.group.position.z -= dimTrimMaterial.z*2/3;
        trim.group.add(borderTrimInterior.group)        

        let angle = Math.PI/4;
        let crossTrimLength = width*Math.SQRT2-dimTrimMaterial.y-.03;
        let crossHeight = width*Math.tan(angle);
        let topRailY = crossHeight - height/2 - dimTrimMaterial.y;
        let crossY = crossHeight/2 - height/2;
        if (width > height/2) {    // use different calculations so that the cross takes up the lower half always        
            angle = Math.atan((height-dimTrimMaterial.y)/width/2);
            crossTrimLength = (height-dimTrimMaterial.y)/(2*Math.sin(angle))-dimTrimMaterial.y-.03;
            topRailY = 0;
            crossY = -height/4;
        }

        // cross trims
        let geoCross1 = new THREE.BoxGeometry(crossTrimLength, dimTrimMaterial.y, dimTrimMaterial.z);
        geoCross1.rotateZ(angle)
        let meshCross1 = new THREE.Mesh(geoCross1, doorTrimMaterial)
        meshCross1.name = 'trim cross 1'
        meshCross1.position.y= crossY;
        trim.group.add(meshCross1)

        let geoCross2 = new THREE.BoxGeometry(crossTrimLength, dimTrimMaterial.y, dimTrimMaterial.z);
        geoCross2.rotateZ(-angle)
        let meshCross2 = new THREE.Mesh(geoCross2, doorTrimMaterial)
        meshCross2.name = 'trim cross 2'
        meshCross2.position.y= crossY;
        trim.group.add(meshCross2)
        
        // horizontal rail above cross
        let geoRail = new THREE.BoxGeometry(width, dimTrimMaterial.y, dimTrimMaterial.z);
        let meshRail = new THREE.Mesh(geoRail, doorTrimMaterial)
        meshRail.position.y = topRailY;
        trim.group.add(meshRail)

        // interior rail
        let meshRailInterior = new THREE.Mesh(geoRail, doorTrimMaterial);
        meshRailInterior.position.z -= dimTrimMaterial.z*2/3;
        meshRailInterior.position.y = topRailY; // change to always middle? (0)
        trim.group.add(meshRailInterior)

        trim.group.position.z += dimTrimMaterial.z*2/3;


    }
}