import * as THREE from 'three'
import _3dBase from './Base.js'
import {CORE} from '../_spec.js'
import layerHelper from '../helpers/layerHelper.js'
import MemHelper from '../helpers/memHelper.js';
import DownspoutPipe from './DownspoutPipe.js';

export default class DownspoutOffset extends _3dBase {
//reference material:
//https://images.squarespace-cdn.com/content/v1/569685c1df40f31bc5cd0840/1601301987021-XWFLFFCB1FCB2ELJM91S/ke17ZwdGBToddI8pDm48kNnbBUj9eSp7j6QYKRqkc1QUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYy7Mythp_T-mtop-vrsUOmeInPi9iDjx9w8K4ZfjXt2dk-7GxrQNx-DJlyQFCDfyfTvaqLCzhT1I4jf7RojfHbjW07ycm2Trb21kYhaLJjddA/front.jpg?format=1500w

    constructor(topHeight, xOffset, bottomHeight, slopeRadians, material){
        super()
        let width = 4; // x
        let depth = 3; // z
        
        let top = new DownspoutPipe(topHeight, material, width, depth)
        xOffset+=depth; // this adds two half-depths for each of the top and bottom pieces
        let offsetPitchHeight = xOffset*Math.tan(slopeRadians);
        let offsetLength = xOffset/Math.cos(slopeRadians);
        let offset = new DownspoutPipe(offsetLength, material, width, depth)
        let bottom = new DownspoutPipe(bottomHeight, material, width, depth)

        top.group.position.set(0,-topHeight/2,0); // pull this back to the right

        // this does the math to make sure that the slanted offset piece
        // aligns with the outer bottom corner of the top 
        // and the outer top corner of the bottom
        
        // theta1 is a slope where 0 means no slope
        let theta1 = Math.PI/2-slopeRadians;
        // vertical distance to align corners
        let middleOffsetY = depth/2*Math.sin(theta1);
        // horizontal distance to align corners
        let middleOffsetZ = depth/2 - (depth/2*Math.cos(theta1));

        let initialY = -topHeight - offsetPitchHeight/2;
        let initialZ = -xOffset/2;

        offset.group.position.set(0,initialY + middleOffsetY, initialZ + middleOffsetZ);
        offset.group.rotation.x = theta1;

        bottom.group.position.set(0,-topHeight - offsetPitchHeight - bottomHeight/2 + middleOffsetY*2, -xOffset + 2*middleOffsetZ) // pull this back to the right
        this.group.add(top.group,offset.group,bottom.group);

    }
}