
import * as THREE from 'three';
import {CORE, impactTypes,rebuildTypes  } from '../_spec.js'
import Base from './Base'
import MemHelper from '../helpers/memHelper.js';
import { Color, Vector3 } from 'three';
import Grabject from './grabject.js';
import OptionHelper from '../helpers/optionHelper.js';
import UpdateHelper from '../helpers/UpdateHelper.js';
import TreeNode from '../helpers/TreeNode.js';
import Util from '../utility.js';
import layerHelper from '../helpers/layerHelper.js';
import materialHelper from '../helpers/materialHelper.js';

export default class Foundation extends Base{
    constructor(design, dim){
		super(design);
		this.group.name = 'Foundation Component'
		this.type = CORE.components.foundation;
		this.lhw = new THREE.Vector3(dim.x, 2*dim.y, dim.z);
		//this.structureDesign = structureDes;		
		//this.addGrabjects = addGrabjects;
		//this.isRotationInputEnabled=addGrabjects;
		this.build();		
	}


    initRebuildHierarchy(){        
        this.rebuildTypeRoot = new TreeNode(null, rebuildTypes.full);
        this.rebuildTypeRoot.addChildNode(rebuildTypes.move);
    }

	disableRotationInput(){
		this.isRotationInputEnabled=false;
		this.addGrabjects=false;
		this.showGrabjects(false);
	}

	enableRotationInput(){
		this.isRotationInputEnabled=true;
		this.addGrabjects=true;
		this.showGrabjects(true);
	}

	processImpactFromSelf(impact){

        impact.handled.push(this.design.id);
        switch(impact.change.name)
        {
            case impactTypes.foundationHeight:
                this.addRebuildNeeded(rebuildTypes.full);
                break;
			case impactTypes.foundationRotation:
                this.addRebuildNeeded(rebuildTypes.move);
                break;
        }
    }

	processInputGrabject(input){
		switch(input.role){
			case CORE.grabjects.rotation:
				//this.grabjectChangeRotation(input.relPosition);
				break;
		}
	}

	// grabjectChangeRotation(relPosition){
	// 	//SOHCAHTOA
	// 	//tan(0) = O/A
	// 	// arctan(O/A) = 0
	// 	let rad = Math.atan(relPosition.y. relPosition.x);
	// 	rad *= 180 / Math.PI;
	// 	this.applyChangeRotation(rad);
	// }

	// applyChangeRotation(v){
	// 	if(v<0)
	// 		v+=360; // don't let the rotation be negative
	// 	// normalize the value
	// 	v=v%360;
	// 	// convert the value for storage in radians
	// 	this.structureDesign.rotRad = v*Math.PI/180;
	// }

	
    processRebuild(type){
        // rebuild the minimal amount of 3d objects for this component
        switch(type){
            case rebuildTypes.full:
                this.rebuildFull();
                break;
            case rebuildTypes.move:
                this.rebuildMove();
                break;
        }
    }
    
    detectImpact(impact){
        
		
        if(this.shouldProcessOwnImpact(impact, this.design.id))
            this.processImpactFromSelf(impact);
		
		this.processImpactFromOther(impact);
    }

	processImpactFromOther(impact){
		impact.handled.push(this.design.id);
        switch(impact.change.name)
        {
			case impactTypes.structureRotation:
				this.addRebuildNeeded(rebuildTypes.move);
                break;
        }
	}


	defaultDesign(){
		return {
			id: -2,
			type: CORE.components.foundation,
			height: 6
		}		
	}

	getCollisionClass (){
		return null;
	}

    getOutlineMeshes(){
        return [this.outlineMesh];
    }
	
    static canEdit(){
        return true;
    }    

	getTypeDisplayName(){
		return 'Foundation'
	}
	getOptionsSpec(){    

        
        let opts = [
            OptionHelper.numericUpDown("height", "Height", impactTypes.foundationHeight, "in.", 4, 60, 4, false)
        ];

		// if(this.addGrabjects){
		// 	opts.push(OptionHelper.numericUpDown("rotRad", "Rotation", impactTypes.structureRotation, "degrees", undefined, undefined, 15, true,
		// 	()=>{ //fnIn
		// 		// load the value as degrees
		// 		return Math.round(this.structureDesign.rotRad*180/Math.PI);
		// 	},
		// 	undefined,			
		// 	(v)=>{//fnChange				
		// 		this.applyChangeRotation(v);
		// 	}
		// 	));
		// }

        //console.table(opts);

        return opts;
    }

	rebuildFull(){
        this.remove();
        this.build();
    }

	
	rebuildMove(){
		//let rotationY = this.design.rotRad;
        //let pos2 = new Vector3(this.design.pos.x,0,this.design.pos.z);
		//this.group.position.copy(pos2);
        //this.group.rotation.y = rotationY;
    }

    build(){
		this.lhw.y = this.design.height;
		
		// when a component uses a 3D object, it needs to add that object's group to the scene.
		// when the parent makes a component, that component needs to be able to rebuild itself,
		//   so the component should be able to remove previous content and make new content without
		//	 changing the object already in the scene.

		// so where-as a component might build a 3dobject and then position and rotate it,
		// the a component needs to be able to position and rotate itself
			
		//scene.add(this.group);
		let texConcrete = CORE.textures.concrete.clone();
		var geometry = new THREE.BoxGeometry( this.lhw.x, this.lhw.y, this.lhw.z);
		geometry.name='foundation'
		// https://github.com/mrdoob/three.js/blob/master/examples/webgl_lights_spotlight.html
		// let material = new THREE.MeshPhongMaterial( { 
		// 	//color: 0xdddddd, 
		// 	dithering: true, 
		// 	map: texConcrete,
		// 	color: 0xeeeeee,
		// 	shininess: 20,
		// 	emissive: 0,
		// 	specular: new Color(0)
		// } );

		let material = materialHelper.getConcreteMaterial(0x666666, texConcrete);
		material.map.wrapS = THREE.MirroredRepeatWrapping;
		material.map.wrapT = THREE.MirroredRepeatWrapping;
		material.map.anisotropy = 32;
		material.map.repeat.set(this.lhw.x/300,this.lhw.z/300);
		this.mFoundation = new THREE.Mesh( geometry, material );
		this.mFoundation.name = 'foundation'
		//this.mFoundation.userData = {type:CORE.components.foundation}
		//this.mFoundation.position.set(this.center.x, this.center.y, this.center.z);
		this.mFoundation.receiveShadow=true;
		let matTransparent =new THREE.MeshBasicMaterial({ color: 0x000000,  side: THREE.DoubleSide, opacity:0, transparent:true});
		this.mFoundation.position.y = this.design.height/2;
		this.outlineMesh = new THREE.Mesh(geometry, matTransparent);
        //this.outlineMesh.position.set(this.center.x, this.center.y, this.center.z);
        this.outlineMesh.visible=false;		


		if(typeof this.pickDetectionMesh === 'undefined'){
            var geoPick = new THREE.BoxGeometry(this.lhw.x, this.lhw.y, this.lhw.z);
			geoPick.name = 'foundation picking'
            this.pickDetectionMesh = new THREE.Mesh(geoPick, matTransparent);
            this.pickDetectionMesh.name=`pickMesh for foundation`;
            this.pickDetectionMesh.visible=false;
            this.pickDetectionMesh.castShadow=false;
            this.pickDetectionMesh.receiveShadow=false;
        }
        this.pickDetectionMesh.userData = {
            type: this.design.type, // not sure this is necessary. copied from wallBase.
            id: this.design.id
        };
        this.outlineMesh = this.pickDetectionMesh;
		this.pickDetectionMesh.position.y = this.design.height/2;

		
		this.buildGrabjects(this.group);
		this.group.add( this.mFoundation );
		this.group.add(this.pickDetectionMesh)
	}

	buildGrabjects(parentGroup){		

		
		
	}

	remove(){
		this.group.remove(this.mFoundation)
		MemHelper.removeAllChildren(this.group);
		MemHelper.dispose(this.group);		
	}
	
	getDescription(){
		return "Foundation";
	}
}