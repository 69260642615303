<template>
  <div class="onboarding">
    <h1>Onboard New Business</h1>
    <form @submit.prevent="onboardNewEntity">
      <div class="form-group">
        <label for="companyName">Business Name:</label>
        <input id="companyName" v-model="newEntity.companyName" required>
      </div>
      <!-- <div class="form-group">
        <label for="adminName">Admin Name:</label>
        <input id="adminName" v-model="newEntity.adminName" required>
      </div>
      <div class="form-group">
        <label for="adminEmail">Admin Email:</label>
        <input id="adminEmail" v-model="newEntity.adminEmail" type="email" required>
      </div> -->
      <div class="form-group">
        <label for="phone">Business Phone Number:</label>
        <input id="phone" v-model="newEntity.address" required>
      </div>
      <div class="form-group">
        <label for="address">Business Address:</label>
        <input id="address" v-model="newEntity.address" required>
      </div>
      <button type="submit">Submit</button>
    </form>
  </div>
</template>

<script>
import ContextLogic from '@/lib/contextLogic';

import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      newEntity: {
        adminName: '',
        adminEmail: '',
        address: '',
        companyName: ''
      }
    }
  },
  computed: {
    ...mapState('contextModule', ['currentContext', 'mode']),
  },
  methods: {
    ...mapActions('businessModule', ['addBusiness']),
    async onboardNewEntity() {
      try {
        // First, create the entity in your backend
        const createdEntity = await this.addBusiness(this.newEntity);
        
        // Then, create an Auth0 user for the entity admin
        await this.createAuth0User(createdEntity);
        
        // Show success message and redirect to the new entity's dashboard
        alert('Entity successfully onboarded!');
        this.$router.push({ name: 'entityDashboard', params: { id: createdEntity.id } });
      } catch (error) {
        console.error('Error onboarding new entity:', error);
        alert('An error occurred while onboarding the entity. Please try again.');
      }
    },
    async createAuth0User(entity) {
      // This method will integrate with Auth0 to create a new user
      // You'll need to implement this using Auth0's Management API
      // Here's a placeholder for the implementation:
      
      try {
        const response = await fetch('YOUR_AUTH0_ENDPOINT', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer YOUR_AUTH0_API_TOKEN'
          },
          body: JSON.stringify({
            email: entity.adminEmail,
            name: entity.adminName,
            password: 'TEMPORARY_PASSWORD', // You might want to generate this or have the user set it
            connection: 'YOUR_AUTH0_CONNECTION',
            user_metadata: {
              companyName: entity.companyName,
              entityId: entity.id
            }
          })
        });

        if (!response.ok) {
          throw new Error('Failed to create Auth0 user');
        }

        // You might want to send a password reset email here
      } catch (error) {
        console.error('Error creating Auth0 user:', error);
        throw error;
      }
    }
  },
  async created(){
    await ContextLogic.initContext(this);
  }
}
</script>

<style scoped>
.onboarding {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}
</style>