import * as THREE from 'three';
import ColumnBase from './ColumnBase.js'
import {CORE} from '../_spec.js'
import { Vector3 } from 'three';
import CollisionHelper from '../helpers/CollisionHelper.js';
import MemHelper from '../helpers/memHelper.js';
import layerHelper from '../helpers/layerHelper.js'
import materialHelper from '../helpers/materialHelper.js';
export default class ColumnSquare extends ColumnBase{
	constructor(length, material, dim = 4, layer){		
		// this is a 4"x4" square tube
		super(undefined, new Vector3(), new Vector3(), undefined);
		this.length = length;
		this.group.name = 'vertical square column';
		this.material = material;
		this.dim = dim;
		this.layer = layer;
		this.build();
    }

    build(){
		// draw a beam

        var geoSide = new THREE.BoxGeometry(this.dim, this.length, this.dim);
		geoSide.name = 'column square'
		var mesh = new THREE.Mesh( geoSide, this.material);
		mesh.name = 'columnSquare'
		mesh.position.y=this.length/2;
		this.group.add(mesh);
		
		layerHelper.setGroup(this.group, this.layer);
	}
}
