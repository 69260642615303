import { CORE, impactTypes } from '../_spec';
import * as THREE from 'three';
import BayBase from './BayBase.js';
import vHelper from '../helpers/vHelper.js'
import { Color, Quaternion, Vector3 } from 'three';
import BlueprintHelper from '../helpers/blueprintHelper';
import ComponentHelper from '../helpers/featureHelper.js'

import _3dColumnC from '../3d/ColumnC.js';
import layerHelper from '../helpers/layerHelper';
import Tri from '../3d/Tri';
import materialHelper from '../helpers/materialHelper';
import Sheeting from '../3d/Sheeting';
import SheetingHelper from '../helpers/SheetingHelper';
import EarcutDataManager from '../helpers/EarcutDataManager';
import MemHelper from '../helpers/memHelper';
import Wall_Base from './Wall_Base';

export default class Wall_Side extends Wall_Base {
    constructor(
        masterDesign,
        des, 
        structureConfig,
        length, 
        maxHeight, 
        facePosZ, 
        trimMaterials, 
        girtHeights, 
        frame, 
        supportsWainscot, 
        footerCollisions, 
        addToQuoteLayer,
        allowDripTrim,
        insulation,
        beamColor, 
        girtColor,
        options) {
        super(masterDesign,des, structureConfig,length, maxHeight, facePosZ, trimMaterials, undefined, supportsWainscot, footerCollisions, addToQuoteLayer,allowDripTrim,insulation,
            undefined, // undefined is used in WallBase and shouldn't be and this doesn't matter for sidewalls
            beamColor,
            girtColor,
            options
            );
        this.length=length;
        this.wallMid = this.length/2;
        this.frame = frame;
        this.frameLines= frame.frameLines;

        if(!this.buildFacingPositiveZ)
            pos.z-=this.wallThickness; // wall thickness (extrusion depth/amount)

        //this.group.position.copy(pos);
        //this.group.rotateY(rot);
        // get framepoints

        this.girtHeights = girtHeights;
        // shift to match previous point 
        this.beamColor = beamColor
        this.girtColor = girtColor;
    }


    migrate(design){
        super.migrate(design);

        switch(design.v)
        {
            case 1:
                //this.migrateTo2(dresign);
                break;
        }
    }
    
    migrateTo2(design){
        //super.migrateTo2(design);
        design.components.forEach((c)=>{
            c.pos.z=0;
        });
        design.v=2;
    }

    buildDynamicOnly(subComponentsToBuild, buildOpenings){
        super.buildDynamicOnly(subComponentsToBuild, buildOpenings);
        this.buildColumns();
    }

    removeDynamicOnly(){
        super.removeDynamicOnly();
        this.removeColumns();
    }


    buildColumns(){
        let subcomponents = this.design.getWallFramedOpenings();

        if(subcomponents.length>0){
            // HangarDoors will use bays for side columns
            // wide openings don't have side columns
            //this.buildSubComponentColumns(subcomponents);
        }
    }

    removeColumns(){

        let columnsGroup = this.getColumnsGroup();
        if (columnsGroup)
            MemHelper.removeAllChildren(columnsGroup);
        
    }


    getWallRightPosition(){
        return this.length;
    }

    getWallLeftPosition(){
        return 0
    }


    //happens to be the same for front and back.
    positionChildAt(design, worldPosition){
        // get this worldPosition in terms of this parent component
        let wld = new Vector3().copy(worldPosition);
        let rel = this.getRelativePosition(wld)
        // then use a static method that accepts the design to constrain the position to exactly fixed header height
        let compType = ComponentHelper.getClassOfComponentType(design.type);
        return compType.updatePosition(rel, design);
    }

    build(){
        //if(this.design.openWall===true)
            //return;        

        super.build(); // core wall 
    }
    
    defaultDesign(){
        return {
            collided : false,
            pos : new THREE.Vector3(),            
            parent: {                
                id: '0'
            },
            type: CORE.components.fsw,            
            selected:false,
            wainscot:{
                enabled: true,
                height: 36,
                color: '0x8f8f8f'
            },
            openHeight:0
        }
    }

    static canEdit(){
        return true;
    }
    
    getCollisionClass(){
        return [CORE.collisions.classes.none];
    }

    remove(){
        super.remove();
        this.removeColumns();        
    }

    getTypeDisplayName(){return this.getDescription();}
}