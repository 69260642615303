import * as THREE from 'three';
import _3dBase from './Base.js'
import {CORE} from '../_spec.js'
import { Vector3 } from 'three';
import CollisionHelper from '../helpers/CollisionHelper.js';

/*
ColumnBase 
	interface
		bottom point, height
	purpose
		collisionZone

SubTypes:
	ColumnMainStraight
		bottom point, height, width
	purpose
		appearance

	ColumnMainTapered
		bottom point, height, width
	purpose
		appearance

	ColumnWall
		bottom point, height
	purpose
		appearance
*/

export default class ColumnBase extends _3dBase{
	constructor(lod, bottomPos, topPos, color){
		super(undefined, undefined, undefined, lod)

		this.bottomPos = bottomPos;		
		this.topPos = topPos
		this.color= color;
	}

	getCollisionClasses(){
		return [
			CORE.collisions.classes.window,		
			CORE.collisions.classes.emptyFramedOpening,
			CORE.collisions.classes.louverVent,		
			CORE.collisions.classes.doorWalkin3o7o,
			CORE.collisions.classes.doorWalkin4o7o,
			CORE.collisions.classes.doorWalkin6o7o,
			CORE.collisions.classes.doorRollup,
			CORE.collisions.classes.doorSliding,
			CORE.collisions.classes.doorCommGlass,
			CORE.collisions.classes.doorSectional,
			CORE.collisions.classes.doorHangar,
			CORE.collisions.classes.wideOpening,
		];
	}
}