import { faEllipsisStroke } from '@fortawesome/pro-duotone-svg-icons';
import {CORE} from '../_spec.js';


export default class BpFrameLine {
    static generate(lineType, 
        dim, 
        frameDimY, 
        frameType, 
        pitchAngle, // this is sent as negative for sloped
        roofType, 
        pitchDimY, 
        pitchedPurlinDimY, 
        frameWidth, 
        pitchRatio, 
        roofFrameType){
            //console.log('generate', frame)
        let result = {
            lineType: lineType,
            materialDim: dim,
            rafterDepthAtColumn: dim.depth,
            
            columnDepth: dim.depth,
            halfColumnDepth: dim.depth/2, 
        }

        let rafterDepthAtColumn;
        if(dim.shape === CORE.frame.shapes.straight)
            rafterDepthAtColumn = dim.depth; // this is not a purely vertical distance
        else
            rafterDepthAtColumn = dim.depthHaunch; // this is not a purely vertical distance

        result.rafterDepthAtColumn = rafterDepthAtColumn;
        
        // we also need the purely vertical distance between the eave and the top of the column
        // this distance is impacted by column depth, rafter depth, roof pitch, frame type, and frame shape

        if(dim.shape ===CORE.frame.shapes.straight){
            // rafterDepthAtColumn should only grow, regardless of pitchAngle sign
            result.rafterDimYAtColumn = rafterDepthAtColumn / Math.cos(pitchAngle) ; // pure vertical distance at column
            console.assert(result.rafterDimYAtColumn >= rafterDepthAtColumn);
            result.rafterDepthAtPeak = rafterDepthAtColumn;
            result.rafterDimYAtPeak = result.rafterDimYAtColumn;
            if(frameType ===CORE.frame.types.weld || frameType ===CORE.frame.types.weldPlus){
                if(roofFrameType === CORE.roof.frameTypes.bypass)
                {
                    // straight rafter / weld / bypass 
                    result.columnTopDistYBelowEave = result.rafterDimYAtColumn;
                    result.yPosFrontColumnAndRafter = frameDimY-(pitchedPurlinDimY+result.columnTopDistYBelowEave);
                }
                else{
                    // straight rafter / weld / flush 
                    result.columnTopDistYBelowEave = (result.rafterDimYAtColumn - pitchedPurlinDimY);
                    result.yPosFrontColumnAndRafter = frameDimY-result.rafterDimYAtColumn;
                }
            }
            else
            { // otherwise, bolt
                // for standards
                //for mainframes
                let transitionHeight = (dim.depth * Math.tan(pitchAngle)); // bolted main has a tall transition piece (depth*tan(angle) at outside)
                //console.assert(transitionHeight >= 0);
                if(roofFrameType === CORE.roof.frameTypes.bypass)
                {
                    // straight rafter / bolt / bypass 
                    if(lineType === CORE.frame.lineTypes.main){
                        // straight rafter / bolt / bypass / mainframe
                        result.columnTopDistYBelowEave = result.rafterDimYAtColumn - transitionHeight; // bolted main has a tall transition piece (depth*tan(angle) at outside)
                    }
                    else if(lineType === CORE.frame.lineTypes.standard){
                        // straight rafter / bolt / bypass / standard
                        result.columnTopDistYBelowEave = result.rafterDimYAtColumn; // bolted standard has a sliver transition piece (0 at outside)
                    }
                    else if(lineType === CORE.frame.lineTypes.postAndBeam){
                        // straight rafter / bolt / bypass / postAndBeam
                        result.columnTopDistYBelowEave = result.rafterDimYAtColumn - transitionHeight; //
                    }
                    result.yPosFrontColumnAndRafter =  frameDimY-(pitchedPurlinDimY+result.columnTopDistYBelowEave);
                }else{
                    // straight rafter / bolt / flush 
                    if(lineType === CORE.frame.lineTypes.main){
                        // straight rafter / bolt / flush / mainframe
                        result.columnTopDistYBelowEave = (result.rafterDimYAtColumn - pitchedPurlinDimY) - transitionHeight; // bolted main has a tall transition piece (depth*tan(angle) at outside)
                    }
                    else if(lineType === CORE.frame.lineTypes.standard){
                        // straight rafter / bolt / flush / standard
                        result.columnTopDistYBelowEave = (result.rafterDimYAtColumn - pitchedPurlinDimY); // bolted standard has a sliver transition piece (0 at outside)
                    }
                    else if(lineType === CORE.frame.lineTypes.postAndBeam){
                        // straight rafter / bolt / flush / postAndBeam
                        result.columnTopDistYBelowEave = (result.rafterDimYAtColumn - pitchedPurlinDimY) - transitionHeight; //
                    }
                    result.yPosFrontColumnAndRafter =  frameDimY-(pitchedPurlinDimY+result.columnTopDistYBelowEave);

                }
            }
        }
        else // otherwise, tapered
        {
            let insideBottomAngle = (Math.PI/2) - pitchAngle; // transition piece
            let halfInsideBottomAngle = insideBottomAngle/2; 
            result.rafterDepthAtPeak = dim.depthPeak;
            result.rafterDimYAtPeak =  result.rafterDepthAtPeak / Math.cos(pitchAngle);
            result.rafterDimYAtColumn = rafterDepthAtColumn / Math.cos(pitchAngle); // pure vertical distance at column
        
            if(roofFrameType === CORE.roof.frameTypes.bypass){
                // tapered / bypass
                result.columnTopDistYBelowEave = result.rafterDimYAtColumn - (dim.depthHaunch * Math.tan(pitchAngle)); // like bolt-up
                result.yPosFrontColumnAndRafter =  frameDimY-(pitchedPurlinDimY+result.columnTopDistYBelowEave);
            }
            else{
                // tapered / flush
                result.columnTopDistYBelowEave = result.rafterDimYAtColumn - (dim.depthHaunch * Math.tan(pitchAngle)); // like bolt-up
                result.yPosFrontColumnAndRafter =  frameDimY-(result.columnTopDistYBelowEave);
            }            
        }

        if(roofFrameType === CORE.roof.frameTypes.bypass)
        {
            // bypass
            result.yPosColumnAndRafterPeak =  frameDimY+ pitchDimY -pitchedPurlinDimY-result.rafterDimYAtPeak;
        }
        else{
            // flush
            result.yPosColumnAndRafterPeak =  frameDimY+ pitchDimY -result.rafterDimYAtPeak;
        }
        
        if(roofType === CORE.roof.types.gable){
            result.yPosBackColumnAndRafter = result.yPosFrontColumnAndRafter;            
        }
        else
        { //sloped
            if(frameType === CORE.frame.types.weld || frameType ===CORE.frame.types.weldPlus)            
                result.yPosBackColumnAndRafter = result.yPosFrontColumnAndRafter + pitchDimY;
            else{ // bolt
                let depth;
                if(dim.shape === CORE.frame.shapes.straight)
                    depth = dim.depth;
                else
                    depth = dim.depthHaunch;
                
                    let margin = 1;// 1 inch margin on front and back, between wall and frame.
                
                // calculate the vertical distance between low and high side for columns (without considering column depths)
                let slopedTaperPitchDimY_Columns = ((frameWidth - (2*margin) - (2*depth)) * pitchRatio)  
                // apply the vertical distance offset 
                result.yPosBackColumnAndRafter = result.yPosFrontColumnAndRafter + slopedTaperPitchDimY_Columns; // this is a point
                // calculate the vertical distance between low and high side for the single rafter (while considering both front and back column depths)
                let slopedTaperPitchDimY_Rafter = (frameWidth - (2*margin) - (2*depth)) * pitchRatio;  
                // apply the vertical distance offset 
                result.yPosColumnAndRafterPeak = result.yPosFrontColumnAndRafter + slopedTaperPitchDimY_Rafter; // this is a point under the high end of the rafter.
            }                
        }

        return result;
    }
}