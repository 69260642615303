import _3dBase from './Base.js'
import * as THREE from 'three';
import {Vector2,  Vector3 } from 'three';
import MemHelper from '../helpers/memHelper.js';
import ShapeHelper from '../helpers/shapeHelper.js'
import { CORE } from '../_spec.js'
import _3dGirtHoriC from './GirtHoriC.js'
import materialHelper from '../helpers/materialHelper.js';

export default class Cupola extends _3dBase{

    constructor(lod, size, panelColor, trimColor, roofColor){
        super(undefined, undefined, undefined, lod)

        this.build(size, panelColor, trimColor, roofColor)
    }

    build(size, panelColor, trimColor, roofColor) {

        //size = size * 30;
        let panelThickness = .1;
        //let mRailTop = new THREE.Mesh(geoRail, material)

        // panel 1
        let panel1 = this.buildCupolaSide(size, panelColor, trimColor);
        panel1.rotateY(Math.PI);
        panel1.position.z = -size/2;
        this.group.add(panel1);
        
        let panel2 = this.buildCupolaSide(size, panelColor, trimColor);
        panel2.position.z = size/2;
        this.group.add(panel2);

        let panel3 = this.buildCupolaSide(size, panelColor, trimColor);
        panel3.rotateY(3/2*Math.PI);
        panel3.position.x = -size/2;
        this.group.add(panel3);

        let panel4 = this.buildCupolaSide(size, panelColor, trimColor);
        panel4.rotateY(Math.PI/2);
        panel4.position.x = size/2;
        this.group.add(panel4);
        this.group.name = '3d cupola'


        let halfSize = size/2
        let hypot = halfSize*Math.SQRT2

        // roof
        let geoRoof = new THREE.CylinderGeometry(0, hypot, halfSize, 4, 1)

        let matRoofPbr = materialHelper.getPanelPbrMaterial(roofColor, halfSize, THREE.FrontSide);
        matRoofPbr.map.repeat.set(4*size/36,1);
        //matRoofPbr.map.rotation = Math.PI
        //matRoofPbr.map.rotation = -5/128 * Math.PI;
        //matRoofPbr.map.offset.set(1,1)
        //matRoofPbr.map.rotation = -Math.atan(halfSize/hypot); // 90 from wrong
        //matRoofPbr.map.rotation = Math.atan(((size/2+1)*Math.SQRT2)/(size/2)); // 90 from wrong
        
        //matRoofPbr.map.rotation = 0;
        matRoofPbr.map.rotation = 123/128 * Math.PI; // 123/128 = 
        
        //matRoofPbr.metalness = .7;
        let matRoofBasic = new THREE.MeshBasicMaterial({ color: roofColor });
        let matRoofTrim = materialHelper.getTrimMaterial(roofColor);
        
        let meshRoof = new THREE.Mesh(geoRoof, matRoofPbr);
        meshRoof.position.y = Math.SQRT2*size/2+2;
        meshRoof.rotateY(Math.PI/4);
        
        
        this.group.add(meshRoof);
        
        this.group.name = 'cupola graphic'
    }

    
    buildCupolaSide(width, panelColor, trimColor) {

        let side = new THREE.Group();
        
        let panelThickness = .1;
        var geoSide = new THREE.BoxGeometry(width-.01, width-.01, panelThickness);   //PlaneBufferGeometry
        geoSide.name = '3d cupola'
        
        let sideMaterial = materialHelper.getExteriorPanelPbrMaterial(panelColor, width-.01);
        sideMaterial.map.repeat.set(width/36,1);
        
        //let mRailTop = new THREE.Mesh(geoRail, material)

        // panel 1
        let sidePanel = new THREE.Mesh(geoSide, sideMaterial);
        side.add(sidePanel);
        
        let dim = new THREE.Vector3(width, width, 1); // trim a square area
        let dimTrimMaterial = new THREE.Vector3(2,2,1.5);
        // border trim
        let matRails = materialHelper.getTrimMaterial(trimColor);        
        let geoTopRail = new THREE.BoxGeometry(width+dimTrimMaterial.x, dimTrimMaterial.y, dimTrimMaterial.z);
        let topRail = new THREE.Mesh(geoTopRail, matRails);
        topRail.position.y = width/2;
        side.add(topRail);

        let geoBottomRail = new THREE.BoxGeometry(width + dimTrimMaterial.x, dimTrimMaterial.y, dimTrimMaterial.z);
        let bottomRail = new THREE.Mesh(geoBottomRail, matRails);
        bottomRail.position.y = -width/2
        side.add(bottomRail);
        
        let geoLeft = new THREE.BoxGeometry(width+dimTrimMaterial.x, dimTrimMaterial.y, dimTrimMaterial.z);
        let leftRail = new THREE.Mesh(geoLeft, matRails);
        leftRail.position.x = -width/2;
        leftRail.rotateZ(Math.PI/2);
        side.add(leftRail);
        
        let geoRight = new THREE.BoxGeometry(width+dimTrimMaterial.x, dimTrimMaterial.y, dimTrimMaterial.z);
        let rightRail = new THREE.Mesh(geoRight, matRails);
        rightRail.position.x = width/2;
        rightRail.rotateZ(Math.PI/2);
        side.add(rightRail);

        // cross trim measurements
        let angle = Math.PI/4;
        let crossTrimLength = width*Math.SQRT2-dimTrimMaterial.y-.03;
        let crossHeight = width*Math.tan(angle);
        let topRailY = crossHeight - width/2 - dimTrimMaterial.y;
        let crossY = crossHeight/2 - width/2;

        // cross trims
        let geoCross1 = new THREE.BoxGeometry(crossTrimLength, dimTrimMaterial.y, dimTrimMaterial.z);
        let matCross1 = materialHelper.getTrimMaterial(trimColor);
        geoCross1.rotateZ(angle)
        let meshCross1 = new THREE.Mesh(geoCross1, matCross1)
        meshCross1.name = 'trim cross 1'
        meshCross1.position.y= crossY;
        side.add(meshCross1)

        let geoCross2 = new THREE.BoxGeometry(crossTrimLength, dimTrimMaterial.y, dimTrimMaterial.z);
        geoCross2.rotateZ(-angle)
        let meshCross2 = new THREE.Mesh(geoCross2, matCross1)
        meshCross2.name = 'trim cross 2'
        meshCross2.position.y= crossY;
        side.add(meshCross2)

        // cupola base
        let baseDimTrimMaterial = new THREE.Vector3(2, width/2, 4)
        let geoBase = new THREE.BoxGeometry(width + 4, width/2, 4);
        let matBase = materialHelper.getTrimMaterial(trimColor);
        let base = new THREE.Mesh(geoBase, matBase);
        base.position.y = -3/4 * width
        side.add(base);

        return side;
    }
}