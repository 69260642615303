import * as THREE from 'three';
import { Vector3 } from 'three';
import _3dDistHori from '../3d/DistHori.js';
import _3dLouverVent from '../3d/LouverVent.js';
import _3dTrimOpeningFace from '../3d/TrimOpeningFace.js'
import { CORE, impactTypes, rebuildTypes } from '../_spec.js';
import CollisionHelper from '../helpers/CollisionHelper.js';
import UpdateHelper from '../helpers/UpdateHelper.js';
import mathHelper from '../helpers/mathHelper.js';
import MemHelper from '../helpers/memHelper.js';
import OptionHelper from '../helpers/optionHelper.js';
import cOpening from './Opening.js';
import Grabject from './grabject.js';
import materialHelper from '../helpers/materialHelper.js';
import layerHelper from '../helpers/layerHelper.js';
import TreeNode from '../helpers/TreeNode.js';
export default class LouverVent extends cOpening
{
    constructor(design) {        
        
        super(design);

        if(this.design.dir) 
            delete this.design.dir;
        
        this.migrateGirtHeight(this.design);
        
        if(!this.design.fixedHeader)
            this.design.fixedHeader={
                height: 7.3333,
                enabled:true
            }

    }

    migrateGirtHeight(design) {
        // girt location changed from using 7.34 to 7.3333
        if (design.fixedHeader && design.fixedHeader == 7.34) {
            design.fixedHeader == 7.3333;
        }
    }

    initRebuildHierarchy(){        
        this.rebuildTypeRoot = new TreeNode(null, rebuildTypes.full); // size is lumped in here
        this.rebuildTypeRoot.addChildNode(rebuildTypes.move);
    }

    initParent(parent){
        this.parent = parent; // louver vent needs to know the parent because a parent wall may be built normal or inverted (two modes for the sake of debugging math more easily)
        this.rot = parent.openingRot(); // the inverted walls are not rotated into place, but built in place, therefore, openings on the walls must be rotated
    }

    processImpactFromSelf(impact){

        impact.handled.push(this.design.id);
        switch(impact.change.name)
        {
            case impactTypes.louverVentDetail:
            case impactTypes.openingResize:
                this.addRebuildNeeded(rebuildTypes.full);
                break;            
            case impactTypes.openingPosition:
                this.addRebuildNeeded(rebuildTypes.move);
                break;
        }
    }
    
    detectImpact(impact){
        
        if(this.shouldProcessOwnImpact(impact, this.design.id))
            this.processImpactFromSelf(impact);
    }

    getOptionsSpec(){

        let options= [
            OptionHelper.numericUpDown("dim.width","Side Length",impactTypes.openingResize, "in.", 20, 100, 1, true,
            ()=>{
                return this.design.dim.width
            },  // fnIn
            undefined,
            (v)=>{//fnChanged
                let oldDim = this.design.dim.height;
                this.design.dim.width = v;
                this.design.dim.height = v;
                if(this.design.fixedHeader.enabled)
                    this.design.pos.y = (this.design.fixedHeader.height)-(this.design.dim.height/2);
                else
                    this.design.pos.y = (this.design.pos.y+oldDim/2)-(this.design.dim.height/2);
            }),
            OptionHelper.checkbox("fixedHeader.enabled", "Fix Header", impactTypes.openingPosition, 
            true,
            undefined,
            undefined,
            (v)=>{
                this.design.fixedHeader.enabled=v;
                if(this.design.fixedHeader.enabled)
                    this.design.pos.y = (this.design.fixedHeader.height*12)-(this.design.dim.height/2);
            }),
            OptionHelper.numericUpDown("fixedHeader.height","Fixed Header Height", impactTypes.openingPosition, "ft.", 4, 20, .5,
            true,
            undefined,
            undefined,
            (v)=>{ // fnChange
                this.design.fixedHeader.height = v;
                if(this.design.fixedHeader.enabled)
                    this.design.pos.y = (this.design.fixedHeader.height*12)-(this.design.dim.height/2);
            }),

            OptionHelper.checkbox("girtToGirt", "Girt To Girt Connection", impactTypes.openingPosition, true),
            
            OptionHelper.checkbox("exhaustFan", "Exhaust Fan", impactTypes.louverVentDetail, true)
        ]
        return options;
    }

    getOutlineMeshes(){
        return [this.outlineMesh];
    }

    applyDesign(design){
        this.design.collided= design.collided,
        this.design.pos = new Vector3( design.pos.x, design.pos.y, design.pos.z),
        this.design.type = design.type,
        this.design.parent = {};
        this.design.parent.id = design.parent.id;
        this.design.dim= {
            width: design.dim.width,
            height: design.dim.height
        };
        this.design.fixedHeader={
            enabled: design.fixedHeader.enabled,
            height: design.fixedHeader.height            
        } 
        this.design.style = design.style;
        this.design.girtToGirt = design.girtToGirt;
        this.design.selected= false;
        this.addRebuildNeeded(rebuildTypes.full);
    }
    
    defaultDesign(sideLength, exhaustFanPreference){
        if(!sideLength)sideLength = 36;
        return {
            collided : false,
            pos : new Vector3(),
            parent: {
                type: 'frontSide'
            },
            type: CORE.components.louverVent,
            id: -1,
            girtToGirt: true,
            dim: {
                width: sideLength,
                height: sideLength
            },
            fixedHeader:{
                height: 9,
                enabled: true
            },
            selected:false,
            exhaustFan: exhaustFanPreference
        }
    }
    
    static canEdit(){
        return true;
    }
    static canDelete(){
        return true;
    }
    static canCopy(){
        return true;
    }
    
    
    getInitialGrabjectUserData(){
        let grabData = super.getInitialGrabjectUserData();
        grabData.allowedParentComponentTypes = [
            CORE.components.bayEndLeft,
            CORE.components.bayEndRight,
            CORE.components.baySideBack,
            CORE.components.baySideFront
            //CORE.components.wallSide
        ]
        return grabData;
    }    

    static parentComponentTypes(){
        // used when moving a grabject to get a list of component types this feature can land on
        return [
            CORE.components.bayEndLeft,
            CORE.components.bayEndRight,
            CORE.components.baySideBack,
            CORE.components.baySideFront
        ]
    }
    
    static updatePosX(design, x){
        if(design.pos.x === x)
            return false;
        design.pos.x = x;
        return true;
    }

    static updatePosY(design, y){
        if(design.fixedHeader.enabled)
            y = (design.fixedHeader.height*12)-(design.dim.height/2);

        if(design.pos.y === y)
            return false;
        design.pos.y = y;
        return true;
    }

    updatePosition(center)
    {
        if(!LouverVent.updatePosition(center, this.design))
            return; 
        
        // move requires no rebuild (except on re-parentage)
        return UpdateHelper.createImpact(this.design,impactTypes.openingPosition);
    }

    static updatePosition(center, design){
        let affected = false;
        affected = LouverVent.updatePosX(design, center.x) || affected;
        affected = LouverVent.updatePosY(design, center.y) || affected; 

        if(!affected)
        {
            //console.log('no effect')
        }
        return affected;
    }

    updateWidth(w){
        if(w<12) // about as small as a louver can get before grabjects run together
            return false; // signal that we can't go any smaller
        if(w>300)
            return false;
        this.design.dim.width=w;
        
        return true;
    }

    left(){
        let left=-this.design.dim.width/2;
        if(this.parent.isInverted())
            left*=-1;
        return this.design.pos.x + left;
    }

    right(){
        let right=this.design.dim.width/2;
        if(this.parent.isInverted())
            right*=-1;
        return this.design.pos.x + right;
    }

    grabjectChangeLeft(newVal){
        // math is written based on positive-Z facing louver, where:
        // left grabject moved to be left implies change < 0)
        // left grabject moved to the right implies change > 0
        // 
        // when flipped
        // left grabject moved to the left implies change > 0
        // left grabject moved to the right implies change < 0
        let change = newVal - this.left(); // change may be positive or negative because left does not necessarily mean a lower X value, since BSW and REW louvers are flipped

        if(change===0)
            return;
        let widthChange = change;
        if(this.parent.isInverted())
            widthChange*=-1;
        // apply the full, positive-only change to the width
        if(!this.updateWidth(this.design.dim.width-widthChange))
            return;
        // apply half of the change (+ or -) to the position
        LouverVent.updatePosX(this.design, this.design.pos.x + change/2);
        return true;
    }

    grabjectChangeRight(newVal){
        let change = newVal - this.right(); // change may be positive or negative because left does not necessarily mean a lower X value, since BSW and REW louvers are flipped
        if(change===0)
            return;
        let widthChange = change;
        if(this.parent.isInverted())
            widthChange*=-1;
        // apply the full, positive-only change to the width
        if(!this.updateWidth(this.design.dim.width+widthChange))
            return;        
        // apply half of the change (+ or -) to the position
        LouverVent.updatePosX(this.design, this.design.pos.x + change/2);
        return true;
    }

    grabjectChangeBottom(halfHeight, newBottom, posY){
        let oldTop = posY+halfHeight;
        let newHeight = oldTop - newBottom;
        if(!this.updateHeight(newHeight))
            return; 
        this.design.pos.y = oldTop - this.design.dim.height/2;                
        return true;
    }

    updateHeight(h){
        if(h<12) // about as small as an it can get before grabjects run together
            return false; // signal that we can't go any smaller
        if(h>120)
            return false;
        this.design.dim.height=h;
        return true;
    }

    grabjectChangeTop(halfHeight, newTop, posY){
        if(this.design.fixedHeader.enabled)
            return;
        let oldBottom = posY-halfHeight;
        let newHeight = newTop - oldBottom;
        if(!this.updateHeight(newHeight))
            return; 
        this.design.pos.y = newTop - this.design.dim.height/2;                
        return true;
    }

    
    show(v){
        this.visible=v;
        this.group.visible = this.visible;
    }

    // required by object interface
    getContextMenuPosition(){        
        // this returns the position of the contextMenuObject (created in build function) 
        // in terms of world position (considering all object groups, and transforms)
        return this.contextMenuObject.getWorldPosition(new Vector3());
    }

    getTopRightCorner(){
         let offset = new THREE.Vector3(1.3*this.design.dim.width/2,
                                        .8*this.design.dim.height/2,
                                        0);
         return offset;
    }
    // required by object interface
    getDescription(){

        //return `LouverVent ${this.design.dim.width.toFixed()}" x ${this.design.dim.height.toFixed()}"`;
        return `Louvered Vent ${this.design.dim.width}" x  ${this.design.dim.height}"` ;
    }
   
    // required by object interface
    getCollisionClass(){
        return CORE.collisions.classes.louverVent;
    }
    // required by object interface
    getCollisionZones() {
        return [this.zone];
    }

    setGrabjectTransparency(transparent){


        if(this.grabjectGroup){
            // if(transparent)
            //     console.log('made transparent')
            // else
            //     console.log('made opaque')
            this.grabjectGroup.children.forEach((g)=>{
                g.material.transparent = transparent;            
                if(transparent)
                    g.material.opacity= .5;
                    else
                    g.material.opacity= 1;

            })
        }
    }

    build() {
        
        // dim here is the size of the opening.        
        let dim = new THREE.Vector3(this.design.dim.width, this.design.dim.height, 1);
        this.group.position.copy(this.design.pos);

         // trim is a bit wider and taller than the opening
         let dimTrimMaterial = new THREE.Vector3(2,2,1.5);
         let trimMaterial = this.structureConfig.trimMaterials.walksAndWindows;
         let trim = new _3dTrimOpeningFace(CORE.preferences.des_levelOfDetail.value, dim, dimTrimMaterial, trimMaterial, true);
         //layerHelper.setGroup(trim.group, CORE.layers.quote);
         layerHelper.enableLayer(trim.group, CORE.layers.quote)
         this.group.add(trim.group);

        let louverVent = new _3dLouverVent(CORE.preferences.des_levelOfDetail, this.group, dim.x, CORE.materials.stainless, this.design.exhaustFan);
        layerHelper.setGroup(louverVent.group, CORE.layers.windows)
        this.group.add(louverVent.group);

        this.pickSize = new THREE.Vector3(dim.x, dim.y, 1.5);

        
            var geoPick = new THREE.BoxGeometry(this.pickSize.x, this.pickSize.y, this.pickSize.z);
            geoPick.name = 'louver picking'
            this.pickDetectionMesh = new THREE.Mesh(geoPick, CORE.materials.transparent);
            this.pickDetectionMesh.name=`pickMesh for louver ${this.design.id}`;
            this.pickDetectionMesh.visible=false;
            this.pickDetectionMesh.castShadow=false;
            this.pickDetectionMesh.receiveShadow=false;
            this.pickDetectionMesh.renderOrder =1;
            this.pickDetectionMesh.position.z +=2;
        
        this.pickDetectionMesh.userData = {
            type: this.design.type, // not sure this is necessary. copied from wallBase.
            id: this.design.id
        };
        this.outlineMesh = this.pickDetectionMesh;

        this.contextMenuObject = new THREE.Mesh()
        let trc = this.getTopRightCorner();
        this.contextMenuObject.position.set(trc.x, trc.y, trc.z);
        this.contextMenuObject.visible = false; // this 
        
        this.group.add(this.pickDetectionMesh)
        this.group.add(this.contextMenuObject);        
        this.group.visible = this.visible;
        
        this.buildDimensions();
        this.buildGrabjects();
        this.showGrabjects(this.design.selected);
        
        
        this.group.rotation.y = this.rot; 
        
        this.setCollisionZone();
        
        this.built=true;
    }

    buildDimensions(){
        let width = new _3dDistHori(new THREE.Vector3(-this.design.dim.width/2,this.design.dim.height/4,10),new THREE.Vector3(this.design.dim.width/2,this.design.dim.height/4,10), new Vector3(0,0,0), new Vector3(0,0,0), 1, CORE.layers.openingDimensions, new Vector3(20,10,1))
        this.group.add(width.group);
        let height = new _3dDistHori(new THREE.Vector3(this.design.dim.width/4,this.design.dim.height/2,10),new THREE.Vector3(this.design.dim.width/4,-this.design.dim.height/2,10), new Vector3(0,0,0), new Vector3(0,0,0), 1, CORE.layers.openingDimensions, new Vector3(20,10,1))
        this.group.add(height.group);
    }

    setCollisionZone(){

        this.group.updateMatrix();
        //this.group.updateMatrixWorld();
        //console.log(`LouverVent group: ${this.group.uuid} reference pickmesh`)
        this.pickDetectionMesh.updateMatrix();
        //this.pickDetectionMesh.renderOrder=0;
        //this.pickDetectionMesh.depthTest=false;
        this.pickDetectionMesh.updateWorldMatrix(true);
        //let zone = this.getCollisionZoneTemplate(meshPickDetection);
        let margin = CollisionHelper.getMargin(
            CORE.preferences.des_marginWindowEdge.value,
            CORE.preferences.des_marginWindowEdge.value,
            CORE.preferences.des_marginWindowEdge.value,
            CORE.preferences.des_marginWindowEdge.value,0,0);
        this.zone = CollisionHelper.getZone2(
            this.group,
            undefined,
            true,
            [
                CORE.collisions.classes.window,
                CORE.collisions.classes.emptyFramedOpening,
                CORE.collisions.classes.louverVent,
                CORE.collisions.classes.doorWalkin3o7o,
                CORE.collisions.classes.doorWalkin4o7o,
                CORE.collisions.classes.doorWalkin6o7o,
                CORE.collisions.classes.doorRollup,
                CORE.collisions.classes.doorSliding,
                CORE.collisions.classes.doorCommGlass,
                CORE.collisions.classes.doorSectional,
                CORE.collisions.classes.doorHangar,
                CORE.collisions.classes.wideOpening,
            ],
            this.pickSize,
            margin, 
            this.getDescription()
        )
        
        this.zone.data = {
            id:this.design.id,
            type:this.design.type
        };

        this.pickDetectionMesh.userData.collisionZones= [this.zone];
    }
    


    buildGrabjects(){   
        // TODO: this can be applied to other components using grabjects.     
        let grabjectSize = 8;
        let grabjectSizeVector = new THREE.Vector3(grabjectSize, grabjectSize, 5);
        
        this.grabjectGroup = new THREE.Group();
        this.grabjectGroup.name= 'grabjects'
        let parentComponentTypes = [
            CORE.components.bayEndLeft,
            CORE.components.bayEndRight,
            CORE.components.baySideBack,
            CORE.components.baySideFront
        ];
        let dim = new THREE.Vector3(this.design.dim.width, this.design.dim.height, 1);
        let halfDim = dim.clone().multiplyScalar(.5);
        
         let positionIndicators;
        if(this.design.fixedHeader.enabled){
            positionIndicators = [CORE.indicators.leftRight];   
        }
        else
        {
            positionIndicators = [CORE.indicators.upDownLeftRight];
        }
        this.positionGrabject = new Grabject(
            this.grabjectGroup, 
            this.design.id, 
            new THREE.Vector3(0,0,0), 
            positionIndicators, 
            new THREE.Vector3(10, 10, 5), 
            
            CORE.grabjects.position,parentComponentTypes);

        this.grabjectGroup.position.z+=4;
        this.group.add(this.grabjectGroup);
    }


    removeGrabjects(){
        this.group.remove(this.grabjectGroup);
        MemHelper.dispose(this.grabjectGroup);
        delete this.grabjectGroup;
    }

    removePickingObject(){
        if(this.pickDetectionMesh)
            delete this.pickDetectionMesh
    }
    
    remove() {
        this.group.remove(this.pickDetectionMesh);
        this.removeGrabjects(); 
        this.removePickingObject();
        MemHelper.dispose(this.group);
    }
    
    getTypeDisplayName(){
        return "Louvered Vent"
    }
}