<template>
    <tr>
        <td>            
            {{ business.businessName }}
        </td>
        <td style="text-align: left;">
          <select style="" v-model="business.pricingModel" @change="update">
            <option value="component" :key="'component'">Component Pricing</option>
            <option value="costMarkup" :key="'costMarkup'">Cost Markup</option>
            
          </select>

        </td>
        <td>
          <select style="" v-model="business.boltPricingLevel" v-if="business.pricingModel=='costMarkup'" @change="update">
            <!-- <option value=1>Standard</option> -->
            <option value=1 >Wholesale</option>
            <option value=2 >Pro</option>
            <option value=3 >Contractor</option>
            <option value=4 >Retail</option>
          </select>
          <span v-else>N/A</span>
        </td>
        <td>
          <select style="" v-model="business.weldPricingLevel" v-if="business.pricingModel=='costMarkup'" @change="update">
            <!-- <option value=1>Standard</option> -->
            <option value=1 >Wholesale</option>
            <option value=2 >Pro</option>
            <option value=3 >Contractor</option>
            <option value=4 >Retail</option>
          </select>
          <span v-else>N/A</span>
        </td>
      </tr>
  </template>
  
  <script>
  export default {
    name: 'BusinessPricingRecord',
    props: ['pBusiness'],    
    data(){
      return {
        business:{}
      }
    },
    mounted(){
      this.business = this.pBusiness;
      console.log(this.business);
    },
    methods:{
      update(){
        console.log(`update ${this.business.businessId} to ${this.business.pricingModel}`)
        this.$emit('update', this.business)
      }
    }
  };
  </script>
  
<style scoped>
  td, th{
  border:1px solid black;
  padding:5px;
  font-size: medium;
}



</style>