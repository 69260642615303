import { CORE , impactTypes } from '../_spec';
import {Vector3 } from 'three';
import * as THREE from 'three';
import BaySide from './BaySide.js';
import BlueprintHelper from '../helpers/blueprintHelper';
import _3dDistHori from '../3d/DistHori.js'
import SheetingHelper from '../helpers/SheetingHelper';
import materialHelper from '../helpers/materialHelper';
import Wall_Side from './Wall_Side';
import Util from '../utility';
import MemHelper from '../helpers/memHelper.js';
import BaySideBack from './BaySideBack.js';
import DesignHelper from '../helpers/DesignHelper.js';

export default class BSW extends Wall_Side {
    constructor(
        masterDesign,
        des, 
        structureConfig,
        length,
        maxHeight,              
        trimMaterials, 
        girtHeights, 
        frame,
        supportsWainscot, 
        footerCollisions, 
        addToQuoteLayer,
        allowDripTrim,
        insulation,
        structureLength,
        beamColor,
        girtColor,
        options,
        shedHoles
        ) {            
            
        des.type = CORE.components.bsw;
        // generate points in the xy plane, z = 0;
        super(masterDesign,des, structureConfig, length, maxHeight, true, trimMaterials, girtHeights, frame, supportsWainscot, footerCollisions, addToQuoteLayer,allowDripTrim,insulation, beamColor, girtColor, options) ;
        this.wallOpenings = shedHoles
        this.frames = [];        
        this.frame.frameLines.forEach((fl)=> 
          {
            // TODO: remove posX defect
            this.frames.push({posX:fl.posX + structureLength/2,index:fl.index, width:fl.width});
            }
        );

        this.groupBays = new THREE.Group();
        this.groupBays.name = "bays";
        this.group.add(this.groupBays);
        this.build();
    }

    buildBay(bayDesign){
        let index = bayDesign.index;
        
        let start = this.frame.design.columnPositions[(this.frames.length - 2)- index];
        let end = this.frame.design.columnPositions[(this.frames.length - 1)- index];

        // We have to shift the bay length whenever there is an open bay before or after so the sheeting covers the frame
        let bays = this.design.getBackSideBays();
        let previousBay = bays[index+1]
        let thisBay = bays[index]
        let nextBay = bays[index-1]
        if (previousBay === undefined || previousBay.openWall === true)
            start -= 4;
        if (nextBay === undefined || nextBay.openWall === true)
            end += 4;

        let bayLength = end-start;

        let bayHoles = this.convertWallHoleToBayHoles(this.dynamicWallOpenings, start, end);

        let bay = new BaySideBack(bayDesign, 
            this.structureConfig,
            start, 
            end, 
            bayLength,
            this.length,
            this.maxHeight,
            this.trimMaterials, 
            this.girtHeights,
            this.frameLines, 
           
            this.supportsWainscot, 
            this.footerCollisions, 
            this.addToQuoteLayer,
            this.allowDripTrim,
            this.insulation,
            0,        
            length,
            this.beamColor,
            this.girtColor,
            this.options,
            bayHoles
            );
            
            bay.group.position.x = start
            this.groupBays.add(bay.group);
            return bay;
    }
    
    calculateBayDesigns(){
            /* 
            important context for this method:
            bay indices are ascending from wall left to wall right. 
            frameline indices are ascending from building left to building right
            At this moment, the framelines may have just increased or decreased and not align with the number of bays.
             */

            // step 1: remember previous left- and right-most bays
            let bayComponents = this.design.getBackSideBays();
            let rightMostBay, leftMostBay;
            let highestFramelineIndex = (this.frames.length - 2); // this is always ascending building left to building right
            if(bayComponents){
                // determine the highestBayIndex, as this must be the old right-most bay 
                let highestBayIndex = 0;
                bayComponents.forEach((b) =>{
                    if(b.index > highestBayIndex)
                        highestBayIndex = b.index; 
                } );

                rightMostBay = bayComponents.filter((b) => b.index === highestBayIndex);
                if(rightMostBay.length>0)
                    rightMostBay=rightMostBay[0];
                else
                    rightMostBay=undefined;

                
                leftMostBay = bayComponents.filter((b) => b.index === 0);
                if(leftMostBay.length>0)
                    leftMostBay=leftMostBay[0];
                else
                    leftMostBay=undefined;
            }

            // step 2: remove all the bays            
            MemHelper.removeAllChildren(this.groupBays);
            this.components = this.components.filter((c) => c.design.type != CORE.components.baySideBack);
            this.design.components = this.design.components.filter((c) => c.type != CORE.components.baySideBack);
         
            
            // so bays are added from highest bay index (frame.lengths - 2) to lowest (0)
            // step 3: recreate all the bays, transplanting the old left- and right-most bays where appropriate
            this.frames.forEach((fl,framelineIndex)=>{                
                if(framelineIndex == this.frames.length-1)
                    return;
                
                let iBayDesign;   
                if(framelineIndex==0 && rightMostBay){
                    iBayDesign = rightMostBay;
                    iBayDesign.index =  (highestFramelineIndex) - framelineIndex; // re-index this transplanted bay
                }
                else if(framelineIndex===highestFramelineIndex && leftMostBay)
                {
                    iBayDesign = leftMostBay;
                    //iBayDesign.index = i; // re-index this transplanted bay
                }
                else 
                {
                    // when # of bays increase, new bays are added from the building's left most side.
                    // thus at frame line 0, leftMostBay must be newly created.
                    if(framelineIndex==0)
                        iBayDesign=undefined
                    else if(bayComponents){
                        // anything in between the leftMostBay and rightMost bay will already exist
                        // or need to be created depending on the # of bays added at a time.
                        iBayDesign = bayComponents.filter((b) => b.index == (highestFramelineIndex) - framelineIndex) ;// DesignHelper.getBayId(i, this.frames.length)})
                        if(iBayDesign.length>0)
                            iBayDesign=iBayDesign[0];
                        else
                            iBayDesign=undefined;
                    }
                }

                if(Util.isUndefined(iBayDesign)){
                    iBayDesign = DesignHelper.getDefaultBayDesign((highestFramelineIndex) - framelineIndex, this.frames.length, CORE.components.baySideBack);
                    this.SetBayDesignFromWall(this.design, iBayDesign);
                    DesignHelper.addComponent(this.design, iBayDesign)
                } 
                else {                    
                    //this.SetBayDesignFromWall(this.design, iBayDesign); // we can't call SetBayDesignFromWall here because bay designs are customized by the end skirting tool
                    iBayDesign.wainscot = {...this.design.wainscot}
                    this.design.components.push(iBayDesign);
                }
            })
        }

        migrateFramedOpenings(framedOpenings){
            //let framedOpenings = this.design.components.filter((c) => DesignHelper.allowedFramedOpeningsOnBays.includes(c.type));
            let bayDetails = [];
            this.design.getBackSideBays().forEach((bay, index)=>{
                let start = this.frame.design.columnPositions[index];
                let end = this.frame.design.columnPositions[index+1];
                if(index == 0)
                    start -= 4;
                if(index == this.frames.length - 2)
                    end += 4;
                bayDetails.push({id: bay.id, start: start, end: end, openWall: bay.openWall, design:bay});
            })
            
            for(const desFramedOpening of framedOpenings){
                for(const [index, bay] of bayDetails.entries()){
                    if((desFramedOpening.pos.x - desFramedOpening.dim.width/2) >= bay.start && (desFramedOpening.pos.x+desFramedOpening.dim.width/2) <= bay.end && !bay.openWall){
                        desFramedOpening.pos.x -= bay.start;
                        DesignHelper.addComponent(bay.design, desFramedOpening);
                        this.components = this.components.filter((c) => c.design.id !== desFramedOpening.id);
                        this.design.components = this.design.components.filter((c) => c.id !== desFramedOpening.id);
                        break;
                    }
                    else{
                        if(index < bayDetails.length - 1){
                            if((desFramedOpening.pos.x - desFramedOpening.dim.width/2) >= bay.start && (desFramedOpening.pos.x - desFramedOpening.dim.width/2) < bayDetails[index + 1].start && (desFramedOpening.pos.x+desFramedOpening.dim.width/2) >= bay.end && (desFramedOpening.pos.x+desFramedOpening.dim.width/2) < bayDetails[index + 1].end && !bay.openWall){
                                desFramedOpening.pos.x -= bay.start;
                                DesignHelper.addComponent(bay.design, desFramedOpening);
                                this.components = this.components.filter((c) => c.design.id !== desFramedOpening.id);
                                this.design.components = this.design.components.filter((c) => c.id !== desFramedOpening.id);
                                break;
                            }
                        }
                    }
                }
            }
        }

    getDescription(){
            return 'Back Side Wall'
    }    

    getFooterCollisionZoneCenter(){        
        let world = this.getWorldPositionFromRelative(new Vector3(this.wallMid, 0,0))
        return world;
    }

    getComponentDistanceMarkers(comp){

        let des = comp.design;
        //goal 1: build is list of columns with size and position data.
        // sideWalls have column positions only for OH doors

        let low, high, lowColPos,highColPos, offset,lowWinPos,highWinPos;
        let markerMarginZ = 24;
        // use framebase or framebase.frameLines to discover the true size and location of frameLine columns on the sideWall.
        let lowWinX = des.pos.x - des.dim.width/2;
        let highWinX = des.pos.x + des.dim.width/2;
        this.frames.forEach((fl)=>{   
            // TODO: remove posX defect         
            if(fl.posX < lowWinX){
                low = fl;                        
            } else if(fl.posX > highWinX && !high){
                high = fl;
            }
        })

        //console.log(low, high)

        if(!low || !high)
            return;
            // TODO: remove posX defect
        let lowColPosX = low.posX; // center of the column width              

        // if a corner column, measure from the outside
        if(low.index===0)
            lowColPosX-=low.width/2;// outside edge for first frameline
        if(low.index===this.frameLines.length-1)
            lowColPosX+=low.width/2; // outside edge for last frameline
        // TODO: remove posX defect
        let highColPosX = high.posX; // center of the column width
        if(high.index===0)
            highColPosX-=high.width/2; // outside edge for first frameline
        if(high.index===this.frameLines.length-1)
            highColPosX+=high.width/2; // outside edge for last frameline

            
        lowColPos = new THREE.Vector3(lowColPosX, des.pos.y, des.pos.z);
        highColPos = new THREE.Vector3(highColPosX, des.pos.y, des.pos.z);
        offset = new THREE.Vector3(0,0,markerMarginZ);

        lowWinPos = new THREE.Vector3(lowWinX, des.pos.y, des.pos.z);
        highWinPos = new THREE.Vector3(highWinX, des.pos.y, des.pos.z);
    
    

        let newLowDh = new _3dDistHori(lowColPos, lowWinPos, new THREE.Vector3(),  offset, 1, CORE.layers.openingDimensions, new Vector3(20,10,1));
        this.gDynamic.add(newLowDh.group);
        let newHighDh = new _3dDistHori(highColPos, highWinPos, new THREE.Vector3(), offset, 1,  CORE.layers.openingDimensions,new Vector3(20,10,1));
        this.gDynamic.add(newHighDh.group);

        return  { 
            newLowDh,
            newHighDh
        }


    }

}