
import * as THREE from 'three';
import { Vector2, Vector3 } from 'three';
import { CORE, rebuildTypes, impactTypes} from '../_spec.js'
import Opening from './Opening.js'
import CollisionHelper from '../helpers/CollisionHelper';
import vHelper from '../helpers/vHelper.js'

export default class Door extends Opening {
    constructor(design) {
        super(design);
        if(this.design)
        {
            if(this.design.dir) 
                delete this.design.dir;
        }
        
    }
    

    initParent(parent, context){

        this.parent = parent; // window needs to know the parent because a parent wall may be built normal or inverted (two modes for the sake of debugging math more easily)
        this.rot = parent.openingRot(); // the inverted walls are not rotated into place, but built in place, therefore, openings on the walls must be rotated
        this.structureConfig = parent.structureConfig;
    }

    
    processRebuild(type){
        // rebuild the minimal amount of 3d objects for this component
        switch(type){
            case rebuildTypes.full:
                this.rebuildFull();
                break;
            case rebuildTypes.move:
                this.rebuildMove();
                break;
        }
    }

    
    rebuildFull(){
        this.remove();
        this.build();
    }

    rebuildMove(){
        this.group.position.copy(this.design.pos);
        this.setCollisionZone();
    }

    static canEdit(){return true;}    
    static canCopy(){return true;}    
    static canDelete(){return true;}    

    static parentComponentTypes(){
        // used when moving a grabject to get a list of component types this feature can land on
        return [
            CORE.components.bayEndLeft,
            CORE.components.bayEndRight,
            CORE.components.baySideBack,
            CORE.components.baySideFront
        ]
    }
    
    // required by object interface
    show(v){
        this.visible=v;
        this.group.visible = this.visible;
    }
    
    // required by object interface
    getContextMenuPosition(){        
        // this returns the position of the contextMenuObject (created in build function) 
        // in terms of world position (considering all object groups, and transforms)
        return this.contextMenuObject.getWorldPosition(new THREE.Vector3());
    }
    
    getTopRightCorner(){
        let offset = new THREE.Vector3(1.3*this.design.dim.width/2,
                                       .8*this.design.dim.height/2,
                                       0);
        return offset;
    }


    // required by object interface
     styleCollision(colliding) {
        this.colliding = colliding;
        if(colliding)
            this.matGlass.color = new THREE.Color(0xff0000);
    }

    
    // required by object interface
    getCollisionClass(){
        return CORE.collisions.classes.doorWalkin3o7o;
    }

    
    // this is for pending object being placed
    // pending doors should not collide with zone that don't include the door collision class
    getCollisionZones() {
        let zone = this.getCollisionZoneTemplate();
        zone.classes = [
            CORE.collisions.classes.doorWalkin3o7o        
        ];
        zone.data = this.design;
        return [zone];
    }

    getCollisionZoneTemplate(mesh){
        let mw;
        if(mesh){
            //mesh.updateMatrixWorld(true);
            mw=mesh.matrixWorld;
        }            
        else
            mw = this.group.matrixWorld
            
        let zone = CollisionHelper.getZone2(
            this.group,
            undefined,
            true,
            [],
            new Vector3(this.design.dim.width, this.design.dim.height, 1),
            undefined,
            this.getDescription()
            
            
        )
        
        return zone;
    }

    static updatePosX(design, posX){
        if(design.pos.x===posX)
            return false
        design.pos.x = posX;
        return true;
    }
    
    getInitialGrabjectUserData(){
        let grabData = super.getInitialGrabjectUserData();
        grabData.allowedParentComponentTypes = Door.parentComponentTypes();
        return grabData;
    }    

}