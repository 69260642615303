<template>
  <div class="chip" :style="{background:color}" @click="$emit('click', color);" :title="name" :class="{disabled:disabled}">
    <div v-if="nameInChip" class='label' :style="{color:textContrastColor2}">{{name}}</div>
  </div>  
</template>

<script>
import { text } from '@fortawesome/fontawesome-svg-core';
export default {
  props:
      ['color', 'name', 'nameInChip', 'nameInTitle', 'disabled'],
  data: ()=>{
      return {
      };
  },
  computed: {  
      textContrastColor:{
          get(){
              if(!this.color){
                return "#000000";
              }
//            var rgb = this.color
            //var colors = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
            var colors = this.hexToRgb(this.color)
            var brightness = 1;

            //var r = colors[1];
            //var g = colors[2];
            //var b = colors[3];

            var ir = Math.floor((255-colors.r)*brightness);
            var ig = Math.floor((255-colors.g)*brightness);
            var ib = Math.floor((255-colors.b)*brightness);
                let textColor = this.rgbToHex(ir, ig, ib);
            return textColor;
          }
      },
      textContrastColor2:{
          get(){
          let hexcolor = this.color;
	// If a leading # is provided, remove it
	if (hexcolor.slice(0, 1) === '#') {
		hexcolor = hexcolor.slice(1);
	}

	// If a three-character hexcode, make six-character
	if (hexcolor.length === 3) {
		hexcolor = hexcolor.split('').map(function (hex) {
			return hex + hex;
		}).join('');
	}

	// Convert to RGB value
	var r = parseInt(hexcolor.substr(0,2),16);
	var g = parseInt(hexcolor.substr(2,2),16);
	var b = parseInt(hexcolor.substr(4,2),16);

	// Get YIQ ratio
	var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

    let colorName = (yiq >= 128) ? 'black' : 'white';
	// Check contrast
	return colorName
          }
      }   
  },
  mounted(){
      //console.log('chip: ', this.color, this.name)
  },
  methods: { 
    componentToHex(c) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    },
    rgbToHex(r, g, b) {
        let hex = "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
        console.log(hex);
        return hex;
    },
    hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }
  }
}
</script> 

<style scoped>

.chip{    
    border-radius: 4px;
    /* border: 1px solid gray; */
    margin: 2px;
    margin-left: auto;
    margin-right: auto;
    width:120px;
    height:25px;
    box-shadow: 1px 1px 1px 1px #444444;
    text-align:center;
    position: relative;
    white-space: nowrap; 
    cursor: pointer;
}

.label{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    overflow-wrap: normal;
    /* word-break: break-word; */
    font-weight: bolder;
}

.disabled{
    opacity:.5;
}


</style>


