
import DoorWalkin from './DoorWalkin.js'
import * as THREE from 'three';
import { CORE,impactTypes  } from '../_spec.js'
import Grabject from './grabject.js'
import OptionHelper from '../helpers/optionHelper.js'

import _3dDoorWalkin from '../3d/DoorWalkin.js'
import { Vector3 } from 'three';
import layerHelper from '../helpers/layerHelper.js';
export default class DoorWalkin6o7o extends DoorWalkin {
    constructor(design) {
        super(design);
        this.group.name = this.getDescription() + ' Component'
        if(this.design.dir) 
            delete this.design.dir;
    }

    migrate(design){
        design.dim.width = 6*12;
        if(typeof design.halfGlass === 'undefined' || design.halfGlass === null){
            design.halfGlass={
                left: false,
                right: false
            }
        }
    }

    init(design) {
        this.migrate(design);
        this.design = design;
    }

    getOptionsSpec(){
        let opts = super.getOptionsSpec();
        opts.push(...[
            OptionHelper.checkbox("halfGlass.left", "Left Half Glass", impactTypes.walkinDetail, true),
            OptionHelper.checkbox("halfGlass.right", "Right Half Glass", impactTypes.walkinDetail, true)
        ]
        );
        return opts;
    }
    
    defaultDesign(){
        return {
            collided : false,
            pos : new THREE.Vector3(0,(7*12)/2,0),
            parent: {
                type: 'frontSide'
            },
            type: CORE.components.doorWalkin6o7o,
            id: -1,
            dim:{
                width: 6 * 12 ,
                height: 7 * 12 
            },
            halfGlass:{
                left: false,
                right: false
            },
            selected:false
        }
    }

    applyDesign(design){
        super.applyDesign(design);
        this.design.halfGlass = {
            left: design.halfGlass.left,
            right: design.halfGlass.right
        }
    }
    
    getDescription(){
        return `Walkin 6' x 7'`;
    }

    
    build() {
        // use the design to build the component

        let singleDoorWidth = this.design.dim.width/2;
        let singleDoorDim = new THREE.Vector3(singleDoorWidth, this.design.dim.height, 0);
        
        
        let posLeftDoor = new Vector3(-singleDoorWidth/2,0,0)
        let leftDoor = new _3dDoorWalkin(CORE.preferences.des_levelOfDetail.value, this.group, singleDoorDim, this.structureConfig.trimMaterials.walksAndWindows, CORE.materials.stainless, this.design.deadbolt, this.design.halfGlass.left, posLeftDoor, true);
        let groupLeft = leftDoor.group;

        let posRightDoor = new Vector3(singleDoorWidth/2,0,0)
        let rightDoor = new _3dDoorWalkin(CORE.preferences.des_levelOfDetail.value, this.group, singleDoorDim, this.structureConfig.trimMaterials.walksAndWindows, CORE.materials.stainless, false, this.design.halfGlass.right, posRightDoor, false);
        let groupRight = rightDoor.group;
        groupRight.rotation.y = Math.PI;

        
        this.group.position.copy(this.design.pos)
        
        this.group.rotation.y = this.rot;
        this.group.add(groupRight);
        this.group.add(groupLeft);
        this.group.name = this.getDescription();
        
        this.grabjectGroup = new THREE.Group();
        let parentComponentTypes = [
            CORE.components.bayEndLeft,
            CORE.components.bayEndRight,
            CORE.components.baySideBack,
            CORE.components.baySideFront
        ]
        new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(0,0,0), [CORE.indicators.leftRight], new THREE.Vector3(15, 15, 5), CORE.grabjects.position, parentComponentTypes);
        this.group.add(this.grabjectGroup);
        this.grabjectGroup.position.z +=2;
        this.showGrabjects(this.design.selected);
        layerHelper.enableLayer(this.group,CORE.layers.quote);
        
        let dim = new THREE.Vector3(this.design.dim.width, this.design.dim.height, 0);

        var geoCollision = new THREE.BoxGeometry(dim.x, dim.y, dim.z);
        this.meshPickDetection = new THREE.Mesh(geoCollision, CORE.materials.transparent);
        this.meshPickDetection.position.z +=2;
        this.meshPickDetection.renderOrder=1;
        this.meshPickDetection.name = "pickDetectionMesh for " + this.getDescription();
        
        this.group.add(this.meshPickDetection);

        this.group.updateMatrix();
        this.group.updateMatrixWorld();
        this.setCollisionZone();

        this.outlineMesh = this.meshPickDetection;
        
        
        this.contextMenuObject = new THREE.Mesh()
        let trc = this.getTopRightCorner();
        this.contextMenuObject.position.set(trc.x, trc.y, trc.z);
        this.contextMenuObject.visible = false;
        this.group.add(this.contextMenuObject);


        var geoGlass = new THREE.BoxGeometry(dim.x, dim.y, 1);
        let meshDoor = new THREE.Mesh(geoGlass);
        meshDoor.userData = this.design;
        meshDoor.visible=false;
        meshDoor.name = "designMesh for" + this.getDescription();
        this.group.add(meshDoor);

        
        this.built=true;
    }

    setCollisionZone(){
        if(!this.meshPickDetection)
            return;
        
        this.meshPickDetection.updateMatrix();
        this.meshPickDetection.updateWorldMatrix(true);
        
        let zone = this.getCollisionZoneTemplate(this.meshPickDetection);
        zone.classes = [
            CORE.collisions.classes.window,
            CORE.collisions.classes.emptyFramedOpening,
            CORE.collisions.classes.louverVent,
            CORE.collisions.classes.doorWalkin3o7o,
            CORE.collisions.classes.doorWalkin4o7o,
            CORE.collisions.classes.doorWalkin6o7o,
            CORE.collisions.classes.doorRollup,
            CORE.collisions.classes.doorSliding,
            CORE.collisions.classes.doorCommGlass,
            CORE.collisions.classes.doorSectional,
            CORE.collisions.classes.doorHangar,
            CORE.collisions.classes.wideOpening,
        ];
        zone.data = this.design;

        this.meshPickDetection.userData.collisionZones = [zone];
    }

    getTypeDisplayName(){
        return `6' Walk Door`;
    }
}