import * as THREE from 'three';
import ColumnBase from './ColumnBase.js'
import {CORE} from '../_spec.js'
import { Vector3 } from 'three';
import CollisionHelper from '../helpers/CollisionHelper.js';
import MemHelper from '../helpers/memHelper.js';
import ColumnC from './ColumnC.js';

export default class ColumnSoldier extends ColumnC {
    constructor(lod, length, addCollisionZone, materialDim, color){		
		super(lod, length, addCollisionZone, materialDim, color);
		this.group.name = 'soldier column';		
    }

    
}