<template>
  <div class="subPanel contextWrapper" style="height: 100%; overflow: hidden;" v-if="true">
    <div>
      <div class='title'><font-awesome-icon icon="fa-duotone fa-wrench" /> <b>{{title}}</b></div>
      <div @click='close' class='close'><font-awesome-icon icon="fa-duotone fa-circle-xmark" size="xl"/></div>
    </div>
    <div style='display:inline-block;height: 100%;width: 100%;'>      
        <div id='optionsScrollWrapper' style='display:inline-block;height: calc(100% - 70px); overflow-y: auto;width: 100%;'>
          <!-- 
            60px for the buttons at the bottom of the options panel (which need to stay visible)
           -->
        <table id='tblOpts'>
          <tr v-for="(option) in dataOptions" v-bind:key="option.key">          

            <td v-if="option.type==='header'" colspan="3" >
              <div class='header'>{{option.text}} 
                <span v-if="option.info!==undefined" ><font-awesome-icon icon="fa-duotone fa-circle-info" size="lg" v-on:mouseover="showInfo(option)" v-on:mouseleave="hideInfo(option)" style="color:blue" />
                <div class="info" :ref="(option.key+'info')"><span style="font-weight:bold;text-decoration: underline;">{{option.name}}</span>{{option.info}}</div>
              </span></div>
              
            </td>
            
            <td v-if="option.type==='note'" colspan="3" >
              <textarea :placeholder="option.name" style="width:100%" v-model="option.value" @change="change" :disabled="optionDisabled(option.enabled)"></textarea>
            </td>
            
            <td v-if="option.type==='checkHeader'" colspan="3">
              <div class='header'>{{option.text}}
                <input :id="option.key" type='checkbox' v-model="option.value" @change="change" :disabled="optionDisabled(option.enabled)"/>
              </div>
            </td>

            <td class='name'  v-if="option.type!=='note'">              
                <helpTooltip v-if="option.helpText" :helpText="option.helpText" >
                <label class='ib' :for="option.key">{{option.name}} </label>
                </helpTooltip>
                <label v-else class='ib' :for="option.key">{{option.name}} </label>
                
            </td>  

            
            <td v-if="option.type==='text'" class='value' colspan="2">
              <input :id="option.key" type='text' v-model="option.value" @change="change" :disabled="optionDisabled(option.enabled)"/>
            </td>          

            <td v-if="option.type==='nud'" class='value'>
              <input :id="option.key" type='number' v-model="option.value" @change="change" inputmode="decimal" :min="option.min" :max="option.max" :step="option.increment" :disabled="optionDisabled(option.enabled)" />
              <span v-if="option.unit!== undefined && option.unit!==''">{{option.unit}}</span>
              <span v-if="option.info!== undefined" ><font-awesome-icon icon="fa-duotone fa-circle-info" size="lg" v-on:mouseover="showInfo(option)" v-on:mouseleave="hideInfo(option)" style="color:blue" />
                <div class="info" :ref="(option.key+'info')"><span style="font-weight:bold;text-decoration: underline;">{{option.name}}</span>: {{option.info}}</div>
              </span>
              
            </td>
<!-- 
            <td v-if="option.type==='chkNud'" class='value'>
              <input :id=`${option.key}_chk` type='checkbox' v-model="option.enabled" @change="change"/>
              <input :id=`${option.key}_val` type='number' v-model="option.value" @change="change" inputmode="decimal" :min="option.min" :max="option.max" :step="option.increment" />
            </td> -->

            <td v-if="option.type==='checkbox'" class='value'>
              <input :id="option.key" type='checkbox' v-model="option.value" @change="change" :disabled="optionDisabled(option.enabled)" />            
            </td>            

            <td v-if="option.type==='switch'" class='value' colspan="2">
              <ToggleButton v-model="option.value" :sync="true" :disabled="optionDisabled(option.enabled)" :color="option.bgColors" :switch-color="option.swColors" :labels="{checked: option.valOn, unchecked: option.valOff}" :width="75" :font-size="12" @change="change"/>
            </td>

            <td v-if="option.type==='selection'" class='value' @change="change" :colspan="option.unit===undefined?2:1">
              <select v-model="option.value" :disabled="optionDisabled(option.enabled)">
                <option v-for="so in option.selOptions" :key="so.value" :value="so.value">{{so.text}}</option>
              </select>
              <span v-if="option.unit!== undefined && option.unit!==''">{{option.unit}}</span>
            </td>

            <td v-if="option.type==='radio'" class='value' @change="change">
              <div v-for="so in option.selOptions" :key="so.value">
                <input type="radio" :id="so.value" :name="so.key" :value="so.value" v-model="option.value" :disabled="optionDisabled(option.enabled)">
                <label :for="so.value">{{so.text}}</label>
              </div>
            </td>

            
            <td v-if="option.type==='range'" class='value' >
              <label :for="option.key" >{{ option.leftLabel }}</label>
              <input type="range" :id="option.key" :name="option.key" v-model="option.value" :disabled="optionDisabled(option.enabled)" :min="option.min" :max="option.max" @input="change">
              <label :for="option.key" >{{ option.rightLabel }}</label>
            </td>
             
            <td v-if="option.type==='color'" class='value'  :colspan="option.unit===undefined?2:1" >
              <colorPicker
               @input="change"
               :openUp=true
               :title=option.name
               :enabled="optionEnabled(option.enabled)"
              :initColor="option.value.bang"
              :initLabel="option.value.name"
                v-model="option.value.bang"
                :palette="option.selOptions"
              ></colorPicker>              
            </td>              

            <!-- <td> -->
              <!-- <span v-if="option.unit!== undefined && option.unit!==''">({{option.unit}})</span> -->
            <!-- </td> -->
          </tr>
        </table>
        </div>
        
        <hr>
        <div style="display:flex; justify-content:space-evenly">
          
          <button v-if="editable && optsContext.showCancel" v-on:click="cancel" class="btn" name='Cancel'>Cancel</button>
          <template v-for="(option) in actionOptions" >                    
              <button v-if="editable" v-on:click="action(option.key)" v-bind:key="option.key" class="btn" :name="option.name">{{option.name}}</button>
          </template>
          
          <button v-on:click="close" class="btn" name='Done'>{{optsContext.closeText}}</button>
          
        </div>
    </div> 
  </div>
  
</template>


<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import Dictionary from '../js/helpers/Dictionary.js'
import { ToggleButton } from 'vue-js-toggle-button' //https://www.npmjs.com/package/vue-js-toggle-button
import colorPicker from './colorPicker.vue'
import helpTooltip from './helpTooltip.vue'
let dictionary = new Dictionary();
export default {
  props: {
    context: Object
  },
  computed:{     
    ...mapState('designModule',[
      'editable'
    ]),
    ...mapGetters('designModule',[
      'optsContext'
    ]),    
    title:{
      get: function(){
        if(!this.optsContext)
          return "Options";
        return `${this.optsContext.title}`;
      }
    } ,      
      dataOptions:{
        get: function(){       
          let opts = this.optsContext.options;
          let fopts = opts.filter((o)=>{return o.type!=='button'});

          return fopts;
        }
      },
      actionOptions:{
        get: function(){
          return this.optsContext.options.filter((o)=>{return o.type==='button'});
        }
      },
      design:{
        get: function(){
          return this.optsContext.design;
        }
      }  
  },
  data: ()=>{
      return {
        wrapperStyle:{
          "white-space": 'pre-wrap'
        } ,  
        color:         '#A463BF',
        previousFrameType: null,
        previousGalvBeams: null,
        previousGalvPurlins: null,
        previousStructureId: null,
        
      };
  },
  created: function(){
  },
  mounted: function(){

  },
  methods: {
...mapActions('designModule',[
        'promptDeleteComponent',        
        'copyComponent',
        'centerComponent',
      ]),
    showInfo:function(option){
      let key = option.key+'info';
      this.$refs[key][0].style.display = 'block'; // this ref index is an array because the ref is in a v-for
    },
    hideInfo:function(option){
      let key = option.key+'info';
      this.$refs[key][0].style.display = 'none'; // this ref index is an array because the ref is in a v-for
    },
    optionEnabled:function(optEnabled){      
      return this.editable && optEnabled;
    },
    optionDisabled:function(optEnabled){      
      return !this.optionEnabled(optEnabled);
    },
    cancel:function(){
      if(this.optsContext.onCancel)
        this.optsContext.onCancel();
    },
    close: function(){
      if(this.optsContext.onClose)
        this.optsContext.onClose();
      //else
      //this.$store.commit('hideOptionsPanel');
    },
    change: function(){
      
      if(this.optsContext.onChange){
        console.log('change call')
        this.optsContext.onChange({
          componentId: this.design.id,
          options: this.dataOptions
          });
      }
    },
    action: function(key){
      switch(key){         
        case 'delete':
          this.promptDeleteComponent(this.design.id);
          break;
        case 'copy':
          this.copyComponent(this.design.id);
          break;
        case 'center':
          this.centerComponent(this.design.id);
          break;
      }
    },
  },
  components:{
    ToggleButton,
    colorPicker,
    helpTooltip
  }
}
</script> 

<style scoped>

tr td {
  white-space: nowrap;
}

td{
  padding-top: 1px;
  padding-bottom: 1px;
}

.name{
  text-align: right;
}
.value{
  text-align: left;
}

.option{
  display:block;
  width: max-content;
  margin: 5px;
  
}

input{
  width: 40px
}

input[type~="radio"]{
  width: 10px;
  margin-right: 4px;
  display: table-cell;
  vertical-align: middle;
}

button[type~='checkbox'] {
  vertical-align: middle;
}

input[type~="color"]{
  width: 60px
}


input[type~="text"]{
  width: 100%;
}

div.header{
  overflow: auto;  
  text-align: left;
  margin-top: 10px;
  font-weight: bold;
  border-top: solid 1px black;
  width: 100%;
    white-space: normal;
}

div.contextWrapper{
  /* display: inline-block;     */
  text-align: center;
  z-index: 10001;  
  /* border-radius: 4px; */
  /* border-bottom: 1px solid black; */
  /* padding:4px;     */
  /* opacity: .85; */
  top: 0px;
  font-size: 12px;
  /* height: 50vh; */
  /* overflow-y: scroll; */
}

.ib{
  display:inline-block
}

.b{
  display:block;
}

.title{
  display:inline;
}
.close{  
  float:right;
  display:inline;
}
.close svg:hover{
  color:red; 
}
button {
  display: inline;
  margin: 3px;
}

#tblOpts{
  margin: auto;
}

/* everything immediately under the table cells needs right margin for readability */
#tblOpts td > * {
  /* margin-right:5px; */
  vertical-align: middle;
}

#tblOpts td input {
  padding-left:2px;
}

button[name='Done'] {
  background:#00a700;
}

button[name='Delete'] {
  background:#df0404;
}

button[name='Cancel'] {
  background:#df0404;
}

.info{
  border: 1px solid black;
  padding: 5px;
  border-radius: 4px;
  width: 250px;
  position: absolute;
  left: 250px;  
  display: none;
  background: gray;
  color: white;
  box-shadow: 0px 0px 8px -1px black;
  text-wrap: wrap;
  
}
</style>
