<template>
    <img src='@/assets/images/ajax-spinner.gif'>
  </template>
  
  <script>
  export default {
    name: 'ProgressSpinner',
  };
  </script>
  
  <style scoped>

  </style>