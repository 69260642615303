<template>
    <div class="layout">
      <div 
        class="sidebar" 
        :class="{ 'collapsed': isCollapsed }"
        :style="{ width: sidebarWidth + 'px' }"
      >
        <button @click="toggleSidebar" class="toggle-btn">
          {{ isCollapsed ? ']>' : '<]' }}
        </button>
        <div v-show="!isCollapsed" class="sidebar-content">
          <slot name="sidebar">A
          </slot>
        </div>
      </div>
      <div class="main-content">
        <slot name="main">B</slot>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SidebarLayout',
    data() {
      return {
        isCollapsed: false,
        collapsedWidth: 50,
        expandedWidth: 250
      };
    },
    computed: {
      sidebarWidth() {
        return this.isCollapsed ? this.collapsedWidth : this.expandedWidth;
      }
    },
    methods: {
      toggleSidebar() {
        this.isCollapsed = !this.isCollapsed;
      }
    }
  };
  </script>
  
  <style scoped>
  .layout {
    display: flex;
    height: 100vh;
  }
  
  .sidebar {
    background-color: lightblue;
    transition: width 0.3s ease;
    position: relative;
  }
  
  .sidebar-content {
    padding: 20px;
    overflow-x: hidden;
  }
  
  .toggle-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
  }
  
  .main-content {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
  }
  </style>