<template>
    <div v-if="show" class="modal">
      <div class="modal-content">
        <h2>{{ message }}</h2>
        <ul v-if="issues.length > 0">
          <li v-for="(issue, index) in issues" :key="index">{{ issue }}</li>
        </ul>
        <button @click="$emit('close')">Close</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      show: Boolean,
      message: String,
      issues: Array
    }
  }
  </script>
  
  <style scoped>
  .modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
  }
  
  button {
    margin-top: 10px;
  }
  </style>