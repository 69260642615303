import api from '@/api';

const state = {
};

const mutations = {
};

const actions = {
    async fetchPricingLevels({commit}, businessId){
        try {
            const response = await api.get(`/price/getPricingLevels/${businessId}`);
            return response;
        } catch (error) {
            console.error('Error updating entity:', error);
            throw error;
        }
    },
    async updatePricingLevels({commit}, payload){
        try {
            const response = await api.put(`/price/updatePricingLevels/${payload.businessId}`, payload.pricingLevels);
            return response.data;
        } catch (error) {
            console.error('Error updating entity:', error);
            throw error;
        }
    }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};