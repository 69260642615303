import * as THREE from 'three';
import {CORE} from '../_spec.js';
import { Vector3, Quaternion, Matrix4, Matrix3 } from 'three';
import { OBB } from 'three/examples/jsm/math/OBB.js'; // https://threejs.org/examples/?q=bounding#webgl_math_obb
import MatrixHelper from '../helpers/matrixHelper.js';
import Base from './Base'
import ComponentHelper from '../helpers/featureHelper.js'

export default class Null extends Base{
    // Side represents the side of the frame, as an attachment place for features, like porches.
    // in the future this could facilitate multi-structure buildings, not just porches.
    constructor(des){
        super(des);

        let _this = this;
        des.components.forEach(function(cDesign){
            console.log('NULL creating child components')
            let c = ComponentHelper.createComponentWithDesign(cDesign)
            _this.addComponent(c);
        });
        
    }
    
    defaultDesign(){
        return {
            type: CORE.components.null,
            id: -1
        }
    }

    getEditableComponentTreeItems(){
        
        return []; // because for the same of the menu, the null component doens't exist and has no children
    }

    static canEdit()
    {
        return false;
    }    

    getDescription(){
        return "the null"
    }

    build(){

    }
    remove(){
        
    }

    positionChildAt(){
        
    }

    processRebuildsInternal_Children(){
    }

    processRebuildsInternal(){
        
    }
}