import { CORE, rakeTrimStyles, eaveTrimStyles, buildingCodes } from './_spec.js';
import ColorHelper from './helpers/colorHelper.js'
import Util from './utility.js'
import FramelineHelper from './blueprints/FramelineHelper.js'
import ConstraintHelper from './helpers/ConstraintHelper.js';
import BuildLogic from './o/BuildLogic.js';
import { Vector3 } from 'three';
import DesignHelper from './helpers/DesignHelper.js';
export default class DesignManager{
  
    constructor(initData){

      
      if(initData && initData.design)
        this.loadDesign(initData.design);
      else{
        this.loadDefault(initData);

        console.log(initData.settings);
        if(initData)
          this.applySettings(initData.settings);
          
        this.constraintCount=1;
        this.constraintRootNode = ConstraintHelper.rootNode();

        //see component IDs at //LINK docs/topics/components.md
        
        let newConstraint = ConstraintHelper.createRootChildConstraintForId(5);
        ConstraintHelper.addConstraintToParent(newConstraint, this)
      }
      
      this.constraintCount = ConstraintHelper.maxConstraintId(this.constraintRootNode);
    }

    updateConstraint(newConstraintData){
      let constraint = ConstraintHelper.getConstraintWithId(this.constraintRootNode, newConstraintData.id);
      constraint.partyWallOwnerID = newConstraintData.partyWallOwnerID;
      ConstraintHelper.applyConstraintData(constraint, newConstraintData);      
    }

    identifyConstraint(constraint){
      this.constraintCount++;
      constraint.id = this.constraintCount;      
      constraint.color = ColorHelper.getConstraintColorFromInt(constraint.id);
      constraint.partyWallOwnerID = CORE.partyWallOwner.neither;
    }

    addConstraintToNode(constraintNode, newConstraint) {
      
      let constraint = ConstraintHelper.addConstraintToNode(constraintNode, newConstraint);
      if(constraint == null)
        return;
        
      this.identifyConstraint(constraint);
      
    }
    
    getAllConstraints(branch, constraints){
      if(!branch)
        return this.getAllConstraints(this.constraintRootNode, []);

        constraints.push(...branch.constraints);
        branch.childNodes.forEach((node)=>{
          this.getAllConstraints(node, constraints);
        });     
        return constraints;
    }
    


    getStructureIdNameMap(){
      let structureMap = [];
      let structures = this.design.getAllStructuresOfAllSystems();
      structures.forEach((s)=>{
        structureMap.push({id:s.id, name:s.name});
      })
      return structureMap;      
    }

    applySettings(settings){
      if(!settings)
      {
        console.log("Design: No settings to apply");  
        return;
      }
      // there are customer defaults that affect an option on this Options component but are not rendered (insulation at the moment)
      // there are customer defaults that affect an option on this Options component and are rendered (wrap tube porch columns)
      // there are customer defaults which are not an option on this Options component, and are rendered / affect the quote (window style)
      // there are customer defaults which are not an option on this Options component, and are not rendered (yet) but affect the quote (chicken wire)

      console.log("Design: Applying Settings", settings);
      if(Util.isDefined(settings.des_gutter))
          this.design.getSystem().getStructure().getOptions().gutters=JSON.parse(settings.des_gutter || false);  
      if(Util.isDefined(settings.des_peakSpace))
          this.design.getSystem().getStructure().getRoof().peakSpace=Number(settings.des_peakSpace);
      if(Util.isDefined(settings.des_dripTrim))
          this.design.getSystem().getStructure().getOptions().dripTrim=JSON.parse(settings.des_dripTrim || false);      
        if(Util.isDefined(settings.des_tubePorchColTrim))
          this.design.getSystem().getStructure().getOptions().wrapTubePorchCols=JSON.parse(settings.des_tubePorchColTrim || false);         
        if(Util.isDefined(settings.des_insulationRoof))
          this.design.getSystem().getStructure().getOptions().insulation.roof=settings.des_insulationRoof;
        if(Util.isDefined(settings.des_insulationWall))
          this.design.getSystem().getStructure().getOptions().insulation.wall=settings.des_insulationWall;   

        if(Util.isDefined(settings.des_wainscotHeight))
        {
          this.design.getSystem().getStructure().getFrontWall().wainscot.height=Number(settings.des_wainscotHeight);
          this.design.getSystem().getStructure().getBackWall().wainscot.height=Number(settings.des_wainscotHeight);
          this.design.getSystem().getStructure().getLeftWall().wainscot.height=Number(settings.des_wainscotHeight);          
          this.design.getSystem().getStructure().getRightWall().wainscot.height=Number(settings.des_wainscotHeight);
        }

        let appearanceDesign = this.design.getSystem().getStructure().getAppearance();
        // apply colors to components here
        if(Util.isDefined(settings.des_colorRoof))
          appearanceDesign.roof=ColorHelper.getColorByColorId(settings.des_colorRoof);
        if(!appearanceDesign.roof)
          appearanceDesign.roof = ColorHelper.getFullPalette()[0];

        if(Util.isDefined(settings.des_colorWall))
          appearanceDesign.wall=ColorHelper.getColorByColorId(settings.des_colorWall);
        if(!appearanceDesign.wall)
          appearanceDesign.wall = ColorHelper.getFullPalette()[0];

        if(Util.isDefined(settings.des_colorWainscoting))
          appearanceDesign.wainscoting=ColorHelper.getColorByColorId(settings.des_colorWainscoting);
          if(!appearanceDesign.wainscoting)
          appearanceDesign.wainscoting = ColorHelper.getFullPalette()[0];

        if(Util.isDefined(settings.des_colorTrim))
          appearanceDesign.trim=ColorHelper.getColorByColorId(settings.des_colorTrim);
          if(!appearanceDesign.trim)
          appearanceDesign.trim = ColorHelper.getFullPalette()[0];

        if(Util.isDefined(settings.des_jambCounterFlashing))
          this.design.getSystem().getStructure().getOptions().jambCounterFlashing=JSON.parse(settings.des_jambCounterFlashing || false);     
        if(Util.isDefined(settings.des_ohJambLiner))
          this.design.getSystem().getStructure().getOptions().ohJambLiner=JSON.parse(settings.des_ohJambLiner || false);     
        if(Util.isDefined(settings.des_tackyTapeRoof))
          this.design.getSystem().getStructure().getOptions().tackyTapeRoof=JSON.parse(settings.des_tackyTapeRoof || false);     
        if(Util.isDefined(settings.des_plusGirtsCutToLength))
          this.design.getSystem().getStructure().getOptions().plusGirtsCTL=JSON.parse(settings.des_plusGirtsCutToLength|| false);
    }
    
    validate() {
      console.log('Validating Design')            
      this.validateComponent(this.design)
    }

    validateComponent(c, p){
      if(p){          
        if(c.parent.id !== p.id){
          console.error(`INVALID: C${c.id} ${c.type} references parent ID ${c.parent.id} but is stored under ${p.id} ${p.type} in design`);
        }
      }
      
      this.validateComponents(c)
    }

    validateComponents(c){
      if(!c.components)
        return;
      c.components.forEach((child)=>{
        this.validateComponent(child, c);
      })
    }

    forEachComponent(callback){
      this.forEachComponentInternal(this.design, callback);    
    }

    forEachComponentInternal(c, callback){
      if(!c.components || c.components.length===0)
        return;
      c.components.forEach((child)=>{
        callback(child);
        this.forEachComponentInternal(child, callback);
      })
    }

    loadDefault(initData){
      DesignHelper.componentCount = 1;
      let buildingDesign = DesignHelper.getDefaultRootDesign()
      DesignHelper.addComponent(null, buildingDesign);

      // null
      DesignHelper.addComponent(buildingDesign, DesignHelper.getNullComponentDesign());
      
      //system
      let systemDesign = DesignHelper.getDefaultSystemDesign();
      DesignHelper.addComponent(buildingDesign, systemDesign);
      
      // structure
      let structureDesign = DesignHelper.getDefaultStructureDesign();
      DesignHelper.addComponent(systemDesign, structureDesign);
      
      DesignHelper.GetBuiltOutStructureDesign(structureDesign, initData);
      
      this.design = buildingDesign;
      // set the version for a default design
      this.version= 3
    }


    loadDesign(designWrapper){
      DesignHelper.componentCount = designWrapper.componentCount;
      this.version = designWrapper.version;
      
      //detect the correct existing design version 
      if(typeof this.version === 'undefined'){
        this.version = 1; // version was first added in v2
        this.design = designWrapper.d; // this changed between v1 and v2
      }
      else{
        this.design = designWrapper.design;
      }
      
      DesignHelper.initLoadedComponent(this.design, this.version);
      this.constraintRootNode = designWrapper.constraintRootNode;
      this.linkParentConstraints(this.constraintRootNode,this.constraintRootNode.childNodes );
    }

    linkParentConstraints(parent, children){
      if(!children)
        return;
      children.forEach((child)=>{
        child.parentNode = parent;
        this.linkParentConstraints(child, child.childNodes);
      })
    }


    migrateToCurrentVersion(bp){
      // no need to migrate something on the current version
      if(this.version == CORE.currentVersion)
        return;
      
      let migCount = 0;
      while(this.version != CORE.currentVersion)
      {
        migCount++;
        console.log(`pre-migration pass #${migCount}: version ${this.version}`)
        this.migrateOneVersion(bp);
         // count this migration
        console.log(`post-migration pass #${migCount}: version ${this.version}`)

        // if we've made more migrations that should be necessary
        if(migCount>CORE.currentVersion){
          console.error(`migration count reached ${migCount} and CORE.currentVersion is ${CORE.currentVersion}`)
          break; // something has probably gone wrong and we don't want to keep attempting to migrate forever
        }
      }
      if(migCount<CORE.currentVersion)
        console.log(`migration complete: version ${this.version}`)
    }

    migrateOneVersion(bp){
      let startVersion = this.version;
      switch(this.version)
      {
        case 1:
          //this.migrateTo2(bp);
          break;
      }

      // if there a need for migration, but no design manager version change,
      if(startVersion != CORE.currentVersion && this.version === startVersion)
        // yell loudly
        throw "design migrate must increment the design version"
    }

    migrateTo2(bp){      
      
    }

    
    
    
    addComponentDesignToNull(c){
      c.collided=false; // must be here now or Vue won't detect it after adding later
      c.selected=false; // must be here now or Vue won't detect it after adding later
      if(Util.isUndefined(c.components)) // structure comes in as a fully-built component tree
        c.components = [];
      
      c.parent={
        id: 2 // changed while adding support for a second structure
      }
      let nullComponent = this.design.components[0];      
      return DesignHelper.addComponent(nullComponent, c);
    }



    setComponentId(d){
      d.id = DesignHelper.getNextComponentId();
    }

    
    addSystemDesignToBuilding(c){
      c.collided=false; // must be here now or Vue won't detect it after adding later
      c.selected=false; // must be here now or Vue won't detect it after adding later
      c.parent={
        id: 1 // changed while adding support for a second structure
      }
      return DesignHelper.addComponent(this.design, c);
    }

    removeStructureDesignFromSystem(dStructureToRemove){
      this.design.getAllSystems().forEach((dSys)=>{

        // dSys.getAllMainStructures().forEach((dStructure)=>{
        //   if(dStructureToRemove.id == dStructure.id){

        //   }
        // });

        // remove this structure from this system
        dSys.components = dSys.components.filter((c) => {return c.id != dStructureToRemove.id});

      });
    }

    removeSystemFromDesign(dSystemToRemove){
      //let nullComp = this.design.components[0];
      this.design.components = this.design.components.filter((c)=> { return c.type == CORE.components.null || c.id != dSystemToRemove.id});
    }

    
    addStructureDesignToSystem(c, sys){
      c.collided=false; // must be here now or Vue won't detect it after adding later
      c.selected=false; // must be here now or Vue won't detect it after adding later
      
      c.parent={
        id: sys.id // changed while adding support for a second structure
      }
      let buildingComponent = this.design;
      return DesignHelper.addComponent(sys, c);
    }

    addStructureDesignToBuilding(c){
      c.collided=false; // must be here now or Vue won't detect it after adding later
      c.selected=false; // must be here now or Vue won't detect it after adding later
      
      c.parent={
        id: 1 // changed while adding support for a second structure
      }
      let buildingComponent = this.design;
      return DesignHelper.addComponent(buildingComponent, c);
    }

    reparentComponent(cDesign,newParentDesign){
      let childId = cDesign.id;
      if(cDesign.parent === null){
        console.error(`reparentingComponent: C${cDesign.id} parent is null`)
      }
      if (newParentDesign === null){
        console.error(`reparentingComponent: new parent is null`)
      }
      // if the child's parent reference is already correct, short circuit
      if(cDesign.parent.id === newParentDesign.id)
        return;
      // find the design for the oldParent
      let oldParent = this.getComponentDesignById(cDesign.parent.id);      
      // filter out the child from the oldParent's component list
      oldParent.components = oldParent.components.filter(function (c){return c.id != childId})
      // update the childs parent reference ID
      cDesign.parent.id = newParentDesign.id;      
      // add the child to the new parent
      newParentDesign.components.push(cDesign);
    }

    

    deleteComponent(id){
      // takes the design of the component to delete
      let child= this.getComponentDesignById(id);
      if(!child)
      {
        console.log(id, ' not found to delete');
        return;
      }
      
      let parentId = child.parent.id;

      if(parentId === 1){
        this.design.components = this.design.components.filter((c)=>{return c.id !== id})
        return;
      }
        
      
      let parent = this.getComponentDesignById(parentId);
      if(parent == null)
        return;
      parent.components = parent.components.filter((c)=>{return c.id !== id})
      
      return parentId;
    }

    // getFrameType(){
    //   return this.design.components[1].components.filter((c)=>{return c.type === CORE.components.frame})[0].frameType;
    // }

    // getFrame(){           
    //   return this.design.components[1].components.filter((c)=>{return c.type === CORE.components.frame})[0];
    // }

    // getRoof(){
    //   return this.design.components[1].components.filter((c)=>{return c.type === CORE.components.roof})[0];
    // }   

    get(){return this.design;}

    getComponentDesignById(id){
      return this.getComponentDesignByIdInternal(this.design.components, id);
    }

    // this needs to be re-written
    getComponentDesignByIdInternal(comps, id){
      if(!comps)
        return null;
      let _this = this;
      let result = null;
      comps.forEach(function(c){        
        if(result !==null)
          return;
        if(c.id === id){
          result = c;
          return;
        }
          
        let child = _this.getComponentDesignByIdInternal(c.components, id);
        if(child!=null)
          result = child;
      }) 

      return result;
    }

    getComponentAncestorIds(id){
      let ancestorIds = [];
      let done = false;
      while(!done){

      
        let childDesign = this.getComponentDesignById(id);


        if(childDesign && childDesign.parent){
          id = childDesign.parent.id; // get the parent id
          ancestorIds.push(id) // push the parent id
        }
        else
          done=true;
      }
      return ancestorIds;
    }

}
