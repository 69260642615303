import * as THREE from 'three'
import {CORE} from '../_spec.js'
import FoldedGeometryHelper from '../helpers/foldedGeometryHelper.js';
import MemHelper from '../helpers/memHelper.js';
import _3dBase from './Base.js'
import layerHelper from '../helpers/layerHelper.js';

export default class TrimRake extends _3dBase {
    constructor(length, material, leftVertAngle, rightVertAngle, leftHoriAngle, rightHoriAngle, leftEndCap = true, rightEndCap = true){
        super()
        
        let folds = [
            {mdist:-2},
            {deg:-45}, 
            {dist:1}, // back upper diag
            {deg:45}, // 
            {dist:4}, // top 
            {deg:60}, 
            {dist:4}, // front upper diag           
            {deg:60}, 
            {dist:4}, // front lower diag                      
            {deg:60},
            {dist:2}, // bottom 
            {deg:90},
            {dist:6.35}, // back vertical
            {markEndOrigin:true},
            {deg:-90},
            {dist:1}, // back horizontal
        ];

        
        let fgh = new FoldedGeometryHelper(folds, material, true, true, true, length, leftVertAngle, rightVertAngle, leftHoriAngle, rightHoriAngle)
        layerHelper.setGroup(fgh.group, CORE.layers.walls);
        this.group.add(fgh.group);
        this.group.name = 'rake Trim'
    }
    

}