<template>
  <div id="app" >
    <div class="container">
      <Sidebar2 style="width:200px;" v-if="user">
        <Sidebar2Link v-if="userIsSysAdmin" :routePath="`/`" :style="{visibility: mode>0?'visible':'hidden'}" style="text-align: center;">Ninja HQ</Sidebar2Link>
        <template v-if="mode == 0">
          <!-- SysAdmin links are of a different form than entity links -->
          <Sidebar2Link :routePath="'/'" style="" >✔️ Projects</Sidebar2Link>
          <Sidebar2Link :routePath="'/businesses/list'" >✔️ All Businesses</Sidebar2Link>
        </template>
        
        <template v-if="mode == 1 && current.supplier">
          
          <Sidebar2Link v-if="userIsSysAdmin && current.supplier" :routePath="`/businesses/list`" style="display: block;margin: 5px;border-radius: 14px;background: rgb(206 216 251); color: black;">
            <div  style="font-weight:bold;text-align: center;padding:10px;font-size:large">
              {{ current.supplier.businessName }}
            </div>
          </Sidebar2Link>

          <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/projects`">✔️ Projects</Sidebar2Link>
          <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/contractors`">✔️ Contractors</Sidebar2Link>
          <br/>
          <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/colors`">✔️ Colors</Sidebar2Link>
          <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/itemManager`">✔️ Items</Sidebar2Link>
          <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/pricing`" :query="{section:'pricingModel'}">✔️ Pricing Model</Sidebar2Link>
          <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/pricing`" :query="{section:'pricingLevels'}">✔️ Pricing Levels</Sidebar2Link>
          <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/pricing`" :query="{section:'logistics'}">✔️ Logistics</Sidebar2Link>
          <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/pricing`" :query="{section:'fabrication'}">✔️ Fabrication</Sidebar2Link>
          <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/pricing`" :query="{section:'beams'}">✔️ Beams</Sidebar2Link>
          <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/pricing`" :query="{section:'fasteners'}">✔️ Fasteners</Sidebar2Link>
          <br/>


          <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/users`">✖️ Users</Sidebar2Link>
          <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/settings`">✔️ Business Settings</Sidebar2Link>
          <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/subscription`">✖️ Subscription</Sidebar2Link>
          
        </template>
        <template v-if="mode==2 && current.contractor">
          
          <Sidebar2Link v-if="userBusiness && userBusiness.type < 2 && current.contractor" :routePath="`/businesses/list`" style="display: block;margin: 5px;border-radius: 14px;background: rgb(206 216 251); color: black;">
            <div  style="font-weight:bold;text-align: center;padding:10px;font-size:large">
              {{ current.contractor.businessName }}
            </div>
          </Sidebar2Link>

          
          <Sidebar2Link :routePath="`/contractor/${current.contractor.id}/projects`">✔️ Projects</Sidebar2Link>
          <Sidebar2Link :routePath="`/contractor/${current.contractor.id}/settings`">✔️ Business Settings</Sidebar2Link>
          <Sidebar2Link :routePath="`/contractor/${current.contractor.id}/users`">✖️Users</Sidebar2Link>
        </template>
        <br/>

        <Sidebar2Link :routePath="`/profile`">✔️ My Settings</Sidebar2Link>
        <Sidebar2Link @click="logout" style="position:absolute;bottom:10px">✔️ Logout</Sidebar2Link>
      </Sidebar2>
      
      <router-view class="main-content" :key="refreshKey+$route.fullPath" />
    </div>
  </div>
</template>

<script>
import ContextLogic from './lib/contextLogic';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
//import SidebarLayout from './components/SidebarLayout.vue'
import SidebarLayout from './components/SidebarLayout.vue'
import {CORE} from './constants.js'
import NavBar from './components/NavBar.vue';
import Sidebar2 from './components/Sidebar2.vue';
import Sidebar2Link from './components/Sidebar2Link.vue';
import Sidebar2Section from './components/Sidebar2Section.vue';
import ProjectView from './views/ProjectView.vue';
import ProjectManagerView from './views/ProjectManagerView.vue';

export default{
  name: 'App',
  data(){
    return{
      // dashboardPath: null,
      routePath:'',
      logo:''    
    }
  },
  components:{
    SidebarLayout,
    Sidebar2,
        Sidebar2Link,
        Sidebar2Section
  },
  watch:{
    async currentBusiness(v){

      console.log('current entity is now', v )
      if(!v)
        return;
        this.logo = await this.getCompanyImage(v.id);  
    },
    $route(to, from) {
      // React to route changes...
      console.log('Route changed from', from.path, 'to', to.path)
      // Perform any necessary actions here
      this.routePath = this.$route.path; // update this to force showSidebar to recalculate
    }
  },
  computed:{      
    ...mapState('contextModule', ['current','user', 'userBusiness', 'refreshKey' ]),
    ...mapGetters('contextModule', ['currentBusiness', 'userIsSysAdmin']),
    abbr(){
      if(!this.currentBusiness)
        return '';
      let companyName = this.currentBusiness.businessName;
      // Handle empty or null input
    if (!companyName) return '';
    
    // Split the name into words and filter out empty strings
    const words = companyName.split(' ').filter(word => word.length > 0);
    
    // Extract first letter from each word and capitalize it
    const abbreviation = words
        .map(word => word[0].toUpperCase())
        .join('');
    
    return abbreviation;
    },
    mode(){
      return this.current.mode
    },
    
    showSidebar(){
      console.log('app: showSidebar')
      if(!this.$auth || !this.$auth.isAuthenticated)
      {
        return false;
      }
      let pathsWithoutSidebar=['design','login', ];
      return !(pathsWithoutSidebar.some(path =>  this.routePath.includes(path)));
    },
    
    toggleLabel(){
      return this.collapsed?"]>":"<]";
    },
    
    sdkClientLoaded(){
      //Check that the SDK client is not currently loading before accessing is methods
      // console.log('isLoading',this.$auth.loading)
      // console.log('isAuth', this.$auth.isAuthenticated)
      return !this.$auth.loading;
    }
  },
  methods: {
    ...mapActions('businessModule', ['getBusinessSetting']),    
    logout() {
      console.log('app: logout')
      console.log('logging out, redirecting to: ', `${process.env.VUE_APP_SITE_BASE_URL}/login`)
      
      ContextLogic.logout();

      this.$auth.logout({
        logoutParams: {
          returnTo: `${process.env.VUE_APP_SITE_BASE_URL}/login`
        }
      });
    },
    
    async getCompanyImageSetting(businessId, settingName){
      return await this.getBusinessSetting({businessId: businessId, settingName});
    }, 
    async getCompanyImage(businessId){
      
      let response = await this.getCompanyImageSetting(businessId, 'logo_Sml');
      if(!response.isError)
      {
          if (!response.value) 
          {
            response = await this.getCompanyImageSetting(businessId, 'logo_Lrg');
            if(!response.isError)
            {
                if (!response.value) 
                  return ''
                else
                  return response.value;
            }
          }          
          else
            return response.value;
      }
      else
        return '';      
    }, 
      projectManagerRoutePath() {
      if (this.$route.name == 'dashboard') {
        return '/projects'
      }
      else
        return `${this.$route.path}/projects`
    },
    entityManagerRoutePath() {
      if (this.$route.name == 'dashboard') {
        return '/entities/list'
      }
      else {
        return `${this.$route.path}/contractors`
      }
    },

    toggleSidebar(){
      this.collapsed=!this.collapsed;
      localStorage.setItem('sidebar-collapsed', JSON.stringify(this.collapsed))
    },
    
    ...mapMutations('contextModule', ['setMode']),
    
    
    //async setCurrentContextForUser(){
      // let currentUser = this.fetch(this.$auth.user.sub);
      // sessionStorage.setItem('user', currentUser);
      
      // let entity = this.fetchBusiness(currentUser.entityId);
      // ContextLogic.initContext(this);
      // if(this.current.mode == CORE.entities.enterprise)
      //   this.dashboardPath = '/projects';
      // else if(this.current.mode == CORE.entities.supplier)
      //   this.dashboardPath = `/company/${JSON.parse(sessionStorage.getItem('user')).entityId}/projects`;
      // else
      //   this.dashboardPath = `/contractor/${JSON.parse(sessionStorage.getItem('user')).entityId}/projects`;

      //}
  },
  async created() {
    console.log('app created')
    
  },
   async mounted(){
  }
}
 
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  height: 100vh;
  // overflow-y:auto;
}

nav {
  

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }

}
body, html {
            margin: 0;
            padding: 0;
            height: 100%;
            font-family: Arial, sans-serif;
        }
        // #app {
        //     display: flex;            
        //     min-height: 100vh;
        // }
        #main {
          display:inline-block;
            flex-grow: 1;
            padding: 20px;
        }
        #sidebar.collapsed {
            width: 50px;
        }
        .stickyBottom{
          position: fixed;
            left: 10px;
            bottom: 10px;
            z-index: 1;

        }



        .container {
            display: flex;
            min-height: 100vh;
        }

        .sidebar {
            width: 250px;
            background-color: #f0f0f0;
            padding: 20px;
        }

        .main-content {
            flex: 1;
            padding: 20px;
        }

        @media (max-width: 768px) {
            .container {
                flex-direction: column;
            }
            .sidebar {
                width: 100%;
            }
        }

</style>
