import { CORE, impactTypes } from '../_spec.js';
import * as THREE from 'three';
import BayBase from './BayBase.js';
import vHelper from '../helpers/vHelper.js'
import { Color, Quaternion, Vector3 } from 'three';
import BlueprintHelper from '../helpers/blueprintHelper.js';
import ComponentHelper from '../helpers/featureHelper.js'

import _3dColumnC from '../3d/ColumnC.js';
import layerHelper from '../helpers/layerHelper.js';
import Tri from '../3d/Tri.js';
import materialHelper from '../helpers/materialHelper.js';
import Sheeting from '../3d/Sheeting.js';
import SheetingHelper from '../helpers/SheetingHelper.js';
import EarcutDataManager from '../helpers/EarcutDataManager.js';
import MemHelper from '../helpers/memHelper.js';

export default class BaySide extends BayBase {
    constructor(
        des, 
        structureConfig,
        length, 
        maxHeight, 
        facePosZ, 
        trimMaterials, 
        shapePoints, 
        girtHeights, 
        typeDetail,
        frameLines, 
        supportsWainscot, 
        footerCollisions, 
        addToQuoteLayer,
        allowDripTrim,
        insulation,
        pitchRatio, 
        beamColor, 
        girtColor,
        options) {
        super(des, structureConfig,length, maxHeight, facePosZ, trimMaterials, undefined, supportsWainscot, footerCollisions, addToQuoteLayer,allowDripTrim,insulation,pitchRatio,
            undefined, // undefined is used in WallBase and shouldn't be and this doesn't matter for sidewalls
            beamColor,
            girtColor,
            options
            );
        this.length=length;
        this.wallMid = this.length/2;
        this.frameLines= frameLines;
        this.design.typeDetail = typeDetail;
        //if(!this.buildFacingPositiveZ)
            //pos.z-=this.wallThickness; // wall thickness (extrusion depth/amount)

        this.girtHeights = girtHeights;
        this.shapePoints=shapePoints
        // shift to match previous point 
        this.beamColor = beamColor
        this.girtColor = girtColor;
    }

    addWallExteriorPanelOld(colorMap, alphaMap, bumpMap){
        
        let tris = this.getWallTris();
        // 
        let material = this.getExteriorPanelMaterial(colorMap, alphaMap, bumpMap);
        material.side = this.getExteriorSide()
        
        tris.forEach((tri)=>{
            let triMesh = new THREE.Mesh(tri.geometry, material);        
            triMesh.position.x += this.length/2;
            triMesh.position.z = .1;
            triMesh.layers.set(CORE.layers.walls);
            triMesh.name='wall '+ tri.name;
            triMesh.renderOrder = 1;
            this.gWall.add(triMesh);
        })
    }

    addWallExteriorPanel(){
     
        let  wall = new EarcutDataManager();
        let eaveHeight = this.maxHeight;        
        let heightRatio = eaveHeight / this.ridgeHeight;
        let bottom = eaveHeight - this.design.height;
        let bottomHeightRatio = bottom / this.ridgeHeight;
        wall.setOutline([
            -this.length / 2, bottom,
            this.length / 2, bottom,
            this.length / 2, eaveHeight,
            -this.length / 2, eaveHeight,
        ]);

        this.holes.forEach((h)=>{
            wall.addSquareHole(h.pos.x-this.length/2, h.pos.y, h.dim.width, h.dim.height);
        })
        let wallData = wall.generate();        
        let sheeting = new Sheeting(
            CORE.preferences.des_levelOfDetail.value,
            wallData, 
            this.getWallFrontPlane(),
            this.getWallBackPlane(),
            CORE.layers.walls );

        sheeting.group.name = 'sheeting'
        sheeting.group.position.x =this.length/2;
        if(!this.inc)
            this.inc = .1;
        //this.inc += 10;
        if(this.buildFacingPositiveZ)
            sheeting.group.position.z = .1+this.inc;
            else
            sheeting.group.position.z = -.1;
        this.gWall.add(sheeting.group);
    }


    addWallInterior(colorMap, alphaMap, bumpMap){
        let tris  = this.getWallTris();

        let material = this.getInteriorMaterial(colorMap, alphaMap, bumpMap);
        material.side = this.getInteriorSide()
        //material.color = new Color(0xff0000);
        tris.forEach((tri)=>{
            let triMesh = new THREE.Mesh(tri.geometry, material);        
            triMesh.position.x += this.length/2;
            triMesh.position.z = -.1;
            triMesh.layers.set(CORE.layers.walls);
            triMesh.name='wall '+ tri.name;
            triMesh.renderOrder = 1;
            this.gWall.add(triMesh);
        })
    }

    getWallTris() {
        // make two  triangles            
        let eaveHeight = this.maxHeight;        
        let heightRatio = eaveHeight / this.ridgeHeight;
        let bottom = eaveHeight - this.design.height;
        let bottomHeightRatio = bottom / this.ridgeHeight;
        let tris = [];
        let r = new Tri(
            'bottom right triangle',
            [
                new Vector3(-this.length / 2, bottom, 0),
                new Vector3(this.length / 2, bottom, 0),
                new Vector3(this.length / 2, eaveHeight, 0),
            ],
            [
                0.0, bottomHeightRatio,
                1.0, bottomHeightRatio,
                1.0, heightRatio,
            ]
        );

        let l = new Tri(
            'middle left triangle',
            [
                new Vector3(-this.length / 2, bottom, 0),
                new Vector3(this.length / 2, eaveHeight, 0),
                new Vector3(-this.length / 2, eaveHeight, 0),
            ],
            [
                0.0, bottomHeightRatio,
                1.0, heightRatio,
                0.0, heightRatio
            ]
        );

        tris.push(r);
        tris.push(l);
        return tris;
    }

    migrate(design){
        super.migrate(design);

        switch(design.v)
        {
            case 1:
                //this.migrateTo2(dresign);
                break;
        }
    }
    
    migrateTo2(design){
        //super.migrateTo2(design);
        design.components.forEach((c)=>{
            c.pos.z=0;
        });
        design.v=2;
    }

    buildDynamicOnly(subComponentsToBuild, buildOpenings){
        super.buildDynamicOnly(subComponentsToBuild, buildOpenings);
        this.buildColumns();
    }

    removeDynamicOnly(){
        super.removeDynamicOnly();
        this.removeColumns();
    }


    buildColumns(){
        let subcomponents = this.design.getWallFramedOpenings();

        if(subcomponents.length>0){
            this.buildSubComponentColumns(subcomponents);
        }
    }

    removeColumns(){

        let columnsGroup = this.getColumnsGroup();
        if (columnsGroup)
            MemHelper.removeAllChildren(columnsGroup);
        
    }


    getWallRightPosition(){
        return this.length;
    }

    getWallLeftPosition(){
        return 0
    }


    //happens to be the same for front and back.
    positionChildAt(design, worldPosition){
        // get this worldPosition in terms of this parent component
        let wld = new Vector3().copy(worldPosition);
        let rel = this.getRelativePosition(wld)
        // then use a static method that accepts the design to constrain the position to exactly fixed header height
        let compType = ComponentHelper.getClassOfComponentType(design.type);
        return compType.updatePosition(rel, design);
    }

    build(){
      
        /*
        * subCompId allows for optimized rebuilding of the wall and a specific, changing sub-component
        */
        if(this.design.openWall===true)
            return;
        
        this.buildColumns();

        super.build(); // core wall 
    }

    buildSubComponentColumns(subcomponents){
        let columnPoints = [];
        // put columns on both sides of each overhead, open sides facing out.

        let componentColumnPoints = super.getIntrabaySubcomponentColumns(); // mainly for overhead doors
        columnPoints.push(...componentColumnPoints);
        try{
            this.addColumnsAtPoints(columnPoints);
        }catch(error){
            console.error("error adding column points: ",error);
        }
    }

    addColumnsAtPoints(columnPoints){
        let collisionZones = false; //TODO: allow collision zones to  not overlap the overhead door
        this.design.columnPositions = [];
        for(var ci = 0;ci<columnPoints.length;ci++){
            let columnPoint = columnPoints[ci];
            let columnPosX = columnPoint.pos;
            let side = columnPoint.side;
            
            //this.design.columnPositions.push(columnPoints[ci]);

            // x coordinate is c
            // y coordinate could be calculated from z coordinate, or just height
            // z coordinate is this side-wall's z coordinate
            let standardFrameLineMaterialDim = BlueprintHelper.getStandardFrameMaterialSize();
            let z = -(1+standardFrameLineMaterialDim.depth/2);
            if(!this.buildFacingPositiveZ)
            z*=-1;

            let bottomPos = new THREE.Vector3(columnPosX, this.shapePoints[0].y, z);
            let topPos = new THREE.Vector3(columnPosX, this.shapePoints[1].y, z);
            let length = topPos.y - bottomPos.y;
            	
            let rot = 0;
            if(side === 'right')
                rot = Math.PI;
            let col = new _3dColumnC(CORE.preferences.des_levelOfDetail.value, length, collisionZones, BlueprintHelper.getStandardFrameMaterialSize(), this.girtColor);
            col.group.position.copy(bottomPos.clone());
            col.group.rotation.y = rot;
            
            this.getColumnsGroup().add(col.group);
            layerHelper.enableLayer(col.group, CORE.layers.quote);
        }
    }
    
    defaultDesign(){
        let bayComponent = (this.design.type === CORE.components.baySideFront) ? CORE.components.baySideFront : CORE.components.baySideBack;
        return {
            collided : false,
            pos : new THREE.Vector3(),            
            parent: {                
                id: '0'
            },
            type: bayComponent,
            dim:{
                width: 0,
                height: 0
            },
            selected:false,
            wainscot:{
                enabled: true,
                height: 36,
                color: '0x8f8f8f'
            },
            openHeight:0
        }
    }

    static canEdit(){
        return false;
    }
    
    getCollisionClass(){
        return [CORE.collisions.classes.none];
    }
    

    isOnWall(cDesign){
        let toRight = (cDesign.pos.x + cDesign.dim.width / 2) < this.getWallRightPosition()
        let toLeft =      (cDesign.pos.x - cDesign.dim.width / 2) > this.getWallLeftPosition();
        return toRight && toLeft;
    }

    

    remove(){
        super.remove();
        this.removeColumns();        
    }

    getTypeDisplayName(){return this.getDescription();}
}