//import _3dRafterBase from '../3d/RafterBase.js'

import * as THREE from 'three';
import {CORE} from '../_spec.js'
import { Color, Vector2 } from 'three';
import vHelper from '../helpers/vHelper.js';
/*
WebGL uses Right-Hand rule, so points in CCW order => up (meaning the front face)



*/

export default class Arrow{

    constructor(y, color, roundTail=false){
        if(roundTail)
            this.buildRoundTail(y,color,-Math.PI/6 ,Math.PI/6, Math.PI/16, 1.5);
        else
            this.buildVersion2(y, color);

    }

    buildRoundTail(addY,color,startTheta, endTheta, incTheta, radius){

        let matArrow= new THREE.MeshBasicMaterial({ 
            color,
            transparent: false,
            opacity: .5
        });

        let arrowWidth = .5;        
        let arrowHeight = .35;

        this.meshPoint = this.getArrowHeadMesh(arrowWidth, arrowHeight);
        this.meshPoint.material = matArrow;
        this.meshPoint.rotation.z = -startTheta;
        this.meshPoint.position.x = Math.cos(-startTheta) * radius;
        this.meshPoint.position.y = Math.sin(-startTheta) * radius;
        
        this.meshPoint2 = this.getArrowHeadMesh(arrowWidth, arrowHeight);
        this.meshPoint2.material = matArrow;
        this.meshPoint2.rotation.z = Math.PI - endTheta;
        this.meshPoint2.position.x = Math.cos(-endTheta) * radius;
        this.meshPoint2.position.y = Math.sin(-endTheta) * radius;

        const shapeTail = new THREE.Shape();
        let width = .15;

        let pInners = [];
        let pOuters = [];

        for(var t = startTheta;t<=endTheta;t+=incTheta){
            let pIn = new Vector2(Math.cos(t)*(radius-width/2), Math.sin(t)*(radius-width/2));
            let pOut = new Vector2(Math.cos(t)*(radius+width/2), Math.sin(t)*(radius+width/2));
            pInners.push(pIn);
            pOuters.push(pOut);
        }

        if(pInners.length>0){
            pInners.forEach((p,i)=>{
                if(i==0)
                    shapeTail.moveTo(p.x,p.y);            
                else
                    shapeTail.lineTo(p.x,p.y);
            })            
        }
        if(pOuters.length>0)
        {
            pOuters.reverse();
            pOuters.forEach((p)=>{
                shapeTail.lineTo(p.x,p.y);
            })
        }

        const gTail = new THREE.ShapeGeometry(shapeTail);
        gTail.name= 'arrow tail';
        this.meshTail = new THREE.Mesh(gTail, matArrow);
        this.meshTail.name = `arrow tail`;

        if(addY)
        {
            this.meshPoint.position.y +=addY;
            //this.meshTail.position.y += addY;
        }
        else
        {
            //this.meshPoint.position.y +=1;
            //this.meshTail.position.y += 1;
        }

        // grabber
        let geoGrabber = new THREE.CircleGeometry(.3, 20);

        let meshGrabber = new THREE.Mesh(geoGrabber, matArrow);
        meshGrabber.name='grabber'
        meshGrabber.position.x = 1.5;
        //meshGrabber.position.set(0,0,-halfWidth/2);
        //meshGrabber.rotation.x=Math.PI/2-pitchRad;

        this.group = new THREE.Group();
        this.group.add(this.meshPoint);        
        this.group.add(this.meshPoint2);
        this.group.add(this.meshTail);
        this.group.add(meshGrabber);
    }

    getArrowHeadMesh(width, height){
        const shapeTip = new THREE.Shape();
        shapeTip.moveTo(+width/2, 0);
        shapeTip.lineTo(-width/2, 0);
        shapeTip.lineTo(0, height );

        const gPoint = new THREE.ShapeGeometry(shapeTip);
        gPoint.name= 'arrow point';
        let meshPoint = new THREE.Mesh(gPoint); 
        meshPoint.name = `arrow point`;
        return meshPoint
    }

    buildVersion2(addY, color){
        const shapeTip = new THREE.Shape();

        const x = 0;
        const y = 0;


        //let gTip=new THREE.CylinderGeometry(0,.25,.25,8,1,false,0,2*Math.PI);
        let matArrow= new THREE.MeshBasicMaterial({ 
            color,
            transparent: false,
            opacity: .5,
            side: THREE.DoubleSide,
        });

        let arrowWidth = .75;        
        let arrowHeight = .5;        
        shapeTip.moveTo(x - arrowWidth, y - arrowHeight);
        shapeTip.lineTo(x + arrowWidth, y - arrowHeight);
        shapeTip.lineTo(x, y );

        const gPoint = new THREE.ShapeGeometry(shapeTip);
        gPoint.name = 'arrow point2'
        this.meshPoint = new THREE.Mesh(gPoint, matArrow);
        this.meshPoint.name = `arrow point2`;

        const shapeTail = new THREE.Shape();
        let width = .25;
        let height = 1;
        shapeTail.moveTo(x - width, y - height);
        shapeTail.lineTo(x + width, y - height);
        shapeTail.lineTo(x + width, y + height);
        shapeTail.lineTo(x - width, y + height);

        const gTail = new THREE.ShapeGeometry(shapeTail);
        gTail.name = 'arrow tail2'
        this.meshTail = new THREE.Mesh(gTail, matArrow);
        this.meshTail.name = `arrow tail`;

        if(addY)
        {
            this.meshPoint.position.y +=addY;
            //this.meshTail.position.y += addY;
        }
        else
        {
            this.meshPoint.position.y +=1;
            //this.meshTail.position.y += 1;
        }

        this.group = new THREE.Group();
        this.group.add(this.meshPoint);
        this.group.add(this.meshTail);
    }

    buildVersion1(color){
        let gTip=new THREE.CylinderGeometry(0,.25,.25,8,1,false,0,2*Math.PI);
        let matArrow= new THREE.MeshBasicMaterial({ 
            color,
            transparent: false,
            opacity: .5
        });

        this.meshPoint = new THREE.Mesh(gTip, matArrow);
        this.meshPoint.name = `arrow tip`;
        this.meshPoint.position.y +=.5;

        let gTail=new THREE.CylinderGeometry(.12,.12,.25,8,1,false,0,2*Math.PI);
        this.meshTail = new THREE.Mesh(gTail, matArrow);
        this.meshTail.name = `arrow tail`;
        this.meshTail.position.y += .25;

        this.group = new THREE.Group();
        this.group.add(this.meshPoint);
        //this.group.add(this.meshTail);
    }

    remove(){
        
    }

    
}
