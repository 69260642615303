import {CORE} from '../_spec.js'
import Util from '../utility.js'

export default class Dictionary{
    /*
    this class represents a dictionary with terms that are 'defined' various ways in various contexts.
    Intent for use is to store alternate names for the same thing
    */

    constructor(){

        this.terms = [];
        this.contexts = {
            buildNet: 'buildNet'
        }
        this.addTerm(CORE.frame.types.bolt, 'Bolt Up'); // driven by bolt-up template
        this.addTerm(CORE.frame.types.weld, 'Weld up'); // driven by bolt-up template
        this.addTerm(CORE.frame.types.weldPlus, 'Weld PLUS'); // driven by bolt-up template
        this.addTerm(CORE.roof.types.gable, 'Gable'); // driven by bolt-up template
        this.addTerm(CORE.roof.types.slope, 'Single Slope'); // driven by bolt-up template
        this.addTerm(CORE.sides.frontSide, 'Front SW');
        this.addTerm(CORE.sides.backSide, 'Back SW');
        this.addTerm(CORE.sides.leftEnd, 'LEW');
        this.addTerm(CORE.sides.rightEnd, 'REW');

    }

    getUniqueTerm(nDef, nContext){
        
        // assume the buildNet context
        if(Util.isUndefined(nContext))
            nContext = this.contexts.buildNet;
        console.log('getUniqueTerm', nDef);

        for(var nTerm in this.terms) {
            let term = this.terms[nTerm];
            //console.log(term);
            let hasContext = term.hasOwnProperty(nContext);
            //console.log(hasContext);
            if(!hasContext)
                continue;
            let val = term[nContext];
            //console.log(val)
            if(val===nDef)
                return nTerm;        
        }

        return null;
    }

    getTerm(nTerm, nContext){

        if(Util.isUndefined(nTerm))
        {
            console.error(`A falsy term is not supported`);
            return;
        }

        if(Util.isUndefined(nContext))
            nContext = this.contexts.buildNet;        

        if(!this.terms.hasOwnProperty(nTerm)){
            console.error(`The term ${nTerm} is not in the dictionary.`);
            return;
        }

        let term = this.terms[nTerm];

        if(!term.hasOwnProperty(nContext)){
            console.error(`The term ${nTerm} has no context ${nContect}.`);
            return;
        }   

        return term[nContext];
    }
    
    addTerm(nTerm, name, nContext){
        
        if(!nTerm)
        {
            console.error(`The falsy term ${nTerm} is not supported`)
            return;
        }

        if(!name){
            console.error(`The falsy name ${name} is not supported`)
            return;
        }

        // assume the buildNet context
        if(Util.isUndefined(nContext))
            nContext = this.contexts.buildNet;

        
        if(!this.terms.hasOwnProperty(nTerm))
            this.terms[nTerm] = {};

        let term = this.terms[nTerm];
        
        if(term.hasOwnProperty(nContext))
            console.error(`The term ${nTerm} already has context ${nContext} defined as '${term[nContext]}'. Unable to redefine it as ${name}`);
        else{
            term[nContext] = name
            
        }
    }
}