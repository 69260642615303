//import _3dRafterBase from '../3d/RafterBase.js'

import * as THREE from 'three';
import {CORE} from '../_spec.js'
import vHelper from '../helpers/vHelper.js';
/*
WebGL uses Right-Hand rule, so points in CCW order => up (meaning the front face)



*/

export default class Tri{

    constructor(name, points, uvs, invertNormals=false){

        this.name = name;
        this.geometry=new THREE.BufferGeometry();
        let verts = vHelper.getFloat32ArrayFromPoints(points);

        var p = new THREE.Plane();
        p.setFromCoplanarPoints(points[0],points[1],points[2]);
        
        // four vertices where the 2D texture is pinned to 3D space
        let normals = [];

        // 4 vertex normals
        normals.push(p.normal.x, p.normal.y, p.normal.z);
        normals.push(p.normal.x, p.normal.y, p.normal.z);
        normals.push(p.normal.x, p.normal.y, p.normal.z);

        if(invertNormals)
        {
            for(let i =0;i<normals.length;i++)
                normals[i] = -normals[i];
        }

        this.geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( verts, 3 ) );
        this.geometry.setAttribute( 'normal', new THREE.Float32BufferAttribute( normals, 3 ) );
        this.geometry.setAttribute( 'uv', new THREE.Float32BufferAttribute( uvs, 2 ) );
        this.geometry.setIndex([0,1,2]);


    }
}