import * as THREE from 'three'
import _3dBase from './Base.js'
import {CORE} from '../_spec.js'
import MemHelper from '../helpers/memHelper.js';
import FoldedGeometryHelper from '../helpers/foldedGeometryHelper.js';
import layerHelper from '../helpers/layerHelper.js';

export default class RoofRidgeCap extends _3dBase {
//reference material:
//https://www.jagmetalsllc.com/wp-content/uploads/2018/04/rake-trim_catalogfinal.png

    constructor(length, width, pitchAngleDeg, material        
        ){
        super();
        
        let folds = [
            {dist:width},
            {deg:2*pitchAngleDeg},
            {dist:width}
        ];

        let fgh = new FoldedGeometryHelper(folds, material, false, false, false, length, 0,0,0,0);
        layerHelper.setGroup(fgh.group, CORE.layers.roof);
        this.group.add(fgh.group);
        this.group.name = 'roof ridge'
        
    }
    
    processGeometries(material){
        this.geometries.forEach((g)=>{
            let mesh = new THREE.Mesh(g, material);
            //mesh.layers.set(CORE.layers.roof)
            this.group.add(mesh)
        });        
    }

    
}