<template>
  <div class="dashboard">
    <div v-if="loading">Loading...</div>
    <div v-else>
      <div vif="mode == 1"></div>
      <img v-if="logo_Lrg" :src="logo_Lrg" alt="Logo" style="width:300px; vertical-align: bottom; "/>
      <h1 v-else style="display:inline-block">{{ linkLabels.dashboard }}</h1>
      <div class="card-group">


        <router-link :to="{ path: projectManagerRoutePath }">
          <NavCard>Projects</NavCard>
        </router-link><br /><br />

        <template v-if="mode === 0 || mode === 1">
          <router-link :to="{ path: entityManagerRoutePath }">
            <NavCard>
              {{ linkLabels.entityManager }}
            </NavCard>
          </router-link><br /><br />
        </template>
      </div>
      <br />
      <br />

      <div class="card-group">

        <template v-if="mode === 1">
          <router-link :to="{ path: colorRoutePath }">
            <NavCard>Colors</NavCard>
          </router-link>
        </template>

        <template v-if="mode === 1 || mode == 2">
          <router-link :to="{ path: pricingRoutePath }">
            <NavCard>Pricing</NavCard>
          </router-link>
          <router-link :to="entitySettingsRoutePath">
            <NavCard>{{ linkLabels.entitySettings }}</NavCard>
          </router-link>
        </template>

      </div>
    </div>
  </div>
</template>

<script>
import ContextLogic from '@/lib/contextLogic';
import { mapState, mapActions, mapMutations } from 'vuex';
import NavCard from '../components/NavCard.vue'
export default {
  props: {
  },
  data() {
    return {
      loading: true,
      logo_Lrg:''
    }
  },
  computed: {
    ...mapState('contextModule', ['current']),
    mode() {
      return this.current.mode
    },
    linkLabels() {
      console.log('dashboard mode', this.mode)
      if (this.mode === 0) {
        return {
          dashboard: 'System Dashboard',
          entityManager: 'Companies and Contractors',
          onboarding: 'Company Onboarding',
          entityInfo: 'Entity Info',
          entitySettings: 'Settings'
        };
      } else if (this.mode === 1) {
        return {
          dashboard: this.current.supplier?.businessName ?? "Loading",
          entityManager: 'Contractors',
          onboarding: 'Contractor Onboarding',
          entityInfo: 'Company Info',
          entitySettings: 'Settings'
        };
      } else if (this.mode === 2) {
        return {
          dashboard: this.current.contractor?.businessName ?? "def",
          onboarding: 'Employee Onboarding',
          entityInfo: 'Contractor Info',
          entitySettings: 'Settings'
        };
      }
      // Default fallback
      return {
        entityManager: 'Entity Manager',
        onboarding: 'Onboarding'
      };
    },
    pricingRoutePath() {
      if (this.$route.name == 'dashboard') {
        return '/pricing'
      }
      else
        return `${this.$route.path}/pricing`
    },
    colorRoutePath() {
      if (this.$route.name == 'dashboard') {
        return '/colors'
      }
      else
        return `${this.$route.path}/colors`
    },
    projectManagerRoutePath() {
      if (this.$route.name == 'dashboard') {
        return '/projects'
      }
      else
        return `${this.$route.path}/projects`
    },
    entityInfoRoutePath() {
      if (this.$route.name == 'dashboard') {
        let currentUser = JSON.parse(sessionStorage.getItem('user'));
        return `/entity/${currentUser.businessId}/info`;
      }
      else {
        return `${this.$route.path}/info`;
      }
    },
    entitySettingsRoutePath() {
      if (this.$route.name == 'dashboard') {
        let currentUser = JSON.parse(sessionStorage.getItem('user'));
        return `settings`;
      }
      else {
        return `${this.$route.path}/settings`;
      }
    },
    entityManagerRoutePath() {
      if (this.$route.name == 'dashboard') {
        return '/entities/list'
      }
      else {
        return `${this.$route.path}/contractors`
      }
    }
  },
  methods: {
    ...mapActions('businessModule', ['fetchBusiness','getBusinessSetting']),    
    beforeRouteUpdate(to, from, next) {
      console.log('beforeRouteUpdate');
      next();
    },
    async doInit() {

      console.log('dashboard: created');
      let redirectUrl = sessionStorage.getItem('redirectUrl')
      if (redirectUrl && redirectUrl != '/') {
        console.log('in dashboard, redirecting to: ', redirectUrl)
        sessionStorage.removeItem('redirectUrl');
        this.$router.push({ path: redirectUrl }) // relative path
      }
      console.log('in dashboard: ', redirectUrl)

      await ContextLogic.initContext(this);

      this.loading = false;
    },
    async loadCompanyLogo(){
      let response = await this.getBusinessSetting({businessId: this.current.supplier.id, settingName: 'logo_Lrg'});
      if(!response.isError){
      {
          let settings = response;
          this.setLogo(settings);
      }
    }    
  },
  setLogo(setting) {        
      if (typeof setting.value !== 'undefined') {
          this.logo_Lrg = setting.value;
      }
    },
  },
  async created() {
    await this.doInit();
    if(this.mode == 1){
      await this.loadCompanyLogo();
    }
  },
  mounted() {
    if (!this.$auth.isAuthenticated) {
      console.log('dashboard mounted: not authenticated');
      return;
    }
  },
  components: {
    NavCard
  }
}
</script>

<style scoped>
.dashboard {
  padding: 20px;
}

.card-group {
  /* margin-top: 20px; */
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.card-group a {
  margin-right: 10px;
  text-decoration: none;
  color: #3498db;
}
</style>