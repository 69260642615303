import {Earcut} from 'three/src/extras/Earcut.js'
import * as THREE from 'three';
import _3dBase from './Base.js'
import {CORE} from '../_spec.js'
import MemHelper from '../helpers/memHelper.js';
import vHelper from '../helpers/vHelper.js';
import Quad from './Quad.js';
import { faHeadSideCoughSlash } from '@fortawesome/pro-duotone-svg-icons';
import { Color, PlaneGeometry } from 'three';
import materialHelper from '../helpers/materialHelper.js';
import layerHelper from '../helpers/layerHelper.js';
import Tri from './Tri.js';
import EarcutHelper from '../helpers/EarcutHelper.js';

export default class Sheeting extends _3dBase {
    constructor(lod,earcutData,front,back,layer){
        super(undefined,undefined,undefined, lod);
        var earcutFacesList = Earcut.triangulate(earcutData.vertexList, earcutData.holeIndices, earcutData.dimensions);
      let { triangles, leftMost, width, bottomMost, height } = EarcutHelper.processEarcutData(earcutFacesList, earcutData.dimensions, earcutData.vertexList);

      this.buildSide(triangles, leftMost, width, bottomMost, height, front);      
      this.buildSide(triangles, leftMost, width, bottomMost, height, back);
      if(layer)
        layerHelper.setGroup(this.group, layer, true);
    }

    buildSide(triangles, leftMost, width, bottomMost, height, side){
      if(!side)
        return;
      
      if(side.material)
      {
        side.material.side = side.side;
        let repititionX = width/36, repititionY = 1;


        // we have to tell the tecture how many times to repeat in the X and y dimensions.
        // # of repetitions in X axis is the width of the wall in inches divided by the width represented by one texture (36" = 3')
        // # of repetitions in Y axis is 1 because we clamp the texture to the top and bottom of the highest and lowest vertex, respectively.
        if(side.material.map)
        {
          if(side.invert)
          {
            side.material.map.repeat.set(repititionX,repititionY);
          }
          else
          {
            side.material.map.repeat.set(repititionX,repititionY); 
          }
        }

        if(side.material.bumpMap)
        {
          if(side.invert){
            side.material.bumpMap.repeat.set(repititionX,repititionY);
          }
          else{
            side.material.bumpMap.repeat.set(repititionX,repititionY);
          }
        }
      }      
      let sideGroup = this.shapeFromPoints(triangles, leftMost, width, bottomMost, height, side);        
      this.group.add(sideGroup);
    }

    
    shapeFromPoints(triangles, leftMost, width, bottomMost, height, side) {
      let group = new THREE.Group();
      
      let obj = EarcutHelper.createGroup(triangles, leftMost, width, bottomMost, height, side);
      group.add(obj);
      
      if(side.wireframe)
      {
        let wireFrameMaterial = new THREE.MeshStandardMaterial({ color: 0x000000, wireframe: true});
        let objWireframe = EarcutHelper.createGroup(triangles, leftMost, width, bottomMost, height, {material:wireFrameMaterial});
        objWireframe.position.z = .1;
        group.add(objWireframe);
      }
  
      return group;
    }
}

