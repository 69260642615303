import * as THREE from 'three';
import _3dBase from './Base.js'
import {CORE} from '../_spec.js'
import MemHelper from '../helpers/memHelper.js';

export default class RidgeCapSmooth extends _3dBase{
    constructor(parentGroup, length, width, material, pos, qRot, pitchRad)
    {
        super(parentGroup);

        let halfWidth = width/2;
        let geoMain = new THREE.PlaneGeometry(length, halfWidth, 1,1);

        let meshLeft = new THREE.Mesh(geoMain, material);
        meshLeft.position.set(0,0,-halfWidth/2);
        meshLeft.rotation.x=Math.PI/2-pitchRad;

        let meshRight = new THREE.Mesh(geoMain, material);
        meshRight.position.set(0,0,halfWidth/2, 0, 0);
        meshRight.rotation.x=Math.PI/2+pitchRad;

        meshLeft.layers.set(CORE.layers.walls)
        meshRight.layers.set(CORE.layers.walls)
        this.group.add(meshLeft);
        this.group.add(meshRight);  

        this.group.rotation.setFromQuaternion(qRot)
        this.group.position.copy(pos);
    }
}