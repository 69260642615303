<template>
      <div id="sidebar" style="background-color: #223f91; text-align: left;padding: 5px;" >
        <slot></slot>
      </div>        
  </template>
  
  <script>
  export default {
    name: 'Sidebar2',
    data() {
      return {
        isCollapsed: false,
        collapsedWidth: 50,
        expandedWidth: 250
      };
    },
    computed: {
      sidebarWidth() {
        return this.isCollapsed ? this.collapsedWidth : this.expandedWidth;
      }
    },
    methods: {
      toggleSidebar() {
        this.isCollapsed = !this.isCollapsed;
      }
    }
  };
  </script>
  
  <style scoped>
  .layout {
    display: flex;
    height: 100vh;
  }
  
  
  .sb2l, .sb2l * {
      color:#abb4e0;
      font-size:large;
    }


  .sidebar {
    background-color: lightblue;
    transition: width 0.3s ease;
    position: relative;
    
  }
  
  .sidebar-content {
    padding: 20px;
    overflow-x: hidden;
  }
  
  .toggle-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
  }
  
  .main-content {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
  }
  </style>