import _3dBase from './Base.js'
import * as THREE from 'three';
import { CORE } from '../_spec.js';
import {Vector2,  Vector3 } from 'three';
import MemHelper from '../helpers/memHelper.js';
import ShapeHelper from '../helpers/shapeHelper.js'
import EarcutDataManager from '../helpers/EarcutDataManager.js';
import EarcutHelper from '../helpers/EarcutHelper.js';
import {Earcut} from 'three/src/extras/Earcut.js'
import _3dGirtHoriC from './GirtHoriC.js'
import _3dTrimOpeningFace from './TrimOpeningFace.js';
import _3dTrimOpeningJamb from './TrimOpeningJamb.js';
import _oDoorSectional from '../o/DoorSectional.js';
import materialHelper from '../helpers/materialHelper.js';
import layerHelper from '../helpers/layerHelper.js';

export default class DoorSectional extends _3dBase{

    constructor(lod, width, height, trimMaterial, girtColor, excludeOh, ohJambLiner, style){
        super(undefined, undefined, undefined, lod)

        this.build(width, height, trimMaterial, girtColor, excludeOh, ohJambLiner, style)
    }

    build(width, height, trimMaterial, girtColor, excludeOh, ohJambLiner, style){

        if(this.isLowDetail())
            this.buildSectional_LD(width, height, trimMaterial, girtColor, excludeOh, ohJambLiner, style)
        else
            this.buildSectional_HD(width, height, trimMaterial, girtColor, excludeOh, ohJambLiner, style)    
    }

    buildSectional_LD(width, height, trimMaterial, girtColor, excludeOh, ohJambLiner, style) {
        // use the design to build the component

        const jambTrimThickness = .5; 
        const jambTrimWidth = 8;

        if(excludeOh!==true){
            
            // how many sections to use for this height
            const section_thickness = 1;
            const sec_min = 1.5*12;
            const section_max = 2*12;
            let sectionCount = Math.ceil(height/section_max);
            let sectionHeight = height / sectionCount;

            // create the texture for the garage door
            let textureDoor = CORE.textures.doorRollup.clone();
            textureDoor.wrapS = THREE.ClampToEdgeWrapping;
            textureDoor.wrapT = THREE.RepeatWrapping
            textureDoor.encoding = THREE.sRGBEncoding;
            textureDoor.anisotropy = 16;

            let repeatX = sectionCount / 4; // the texture has 4 "sections" in the image
            textureDoor.repeat.set(1, repeatX);
            let matDoor = materialHelper.getdoorRollupDoorMaterial(0xFFFFFF,textureDoor);

            if (style == CORE.doorSectional.styles.lightA) {
                
                // use earcutting to make holes in a section to make a window
                let earcut = new EarcutDataManager();
                let corners = [
                    width/2, height/2, 
                    -width/2, height/2, 
                    -width/2, -height/2, 
                    width/2, -height/2
                ];
                earcut.setOutline(corners)
                
                const window_frame_width = 1;
                const window_max_width = 3*12 + 2*window_frame_width; // adjust 1" on either side of window
                let windowCount = Math.ceil(width/window_max_width)
                let windowWidth = width / windowCount;
                let windowY = (height+sectionHeight)/2 - Math.ceil(sectionCount/2)*sectionHeight
                for (let i = 0;  i < windowCount; i++) {
                    let locX = (-width+windowWidth)/2 + i * windowWidth;
                    earcut.addSquareHole(locX, windowY, windowWidth - 2*window_frame_width, sectionHeight - 2*window_frame_width)
                }

                let earcutData = earcut.generate();
                
                let earcutFaces = Earcut.triangulate(earcutData.vertexList, earcutData.holeIndices, earcutData.dimensions);
                let { triangles, leftMost, widthEC = width, bottomMost, heightEC = height } = EarcutHelper.processEarcutData(earcutFaces, earcutData.dimensions, earcutData.vertexList);

                let objDoor = EarcutHelper.createGroup(triangles, leftMost, widthEC, bottomMost, heightEC, {material: matDoor});
                objDoor.position.set(0,0,-jambTrimWidth);
                this.group.add(objDoor);

            } else {
                var geoDoor = new THREE.BoxGeometry(width, height, 1);
                let meshDoor = new THREE.Mesh(geoDoor, matDoor);
                meshDoor.name = 'sectional door panel'
                meshDoor.position.set(0,0,-jambTrimWidth);
                this.group.add(meshDoor)
            }
        }

        // trim
        let dim = new THREE.Vector3(width, height, 1);
        let dimTrimMaterial = new THREE.Vector3(2,2,1.5);
        let trim = new _3dTrimOpeningFace(this.lod, dim, dimTrimMaterial, trimMaterial, false);        
        layerHelper.enableLayer(trim.group, CORE.layers.quote);
        
        let jamb;
        if (ohJambLiner)
        {
            const zFightingAdjustment = .04;
            let dimJamb = dim.clone();
            dimJamb.x += zFightingAdjustment;
            jamb = new _3dTrimOpeningJamb(this.lod, dimJamb, jambTrimThickness, jambTrimWidth+zFightingAdjustment, trimMaterial);
            layerHelper.enableLayer(jamb.group, CORE.layers.quote);
        }

        /// FRAMING 
        let gFrame = new THREE.Group();
        gFrame.name = 'sectional door frame'
        this.group.add(gFrame)
        let frameInset = 1;
        let header = new _3dGirtHoriC(this.lod, new THREE.Vector3(0, 0, 0), new THREE.Vector3(width, 0, 0), CORE.dir.rel.up, false, girtColor);
        header.build();
        gFrame.add(header.group);
        
        header.group.position.set(0,height/2,-header.dim.web/2-frameInset);

        layerHelper.setGroup(this.group, CORE.layers.doorsOverhead, true);
        layerHelper.setGroup(gFrame, CORE.layers.frame);
        this.group.add(trim.group);
        if(jamb)
            this.group.add(jamb.group);
    }


    buildSectional_HD(width, height, trimMaterial, girtColor, excludeOh, ohJambLiner, style) {
        // use the design to build the component

        const jambTrimThickness = .5; 
        const jambTrimWidth = 8;

        if(excludeOh!==true){
            
            // how many sections to use for this height
            const section_thickness = 1;
            const sec_min = 1.5*12;
            const section_max = 2*12;
            let sectionCount = Math.ceil(height/section_max);
            let sectionHeight = height / sectionCount;

            // create the texture for the garage door
            let textureDoor = CORE.textures.doorRollup.clone();
            textureDoor.wrapS = THREE.ClampToEdgeWrapping;
            textureDoor.wrapT = THREE.RepeatWrapping
            textureDoor.encoding = THREE.sRGBEncoding;
            textureDoor.anisotropy = 16;

            let repeatX = sectionCount / 4; // the texture has 4 "sections" in the image
            textureDoor.repeat.set(1, repeatX);
            let matDoor = materialHelper.getdoorRollupDoorMaterial(0xFFFFFF,textureDoor);

            if (style == CORE.doorSectional.styles.lightA) {
                
                // use earcutting to make holes in a section to make a window
                let earcut = new EarcutDataManager();
                let corners = [
                    width/2, height/2, 
                    -width/2, height/2, 
                    -width/2, -height/2, 
                    width/2, -height/2
                ];
                earcut.setOutline(corners)
                
                const window_max_width = 3*12 + 2; // adjust 1' on either side of window
                let windowCount = Math.ceil(width/window_max_width)
                let windowWidth = width / windowCount;
                let windowY = (height+sectionHeight)/2 - Math.ceil(sectionCount/2)*sectionHeight
                for (let i = 0;  i < windowCount; i++) {
                    let locX = (-width+windowWidth)/2 + i * windowWidth;
                    earcut.addSquareHole(locX, windowY, windowWidth - 2, sectionHeight - 2)
                }

                let earcutData = earcut.generate();
                
                let earcutFaces = Earcut.triangulate(earcutData.vertexList, earcutData.holeIndices, earcutData.dimensions);
                let { triangles, leftMost, widthEC = width, bottomMost, heightEC = height } = EarcutHelper.processEarcutData(earcutFaces, earcutData.dimensions, earcutData.vertexList);

                let objDoor = EarcutHelper.createGroup(triangles, leftMost, widthEC, bottomMost, heightEC, {material: matDoor});
                objDoor.position.set(0,0,-jambTrimWidth);
                this.group.add(objDoor);

                // create the windows

            } else {
                var geoDoor = new THREE.BoxGeometry(width, height, 1);
                let meshDoor = new THREE.Mesh(geoDoor, matDoor);
                meshDoor.name = 'sectional door panel'
                meshDoor.position.set(0,0,-jambTrimWidth);
                this.group.add(meshDoor)
            }
        }

        // trim
        let dim = new THREE.Vector3(width, height, 1);
        let dimTrimMaterial = new THREE.Vector3(2,2,1.5);
        let trim = new _3dTrimOpeningFace(this.lod, dim, dimTrimMaterial, trimMaterial, false);
        layerHelper.enableLayer(trim.group, CORE.layers.quote);

        let jamb
        if (ohJambLiner)
        {
            const zFightingAdjustment = .04;
            let dimJamb = dim.clone();
            dimJamb.x += zFightingAdjustment;
            jamb = new _3dTrimOpeningJamb(this.lod, dimJamb, jambTrimThickness, jambTrimWidth+zFightingAdjustment, trimMaterial);
            layerHelper.enableLayer(jamb.group, CORE.layers.quote);
        }

        /// FRAMING 
        let gFrame = new THREE.Group();
        gFrame.name = 'sectional door frame'
        this.group.add(gFrame)
        let frameInset = 1;
        let header = new _3dGirtHoriC(this.lod, new THREE.Vector3(0, 0, 0), new THREE.Vector3(width, 0, 0), CORE.dir.rel.up, false, girtColor);
        header.build();
        gFrame.add(header.group);
        header.group.position.set(0,height/2,-header.dim.web/2-frameInset);

        layerHelper.setGroup(this.group, CORE.layers.doorsOverhead, true);
        layerHelper.setGroup(gFrame, CORE.layers.frame);
        this.group.add(trim.group);        
        if(jamb)
            this.group.add(jamb.group);  
    }
}
