import * as THREE from 'three';
import ColumnBase from './ColumnBase.js'
import {CORE} from '../_spec.js'
import { Vector3 } from 'three';
import CollisionHelper from '../helpers/CollisionHelper.js';
import MemHelper from '../helpers/memHelper.js';
import materialHelper from '../helpers/materialHelper.js';
import _3dBase from './Base.js';
import Sheeting from './Sheeting.js';
import EarcutDataManager from '../helpers/EarcutDataManager.js';
import SheetingHelper from '../helpers/SheetingHelper.js';
import ColumnC from './ColumnC.js';
import RafterC from './RafterC.js';
import GirtHoriC from './GirtHoriC.js';
import Girt2 from './Girt2.js';
import ColumnSquare from './ColumnSquare.js';
import layerHelper from '../helpers/layerHelper.js';
import TrimOpeningFace from './TrimOpeningFace.js';

export default class HangarDoorSection extends _3dBase{
	constructor(lod, width, height, color, trimMaterial){
		super(undefined, undefined, undefined, lod);
		this.group.name = 'hangar door section';
		// this.group.add(
		// 	new THREE.Mesh(
		// 		new THREE.CylinderGeometry(20,20,40,10,10,false,0,Math.PI*2), 
		// 		new THREE.MeshBasicMaterial({color: 0xff0000})
		// 		)
		// 	);
		this.width = width;
		this.height = height;		
		this.color = color;
		this.trimMaterial = trimMaterial;
		
		this.build();
    }

    build(){
		if(this.isLowDetail())
			this.build_LD();
		else
		{
			this.build_HD();
			this.group.castShadow=true;
		}
	}

	build_HD() {
		let girtDepth = 8;
		let halfGirtDepth = girtDepth/2;
		let girtWidth = 4;
		let halfGirtWidth = girtWidth/2;

		let doorPoints = this.getPoints();
        let doorDataManager = new EarcutDataManager();
        doorDataManager.setOutline(doorPoints);

        let doorData = doorDataManager.generate();

		let exteriorMaterial = materialHelper.getExteriorPanelPbrMaterial(this.color)
        let doorPanelExterior = SheetingHelper.defineFrontPlane(exteriorMaterial);
		let interiorMaterial = materialHelper.getInteriorPanelPbrMaterial(0xEEEEED);
        let doorPanelInterior = SheetingHelper.defineBackPlane(interiorMaterial);

        let doorSheeting = new Sheeting(
            this.lod,
            doorData,
            doorPanelExterior,
            doorPanelInterior,
            CORE.layers.walls);
        doorSheeting.group.position.z +=girtWidth+.1;


        this.group.add(doorSheeting.group);

		let tof = new TrimOpeningFace(this.lod, new THREE.Vector2(this.width, this.height), new THREE.Vector2(2,2), this.trimMaterial, true, false)
		tof.group.position.z = girtWidth+.2;
		this.group.add(tof.group);

		let framingGroup = new THREE.Group();

		let header = new Girt2(this.lod, this.width, false, CORE.frame.redOxideColor);
		header.group.rotation.x = Math.PI;
		//header.group.position.x = -this.width/2;
		header.group.position.y = this.height/2 - halfGirtDepth;
		//header.group.position.z = girtWidth;
		framingGroup.add(header.group);

		let footer = new Girt2(this.lod, this.width, false, CORE.frame.redOxideColor);
		footer.group.rotation.x = Math.PI;
		//footer.group.rotation.z = Math.PI/2;
		//footer.group.position.x = -this.width/2;
		footer.group.position.y = -this.height/2 + halfGirtDepth;
		//footer.group.position.z = girtWidth;
		framingGroup.add(footer.group);

		
		
		let squareDimension = 2;		
		let halfSquareDim = squareDimension/2;

		let startHeight =-this.height/2 + halfSquareDim;
		let finishHeight = this.height/2 - halfSquareDim;
		let distanceHeight = finishHeight - startHeight;
		let maxVerticalSpacing = 36;
		let actualVerticalSpaces =  Math.round(distanceHeight / maxVerticalSpacing);
		let actualVerticalSpacing = distanceHeight / actualVerticalSpaces;
		for(let height = startHeight; height<= finishHeight; height+=actualVerticalSpacing){
			let c = new ColumnSquare(this.width-squareDimension, materialHelper.getFrameMaterial_HD(), squareDimension, CORE.layers.doorsOverhead);
			c.group.rotation.z = Math.PI/2;
			c.group.position.x = this.width/2;
			c.group.position.y = height;
			c.group.position.z = -squareDimension/2;
			framingGroup.add(c.group);
		}
		
		let left = -this.width/2 + halfSquareDim;
		let right = this.width/2 - halfSquareDim;
		let distanceWidth = right - left;
		let maxHorizontalSpacing = 48;
		let actualHorizontalSpaces =  Math.round(distanceWidth / maxHorizontalSpacing);
		let actualHorizontalSpacing = distanceWidth / actualHorizontalSpaces;
		for(let width = left; width <= right; width+=actualHorizontalSpacing){
			let c = new ColumnSquare(this.height-squareDimension, materialHelper.getFrameMaterial_HD(), squareDimension, CORE.layers.doorsOverhead);
			c.group.position.x = width;
			c.group.position.y = -this.height/2 + halfSquareDim;
			c.group.position.z = -squareDimension/2;
			framingGroup.add(c.group);
		}

		layerHelper.setGroup(framingGroup, CORE.layers.frame);
		framingGroup.position.z += girtWidth;
		this.group.add(framingGroup);
	}


	getPoints(){
		let hw = this.width/2;
		let hh = this.height/2;
		return [
			//bottom left
			-hw,
			-hh,

			// bottom right
			hw,
			-hh,

			// top right
			hw,
			hh,

			// top left
			-hw,
			hh

		]
	}

	build_LD() {
		let girtDepth = 8;
		let halfGirtDepth = girtDepth/2;
		let girtWidth = 4;
		let halfGirtWidth = girtWidth/2;

		let doorPoints = this.getPoints();
        let doorDataManager = new EarcutDataManager();
        doorDataManager.setOutline(doorPoints);

        let doorData = doorDataManager.generate();

		let exteriorMaterial = materialHelper.getExteriorPanelPbrMaterial(this.color)
        let doorPanelExterior = SheetingHelper.defineFrontPlane(exteriorMaterial);
		let interiorMaterial = materialHelper.getInteriorPanelPbrMaterial(0xEEEEED);
        let doorPanelInterior = SheetingHelper.defineBackPlane(interiorMaterial);

        let doorSheeting = new Sheeting(
            this.lod,
            doorData,
            doorPanelExterior,
            doorPanelInterior,
            CORE.layers.walls);
        doorSheeting.group.position.z +=girtWidth+.1;


        this.group.add(doorSheeting.group);

		let tof = new TrimOpeningFace(this.lod, new THREE.Vector2(this.width, this.height), new THREE.Vector2(2,2), this.trimMaterial, true, false)
		tof.group.position.z = girtWidth+.2;
		this.group.add(tof.group);

		let framingGroup = new THREE.Group();

		let header = new Girt2(this.lod, this.width, false, CORE.frame.redOxideColor);
		header.group.rotation.x = Math.PI;
		//header.group.position.x = -this.width/2;
		header.group.position.y = this.height/2 - halfGirtDepth;
		//header.group.position.z = girtWidth;
		framingGroup.add(header.group);

		let footer = new Girt2(this.lod, this.width, false, CORE.frame.redOxideColor);
		footer.group.rotation.x = Math.PI;
		//footer.group.rotation.z = Math.PI/2;
		//footer.group.position.x = -this.width/2;
		footer.group.position.y = -this.height/2 + halfGirtDepth;
		//footer.group.position.z = girtWidth;
		framingGroup.add(footer.group);

		
		
		let squareDimension = 2;		
		let halfSquareDim = squareDimension/2;

		let startHeight =-this.height/2 + halfSquareDim;
		let finishHeight = this.height/2 - halfSquareDim;
		let distanceHeight = finishHeight - startHeight;
		let maxVerticalSpacing = 36;
		let actualVerticalSpaces =  Math.round(distanceHeight / maxVerticalSpacing);
		let actualVerticalSpacing = distanceHeight / actualVerticalSpaces;
		for(let height = startHeight; height<= finishHeight; height+=actualVerticalSpacing){
			let c = new ColumnSquare(this.width-squareDimension, materialHelper.getFrameMaterial_LD(), squareDimension, CORE.layers.doorsOverhead);
			c.group.rotation.z = Math.PI/2;
			c.group.position.x = this.width/2;
			c.group.position.y = height;
			c.group.position.z = -squareDimension/2;
			framingGroup.add(c.group);
		}
		
		let left = -this.width/2 + halfSquareDim;
		let right = this.width/2 - halfSquareDim;
		let distanceWidth = right - left;
		let maxHorizontalSpacing = 48;
		let actualHorizontalSpaces =  Math.round(distanceWidth / maxHorizontalSpacing);
		let actualHorizontalSpacing = distanceWidth / actualHorizontalSpaces;
		for(let width = left; width <= right; width+=actualHorizontalSpacing){
			let c = new ColumnSquare(this.height-squareDimension, materialHelper.getFrameMaterial_LD(), squareDimension, CORE.layers.doorsOverhead);
			c.group.position.x = width;
			c.group.position.y = -this.height/2 + halfSquareDim;
			c.group.position.z = -squareDimension/2;
			framingGroup.add(c.group);
		}

		layerHelper.setGroup(framingGroup, CORE.layers.frame);
		framingGroup.position.z += girtWidth;
		this.group.add(framingGroup);
	

	}
}
