<template>
  <div class="notFound">
    
    <router-link v-if="!$auth.isAuthenticated" to="/">
      Please log into Steel Ninja
    </router-link>

    <div v-if="$auth.isAuthenticated">
      <h1>Sorry, like any good ninja, this page cannot be found.</h1>
      <h3>Please double check your link or try again later.</h3>
    </div>
  </div>
</template>

<script>
export default{
  name: 'App',
  methods:{

  }
}
</script>