
import * as THREE from 'three';
import {CORE} from '../_spec.js'
import { Vector3, Quaternion, Matrix4, Matrix3 } from 'three';
import MatrixHelper from '../helpers/matrixHelper.js';
import CollisionHelper from '../helpers/CollisionHelper.js';
import MemHelper from '../helpers/memHelper.js';
import layerHelper from '../helpers/layerHelper.js'
import _3dBase from './Base.js';
import materialHelper from '../helpers/materialHelper.js';


/* https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Ibeam.svg/520px-Ibeam.svg.png

		|---------FLANGE---------|__
		************************** |
	___	************************** |
	|				            ** | 
	|				            ** |              
	|				            ** | 
	WEB				            ** DEPTH
	|				            ** | 
	|				            ** | 
	|				            ** | 
	___				            ** | 
		************************** |
		**************************__
*/

export default class FastenerHex extends _3dBase{


    constructor(parentGroup, height, pos, qRot){
        super(parentGroup, pos, qRot);
        this.height = height;
        this.build();
    }

    build(){
         //TODO: this is duplicate code with rafterTapered
        
     // deadbolt        
     var geoNut = new THREE.CylinderGeometry( 
         1, // radius
         1, // radius
         this.height, // height
         6 // segments
         );

    let mat = materialHelper.materials.bolt;
    mat = materialHelper.getSteelFastenerMaterial_LD()

         
     let meshNut = new THREE.Mesh(geoNut, mat);
     meshNut.name = 'nut';
     meshNut.layers.set(CORE.layers.frame)
     meshNut.layers.enable(CORE.layers.quote)
     this.group.add(meshNut);
    }
  
}
