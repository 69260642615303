<!-- AddressInput.vue -->
<template>
  <div class="address-input-container">
      <!-- Input Mode -->
      <div v-if="isInEditMode" class="input-mode" style="display:flex; gap:1 rem">
        <div style="width:50px;margin:auto;color:red;font-size:16px;cursor:pointer" id='cancel' @click="isInEditMode=false" class="" title="Cancel address search">✖️</div>
        <input
          ref="addressInput"
          v-model="address"
          type="text"
          placeholder="Search for city, county, or zipcode"
          @input="handleInput"
          @keydown="handleKeypress"
          class="address-input"
          style="flex:1"
        />
        <div v-if="error" class="error-message">
          {{ error }}
        </div>
      </div>
      
      <!-- Display Mode -->
      <div v-else class="display-mode" style="display:flex; gap:1 rem">        
        <div 
          class="selected-address"
          :class="{editable: editable}"
          
          @click="enterInputMode"
          style="flex:1"
        >
          {{ resultAddress }}

          <!-- <div class="address-details">
            <p>Street Address: {{ addressDetails.streetAddress }}</p>
            <p>City: {{ addressDetails.city }}</p>
            <p>State: {{ addressDetails.state }}</p>
            <p>Zip Code: {{ addressDetails.zipCode }}</p>
          </div> -->
        </div>
        <div style="width:200px;overflow:hidden;margin-left:10px" class="input-group" >
          <span>Zipcode:</span><input type="number" :value="resultZipCode" style="width:60px" disabled> 
        </div>
        

        
      </div>
  </div>
</template>

<script>
export default {
  name: 'AddressInput',
  props: ['editMode', 'editable'],
  computed:{
    // showZip(){
    //   return !this.addressDetails.formattedAddress.includes(this.addressDetails.zipCode);
    // },
    resultAddress(){
      if(!this.addressDetails)
        return '';
      return this.addressDetails.formattedAddress
    },
    resultZipCode(){
      if(!this.addressDetails)
        return '';

      if(!this.addressDetails.zipCode){
        return '✖️'        
      }
      return this.addressDetails.zipCode
    }
  },
  watch:{    
    editMode(){
      this.isInEditMode = this.editMode;
    }
  },
  data() {
    return {
      address: '',
      addressDetails: null,
      autocomplete: null,
      error: null,
      geocoder: null,
      isScriptLoaded: false,
      map: null,
      marker: null,
      isInEditMode: false,
      isMapInitialized: false
    }
  },
  created() {
    this.loadGoogleMapsScript()
    
  },
  methods: {
    setAddressDetails(addressData){
      this.addressDetails = {
      formattedAddress: addressData.formattedAddress,
      zipCode: addressData.zipcode
    } 
    },
    loadGoogleMapsScript() {
      if (window.google && window.google.maps) {
        this.isScriptLoaded = true
        this.$nextTick(() => {
          this.initializeGoogleMaps()
        })
        return
      }

      const API_KEY = process.env.VUE_APP_GOOGLE_MAPS_API_KEY
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`
      script.async = true
      script.defer = true

      script.onload = () => {
        this.isScriptLoaded = true
        this.$nextTick(() => {
          this.initializeGoogleMaps()
        })
      }

      script.onerror = () => {
        this.error = 'Failed to load Google Maps'
        console.error('Google Maps script failed to load')
      }

      document.head.appendChild(script)
    },
    initializeGoogleMaps() {
      
        this.geocoder = new google.maps.Geocoder()

        if(this.isInEditMode)
          this.wireUpInputField();
        
    },
    async initializeMap() {
      if (!this.$refs.mapContainer || this.isMapInitialized) return

      // Default center (e.g., US center)
      const defaultCenter = { lat: 37.0902, lng: -95.7129 }
      
      this.map = new google.maps.Map(this.$refs.mapContainer, {
        zoom: 4,
        center: defaultCenter,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        mapId: 'project-site'
      })

      const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");
      // Create a marker but don't set its position yet
      this.marker = new google.maps.marker.AdvancedMarkerElement({
        map: this.map,
        // visible: false,
        title: 'project-location',
      })

      this.isMapInitialized = true
    },
    async handlePlaceSelect() {
      try {
        const place = this.autocomplete.getPlace()
        
        if (!place.geometry) {
          this.error = 'Please select an address from the dropdown'
          return
        }

        const addressComponents = place.address_components
        let zipCode = ''
        let streetNumber = ''
        let route = ''
        let city = ''
        let state = ''

        // Extract address components
        addressComponents.forEach(component => {
          const types = component.types

          if (types.includes('postal_code')) {
            zipCode = component.long_name
          }
          if (types.includes('street_number')) {
            streetNumber = component.long_name
          }
          if (types.includes('route')) {
            route = component.long_name
          }
          if (types.includes('locality')) {
            city = component.long_name
          }
          if (types.includes('administrative_area_level_1')) {
            state = component.short_name
          }
        })

        // If no zip code was found, get it using geocoding
        if (!zipCode) {
          zipCode = await this.getZipCodeFromGeocoding(
            place.geometry.location.lat(),
            place.geometry.location.lng()
          )
        }

        this.addressDetails = {
          formattedAddress: place.formatted_address,
          streetAddress: `${streetNumber} ${route}`.trim(),
          city,
          state,
          zipCode,
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng()
        }

        // Switch to display mode first
        this.isInEditMode = false
        
        // Emit the address details
        this.$emit('address-selected', this.addressDetails)
        this.error = null
      } catch (error) {
        this.error = 'Error processing selected address'
        console.error('Error processing address:', error)
      }
    },
    updateMapLocation() {
      if (!this.map || !this.addressDetails) return

      const location = {
        lat: this.addressDetails.latitude,
        lng: this.addressDetails.longitude
      }
      this.map.setCenter(location)
      this.map.setZoom(11)
      this.marker.position = location;
      // this.marker.setVisible(true)
    },
    async getZipCodeFromGeocoding(lat, lng) {
      try {
        const response = await new Promise((resolve, reject) => {
          this.geocoder.geocode(
            {
              location: { lat, lng }
            },
            (results, status) => {
              if (status === 'OK') {
                resolve(results)
              } else {
                reject(new Error(`Geocoding failed: ${status}`))
              }
            }
          )
        })

        for (const result of response) {
          for (const component of result.address_components) {
            if (component.types.includes('postal_code')) {
              return component.long_name
            }
          }
        }
        
        throw new Error('No zip code found')
      } catch (error) {
        console.error('Error getting zip code:', error)
        return ''
      }    
    },
    handleKeypress(event){
      if(event.code == 'Escape')
        this.isInEditMode=false;
    },
    handleInput(event) {
      this.address = event.target.value
      if (!this.address) {
        this.addressDetails = null
        if (this.marker) {
          this.marker.setVisible(false)
        }
      }
    },
    enterInputMode() {
      if(!this.editable)
        return;
      this.isInEditMode = true
      this.address = this.addressDetails.formattedAddress;
        // Reset and reinitialize autocomplete after the input is rendered
  this.$nextTick(() => {
    if (this.$refs.addressInput) {
      this.$refs.addressInput.focus();
      // Reinitialize autocomplete with the new input element
      this.wireUpInputField();
    }
  });
},
  wireUpInputField(){
    try {
    this.autocomplete = new google.maps.places.Autocomplete(
        this.$refs.addressInput,
        {
          types: ['(regions)'],
          componentRestrictions: { country: 'US' }
        }
      );
      
      // Reattach the place_changed listener
      this.autocomplete.addListener('place_changed', () => {
        this.handlePlaceSelect();
      });
      } catch (error) {
        this.error = 'Error initializing Google Maps'
        console.error('Google Maps initialization error:', error)
      }
  }

  }
}
</script>

<style scoped>
.address-input-container {
  width: 100%;  
  margin: 0 auto;
}

.input-mode .address-input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 8px;
}

.display-mode .selected-address {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size:14px;
  background-color: #f8f9fa;
  cursor:default;
  text-align: left;
  transition: background-color 0.2s;
}

.display-mode .editable.selected-address {
  cursor:pointer;
}

.display-mode .editable.selected-address:hover {
  background-color: #e9ecef;
}

.error-message {
  color: #dc3545;
  font-size: 14px;
  margin-top: 4px;
}

.map-container {
  width: 100%;
  height: 300px;
  margin: 16px 0;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #ccc;
}

.map-hidden {
  visibility: hidden;
  height: 0;
  margin: 0;
  border: none;
}

.loading {
  padding: 1rem;
  text-align: center;
  color: #666;
}

.address-details {
  margin-top: 16px;
  padding: 12px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.input-group {
    display: flex;
    align-items: center;

    span {
      padding: 6px 12px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      color: #555;
      background-color: #eee;
      border: 1px solid #ccc;
      border-radius: 4px 0 0 4px;
      white-space: nowrap;
    }


    input[type="text"],
    input[type="number"],
    select {
      flex-grow: 1;
      padding: 6px 12px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      color: #555;
      border: 1px solid #ccc;
      border-radius: 0 4px 4px 0;
      border-left: none;

      &:focus {
        outline: none;
      }
    }

    input[type="checkbox"] {
      margin-left: 10px;
    }

  }

</style>