<template>
    <div id="sew" class="sewOverlay" :style="styleObject">
        <!-- Login doesn't reference the same shared layout that everything else does,
        so we can put our keep alive prompt here to cover sesstion timeout on all
        restricted access pages that do reference the shared layout-->
        <div class="sewDialog">
            <div class="sewTitle swm-box-header2">Are you still working?</div>
            <div class="sewBody">
                This session is about expire due to inactivity.<br/>
                You will be logged out unless you extend your session.<br />
                <br />
                Redirecting to login page in {{seconds}} {{unit}}<br/>
                <button class='btn' style='display:inline;width:auto;' v-on:click="keepSession">Extend Session</button>
            </div>
        </div>
        <iframe :src="src" style="display:none;"></iframe>
    </div>    
</template>

<script>
export default {
    data: function () {
            return {
                src: "/KeepAlive",
                countingDown:false,
                seconds: 60,
                styleObject: {
                    display: 'none',
                }
            };
        },
        computed: {
            unit: function () {
                if(this.seconds == 1)
                    return "second"
                return "seconds"
            }
        },
        mounted: function () {
            this.startSessionTimeoutWarningTimer(); // start the session expiration warning timeout again
        },
        methods: {
            logout: function () {
                window.location="/login"
            },
            subtract: function () {                
                if (!this.countingDown)
                    return;
                this.seconds -= 1;
                if (this.seconds === 0)
                    this.logout();
                else
                    this.startCountDownTimer(); 
                
            },
            startCountDownTimer: function () {
               
                setTimeout(() => {                    
                    this.subtract();
                },1000)
            },
            startSessionTimeoutWarningTimer: function () {
                setTimeout(() => {                    
                    this.countingDown = true;
                    this.seconds = 60;
                    this.show();
                    this.startCountDownTimer();
                },
                // 19 min * 60 sec/min * 1000 ms/sec
                .1 * 60 *  1000)
            },
            keepSession: function () {
                this.countingDown = false;                
                this.styleObject.display = 'none';
                this.src = "/KeepAlive?v=" + (new Date()).getTime();
                this.startSessionTimeoutWarningTimer(); // start the session expiration warning timeout again
            },
            show: function () {                

                this.styleObject.display = 'inline-block';
            }
        }
}
</script>

<style scoped>
.sewOverlay {
            z-index: 1030;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            background: rgba(64,64,64,0.7);
            text-align: center;
            left: 0;
        }

        .sewDialog {
            background: white;
            width: 30%;
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            box-shadow: 0px 0px 14px black;
            
        }

        .sewTitle {
            border-top: 3px solid #4cc8f1;
            background: #f2f0f057;
            font-weight: bold;
        }

        .sewBody {
            position: relative;
            /*top: 50%;
            left: 50%;
            transform: translate(-50%,-50%)*/
            padding: 10px;
        }
</style>