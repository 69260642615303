

import {CORE} from '../_spec.js'
import * as THREE from 'three';
export default class PickHelper {
    constructor() {
      this.raycaster = new THREE.Raycaster();
      this.pickedObject = null;
    }

    closestIntersectionWithObjectOfIdType(pickingData, id, type){      
      let result = null;
      
      // nearest to furthest
      pickingData.intersectedObjects.forEach((intersection)=>{
        if(result!=null)
          return;
        if(intersection.object.userData.id == id && 
          (typeof type === 'undefined' || intersection.object.userData.type == type)){
          result={
            point: intersection.point,
            objectType: intersection.object.userData.type,
            id: intersection.object.userData.id
        }
      }
      });
      return result;
    }

    //pointOnClosestObjectOfType(pickingData, types){
    closestIntersectionWithObjectOfType(pickingData, types, ignoreIds){      
      let result = null;
      
      // nearest to furthest
      pickingData.intersectedObjects.forEach((intersection)=>{
        if(result!=null)
          return;
        if(types.includes(intersection.object.userData.type) &&         
        !ignoreIds.includes(intersection.object.userData.id)){
          result={
            point: intersection.point,
            objectType: intersection.object.userData.type,
            id: intersection.object.userData.id
          }
        }
      });
      return result;
    }

    static getClosestValueToTarget(values, target){
      if(values.length==0)
        return null;

      let closestDistance;
      let closestValue;
      let closestIndex;

      values.forEach(function(v,i){
        let currDist = Math.abs(v-target);
        if(typeof closestDistance === `undefined` || currDist < closestDistance){
          closestDistance = currDist;
          closestValue=v;
          closestIndex=i;
        }
      });
      
      return {closestValue, closestIndex};
    }

    static getNearestValueUnderTarget(values, target){
      if(values.length==0)
        return null;

      let closestDistance;
      let closestValue;
      let closestIndex;

      values.forEach(function(v,i){
        let currDist = Math.abs(v-target);
        if(typeof closestDistance === `undefined` || currDist < closestDistance && v < target){
          closestDistance = currDist;
          closestValue=v;
          closestIndex=i;
        }
      });
      
      return {closestValue, closestIndex};
    }

    getClosestObjectOfType(pickingData, types){
      let result = null;
      
      // nearest to furthest
      pickingData.intersectedObjects.forEach((intersection)=>{
        if(types.includes(intersection.object.userData.type)){
          result = intersection.object;
          
        }
      });
      return result;
    }

    pick(normalizedPosition, scene, camera, time) {
      
      
      // restore the color if there is a picked object
      if (this.pickedObject) {
        //this.pickedObject.material.color.setHex(this.pickedObjectSavedColor);
        this.pickedObject = undefined;
      }
   
      this.raycaster.layers.enable(CORE.layers.walls);
      this.raycaster.layers.enable(CORE.layers.roof);
      this.raycaster.layers.enable(CORE.layers.windows);
      this.raycaster.layers.enable(CORE.layers.doorsWalkin);
      this.raycaster.layers.enable(CORE.layers.constraint);
      
      // cast a ray through the frustum
      this.raycaster.setFromCamera(normalizedPosition, camera);
      // get the list of objects the ray intersected
      const intersectedObjects = this.raycaster.intersectObjects(scene.children, true);
      if (intersectedObjects.length) {
        // pick the first object. It's the closest one
        //this.pickedObject = intersectedObjects[0].object;
        let types = '';
        intersectedObjects.forEach((f) => {
          
          types+= f.object.userData.type+', ';
      });
      //console.log(types);

        let intersectedObject = intersectedObjects[0];
        //console.log(intersectedObject.point);
        let picked = {
          intersectedObjects: intersectedObjects//intersectedObject.object,
        }
        return picked;
        // save its color
        //this.pickedObjectSavedColor = this.pickedObject.material.color.getHex();
        // set its emissive color to flashing red/yellow
        //this.pickedObject.material.color.setHex((time * 8) % 2 > 1 ? 0xFFFF00 : 0xFF0000);
      }
      return null;
    }

    static getGridSnappedPosition(pos, snapX, snapZ)
    {
        //console.log("snap input:", pos)
        let gridSizeInches = 3;
        let gridSize = gridSizeInches; //inches
        
        let x = pos.x;
        if(snapX) {
            x =(pos.x + (gridSize/2))/gridSize; // chop it down to fractional gridSize increments
            x = Math.floor(x)*gridSize; // truncate decimals and scale up by gridSize
        }
        let y = (pos.y + (gridSize/2))/gridSize; // chop it down to fractional gridSize increments
        y = Math.floor(y)*gridSize; // truncate decimals and scale up by gridSize
        
        let z = pos.z;
        if(snapZ){
            z = (pos.z + (gridSize/2))/gridSize; // chop it down to fractional gridSize increments
            z = Math.floor(z)*gridSize; // truncate decimals and scale up by gridSize
        }

        let sPos = new THREE.Vector3(x, y, z)
        //console.log("snap output:", sPos)
        return sPos;
    }
    
  }