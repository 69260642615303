

import * as THREE from 'three';
import {CORE} from '../_spec.js'
import { OBB } from 'three/examples/jsm/math/OBB.js'; // https://threejs.org/examples/?q=bounding#webgl_math_obb
import CollisionHelper from '../helpers/CollisionHelper';
import ShapeHelper from '../helpers/shapeHelper';
import { Vector2, Vector3, Quaternion, Matrix4, Matrix3, Group, Object3D } from 'three';
import MaterialHelper from '../helpers/materialHelper.js';
import _3dFastenerHex from './FastenerHex.js'
import _3dRafterBase from './RafterBase.js'
import Tri from './Tri.js';
import layerHelper from '../helpers/layerHelper.js';

export default class RafterC extends _3dRafterBase {
    
 /*
        ST = shear triangle allows rafter to mate with vertical seam at bolt plate or weld
        CORE RECTANGLE 

        the straight frame is built in this horizontal orientation (top flange is level)

             ******************************************************
           *ST*              CORE RECTANGLE                  *ST*   
        ******************************************************          
        
        */

    constructor(lod, parentGroup, pos, rot, length, pitch, depthSideFlag, addCollisionZone, bpFrameLine, rafterColor){
        super(parentGroup, pos, rot, lod);
        this.length = length;
        this.pitchAngle = pitch;
        this.absPitchAngle = Math.abs(this.pitchAngle);
        this.addCollisionZone = addCollisionZone;
        this.dimensions = bpFrameLine.materialDim;
        this.bpFrameLine = bpFrameLine;
        this.group.name='3d Rafter Cee';
        // depthFlag just determines which side the depth of the rafter is pushed to
        // (given default rafter is back left corner of frame, opening to the left)
        // 1 means push to make the rafter open to the left
        // -1 means pull to make the rafter open to the right
        this.depthSideFlag = depthSideFlag
        
        this.extrusionSettings = {
            depth: .75, //used to be amount
            bevelEnabled: false,
            bevelThickness: 0.1,
            bevelSize: 1,
            bevelSegments: 1,
            material: 0,
            extrudeMaterial: 1
        };

        this.shearLength = this.dimensions.depth * Math.tan(this.pitchAngle);
        
        this.rafterColor = rafterColor ? rafterColor : CORE.frame.redOxideColor;

        this.build();
    }
    
	build(){
        // these are first built on end, then sheared, then rotated into place
        // the shearing affects appearance of the corner, so some tweaks in the y dimension fix z dimension discrepancies
        
        this.buildRafter();
        let czPositionMarker = new Object3D(); // add an object purely for marking the position of the collisionZone center
        czPositionMarker.position.z = this.length/2; // position the marker locally (it's global position is calculated automatically)
        //czPositionMarker.position.x = -this.dimensions.depth/2;
        this.group.add(czPositionMarker) // add it to the tree 
        
        this.group.updateMatrix();
        this.group.updateMatrixWorld();
        
        if(this.addCollisionZone){
            //collision bounding box
            let size = new Vector3(this.dimensions.flange+10,  this.dimensions.depth, this.length) // x,z,y
            let position = new Vector3(0,0,this.length/2);
            let margin = CollisionHelper.getMargin(4,4,0,0,0,0);
            let zone = CollisionHelper.getZone2(
                this.group,
                position,
                false,
                [
                    CORE.collisions.classes.window,
                    CORE.collisions.classes.emptyFramedOpening,
                    CORE.collisions.classes.louverVent,	
                    CORE.collisions.classes.doorWalkin3o7o,
                    CORE.collisions.classes.doorWalkin4o7o,
                    CORE.collisions.classes.doorWalkin6o7o,
                    CORE.collisions.classes.doorRollup,
                    CORE.collisions.classes.doorSliding,
                    CORE.collisions.classes.doorCommGlass,
                    CORE.collisions.classes.doorSectional,
                    CORE.collisions.classes.doorHangar,
                    CORE.collisions.classes.wideOpening,
                ],
                size,
                //this.group.matrixWorld, 
                margin,
                'C Rafter');
            
            
            //zone.position.z = this.length/2;// czPositionMarker.getWorldPosition(new THREE.Vector3());

            this.meshDepth.userData = {
                collisionZones: [zone]
            };
        }        
    }



        buildRafter_HD(){
            let halfFlangeWidth = this.dimensions.flange/2;
            let repeatX=1,repeatY=1;
    
            // top flange shape
            let meshTopFlange = this.getExtrudedMesh([
                new Vector2(-halfFlangeWidth,0),
                new Vector2(-halfFlangeWidth,this.length),
                new Vector2(halfFlangeWidth,this.length),
                new Vector2(halfFlangeWidth,0)        
            ],MaterialHelper.getFrameMaterialWithTexture(this.texFrame, this.rafterColor, repeatX, repeatY)
            );
            meshTopFlange.rotation.x = Math.PI/2;
            meshTopFlange.position.z = this.shearLength;
            meshTopFlange.position.y = this.dimensions.depth;
            this.group.add(meshTopFlange);
    
            // depth shape				
            this.meshDepth = this.getExtrudedMesh([
                new Vector2(this.dimensions.depth,0),
                new Vector2(0,this.shearLength),
                new Vector2(0,this.shearLength+this.length),
                new Vector2(this.dimensions.depth,this.length)
            ],
            MaterialHelper.getFrameMaterialWithTexture(this.texFrame, this.rafterColor, repeatX, repeatY)
            )
            this.meshDepth.position.y = this.dimensions.depth;
    
            // default rafter is built for back left corner, so open to the left by pushing the depth positive (relative to the frameline which is built by default from x=0, to x=+n)
            this.meshDepth.position.x = this.depthSideFlag * halfFlangeWidth; // 
            
                
            this.meshDepth.rotation.y = -Math.PI/2;
            this.meshDepth.rotation.z = -Math.PI/2;
            this.group.add(this.meshDepth);
            
            // bottom flange shape		
            var meshBottomFlange = this.getExtrudedMesh([
                new Vector2(-halfFlangeWidth,0),
                new Vector2(-halfFlangeWidth,this.length),
                new Vector2(halfFlangeWidth,this.length),
                new Vector2(halfFlangeWidth,0)
            ],
            MaterialHelper.getFrameMaterialWithTexture(this.texFrame, this.rafterColor, repeatX, repeatY)
            );
            meshBottomFlange.rotation.x = Math.PI/2;        
            this.group.add(meshBottomFlange);
        }

        buildRafter_LD(){
            let flangeWidth =this.dimensions.flange;
            let halfFlangeWidth = flangeWidth/2;
            let repeatX=1,repeatY=1;
            let depth = this.dimensions.depth + 0* CORE.steel.thickness_in;
            let mat = MaterialHelper.getFrameMaterial_LD(this.rafterColor)
            // top flange shape
            let geoTopFlange = new THREE.PlaneBufferGeometry(flangeWidth, this.length, 1,1)
            let meshTopFlange = new THREE.Mesh(geoTopFlange, mat)
            meshTopFlange.rotation.x = Math.PI/2;
            meshTopFlange.position.z = this.shearLength + this.length/2;
            meshTopFlange.position.y = depth;
            //this.length, flangeWidth
            //meshTopFlange.rotation.x = Math.PI/2;
            //meshTopFlange.position.z = this.shearLength;
            //meshTopFlange.position.x = this.length/2;
            //meshTopFlange.position.y = depth;
            
            this.group.add(meshTopFlange);
    
            // depth shape


            this.meshDepth = this.getExtrudedMesh([
                new Vector2(depth,0), // bottom right
                new Vector2(0,this.shearLength), // bottom left
                new Vector2(0,this.shearLength+this.length), // top left
                new Vector2(depth,this.length) // top right
            ],
            MaterialHelper.getFrameMaterial_LD(this.rafterColor)
            )

            
		// depth shape		
		let bl = new Vector3(0,this.shearLength, 0)
		let tl = new Vector3(0,this.shearLength+this.length, 0)
        let tr = new Vector3(depth,this.length, 0)
        let br = new Vector3(depth,0,0)
        
		let triTopRight = new Tri(
            'bottom right triangle',
            [
                // counter clock-wise
                br, //bottom right 
                tr, // top right 
                tl, // top left 
            ],
            [
                              
                1.0, 0, // bottom right
                1.0, 1, // top right
                0.0, 1.0 // top left
            ]
        );

        let triBottomLeft = new Tri(
            'top left triangle',
            [
                br, // bottom right
                tl, // top left 
                bl, // bottom left 
            ],
            [
                1.0, 0, // bottom right
                0.0, 1, // top left
                0.0, 0, // bottom left
            ]
        );
            
        let meshDepthBottomLeft = new THREE.Mesh(triBottomLeft.geometry, mat);
        let meshDepthTopRight = new THREE.Mesh(triTopRight.geometry, mat);

            meshDepthTopRight.position.y = depth;
            // default rafter is built for back left corner, so open to the left by pushing the depth positive (relative to the frameline which is built by default from x=0, to x=+n)
            meshDepthTopRight.position.x = this.depthSideFlag * halfFlangeWidth; // 
            meshDepthTopRight.rotation.y = -Math.PI/2;
            meshDepthTopRight.rotation.z = -Math.PI/2;

            meshDepthBottomLeft.position.y = depth;
            // default rafter is built for back left corner, so open to the left by pushing the depth positive (relative to the frameline which is built by default from x=0, to x=+n)
            meshDepthBottomLeft.position.x = this.depthSideFlag * halfFlangeWidth; // 
            meshDepthBottomLeft.rotation.y = -Math.PI/2;
            meshDepthBottomLeft.rotation.z = -Math.PI/2;


            this.group.add(meshDepthTopRight);
            this.group.add(meshDepthBottomLeft);
            
            // bottom flange shape		
            let meshBottomFlange = new THREE.Mesh(geoTopFlange, mat);
            //meshBottomFlange.rotation.x = Math.PI/2;        
            meshBottomFlange.rotation.x = Math.PI/2;
            meshBottomFlange.position.z = this.length/2;
            this.group.add(meshBottomFlange);
        }


    buildRafter(){
        
        if(this.isLowDetail())
            this.buildRafter_LD()
        else 
            this.buildRafter_HD()
        layerHelper.setGroup(this.group, CORE.layers.frame, true);
    }

    getExtrudedMesh(points, material){
        let mesh = ShapeHelper.getMeshFromPoints( 
            points,        
        this.extrusionSettings,
        material );        
        mesh.layers.set(CORE.layers.frame);
        mesh.castShadow=true;
        mesh.receiveShadow=true;
        return mesh;
    }
}
