import * as THREE from 'three';
import _3dBase from './Base.js'
import {CORE} from '../_spec.js'
import { Vector3 } from 'three';
import CollisionHelper from '../helpers/CollisionHelper.js';
import materialHelper from '../helpers/materialHelper.js';

export default class Insulation extends _3dBase{
	constructor( length, width, height){
		super();
		this.length = length;
		this.width = width;
		this.height = height;
        this.build();
	}


    build(){		
		// Create a custom texture for this object
		let material = materialHelper.getInsulationMaterial(0xEEEEEE, this.width, CORE.textures.insulation);
		material.emissiveIntensity=.5;
		
		var geoSurface = new THREE.BoxGeometry(this.length, this.width, this.height, 2,2, 2);
		geoSurface.name='insulation geoSurface'
		var meshSurface = new THREE.Mesh( geoSurface, material );
		meshSurface.rotateX(Math.PI/2)
		meshSurface.castShadow=true;		
		this.group.add(meshSurface);
		
		
		this.group.castShadow=true;        
		meshSurface.layers.set(CORE.layers.frame)
	}	
}