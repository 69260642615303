import * as THREE from 'three'
import _3dWindowBase from './WindowBase.js'
import layerHelper from '../helpers/layerHelper.js'
import _3dTrimOpeningFace from './TrimOpeningFace.js'
import materialHelper from '../helpers/materialHelper.js'
import { CORE } from '../_spec.js'
// REFERENCE IMAGE: https://nebula.wsimg.com/afa587762ee092eecdb6212d340a942c?AccessKeyId=540DC0AD243BC3839B8C&disposition=0&alloworigin=1

export default class WindowDoubleHung extends _3dWindowBase{
    constructor(lod, parentGroup, dim, trimMaterial){
        super(lod, parentGroup, dim, trimMaterial)
        this.group.name = "DoubleHung Window"
        
        this.build(dim);
    }

    build(dim){
         // REFERENCE IMAGE: https://nebula.wsimg.com/afa587762ee092eecdb6212d340a942c?AccessKeyId=540DC0AD243BC3839B8C&disposition=0&alloworigin=1
         let gWindow = new THREE.Group();                
         let sashDepth=1; //
         let dimJamMaterial = new THREE.Vector3(.5,.5,(sashDepth*2)+1); // add an inch to split front and back
         let matJam = materialHelper.getPlasticMaterial_HD(0xffffff)
         let jam = new _3dTrimOpeningFace(this.lod, dim, dimJamMaterial, matJam);
         layerHelper.enableLayer(jam.group, CORE.layers.quote);
         //let jam = _3dWindowBase.buildPerimeter(dim,dimJamMaterial,matJam)
         gWindow.add(jam.group)
         
         let dimSashMaterial = new THREE.Vector3(2, 2, sashDepth)
         let dimSash = dim.clone().sub(dimJamMaterial); // subtract the space taken up by the jam
         dimSash.y = dimSash.y/2 + dimSashMaterial.y/2; // calculate the full height of a single sash
         dimSash.z = sashDepth; // subtracting the jam should not affect the sashDepth
 
         let sashOffsetY = (dimSash.y - dimSashMaterial.y)/2; // sashes overlap, so calculate their vertical offset from the vertical center of the window opening
         // top sash
         let sashTop = this.buildSash(dimSash,dimSashMaterial);
         sashTop.position.y = sashOffsetY; // offset upward
         sashTop.position.z = dimSash.z/2; // set the top sash out by half the depth of a sash
         gWindow.add(sashTop);
 
         // bottom sash
         let sashBottom = this.buildSash(dimSash,dimSashMaterial);
         sashBottom.position.y = -sashOffsetY;  // offset downward
         sashBottom.position.z = -dimSash.z/2; // set the bottom sash in by half the depth of a sash
         gWindow.add(sashBottom);
 
         gWindow.position.z-=.5;// set the whole window in 
         this.group.add(gWindow)
         this.setWindowGroup(gWindow);
    }
    
}