import { CORE, impactTypes } from '../_spec.js';
import {Vector3 } from 'three';
import * as THREE from 'three';
import BaySide from './BaySide.js';
import BlueprintHelper from '../helpers/blueprintHelper.js';
import _3dDistHori from '../3d/DistHori.js'
import materialHelper from '../helpers/materialHelper.js';
import SheetingHelper from '../helpers/SheetingHelper.js';
export default class BaySideBack extends BaySide {
    constructor(des, 
        structureConfig,
        bayStart,
        bayEnd,
        bayLength,
        wallLength, 
        maxHeight, 
        trimMaterials, 
        girtHeights, 
        frameLines,  
        supportsWainscot, 
        footerCollisions, 
        addToQuoteLayer,
        allowDripTrim,
        insulation,
        pitchRatio,
        structureLength,
        beamColor, 
        girtColor,
        options,
        shedHoles) {

        des.type = CORE.components.baySideBack;
        // generate points in the xy plane, z = 0;
        // let shapePoints = BlueprintHelper.generatePointsWithOffset(start, end,length, maxHeight, undefined, 0, 0, length / 2);
        let shapePoints = BlueprintHelper.generateBayPoints(bayStart, bayEnd, wallLength, maxHeight, des.height, undefined, undefined, CORE.sides.frontSide);
        
        shapePoints.forEach((point) => {
            point.x -= bayStart;
        });
        super(des, structureConfig, bayLength, maxHeight, false, trimMaterials, shapePoints, girtHeights, CORE.sides.backSide,frameLines, supportsWainscot, footerCollisions, addToQuoteLayer, allowDripTrim, insulation, pitchRatio,
        beamColor, girtColor, options);
        this.shedHoles = shedHoles;
        this.frames = [];
        this.frameLines.forEach((fl) => {
            // TODO: remove posX defect
            this.frames.push({ posX: fl.posX + structureLength / 2, index: fl.index, width: fl.width });
        });

        this.build();
    }

    getWallBackPlane(){
        return SheetingHelper.defineBackPlane(materialHelper.getExteriorPanelPbrMaterial(this.structureConfig.wallColor,this.length, THREE.BackSide));
    }

    getWallFrontPlane(){
        return SheetingHelper.defineFrontPlane(materialHelper.getInsulationMaterial(0xEEEEEE, this.length, CORE.textures.insulation));
    }

    getWainscotBackPlane(){
        return SheetingHelper.defineBackPlane(materialHelper.getExteriorPanelPbrMaterial(this.structureConfig.wainscotColor, this.length));
    }

    getWainscotFrontPlane(){
        return SheetingHelper.defineFrontPlane(materialHelper.getInteriorPanelPbrMaterial(0xEEEEEE,this.length, THREE.BackSide));
    }
    
    

    getInteriorSide(){
        return THREE.FrontSide
    }

    getExteriorSide(){
        return THREE.BackSide;
    }

    getDescription(){
        return `Bay ${this.design.index+1}`
    }    

    // because the collision zones are OOBs, they don't spin around 
    getFooterCollisionZoneCenter(){        
        let world = this.getWorldPositionFromRelative(new Vector3(this.wallMid, 0,0))
        return world;
    }
    
    getComponentDistanceMarkers(comp){

        let des = comp.design;
        //goal 1: build is list of columns with size and position data.
        // sideWalls have column positions only for OH doors

        let low, high, lowColPos,highColPos, offset,lowWinPos,highWinPos;
        let markerMarginZ = -24;
        // use framebase or framebase.frameLines to discover the true size and location of frameLine columns on the sideWall.
        let lowWinX = des.pos.x - des.dim.width/2;
        let highWinX = des.pos.x + des.dim.width/2;
        this.frames.forEach((fl)=>{
            if(fl.posX < lowWinX){
                low = fl;                        
            } else if(fl.posX > highWinX && !high){
                high = fl;
            }
        })

        //console.log(low, high)

        if(!low || !high)
            return;
        let lowColPosX = low.posX // center of the column width              

        // if a corner column, measure from the outside
        if(low.index===0)
            lowColPosX-=low.width/2;// outside edge for first frameline
        if(low.index===this.frameLines.length-1)
            lowColPosX+=low.width/2; // outside edge for last frameline
        
        let highColPosX = high.posX // center of the column width
        if(high.index===0)
            highColPosX-=high.width/2; // outside edge for first frameline
        if(high.index===this.frameLines.length-1)
            highColPosX+=high.width/2; // outside edge for last frameline

        lowColPos = new THREE.Vector3(lowColPosX, des.pos.y, des.pos.z);
        highColPos = new THREE.Vector3(highColPosX, des.pos.y, des.pos.z);
        offset = new THREE.Vector3(0,0,markerMarginZ);

        lowWinPos = new THREE.Vector3(lowWinX, des.pos.y, des.pos.z);
        highWinPos = new THREE.Vector3(highWinX, des.pos.y, des.pos.z);

        
        let newLowDh = new _3dDistHori(lowColPos, lowWinPos, new THREE.Vector3(),  offset, 1, CORE.layers.openingDimensions, new Vector3(20,10,1));
        this.group.add(newLowDh.group);
        let newHighDh = new _3dDistHori(highColPos, highWinPos, new THREE.Vector3(), offset, 1,  CORE.layers.openingDimensions,new Vector3(20,10,1));
        this.group.add(newHighDh.group);

        return  { 
            newLowDh,
            newHighDh
        }
    }
}
