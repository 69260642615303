import * as THREE from 'three';
import { Quaternion, Vector3, Vector2 } from 'three';
import ShapeHelper from '../helpers/shapeHelper.js';
import BayBase from './BayBase.js';
import OptionHelper from '../helpers/optionHelper.js'
import BlueprintHelper from '../helpers/blueprintHelper.js'
import vHelper from '../helpers/vHelper.js'
import ComponentHelper from '../helpers/featureHelper.js'
import layerHelper from '../helpers/layerHelper.js'
import {CORE, impactTypes } from '../_spec.js'
import CollisionHelper from '../helpers/CollisionHelper.js'


import _3dColumnC from '../3d/ColumnC.js';
import _3dColumnStraight from '../3d/ColumnStraight.js';
import _3dColumnSquare from '../3d/ColumnSquare.js'
import { coolGray } from 'tailwindcss/colors';
import Tri from '../3d/Tri.js';
import materialHelper from '../helpers/materialHelper.js';
import EarcutDataManager from '../helpers/EarcutDataManager.js';
import SheetingHelper from '../helpers/SheetingHelper.js';
import Sheeting from '../3d/Sheeting.js';
import MemHelper from '../helpers/memHelper.js';

export default class BayEnd extends BayBase {
    constructor(
        des, 
        structureConfig,
        length,
         maxHeight,
          facePosZ,
           trimMaterials,
            shapePoints,
             cbDesignChange,
              girtHeights,
              pos,
              rot,
               typeDetail,
                supportsWainscot,
                 footerCollisions,
                  addToQuoteLayer,
                  allowDripTrim,
                  insulation,
                  pitchRatio,
        roofType, frameType, distToNextFrameline, stopFrameLine, beamColor, girtColor, options,
        shedHoles) {
        super(des, structureConfig, length, maxHeight, facePosZ, trimMaterials, cbDesignChange, supportsWainscot, footerCollisions, addToQuoteLayer,allowDripTrim, insulation, pitchRatio,
            roofType, beamColor, girtColor, options);
        
        this.shedHoles = shedHoles;
        this.position=pos;
        this.rotationY=rot;
        this.distToNextFrameline = distToNextFrameline;
        this.frameType = frameType;
        //shapePoints=this.getShapePoints(); // LEW or REW specific points
        // LEW or REW specific geometry for the holey wall
        this.stopFrameLine = stopFrameLine;
        this.design.typeDetail = typeDetail; //
        // our encapsulated scene

        //if(!this.buildFacingPositiveZ)
           // pos.z-=this.wallThickness; // wall thickness (extrusion depth/amount)
            
        this.group.position.copy(this.position);
        this.group.rotateY(this.rotationY);
        this.girtHeights=girtHeights;
        this.backBottom = new Vector3().copy(shapePoints[0]);
        //this.backTop = new Vector3().copy(shapePoints[1]);
        this.frontTop = vHelper.addToCopy(shapePoints[shapePoints.length-2], 0, 0, 0);

        this.length = length;
        this.wallMid = this.length/2;
        
        // push the wall to the appropriate edge of the column


        if(des.frameLine === undefined)
            des.frameLine = 0;

        // this is an endWall which can be inset from the end of the frame, so the position
        // of the wall is based on the location of the targetted frameline (0 to n-1)
        this.columnPoints=[];

        this.shapePoints = Array.from(shapePoints);
        //this.shapePoints[0].y-=3;         // 1 of 2 bottom points extended down below concrete because shapes are a pain
        //this.shapePoints[this.shapePoints.length-1].y-=3; // 1 of 2 bottom points extended down into concrete because shapes are a pain
        
        this.beamColor = beamColor;
        this.girtColor = girtColor;
        this.build();
    }


        
    addWallExteriorPanelOld(colorMap, alphaMap, bumpMap){

        // make three triangles            
        let  tris = this.getWallTris();

        let material = this.getExteriorPanelMaterial(colorMap, alphaMap, bumpMap);
        material.side = this.getExteriorSide()
        tris.forEach((tri)=>{
            let triMesh = new THREE.Mesh(tri.geometry, material);        
            triMesh.position.x += this.length/2;
            triMesh.position.z = .1;
            triMesh.layers.set(CORE.layers.walls);
            triMesh.name='wall '+ tri.name;
            triMesh.renderOrder = 1;
            this.gWall.add(triMesh);
        })
    }

    addWallExteriorPanel(){
        let  wall = new EarcutDataManager();
        let frontEaveHeight = this.maxHeight;        
        let backEaveHeight = this.maxHeight; // gabled
        let bottom = frontEaveHeight - this.design.height;   

        
        
        
        let bayPoints = [];
        bayPoints.push(-this.length / 2, this.shapePoints[0].y); // bottom left
        bayPoints.push(this.length / 2, this.shapePoints[this.shapePoints.length - 1].y); // bottom right
        bayPoints.push(this.length / 2, this.shapePoints[this.shapePoints.length - 2].y); // top right
        if(this.shapePoints.length > 4)
            bayPoints.push(this.shapePoints[2].x - this.length/2, this.shapePoints[2].y); // middle 
        bayPoints.push( -this.length / 2, this.shapePoints[1].y);// top left
        
            
        wall.setOutline(bayPoints);


        this.holes.forEach((h)=>{
            wall.addSquareHole(h.pos.x-this.length/2, h.pos.y, h.dim.width, h.dim.height);
        })
        let wallData = wall.generate();
            
       
        let sheeting = new Sheeting(
            CORE.preferences.des_levelOfDetail.value,
            wallData,
            this.getWallFrontPlane(),
            this.getWallBackPlane(),
            CORE.layers.walls);

        sheeting.group.position.x =this.length/2;
        sheeting.group.name = 'sheeting';
        if(this.buildFacingPositiveZ)
            sheeting.group.position.z = .1;
            else
            sheeting.group.position.z = -.1;
        this.gWall.add(sheeting.group);
    }

    addWallInterior(colorMap, alphaMap, bumpMap){
        let tris  = this.getWallTris();

        let material = this.getInteriorMaterial(colorMap, alphaMap, bumpMap);
        material.side = this.getInteriorSide()
        tris.forEach((tri)=>{
            let triMesh = new THREE.Mesh(tri.geometry, material);        
            triMesh.position.x += this.length/2;
            triMesh.position.z = -.1;
            triMesh.layers.set(CORE.layers.walls);
            triMesh.name='wall '+ tri.name;
            triMesh.renderOrder = .99;
            this.gWall.add(triMesh);
        })
    }

    
    getWallTris() {
        if(this.roofType === CORE.roof.types.gable)
            return this.getGableWallTris()
        else 
            return this.getSingleSlopeWallTris()
    }

    getSingleSlopeWallTris() {
        let frontEaveHeight = this.maxHeight;
        let backEaveHeight = this.ridgeHeight
        
        let frontHeightRatio = frontEaveHeight / this.ridgeHeight;
        let backHeightRatio = backEaveHeight / this.ridgeHeight;
        let bottom = frontEaveHeight - this.design.height;
        let bottomHeightRatio = bottom / this.ridgeHeight;
        let tris = [];
        let r = new Tri(
            'bottom right triangle',
            [
                new Vector3(-this.length / 2, bottom, 0),
                new Vector3(this.length / 2, bottom, 0),
                new Vector3(this.length / 2, frontEaveHeight, 0),
            ],
            [
                0.0, bottomHeightRatio,
                1.0, bottomHeightRatio,
                1.0, frontHeightRatio,
            ]
        );

        let l = new Tri(
            'middle left triangle',
            [
                new Vector3(-this.length / 2, bottom, 0),
                new Vector3(this.length / 2, frontEaveHeight, 0),
                new Vector3(-this.length / 2, frontEaveHeight, 0),
            ],
            [
                0.0, bottomHeightRatio,
                1.0, frontHeightRatio,
                0.0, frontHeightRatio
            ]
        );

        let t = new Tri(
            'gable triangle',
            [
                new Vector3(-this.length / 2, frontEaveHeight, 0),
                new Vector3(this.length / 2, frontEaveHeight, 0),
                new Vector3(-this.length / 2, this.ridgeHeight, 0), // gabled only
            ],
            [
                0.0, frontHeightRatio,
                1.0, frontHeightRatio,
                0.0, backHeightRatio,
            ]
        );
        tris.push(r);
        tris.push(l);
        tris.push(t);
        return tris;
    }

    getGableWallTris() {
        let frontEaveHeight = this.maxHeight;
        let backEaveHeight = this.maxHeight;
        
        let frontHeightRatio = frontEaveHeight / this.ridgeHeight;
        let backHeightRatio = backEaveHeight / this.ridgeHeight;
        let bottom = frontEaveHeight - this.design.height;
        let bottomHeightRatio = bottom / this.ridgeHeight;
        let tris = [];
        let r = new Tri(
            'bottom right triangle',
            [
                new Vector3(-this.length / 2, bottom, 0),
                new Vector3(this.length / 2, bottom, 0),
                new Vector3(this.length / 2, frontEaveHeight, 0),
            ],
            [
                0.0, bottomHeightRatio,
                1.0, bottomHeightRatio,
                1.0, frontHeightRatio,
            ]
        );

        let l = new Tri(
            'middle left triangle',
            [
                new Vector3(-this.length / 2, bottom, 0),
                new Vector3(this.length / 2, frontEaveHeight, 0),
                new Vector3(-this.length / 2, frontEaveHeight, 0),
            ],
            [
                0.0, bottomHeightRatio,
                1.0, frontHeightRatio,
                0.0, backHeightRatio
            ]
        );

        let t = new Tri(
            'gable triangle',
            [
                new Vector3(-this.length / 2, frontEaveHeight, 0),
                new Vector3(this.length / 2, frontEaveHeight, 0),
                new Vector3(0, this.ridgeHeight, 0), // gabled only
            ],
            [
                0.0, backHeightRatio,
                1.0, frontHeightRatio,
                0.5, 1.0,
            ]
        );
        tris.push(r);
        tris.push(l);
        tris.push(t);
        return tris;
    }

    buildDynamicOnly(subComponentsToBuild, buildOpenings){
        super.buildDynamicOnly(subComponentsToBuild, buildOpenings);

        if(this.design.openWall===true)
            return;
            
        this.buildColumns();
    }

    removeDynamicOnly(){
        super.removeDynamicOnly();
        this.removeColumns()
    } 
    
    buildColumns(){
        this.design.columnPositions = [];
        let subcomponents = this.design.getWallFramedOpenings();

        // if(this.design.baySpacing.mode === CORE.modes.baySpacing.auto)
        //     this.buildAutomaticSupportColumns(subcomponents); // CompGroup checks out ok sometimes and not others
        // else
            this.buildManualSupportColumns();

        // make sure column positions are always in ascending order.
        this.design.columnPositions.sort((a,b)=>{
            if(a.pos < b.pos)
                return -1;
            else 
                return 1;
        });
        this.design.columnPositions.forEach((cp,i)=>{
            cp.index = i;
        });
    }

    removeColumns(){
        
        if(this.columns)
        this.columns.forEach((c)=>{
            c.remove();
        });

        if(this.privateColumns)
            this.privateColumns.forEach((c)=>{
                c.remove();
            })

        let columnsGroup = this.getColumnsGroup();
        if (columnsGroup)
            MemHelper.removeAllChildren(columnsGroup);
    }


    migrate(design){
        super.migrate(design);

        switch(design.v)
        {
            case 1:
                //this.migrateTo2(design);
                break;
        }
    }

    migrateTo2(design){
        //super.migrateTo2(design);
        if(typeof design.openSupport === 'undefined')
            design.openSupport = CORE.endWallColumns.columnsToGround.value;

        
        design.components.forEach((c)=>{
                c.pos.x = c.pos.z;
                c.pos.z = 0.0;
        });
        design.v=2;
    }


    getRelativePosition(worldPosition){
        let wld = new Vector3().copy(worldPosition);
        let rel = this.group.worldToLocal(wld);
        return rel;
    }

    positionChildAt(design, worldPosition){
        // get this worldPosition in terms of this parent component
        let rel = this.getRelativePosition(worldPosition)
        // then use a static method that accepts the design to constrain the position to exactly fixed header height
        let compType = ComponentHelper.getClassOfComponentType(design.type);
        return compType.updatePosition(rel, design);
    }
    
    
    // getOptionsSpec(){
    //     let spec = [];

    //     let openSupport =  OptionHelper.selection("openSupport","Open Area Column", impactTypes.wallColumnOption, undefined, [
    //         {
    //             value: CORE.endWallColumns.columnsToGround.value,
    //             text:  CORE.endWallColumns.columnsToGround.name
    //         },
    //         {
    //             value: CORE.endWallColumns.columnsToHeader.value,
    //             text:  CORE.endWallColumns.columnsToHeader.name
    //         },
    //         {
    //             value: CORE.endWallColumns.backBrace.value,
    //             text:  CORE.endWallColumns.backBrace.name
    //         },
    //     ],
    //     this.design.openHeight > 0,
    //     ()=>{//fnIn
    //         return this.design.openSupport;
    //     },
    //     undefined,
    //     (v)=>{//fnChanged
    //         this.design.openSupport = v;
    //     }
    //     );


    //     //spec.push(frameLine);
    //     spec.push(...super.getOptionsSpec());
    //     spec.splice(3,0, openSupport)

    //     return spec;
    // }

    defaultDesign(){
        let bayComponent = (this.design.type === CORE.components.bayEndLeft) ? CORE.components.bayEndLeft : CORE.components.bayEndRight;
        return {
            type: bayComponent,
            wainscot:{
                enabled: true,
                height: 36,
                color: '0x8f8f8f'
            },
            lineType: CORE.frame.lineTypes.standard,
            openHeight:0,
            
        }
    }
    
    static canEdit(){return true;}

    
    getCollisionClass(){
        return [CORE.collisions.classes.none];
    }

    
    
    isOnWall(cDesign){
        
        let val = (
            (cDesign.pos.x + cDesign.dim.width / 2) <= this.length &&
            (cDesign.pos.x - cDesign.dim.width / 2) >= 0
            );
            return val;
    }

    
    getExtrudedMesh(points, material){
        let mesh = ShapeHelper.getMeshFromPoints( 
            points,        
        this.extrusionSettings,
        material );        
        mesh.layers.set(CORE.layers.frame);
        mesh.castShadow=true;
        mesh.receiveShadow=true;
        return mesh;
    }




    buildAutomaticSupportColumns(subcomponents){
        this.columns = [];
        this.privateColumns = [];
        let columnPoints = [];

        // put columns on both sides of each overhead, open sides facing out.

        let componentColumnPoints = super.getIntrabaySubcomponentColumns(); // mainly for overhead doors
        columnPoints.push(...componentColumnPoints);
        // TODO: centralize and/or correct this static 4 from standard flange
        // TODO: why is this full flange, but called halfColumnWidth?
        let halfColumnWidth = BlueprintHelper.getStandardFrameMaterialSize().flange;
        let defaultColumnPoints = BlueprintHelper.generateColumnPositions2(0, this.length, this.design.baySpacing.center, this.design.baySpacing.nonCenter, halfColumnWidth); //TODO: the halfColumnWidth parameter needs to be context sensitive
        defaultColumnPoints.forEach((dp)=>{
            columnPoints.push({
                required: false,
                source: 'default',
                pos: dp
            });
        })

        columnPoints.sort((a,b)=>{
            if(a.pos < b.pos)
                return -1;
            else 
                return 1;
        });

        columnPoints[0].required=true;
        columnPoints[columnPoints.length-1].required=true;

        let corrections = this.getCorrections(subcomponents, columnPoints)
        if(corrections.length> 0){
            this.applyCorrections(columnPoints, corrections);
        }

        // remove any columns immediately next to one-another (< 3').
        //this.removeRedundantColumns(columnPoints);        

        try{
            this.addColumnsAtPoints(columnPoints);
        }catch(error){
            console.error("error adding column points: ",error);
        }

    }


    removeRedundantColumns(columnPoints){
        let redundantThreshold = 3*12; // consider columns less than this far apart from one-another, redundant.
        let redundantColumns = [];        
        let lastPoint;
        for(var iCol = 0;iCol<columnPoints.length;iCol++){
            let cp = columnPoints[iCol];
            if(iCol === 0)
            {
                lastPoint = cp;
                continue;
            }
            
            let delta = cp.pos - lastPoint.pos ;
            // if they're too close
            if(delta <= redundantThreshold ){
                /* scenarios
                    required against default => remove default
                    default against default => merge defaults into 1
                */

                if(cp.required === false &&  lastPoint.required === false){                    
                    redundantColumns.push(iCol-1); // remove the older one so lastPoint isn't weird to understand
                    cp.pos = (cp.pos + lastPoint.pos) / 2; // merge
                    lastPoint = cp;
                    continue;
                }

                if(cp.required === false && lastPoint.required === true){
                    redundantColumns.push(iCol); // remove unrequired
                    // leave last point alone
                    continue;
                }

                if(cp.required === true && lastPoint.required === false){
                    redundantColumns.push(iCol-1); // remove unrequired
                    lastPoint = cp;
                    continue;
                }
            }
            else{
                lastPoint = cp;
            }
        }

        redundantColumns.sort((a,b) => {return b-a});
        redundantColumns.forEach((r)=>{
            columnPoints.splice(r,1);
        })
    }

    applyCorrections(columnPoints, corrections){
        corrections.forEach((corr)=>{
            if(corr.delete===true)
                // we'll come back for these mutations
                return;

            if(corr.correction){
                columnPoints[corr.iCol].pos += corr.correction;
            }
        });

        corrections.sort((a,b)=>{return b.iCol-a.iCol}); // sort descending so that indices are preserved during deletion
        // delete after other mutations in order to preserve indices of other mutations
        corrections.forEach((corr)=>{
            if(corr.delete===true)
                columnPoints.splice(corr.iCol, 1);
        });
    }

    getCorrections(subcomponents, columnPoints){
        let conflicts = [];
        let halfColumnWidth = BlueprintHelper.getStandardFrameMaterialSize().flange;
        // identify conflicts and attempt to resolve them by moving a column in/out first (then out/in), and re-check
        subcomponents.forEach((desC, iComp)=>{

            let compMin,compMid, compMax;
            compMin = desC.pos.x-desC.dim.width/2;
            compMid = desC.pos.x;
            compMax = desC.pos.x+desC.dim.width/2;

            columnPoints.forEach((col, iCol)=>{
                if(col.required === true)
                    return;
                let colMax = col.pos+halfColumnWidth;
                let colMin = col.pos-halfColumnWidth;

                if (col.source === 'default' &&
                    colMax > compMin && 
                    colMin < compMax)
                    conflicts.push({
                        iComp: iComp,
                        iCol: iCol,
                        delete: true
                    });
                else
                // detect collisions
                if(colMax > compMin && 
                    colMin <= compMid){                    
                    conflicts.push({
                        iComp: iComp,
                        iCol: iCol,
                        correction: compMin - colMax
                    });
                }
                else
                if(colMin < compMax &&
                    colMax >= compMid){
                    conflicts.push({
                        iComp: iComp,
                        iCol: iCol,
                        correction: compMax - colMin
                    });
                }            
            })
        });
        return conflicts;
    }

    buildManualSupportColumns(){
        this.columns = [];
        this.privateColumns = [];
        let columnPoints = [];
        
        let componentColumnPoints = super.getIntrabaySubcomponentColumns(); // mainly for overhead doors
        columnPoints.push(...componentColumnPoints);
        // build wall columns here

        let halfColumnWidth = BlueprintHelper.getStandardFrameMaterialSize().flange;
        // let points = BlueprintHelper.generateColumnPositions2(0, this.length, this.design.baySpacing.center, this.design.baySpacing.nonCenter, halfColumnWidth); //TODO: the halfColumnWidth parameter needs to be context sensitive
        
        // points.forEach((p)=>{
        //     columnPoints.push(
        //         {
        //             pos:p,
        //             required:true,
        //             source:'default' 
        //         }
        //     )
        // });

        // sort the column positions in ascending order so the first and last can be tossed out
        columnPoints.sort((a,b)=>{
            if(a.pos < b.pos)
                return -1;
            else 
                return 1;
        });

        try{
            //this.addColumnsAtPoints(columnPoints);
        }catch(error){
            console.error("error adding column points: ",error);
        }
    }

    addColumnsAtPoints(columnPoints){
        let collisionZones;
        
        for(var ci = 0;ci<columnPoints.length;ci++){
            
            let columnPoint = columnPoints[ci];
            let columnPosAlongWall = columnPoint.pos
            
            let side = columnPoint.side;
            let source = columnPoint.source;

            columnPoint.atSideWall = (ci === 0 || ci === columnPoints.length-1)
            
            // columns purely for overhead doors and windows are not structural (i.e. a porch can't tie into them)
            columnPoint.structural = (source !== CORE.components.doorRollup && 
                                    source !== CORE.components.window && source !== CORE.components.louverVent && source !== CORE.components.emptyFramedOpening)

            // record all columnPositions for sharing with sub-components of the corresponding frameSide (i.e. porches)
            this.design.columnPositions.push(columnPoint);

            // skip the outermost columns at the sideWall
            // we skip instead of remove them because these position are used by frameSides to structurally tie in porches to existing columns.
            if(columnPoint.atSideWall)
                // outside columns (at front side wall or back side wall) are always built by frameBase 
                                
                continue;

            // intermediate columns are always built by the wall
            /*if(this.design.baySpacing.mode === CORE.modes.baySpacing.auto)
                collisionZones = ci === 0 || ci === columnPoints.length-1;
            else*/
                collisionZones=columnPoint.source==='default';

            let z= -4.5//-this.frameLinePos // this is an absolute z
            if(!this.buildFacingPositiveZ)
                z = 4.5;
           
            let bottomPos = new THREE.Vector3(columnPosAlongWall, this.backBottom.y, z);
            let y= BlueprintHelper.getColumnHeightAlongFrameLine(columnPosAlongWall - this.length/2, this.length, this.maxHeight, this.pitchRatio, this.roofType);            
            let topPos = new THREE.Vector3(columnPosAlongWall, y, z);
            let vertLengthY = topPos.y - bottomPos.y;
            switch(this.design.openSupport){
                case CORE.endWallColumns.columnsToGround.value:
                    vertLengthY = (topPos.y - bottomPos.y)-7;
                    break;
                case CORE.endWallColumns.columnsToHeader.value:
                case CORE.endWallColumns.backBrace.value:
                    vertLengthY = (topPos.y - this.design.openHeight)-7; // this.design.openHeight is 0 for leftSkirt here, this.design.height is 36 (openHeight should be )
                    bottomPos.y +=this.design.openHeight;
                    break;
            }
            

            let rot =0;
            let col;
            if(source === CORE.components.doorRollup){    // sliding doors work under this source as sourced under this as well
                
                rot = 0; // open to the right
                if(side === 'right')
                    rot = -Math.PI; // open to the left
                
                col = new _3dColumnC(CORE.preferences.des_levelOfDetail.value, vertLengthY, false, BlueprintHelper.getStandardFrameMaterialSize(), this.girtColor);                                
                col.group.position.copy(bottomPos.clone());
                col.group.rotation.y = rot;
                this.getColumnsGroup().add(col.group);
                layerHelper.enableLayer(col.group, CORE.layers.quote);
                //this.columns.push(col);
            }
            else{
                
                // calculate the distance between this wall's frameline and the next interior frameline
                

                // 
                if(this.design.lineType === CORE.frame.lineTypes.standard)
                {
                    rot = 0; // open to the right
                    if(columnPosAlongWall>this.length/2)
                        rot = -Math.PI; // open to the left
                    
                    if(this.design.typeDetail === CORE.sides.rightEnd)
                        rot+=Math.PI;

                    let xp = 0; // interior Z-axis
                    let xn = .5; // exterior Z -axis

                    if(collisionZones){

                        let margin = CollisionHelper.getMargin(xp,xn,0,0,0,0);
                        if(rot>=0)
                            margin.zp = 6;
                        else
                            margin.zn = 6;

                        let standardFrameLineMaterialDim = BlueprintHelper.getStandardFrameMaterialSize();
                        collisionZones = {
                            dim: new Vector3(standardFrameLineMaterialDim.flange, vertLengthY, standardFrameLineMaterialDim.depth),
                            margin,
                            position: new Vector3(0,vertLengthY/2,0)
                        }
                    }

                    //console.log(bottomPos);
                    col = new _3dColumnC(CORE.preferences.des_levelOfDetail.value, vertLengthY, collisionZones, BlueprintHelper.getStandardFrameMaterialSize(), this.girtColor);                    
                    col.group.position.copy(bottomPos.clone());
                    col.group.rotation.y = rot;
                    this.getColumnsGroup().add(col.group);
                    if(this.addToQuoteLayer)
                        layerHelper.enableLayer(col.group, CORE.layers.quote);
                    //this.columns.push(col);
                    if(this.design.openSupport === CORE.endWallColumns.backBrace.value){
                        
                        //length depends on the height of the wall, position of the column, and the distance to the next frameline
                        /*

                        |\
            vertLengthY | \ braceLength
                        |__\
                         Dist

                         vertLengthY is already known (top.Y - bottom.Y or top.Y - openHeight)
                         top.y  based on column position
                         vertLengthY is top.y, adjusted for open height to know total vertical run
                         solve for H

                         Solve for angle:
                         sohcahtoa
                         tan(theta) = o/a
                         atan(o/a) = theta
                         cos(theta) = a/h
                        */

                         let backBraceAngleRadians = Math.atan(vertLengthY/this.distToNextFrameline);
                         let braceLength = this.distToNextFrameline / Math.cos(backBraceAngleRadians);
                         let columnSquareFrameSideLength = 4;
                         let frameMaterial
                         if(CORE.preferences.des_levelOfDetail.value === CORE.levelOfDetail.high)
                            frameMaterial=materialHelper.getSquareColumnFrameMaterial_HD(braceLength, columnSquareFrameSideLength, this.girtColor);
                        else
                            frameMaterial=materialHelper.getFrameMaterial_LD(this.getBeamColor());
                

                         let brace = new _3dColumnSquare(braceLength, frameMaterial, columnSquareFrameSideLength, CORE.layers.frame ); // only ever square, per Adam on 2021.09.09 via Twist
                         let bracePos = new Vector3().copy(bottomPos);
                         bracePos.y+=3;
                         bracePos.z-=columnSquareFrameSideLength/2;
                         brace.group.position.copy(bracePos.clone())
                         this.getColumnsGroup().add(brace.group);
                         if(this.addToQuoteLayer)
                            layerHelper.enableLayer(brace.group, CORE.layers.quote);
                         brace.group.rotation.x = backBraceAngleRadians - Math.PI/2;
                         
                         if(this.design.typeDetail == CORE.sides.rightEnd)
                            brace.group.rotateY(Math.PI);
                         
                         //this.privateColumns.push(brace);
                    }
                }
                else{
                    // calculate column position
                    // calculate column rotation
                    // endWall can only be left or right end. No need to support the back side.

                    let czOffsetX = 5; // a offset to push the collsion zone out so that it collides with wall opening component zones.
                    let czOffsetZ = -BlueprintHelper.getPostAndBeamFrameMaterialSize().depth/2 // an offset to center the zone in the column depth (since the depth faces out, not the flange)
                    if(this.design.typeDetail === CORE.sides.leftEnd){
                        rot = -Math.PI/2;
                    }
                    else{
                        rot = -Math.PI/2;
                    }

                    if(Math.abs(bottomPos.x) > this.length/2){
                        rot *= -1
                        czOffsetX *= -1;                        
                    }
                    
                    
                    let length = (topPos.y - bottomPos.y)-14;
                    let postAndBeamFrameLineDim = BlueprintHelper.getPostAndBeamFrameMaterialSize();

                    let collisionZoneDetails = collisionZones;
                    if(collisionZoneDetails){
                        
                        let xp = 0;
                        let xn = 0;
                        let zp = .5;
                        let zn = .5;
    
                        let margin = CollisionHelper.getMargin(xp,xn,0,0,zp,zn);

                        
                        collisionZoneDetails = {
                            dim: new Vector3(postAndBeamFrameLineDim.flange, length, postAndBeamFrameLineDim.depth),
                            margin,
                            position: new Vector3(czOffsetX,length/2,czOffsetZ)
                        }
                    }
                    
                    let bugValue = 8;
                    col = new _3dColumnStraight(CORE.preferences.des_levelOfDetail.value,  length, true, collisionZoneDetails, postAndBeamFrameLineDim, bugValue, this.pitchRadians, this.frameType === CORE.frame.types.bolt, this.beamColor);

                    //col.build( bottomPos, rot)
                    col.group.position.copy(bottomPos.clone())
                    col.group.rotation.y =rot

                    this.gDynamic.add(col.group);
                    //this.getColumnsGroup.add(col.group);
                    layerHelper.enableLayer(col.group, CORE.layers.quote);
                    //this.columns.push(col);
                    if(this.design.openSupport === CORE.endWallColumns.backBrace.value){
                        let backBraceAngleRadians = Math.atan(vertLengthY/this.distToNextFrameline);
                         let braceLength = this.distToNextFrameline / Math.cos(backBraceAngleRadians);
                         let columnSquareFrameSideLength = 4;
                         let frameMaterial
                         if(CORE.preferences.des_levelOfDetail.value === CORE.levelOfDetail.high)
                            frameMaterial=materialHelper.getSquareColumnFrameMaterial_HD(braceLength, columnSquareFrameSideLength, this.girtColor);
                        else
                            frameMaterial=materialHelper.getFrameMaterial_LD(this.getBeamColor());
                


                         let brace = new _3dColumnSquare( braceLength, frameMaterial, columnSquareFrameSideLength, CORE.layers.frame);
                         let bracePos = new Vector3().copy(bottomPos);
                         bracePos.y+=3;
                         bracePos.z-=columnSquareFrameSideLength/2;
                         brace.group.position.copy(bracePos.clone())
                         this.getColumnsGroup().add(brace.group);
                         layerHelper.enableLayer(brace.group, CORE.layers.quote);
                         brace.group.rotation.x = backBraceAngleRadians - Math.PI/2;
                         //this.privateColumns.push(brace);
                    }                
                }
            }            
        }
    }

    buildDripTrim(z){

        if(this.design.frameLine !== 0)
            return;
        super.buildDripTrim(z);
    }


    remove(){
        super.remove();        
        this.removeColumns();
    }


    getTypeDisplayName(){return this.getDescription();}
    
}
