import _3dBase from './Base.js'
import * as THREE from 'three';
import {Vector2,  Vector3 } from 'three';
import MemHelper from '../helpers/memHelper.js';
import ShapeHelper from '../helpers/shapeHelper.js'
import { CORE } from '../_spec.js'
import materialHelper from '../helpers/materialHelper.js';
import TrimOpeningFace from './TrimOpeningFace.js';
import layerHelper from '../helpers/layerHelper.js';

export default class DoorWalkin extends _3dBase{

    constructor(lod, parentGroup, dim, trimMaterialColor, stainlessMaterial, deadbolt, halfGlass, pos, isLeftDoor){
        super(parentGroup, pos, undefined, lod)
        this.extrusionSettings = {
            depth: 1.75, //used to be amount
            bevelEnabled: false,
            bevelThickness: 0.1,
            bevelSize: 1,
            bevelSegments: 1,
            material: 0,
            extrudeMaterial: 1
        };
        this.isLeftDoor = isLeftDoor;
        this.build(dim, trimMaterialColor, stainlessMaterial, deadbolt, halfGlass)
    }

    build(dim, trimMaterialColor, stainlessMaterial, deadbolt, halfGlass){

        if(this.isLowDetail())
            this.singleDoor_LD(dim, trimMaterialColor, stainlessMaterial, deadbolt, halfGlass)
        else
            this.singleDoor_HD(dim, trimMaterialColor, stainlessMaterial, deadbolt, halfGlass)    
    }

    singleDoor_LD(dim, trimMaterialColor, stainlessMaterial, deadbolt, halfGlass){
        let doorThickness = 0.55;
        let frameThickness = 2;
        let halfDim = dim.clone().multiplyScalar(.5);
        let halfFrameThickness = frameThickness / 2;

        this.matDoor = materialHelper.getDoorMaterial(0xDDDDDD)
        this.sizeDoor = new Vector3(dim.x, dim.y, 0);
        let halfSize = new THREE.Vector2(this.sizeDoor.x/2, this.sizeDoor.y/2, this.sizeDoor.z/2);

        // ways to poke holes in things
        // alpha map (I have tooling for this) to give appearance of a hole
        // if the holes are predictable, make the surrounding geometries instead of holes
        //  - this works for doors, but not for walls with variable number and size of holes
        // 

        let g = new THREE.Group();

     
        // anything other than true means it's undefined (not applicable) or false
        if(halfGlass===true){

            let geoDoorBottom = new THREE.BoxGeometry(dim.x, dim.y/2, doorThickness);//new THREE.PlaneBufferGeometry(dim.x, dim.y/2, 1,1);
            let meshDoorBottom = new THREE.Mesh(geoDoorBottom, this.matDoor);
            meshDoorBottom.name = 'door bottom'
            meshDoorBottom.castShadow=false;
            meshDoorBottom.position.y = -dim.y/4
            meshDoorBottom.layers.set(CORE.layers.doorsWalkin) ;              
            g.add(meshDoorBottom);

            let geoDoorSide = new THREE.BoxGeometry(6, dim.y/2, doorThickness);//new THREE.PlaneBufferGeometry(6, dim.y/2, 1,1);
            let meshDoorLeftSide = new THREE.Mesh(geoDoorSide, this.matDoor);
            meshDoorLeftSide.name = 'door left'
            meshDoorLeftSide.castShadow=false;
            meshDoorLeftSide.position.y = +dim.y/4
            meshDoorLeftSide.position.x = -dim.x/2+3;
            meshDoorLeftSide.layers.set(CORE.layers.doorsWalkin) ;              
            g.add(meshDoorLeftSide);

            let meshDoorRightSide = new THREE.Mesh(geoDoorSide, this.matDoor);
            meshDoorRightSide.name = 'door right'
            meshDoorRightSide.castShadow=false;
            meshDoorRightSide.position.y = +dim.y/4
            meshDoorRightSide.position.x = +dim.x/2-3;
            meshDoorRightSide.layers.set(CORE.layers.doorsWalkin) ;              
            g.add(meshDoorRightSide);

            let geoDoorTop = new THREE.BoxGeometry(dim.x, 6, doorThickness);//new THREE.PlaneBufferGeometry(dim.x, 6, 1,1);
            let meshDoorTop = new THREE.Mesh(geoDoorTop, this.matDoor);
            meshDoorTop.name = 'door top'
            meshDoorTop.castShadow=false;
            meshDoorTop.position.y = +dim.y/2-3
            meshDoorTop.layers.set(CORE.layers.doorsWalkin);
            g.add(meshDoorTop);
        }
        else{
            // if the door is 6'x7', we need to use box geometry so that it hides the left door right vertical trim. 
            let geoDoor = new THREE.BoxGeometry(dim.x, dim.y, doorThickness);
            //let geoDoor = new THREE.PlaneBufferGeometry(dim.x, dim.y, 1,1); 
            let meshDoor = new THREE.Mesh(geoDoor, this.matDoor);
            meshDoor.name = 'door bottom'
            meshDoor.castShadow=false;
            //meshDoor.position.y = -dim.y/2;
            meshDoor.layers.set(CORE.layers.doorsWalkin)               
            g.add(meshDoor);
        }

        
        let trim = new TrimOpeningFace(this.lod, dim, new Vector2(frameThickness,frameThickness,1), trimMaterialColor, false);
        
        // 
        if(this.isLeftDoor)
            trim.group.position.z+=.25;
         else
             trim.group.position.z -=0.25;
        this.group.add(trim.group);                       
        layerHelper.setGroup(trim.group, CORE.layers.walls, true);
        layerHelper.enableLayer(trim.group, CORE.layers.quote);

        if(deadbolt===true){
            // deadbolt
            var geoLock = new THREE.CylinderGeometry( 3/3, 3/3, 3, 16 );
            let meshLock = new THREE.Mesh(geoLock, stainlessMaterial);
            meshLock.name = 'deadbolt'
            meshLock.rotation.x = Math.PI / 2;
            meshLock.position.set(halfDim.x * .8, 0, 0)
            g.add(meshLock);
        }
        

        this.group.add(g)
        return this.group;
    }

    singleDoor_HD(dim, trimMaterialColor, stainlessMaterial, deadbolt, halfGlass){
        
        let frameThickness = 2;
        let halfDim = dim.clone().multiplyScalar(.5);
        let halfFrameThickness = frameThickness / 2;

        this.matDoor = materialHelper.getDoorMaterial(0xDDDDDD)
        this.sizeDoor = new Vector3(dim.x, dim.y, 0);
        let halfSize = new THREE.Vector2(this.sizeDoor.x/2, this.sizeDoor.y/2, this.sizeDoor.z/2);

        //var geoGlass = new THREE.BoxGeometry(this.sizeDoor.x, this.sizeDoor.y, this.sizeDoor.z);
        //let meshDoor = new THREE.Mesh(geoGlass, this.matDoor);

        // ways to poke holes in things
        // alpha map (I have tooling for this) to give appearance of a hole
        // if the holes are predictable, make the surrounding geometries instead of holes
        //  - this works for doors, but not for walls with variable number and size of holes
        // 



        // Create a shape for the door that is A) extrudable and B) accepts holes (for half glass option)
        let shapePoints = [
            new THREE.Vector2(-halfSize.x, -halfSize.y),
            new THREE.Vector2(-halfSize.x, +halfSize.y),
            new THREE.Vector2(+halfSize.x, +halfSize.y),
            new THREE.Vector2(+halfSize.x, -halfSize.y),
        ]
        this.sh = new ShapeHelper(shapePoints, undefined, undefined, true);
        
        let meshGlass;
        // anything other than true means it's undefined (not applicable) or false
        if(halfGlass===true){
            let sizeGlass = new THREE.Vector2(24,36)
            let hole = this.getHalfGlassHole(new THREE.Vector2(0,sizeGlass.y/2), sizeGlass);
            this.sh.removeShape(hole, true);
            var geoGlass = new THREE.BoxGeometry(sizeGlass.x, sizeGlass.y, .1);
            geoGlass.name = 'geoglass'
            let matGlass = materialHelper.getGlassMaterial_HD();
            meshGlass = new THREE.Mesh(geoGlass, matGlass);
            meshGlass.renderOrder=1;
            meshGlass.name = 'walkdoor glass'
            meshGlass.position.y = sizeGlass.y/2;
        }

        this.geoHoleyDoor = new THREE.ExtrudeGeometry(this.sh.getShape(), this.extrusionSettings);
        let meshDoor = new THREE.Mesh(this.geoHoleyDoor, this.matDoor);
        meshDoor.name = 'holey door'
        meshDoor.castShadow=true;
        meshDoor.position.z=-1;

        let trim = new TrimOpeningFace(this.lod, dim, new Vector2(frameThickness,frameThickness,1), trimMaterialColor, false);
        
        trim.group.position.z+=.25;
        
        this.group.add(trim.group);               
        
        layerHelper.setGroup(trim.group, CORE.layers.walls, true);
        layerHelper.enableLayer(trim.group, CORE.layers.quote);


        if(deadbolt===true){
            // deadbolt
            var geoLock = new THREE.CylinderGeometry( 3/3, 3/3, 3, 16 );
            let meshLock = new THREE.Mesh(geoLock, stainlessMaterial);
            meshLock.name = 'deadbolt'
            meshLock.rotation.x = Math.PI / 2;
            meshLock.position.set(halfDim.x * .8, 0, 0)
            this.group.add(meshLock);
        }
        
        let handlePosY = -5;
        // handle shank
        var geoHandleShank = new THREE.CylinderGeometry( 2/3, 2/3, 7, 10 );
        let meshHandleShank = new THREE.Mesh(geoHandleShank, stainlessMaterial);
        meshHandleShank.name = 'walkdoor handle shank'
        meshHandleShank.rotation.x = Math.PI / 2;
        meshHandleShank.position.set(halfDim.x * .8, handlePosY, 0)
        
        // handles
        let handleWidth =  4;
        var geoHandle = new THREE.BoxGeometry( handleWidth, 1, 1);
        geoHandle = new THREE.CylinderGeometry(.65,.65,handleWidth,16,1,false)
        // outside handle
        let meshHandleOut = new THREE.Mesh(geoHandle, stainlessMaterial);
        meshHandleOut.name = 'walkdoor outside handle'
        meshHandleOut.rotation.z = Math.PI / 2;
        meshHandleOut.position.set((halfDim.x * .8)-handleWidth/2, handlePosY, 3)
        
        // inside handle
        let meshHandleIn = new THREE.Mesh(geoHandle, stainlessMaterial);
        meshHandleIn.name = 'walkdoor inside handle'
        meshHandleIn.rotation.z = Math.PI / 2;
        meshHandleIn.position.set((halfDim.x * .8)-handleWidth/2, handlePosY, -3)
        
        this.group.add(meshDoor)
        if(meshGlass){
            this.group.add(meshGlass);
            meshGlass.layers.set(CORE.layers.doorsWalkin)
        }
        this.group.add(meshHandleShank);
        this.group.add(meshHandleOut);
        this.group.add(meshHandleIn);

        meshDoor.layers.set(CORE.layers.doorsWalkin)
        
        meshHandleShank.layers.set(CORE.layers.doorsWalkin)
        meshHandleOut.layers.set(CORE.layers.doorsWalkin)
        meshHandleIn.layers.set(CORE.layers.doorsWalkin)

        
        return this.group;
    }


    getHalfGlassHole(pos, dim) {
        // added to support optimized wall sub-component re-positioning
        let halfWidth = dim.x / 2;
        let halfHeight = dim.y / 2;

        let botLeft = new Vector2(pos.x - halfWidth, pos.y - halfHeight);
        let topLeft = new Vector2(pos.x - halfWidth, pos.y + halfHeight);
        let topRight = new Vector2(pos.x + halfWidth, pos.y + halfHeight);
        let botRight = new Vector2(pos.x + halfWidth, pos.y - halfHeight);

        var hole = new THREE.Shape();
        let offset = new THREE.Vector2(0,0);
        hole.moveTo(botLeft.x-offset.x, botLeft.y);
        hole.lineTo(topLeft.x-offset.x, topLeft.y);
        hole.lineTo(topRight.x-offset.x, topRight.y);
        hole.lineTo(botRight.x-offset.x, botRight.y);
        hole.lineTo(botLeft.x-offset.x, botLeft.y);
        return hole;
    }
}