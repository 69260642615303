import MemHelper from '../helpers/memHelper.js';

// in window add mode, it just draws a new window where the mouse is
// when we click and drag, we need to detect what drag point we're using
// and update the real-time assets accordingly
// when mouse down, figure out which drag point
// as long as mouse is down, keep the mode
// when the mouse is let up, reset what edit mode


import * as THREE from 'three';
import { CORE } from '../_spec.js';
import Arrow from '../3d/Arrow.js';

export default class Grabject{
    constructor(
        object,        
        parentDesignId,
        anchorPosition,
        indicators,
        size,
        role,
        allowedParentComponentTypes,
        basis,
        offsetPosition,
        
        ){
        this.parentDesignId=parentDesignId;
        this.allowedParentComponentTypes = allowedParentComponentTypes;
        this.anchorPosition = anchorPosition;
        this.offsetPosition = offsetPosition;
        this.indicators = indicators?indicators:[];
        this.size = size;
        this.role = role;
        this.basis = basis;
        this.build(object);
    }

    show(visible){
        this.meshG.visible = visible;
        this.sp.visible = visible;
        this.arrow.visible = visible;
    }

    //static roles = {topLeft:'topLeft', top:'top', topRight:'topRight', right:'right', bottomRight:'bottomRight', bottom:'bottom', bottomLeft:'bottomLeft', left:'left', position:'position'}

    build(object){
        
        this.group = new THREE.Group();
        this.group.name = 'grabject';
        let position = this.anchorPosition.clone();
        if(this.offsetPosition)        
            position.add(this.offsetPosition);
        
        this.group.position.copy(position.clone());

        this.objects=[];


        if( this.indicators.includes(CORE.indicators.sphere)){
            this.buildSphere(6);
        }

        if( this.indicators.includes(CORE.indicators.left) ||
            this.indicators.includes(CORE.indicators.leftRight))
            this.buildLeftArrow(1.5);
        
        if( this.indicators.includes(CORE.indicators.right) ||
            this.indicators.includes(CORE.indicators.leftRight))
            this.buildRightArrow(1.5);

        if( this.indicators.includes(CORE.indicators.in) ||
            this.indicators.includes(CORE.indicators.inOut))
            this.buildInArrow(1.5);

        if( this.indicators.includes(CORE.indicators.out) ||
            this.indicators.includes(CORE.indicators.inOut))
            this.buildOutArrow(1.5);

        if( this.indicators.includes(CORE.indicators.up) ||
            this.indicators.includes(CORE.indicators.upDown))            
            this.buildUpArrow(1.5);

        if( this.indicators.includes(CORE.indicators.down) ||
            this.indicators.includes(CORE.indicators.upDown))            
            this.buildDownArrow(1.5);

        if( this.indicators.includes(CORE.indicators.topLeft))
            this.buildTopLeftArrow(1.5);

        if( this.indicators.includes(CORE.indicators.topRight))
            this.buildTopRightArrow(1.5);

        if( this.indicators.includes(CORE.indicators.bottomLeft))
            this.buildBottomLeftArrow(1.5);

        if( this.indicators.includes(CORE.indicators.bottomRight))
            this.buildBottomRightArrow(1.5);
            

        if(this.indicators.includes(CORE.indicators.upDownLeftRight)){            
            this.buildUpArrow(1.5);
            this.buildDownArrow(1.5);
            this.buildLeftArrow(1.5);
            this.buildRightArrow(1.5);
        }

        if(this.indicators.includes(CORE.indicators.inOutLeftRight)){            
            this.buildInArrow(1.5);
            this.buildOutArrow(1.5);
            this.buildLeftArrow(1.5);
            this.buildRightArrow(1.5);
        }

        
        if(this.indicators.includes(CORE.indicators.rotation)){            
            this.buildRotationArrow(1.5);
        }


        this.objects.forEach((o)=>{
            this.group.add(o);
        })
        object.add(this.group);

    }

    buildSphere(size){
        let group = new THREE.Group();
        group.name= 'grabject sphere';

        let matG= new THREE.MeshBasicMaterial({ color: CORE.colors.grabject.inactive,
            transparent: true,
            opacity: .5
        });
        
        //console.log('created transparent')
        this.geoG = new THREE.SphereGeometry( size,8,8);
        this.meshG = new THREE.Mesh(this.geoG, matG);
        this.meshG.userData = {
            parent: {
                id: this.parentDesignId
            },
            role: this.role,
            type: CORE.components.grabject,
            allowedParentComponentTypes: this.allowedParentComponentTypes,
            basis: this.basis
        };        
        //this.meshG.position.set(this.anchorPosition.x,this.anchorPosition.y, this.anchorPosition.z);
        this.meshG.name = `${this.role} grabject for ` + this.parentDesignId;
        group.add(this.meshG);
        
        this.objects.push(group)
        group.children.forEach((c)=> {
            c.userData = {
                parent: {
                    id: this.parentDesignId
                },
                role: this.role,
                type: CORE.components.grabject,
                allowedParentComponentTypes: this.allowedParentComponentTypes,
                basis: this.basis
            };
        })
    }

    buildDisc(size){
        let group = new THREE.Group();
        group.name= 'grabject disc';
        let matC= new THREE.MeshBasicMaterial({ 
            color: 0x5555ff,
            transparent: true,
            opacity: .3
        });
        this.geoC = new THREE.CylinderGeometry( size, size, .2, 8,1, false, 0, Math.PI*2);
        this.meshC = new THREE.Mesh(this.geoC, matC);
        this.meshC.userData = {
            parent: {
                id: this.parentDesignId
            },
            role: this.role,
            type: CORE.components.grabject,
            allowedParentComponentTypes: this.allowedParentComponentTypes,
            basis: this.basis
        };        
        this.meshC.position.set(this.anchorPosition.x,this.anchorPosition.y, this.anchorPosition.z);
        this.meshC.name = `${this.role} grabject for ` + this.parentDesignId;
        this.meshC.rotateX(Math.PI/2)
        group.add(this.meshC);
        this.objects.push(group)
        group.children.forEach((c)=> {
            c.userData = {
                parent: {
                    id: this.parentDesignId
                },
                role: this.role,
                type: CORE.components.grabject,
                allowedParentComponentTypes: this.allowedParentComponentTypes,
                basis: this.basis
            };
        })
    }
    
    buildRotationArrow(){
        let arrow = new Arrow(0, CORE.colors.grabject.inactive, true).group;
        arrow.scale.set(this.size.x, this.size.y, this.size.z);
        this.objects.push(arrow)
        arrow.children.forEach((c)=> {
            c.userData = {
                parent: {
                    id: this.parentDesignId
                },
                role: this.role,
                type: CORE.components.grabject,
                allowedParentComponentTypes: this.allowedParentComponentTypes,
                basis: this.basis
            };
        })
        return arrow;
    }

    buildArrow(y){
        let arrow = new Arrow(y, CORE.colors.grabject.inactive).group;
        arrow.scale.set(this.size.x, this.size.y, this.size.z);
        this.objects.push(arrow)
        arrow.children.forEach((c)=> {
            c.userData = {
                parent: {
                    id: this.parentDesignId
                },
                role: this.role,
                type: CORE.components.grabject,
                allowedParentComponentTypes: this.allowedParentComponentTypes,
                basis: this.basis
            };
        })
        return arrow;
    }

    buildTopLeftArrow(y){
        let arrow = this.buildArrow(y);
        arrow.rotation.z = Math.PI/4;        
        return arrow;
    }

    buildTopRightArrow(y){
        let arrow = this.buildArrow(y);
        arrow.rotation.z = -Math.PI/4;
        return arrow;
    }

    buildBottomLeftArrow(y){
        let arrow = this.buildArrow(y);
        arrow.rotation.z = 3*Math.PI/4;
        return arrow;
    }

    buildBottomRightArrow(y){
        let arrow = this.buildArrow(y);
        arrow.rotation.z = -3*Math.PI/4;
        return arrow;
    }


    buildLeftArrow(y){
        let arrow = this.buildArrow(y);
        arrow.rotation.z = Math.PI/2;
        return arrow;
    }

    buildRightArrow(y){
        let arrow = this.buildArrow(y);
        arrow.rotation.z = -Math.PI/2;
        return arrow;
    }

    buildUpArrow(y){
        let arrow = this.buildArrow(y);
        return arrow;
    }
    
    buildDownArrow(y){
        let arrow = this.buildArrow(y);
        arrow.rotation.z = Math.PI;
        return arrow;
    }

    buildInArrow(y){
        let arrow = this.buildArrow(y);
        arrow.rotation.x = -Math.PI/2;
        return arrow;
    }

    buildOutArrow(y){
        let arrow = this.buildArrow(y);
        arrow.rotation.x = Math.PI/2;
        arrow.rotation.y = Math.PI;
        return arrow;
    }

    remove(){
        this.objects.forEach((a)=>{
            MemHelper.dispose(a);
        })
        MemHelper.dispose(this.meshG)
        MemHelper.dispose(this.sp)
    }
}