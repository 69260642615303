<template>
    <div style="text-align: center;"><font-awesome-icon icon="fa-duotone fa-location-crosshairs" /> {{structureName}}</div> 
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  computed: {
    
...mapGetters('designModule', [
      'structure',
    ]),

    structureName(){
          if(this.structure)
            return this.structure.design.name
        return ""
        },
  },
  created(){
  },
  mounted(){
  },
  methods: {      
  },
  components:{
  }
}
</script> 

<style scoped>

</style>


