<template>
    <span :id="uniqueId" class="vessel">
        <div v-if="status==='None'" v-bind:style="styleObj" />
        <div id="divIfSuccess" v-if="status==='Success'">
            <svg class="svgW3" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 :width="statusWidth" :height="statusHeight" viewBox="0 0 48 48">
                <linearGradient id="HoiJCu43QtshzIrYCxOfCa_VFaz7MkjAiu0_gr1" x1="21.241" x2="3.541" y1="39.241" y2="21.541" gradientUnits="userSpaceOnUse">
                    <stop offset=".108" stop-color="#0d7044"></stop>
                    <stop offset=".433" stop-color="#11945a"></stop>
                </linearGradient>
                <path fill="url(#HoiJCu43QtshzIrYCxOfCa_VFaz7MkjAiu0_gr1)" d="M16.599,41.42L1.58,26.401c-0.774-0.774-0.774-2.028,0-2.802l4.019-4.019	c0.774-0.774,2.028-0.774,2.802,0L23.42,34.599c0.774,0.774,0.774,2.028,0,2.802l-4.019,4.019	C18.627,42.193,17.373,42.193,16.599,41.42z"></path>
                <linearGradient id="HoiJCu43QtshzIrYCxOfCb_VFaz7MkjAiu0_gr2" x1="-15.77" x2="26.403" y1="43.228" y2="43.228" gradientTransform="rotate(134.999 21.287 38.873)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#2ac782"></stop><stop offset="1" stop-color="#21b876"></stop></linearGradient>
                <path fill="url(#HoiJCu43QtshzIrYCxOfCb_VFaz7MkjAiu0_gr2)" d="M12.58,34.599L39.599,7.58c0.774-0.774,2.028-0.774,2.802,0l4.019,4.019	c0.774,0.774,0.774,2.028,0,2.802L19.401,41.42c-0.774,0.774-2.028,0.774-2.802,0l-4.019-4.019	C11.807,36.627,11.807,35.373,12.58,34.599z"></path>
            </svg>
            <span id="spanSuccessText">{{successText}}</span>
        </div>

        <svg class="svgW3" v-else-if="status==='Failure'" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" :width="statusWidth" :height="statusHeight"
             viewBox="0 0 172 172">
            <g id="gOuter" fill="none" fill-rule="nonzero" stroke="none" stroke-width="none" stroke-linecap="none" stroke-linejoin="none" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none">
                <path d="M0,172v-172h172v172z" fill="none" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter"></path>
                <g>
                    <path d="M103.46488,86l40.0072,-40.0072c1.34332,-1.34332 1.34332,-3.52256 0,-4.86588l-12.599,-12.599c-1.34332,-1.34332 -3.52256,-1.34332 -4.86588,0l-40.0072,40.0072l-40.0072,-40.0072c-1.34332,-1.34332 -3.52256,-1.34332 -4.86588,0l-12.599,12.599c-1.34332,1.34332 -1.34332,3.52256 0,4.86588l40.0072,40.0072l-40.0072,40.0072c-1.34332,1.34332 -1.34332,3.52256 0,4.86588l12.599,12.599c1.34332,1.34332 3.52256,1.34332 4.86588,0l40.0072,-40.0072l40.0072,40.0072c1.34332,1.34332 3.52256,1.34332 4.86588,0l12.599,-12.599c1.34332,-1.34332 1.34332,-3.52256 0,-4.86588z" fill="#e74c3c" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter"></path>
                    <path d="M103.46488,86l40.0072,-40.0072c1.34332,-1.34332 1.34332,-3.52256 0,-4.86588l-12.599,-12.599c-1.34332,-1.34332 -3.52256,-1.34332 -4.86588,0l-40.0072,40.0072l-40.0072,-40.0072c-1.34332,-1.34332 -3.52256,-1.34332 -4.86588,0l-12.599,12.599c-1.34332,1.34332 -1.34332,3.52256 0,4.86588l40.0072,40.0072l-40.0072,40.0072c-1.34332,1.34332 -1.34332,3.52256 0,4.86588l12.599,12.599c1.34332,1.34332 3.52256,1.34332 4.86588,0l40.0072,-40.0072l40.0072,40.0072c1.34332,1.34332 3.52256,1.34332 4.86588,0l12.599,-12.599c1.34332,-1.34332 1.34332,-3.52256 0,-4.86588z" fill="none" stroke="#1f212b" stroke-width="3.44" stroke-linecap="butt" stroke-linejoin="round"></path>
                    <path d="M43.86,36.98l42.14,42.14l23.22,-23.22" fill="none" stroke="#1f212b" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M126.42,38.7l1.72,-1.72" fill="none" stroke="#1f212b" stroke-width="1" stroke-linecap="round" stroke-linejoin="miter"></path>
                    <path d="M112.66,52.46l10.32,-10.32" fill="none" stroke="#1f212b" stroke-width="1" stroke-linecap="round" stroke-linejoin="miter"></path>
                </g>
            </g>
        </svg>
        <svg v-else-if="status==='Loading'" version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             :width="statusWidth" :height="statusHeight" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve">
        <path opacity="0.5" fill="#000" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                            s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                            c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z" />
        <path fill="#000" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                            C22.32,8.481,24.301,9.057,26.013,10.047z">
        <animateTransform attributeType="xml"
                          attributeName="transform"
                          type="rotate"
                          from="0 20 20"
                          to="360 20 20"
                          dur="0.5s"
                          repeatCount="indefinite" />
                        </path>
                    </svg>
    </span>
</template>

<script>
    export default {
        name: 'status-indicator',
        props: {
            id: {
                type: String,
                required: false,
                default: 'uid',
                note: 'identifier'
            },
            statusWidth: {
                type: Number,
                required: false,
                default: 25,
                note: 'width of the images'
            },
            statusHeight: {
                type: Number,
                required: false,
                default: 25,
                note: 'height of the images'
            },
            successText: {
                type: String,
                required: false,
                default: "",
                note: 'text to show with success icon'
            }

        },
        data: function () {
            return {
                // ✔️ ❌
                uniqueId: `status-indicator_${this.jFnRandomId()}`,
                status: 'None',
                statuses: ['None', 'Success', 'Failure', 'Loading'],
                styleObj: {
                    'display': 'inline-block',
                    'min-width': this.statusWidth + 'px',
                    'min-height': this.statusHeight + 'px'
                },
                icons: { 'None': '', 'Success': '', 'Failure': '', 'Loading': '' },
                messages: { 'None': '', 'Success': 'Success', 'Failure': 'Failed', 'Loading': 'In progress' },
                minimumLoadingShowTime: 500, // show loading for at least half a second; not used
            }
        },
        computed: {

        },
        watch: {

        },
        mounted: function () {

        },
        updated: function () {

        },
        methods: {

            jFnRandomId: function() {
                return '_' + Math.random().toString(36).substr(2, 9);
            },
            hide: function () {
                this.status = 'None';
                this.$emit('done')
            },
            loading: function () {
                this.status = 'Loading';
            },
            success: function (autoHide = true) {
                this.status = 'Success';
                if (autoHide)
                    setTimeout(this.hide, 3000);
            },
            fail: function (autoHide = true) {
                this.status = 'Failure';
                if (autoHide)
                    setTimeout(this.hide, 3000);
            },
        }
    }
</script>

<style scoped>
    .vessel {
        pointer-events: none;
    }
    #divIfSuccess {
        display: inline;
    }
    .svgW3 {
        fill: #000000;
    }
    #spanSuccessText {
        color:green;
    }
    #gOuter {
        mix-blend-mode: normal;
    }
</style>