import * as THREE from 'three';
import _3dBase from './Base.js'
import _3dGirtBase from './GirtBase.js'

import {CORE} from '../_spec.js'
import { Vector3, Vector2 } from 'three';
import ShapeHelper from '../helpers/shapeHelper.js'
import MemHelper from '../helpers/memHelper.js';
import materialHelper from '../helpers/materialHelper.js';
export default class Girt2 extends _3dBase {
    constructor(lod, length, addCollisionZone, color){		
		super(undefined, undefined, undefined, lod );
		this.group.name = 'horizontal girt';
		this.addCollisionZone = addCollisionZone;
		this.dim = {
            web: 8,
			flange: 4,
			lip: .5,
			length,
		}
		this.color = color ? color : CORE.frame.redOxideColor;
		//https://drive.google.com/drive/folders/1MmrI7Iaq51SJwRktT_inwyxKPjl3NmIA?ths=true
		/*

		flange    .origin
		|-------| 
	lip |		|
				|
				|
				| web
				|
		|		|
		|-------|
		*/
		
		//build the girt to face in,
		// then they can be rotated up from there, hopefully.
		this.build();
    }

	build_HD(){

		let material = materialHelper.getFrameMaterial_HD(this.color);

		this.geoWeb = new THREE.BoxGeometry(this.dim.length, this.dim.web, CORE.steel.thickness_in);
		var meshWeb = new THREE.Mesh( this.geoWeb, material);
		meshWeb.name = 'horizontal girt web'
		meshWeb.position.z = CORE.steel.thickness_in/2;

		var sizeHeight = new THREE.Vector3( this.dim.length, CORE.steel.thickness_in, this.dim.flange);
		this.geoFlange = new THREE.BoxGeometry(sizeHeight.x,sizeHeight.y,sizeHeight.z);
        var meshFlangeTop = new THREE.Mesh( this.geoFlange, material);
		meshFlangeTop.name = 'horizontal girt top flange'
		meshFlangeTop.position.z= this.dim.flange/2;
		meshFlangeTop.position.y= this.dim.web/2;

		var meshFlangeBottom = new THREE.Mesh( this.geoFlange, material);
		meshFlangeBottom.name = 'horizontal girt bottom flange'
		meshFlangeBottom.position.z= this.dim.flange/2;
		meshFlangeBottom.position.y=-this.dim.web/2;

		this.group.add(
			meshWeb,
			meshFlangeTop,
			meshFlangeBottom
		);

		meshWeb.castShadow=true;
		meshFlangeTop.castShadow=true;
		meshFlangeBottom.castShadow=true;
		meshWeb.layers.set(CORE.layers.frame)
		meshFlangeTop.layers.set(CORE.layers.frame)
		meshFlangeBottom.layers.set(CORE.layers.frame)
	}

	
	build_LD(){

		let material = materialHelper.getFrameMaterial_LD(this.color);

		this.geoWeb = new THREE.PlaneBufferGeometry(this.dim.length, this.dim.web,1,1);
		var meshWeb = new THREE.Mesh( this.geoWeb, material);
		meshWeb.name = 'horizontal girt web'

		this.geoFlange = new THREE.PlaneBufferGeometry(this.dim.length,this.dim.flange,1,1);
        var meshFlangeTop = new THREE.Mesh(this.geoFlange, material);
		meshFlangeTop.name = 'horizontal girt top flange'
		meshFlangeTop.rotation.x = Math.PI/2;
		meshFlangeTop.position.z= this.dim.flange/2;
		meshFlangeTop.position.y= this.dim.web/2;

		var meshFlangeBottom = new THREE.Mesh( this.geoFlange, material);
		meshFlangeBottom.name = 'horizontal girt bottom flange'
		meshFlangeBottom.rotation.x = Math.PI/2;
		meshFlangeBottom.position.z= this.dim.flange/2;
		meshFlangeBottom.position.y=-this.dim.web/2;

		this.group.add(
			meshWeb,
			meshFlangeTop,
			meshFlangeBottom
		);

		meshWeb.castShadow=false;
		meshFlangeTop.castShadow=false;
		meshFlangeBottom.castShadow=false;
		meshWeb.layers.set(CORE.layers.frame)
		meshFlangeTop.layers.set(CORE.layers.frame)
		meshFlangeBottom.layers.set(CORE.layers.frame)
	}

    build(){
		// draw a beam
		
		 // Create a custom texture for this object
		let texture = CORE.textures.beam.clone();
		texture.wrapT = texture.wrapS = THREE.MirroredRepeatWrapping;
		texture.encoding = THREE.sRGBEncoding;        
		texture.repeat.set(this.dim.length,8); 
		
		if(this.isLowDetail())
			this.build_LD();
		else
			this.build_HD();
	}

	
    getExtrudedMesh(points, material){
        this.extrusionSettings = {
            depth: .75, //used to be amount
            bevelEnabled: false,
            bevelThickness: 0.1,
            bevelSize: 1,
            bevelSegments: 1,
            material: 0,
            extrudeMaterial: 1
        };

        let mesh = ShapeHelper.getMeshFromPoints( 
            points,        
        this.extrusionSettings,
        material );        
        return mesh;
    }

}