//import { CORE } from './_spec.js'
import materialHelper from "../helpers/materialHelper"
import _3dBase from './Base.js'
import * as THREE from 'three';

export default class LouverVent extends _3dBase {
    constructor(lod, parentGroup, sideLength, stainlessMaterial, includeFan){
        super(parentGroup, undefined, undefined, lod)
        
        this.sideLength = sideLength;
        this.stainlessMaterial = stainlessMaterial;
        //this.trimMaterial = trimMaterial;

        this.includeFan = includeFan;
        this.group.name = 'Louver Vent'
        this.build();
    }

    build(){
        if(this.isLowDetail()){
			this.build_LD();
        }
    }

    build_LD(){
        /** front of louver vent faces positive z  */
        let gHousingAndLouvers = new THREE.Group();

        // Build Walls of Box 
        let louverBoxDepth = 10;
        let louverBoxSideLength = this.sideLength;
        let halfLouverBoxLength = louverBoxSideLength / 2;
        let leftSide = this.BuildLouverVentSide(louverBoxDepth,louverBoxSideLength+0.5, -halfLouverBoxLength,0,-3, 0,Math.PI/2,0);
        gHousingAndLouvers.add(leftSide);

        let topSide = this.BuildLouverVentSide(louverBoxDepth,louverBoxSideLength, 0,halfLouverBoxLength,-3, Math.PI/2,0,Math.PI/2);
        gHousingAndLouvers.add(topSide);

        let rightSide = this.BuildLouverVentSide(louverBoxDepth,louverBoxSideLength+0.5, halfLouverBoxLength,0,-3, 0,-Math.PI/2,0);
        gHousingAndLouvers.add(rightSide);

        let bottomSide = this.BuildLouverVentSide(louverBoxDepth,louverBoxSideLength, 0,-halfLouverBoxLength,-3, Math.PI/2,0,Math.PI/2);
        gHousingAndLouvers.add(bottomSide);

        // Build Back Wall With Hole
        let backWallPlanePoints = [
            new THREE.Vector3(halfLouverBoxLength, halfLouverBoxLength, 0), // top right
            new THREE.Vector3(halfLouverBoxLength, -halfLouverBoxLength, 0), // bottom right
            new THREE.Vector3(-halfLouverBoxLength, -halfLouverBoxLength, 0), // bottom left
            new THREE.Vector3(-halfLouverBoxLength, halfLouverBoxLength, 0) // top left
        ]
        let fanCompartmentRadius = this.sideLength /2 - 3;
        let shBackWall = new THREE.Shape().setFromPoints(backWallPlanePoints)
        let holeVertices = new THREE.Path().absellipse(0, 0, fanCompartmentRadius, fanCompartmentRadius, 0, Math.PI*2);
        shBackWall.holes.push(holeVertices)
        let geoBackWall = new THREE.ShapeGeometry(shBackWall)
        geoBackWall.name = 'geoBackWall';
        let material =  materialHelper.getTrimMaterial({color: 0xBEBEBE});   
        material.side = THREE.DoubleSide;
        let meshBackWall = new THREE.Mesh(geoBackWall, material)
        meshBackWall.name = 'meshBackWall';
        meshBackWall.position.z = -8;
        gHousingAndLouvers.add(meshBackWall);
        
        //Build Louvers
        this.BuildLouvers(gHousingAndLouvers);

        this.group.add(gHousingAndLouvers);

        //Build Cylinder Fan Compartment
        let geoFanCompartment = new THREE.CylinderGeometry(fanCompartmentRadius, fanCompartmentRadius, 10, 8, undefined, true);
        geoFanCompartment.openEnded = true;
        geoFanCompartment.name = "geoFanCompartment"  
        let meshFanCompartment = new THREE.Mesh(geoFanCompartment, this.stainlessMaterial);
        meshFanCompartment.position.set(0, 0, -10);
        meshFanCompartment.rotation.x = Math.PI/2
        meshFanCompartment.name = 'meshFanCompartment'     
        this.group.add(meshFanCompartment);

        if(this.includeFan){       
            let gMotorAndFan = new THREE.Group();

            // Build Fan
            this.BuildFanBlades(gMotorAndFan, fanCompartmentRadius-2);
            
            // Build Motor
            let motorRadius = fanCompartmentRadius / 3
            let geoMotor = new THREE.CylinderGeometry(motorRadius, motorRadius, motorRadius, 8);
            let meshMotor = new THREE.Mesh(geoMotor, material);
            meshMotor.position.set(0, 0, -8);
            meshMotor.rotation.x = Math.PI/2
            meshMotor.name = 'motor'     
            gMotorAndFan.add(meshMotor);

            this.group.add(gMotorAndFan);
        }
        
    }

    BuildFanBlades(gMotorAndFan, fanBladeLength){
        this.stainlessMaterial.side = THREE.DoubleSide;
        let fanBladePosZ = -7;
        let fanBladePoints = [
            new THREE.Vector3(Math.floor(fanBladeLength/4), fanBladeLength, 0), // top right
            new THREE.Vector3(1, 0, 0), // bottom right
            new THREE.Vector3(-1, 0, 0), // bottom left
            new THREE.Vector3(-Math.floor(fanBladeLength/4), fanBladeLength, 0) // top left
        ]
        
        let shTopRightFanBlade = new THREE.Shape().setFromPoints(fanBladePoints);
        let geoTopRightFanBlade = new THREE.ShapeGeometry(shTopRightFanBlade);
        geoTopRightFanBlade.name = "geoTopFanBlade";
        let meshTopRightFanBlade = new THREE.Mesh(geoTopRightFanBlade, this.stainlessMaterial);
        meshTopRightFanBlade.name = "meshTopFanBlade";
        meshTopRightFanBlade.position.z = fanBladePosZ;
        meshTopRightFanBlade.rotation.z = -Math.PI/4;
        gMotorAndFan.add(meshTopRightFanBlade);

        let shTopLeftFanBlade = new THREE.Shape().setFromPoints(fanBladePoints);
        let geoTopLeftFanBlade = new THREE.ShapeGeometry(shTopLeftFanBlade);
        geoTopLeftFanBlade.name = "geoBottomFanBlade";
        let meshTopLeftFanBlade = new THREE.Mesh(geoTopLeftFanBlade, this.stainlessMaterial);
        meshTopLeftFanBlade.name = "meshBottomFanBlade";
        meshTopLeftFanBlade.position.z = fanBladePosZ;
        meshTopLeftFanBlade.rotation.z = Math.PI/4;
        gMotorAndFan.add(meshTopLeftFanBlade);
        
        let shBottomLeftFanBlade = new THREE.Shape().setFromPoints(fanBladePoints);
        let geoBottomLeftFanBlade = new THREE.ShapeGeometry(shBottomLeftFanBlade);
        geoBottomLeftFanBlade.name = "geoBottomFanBlade";
        let meshBottomLeftFanBlade = new THREE.Mesh(geoBottomLeftFanBlade, this.stainlessMaterial);
        meshBottomLeftFanBlade.name = "meshBottomFanBlade";
        meshBottomLeftFanBlade.position.z = fanBladePosZ;
        meshBottomLeftFanBlade.rotation.z = 3*Math.PI/4;
        gMotorAndFan.add(meshBottomLeftFanBlade);

        let shBottomRightFanBlade = new THREE.Shape().setFromPoints(fanBladePoints);
        let geoBottomRightFanBlade = new THREE.ShapeGeometry(shBottomRightFanBlade);
        geoBottomRightFanBlade.name = "geoBottomFanBlade";
        let meshBottomRightFanBlade = new THREE.Mesh(geoBottomRightFanBlade, this.stainlessMaterial);
        meshBottomRightFanBlade.name = "meshBottomFanBlade";
        meshBottomRightFanBlade.position.z = fanBladePosZ;
        meshBottomRightFanBlade.rotation.z = -3*Math.PI/4;
        gMotorAndFan.add(meshBottomRightFanBlade);
        
    }

    BuildLouvers(gHousingAndLouvers){
        let louverLength = 4;
        let louverWidth = this.sideLength;
        let louverPosHeight = this.sideLength/2;
        let louverCount = louverWidth / louverLength;
        let louverRotation = -Math.PI/3;

        // build louvers from top of box downward 
        for(var i = 1; i <= louverCount; i++){
            var geoLouver = new THREE.PlaneBufferGeometry(louverWidth, louverLength, 1, 1);
            geoLouver.name = `geoLouver${i}`;
            var meshLouver = new THREE.Mesh(geoLouver, this.stainlessMaterial);
            meshLouver.name = `meshLouver${i}`;
            meshLouver.position.set(0, louverPosHeight + (Math.sin(louverRotation) * (louverLength/2)), louverLength/2)
            meshLouver.rotation.x = louverRotation;
            meshLouver.castShadow = false;
            gHousingAndLouvers.add(meshLouver);

            louverPosHeight -= louverLength;
        }
    }

    BuildLouverVentSide(width,length, posX, posY, posZ, rotX, rotY, rotZ){
        var geoSide = new THREE.BoxGeometry(width, length, 0.5);
        geoSide.name =  'geoLouverVentSide'
        let material =  materialHelper.getTrimMaterial({color: 0xBEBEBE});   
        let meshSide = new THREE.Mesh(geoSide, material);
        meshSide.name = 'meshLouverVentSide'
        meshSide.position.set(posX, posY, posZ);
        meshSide.rotation.set(rotX, rotY, rotZ);

        return meshSide;
    }
}