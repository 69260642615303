import _3dBase from './Base.js'
import * as THREE from 'three';
import {Vector2,  Vector3 } from 'three';
import { CORE } from '../_spec.js'
import materialHelper from '../helpers/materialHelper.js';
import layerHelper from '../helpers/layerHelper.js';

export default class DoorGlass extends _3dBase{

    constructor(lod, dim, stainlessMaterial, deadbolt){
        super(undefined, undefined, undefined, lod)
        this.extrusionSettings = {
            depth: 1.75, //used to be amount
            bevelEnabled: false,
            bevelThickness: 0.1,
            bevelSize: 1,
            bevelSegments: 1,
            material: 0,
            extrudeMaterial: 1
        };
        this.build(dim, stainlessMaterial, deadbolt)
    }

    build(dim, stainlessMaterial, deadbolt){

        if(this.isLowDetail())
            this.singleDoor_LD(dim, stainlessMaterial, deadbolt)
        else
            this.singleDoor_HD(dim, stainlessMaterial, deadbolt)    
    }

    singleDoor_LD(dim, stainlessMaterial, deadbolt){
        let halfDim = dim.clone().multiplyScalar(.5);
        let size = dim.clone();

        var geoGlass = new THREE.BoxGeometry(size.x - .05, size.y - .05, size.z);
        geoGlass.name = 'geoGlass'
        //let matGlass = materialHelper.getGlassMaterial_Ultra_HD();
        let matGlass = undefined; //materialHelper.getTintedGlassMaterial_LD();
        
        /*
        let meshDoor = new THREE.Mesh(geoGlass, matGlass);
        meshDoor.name = 'glass door'
        meshDoor.castShadow=true;
        //meshDoor.position.z=-2;
        this.group.add(meshDoor);
        meshDoor.layers.set(CORE.layers.doorsWalkin)
        */
       
        let trimBlack = this.doorBorder(dim);
        this.group.add(trimBlack);
        layerHelper.setGroup(trimBlack, CORE.layers.doorsWalkin, true);


        if(deadbolt===true){
            // deadbolt
            var geoLock = new THREE.CylinderGeometry( 3/3, 3/3, 3, 16 );
            let meshLock = new THREE.Mesh(geoLock, stainlessMaterial);
            meshLock.name = 'deadbolt'
            meshLock.rotation.x = Math.PI / 2;
            meshLock.position.set(halfDim.x - 2, -.2*halfDim.y, 0)            
            this.group.add(meshLock);
            meshLock.layers.set(CORE.layers.doorsWalkin);
        }
        
        return this.group;
    }

    singleDoor_HD(dim, stainlessMaterial, deadbolt){
        
        let halfDim = dim.clone().multiplyScalar(.5);
        let size = dim.clone();

        var geoGlass = new THREE.BoxGeometry(size.x - .05, size.y - .05, size.z);
        geoGlass.name = 'geoGlass'
        let matGlass = materialHelper.getTintedGlassMaterial_HD();
        
        let meshDoor = new THREE.Mesh(geoGlass, matGlass);
        meshDoor.name = 'glass door'
        meshDoor.castShadow=true;
        //meshDoor.position.z=-2;
        this.group.add(meshDoor);
        meshDoor.layers.set(CORE.layers.doorsWalkin)

        let trimBlack = this.doorBorder(dim);
        this.group.add(trimBlack);
        layerHelper.setGroup(trimBlack, CORE.layers.doorsWalkin, true);


        if(deadbolt===true){
            // deadbolt
            var geoLock = new THREE.CylinderGeometry( 3/3, 3/3, 3, 16 );
            let meshLock = new THREE.Mesh(geoLock, stainlessMaterial);
            meshLock.name = 'deadbolt'
            meshLock.rotation.x = Math.PI / 2;
            meshLock.position.set(halfDim.x - 2, -.2*halfDim.y, 0)
            
            this.group.add(meshLock);
            meshLock.layers.set(CORE.layers.doorsWalkin);
        }
        
        let handlePosY = -5;
        // handle shank
        var geoHandleShank = new THREE.CylinderGeometry( 2/3, 2/3, 7, 10 );
        let meshHandleShank = new THREE.Mesh(geoHandleShank, stainlessMaterial);
        meshHandleShank.name = 'glassdoor handle shank'
        meshHandleShank.rotation.x = Math.PI / 2;
        meshHandleShank.position.set(halfDim.x * .9, handlePosY, 0)
        
        // handles
        let handleWidth =  4;
        var geoHandle = new THREE.BoxGeometry( handleWidth, 1, 1);
        geoHandle = new THREE.CylinderGeometry(.65,.65,handleWidth,16,1,false)
        // outside handle
        let meshHandleOut = new THREE.Mesh(geoHandle, stainlessMaterial);
        meshHandleOut.name = 'glassdoor outside handle'
        meshHandleOut.rotation.z = Math.PI / 2;
        meshHandleOut.position.set((halfDim.x * .9)-handleWidth/2, handlePosY, 3)
        
        // inside handle
        let meshHandleIn = new THREE.Mesh(geoHandle, stainlessMaterial);
        meshHandleIn.name = 'glassdoor inside handle'
        meshHandleIn.rotation.z = Math.PI / 2;
        meshHandleIn.position.set((halfDim.x * .9)-handleWidth/2, handlePosY, -3)
        
        this.group.add(meshHandleShank);
        this.group.add(meshHandleOut);
        this.group.add(meshHandleIn);

        meshHandleShank.layers.set(CORE.layers.doorsWalkin)
        meshHandleOut.layers.set(CORE.layers.doorsWalkin)
        meshHandleIn.layers.set(CORE.layers.doorsWalkin)

        
        return this.group;
    }


    doorBorder(dimSize) {
        let gPerimeter = new THREE.Group();
        
        let halfDimSize = dimSize.clone().multiplyScalar(.5);

        let dimMaterial = new THREE.Vector3(4, 4, 1);
        let halfDimMaterial = dimMaterial.clone().multiplyScalar(.5);
        
        let matTrimBlack = materialHelper.getTrimMaterial("0x111111");

        // Horizontal sections
        let geoRail = new THREE.BoxGeometry(dimSize.x, dimMaterial.y, dimMaterial.z);
        geoRail.position = new THREE.Vector3(0, halfDimSize.y, 0);
        let mRailTop = new THREE.Mesh(geoRail, matTrimBlack)
        mRailTop.name = 'trimOpeningFace top'
        mRailTop.position.set(0, halfDimSize.y - halfDimMaterial.y, 0);
        gPerimeter.add(mRailTop);

        let mRailBottom = new THREE.Mesh(geoRail, matTrimBlack)
        mRailBottom.name = 'trimOpeningFace bottom'
        mRailBottom.position.set(0, -halfDimSize.y + halfDimMaterial.y, 0)            
        gPerimeter.add(mRailBottom);


        //Vertical sections  
        let geoStile = new THREE.BoxGeometry(dimMaterial.x, dimSize.y-2*dimMaterial.y, dimMaterial.z);
        let mStileLeft = new THREE.Mesh(geoStile, matTrimBlack)
        mStileLeft.name = 'trimOpeningFace left'
        mStileLeft.position.set(-halfDimSize.x + halfDimMaterial.x, 0, 0)
        let mStileRight = new THREE.Mesh(geoStile, matTrimBlack)
        mStileRight.name = 'trimOpeningFace right'
        mStileRight.position.set(halfDimSize.x - halfDimMaterial.x, 0, 0)                
        gPerimeter.add(mStileLeft);
        gPerimeter.add(mStileRight);        
        layerHelper.setGroup(gPerimeter, CORE.layers.walls, true);        
        //layerHelper.enableLayer(gPerimeter, CORE.layers.quote);
        return gPerimeter;
    }
}