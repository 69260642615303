import _3dBase from './Base.js'
import * as THREE from 'three';
import {Vector2,  Vector3 } from 'three';
import MemHelper from '../helpers/memHelper.js';
import ShapeHelper from '../helpers/shapeHelper.js'
import layerHelper from '../helpers/layerHelper.js';
import { CORE } from '../_spec.js'
import _3dGirtHoriC from './GirtHoriC.js'
import _3dTrimOpeningFace from './TrimOpeningFace.js';
import _3dTrimOpeningJamb from './TrimOpeningJamb.js';
import materialHelper from '../helpers/materialHelper.js';

export default class DoorRollup extends _3dBase{

    constructor(lod, width, height, trimMaterial, girtColor, excludeOh, ohJambLiner){
        super(undefined, undefined, undefined, lod)

        this.build(width, height, trimMaterial, girtColor, excludeOh, ohJambLiner)
    }

    build(width, height, trimMaterial, girtColor, excludeOh, ohJambLiner){

        if(this.isLowDetail())
            this.rollup_LD(width, height, trimMaterial, girtColor, excludeOh, ohJambLiner)
        else
            this.rollup_HD(width, height, trimMaterial, girtColor, excludeOh, ohJambLiner)    
    }

    rollup_LD(width, height, trimMaterial, girtColor, excludeOh, ohJambLiner) {
        // use the design to build the component
       
        let jambTrimThickness = .5; 
        let jambTrimWidth = 8;

        this.texture = CORE.textures.doorRollup.clone();
        //this.texture.wrapS = THREE.MirroredRepeatWrapping;
        this.texture.wrapS = THREE.RepeatWrapping;
        this.texture.wrapT = THREE.RepeatWrapping
        this.texture.encoding = THREE.sRGBEncoding;
        this.texture.anisotropy = 16;
        this.texture.repeat.set(1, height/21.6*2);
        this.matDoor = materialHelper.getdoorRollupDoorMaterial(0xFFFFFF,this.texture);

        let textureRoll = CORE.textures.doorRollup.clone();
        //this.texture.wrapS = THREE.MirroredRepeatWrapping;
        textureRoll.wrapS = THREE.RepeatWrapping;
        textureRoll.wrapT = THREE.RepeatWrapping
        textureRoll.encoding = THREE.sRGBEncoding;
        textureRoll.anisotropy = 16;
        textureRoll.repeat.set(1, height/21.6);
        let matRoll = materialHelper.getdoorRollupDoorMaterial(0xFFFFFF,textureRoll);
        matRoll.map.rotation = Math.PI/2;
        //matRoll.metalness = .7;
        //matRoll.roughness = .5;
        matRoll.emissiveIntensity = 0.15; // lowering the intensity for the roll a little bit
        

        let dim = new THREE.Vector3(width, height, 1);
        let halfDim = dim.clone().multiplyScalar(.5);
        
    
        var geoDoor = new THREE.BoxGeometry(width, height, 1);
        
        if(excludeOh!==true){
            let meshDoor = new THREE.Mesh(geoDoor, this.matDoor);
            meshDoor.name = 'rollup panel'
            this.group.add(meshDoor);
            //meshDoor.userData = this.design;
            meshDoor.position.set(0,0,-jambTrimWidth - .05);//-halfFrameDepth);

            let radius = height/20;
            var geoRoll = new THREE.CylinderGeometry(radius, radius, width, 12, 1)
            let meshRoll  = new THREE.Mesh(geoRoll, matRoll)
            meshRoll.rotateZ(Math.PI/2);
            meshRoll.position.set(0, height/2, -jambTrimWidth-.05 -radius+1);

            meshRoll.name = 'rollup roll'
            this.group.add(meshRoll);
        }


        // trim        
        let dimTrimMaterial = new THREE.Vector3(2,2,1.5);
        let trim = new _3dTrimOpeningFace(this.lod, dim, dimTrimMaterial, trimMaterial, false);        
        layerHelper.enableLayer(trim.group, CORE.layers.quote);

        let jamb
        if (ohJambLiner)
        {
            jamb = new _3dTrimOpeningJamb(this.lod, dim, jambTrimThickness, jambTrimWidth, trimMaterial);
            layerHelper.enableLayer(jamb.group, CORE.layers.quote);
        }

        /// FRAMING 
        let gFrame = new THREE.Group();
        gFrame.name = 'rollup door frame'
        this.group.add(gFrame)
        let frameInset = 1;
        let header = new _3dGirtHoriC(this.lod, new THREE.Vector3(0, 0, 0), new THREE.Vector3(width, 0, 0), CORE.dir.rel.up, false, girtColor);
        header.build();
        gFrame.add(header.group);
        
        header.group.position.set(0,height/2,-header.dim.web/2-frameInset);

        layerHelper.setGroup(this.group, CORE.layers.doorsOverhead);
        layerHelper.setGroup(gFrame, CORE.layers.frame);
        this.group.add(trim.group);
        if(jamb)
            this.group.add(jamb.group);        
        
    }


    rollup_HD(width, height, trimMaterial, girtColor, excludeOh, ohJambLiner) {
        // use the design to build the component
       
        let jambTrimThickness = .5; 
        let jambTrimWidth = 8;

        this.texture = CORE.textures.doorRollup.clone();
        //this.texture.wrapS = THREE.MirroredRepeatWrapping;
        this.texture.wrapS = THREE.RepeatWrapping;
        this.texture.wrapT = THREE.RepeatWrapping
        this.texture.encoding = THREE.sRGBEncoding;
        this.texture.anisotropy = 16;
        this.texture.repeat.set(1, height/21.6*2);
        this.matDoor = materialHelper.getdoorRollupDoorMaterial(0xFFFFFF,this.texture);

        let textureRoll = CORE.textures.doorRollup.clone();
        //this.texture.wrapS = THREE.MirroredRepeatWrapping;
        textureRoll.wrapS = THREE.RepeatWrapping;
        textureRoll.wrapT = THREE.RepeatWrapping
        textureRoll.encoding = THREE.sRGBEncoding;
        textureRoll.anisotropy = 16;
        textureRoll.repeat.set(1, height/21.6);
        //let matRoll = materialHelper.getdoorRollupDoorMaterial(0xFFFFFF,textureRoll);
        let matRoll =  new THREE.MeshStandardMaterial({
            color: Number(0xFFFFFF), 
            side: THREE.DoubleSide,
            roughness: .4,
            metalness: .7,
            //emissive: nColor,
            emissive: Number(0xFFFFFF),
            emissiveIntensity: .05,
            
            envMap: CORE.envMap,
            envMapIntensity: 1
        });
        matRoll.map = textureRoll;
        matRoll.map.rotation = Math.PI/2;
        //matRoll.metalness = .7;
        //matRoll.roughness = .5;

        //matRoll.emissive = Number(0xFFFFFF);
        //matRoll.emissiveIntensity = 0.10; // lowering the intensity for the roll a little bit
        

        let dim = new THREE.Vector3(width, height, 1);
        let halfDim = dim.clone().multiplyScalar(.5);
        
    
        var geoDoor = new THREE.BoxGeometry(width, height, 1);
        
        if(excludeOh!==true){
            let meshDoor = new THREE.Mesh(geoDoor, this.matDoor);
            meshDoor.name = 'rollup panel'
            this.group.add(meshDoor);
            //meshDoor.userData = this.design;
            meshDoor.position.set(0,0,-jambTrimWidth - .05);//-halfFrameDepth);
            
            let groupRoll = new THREE.Group();

            let radius = height/20;
            var geoRoll = new THREE.CylinderGeometry(radius, radius, width-1, 12, 1)
            let meshRoll  = new THREE.Mesh(geoRoll, matRoll)
            meshRoll.rotateZ(Math.PI/2);
            //meshRoll.position.set(0, height/2, -jambTrimWidth-.05 -radius+1);

            meshRoll.name = 'rollup roll'
            groupRoll.add(meshRoll);

            // left metal roll cap
            let geoCap = new THREE.CylinderGeometry(radius-.5, radius-.5, 0.75, 12, 1)
            let matCapL = new THREE.MeshStandardMaterial({
                color: Number(0x666666), 
                side: THREE.DoubleSide, 
                envMap: CORE.envMap,
                envMapIntensity: 1,
                metalness: .8,
                roughness: .4,            
                emissive: Number(0x000000),
                emissiveIntensity: 1,
            });
            let meshCapL = new THREE.Mesh(geoCap, matCapL);
            meshCapL.rotateZ(Math.PI/2)
            meshCapL.position.x = -width/2+.25;
            groupRoll.add(meshCapL);
            
            // right metal roll cap 
            let matCapR = new THREE.MeshStandardMaterial({
                color: Number(0x666666), 
                side: THREE.DoubleSide, 
                envMap: CORE.envMap,
                envMapIntensity: 1,
                metalness: .7,
                roughness: .4,            
                emissive: Number(0x000000),
                emissiveIntensity: 1,
            });
            let meshCapR = new THREE.Mesh(geoCap, matCapR);
            meshCapR.rotateZ(-Math.PI/2)
            meshCapR.position.x = width/2-.25;
            groupRoll.add(meshCapR);

            let geoRollbar = new THREE.BoxGeometry(radius*3/2, 2, .5);
            let matRollbarL = materialHelper.getTrimMaterial(0x999999);
            let meshRollbarL = new THREE.Mesh(geoRollbar, matRollbarL);
            meshRollbarL.rotateY(Math.PI/2);
            meshRollbarL.position.set(-width/2-.5, 0, radius/2-1);
            groupRoll.add(meshRollbarL);

            let matRollbarR = materialHelper.getTrimMaterial(0x999999);
            let meshRollbarR = new THREE.Mesh(geoRollbar, matRollbarR);
            meshRollbarR.rotateY(Math.PI/2);
            meshRollbarR.position.set(width/2+.5, 0, radius/2-1);
            groupRoll.add(meshRollbarR);
        
            groupRoll.position.set(0, height/2, -jambTrimWidth-.05 -radius);
            this.group.add(groupRoll);
        }

        


        // trim        
        let dimTrimMaterial = new THREE.Vector3(2,2,1.5);
        let trim = new _3dTrimOpeningFace(this.lod, dim, dimTrimMaterial, trimMaterial, false);        
        layerHelper.enableLayer(trim.group, CORE.layers.quote);

        let jamb
        if (ohJambLiner)
        {
            jamb = new _3dTrimOpeningJamb(this.lod, dim, jambTrimThickness, jambTrimWidth, trimMaterial);
            layerHelper.enableLayer(jamb.group, CORE.layers.quote);
        }

        /// FRAMING 
        let gFrame = new THREE.Group();
        gFrame.name = 'rollup door frame'
        this.group.add(gFrame)
        let frameInset = 1;
        let header = new _3dGirtHoriC(this.lod, new THREE.Vector3(0, 0, 0), new THREE.Vector3(width, 0, 0), CORE.dir.rel.up, false, girtColor);
        header.build();
        gFrame.add(header.group);
        header.group.position.set(0,height/2,-header.dim.web/2-frameInset);

        layerHelper.setGroup(this.group, CORE.layers.doorsOverhead);
        layerHelper.setGroup(gFrame, CORE.layers.frame);
        this.group.add(trim.group);        
        if(jamb)
            this.group.add(jamb.group);
    }
}
