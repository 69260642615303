
import * as THREE from 'three';
import {impactTypes, CORE} from '../_spec.js'
import Base from './Base'
import OptionHelper from '../helpers/optionHelper.js'
import Util from '../utility.js'
import UpdateHelper from '../helpers/UpdateHelper.js';
import EarcutDataManager from '../helpers/EarcutDataManager.js';
import Sheeting from '../3d/Sheeting.js';
import SheetingHelper from '../helpers/SheetingHelper.js';
import layerHelper from '../helpers/layerHelper.js';
import MemHelper from '../helpers/memHelper.js';

export default class RoofBase extends Base{
    constructor(design){
        super(design);

        // create list of purline from/to vertices
        this.purlins = [];
        this.eaves = [];
        this.panels = [];
        this.trimRakes = [];
        this.trimEaves = [];
    }    

    addOutlineMeshesToGroup(vertexList, group) {
        let outline = new EarcutDataManager();
        outline.setOutline(vertexList);
        let outlineData = outline.generate();
        let outlineSheeting = new Sheeting(
            CORE.preferences.des_levelOfDetail.value,
            outlineData,
            SheetingHelper.definePlane(CORE.materials.transparent.clone(), THREE.DoubleSide),
            undefined,
            CORE.layers.walls);

        let meshes = outlineSheeting.group.children[0].children[0].children;
        meshes.forEach((c) => {
            c.visible = true;
            c.userData = {
                type: this.design.type,
                id: this.design.id
            };
            //c.material = CORE.materials.debug;
            this.outlineMeshes.push(c);
        });
        group.add(outlineSheeting.group);
        layerHelper.setGroup(outlineSheeting.group, CORE.layers.world)
    }

    getFrontTieIns(){
        return this.tieIns.filter(t=> t.side == CORE.sides.frontSide)
    }
    getBackTieIns(){
        return this.tieIns.filter(t=> t.side == CORE.sides.backSide)
    }
    // initRebuildHierarchy(){        
    //     this.rebuildTypeRoot = new TreeNode(null, rebuildTypes.full);        
    //     this.rebuildTypeRoot.addChildNode(rebuildTypes.trim);
    // }


    defaultDesign(){
        return {
            type: CORE.components.roof,
            peakSpace: 12
        }
    }

    migrate(design){

        if(!design.components)
            design.components=[]
        
        // 2021.04.07 added for the sake of new web quoting feature.
        if(!Util.isDefined(design.peakSpace))
            design.peakSpace = 12;
        if(Util.isUndefined(design.roofFrameType)){
            if(design.isPorch)
                design.roofFrameType='flush';
            else
                design.roofFrameType='bypass';
        }
    }

    static canClick(){
        return true; // you click roofs to select structures
    }

    static canEdit(){
        // this is typically used to uneditable components, like Frame, sideFrame
        // Roof is editable, but it's options are bound up in the Structure now
        // so while the roof can be adjusted, the roof component has no options
        // and therefore cannot, itself, be edited (nor need it be shown, as a result)
        return false;}
    static canDelete(){return false;}
    static canCopy(){return false;}

    
    getOutlineMeshes(){
        return this.outlineMeshes;
    }

    getDescription(){
        return 'Roof'
    }

    getCollisionClass(){
        return [CORE.collisions.classes.none];
    }

    getOptionsSpec(){
        return [];        
    }

    getTypeDisplayName(){
        return `Roof`;
    }

    remove(){
        MemHelper.removeAllChildren(this.group);
    }

    // I started adding this for Gutters option before I realized it doesn't calculate it's own gutter trim due to porch stuff
    // detectImpact(impact){

    //     if(UpdateHelper.shouldProcessOwnImpact(impact, this.design.id))
    //         this.processImpactFromSelf(impact);

    //     switch(impact.change.name){
    //         case impactTypes.optionsGutter:
    //         default:
    //             this.addRebuildNeeded(rebuildTypes.full)
    //             break;
    //     }
    // }

    // processRebuild(type){
    //     switch(type){                    
    //         default:
    //             this.rebuildFull();
    //             break;
    //     }
    // }

    // rebuildFull(){        
    //     this.remove();

    //     this.build();

    // }


    static calcEaveOverhangPoint(pRef, pEave, overhang){
        let length = pEave.distanceTo(pRef);
        let extToLengthRatio = (overhang)/length
        return pRef.clone().lerp(pEave, 1 + extToLengthRatio)
    }
}