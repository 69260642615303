import * as THREE from 'three'
import _3dBase from './Base.js'
import {CORE} from '../_spec.js'
import MemHelper from '../helpers/memHelper.js';
import FoldedGeometryHelper from '../helpers/foldedGeometryHelper.js';
import layerHelper from '../helpers/layerHelper.js';

export default class TrimEaveGutter  extends _3dBase {
//reference material:
//https://www.jagmetalsllc.com/wp-content/uploads/2018/04/rake-trim_catalogfinal.png

    constructor(length, material, 
        leftVertAngle, 
        rightVertAngle, 
        leftHoriAngle, 
        rightHoriAngle
        ){
        super();
        
        let folds = [
            {mdist:-2},
            {deg:-45}, 
            {mdist:1}, // back upper diag
            {deg:45}, // 
            {dist:4}, // bottom 
            {deg:60}, 
            {dist:4}, // front lower diag           
            {deg:60}, 
            {dist:4}, // front upper diag                      
            {deg:60},
            {dist:2}, // top 
            {deg:90},
            {dist:6.35}, // back vertical
            {deg:-90},
            {dist:1}, // back horizontal
        ];

        let fgh = new FoldedGeometryHelper(folds, material, true,true, false, length, leftVertAngle, rightVertAngle, leftHoriAngle, rightHoriAngle)
        layerHelper.setGroup(fgh.group, CORE.layers.walls);
        this.group.add(fgh.group);
        this.group.name = 'eave gutter trim'
    }
    

    
}