export default class UpdateHelper{




    static createImpact(design, impactType, change){
        let impact = {
            id: design.id,
            design: design,
            change,
            handled:[]
            };

        if(!change)
            impact.change = {
                name: impactType
            };
        return impact;
    }

    


}