<template>
    <div class="body">
      <div class="header"><slot></slot></div>      
      <div class="detail">
        <slot name="detail1"></slot>
      </div>
      <div class="detail">
        <slot name="detail2"></slot>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NavCard',
  };
  </script>
  
<style scoped>
  .body{
    color:black;
    font-weight: bold;
    font-size: 25px;
    border-radius: 4px;
    border: 1px solid black;
    /* background: lightgray; */
    padding: 20px;
    margin: 20px;
    
    display:inline-block;
    width:200px;
  }

  .detail{
    font-weight: normal;
    margin: 10px;
    font-size: .8em;
  }
</style>