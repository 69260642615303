import * as THREE from 'three';
import {CORE} from '../_spec.js'
import MemHelper from '../helpers/memHelper.js';
import _3dBase from './Base.js'
import materialHelper from '../helpers/materialHelper.js';

export default class _3dPurlinC extends _3dBase{
    constructor(lod, length, pos, rot, color){
        super(undefined,undefined,undefined,lod);
        this.group.name = "C-purlin";        
        this.length = length;
        this.color = color ? color : CORE.frame.redOxideColor;
        this.build()
    }


    build_LD(height){
        //height+=2*CORE.roof.purlin.dim.thickness;
        let halfHeight = height/2;

        this.material = materialHelper.getFrameMaterial_LD(this.color);
        var geoFlange = new THREE.PlaneBufferGeometry(this.length, 3.5, 1, 1)
        var mTop = new THREE.Mesh(geoFlange, this.material);
        mTop.position.y=halfHeight;
        mTop.rotation.x = Math.PI/2;

        var geoWeb = new THREE.PlaneBufferGeometry(this.length, height, 1, 1)
        var mMid = new THREE.Mesh( geoWeb, this.material );
        mMid.position.z=1.75;
        let mBot = new THREE.Mesh( geoFlange, this.material );
        mBot.position.y=-halfHeight;
        mBot.rotation.x = Math.PI/2;
        this.group.add(			
            mTop,
            mMid,
            mBot
        );
        mBot.castShadow=false;
		mTop.castShadow=false;
        mMid.castShadow=false;
        mBot.layers.set(CORE.layers.frame)
        mTop.layers.set(CORE.layers.frame)
        mMid.layers.set(CORE.layers.frame)
    }

    build_HD(height, texture){
        let thickness = CORE.roof.purlin.dim.thickness;
        let halfThickness = thickness/2;
        let halfHeight = height/2;
        this.material = materialHelper.getFrameMaterial_HD(this.color, texture);

        //TODO: make a variable for 3.5 and 3.5/2
        var gFlange = new THREE.BoxGeometry(this.length, CORE.roof.purlin.dim.thickness, 3.5);
        gFlange.name = 'purlinC flange'
        var mTop = new THREE.Mesh( gFlange, this.material);
        mTop.position.y=halfHeight-halfThickness;
		var gMid = new THREE.BoxGeometry(this.length, height, CORE.roof.purlin.dim.thickness);
        gMid.name="purlinC web"
        var mMid = new THREE.Mesh( gMid, this.material );

            mMid.position.z=1.75;
        
        var mBot = new THREE.Mesh( gFlange, this.material );
        mBot.position.y=-halfHeight + halfThickness;
        let origin = new THREE.Mesh(new THREE.SphereGeometry(.5,2,2), materialHelper.getPlasticMaterial_HD(0xffffff));
            
		this.group.add(			
			mTop,
            mMid,
            mBot,
            //origin,
        );
        mBot.castShadow=true;
		mTop.castShadow=true;
        mMid.castShadow=true;
        mBot.layers.set(CORE.layers.frame)
        mTop.layers.set(CORE.layers.frame)
        mMid.layers.set(CORE.layers.frame)
        
    }


    build(pos, rot){

        let height =8;
        let halfHeight = height/2;
        let thickness = CORE.roof.purlin.dim.thickness;
        let halfThickness = thickness/2;
        

        // Create a custom texture for this object
        this.texture = CORE.textures.beam.clone();
        this.texture.wrapT = this.texture.wrapS = THREE.MirroredRepeatWrapping;
        //this.texture.wrapT = THREE.ClampToEdgeWrapping
        this.texture.encoding = THREE.sRGBEncoding;
        //this.texture.anisotropy = 32;
        let texScale = .333 // 720 inch long purlin /3 => 240, which is the length of 1 gabled rafter of a 40' wide barn @ repeat 1,1
        this.texture.repeat.set(this.length/texScale, 3.5/texScale);

        if(this.isLowDetail())
            this.build_LD(height)
        else 
            this.build_HD(height , this.texture)

        //const cur = this.group.quaternion
        
        //cur.multiplyQuaternions(rot,cur);
        
        //this.group.position.copy(pos)

		
    }
}
