<template>
  <div id='contextMenu' :style="style" class="contextWrapper">
    <componentOptions style='display:inline-block;position:absolute;min-width:200px;'
    ref="options" /> 
  </div>
  
</template>

<script>
import componentOptions from './componentOptions.vue'

export default {
  props: {
    context: Object,
  },
  components:{
    componentOptions
  },
  computed:{
      options:{
        get: function(){
          return this.context.options;
        }
      },
      dataOptions:{
        get: function(){
          return this.context.options.filter((o)=>{return o.type!=='button'});
        }
      },
      actionOptions:{
        get: function(){
          return this.context.options.filter((o)=>{return o.type==='button'});
        }
      },
      left:{
        get: function(){
          return this.context.position.x;
        }
      },
      top:{
        get: function(){
          return this.context.position.y;
        }
      },
      style:{
        get: function(){
            let st = `left:${this.context.position.x}px;top:${this.context.position.y}px`;
          return st;
        }
      }
  },
  data: ()=>{
      return {
        
      };
  },
  mounted: function(){
      let e = document.getElementById('contextMenu');
      let pos = this.context.position
      e.style.left = `${this.left}px`;
      e.style.top = `${pos.y}px`;
  },
  methods: {   
    change: function(){

      this.$emit('change', 
        {
          componentId: this.context.componentId,
          options: this.dataOptions
        }
      )
    } ,
    action: function(key){
      switch(key){
        case 'delete':
          this.$emit('delete', this.context.componentId);
          break;
        case 'copy':
          this.$emit('copy', this.context.componentId);
          break;
      }
    }    
  }
}
</script> 

<style scoped>


.option{
  display:block;
}
input{
  width: 40px
}

div.contextWrapper{
  display: inline-block;    
    text-align: center;
    /* z-index: 10001; */
    background: lightgray;
    border-radius: 4px;
    border: 1px solid black;
    padding:4px;    
    opacity: .85;
    position:absolute;
}


</style>


