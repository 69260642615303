import EarcutHelper from "./EarcutHelper";

export default class EarcutDataManager{

    constructor(dimensions = 2){
        this.vertices=[];
        this.holes=[];
        this.dimensions = dimensions;
    }

    setOutline(verts){
        this.vertices = verts;
    }

    addSquareHole(x,y,width,height){
        this.holes.push({type:'quad',x,y,width,height});
    }    

    addTriangularHole(p1,p2,p3){
        this.holes.push({type:'tri',p1,p2,p3})
    }

    addHoleFromVertices(verts){
        this.holes.push({type: 'dyn', verts: verts})
    }

    generate(){
        let vertexList = Array.from(this.vertices);

        let holeIndices = [];

        this.holes.forEach((h)=>{
            holeIndices.push(vertexList.length/this.dimensions);
            switch(h.type){
                case 'quad':
                    vertexList.push(...EarcutHelper.createSquareHole(h.x,h.y,h.width, h.height, this.dimensions));
                    break;
                case 'tri':
                    vertexList.push(...EarcutHelper.createTriHole(h.p1,h.p2,h.p3,this.dimensions));
                    break;
                case 'dyn':
                    vertexList.push(...EarcutHelper.createDynamicHole(h.verts,this.dimensions))
            }
        });

        return {vertexList,holeIndices, dimensions:this.dimensions};
    }
}