import api from '@/api';
import {CORE} from '../../constants.js'

const state = {
  loaded:false, // definitive, top-level readiness of context state
  user:null,
  userBusiness:null,

  current: {
    mode: null,
    supplier: null,
    contractor:null,    
  },
  contractorParentData: {},
  refreshKey:0
};

const getters  = {
  currentBusiness: state => {
    if(state.current.contractor)
        return state.current.contractor;
    if(state.current.supplier)
        return state.current.supplier;
    return null;      
  },
  userIsSysAdmin: state => {
    return state.user.sRoles.includes('SysAdmin')
  },
  userIsCompAdmin: state => {
    return state.user.sRoles.includes('CompAdmin')
  },
  userIsContAdmin: state => {
    return state.user.sRoles.includes('ContAdmin')
  },
}

const mutations = {
  refresh(state){
    state.refreshKey++;
  },
  setUser(state, user){    
    state.user = user;
    // cache the user in browser session storage
    sessionStorage.setItem('user', JSON.stringify(user));
  },
  setuserBusiness(state, userBusiness){
    state.userBusiness = userBusiness;
    // cache the user's Entity in browser session storage
    sessionStorage.setItem('userBusiness', JSON.stringify(userBusiness));
  },
  setMode(state, m){
    state.current.mode = m;
    // leave a way to recall in the event of a hard refresh
    localStorage.setItem('mode', m)
  },
  setContractorParentData(state, data){
    state.contractorParentData = data
  },
  setCurrentSupplier(state, entity){
    state.current.supplier = entity;    
    localStorage.setItem('supplier', JSON.stringify(entity))
    console.log("set supplier", entity);
  },
  setCurrentContractor(state, entity){
    state.current.contractor = entity;    
    localStorage.setItem('contractor', JSON.stringify(entity))
    console.log("set contractor", entity);
  },
  clearCurrentSupplier(state){
    state.current.supplier = null;
    localStorage.setItem('supplier', null)
  },
  clearCurrentContractor(state){
    state.current.contractor = null;
    localStorage.setItem('contractor', null)
  }

};

const actions = {  
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};