
import {CORE} from '../_spec.js'
import cWindow from '../o/Window';
import LouverVent from '../o/LouverVent.js';
import DoorWalkin3o7o from '../o/DoorWalkin3o7o.js';
import DoorWalkin4o7o from '../o/DoorWalkin4o7o.js';
import DoorWalkin6o7o from '../o/DoorWalkin6o7o.js';
import DoorSliding from '../o/DoorSliding.js';
import Cupola from '../o/Cupola.js';
import FSW from '../o/Wall_FSW.js';
import BayEnd from '../o/BayEnd.js';
import BaySide from '../o/BaySide.js';
import Null from '../o/Null.js'
import cStructureMain from '../o/StructureMain.js'
import FrameBase from '../o/framebase.js'
import Foundation from '../o/foundationbase.js'
import RoofBase from '../o/RoofBase.js'
import Appearance from '../o/appearance.js'
import Options from '../o/options.js'
import EndSkirt from '../o/endSkirt.js'
import WideOpening from '../o/WideOpening.js';
import JobSpecs from '../o/JobSpecs.js';
import model from '../o/model.js';
import StructurePorch from '../o/StructurePorch.js';
import DoorRollup from '../o/DoorRollup.js';
import DoorCommGlass from '../o/DoorCommGlass.js';
import DoorHangar from '../o/DoorHangar.js';
import DoorSectional from '../o/DoorSectional.js';
import System from '../o/System.js';
import BSW from '../o/Wall_BSW.js';
import LEW from '../o/Wall_LEW.js';
import REW from '../o/Wall_REW.js';
import BayBase from '../o/BayBase.js';
import BayEndLeft from '../o/BayEndLeft.js';
import BayEndRight from '../o/BayEndRight.js';
import BaySideFront from '../o/BaySideFront.js';
import BaySideBack from '../o/BaySideBack.js';
import DesignHelper from './DesignHelper.js';
import TransversePartition from '../o/Wall_TransversePartition.js';
import StructureLeanTo from '../o/StructureLeanTo';
import EmptyFramedOpening from '../o/EmptyFramedOpening.js';
//import Bay from '../o/Bay.js';

export default class ComponentHelper{
    

    static isDiagonalGrabject(type){
        return (type === CORE.grabjects.topLeft ||
        type === CORE.grabjects.topRight ||
        type === CORE.grabjects.bottomRight ||
        type === CORE.grabjects.bottomLeft);
    }

    static isOrthogonalGrabject(type){
        return (type === CORE.grabjects.top ||
        type === CORE.grabjects.right ||
        type === CORE.grabjects.bottom ||
        type === CORE.grabjects.left);
    }

    static isPositionGrabject(type){
        return (type === CORE.grabjects.position)
    }

    static createComponentOfType(type){
        let componentClass = this.getClassOfComponentType(type);
        if(!componentClass)
            return null;
        return new componentClass();        
    }

    static getComponentOfType(type){
        let componentClass = this.getClassOfComponentType(type);
        if(!componentClass)
            return null;
        return componentClass;
    }

    static getClassOfComponentType(type){
        switch(type){
            case CORE.components.fsw:
                return FSW;
            case CORE.components.bsw:
                return BSW;
            case CORE.components.lew:
                return LEW;            
            case CORE.components.rew:
                return REW;            
            case CORE.components.bayEndLeft:
                return BaySide;
            case CORE.components.bayEndRight:
                return BaySide;
            case CORE.components.baySideFront:
                return BaySide;
            case CORE.components.baySideBack:
                return BaySide;
            case CORE.components.tvp:
                return TransversePartition;
            case CORE.components.window:
                return cWindow;     
            case CORE.components.louverVent:
                return LouverVent;           
            case CORE.components.doorWalkin3o7o:
                return DoorWalkin3o7o;
            case CORE.components.doorWalkin4o7o:
                return DoorWalkin4o7o;
            case CORE.components.doorWalkin6o7o:
                return DoorWalkin6o7o;
            case CORE.components.doorCommGlass:
                return DoorCommGlass;
            case CORE.components.porch:
                return StructurePorch;  
            case CORE.components.leanTo:
                return StructureLeanTo;      
            case CORE.components.doorRollup:
                return DoorRollup;
            case CORE.components.doorSliding:
                return DoorSliding;
            case CORE.components.doorHangar:
                return DoorHangar;
            case CORE.components.doorOverhead:
            case CORE.components.doorSectional:
                return DoorSectional;
            case CORE.components.cupola:
                return Cupola;
            case CORE.components.null:
                return Null;
            case CORE.components.structure:
                return cStructureMain;
            case CORE.components.frame:
                return FrameBase;
            case CORE.components.foundation:
                return Foundation;
            //case CORE.components.frameSide:
                //return FrameSide;
            case CORE.components.roof:
                return RoofBase;
            case CORE.components.skirtLeft:
                return EndSkirt;
            case CORE.components.skirtRight:
                return EndSkirt;
            case CORE.components.appearance:
                return Appearance;
            case CORE.components.options:
                return Options;
            case CORE.components.jobspecs:
                return JobSpecs;
            case CORE.components.wideOpening:
                return WideOpening;
            case CORE.components.emptyFramedOpening:
                return EmptyFramedOpening;
            case CORE.components.model:
                return model
            case CORE.components.system:
                return System;
            /*case CORE.components.bay:
                return BayBase;*/
            }
        return null;
    }

    static ComponentTypeIsEditable(type){        
        let f = this.getClassOfComponentType(type)
        if(!f)
            return false;
        
        return f.canEdit();
    }

    
    static ComponentTypeIsClickable(type){        
        let f = this.getClassOfComponentType(type)
        if(!f)
            return false;
        
        return f.canClick();
    }


    static createComponentWithDesign(objDes){
        var t = this.getComponentOfType(objDes.type);
        let comp = new t(objDes);
        //var o = this.createComponentOfType(objDes.type);
        if(comp.hasOwnProperty('init'))
            comp.init(objDes)

        return comp;
    }

    static objectTypeIsWall(type){
        return type == CORE.components.fsw || 
            type == CORE.components.bsw || 
            type == CORE.components.lew ||
            type == CORE.components.rew
    }

    static componentIsWallOpening(type){
        let doorsAndWindows = [
            CORE.components.window,
            CORE.components.emptyFramedOpening,
            CORE.components.louverVent,
            CORE.components.doorRollup,
            CORE.components.doorWalkin3o7o,
            CORE.components.doorWalkin4o7o,
            CORE.components.doorWalkin6o7o,
            CORE.components.doorHangar,
            CORE.components.doorCommGlass,
            CORE.components.doorSectional,
            CORE.components.doorSliding,
            CORE.components.doorRollup,
            
          ]

          return doorsAndWindows.includes(type);
          
    }

    static componentIsStructureSpecifications(type){
        let specifications = [
            CORE.components.options,
            CORE.components.appearance,
            CORE.components.jobspecs
          ]
          
          return specifications.includes(type)
    }

    static componentCanNotBeDeleted(type){
        return this.objectTypeIsWall(type) || this.componentIsStructureSpecifications(type) || type === CORE.components.system;
    }
}