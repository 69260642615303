export default class layerHelper{
    static setGroup(g, layer, recurse=false){
        if(!g || !g.children)
            return;
        g.children.forEach((c)=>{
            // if the group has a child group
            if(c.type === 'Group')
            {
                c.layers.set(layer);
                // set that group's layer
                this.setGroup(c, layer, true);
            }
            else // assumme this is a mesh
                c.layers.set(layer);
        })
    }

    static enableLayer(g, layer, recurse=false){
        if(!g || !g.children)
            return;
        g.layers.enable(layer);
        g.children.forEach((c)=>{
            // if the group has a child group
            if(c.type === 'Group'){
                c.layers.enable(layer);
                
                // set that group's layer
                this.enableLayer(c, layer, true);
            }
            else // assumme this is a mesh
                c.layers.enable(layer);
        })
    }
}