import { CORE } from '../_spec.js'
import _3dBase from './Base.js'
import * as THREE from 'three';

export default class TrimTransitionStrip extends _3dBase {
    constructor(lod, parentGroup, pPitch, cPitch, length, width, height, cName, material){
        super(parentGroup, undefined, undefined, lod)
        
        this.pPitch = pPitch;
        this.cPitch = cPitch;
        this.length = length;
        this.width = width;
        this.height = height
        this.cName = cName;
        this.material = material;
        //this.trimMaterial = trimMaterial;

        this.group.name = 'Transition Strip'
        this.build();
    }

    build(){
        let rakeTrimOffset = 0.25; // prevents transition strip from going past the rake trim on both ends
        let stripLength = this.length - rakeTrimOffset;
        let stripY = this.height;
        let stripZ = -this.width/2
        //split the difference in pitch, as this is a transition piece
        let middlePitch = (this.cPitch + this.pPitch) / 2;
        var geoXY = new THREE.BoxGeometry(stripLength, 0.5, 3);
        geoXY.name = 'geo transition trim'
        let matTrim = new THREE.MeshBasicMaterial({ color: Number(0x912e17), side: THREE.DoubleSide});
        let meshPitchTransition = new THREE.Mesh(geoXY,this.material);
        meshPitchTransition.position.set(0, stripY, stripZ) // this six is not foundation related
        meshPitchTransition.rotation.x = Math.atan(middlePitch/12);
        meshPitchTransition.name = `pitch transition trim for ${this.cName}`;
        meshPitchTransition.layers.set(CORE.layers.roof);
        this.group.add(meshPitchTransition);
    }
}