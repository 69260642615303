import { CORE } from '../_spec.js';
import ConstraintHelper from './ConstraintHelper.js';
import StructureHelper from "./StructureHelper";

export default class SystemHelper {
// this class contains static helper methods for workign with a system of buildings


    static getFrameDesignForStructureId(masterDesign, id){
        let design = masterDesign.getComponentDesignById(id);
        if(!design)
            return;
        return design.getFrame();
    }

    static IsChildSideContainedByParentSide(masterDesign, c){    
        if(c.parent.structureID===0)
            return;
        // get the frame design of the constrainer structure
        let parentFrameDesign = this.getFrameDesignForStructureId(masterDesign, c.parent.structureID) 
        // get the frame design of the constrainee structure
        let childFrameDesign = this.getFrameDesignForStructureId(masterDesign, c.child.structureID);
        
        // detemine the length of the side/end along which the constrained structure is mated to this constraining structure
        let constraineeMatingSideLength = SystemHelper.getConstraintPartMatingLength(c.child, childFrameDesign);        
        // calc the constrained structure mating point
        let start = SystemHelper.getConstrainerRelPosX(c.parent, parentFrameDesign);
        start = c.parent.length*12;
        // calc the end position of the constrained structure in terms of this constrainer structure
        let end = start + constraineeMatingSideLength;

        // get the length of the constrainer side        
        let parentMatingSideFullLength = 12*StructureHelper.getLengthOfSide(masterDesign.getComponentDesignById(c.parent.structureID),c.parent.matingSide);

        // along the mating axis
        // if the child starts at or after where the parent start and ends at or before where the parent ends
        if( start >= 0 && end <= parentMatingSideFullLength)
            // the mated child side is not contained by the mated parent side
            return true;

        return false;
    }
    
    static IsParentSideContainedByChildSide(masterDesign, c){            
        if(c.parent.structureID===0)
            return;
        // get the frame design of the constrainer structure
        let parentFrameDesign = this.getFrameDesignForStructureId(masterDesign, c.parent.structureID) 
        // get the frame design of the constrainee structure
        let childFrameDesign = this.getFrameDesignForStructureId(masterDesign, c.child.structureID);
        
        // detemine the length of the side/end along which the constrained structure is mated to this constraining structure
        let constraineeMatingSideLength = SystemHelper.getConstraintPartMatingLength(c.child, childFrameDesign);        
        // calc the constrainee structure mating point
        let start = SystemHelper.getMatingPointOnConstrainee(c.parent, parentFrameDesign);
        // calc the end position of the constrainee structure in terms of this constrainer structure
        let end = start + constraineeMatingSideLength;

        // get the length of the constrainer side        
        let parentMatingSideFullLength = 12*StructureHelper.getLengthOfSide(masterDesign.getComponentDesignById(c.parent.structureID),c.parent.matingSide);

         // along the mating axis
        // if the child starts before where the parent starts and ends after the parent where the parent ends
        if( start <= 0 && end >= parentMatingSideFullLength)
            // the mated child side is not contained by the mated parent side
            return true;

        return false;
    }
    

    static getConstraintPartMatingLength(cp, frameDesign){
        switch(cp.matingSide){
            case CORE.sides.frontSide:
            case CORE.sides.backSide:
                return frameDesign.length*12;
            case CORE.sides.leftEnd:
            case CORE.sides.rightEnd:
                return frameDesign.width*12;
            default:
                throw  `Invalid constraintPart with mating side ${cp.matingSide}`;
        }
    }

    static getConstrainerMatingSpecs(constrainer, constrainee, constrainerFrameDesign, constraineeFrameDesign){
        // calc the constrainee structure mating point
        let constrainerX = SystemHelper.getConstraineeRelPosX(constrainer, constrainee, constrainerFrameDesign, constraineeFrameDesign);
        // calc the constrained structure mating point
        let constraineeX = 0; //StructureBase.getConstraineeRelPosX(c.child, frameDesign);
        // get the design of the constrained structure
        
        
        // detemine the length of the side/end along which the constraining structure is mated to this constrained structure
        let constraineeMatingWidth = SystemHelper.getConstraintPartMatingLength(constrainer, constrainerFrameDesign);
        return {constrainerX, constraineeX, constraineeMatingWidth}
    }
    
    static getConstraineeMatingSpecs(c, frameDesign, masterDesign){
        // calc the constraining structure mating point
        let constrainerX = SystemHelper.getConstrainerRelPosX(c.parent, frameDesign);
        // calc the constrained structure mating point
        let constraineeX = 0; //StructureBase.getConstraineeRelPosX(c.child, frameDesign);
        // get the design of the constrained structure
        let desConstrainee = masterDesign.getComponentDesignById(c.child.structureID);
        if(!desConstrainee)
            return; // added purely to allow hot-reload
        // get the design of the frame of the constrained structure
        let desConstraineeFrame = desConstrainee.getFrame();
        
        // detemine the length of the side/end along which the constrained structure is mated to this constraining structure
        let constraineeMatingWidth = SystemHelper.getConstraintPartMatingLength(c.child, desConstraineeFrame);
        return {constrainerX, constraineeX, constraineeMatingWidth}
    }
    
    
    static getConstrainerRelPosX(cp, frameDesign){
        // this gets the constrained structure mating point relative to the constrainer's structure's wall
        //cp => ConstraintPart (parent/child)
        let matingLength = SystemHelper.getConstraintPartMatingLength(cp, frameDesign); // width or length of the mating side
        let constraintPartLength = cp.length*12;        
        switch(cp.matingSide){
            case CORE.sides.frontSide:
                return constraintPartLength;
            case CORE.sides.backSide:
                return matingLength - constraintPartLength;
            case CORE.sides.leftEnd:
                return constraintPartLength;
            case CORE.sides.rightEnd:
                return matingLength - constraintPartLength;
            default:
                throw `Invalid constraintPart with mating side ${cp.matingSide}`;
        }
    }

    static getConstraineeRelPosX(constrainerPart, constraineePart, constrainerFrameDes, constraineeFrameDes){
        // this gets the constrainer structure mating point relative to the constrained structure's wall
        //cp => ConstraintPart (parent/child)
        let constraineeMatingLength = SystemHelper.getConstraintPartMatingLength(constraineePart, constraineeFrameDes); // width or length of the side that is mated
        let constrainerMatingLength = SystemHelper.getConstraintPartMatingLength(constrainerPart, constrainerFrameDes);
        let offsetOfChildRelParent = constrainerPart.length * 12;        
        switch(constraineePart.matingSide){
            case CORE.sides.frontSide:
                return  constraineeMatingLength - (-offsetOfChildRelParent + constrainerMatingLength)
            case CORE.sides.backSide:
                return constrainerMatingLength + -(offsetOfChildRelParent)
            case CORE.sides.leftEnd:
                return constraineeMatingLength - (-offsetOfChildRelParent + constrainerMatingLength)
            case CORE.sides.rightEnd:
                return constrainerMatingLength + -(offsetOfChildRelParent)
            default:
                throw `Invalid constraintPart with mating side ${cp.matingSide}`;
        }
    }
    
     static getMatingPointOnConstrainee(cp, frameDesign){
         // All this does is return 0 or throw an error
         //cp => ConstraintPart (parent/child)
         let matingWidth = SystemHelper.getConstraintPartMatingLength(cp, frameDesign);
         let constraintPartLength = cp.length*12; 
         switch(cp.matingSide){
             case CORE.sides.frontSide:
                 return 0 + constraintPartLength;
             case CORE.sides.backSide:
                 return 0 + constraintPartLength;
             case CORE.sides.leftEnd:
                 return 0 + constraintPartLength
             case CORE.sides.rightEnd:
                 return  0 + constraintPartLength;
             default:
                 throw `Invalid constraintPart with mating side ${cp.matingSide}`;
         }
     }

    static getAllUnRotatedSystemCoordinates(cSystem){
        cSystem.group.rotation.y = 0; // unrotate this system so the math is predictable and axis-aligned
        let systemCornerTrimDetails = cSystem.getAllSystemCoordinates();
        cSystem.group.rotation.y = cSystem.design.rotRad; // re-rotate the system
        return systemCornerTrimDetails;

    }

}