import * as THREE from 'three';
import { CORE, rebuildTypes,impactTypes } from '../_spec.js'
import { Vector3 } from 'three';
import OptionHelper from '../helpers/optionHelper.js'
import _3dWindowBase from '../3d/WindowBase.js'
import _3dGirtHoriC from '../3d/GirtHoriC.js'
import _3dDoorHangar from '../3d/DoorHangar.js';
import DoorResizable from './DoorResizable.js';
import Util from '../utility.js';
import mathHelper from '../helpers/mathHelper.js';

export default class DoorHangar extends DoorResizable {
    constructor(design, config) {        
        super(design);

    }

    assertConfig(){
        super.assertConfig();
        
        if(Util.isUndefined(this.design.actuated))
            this.design.actuated = 0;
    }
  
    migrate(design){
        if(!design)
            return;
    }

    applyDesign(design){
        this.design.collided= design.collided,
        this.design.pos = new Vector3( design.pos.x, design.pos.y, design.pos.z),        
        this.design.type = design.type,
        //this.design.id = design.id,
        this.design.parent = {};
        this.design.parent.id = design.parent.id;
        this.design.dim= {
            width: design.dim.width,
            height: design.dim.height
        };
        this.design.excludeOh = design.excludeOh;
        this.design.pos.y = (this.design.dim.height)/2; 
        this.design.selected= false;
    }
    
    defaultDesign(limits){

        let des = {
            collided : false,
            pos : new Vector3(0,(9*12)/2,0), 
            parent: {
            },
            type: CORE.components.doorHangar,
            id: -1,
            dim:{
                width: 9*12,
                height: 9*12
            },
            style: 'bi-fold',
            excludeOh:false,
            selected:false
        }
        if(limits){
            if(des.dim.height > limits.dim.height.max)
            des.dim.height = limits.dim.height.max;
        }
        des.pos.y = (des.dim.height)/2; //foundation is 6" tall
        return des;
    }

    processImpactFromSelf(impact){
        super.processImpactFromSelf(impact);

        switch(impact.change.name)
        {
            case impactTypes.doorHangarDetail:
                this.addRebuildNeeded(rebuildTypes.full);
                break;
        }
    }
    
    getOptionsSpec(){
        let options = super.getOptionsSpec();
        options.push(OptionHelper.radios("style", "Style", impactTypes.doorHangarDetail, [
            {
                value:"bi-fold",
                text:"Bi-Fold"
            },
            {
                value:"no-fold",
                text:"No-Fold"
            }
        ], undefined,undefined,undefined));
        
        options.push(OptionHelper.numericUpDown("actuated","Actuated", impactTypes.doorHangarDetail, 'degrees', 0, 85, 5, true));
        options.push(OptionHelper.header("Hangar doors enforce manual bay spacing on the end wall."));
        return options;
    }
    
    // required by object interface
    getDescription(){

        let widthFt = (this.design.dim.width/12).toFixed(2);
        let heightFt = (this.design.dim.height/12).toFixed(2);
        //return `Hangar Door (${widthFt}' x ${heightFt}')`;
        return `Hangar Door (${mathHelper.inToFtIn(this.design.dim.width)} x ${mathHelper.inToFtIn(this.design.dim.height)})`;
    } 

    build() {
        this.assertConfig();
        let degrees = this.design.actuated;
        let radians = (90-degrees) * Math.PI/180;

        let jambTrimThickness = .5; 
        let jambTrimWidth = 8;
        let halfFrameDepth = jambTrimThickness/2;

        
        let dim = new THREE.Vector3(this.design.dim.width, this.design.dim.height, 1);
        let halfDim = dim.clone().multiplyScalar(.5);
        
        this.sizeDoor = new THREE.Vector3(dim.x, dim.y, 1);
        
        this.group.position.copy(this.design.pos)

        //if(this.settings && this.settings.ohJambLiner!==false)
        let ohJambLiner = this.options && this.options['ohJambLiner']!==false;
        let panelColor = this.structureConfig.wallColor;
        let trimMaterial = this.structureConfig.trimMaterials.door;
        let door = new _3dDoorHangar(CORE.preferences.des_levelOfDetail.value, this.design.dim.width, this.design.dim.height, this.design.style, panelColor, trimMaterial, this.parent.girtColor, radians, ohJambLiner);
        door.group.position.z = .25;
        this.group.add(door.group)


        this.contextMenuObject = new THREE.Mesh()
        let trc = this.getTopRightCorner();
        this.contextMenuObject.position.set(trc.x, trc.y, trc.z);
        this.contextMenuObject.visible = false; // this 

        // we need a separate pickMesh on overhead doors because they're largest feature (the door mesh)
        // is inset into the wall, which "hides" the door from rayCasting because the wall pickMesh
        // must be without holes for the sake of component editing.
        
        if(typeof this.pickDetectionMesh === 'undefined'){
            var geoPick = new THREE.BoxGeometry(this.sizeDoor.x, this.sizeDoor.y, halfFrameDepth);
            this.pickDetectionMesh = new THREE.Mesh(geoPick, CORE.materials.transparent);            
            this.pickDetectionMesh.name=`pickMesh for hangar door ${this.design.id}`;
            this.pickDetectionMesh.visible=false;
        }
        this.pickDetectionMesh.userData = {
            type: this.design.type, // not sure this is necessary. copied from wallBase.
            id: this.design.id
        };
        this.outlineMesh = this.pickDetectionMesh;      
        
        this.group.add(this.pickDetectionMesh)
        this.group.add(this.contextMenuObject);        
        this.group.visible = this.visible;

        
        this.buildGrabjects(halfDim)
        this.showGrabjects(this.design.selected);
        
        this.group.rotation.y = this.rot;

        //this.applyPosition();
        this.setCollisionZone();
        
        this.built=true;
    }


    getAllowedParentTypes(){
        return [
            CORE.components.lew,
            CORE.components.rew
        ]
    }

    getInitialGrabjectUserData(){
        let grabData = super.getInitialGrabjectUserData();        
        grabData.allowedParentComponentTypes = this.getAllowedParentTypes();
        return grabData;
    }    

    getCollisionClass(){
        return CORE.collisions.classes.doorHangar;
    }

    getTypeDisplayName(){
        return "Hangar Door"
    }

}
