export default class SegmentHelper{

    
    static getAffectedSegmentIndex(segs,position){
        // assumes only one segment will be affected by a wall sub-component
        for(var segi=0;segi<segs.length;segi++)
        {
            let seg = segs[segi];
            if(seg.a< position && seg.b > position)
                return segi;
        }
        return -1;
    }


    static divideSegment(seg, linearPosition, width){
        // chops a segment into two parts   
        // assumes seg.a, seg.b, and linearPosition are all relative to the same point
        let distFromA = linearPosition - (seg.a);
        let newAb = (seg.a) + distFromA - width/2;
        let newBa = (seg.a) + distFromA + width/2;
        let segA = SegmentHelper.getSegment(seg.a,newAb);
        let segB = SegmentHelper.getSegment(newBa,seg.b);
        return {
            segA,
            segB
        }
    }

    static getSegment(a, b){
        return {a,b}
    }

    static getMiddle(segment){
        return (segment.a+segment.b)/2;
    }

    static getLength(segment){
        return Math.abs(segment.b - segment.a);
    }




}