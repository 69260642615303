<template>
    <div class="entityUserPicker">
      <BusinessPicker v-if="showBusiness" :disable="disable" :businesses="businesses"  ref="businessPicker" @business-selected="onBusinessSelected" />
      <UserPicker v-if="showUser" :disable="disable" :users="users" ref="userPicker" @user-selected="onUserSelected" />
    </div>
  </template>
  
  <script>
  import BusinessPicker from './BusinessPicker.vue';
  import UserPicker from './UserPicker.vue'
  import { mapState, mapActions, mapMutations } from 'vuex';
  export default {
    name: 'BusinessUserPicker',
    props: {
      businesses: {
        type: Array,
        required: true
      },
      isSupplierPicker:{
        type: Boolean,
        required: true
      },
      disable:{
        type: Boolean,
        required: true
      }
    },
    components: {
      BusinessPicker,
      UserPicker
    },
    data() {
      return {
        selectedBusinessId: null,
        users: [],
        showBusiness: true,
        showUser: true,
      }
    },
    computed: {
    
    },
    mounted(){
      
    },
    methods: {
      ...mapActions('userModule', ['fetchUsersFromSupplier', 'fetchUsersFromContractor']),
      async onBusinessSelected(businessId) {
        this.selectedBusinessId = businessId;
        if (businessId) {
          this.$refs.businessPicker.selectedBusiness = businessId;
          this.users = this.isSupplierPicker ? await this.fetchUsersFromSupplier(businessId) : await this.fetchUsersFromContractor(businessId);
          console.log('Project lvl1 user is from: ', businessId)
        }
        else {
          this.users = [];  // Clear users when no entity is selected
        }
      },
      onUserSelected(userId) {
        if(userId){
          this.$refs.userPicker.selectedUser = userId;
          this.$emit('user-selected', userId)
          console.log('Project lvl1 user is: ', userId)
        }
      },
      disablePickers(){
        this.$refs.entityPicker.isDisabled = true;
        this.$refs.userPicker.isDisabled = true;
      }
    },
    watch:{
  
    }
  }
  </script>

<style lang="scss" scoped>
.entityUserPicker{
  display:flex;
  
}
</style>