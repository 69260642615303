<template>
  <div>
    <div v-if="showSelector" class="options" :class="{openUp: openUp}">
      <div class='title' style="text-align: center;">
        {{title}} 
        <div @click='close' class='close'><font-awesome-icon icon="fa-duotone fa-circle-xmark" size="xl"/></div>
      </div>        
      
      <colorChip 
      v-for="color in palette" 
      :key="color.label" 
      :color="color.color" 
      :name="color.label"
      :nameInTitle="true"
      :nameInChip="true"
      @click="selectColor">
      </colorChip>  
    </div>
    <div class="wrapper">      
      <colorChip 
      :color="this.value"
      :disabled="!enabled"
      :name="labelForColor"
      :nameInChip="true"
      @click="toggleSelector">
      </colorChip>
      
    </div>
  </div>
</template>

<script>
import colorChip from './colorChip.vue'

export default {
  props: 
      ['initColor', 'initLabel', 'palette', 'value', 'openUp', 'title', 'enabled'],
  data: ()=>{
      return {
          showSelector:false,
          color: null,
          label: null,          
      };
  },
  computed: {
      labelForColor:{
        get(){
          for(let i=0;i<this.palette.length;i++)
            if(this.palette[i].color === this.value)
              return this.palette[i].label;
          return '';
        }
      },
      initialColor:{
          get(){
              if(!this)
                console.log('not this')
            
            if(!this.initColor)
                console.log('not this.initColor')

              return this.initColor;
          }
      },
      
  },
  created(){
      this.color = this.initColor;
      this.label = this.initLabel;   
  },
  mounted(){
  },
  methods: {      
      toggleSelector(){
        if(!this.enabled){
          console.log(this.color)
          return;          
        }
          
        let was = this.showSelector;
        this.$emit('opening');
        this.showSelector=!was;
      },
      close(){
        this.showSelector=false;
      },
      selectColor(color){        
        this.color = color;
        this.showSelector=false;
        this.$emit('input', this.color);
      }
  },
  components:{
      colorChip
  }
}
</script> 

<style scoped>

div.wrapper{ 
    text-align: center;
    border-radius: 4px;
    padding:2px;
    /*cursor: pointer;*/
    opacity: 1;  
    z-index: 3; 
    margin-bottom: 20px;
}

div.options{
    margin:auto;
    width:378px;
    display: flex;
    flex-flow: row wrap;
    background: darkgray;
    border-radius: 5px;
    position: absolute;
    border: 1px solid black;
     z-index:5; /* Advanced component options */
    /* left:200px;
    top:-30px; */
}

.open{
  z-index:1003
}

.openUp{
  transform: translate(0%, -130%);
}


div.title{
   font-weight:bold;
   display:inline-block;
   width:100%;
}
.close{
  float: right;
  cursor: pointer;
}
</style>


