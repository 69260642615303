import * as THREE from 'three';
import {Vector3} from 'three'
import EndSkirt from './endSkirt.js'
import BaySideFront from './BaySideFront.js'
import BaySideBack from './BaySideBack.js'

export default class EndSkirtLeft extends EndSkirt{
    constructor(
        design, 
        structureConfig,
        length, 
        width, 
        height, 
        frameType, 
        distToNextFrameline, 
        trimMaterials, 
        endGirtHeights, 
        frontGirtHeights, 
        backGirtHeights, 
        wallColor,
        pitchRatio,
        beamColor){
        super(
            design, 
            structureConfig,
            length, 
            width, 
            height, 
            frameType, 
            distToNextFrameline, 
            trimMaterials, 
            endGirtHeights, 
            frontGirtHeights, 
            backGirtHeights, 
            wallColor,
            pitchRatio,
            beamColor)
        this.group.name = this.getDescription() + ' Component'
    }

    getDescription(){     
            return "Left End Skirt";     
    }

    getNextInnerBayIndex(index){
        return index++;
    }

    getNextOuterBayIndex(index){
        return index--;
    }


    getFrontSideWall(wallDesign, wallDes,maxHeight){
        return new BaySideFront(wallDesign, 
            this.structureConfig,
            wallDes.bay.length,
            maxHeight,
            this.trimMaterials,                     
            this.frontGirtHeights, 
            [], // framelines
            new Vector3(this.getFrontSideX(),-(this.height+this.design.pitchHeight),-wallDes.bay.start),
            Math.PI/2, // rotation
            undefined, // wainscot
            {enabled:false}, // footerCollision
            false, // addToQuoteLayer
            false, // allowDripTrip
            false, // insulation
            this.pitchRatio, //pitchRatio
            this.length
            );
    }

    getBackSideWall(wallDesign, wallDes, maxHeight){
        // walls are built from the ground up. 
        // End skirt is built with origin at peak.
        // subtract pitchHeight (if any) and height from 0 to get to the foundation
        
        return new BaySideBack(wallDesign, 
            this.structureConfig,
            wallDes.bay.length,
            maxHeight,
            this.trimMaterials,                     
            this.backGirtHeights,
            [], 
            new Vector3(this.getBackSideX(),-(this.pitchHeight + this.height),-wallDes.bay.start), 
            Math.PI/2,            
            undefined,
            {enabled:false},
            false, // addToQuoteLayer
            false, // allowDripTrip
            false, // insulation
            this.pitchRatio, //pitchRatio
            this.length);
    }


    getFrontSideX(){
        return this.width/2
    }

    getBackSideX(){
        return -(this.width/2 + .1);
    }

    orientFrontSideWall(group, wallMiddle){
        group.position.x = this.halfWidth;
        group.position.z = -wallMiddle; // put it in the middle so we can rotate it
        group.rotation.y=Math.PI/2; // rotate it
    }

    orientBackSideWall(group, wallMiddle){
        group.position.x = -this.halfWidth;
        group.position.z = -wallMiddle; // put it in the middle so we can rotate it
        group.rotation.y= -Math.PI/2; // rotate it
    }

    orientFrontTrim(group){
        group.position.x = this.halfWidth+1.2;
        group.position.z = +1.2;
        group.position.y = -this.design.pitchHeight - this.openHeightOffsetInches; 
        group.rotation.y = Math.PI/2;
    }

    orientBackTrim(group){
        group.position.x = -this.halfWidth-1.2;
        group.position.z = +1.2;
        group.position.y = -this.design.pitchHeight - this.design.openHeightOffset;
        group.position.y = -this.design.pitchHeight -this.openHeightOffsetInches;
    }

    getSlopedRoofEndWallPoints2D(bottomPosY, dropHeight){
        let points = [];
        
         // sloped
         // front side
         points.push(new THREE.Vector2(-this.halfWidth,bottomPosY))                    
         points.push(new THREE.Vector2(-this.halfWidth,0))
         // back side
         points.push(new THREE.Vector2(this.halfWidth,bottomPosY+dropHeight))
         points.push(new THREE.Vector2(this.halfWidth,bottomPosY))
         return points;
    }

    getFrontSideWallPoints2D(halfLength, bottomPosY, dropHeight){
        let points=[];
        // gabled or sloped, it's the same
        points.push(new THREE.Vector2(halfLength, bottomPosY));
        points.push(new THREE.Vector2(halfLength, bottomPosY+dropHeight));
        points.push(new THREE.Vector2(-halfLength, bottomPosY+dropHeight));
        points.push(new THREE.Vector2(-halfLength, bottomPosY));
        return points
    }

    getGabledRoofBackSideWallPoints2D(halfLength, bottomPosY, dropHeight){
        let points = [];
        points.push(new THREE.Vector2(halfLength, bottomPosY));        
        points.push(new THREE.Vector2(halfLength, bottomPosY+dropHeight));
        points.push(new THREE.Vector2(-halfLength, bottomPosY+dropHeight));
        points.push(new THREE.Vector2(-halfLength, bottomPosY));
        return points;
    }
    
    getSlopedRoofBackSideWallPoints2D(halfLength, bottomPosY){
        let points =[];
        points.push(new THREE.Vector2(halfLength, bottomPosY));                
        points.push(new THREE.Vector2(halfLength, 0));
        points.push(new THREE.Vector2(-halfLength, 0));                    
        points.push(new THREE.Vector2(-halfLength, bottomPosY));
        return points;
    }

    framelineIndexAbsolute(){
        return 0
    }

    nextInteriorFramelineIndexAbsolute(){
        return 1;
    }
}