import * as THREE from 'three'
import {CORE} from '../_spec.js'
import vHelper from '../helpers/vHelper.js'
import MemHelper from '../helpers/memHelper.js';
import SpriteHelper from '../helpers/SpriteHelper.js';
import _3dBase from './Base.js';

export default class DistHori extends _3dBase{
    constructor(a,b, offsetAtBase, offsetAtLine, multiplier, layer, scale) {
        super()
        this.group.name = 'distance marker'
        this.a = a;
        this.b = b;

        this.scale = scale;
        if(!this.scale)
            this.scale = new THREE.Vector3(30,15,1.0)
        this.offsetAtBase = offsetAtBase;
        this.offsetAtLine = offsetAtLine;
        this.layer = layer;
        // one-time logic
        let dist = this.a.distanceTo(this.b);
        this.distRaw = dist;
        dist*=multiplier;
        dist = Number(dist).toFixed(1);
        let distUnit = `'`;
        if(multiplier===1)
            distUnit = `"`;
        
        this.distString = `${dist}${distUnit}`;

        this.material = new THREE.LineBasicMaterial({
            color: 0x000000,
            linewidth: 3, // there is a defect in underlying software that prevents this from taking effect.
            linecap: 'round',
            linejoin: 'round'
        });

        this.baseA = vHelper.addToCopy(this.a,this.offsetAtBase.x,this.offsetAtBase.y,this.offsetAtBase.z);
        this.baseB = vHelper.addToCopy(this.b,this.offsetAtBase.x,this.offsetAtBase.y,this.offsetAtBase.z);

        // apply the offset to A and B
        this.offsetA = vHelper.addToCopy(this.baseA, this.offsetAtLine.x,this.offsetAtLine.y,this.offsetAtLine.z)
        this.offsetB = vHelper.addToCopy(this.baseB, this.offsetAtLine.x,this.offsetAtLine.y,this.offsetAtLine.z)

        this.textMaterial = new THREE.MeshPhongMaterial( { color: 0x000000 } );
        this.halfWay = vHelper.addToCopy(this.a,this.offsetAtLine.x,this.offsetAtLine.y,this.offsetAtLine.z);
		this.halfWay.lerp(this.offsetB, .5); // find the half-way point
        this.build();
    }

    build(camDist){
        //TODO: these are slowing everything down. These don't need to be rebuilt with dynamicOnly for walls.
        
        camDist = camDist || 1800;        
        let letterFactor = (camDist*.001)
        let letterWidth = 2*letterFactor;
        let textSize = 8*letterFactor;
        let textWidth = (this.distString.length*letterWidth);
        let halfTextWidth = textWidth/2;
        let gapLength = letterWidth*7; // inches
        this.textInGap = true;
        if(this.distRaw < gapLength+2){
            this.textInGap=false;
        }

        let pointsA;        
        let pointsB;
        
        if(this.textInGap){ // number text goes in the gap!
            // calculate points that create a gap in the primary line along the distance
            let gapRatio = gapLength/this.distRaw; // calculate the ratio of gap to distance
            let halfGapRatio = gapRatio/2;
            // interpolate from A to A's side of the gap
            let offsetAGap = vHelper.addToCopy(this.offsetA, 0,0,0);
            offsetAGap = offsetAGap.lerp(this.offsetB, .5-halfGapRatio);
            // interpolate from B to B's side of the gap
            let offsetBGap = vHelper.addToCopy(this.offsetA, 0,0,0);
            offsetBGap = offsetBGap.lerp(this.offsetB, .5+halfGapRatio);

            pointsA = [
                this.baseA,
                this.offsetA,
                offsetAGap
            ];
            // number text goes in the gap!
            pointsB = [
                offsetBGap,
                this.offsetB,
                this.baseB
            ];
        }
        else{
            let offsetMid = vHelper.addToCopy(this.offsetA, 0,0,0);
            offsetMid = offsetMid.lerp(this.offsetB, .5);
            // assumes horizontal, as the class implies.
            let offsetMidUp = vHelper.addToCopy(offsetMid, 0, textSize/2, 0)
            pointsA = [
                this.baseA,
                this.offsetA,
                offsetMid,
                offsetMidUp
            ];
            
            pointsB = [
                offsetMid,
                this.offsetB,
                this.baseB
            ];
        }
        
        this.geometryA = new THREE.BufferGeometry().setFromPoints( pointsA );
        this.lineA = new THREE.Line( this.geometryA, this.material );

        this.geometryB = new THREE.BufferGeometry().setFromPoints( pointsB );
        this.lineB = new THREE.Line( this.geometryB, this.material );

        this.sprite = SpriteHelper.makeTextSprite(this.distString, this.scale, {
            fontsize:30
        });
        
        if(this.textInGap){
            this.sprite.position.set( this.halfWay.x, this.halfWay.y  + this.offsetAtBase.y, this.halfWay.z);
            this.sprite.center.y = .5;
        }
        else{
            this.sprite.position.set( this.halfWay.x , this.halfWay.y + this.offsetAtBase.y, this.halfWay.z);
            this.sprite.center.y = 0;
        }

        if(this.layer){
            this.lineA.layers.set(this.layer);
            this.lineB.layers.set(this.layer);
            this.sprite.layers.set(this.layer);
        }
        else{
            this.lineA.layers.set(CORE.layers.openingDimensions);
            this.lineB.layers.set(CORE.layers.openingDimensions);
            this.sprite.layers.set(CORE.layers.openingDimensions);
        }
        
        this.group.add( this.sprite);
        this.group.add( this.lineA );
        this.group.add( this.lineB );
    }
}

