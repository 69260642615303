import * as THREE from 'three'
import { CORE } from '../_spec.js'
import _3dWindowBase from './WindowBase.js'
import layerHelper from '../helpers/layerHelper.js'
import _3dTrimOpeningFace from './TrimOpeningFace.js';
import materialHelper from '../helpers/materialHelper.js';
// REFERENCE IMAGE: https://nebula.wsimg.com/afa587762ee092eecdb6212d340a942c?AccessKeyId=540DC0AD243BC3839B8C&disposition=0&alloworigin=1

export default class WindowPicture extends _3dWindowBase{
    constructor(lod, parentGroup, dim, trimMaterial){
        super(lod, parentGroup, dim, trimMaterial)
        this.group.name = "Picture Window";
        this.build(dim);
    }

    build(dim){
        let gWindow = new THREE.Group();
        
        let dimJamMaterial = new THREE.Vector3(.5,.5,2);
        let matJam = materialHelper.getPlasticMaterial_HD();
        let trim = new _3dTrimOpeningFace(this.lod, dim,dimJamMaterial,matJam);
        layerHelper.enableLayer(trim.group, CORE.layers.quote);
        gWindow.add(trim.group);

        let dimSash = dim.clone().sub(dimJamMaterial);
        dimSash.z = 1;
        let dimSashMaterial = new THREE.Vector3(3, 3, dimSash.z)
        gWindow.add(this.buildSash(dimSash, dimSashMaterial));       
        
        this.group.add(gWindow)
        this.setWindowGroup(gWindow);
    }
    
}