import {CORE} from '../_spec.js'
import Util from '../utility.js'
import BuildLogic from '../o/BuildLogic.js';
import {TangentSpaceNormalMap, Vector3} from 'three'
export default class BlueprintHelper{
    
    static getBackSideWallHeightDeltaInches(widthInches, pitch, newRoofType){
        // return the delta for the back side wall height when the roof type changes

        // toggling from gable to slope adds slope-much height to the back wall
        // toggling from slope to gable subtracts slope-much height from the back wall
        let pitchHeight = BlueprintHelper.pitchHeight(widthInches, BlueprintHelper.pitchToPitchRatio(pitch), CORE.roof.types.slope);
        if(newRoofType === CORE.roof.types.slope)
            return pitchHeight;
        else
            return -pitchHeight;
    }
    
    static getBackSideWallHeightInches(roofDesign, frameDesign)
    {
        if(roofDesign.roofType == CORE.roof.types.gable)
            return this.getStructureHeightInches(frameDesign);
        let pitchHeight =
        BlueprintHelper.pitchHeight(frameDesign.width*12, 
        BlueprintHelper.pitchToPitchRatio(roofDesign.pitch),
        roofDesign.roofType);
    
        return  this.getStructureHeightInches(frameDesign) + pitchHeight;
    }
    static getStructureHeightInches(frameDesign){
        if(!frameDesign)
            return 0;
        return frameDesign.height * 12;
    }

    static isEnd(side){
        return side === CORE.sides.leftEnd || side === CORE.sides.rightEnd
    }

    static isSide(side){
        return side === CORE.sides.backSide || side === CORE.sides.frontSide;
    }

    /*
    static GableCanTieInSides(sideA, sideB){
        return (this.isSide(sideA) && this.isEnd(sideB)) || 
                (this.isSide(sideB) && this.isEnd(sideA))
    }
    */

    static GableCanTieInSides(childSide, parentSide, pRoofType){

        // child structure - only the left side is supported
        if (childSide != CORE.sides.leftEnd)
            return false;

        // parent structure - end wall connections are never gable tie in
        if (this.isEnd(parentSide))
            return false;
        
        // parent structure - back side connection can only be a tie-in for gabled roofs
        if (parentSide == CORE.sides.backSide)
            if (pRoofType == CORE.roof.types.slope)
                return false;

        return true;
    }
    
    static GableCanTieInHeight(ewEaveHeight, ewRidgeHeight, swEaveHeight, swRidgeHeight){

        // end-wall building must have a ridge/high-side between side-wall building eave and ridge
        // end-wall building must have eave at or below side-wall building eave

        if( swRidgeHeight < ewRidgeHeight ||                                     
            swEaveHeight > ewRidgeHeight ||
            swEaveHeight < ewEaveHeight)
            return false;
        return true;
    }

    static getFramelinePositionXByIndex(length, framelinePositions, frameLineIndex, isPorch){
        let xFrameLine = framelinePositions[frameLineIndex];
        let referenceX = -length*12/2; // front porch
        let flip = 1;   
        if(isPorch){
            // back porch frame is built facing forward, and then rotated into position
                // TODO: centralize and/or correct this static 4
            referenceX = (-length*12/2) - framelinePositions[0] + BuildLogic.getOutsideFramelineInset().outsideFrameLineInset;
        }
        return referenceX+(xFrameLine*flip)
    }

    static ridgeHeight(height, width, pitchRatio, roofType){
        return height + this.pitchHeight(width, pitchRatio, roofType)
    }

    static purlinType(frameType){
        if(frameType === CORE.frame.types.bolt)
            return CORE.roof.purlin.types.Z;
        return CORE.roof.purlin.types.C;
    }

    static height_FrontEaveTop(height){
        return height;
    }

    static height_FrontEaveBottom(height, pitchRatio){
        return this.height_FrontEaveTop(height)  - this.pitchedPurlinDimY(pitchRatio);
    }

    static height_BackEaveTop(roofType, width, height, pitchRatio){
        let heightFrontEaveTop = this.height_FrontEaveTop(height, pitchRatio)
        if (roofType === CORE.roof.types.gable)
            return heightFrontEaveTop
        else
            return heightFrontEaveTop + this.pitchHeight(width, pitchRatio, roofType);
    }

    static height_BackEaveBottom(roofType, width, height, pitchRatio){
        return this.height_BackEaveTop(roofType, width, height, pitchRatio) - this.pitchedPurlinDimY(pitchRatio);
    }


    static pitchToPitchRatio(pitch)
    {
        return pitch/12;
    }

    static pitchRatioToRadians(pitchRatio){
        return Math.atan(pitchRatio);
    }

    static pitchRatioToDegrees(pitchRatio) {
        return this.pitchRatioToRadians(pitchRatio) * 180/Math.PI
    }

    static pitchHeight(frameWidth, pitchRatio, roofType){        
        if(roofType === CORE.roof.types.slope)
            return frameWidth * pitchRatio;
        else
            return frameWidth * pitchRatio / 2 ;
    }

    static peakHeight(frameWidth, pitch, roofType, eave)
    {
        return BlueprintHelper.pitchHeight(frameWidth, BlueprintHelper.pitchToPitchRatio(pitch), roofType) + eave
    }

    static getLengthCreatedByAngleOverDistance(radians, adjacentDistance){
        // tan (@) = O/A
        // A * tan(@) = O  <------
        // A = O / tan(@)
        /*
            |\
        opp | \
            |  \
            |   \
            |___@\

              adj.
        */   

        return adjacentDistance * Math.tan(radians);
        

    }

    static pitchedPurlinDimY(pitchRatio){
        let purlinDimY = CORE.roof.purlin.dim.height;// + (2*CORE.roof.purlin.dim.thickness);
        let pitchRadians = this.pitchRatioToRadians(pitchRatio)        
        let pitchedPurlinDimY = purlinDimY / Math.cos(pitchRadians);
        return pitchedPurlinDimY;
    } 



    static generatePoints(length,height,roofType, pitchHeight){
        // generates points around the origin so some have negative X coordinates
        let points = [];
        let hl=length/2;

        // bottom left
        points.push(new Vector3(-hl,0,0));

        switch(roofType){
            case CORE.roof.types.gable:
                //top left
                points.push(new Vector3(-hl,height,0));
                //top middle
                points.push(new Vector3(0,height+pitchHeight,0));
                break;
            case CORE.roof.types.slope:
                //top left
                points.push(new Vector3(-hl,height+pitchHeight,0));
                break;
            default: // no roof consideration
                //top left
                points.push(new Vector3(-hl,height,0));
                break;
        }

        // top right
        points.push(new Vector3(hl,height,0));
        // bottom right
        points.push(new Vector3(hl,0,0));

        
        return points;
    } 

    
    static generatePointsWithOffset(length,height,roofType, pitchHeight, offsetX){
        // generates points around the origin so some have negative coordinates
        let points = [];
        if(typeof offsetX === 'undefined')
            offsetX = 0;
        let left = 0 + offsetX; // left
        let right = left+length; // right
        let middle = left+length/2; // middle        

        // bottom left
        points.push(new Vector3(left,0,0));

        switch(roofType){
            case CORE.roof.types.gable:
                //top left
                points.push(new Vector3(left,height,0));
                //top middle
                points.push(new Vector3(middle,height+pitchHeight,0));
                break;
            case CORE.roof.types.slope:
                //top left
                points.push(new Vector3(left,height+pitchHeight,0));
                break;
            default: // no roof consideration
                //top left
                points.push(new Vector3(left,height,0));
                break;
        }

        // top right
        points.push(new Vector3(right,height,0));
        // bottom right
        points.push(new Vector3(right,0,0));
        
        return points;
    } 

    static generateBayPoints(bayStart, bayEnd, wallLength, wallHeight, bayHeight, roofType, pitchRatio, wallSide){
        let points = [];
        let middle = wallLength/2;
        let bayHasPeak = bayEnd > middle && bayStart < middle;
        let pitchHeight = BlueprintHelper.pitchHeight(wallLength, pitchRatio, roofType);
        bayHeight = bayHeight??0;
        let openHeight = bayHeight == 0 ? 0 : wallHeight - bayHeight;

        switch(roofType){
            case CORE.roof.types.gable:
                // bayStartRun and bayStartEnd account for which side of the roof we are on 
                let bayStartRun = bayStart < middle ? bayStart : wallLength-bayStart;
                let bayEndRun = bayEnd < middle ? bayEnd : wallLength-bayEnd;
                // bottom left
                points.push(new Vector3(bayStart,openHeight,0));
                // top left
                points.push(new Vector3(bayStart,wallHeight+bayStartRun*pitchRatio,0));
                // top middle, bay spacing affects coordinate x, 
                // peak is still in the middle of the wall, but may not be in the middle of a bay
                if(bayHasPeak){
                    points.push(new Vector3(middle, wallHeight+pitchHeight,0));
                }
                // top right
                points.push(new Vector3(bayEnd,wallHeight+bayEndRun*pitchRatio,0));
                // bottom right
                points.push(new Vector3(bayEnd, openHeight, 0))
                break;

            case CORE.roof.types.slope:
                // bayStartRun and bayStartEnd account for which side of the wall we are on 
                //let bayStartRunSl = wallSide == CORE.sides.rightEnd ? bayStart : wallLength-bayStart;
                let bayStartRunSl =  wallLength-bayStart;
                //let bayEndRunSl = wallSide == CORE.sides.rightEnd ? bayEnd : wallLength-bayEnd;
                let bayEndRunSl =  wallLength-bayEnd;
                // bottom left
                points.push(new Vector3(bayStart, openHeight, 0));
                // top left
                points.push(new Vector3(bayStart,wallHeight+bayStartRunSl*pitchRatio,0));
                //top right
                points.push(new Vector3(bayEnd,wallHeight+bayEndRunSl*pitchRatio,0));
                //bottom right
                points.push(new Vector3(bayEnd, openHeight, 0))
                break;
            default: // no roof consideration
                 // bottom left
                 points.push(new Vector3(bayStart,openHeight,0));
                 // top left
                 points.push(new Vector3(bayStart,wallHeight,0));
                 // top right
                 points.push(new Vector3(bayEnd,wallHeight,0));
                 // bottom right
                 points.push(new Vector3(bayEnd, openHeight, 0))
                 break;
        }
        
        return points;
    }


    static subdivideLength(start,end,increment){
        let points=[];

        for(let i=start;i<=end;i+=increment)
            points.push(i);
            
        if(points.at(-1)>end)
            points.splice(points.length-1,1);
        
        return points;
    }

    static divideLengthIntoSections(length, maxSpacingIn, options){        
        
        let points = [];

        if(length < 0)
            length = 0;
        
        let numSections = Math.floor((length)/maxSpacingIn);

        let widthRemnant = (length)%maxSpacingIn;

        if(!options.remnantLeft && !options.remnantRight)
            throw 'a remenant location must be specified'

        if(options.remnantLeft && options.remnantRight)
            widthRemnant = widthRemnant/2;

        let point = 0;         
        points.push(point);

        // optional starting remnant
        if(options.remnantLeft && widthRemnant!==0)
        {
            point+=widthRemnant;            
            points.push(point);
        }
        
        // equal size sections
        for(let b=0;b<numSections;b++)
        {
            if(point<0) continue;
            point+=maxSpacingIn;// 
            points.push(point);
        }

        // optional ending remnant
        if(options.remnantRight &&widthRemnant!==0)
        {
            point+=widthRemnant;            
            points.push(point);
        }
        
        return points;
    }

    static generateColumnPositions2(start, end, centerSpacing, nonCenterSpacing, columnHalfWidth) {
        
        let centerSpacingIn=Util.Convert.ftToIn(centerSpacing);
        let nonCenterSpacingIn=Util.Convert.ftToIn(nonCenterSpacing);
        let length = end-start;
        let posStart = columnHalfWidth;
        let posEnd = length - columnHalfWidth;        
        let lengthCtoC =posEnd - posStart;
        let points = [];

        let widthNonCenterIn = lengthCtoC - centerSpacingIn;

        if(widthNonCenterIn < 0)
            widthNonCenterIn = 0;

        let numNonCenterBays = Math.floor((widthNonCenterIn/2)/nonCenterSpacingIn);
        let widthRemnant = (widthNonCenterIn/2)%nonCenterSpacingIn;

        let point = posStart; // start on the Left end of the side
        //there will always be this left-most frame-line
        points.push(point);// left end
        if(widthRemnant!==0)
        {
            point+=widthRemnant;            
            points.push(point);
        }
        
        // left non-center bays
        for(let b=0;b<numNonCenterBays;b++)
        {
            if(point<0) continue;
            point+=nonCenterSpacingIn;// 
            points.push(point);
        }

        if(centerSpacingIn!==0){

            point+=Math.min(centerSpacingIn, lengthCtoC); // if center is larger than the entire span, claim entire span
            points.push(point);
        }

        // right non-center bays
        for(let b=0;b<numNonCenterBays;b++)
        {
            if(point<0) continue;
            point+=nonCenterSpacingIn;// 
            points.push(point);
        }

        if(widthRemnant!==0)
        {
            point+=widthRemnant;            
            points.push(point);
        }
        
        return points;
    }


    static generateColumnPositions(start, end, spacing, columnHalfWidth) {
        
        let midSpacing=Util.Convert.ftToIn(spacing);
        let length = end-start;
        let posStart = columnHalfWidth;
        let posEnd = length - columnHalfWidth;        
        let lengthCtoC =posEnd - posStart;
        let points = [];
        if(length < midSpacing)
        {
            /*
            if there's less length than the bay spacing
            then there will be just the one bay, -length/2 to length/2
            */
            points.push(posStart);
            points.push(posEnd);
        }
        else{
            //if there's more length than the bay spacing, 
            //there's at least 1 middle bay
            //If the LEW bay spacing is customized, there may not be a REW bay, per se.


            let leftBay, rightBay, middleBays;
            // calculate the number of middle, full length spans, 
            middleBays = Math.floor(lengthCtoC/midSpacing);
            // calculate the remnant spans
            let remnant = lengthCtoC - (middleBays*midSpacing);
            
            if(remnant< 60){
                remnant = 0;
                midSpacing = Math.floor(lengthCtoC/middleBays);
            }

            leftBay = remnant/2;
            rightBay = remnant/2;            
            // start with Left remnant, add middles, and Right remnant

            let point = posStart; // start on the Left end of the side
            //there will always be this left-most frame-line
            points.push(point);// left end
            point+=leftBay;
            
            points.push(point);
            for(let b=0;b<middleBays;b++)
            {
                if(point<0) continue;
                point+=midSpacing;// 
                points.push(point);
            }

            point+=rightBay;//
            if(point>=0)
                points.push(point);
        }
        return points;
    }

    static getEndWallHeightAtPosition(z, length, eaveHeight, pitchRatio,roofType){
        let pitchDistance = z; // horizontal distance for which we must account for pitch

        if(roofType === CORE.roof.types.slope){
            pitchDistance = length-z; // so we can simply add to what is always the low-point of the roof.            
        }
        else
        if(roofType === CORE.roof.types.gable && pitchDistance > length/2)
            pitchDistance = length-z; // so we can simply add to what is always the low-point of the roof.

        let pitchHeight = BlueprintHelper.getLengthCreatedByAngleOverDistance(BlueprintHelper.pitchRatioToRadians(pitchRatio), pitchDistance);
        return eaveHeight + pitchHeight;
    }

    static getColumnHeightAlongFrameLine(z, length, minHeight, pitchRatio,roofType){
        let pitchDistance = z; // horizontal distance for which we must account for pitch
        let pitchHeight = 0;        


        // length = 960
        // SS

        if(roofType === CORE.roof.types.slope){
            pitchDistance = length/2-z; // so we can simply add to what is always the low-point of the roof.
        }
        if (roofType === CORE.roof.types.gable ){
            
            pitchDistance = length/2 - Math.abs(z);// length-z; // so we can simply add to what is always the low-point of the roof.

        }
        pitchHeight = BlueprintHelper.getLengthCreatedByAngleOverDistance(BlueprintHelper.pitchRatioToRadians(pitchRatio), pitchDistance);
        return minHeight + pitchHeight;
    }

    static getStandardFrameMaterialSize(){
        return {shape: CORE.frame.shapes.straight, depth:8, flange:4}
    }

    static getMainFrameMaterialSize(width, frameType){
    /*
    Calculates the size of main frame columns/rafters based on the width of the building and the frame type (weld or bolt)
    */

        let widthFt = width/12; // convert to ft
        let depth, flange;

        if(widthFt>60){
            // <=100
            if(frameType === CORE.frame.types.weld || frameType ===CORE.frame.types.weldPlus)
                return null; // welded buildings aren't supported above 60'

            return this.getTaperedFrameMaterialSize(width);
        }
        else if(widthFt>50){
            // <=60
            depth=16;
            flange=5.5; 
        }
        else if(widthFt>40){
            // <=50
            depth=14;
            flange=5; 
        }
        else if(widthFt>30){
            // <=40
            depth=12;
            flange=4; 
        }
        else {
            // <=30
            return this.get8x4StraightFraming();
        }

        return {shape: CORE.frame.shapes.straight, depth, flange};
    }

    static get8x4StraightFraming(){
        return this.getStraightFraming(8,4);
    }

    static getStraightFraming(depth, flange){
        return {shape: CORE.frame.shapes.straight, depth, flange};
    }

    
    static getPostAndBeamFrameMaterialSize(){
    /*
    Returns pre-determines size of frame columns/rafters
    Source: Adam
    */

    return {shape: CORE.frame.shapes.straight, depth:8, flange:4};
    }

    static getTaperedFrameMaterialSize(width){
        let widthFt = width/12;
        
        // calculation source: Main Frame Chart.xlsx
        // https://docs.google.com/spreadsheets/d/1eXYVYZUjdDPrN1n1xU_ZNNQy0ZgyfwuD/edit#gid=406712211        
        let E22 = 20;
        let E23 = (E22*(1+((widthFt-60)/100)));
        let E24 = E23+(E23-E22)
        return {shape: CORE.frame.shapes.tapered, depthBase: 10, depthHaunch: E24, depthPeak:16, flange: 6};
    }    

    static getAdjacentSides(side){
        switch(side){
            case CORE.sides.frontSide:
                return {left: CORE.sides.leftEnd, right: CORE.sides.rightEnd}
            case CORE.sides.leftEnd:
                return {left: CORE.sides.backSide, right: CORE.sides.frontSide}
            case CORE.sides.backSide:
                return {left: CORE.sides.rightEnd, right: CORE.sides.leftEnd}
            case CORE.sides.rightEnd:
                return {left: CORE.sides.frontSide, right: CORE.sides.backSide}
        }
        throw `invalid argument: side ${side}`;
    }
}