
import * as THREE from 'three';
import { Quaternion, Vector3, Vector2 } from 'three';
import ShapeHelper from '../helpers/shapeHelper';
import BayBase from './BayBase.js';
import OptionHelper from '../helpers/optionHelper.js'
import BlueprintHelper from '../helpers/blueprintHelper.js'
import vHelper from '../helpers/vHelper.js'
import ComponentHelper from '../helpers/featureHelper.js'
import layerHelper from '../helpers/layerHelper.js'
import {CORE, impactTypes } from '../_spec.js'
import CollisionHelper from '../helpers/CollisionHelper'


import _3dColumnC from '../3d/ColumnC.js';
import _3dColumnStraight from '../3d/ColumnStraight.js';
import _3dColumnSquare from '../3d/ColumnSquare.js'
import { coolGray } from 'tailwindcss/colors';
import Tri from '../3d/Tri';
import materialHelper from '../helpers/materialHelper';
import EarcutDataManager from '../helpers/EarcutDataManager';
import SheetingHelper from '../helpers/SheetingHelper';
import Sheeting from '../3d/Sheeting';
import MemHelper from '../helpers/memHelper';
import Wall_Base from './Wall_Base';
import StructureHelper from '../helpers/StructureHelper';
import Util from '../utility.js';
import DesignHelper from '../helpers/DesignHelper.js';
import DesignManager from '../design.js';
import ConstraintHelper from '../helpers/ConstraintHelper.js';

export default class Wall_End extends Wall_Base {
    constructor(
        masterDesign,
        des, 
        structureConfig,
        length,
         maxHeight,
          facePosZ,
           trimMaterials,
             cbDesignChange,
              girtHeights,
               typeDetail,
                supportsWainscot,
                 footerCollisions,
                  addToQuoteLayer,
                  allowDripTrim,
                  insulation,
                  pitchRatio,
        roofType, frameType, distToNextFrameline, stopFrameLine, beamColor, girtColor, options,
        shedHoles) {
        super(masterDesign,des, structureConfig, length, maxHeight, facePosZ, trimMaterials, cbDesignChange, supportsWainscot, footerCollisions, addToQuoteLayer,allowDripTrim, insulation, pitchRatio,
            roofType, beamColor, girtColor, options);
        
        this.wallOpenings = shedHoles;
        this.distToNextFrameline = distToNextFrameline;
        this.frameType = frameType;
        //shapePoints=this.getShapePoints(); // LEW or REW specific points
        // LEW or REW specific geometry for the holey wall
        this.stopFrameLine = stopFrameLine;
        this.design.typeDetail = typeDetail; //
        // our encapsulated scene

        //if(!this.buildFacingPositiveZ)
           // pos.z-=this.wallThickness; // wall thickness (extrusion depth/amount)
            
        this.girtHeights=girtHeights;
        //this.backBottom = new Vector3().copy(shapePoints[0]);
        //this.backTop = new Vector3().copy(shapePoints[1]);
        //this.frontTop = vHelper.addToCopy(shapePoints[shapePoints.length-2], 0, 0, 0);
        StructureHelper.get
        

        this.length = length;
        this.wallMid = this.length/2;
        
        // push the wall to the appropriate edge of the column


        if(des.frameLine === undefined)
            des.frameLine = 0;

        // this is an endWall which can be inset from the end of the frame, so the position
        // of the wall is based on the location of the targetted frameline (0 to n-1)
        this.columnPoints=[];

        //this.shapePoints = Array.from(shapePoints);
        //this.shapePoints[0].y-=3;         // 1 of 2 bottom points extended down below concrete because shapes are a pain
        //this.shapePoints[this.shapePoints.length-1].y-=3; // 1 of 2 bottom points extended down into concrete because shapes are a pain
        
        this.beamColor = beamColor;
        this.girtColor = girtColor;
    }

    calculateBayDesigns(){
        let bayComponents;
        if(this.design.type === CORE.components.lew)
            bayComponents = this.design.getLeftEndBays();
        else if(this.design.type === CORE.components.rew)
            bayComponents = this.design.getRightEndBays();
        else {
            // tvps are sheeted on one side only, as of 4/15/2024
            if(this.design.sheetingLeft)
                bayComponents = this.design.getLeftEndBays();
            else
                bayComponents = this.design.getRightEndBays();
        }

        let leftMostBay, rightMostBay;

        if(bayComponents){
            leftMostBay = bayComponents.filter((b) => b.index === 0);
            if(leftMostBay.length>0)
                leftMostBay=leftMostBay[0];
            else
                leftMostBay=undefined;

            rightMostBay = bayComponents.filter((b) => b.index === this.design.columnPositions.length-2);
            if(rightMostBay.length>0)
                rightMostBay=rightMostBay[0];
            else
                rightMostBay=undefined;
        }

        MemHelper.removeAllChildren(this.groupBays);
        
        let bayComponentTypes = [];
        if(this.design.type === CORE.components.lew) 
            bayComponentTypes.push(CORE.components.bayEndLeft);
        else if(this.design.type === CORE.components.rew)
            bayComponentTypes.push(CORE.components.bayEndRight);
        else if (this.design.type === CORE.components.tvp){
            bayComponentTypes.push(CORE.components.bayEndLeft);
            bayComponentTypes.push(CORE.components.bayEndRight);
        }
        this.components = this.components.filter((c) => !bayComponentTypes.includes(c.design.type));

        this.design.components = this.design.components.filter((c) => !bayComponentTypes.includes(c.type));
        // use only the columns with a source of 'default' when defining bays (not columns from overhead or sliding doors)
        let defaultColumns = this.getDefaultColumnsFromArray(this.design.columnPositions); 
        


        defaultColumns.forEach((pos,i)=>{
            if(i == defaultColumns.length-1)
                return;

            let prevColumn = defaultColumns[i];
            let nextColumn = defaultColumns[i+1];
            let iBayDesign;
            if(i == 0 && leftMostBay)
                iBayDesign = leftMostBay
            else if(i === defaultColumns.length - 2 && rightMostBay)
                iBayDesign = rightMostBay;
            else
            {
                if(bayComponents){
                    iBayDesign = bayComponents.filter((b) => b.index == i);
                    if(iBayDesign.length>0)
                    iBayDesign=iBayDesign[0];
                    else
                    iBayDesign=undefined;
                }
            }

            if(Util.isUndefined(iBayDesign)){
                let newBayComponentType
                if(this.design.type === CORE.components.tvp)
                {
                    if(this.design.sheetingLeft)
                    newBayComponentType =  CORE.components.bayEndLeft;
                    else 
                    newBayComponentType =  CORE.components.bayEndRight;
                }                    
                else if (this.design.type === CORE.components.lew)
                    newBayComponentType =  CORE.components.bayEndLeft;
                else if (this.design.type === CORE.components.rew)
                    newBayComponentType =  CORE.components.bayEndRight;
                 
                iBayDesign = DesignHelper.getDefaultBayDesign(i, defaultColumns.length, newBayComponentType);
                this.SetBayDesignFromWall(this.design, iBayDesign);
                iBayDesign.skirted = false;
                DesignHelper.addComponent(this.design, iBayDesign)   
            } else {
                //this.SetBayDesignFromWall(this.design, iBayDesign); // we can't call SetBayDesignFromWall here because bay designs are customized by the end skirting tool
                iBayDesign.wainscot = {...this.design.wainscot}
                if(prevColumn.context == CORE.components.doorHangar && nextColumn.context == CORE.components.doorHangar){
                    iBayDesign.height =  ( this.maxHeight) - nextColumn.openHeight;
                    iBayDesign.openHeight = nextColumn.openHeight;
                } else if(prevColumn.context == CORE.components.doorHangar || nextColumn.context == CORE.components.doorHangar) {
                    iBayDesign.height =  this.maxHeight;
                    iBayDesign.openHeight = 0;
                }                   
                this.design.components.push(iBayDesign);
            }
            //let bay = this.buildBay(iBayDesign);
            //this.components.push(bay);
        });

    }
        
    addWallExteriorPanelOld(colorMap, alphaMap, bumpMap){

        // make three triangles            
        let  tris = this.getWallTris();

        let material = this.getExteriorPanelMaterial(colorMap, alphaMap, bumpMap);
        material.side = this.getExteriorSide()
        tris.forEach((tri)=>{
            let triMesh = new THREE.Mesh(tri.geometry, material);        
            triMesh.position.x += this.length/2;
            triMesh.position.z = .1;
            triMesh.layers.set(CORE.layers.walls);
            triMesh.name='wall '+ tri.name;
            triMesh.renderOrder = 1;
            this.gWall.add(triMesh);
        })
    }

    addWallExteriorPanel(){
        let  wall = new EarcutDataManager();
        let frontEaveHeight = this.maxHeight;        
        let backEaveHeight = this.maxHeight; // gabled
        let bottom = frontEaveHeight - this.design.height;        
        
        if(this.roofType === CORE.roof.types.gable){
            wall.setOutline([
                -this.length / 2, bottom,
                this.length / 2, bottom,
                
                this.length / 2, frontEaveHeight, // front eave
                0,this.ridgeHeight,
                -this.length / 2, frontEaveHeight, // back eave
            ]);
        }
        else{
            wall.setOutline([
                -this.length / 2, bottom,
                this.length / 2, bottom,
                this.length / 2, frontEaveHeight, // front eave
                -this.length / 2, this.ridgeHeight, // back eave
            ]);            
        }

        this.holes.forEach((h)=>{
            wall.addSquareHole(h.pos.x-this.length/2, h.pos.y, h.dim.width, h.dim.height);
        })
        let wallData = wall.generate();
            
        let frontMaterial = materialHelper.getExteriorPanelPbrMaterial(this.design.color, this.length);
        
        let front = SheetingHelper.defineFrontPlane(frontMaterial);
        front.wireframe = true;
        let sheeting = new Sheeting(
            CORE.preferences.des_levelOfDetail.value,
            wallData,
            this.getWallFrontPlane(),
            this.getWallBackPlane(),
            CORE.layers.walls);

        sheeting.group.position.x =this.length/2;
        sheeting.group.name = 'sheeting';
        if(this.buildFacingPositiveZ)
            sheeting.group.position.z = .1;
            else
            sheeting.group.position.z = -.1;
        this.gWall.add(sheeting.group);
    }

    addWallInterior(colorMap, alphaMap, bumpMap){
        let tris  = this.getWallTris();

        let material = this.getInteriorMaterial(colorMap, alphaMap, bumpMap);
        material.side = this.getInteriorSide()
        tris.forEach((tri)=>{
            let triMesh = new THREE.Mesh(tri.geometry, material);        
            triMesh.position.x += this.length/2;
            triMesh.position.z = -.1;
            triMesh.layers.set(CORE.layers.walls);
            triMesh.name='wall '+ tri.name;
            triMesh.renderOrder = .99;
            this.gWall.add(triMesh);
        })
    }

    
    getWallTris() {
        if(this.roofType === CORE.roof.types.gable)
            return this.getGableWallTris()
        else 
            return this.getSingleSlopeWallTris()
    }

    getSingleSlopeWallTris() {
        let frontEaveHeight = this.maxHeight;
        let backEaveHeight = this.ridgeHeight
        
        let frontHeightRatio = frontEaveHeight / this.ridgeHeight;
        let backHeightRatio = backEaveHeight / this.ridgeHeight;
        let bottom = frontEaveHeight - this.design.height;
        let bottomHeightRatio = bottom / this.ridgeHeight;
        let tris = [];
        let r = new Tri(
            'bottom right triangle',
            [
                new Vector3(-this.length / 2, bottom, 0),
                new Vector3(this.length / 2, bottom, 0),
                new Vector3(this.length / 2, frontEaveHeight, 0),
            ],
            [
                0.0, bottomHeightRatio,
                1.0, bottomHeightRatio,
                1.0, frontHeightRatio,
            ]
        );

        let l = new Tri(
            'middle left triangle',
            [
                new Vector3(-this.length / 2, bottom, 0),
                new Vector3(this.length / 2, frontEaveHeight, 0),
                new Vector3(-this.length / 2, frontEaveHeight, 0),
            ],
            [
                0.0, bottomHeightRatio,
                1.0, frontHeightRatio,
                0.0, frontHeightRatio
            ]
        );

        let t = new Tri(
            'gable triangle',
            [
                new Vector3(-this.length / 2, frontEaveHeight, 0),
                new Vector3(this.length / 2, frontEaveHeight, 0),
                new Vector3(-this.length / 2, this.ridgeHeight, 0), // gabled only
            ],
            [
                0.0, frontHeightRatio,
                1.0, frontHeightRatio,
                0.0, backHeightRatio,
            ]
        );
        tris.push(r);
        tris.push(l);
        tris.push(t);
        return tris;
    }

    getGableWallTris() {
        let frontEaveHeight = this.maxHeight;
        let backEaveHeight = this.maxHeight;
        
        let frontHeightRatio = frontEaveHeight / this.ridgeHeight;
        let backHeightRatio = backEaveHeight / this.ridgeHeight;
        let bottom = frontEaveHeight - this.design.height;
        let bottomHeightRatio = bottom / this.ridgeHeight;
        let tris = [];
        let r = new Tri(
            'bottom right triangle',
            [
                new Vector3(-this.length / 2, bottom, 0),
                new Vector3(this.length / 2, bottom, 0),
                new Vector3(this.length / 2, frontEaveHeight, 0),
            ],
            [
                0.0, bottomHeightRatio,
                1.0, bottomHeightRatio,
                1.0, frontHeightRatio,
            ]
        );

        let l = new Tri(
            'middle left triangle',
            [
                new Vector3(-this.length / 2, bottom, 0),
                new Vector3(this.length / 2, frontEaveHeight, 0),
                new Vector3(-this.length / 2, frontEaveHeight, 0),
            ],
            [
                0.0, bottomHeightRatio,
                1.0, frontHeightRatio,
                0.0, backHeightRatio
            ]
        );

        let t = new Tri(
            'gable triangle',
            [
                new Vector3(-this.length / 2, frontEaveHeight, 0),
                new Vector3(this.length / 2, frontEaveHeight, 0),
                new Vector3(0, this.ridgeHeight, 0), // gabled only
            ],
            [
                0.0, backHeightRatio,
                1.0, frontHeightRatio,
                0.5, 1.0,
            ]
        );
        tris.push(r);
        tris.push(l);
        tris.push(t);
        return tris;
    }




    doesSecondaryStructureCrossThisFrameline(){
        // is there anything joined to this structure
            // a porch or lean-to

        if(!this.masterDesign)
            return;
        let parentStructureId = this.design.parent.id
        let parentStructureDesign = this.masterDesign.getComponentDesignById(parentStructureId);
        // I can get my single main structure ancestor        
        let frontPorchContraints = ConstraintHelper.getSecondaryStructuresAttachedToStructureSide(CORE.components.porch, this.masterDesign, parentStructureDesign, CORE.sides.frontSide);         
        let backPorchConstraints = ConstraintHelper.getSecondaryStructuresAttachedToStructureSide(CORE.components.porch, this.masterDesign, parentStructureDesign, CORE.sides.backSide);
        
        // what's the location of me?
        // LEW can always return 0
        // REW can always return parent structure length
        // partition can check design.frameLine and cross reference that with the parent design
        let positionAlongFSW = this.getPositionAlongFSW();
        let positionAlongBSW = this.getPositionAlongBSW();

        let result = false;
        frontPorchContraints.forEach(porchConstraint => {
            if(result)
                return;
            // what's the start and end of the crossing porch or lean-to?    
            let porchDesign = this.masterDesign.getComponentDesignById(porchConstraint.child.structureID);
            let porchFrameDesign = porchDesign.getFrame();
            //let lengthOfPorch = StructureHelper.getLengthOfSide(frontPorchDesign, frontPorch.child.matingSide);
            let porchLength = 12*porchFrameDesign.length;
            let porchStart = porchConstraint.parent.length;
            let porchEnd = porchStart + porchLength;
            if(positionAlongFSW >= porchStart && positionAlongFSW <= porchEnd){
                result=true;
            }
        })

        backPorchConstraints.forEach(porchConstraint => {
            if(result)
                return;
            // what's the start and end of the crossing porch or lean-to?    
            let porchDesign = this.masterDesign.getComponentDesignById(porchConstraint.child.structureID);
            let porchFrameDesign = porchDesign.getFrame();
            //let lengthOfPorch = StructureHelper.getLengthOfSide(frontPorchDesign, frontPorch.child.matingSide);
            let porchLength = 12*porchFrameDesign.length;
            let porchStart = porchConstraint.parent.length;
            let porchEnd = porchStart + porchLength;
            if(positionAlongBSW >= porchStart && positionAlongBSW <= porchEnd){
                result=true;
            }
        })
        return result;
    }



    hasAdjoinedSecondaryStructure(){
        
        if(!this.masterDesign)
            return;
            let parentStructureId = this.design.parent.id
        let parentStructureDesign = this.masterDesign.getComponentDesignById(parentStructureId);        

        let extSide = this.getExteriorSide();
        let endSecondaryStructureConstraints = ConstraintHelper.getSecondaryStructuresAttachedToStructureSide(CORE.components.porch, this.masterDesign, parentStructureDesign, extSide);
        return endSecondaryStructureConstraints.length>0;
    }

    buildDynamicOnly(subComponentsToBuild, buildOpenings){
        this.z=0;
            this.initDynamicGroup()
            if(this.design.openWall===true)
                return;
                
            /////////////////////////            
            // imported logic from StructureBase.buildNewLew()

            let porchCrossesThisFrameline = this.doesSecondaryStructureCrossThisFrameline();

            this.design.lineType = CORE.frame.lineTypes.standard; // assume standard
            if(this.design.mainFrame === true) // if this wall must use main frames (which includes the LEW and REW only)
                this.design.lineType = CORE.frame.lineTypes.main; // use main
            else if(porchCrossesThisFrameline)
                this.design.lineType = CORE.frame.lineTypes.postAndBeam; // porches require more than a standard Cee Column, so use a post-and-beam type columns

            let porchAdjoinedToThisWall = this.hasAdjoinedSecondaryStructure();
            if(porchAdjoinedToThisWall)
            {
                this.design.lineType = CORE.frame.lineTypes.postAndBeam; 
                this.design.baySpacing.mode = CORE.modes.baySpacing.manual;
            }            
            ///////////////////////

            this.componentsNotOnWall=[];

            this.gWall = new THREE.Group();  
            this.gWall.name = 'gWall';
            let touchesFoundation = this.doesTouchFoundation();

            let hasHangar = this.design.getAllHangarDoors().length>0

            if(hasHangar) this.design.baySpacing.mode = CORE.modes.baySpacing.manual

            //let shapePoints= this.shapePoints;
            this.addWainscoting = this.supportsWainscot && this.design.wainscot.enabled && touchesFoundation;

            // bottom points of the wall are the first and the last.
            //let last = shapePoints.length-1;
            // by sinking wall/wainscot shapes down into the concrete by some small amount (<=1), wall sub-components can be holes or protrusions from the top, but never fully overlapping (which is harder to solve)
            let shapeInsetForWallComponents = .1 
            let bottomOfWallPosY = this.maxHeight -shapeInsetForWallComponents - this.design.height;
            //shapePoints[0].y = bottomOfWallPosY;
            //shapePoints[last].y = bottomOfWallPosY;

            // if unmigrated, dont build columns, calculatebaydesigns
            if(this.design.v === undefined || this.design.v === CORE.currentVersion){
                // this must be done before buildComponents, because buildComponents builds the bays
                this.design.columnPositions = this.locateColumns(); // sort out column locations (bays depends on these columns)

                // make sure column positions are always in ascending order.
                this.design.columnPositions.sort((a,b)=>{
                    if(a.pos < b.pos)
                        return -1;
                    else 
                        return 1;
                });
                this.design.columnPositions.forEach((cp,i)=>{
                    cp.index = i;
                });
            }
            else{
                // ignore all columns currently there
                // overwrite them 
                if(this.getDefaultColumnsFromArray(this.design.columnPositions).length < 2)
                    this.design.columnPositions = this.layoutColumnsOnWall();                
            }
            this.buildColumns(this.design.columnPositions);
            this.calculateBayDesigns(); // call this after columns have been located
            
            let framedOpenings = this.design.components.filter((c) => DesignHelper.allowedFramedOpeningsOnBays.includes(c.type));
            if(framedOpenings.length > 0)
                this.migrateFramedOpenings(framedOpenings);

            this.buildPickMesh();   
            this.holes = [];
            if(this.wallOpenings)
                this.holes.push(...this.wallOpenings);
            // build any components that might need a hole punched in the wall
            this.buildComponents(); // this punches holes in this.sh
            
        let girtGroup = new THREE.Group();
        girtGroup.name = "girt group"
        this.gDynamic.add(girtGroup);

        

        this.contextMenuObject = new THREE.Mesh()            
        this.contextMenuObject.position.set(this.wallMid, bottomOfWallPosY, 0);

        this.contextMenuObject.visible = false; // this 
        this.gWall.add(this.contextMenuObject);
        if(this.addToQuoteLayer)            
            layerHelper.enableLayer(this.gWall,CORE.layers.quote);
        
        
        // build some bays
        //this.buildBays();
    }

    removeDynamicOnly(){
        super.removeDynamicOnly();
        this.removeColumns()
    } 

    buildColumns(columnPoints){
        
        try{
            this.addColumnsAtPoints(columnPoints);
        }catch(error){
            console.error("error adding column points: ",error);
        }
    }

    hasHangarDoor() {
        return this.design.getAllHangarDoors().length > 0;
    }
    
    locateColumns(){
        let oldColumnPositions=[]
        // if there are existing column positions
        if(this.design.columnPositions)
            // hang on to these so the automatic columns have a frame of reference for what the bays looked like when a framedOpening was moved
            oldColumnPositions = Array.from(this.design.columnPositions);
        this.design.columnPositions = [];
        let subcomponents = this.design.getWallFramedOpenings();
        // these subcomponents are grandchildren (wall -> bay -> window)

        /*
        
        let columnPoints = [];
        const hangarDoor = this.hasHangarDoor();
        // if there's a hangar door
        if(hangarDoor){
            // calculate the hangar column points
            let hangarColumnPoints = this.getInterbaySubcomponentColumns(); // mainly for hangar doors
            let baySpacing = this.design.baySpacing.nonCenter*12;
            
            // handle any leftover on the left
            let leftLength = hangarColumnPoints[0].pos;            
            let leftColumnPositions = BlueprintHelper.divideLengthIntoSections(leftLength, baySpacing, {remnantLeft:true});

            // handle any leftover on the right
            let rightMostHangarPointPos = hangarColumnPoints.at(-1).pos;
            let rightLength = this.length-rightMostHangarPointPos
            let rightColumnPositions =  BlueprintHelper.divideLengthIntoSections(rightLength, baySpacing, {remnantRight:true});
            rightColumnPositions = rightColumnPositions.map(p => p + rightMostHangarPointPos);


            let componentColumnPoints = super.getIntrabaySubcomponentColumns(oldColumnPositions); // mainly for overhead doors
            columnPoints.push(...componentColumnPoints);

            if(this.design.baySpacing.mode === CORE.modes.baySpacing.manual){
                let leftColumnPoints = this.getManualPointsForPositions(leftColumnPositions)
                let rightColumnPoints = this.getManualPointsForPositions(rightColumnPositions)
                console.log("end wall hangar points")
                columnPoints.push(...leftColumnPoints);
                columnPoints.push(...hangarColumnPoints);
                columnPoints.push(...rightColumnPoints);
                columnPoints = this.sortColumnPoints(columnPoints)

            }
        }
        else
        {
            // no hangar door
            // divvy up the entire wall
            if(this.design.baySpacing.mode === CORE.modes.baySpacing.auto)
                this.buildAutomaticSupportColumns(subcomponents, oldColumnPositions); // CompGroup checks out ok sometimes and not others
            else
                this.buildManualSupportColumns(oldColumnPositions);
        }

        
        try{
            this.addColumnsAtPoints(columnPoints);
        }catch(error){
            console.error("error adding column points: ",error);
        }
        */

        let hasHangar = this.design.getAllHangarDoors().length > 0

        if(this.design.baySpacing.mode === CORE.modes.baySpacing.auto && !hasHangar)
            return this.buildAutomaticSupportColumns(subcomponents, oldColumnPositions); // CompGroup checks out ok sometimes and not others
        else
            return this.buildManualSupportColumns(oldColumnPositions);
    }


    getManualPointsForPositions(positions){
        let points = []
        positions.forEach((p)=>{
            points.push(
            {
                pos:p,
                required:true,
                source:'default' 
            });
        });
        return points;
    }


    removeColumns(){
        
        if(this.columns)
        this.columns.forEach((c)=>{
            c.remove();
        });

        if(this.privateColumns)
            this.privateColumns.forEach((c)=>{
                c.remove();
            })

        let columnsGroup = this.getColumnsGroup();
        if (columnsGroup)
            MemHelper.removeAllChildren(columnsGroup);
    }


    migrate(design){
        super.migrate(design);

        if(typeof design.baySpacing.mode === 'undefined')
            design.baySpacing.mode = CORE.modes.baySpacing.auto;

        switch(design.v)
        {
            case 1:
                //this.migrateTo2(design);
                break;
        }
    }

    migrateTo2(design){
        //super.migrateTo2(design);
        if(typeof design.openSupport === 'undefined')
            design.openSupport = CORE.endWallColumns.columnsToGround.value;

        
        design.components.forEach((c)=>{
                c.pos.x = c.pos.z;
                c.pos.z = 0.0;
        });
        design.v=2;
    }


    getRelativePosition(worldPosition){
        let wld = new Vector3().copy(worldPosition);
        let rel = this.group.worldToLocal(wld);
        return rel;
    }

    positionChildAt(design, worldPosition){
        // get this worldPosition in terms of this parent component
        let rel = this.getRelativePosition(worldPosition)
        // then use a static method that accepts the design to constrain the position to exactly fixed header height
        let compType = ComponentHelper.getClassOfComponentType(design.type);
        return compType.updatePosition(rel, design);
    }
    
    getOpenWallOption(){
        return OptionHelper.checkbox("openWall", "Open wall", impactTypes.wallOpen,
            true,
            undefined,
            undefined,
            (v)=>{
                this.design.openWall = v;
                if(this.design.openWall)
                    this.design.mainFrame = true;
                // update all bays on this wall
                let bays = []
                bays.push(...this.design.getLeftEndBays(true));
                bays.push(...this.design.getRightEndBays(true));                
                if(bays)
                    bays.forEach((b)=>{
                        b.openWall = this.design.openWall;
                });

                this.designChange();                
            })
    }
    
    getOptionsSpec(){
        let spec = []

        
        let leftEndMainFrame = OptionHelper.checkbox("mainFrame", "Main Frame", impactTypes.structureMainFrame,
        !this.design.openWall,
        ()=>{ // fnIn
            return this.design.mainFrame;
        },
        undefined,
        (v)=>{
            this.design.mainFrame = v;
        });
        leftEndMainFrame.procOrder = 1; // leftEnd.openBays can overwrite this value, so this needs to occur first


        let openSupport =  OptionHelper.selection("openSupport","Open Area Column", impactTypes.wallColumnOption, undefined, [
            {
                value: CORE.endWallColumns.columnsToGround.value,
                text:  CORE.endWallColumns.columnsToGround.name
            },
            {
                value: CORE.endWallColumns.columnsToHeader.value,
                text:  CORE.endWallColumns.columnsToHeader.name
            },
            {
                value: CORE.endWallColumns.backBrace.value,
                text:  CORE.endWallColumns.backBrace.name
            },
        ],
        this.maxHeight - this.design.height > 0,
        //this.design.openHeight > 0,
        ()=>{//fnIn
            return this.design.openSupport;
        },
        undefined,
        (v)=>{//fnChanged
            this.design.openSupport = v;
        }
        );

        
        //let hasLeftEndPorch = true; //  this is temporary dev code only. If this is in production, it is a defect. CBH 4/15/2024
        let porchAdjoinedToThisWall = this.hasAdjoinedSecondaryStructure();
            

        
        let bsMode = OptionHelper.switch("baySpacing.mode", "Mode", "Columns positions can be manually controlled or automatically determined. Note: Currently, a Hangar Door requires manual mode.", "Auto.", "Manual", impactTypes.structureBaySpacing,
            undefined, // colors
            undefined, // colors
            ()=>{ //fnEnabled       
                // partition really should never be opened, but in case it can         
                if(Util.isDefined(this.design.openWall) && this.design.openWall === true)
                    return false;

                // if there's a porch, that forces bay spacing mode to manual
                return !porchAdjoinedToThisWall;
            },
            ()=>{ //fnIn
                //true => manual
                // false => auto
                return this.design.baySpacing.mode === CORE.modes.baySpacing.manual
            },
            (v)=>{ //fnOut                
                    return v?CORE.modes.baySpacing.manual:CORE.modes.baySpacing.auto;
            },
            (v)=>{ //fnChanged
                this.design.baySpacing.mode = v;//?CORE.modes.baySpacing.auto:CORE.modes.baySpacing.manual;
            });
            let bsCenter = OptionHelper.numericUpDown("baySpacing.center", "Center Spacing", impactTypes.structureBaySpacing, "ft.", 0, 27, 1,!this.design.openWall);
            let bsNonCenter = OptionHelper.numericUpDown("baySpacing.nonCenter", "Max. Spacing", impactTypes.structureBaySpacing, "ft.", 5, 27, 1,
            !this.design.openWall,

            undefined,
            (v)=>{ // fnOut
                if(v<5)
                    v=5;
                if(v>27)
                    v=27;
                return v;
            });
            
        spec.push(...super.getOptionsSpec());
        
        spec.splice(2,0, bsMode)
        spec.splice(3,0, bsCenter)
        spec.splice(4,0, bsNonCenter)
        spec.splice(2,0, OptionHelper.header('Bay Spacing'));
        spec.splice(2,0, openSupport)

        spec.splice(3,0, leftEndMainFrame);
        

        return spec;
    }

    static canEdit(){return true;}

    
    getCollisionClass(){
        return [CORE.collisions.classes.none];
    }

    
    
    isOnWall(cDesign){
        
        let val = (
            (cDesign.pos.x + cDesign.dim.width / 2) < this.length &&
            (cDesign.pos.x - cDesign.dim.width / 2) > 0
            );
            return val;
    }

    
    getExtrudedMesh(points, material){
        let mesh = ShapeHelper.getMeshFromPoints( 
            points,        
        this.extrusionSettings,
        material );        
        mesh.layers.set(CORE.layers.frame);
        mesh.castShadow=true;
        mesh.receiveShadow=true;
        return mesh;
    }


    getInterbaySubcomponentColumns(){
        // openings that affect multiple bays (these are direct children components)
        let columnPoints = [];
        let supportMargin = 2;
        let interbayOpenings = [];
        interbayOpenings.push(...this.design.getAllHangarDoors());
        //interbayOpenings.push(...this.design.getAllWideOpenings());
        
        // lay out the columns for this hangar door
        // left
        // right
        // center bay spacing to control the maximum spacing for columns
        // 
        
        interbayOpenings.forEach((o)=>{
            
            let rightColumnPosX = (o.pos.x + o.dim.width/2)  + supportMargin;

            columnPoints.push({
                required: true,
                context: CORE.components.doorHangar,
                source: 'default',//CORE.components.doorHangar,   // we're including type (or purpose) information so that we can remove a redundant column serving an unnecessary purpose.
                side: 'right',
                openHeight: o.dim.height,
                pos: rightColumnPosX
            });
            

            let leftColumnPosX = (o.pos.x - o.dim.width/2) - supportMargin;

            columnPoints.push({
                required: true,
                context: CORE.components.doorHangar,
                source: 'default',//CORE.components.doorHangar,
                side: 'left',
                openHeight: o.dim.height,
                pos: leftColumnPosX
            });

            // length of the door
            let width = o.dim.width + supportMargin*2;
            let maxSpacing = 6*12;

            let equalSizeBayCount = Math.floor(width/maxSpacing);
            let equalSizeBaySpacing = maxSpacing;// width/equalSizeBayCount
            let specialEndBaySpacing = (width-(equalSizeBayCount*equalSizeBaySpacing))/2
            
        // 18' ft => 216 inches
        // but the column (centers) are actuall 2" to the left and right of that, so C-to-C width is 2+216+2 = 220
        // if you attempt to divide that into 6' segments, you get 220"/72" = 3.055 repeating.
        // florring, you get 3x 72" segments, plus 2x 2" segments as leftovers we don't want less than a 6" segment left over.
        // the left and the right can grow up to 6"

        // thus, 
            if(width<=84){
                equalSizeBayCount=1;
                equalSizeBaySpacing = width;
                specialEndBaySpacing=0;
            }
            else
            if(specialEndBaySpacing<6){
                // distribtue the left-over into the remainders
                equalSizeBayCount-=2;
                specialEndBaySpacing+= maxSpacing;
            }
            
            let currPos = leftColumnPosX;
            if(specialEndBaySpacing >= 6)
            {
                currPos+=specialEndBaySpacing
                columnPoints.push({
                    required: true,
                    context: CORE.components.doorHangar,                
                    source: 'default',
                    side: 'mid',
                    openHeight: o.dim.height,
                    pos: currPos
                });
            }
            
            for(let iBay = 1;iBay<=equalSizeBayCount;iBay++){
                
            currPos+=equalSizeBaySpacing;
            columnPoints.push({
                required: true,
                context: CORE.components.doorHangar,                
                source: 'default',
                side: 'mid',
                openHeight: o.dim.height,
                pos: currPos 
            });
            }

            if(specialEndBaySpacing >= 6)
            {
                currPos+=specialEndBaySpacing;
                if (currPos != rightColumnPosX)
                    columnPoints.push({
                        required: true,
                        context: CORE.components.doorHangar,                
                        source: 'default',
                        side: 'mid',
                        openHeight: o.dim.height,
                        pos: currPos
                    });
            }

        });
        columnPoints.sort((a, b) => a.pos- b.pos); // make sure columns are in ascending position order L-to-R
        return columnPoints;

    }

    buildAutomaticSupportColumns(subcomponents, oldColumnPositions){
        // 2024.03.06
        // now that walls have bays that have openings, automatic columns are more complicated
        // 
        // new bays depend on where the column locations
        // column locations depend on where the openings are
        // openings are relative to the old bays
        this.columns = [];
        this.privateColumns = [];
        let columnPoints = [];
        
        // put columns on both sides of each overhead, open sides facing out.
        // use the old column positions to know where the old bays start, since framed openings are relative to the bays        
        let componentColumnPoints = super.getIntrabaySubcomponentColumns(oldColumnPositions);
        columnPoints.push(...componentColumnPoints);
        // TODO: centralize and/or correct this static 4 from standard flange
        // TODO: why is this full flange, but called halfColumnWidth?
        this.layoutColumnsOnWall(columnPoints)
        // we have all the default columns in order and the required end columns flagged as required.
        let endWallBayOpenings = [];
        // use the old columns and use only the columns with a source of 'default' since those are not being added due to openings like overhead or sliding doors
        let oldDefaultColumns = this.getDefaultColumnsFromArray(oldColumnPositions);

        subcomponents.forEach((compDes)=>{
            let bayId = compDes.parent.id;
            let bayDesign = this.design.getChildDesign(bayId);

                
            let index = bayDesign.index;
            
            //let start = oldDefaultColumns[index];
            //let end = oldDefaultColumns[index+1];
            let start = this.getStartOfBayAtIndex(oldDefaultColumns, index)
            let end = this.getEndOfBayAtIndex(oldDefaultColumns, index)

            let opening = {
                dim:{
                    width: compDes.dim.width,
                    height: compDes.dim.height,
                },
                pos:{
                    x: compDes.pos.x + start.pos,
                    y: compDes.pos.y,
                }
            }
            endWallBayOpenings.push(opening);
        });

        // if the opening's left end is before the default column's start (or right end beyond default column's end)
        // adjust the location of that column and the window position

        let corrections = this.getCorrections(endWallBayOpenings, columnPoints)
        if(corrections.length> 0){
            this.applyCorrections(columnPoints, corrections);
        }
       
        return columnPoints;
    }

    layoutColumnsOnWall(columnPoints = []){
        let halfColumnWidth = BlueprintHelper.getStandardFrameMaterialSize().flange;
        let defaultColumnPoints = BlueprintHelper.generateColumnPositions2(0, this.length, this.design.baySpacing.center, this.design.baySpacing.nonCenter, halfColumnWidth); //TODO: the halfColumnWidth parameter needs to be context sensitive
        defaultColumnPoints.forEach((dp)=>{
            columnPoints.push({
                required: false,
                source: 'default',
                pos: dp
            });
        })

        columnPoints.sort((a,b)=>{
            if(a.pos < b.pos)
                return -1;
            else 
                return 1;
        });

        columnPoints[0].required=true;
        columnPoints[columnPoints.length-1].required=true;

        return columnPoints;
    }

    removeRedundantColumns(columnPoints){
        let redundantThreshold = 3*12; // consider columns less than this far apart from one-another, redundant.
        let redundantColumns = [];        
        let lastPoint;
        for(var iCol = 0;iCol<columnPoints.length;iCol++){
            let cp = columnPoints[iCol];
            if(iCol === 0)
            {
                lastPoint = cp;
                continue;
            }
            
            let delta = cp.pos - lastPoint.pos ;
            // if they're too close
            if(delta <= redundantThreshold ){
                /* scenarios
                    required against default => remove default
                    default against default => merge defaults into 1
                */

                if(cp.required === false &&  lastPoint.required === false){                    
                    redundantColumns.push(iCol-1); // remove the older one so lastPoint isn't weird to understand
                    cp.pos = (cp.pos + lastPoint.pos) / 2; // merge
                    lastPoint = cp;
                    continue;
                }

                if(cp.required === false && lastPoint.required === true){
                    redundantColumns.push(iCol); // remove unrequired
                    // leave last point alone
                    continue;
                }

                if(cp.required === true && lastPoint.required === false){
                    redundantColumns.push(iCol-1); // remove unrequired
                    lastPoint = cp;
                    continue;
                }
            }
            else{
                lastPoint = cp;
            }
        }

        redundantColumns.sort((a,b) => {return b-a});
        redundantColumns.forEach((r)=>{
            columnPoints.splice(r,1);
        })
    }

    applyCorrections(columnPoints, corrections){
        corrections.forEach((corr)=>{
            if(corr.delete===true)
                // we'll come back for these mutations
                return;

            if(corr.correction){
                columnPoints[corr.iCol].pos += corr.correction;
            }
        });

        corrections.sort((a,b)=>{return b.iCol-a.iCol}); // sort descending so that indices are preserved during deletion
        // delete after other mutations in order to preserve indices of other mutations
        corrections.forEach((corr)=>{
            if(corr.delete===true)
                columnPoints.splice(corr.iCol, 1);
        });
    }

    getCorrections(subcomponents, columnPoints){
        let conflicts = [];
        let halfColumnWidth = BlueprintHelper.getStandardFrameMaterialSize().flange;
        // identify conflicts and attempt to resolve them by moving a column in/out first (then out/in), and re-check
        subcomponents.forEach((desC, iComp)=>{

            let compMin,compMid, compMax;
            compMin = desC.pos.x-desC.dim.width/2;
            compMid = desC.pos.x;
            compMax = desC.pos.x+desC.dim.width/2;

            columnPoints.forEach((col, iCol)=>{
                if(col.required === true)
                    return;
                let colMax = col.pos+halfColumnWidth;
                let colMin = col.pos-halfColumnWidth;
/*
                if (col.source === 'default' &&
                    colMax > compMin && 
                    colMin < compMax)
                    conflicts.push({
                        iComp: iComp,
                        iCol: iCol,
                        delete: true
                    });
                else*/
                // detect collisions
                if(colMax > compMin &&
                    colMin <= compMid){                    
                    conflicts.push({
                        iComp: iComp,
                        iCol: iCol,
                        correction: compMin - colMax
                    });
                }
                else
                if(colMin < compMax &&
                    colMax >= compMid){
                    conflicts.push({
                        iComp: iComp,
                        iCol: iCol,
                        correction: compMax - colMin
                    });
                }            
            })
        });
        return conflicts;
    }

    buildManualSupportColumns(oldColumnPositions){
        this.columns = [];
        this.privateColumns = [];
        let columnPoints = [];
        
        let hangarColumnPoints = this.getInterbaySubcomponentColumns(); // mainly for overhead doors
        columnPoints.push(...hangarColumnPoints);
        let componentColumnPoints = super.getIntrabaySubcomponentColumns(oldColumnPositions); // mainly for overhead doors
        columnPoints.push(...componentColumnPoints);
        // build wall columns here

        let halfColumnWidth = BlueprintHelper.getStandardFrameMaterialSize().flange;
        
        let points
        if (hangarColumnPoints.length > 0) 
            points = this.generateColumnPositionsAroundHangar(0, this.length, hangarColumnPoints, this.design.baySpacing.nonCenter, halfColumnWidth)
        else
            points = BlueprintHelper.generateColumnPositions2(0, this.length, this.design.baySpacing.center, this.design.baySpacing.nonCenter, halfColumnWidth); //TODO: the halfColumnWidth parameter needs to be context sensitive
        
        points.forEach((p)=>{
            columnPoints.push(
                {
                    pos:p,
                    required:true,
                    source:'default' 
                }
            )
        });

        // sort the column positions in ascending order so the first and last can be tossed out
        columnPoints = this.sortColumnPoints(columnPoints);
        return columnPoints;
    }

    sortColumnPoints(columnPoints){
        columnPoints.sort((a,b)=>{
            if(a.pos < b.pos)
                return -1;
            else 
                return 1;
        });

        return columnPoints;
    }

    generateColumnPositionsAroundHangar(start, end, hangarColumnPoints, nonCenterSpacing, columnHalfWidth) {
        
        hangarColumnPoints.sort((a, b) => a.pos- b.pos); // make sure columns are in ascending position order L-to-R
        let HangarLeftColX = hangarColumnPoints[0].pos; // alternatively
        let HangarRightColX = hangarColumnPoints[hangarColumnPoints.length-1].pos;
        let hangarWidth = HangarRightColX - HangarLeftColX;

        let nonCenterSpacingIn=Util.Convert.ftToIn(nonCenterSpacing);
        
        let length = end-start;
        let posStart = start + columnHalfWidth;
        let posEnd = length - columnHalfWidth;        
        let lengthCtoC = posEnd - posStart;
        let points = [];

        let nonCenterLeftWidth = HangarLeftColX - posStart;
        let nonCenterRightWidth = posEnd - HangarRightColX;

        if(nonCenterLeftWidth < 0)
            nonCenterLeftWidth = 0;
        if(nonCenterRightWidth < 0)
            nonCenterRightWidth = 0;

        let numLeftNonCenterBays = Math.floor(nonCenterLeftWidth/nonCenterSpacingIn);
        let leftwidthRemanant = nonCenterLeftWidth % nonCenterSpacingIn;

        let numRightNonCenterBays = Math.floor(nonCenterRightWidth/nonCenterSpacingIn);
        let rightwidthRemanant = nonCenterRightWidth % nonCenterSpacingIn;

        let point = posStart; // start on the Left end of the side
        //there will always be this left-most frame-line
        points.push(point);// left end
        if(leftwidthRemanant!==0)
        {
            point+=leftwidthRemanant;            
            if (point != HangarLeftColX)
                points.push(point);
        }
        
        // left non-center bays
        for(let b=0;b<numLeftNonCenterBays;b++)
        {
            if(point<0) continue;
            point+=nonCenterSpacingIn;// 
            if (point === HangarLeftColX) continue; // there's already a column at this point
            points.push(point);
        }

        point+= hangarWidth;

        // right non-center bays
        for(let b=0;b<numRightNonCenterBays;b++)
        {
            if(point<0) continue;
            point+=nonCenterSpacingIn;// 
            if (point === HangarRightColX) continue; // there's already a column at this point
            points.push(point);
        }

        if(rightwidthRemanant!==0)
        {
            point+=rightwidthRemanant;            
            points.push(point);
        }
        
        return points;
    }

    addColumnsAtPoints(columnPoints){
        console.log("endwall column points", columnPoints)
        let collisionZones;
        
        for(var ci = 0;ci<columnPoints.length;ci++){
            
            let columnPoint = columnPoints[ci];
            let columnPosAlongWall = columnPoint.pos
            
            let side = columnPoint.side;
            let source = columnPoint.source;

            columnPoint.atSideWall = (ci === 0 || ci === columnPoints.length-1)
            
            // columns purely for overhead doors and windows are not structural (i.e. a porch can't tie into them)
            columnPoint.structural = (source !== CORE.components.doorRollup && 
                                    source !== CORE.components.window && source !== CORE.components.louverVent && source !== CORE.components.emptyFramedOpening)

            // record all columnPositions for sharing with sub-components of the corresponding frameSide (i.e. porches)
            //this.design.columnPositions.push(columnPoint);

            // skip the outermost columns at the sideWall
            // we skip instead of remove them because these position are used by frameSides to structurally tie in porches to existing columns.
            if(columnPoint.atSideWall)
                // outside columns (at front side wall or back side wall) are always built by frameBase 
                                
                continue;

            // intermediate columns are always built by the wall
            if(this.design.baySpacing.mode === CORE.modes.baySpacing.auto)
                collisionZones = ci === 0 || ci === columnPoints.length-1;
            else
                collisionZones=columnPoint.source==='default' && columnPoint.context != CORE.components.doorHangar; 

            let z= -4.5 //-this.frameLinePos // this is an absolute z
            if(!this.buildFacingPositiveZ)
                z = 4.5;

            let bottomPos = new THREE.Vector3(columnPosAlongWall,0, z);
            if(columnPoint.openHeight && columnPoint.side == 'mid'){ // for HangarDoors
                bottomPos.y = columnPoint.openHeight;
            }
            let y= BlueprintHelper.getColumnHeightAlongFrameLine(columnPosAlongWall - this.length/2, this.length, BlueprintHelper.height_FrontEaveTop(this.maxHeight), this.pitchRatio, this.roofType);            
            let topPos = new THREE.Vector3(columnPosAlongWall, y, z);
            let vertLengthY = topPos.y - bottomPos.y;
            switch(this.design.openSupport){
                case CORE.endWallColumns.columnsToGround.value:
                    vertLengthY = (topPos.y - bottomPos.y)-7;
                    break;
                case CORE.endWallColumns.columnsToHeader.value:
                case CORE.endWallColumns.backBrace.value:
                    vertLengthY = (topPos.y - this.design.openHeight)-7; // this.design.openHeight is 0 for leftSkirt here, this.design.height is 36 (openHeight should be )
                    bottomPos.y +=this.design.openHeight;
                    break;
            }
            

            let rot =0;
            let col;
            if(source === CORE.components.doorRollup){    // sliding doors work under this source as sourced under this as well
                
                rot = 0; // open to the right
                if(side === 'right')
                    rot = -Math.PI; // open to the left
                
                col = new _3dColumnC(CORE.preferences.des_levelOfDetail.value, vertLengthY, false, BlueprintHelper.getStandardFrameMaterialSize(), this.girtColor);                                
                col.group.position.copy(bottomPos.clone());
                col.group.rotation.y = rot;
                this.getColumnsGroup().add(col.group);
                layerHelper.enableLayer(col.group, CORE.layers.quote);
                //this.columns.push(col);
            }
            else{
                
                // calculate the distance between this wall's frameline and the next interior frameline
                

                // 
                if(this.design.lineType === CORE.frame.lineTypes.standard)
                {
                    rot = 0; // open to the right
                    // Left end collision zones do not need to be rotated 
                    // if(columnPosAlongWall>this.length/2)
                    //     rot = -Math.PI; // open to the left
                    
                    // All right end collision zones need to be rotated 180 degrees
                    if(this.design.typeDetail === CORE.sides.rightEnd)
                        rot+=Math.PI;

                    let xp = 0; // interior Z-axis
                    let xn = .5; // exterior Z -axis

                    if(collisionZones){

                        let margin = CollisionHelper.getMargin(xp,xn,0,0,0,0);
                        if(rot>=0)
                            margin.zp = 6;
                        else
                            margin.zn = 6;

                        let standardFrameLineMaterialDim = BlueprintHelper.getStandardFrameMaterialSize();
                        collisionZones = {
                            dim: new Vector3(standardFrameLineMaterialDim.flange, vertLengthY, standardFrameLineMaterialDim.depth),
                            margin,
                            position: new Vector3(0,vertLengthY/2,0)
                        }
                    }

                    //console.log(bottomPos);
                    col = new _3dColumnC(CORE.preferences.des_levelOfDetail.value, vertLengthY, collisionZones, BlueprintHelper.getStandardFrameMaterialSize(), this.girtColor);                    
                    col.group.position.copy(bottomPos.clone());
                    col.group.rotation.y = rot;
                    this.getColumnsGroup().add(col.group);
                    if(this.addToQuoteLayer)
                        layerHelper.enableLayer(col.group, CORE.layers.quote);
                    //this.columns.push(col);
                    if(this.design.openSupport === CORE.endWallColumns.backBrace.value){
                        
                        //length depends on the height of the wall, position of the column, and the distance to the next frameline
                        /*

                        |\
            vertLengthY | \ braceLength
                        |__\
                         Dist

                         vertLengthY is already known (top.Y - bottom.Y or top.Y - openHeight)
                         top.y  based on column position
                         vertLengthY is top.y, adjusted for open height to know total vertical run
                         solve for H

                         Solve for angle:
                         sohcahtoa
                         tan(theta) = o/a
                         atan(o/a) = theta
                         cos(theta) = a/h
                        */

                         let backBraceAngleRadians = Math.atan(vertLengthY/this.distToNextFrameline);
                         let braceLength = this.distToNextFrameline / Math.cos(backBraceAngleRadians);
                         let columnSquareFrameSideLength = 4;
                         let frameMaterial
                         if(CORE.preferences.des_levelOfDetail.value === CORE.levelOfDetail.high)
                            frameMaterial=materialHelper.getSquareColumnFrameMaterial_HD(braceLength, columnSquareFrameSideLength, this.girtColor);
                        else
                            frameMaterial=materialHelper.getFrameMaterial_LD(this.getBeamColor());
                

                         let brace = new _3dColumnSquare(braceLength, frameMaterial, columnSquareFrameSideLength, CORE.layers.frame ); // only ever square, per Adam on 2021.09.09 via Twist
                         let bracePos = new Vector3().copy(bottomPos);
                         bracePos.y+=3;
                         bracePos.z-=columnSquareFrameSideLength/2;
                         brace.group.position.copy(bracePos.clone())
                         this.getColumnsGroup().add(brace.group);
                         if(this.addToQuoteLayer)
                            layerHelper.enableLayer(brace.group, CORE.layers.quote);
                         brace.group.rotation.x = backBraceAngleRadians - Math.PI/2;
                         
                         if(this.design.typeDetail == CORE.sides.rightEnd)
                            brace.group.rotateY(Math.PI);
                         
                         //this.privateColumns.push(brace);
                    }
                }
                else{
                    // calculate column position
                    // calculate column rotation
                    // endWall can only be left or right end. No need to support the back side.

                    let czOffsetX = 5; // a offset to push the collsion zone out so that it collides with wall opening component zones.
                    let czOffsetZ = -BlueprintHelper.getPostAndBeamFrameMaterialSize().depth/2 // an offset to center the zone in the column depth (since the depth faces out, not the flange)
                    if(this.design.typeDetail === CORE.sides.leftEnd){
                        rot = -Math.PI/2;
                    }
                    else{
                        rot = -Math.PI/2;
                    }

                    if(Math.abs(bottomPos.x) > this.length/2){
                        rot *= -1
                        czOffsetX *= -1;                        
                    }
                    
                    
                    let length = (topPos.y - bottomPos.y)-14;
                    let postAndBeamFrameLineDim = BlueprintHelper.getPostAndBeamFrameMaterialSize();

                    let collisionZoneDetails = collisionZones;
                    if(collisionZoneDetails){
                        
                        let xp = 0;
                        let xn = 0;
                        let zp = .5;
                        let zn = .5;
    
                        let margin = CollisionHelper.getMargin(xp,xn,0,0,zp,zn);

                        
                        collisionZoneDetails = {
                            dim: new Vector3(postAndBeamFrameLineDim.flange, length, postAndBeamFrameLineDim.depth),
                            margin,
                            position: new Vector3(czOffsetX,length/2,czOffsetZ)
                        }
                    }
                    
                    let bugValue = 8;
                    col = new _3dColumnStraight(CORE.preferences.des_levelOfDetail.value,  length, true, collisionZoneDetails, postAndBeamFrameLineDim, bugValue, this.pitchRadians, this.frameType === CORE.frame.types.bolt, this.beamColor);

                    //col.build( bottomPos, rot)
                    col.group.position.copy(bottomPos.clone())
                    col.group.rotation.y =rot

                    this.gDynamic.add(col.group);
                    //this.getColumnsGroup.add(col.group);
                    layerHelper.enableLayer(col.group, CORE.layers.quote);
                    //this.columns.push(col);
                    if(this.design.openSupport === CORE.endWallColumns.backBrace.value){
                        let backBraceAngleRadians = Math.atan(vertLengthY/this.distToNextFrameline);
                         let braceLength = this.distToNextFrameline / Math.cos(backBraceAngleRadians);
                         let columnSquareFrameSideLength = 4;
                         let frameMaterial
                         if(CORE.preferences.des_levelOfDetail.value === CORE.levelOfDetail.high)
                            frameMaterial=materialHelper.getSquareColumnFrameMaterial_HD(braceLength, columnSquareFrameSideLength, this.girtColor);
                        else
                            frameMaterial=materialHelper.getFrameMaterial_LD(this.getBeamColor());
                


                         let brace = new _3dColumnSquare( braceLength, frameMaterial, columnSquareFrameSideLength, CORE.layers.frame);
                         let bracePos = new Vector3().copy(bottomPos);
                         bracePos.y+=3;
                         bracePos.z-=columnSquareFrameSideLength/2;
                         brace.group.position.copy(bracePos.clone())
                         this.getColumnsGroup().add(brace.group);
                         layerHelper.enableLayer(brace.group, CORE.layers.quote);
                         brace.group.rotation.x = backBraceAngleRadians - Math.PI/2;
                         //this.privateColumns.push(brace);
                    }                
                }
            }            
        }
    }

    buildDripTrim(z){

        if(this.design.frameLine !== 0)
            return;
        super.buildDripTrim(z);
    }


    remove(){
        super.remove();        
        this.removeColumns();
    }


    getTypeDisplayName(){return this.getDescription();}
    
}
