import { CORE,impactTypes  } from '../_spec';
import * as THREE from 'three';
import BayEnd from './BayEnd.js';
import BlueprintHelper from '../helpers/blueprintHelper.js'
import _3dDistHori from '../3d/DistHori.js'
import { Quaternion, Vector3 } from 'three';
import SheetingHelper from '../helpers/SheetingHelper';
import materialHelper from '../helpers/materialHelper';
import Wall_End from './Wall_End';
import Util from '../utility';
import BayEndRight from './BayEndRight.js';
import DesignHelper from '../helpers/DesignHelper.js';

export default class REW extends Wall_End {
    constructor(
        masterDesign,
        des,
        structureConfig, 
        length,        
        maxHeight,
        trimMaterials,
        cbDesignChange,
        girtHeights,
        supportsWainscot,
        footerCollisions,
        addToQuoteLayer,
        allowDripTrim,
        insulation,
        pitchRatio,
        roofType,
        frameType,
        distToNextFrameline,
        stopFrameLine,
        beamColor,
        girtColor,
        options,
        shedHoles) {            
        des.type = CORE.components.rew;
        
        //let pitchHeight = BlueprintHelper.pitchHeight(length,pitchRatio, roofType);
        //let shapePoints = BlueprintHelper.generatePointsWithOffset(length, maxHeight, roofType, pitchHeight, 0, length/2)

        super(masterDesign,des, structureConfig,length, maxHeight, false, trimMaterials, 
            //shapePoints,
              cbDesignChange, girtHeights,
              //pos,rot,
               CORE.sides.rightEnd, supportsWainscot, footerCollisions, addToQuoteLayer,allowDripTrim,insulation,
            pitchRatio,
            roofType,
            frameType,
            distToNextFrameline,
            stopFrameLine, beamColor, girtColor, options,shedHoles)

        this.groupBays = new THREE.Group();
        this.groupBays.name = "bays";
        this.group.add(this.groupBays);
        
        this.build();
    }

    getExteriorSide(){
        return CORE.sides.rightEnd;
    }
    
    getPositionAlongFSW(){        
        if(!this.masterDesign)
            return;
        let parentStructureId = this.design.parent.id
        let parentStructureDesign = this.masterDesign.getComponentDesignById(parentStructureId);
        let parentStructureFrameDesign = parentStructureDesign.getFrame();
        return parentStructureFrameDesign.length;
    }

    getPositionAlongBSW(){
        return 0;
    }
 
    defaultDesign(){
        return {
            type: CORE.components.rew,
            wainscot:{
                enabled: true,
                height: 36,
                color: '0x8f8f8f'
            },
            lineType: CORE.frame.lineTypes.standard,
            openHeight:0,
            
        }
    }

    getStartOfBayAtIndex(columns, index)
    {
        let length = columns.length-1;
        return columns[length-index-1];
    }

    getEndOfBayAtIndex(columns, index){
        let length = columns.length-1;
        return columns[length-index];
    }
    
    
    buildBay(bayDesign){

        let index = bayDesign.index;

        let defaultColumns = this.getDefaultColumnsFromArray(this.design.columnPositions);
        let length = defaultColumns.length-1;
        
        //https://docs.google.com/document/d/10Q_liy4drSNUn6yEGMfMjmER5yno60vej_sJTs6JJwk/edit#heading=h.da8egom1xdxz
        //let start = defaultColumns[length-index-1];
        //let end = defaultColumns[length-index];
        let start = this.getStartOfBayAtIndex(defaultColumns, index).pos
        let end = this.getEndOfBayAtIndex(defaultColumns, index).pos

        // first and last column are offset by 4, so recorrect it
        if(index == 0)
            end += 4;
        if(index == defaultColumns.length - 2)
            start -= 4;

        let bayLength = end - start;

        let bayHoles = this.convertWallHoleToBayHoles(this.wallOpenings, start, end);

        let bay = new BayEndRight(bayDesign, 
            this.structureConfig,
            start,
            end,
            bayLength,
            this.length,
            this.maxHeight,
            this.trimMaterials,
            undefined,
            this.girtHeights,
            new Vector3(start,0,0), 
            0, 
            this.supportsWainscot, 
            this.footerCollisions, 
            this.addToQuoteLayer,
            this.allowDripTrim,
            this.insulation,
            this.pitchRatio,
            this.roofType,
            this.frameType,
            this.distToNextFrameline,
            0,
            this.beamColor,
            this.girtColor,
            this.options,
            bayHoles
            );
    
            //this.group.add(bay.group);
            bay.group.position.x = start
            this.groupBays.add(bay.group);
            return bay;
    }

    getWallBackPlane(){
        return SheetingHelper.defineBackPlane(materialHelper.getInsulationMaterial(0xEEEEEE, this.length, CORE.textures.insulation));
    }

    getWallFrontPlane(){
        return SheetingHelper.defineFrontPlane(materialHelper.getExteriorPanelPbrMaterial(this.design.color,this.length));
    }

    getWainscotBackPlane(){
        return SheetingHelper.defineBackPlane(materialHelper.getInteriorPanelPbrMaterial(0xEEEEEE, this.length));
    }

    getWainscotFrontPlane(){
        return SheetingHelper.defineFrontPlane(materialHelper.getExteriorPanelPbrMaterial(this.design.wainscot.color,this.length));
    }
    
    getInteriorSide(){
        return THREE.BackSide
    }
    
    migrate(design){
        super.migrate(design);

        switch(design.v){
            case 1:
                //this.migrateTo2(design);
            break;
        }
        
    }

    migrateFramedOpenings(framedOpenings){
        //let framedOpenings = this.design.components.filter((c) => DesignHelper.allowedFramedOpeningsOnBays.includes(c.type));
        let defaultColumns = this.getDefaultColumnsFromArray(this.design.columnPositions);
        let bayDetails = [];
        this.design.getRightEndBays().forEach((bay, index)=>{
            let start = this.getStartOfBayAtIndex(defaultColumns, index).pos;
            let end = this.getEndOfBayAtIndex(defaultColumns, index).pos;
            if(index == 0)
                end += 4;
            if(index == defaultColumns.length - 2)
                start -=0
            bayDetails.push({id: bay.id, start: start, end: end, openWall: bay.openWall, design: bay});
        })
        
        for(const desFramedOpening of framedOpenings){
            for(const [index, bay] of bayDetails.entries()){
                if((desFramedOpening.pos.x - desFramedOpening.dim.width/2) >= bay.start && (desFramedOpening.pos.x+desFramedOpening.dim.width/2) <= bay.end && !bay.openWall){
                    desFramedOpening.pos.x -= bay.start;
                    DesignHelper.addComponent(bay.design, desFramedOpening);
                    this.components = this.components.filter((c) => c.design.id !== desFramedOpening.id);
                    this.design.components = this.design.components.filter((c) => c.id !== desFramedOpening.id);
                    break;
                }
                else{
                    if(index < bayDetails.length - 1){
                        if((desFramedOpening.pos.x - desFramedOpening.dim.width/2) >= bay.start && (desFramedOpening.pos.x - desFramedOpening.dim.width/2) < bayDetails[index + 1].start && (desFramedOpening.pos.x+desFramedOpening.dim.width/2) >= bay.end && (desFramedOpening.pos.x+desFramedOpening.dim.width/2) < bayDetails[index + 1].end && !bay.openWall){
                            desFramedOpening.pos.x -= bay.start;
                            DesignHelper.addComponent(bay.design, desFramedOpening);
                            this.components = this.components.filter((c) => c.design.id !== desFramedOpening.id);
                            this.design.components = this.design.components.filter((c) => c.id !== desFramedOpening.id);
                            break;
                        }
                    }
                }
                    
            }
        }
    }

    
    // required by object interface
    getDescription(){
            return 'Right End Wall'
    }

    // because the collision zones are OOBs, they don't spin around 
    getFooterCollisionZoneCenter(){        
        let world = this.getWorldPositionFromRelative(new Vector3(this.wallMid, 0,0))
        return world;
    }
    
    //TODO-WALL: a wall really shouldn't need to know about framelines. 
    framelineIndexAbsolute(){
        // the absolute frameline (as opposed to a relative frameline, where 0 is local, and 1 is toward the opposite end of the building, starting from either end)
        return this.design.frameLine;
    }

    //TODO-WALL: a wall really shouldn't need to know about framelines. 
    // A) Whatever this is driving should be provided specifically (like distance to next frameline), OR
    // B) the parent component using the walls can put the backbracing in place as necessary, since that's a framing thing and spans two frameelines.
    nextInteriorFramelineIndexAbsolute(){
        return this.framelineIndexAbsolute()+1;
    }

    getComponentDistanceMarkers(comp){      
        let des = comp.design;
        //goal 1: build is list of columns with size and position data.
        // sideWalls have column positions only for OH doors

        let low, high, lowColPos,highColPos, offset,lowWinPos,highWinPos;
        let markerMarginZ = 24;

        //console.table(this.design.columnPositions);
        let searchMargin =2;

        if(des.pos == undefined)
            return
        let lowWinX = des.pos.x - des.dim.width/2;
        let highWinX = des.pos.x + des.dim.width/2;
        this.design.columnPositions.forEach((cp)=>{
            // TODO: This seems hacky. 
            // are all columns 4 inches wide? I doubt it.
            cp.width=4;

            if(cp.pos < lowWinX - searchMargin){
                low = cp;
            } else if(cp.pos > highWinX + searchMargin && !high){
                high = cp;
            }
        })

        if(!low || !high)
            return;

        //console.log(low, high)
        let lowColPosX = low.pos; // center of the column width
            // if a corner column, measure from the outside
        if(low.index===0)
            lowColPosX-=low.width/2;// outside edge for first frameline
        if(low.index===this.design.columnPositions.length-1)
            lowColPosX+=low.width/2; // outside edge for last frameline

        let highColPosX = high.pos; // center of the column width
        if(high.index===0)
            highColPosX-=high.width/2;// outside edge for first frameline
        if(high.index===this.design.columnPositions.length-1)
            highColPosX+=high.width/2; // outside edge for last frameline

        lowColPos = new THREE.Vector3(lowColPosX, des.pos.y, des.pos.z);
        highColPos = new THREE.Vector3(highColPosX, des.pos.y, des.pos.z);
        
        offset = new THREE.Vector3(0,0,markerMarginZ);

        lowWinPos = new THREE.Vector3(lowWinX, des.pos.y, des.pos.z);
        highWinPos = new THREE.Vector3(highWinX, des.pos.y, des.pos.z);
        
        let newLowDh = new _3dDistHori(lowColPos, lowWinPos, new THREE.Vector3(),  offset, 1, CORE.layers.openingDimensions, new Vector3(20,10,1));
        this.group.add(newLowDh.group);
        let newHighDh = new _3dDistHori(highColPos, highWinPos, new THREE.Vector3(), offset, 1,  CORE.layers.openingDimensions,new Vector3(20,10,1));
        this.group.add(newHighDh.group);

        return  { 
            newLowDh,
            newHighDh
        }
    }    



}