<template>
    <div class="categoryMarkup">  
      Pricing Levels
      <div class="category-markup">
        <table id="pricingLevelMargins">
          <thead>
            <tr>              
              <th>Item Category</th>
              <!-- <th style="text-align: center; padding:2px"  v-for="level in pricingLevelHeaders"> {{ level }}<br/> (%)</th> -->
              <th style="text-align: center;padding:5px 20px">Wholesale<br/>(%)</th>
              <th style="text-align: center;padding:5px 20px">Pro<br/>(%)</th>
              <th style="text-align: center;padding:5px 20px">Contractor<br/>(%)</th>
              <th style="text-align: center;padding:5px 20px">Retail<br/>(%)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(category,i) in CategoryMarkupTypes" 
              :key="category">
              <td>
                {{ category.replace(/_/g, ' ') }}
              </td>
              <td v-for="level in pricingLevelHeaders" 
                  style="text-align: center;"
                  :key="`${category}-${level}`">
                  <input style="width:50px;text-align: center; border-width:0;border-radius: 3px;" :value="getMarkupValue(category, level)" v-on:input="setMarkupValue(category, level, $event.target.value)">
              </td>
              <!-- <td v-if="i==0" colspan=4 rowspan=15 style="vertical-align: top; padding-top: 100px;">
                <div style="rotate: -15deg; text-align: center;font-weight:bold;  font-size:24px; background:#b6c6d3;box-shadow: 5px 5px 2px 1px #00ae41; color:#00ae41">
                  Coming Soon!
                </div>

              </td> -->
              <!-- <td>
                <input
                  :disabled="true"
                  class="markup-input"
                  v-model="getMarkup(level, category)"
                  type="number"
                  step="0.01"
                  placeholder="Markup %"
                  max="1"
                  min="0"
                >
              </td> -->
            </tr>
          </tbody>
          <!-- <tbody>
            <tr v-for="markup in pricingLevelMarkups.markups" :key="markup.id">
              <td>{{ CategoryMarkupTypes[markup.category] }}</td>
              <td>
                <input
                  :disabled="true"
                  class="markup-input"
                  v-model.number="markup.markup"
                  type="number"
                  step="0.01"
                  placeholder="Markup %"
                  max="1"
                  min="0"
                >
              </td>
            </tr>
          </tbody> -->
        </table>
        <button :disabled="false" @click="saveScheme">Save <StatusIndicator ref="si_pricingLevels" :successText="'Saved'"></StatusIndicator></button>
      </div>
    </div>
  </template>
  
  <script>
  import { CORE } from '@/constants';
import { computed } from 'vue';
  
  export default {
    name: 'CategoryMarkup',
    props: {
        // scheme: {
        // type: Object,
        // required: true
        // }
    },
    data(){
        return{
            priceSchemeTypes: {
                NotSet: -1,
                ComponentPrice: 0,
                CostMarkup: 1,   
            },
            CategoryMarkupTypes:[
              "Taper_Frame",  
              "Plate",   
              "Beam",            
              "Tube",
              "Weld_Plates",
              "EaveStrut",
              "Purlin",
              "Import_Purlin",
              "Clips",
              "Insulation",
              "Panel_Galvalume",
              "Panel_Kynar",
              "Panel",
              "Trim",
              "Fasteners",
              "Accessories"
            ],
            pricingLevelMarkups:[]
        }
    },
    computed: {
      pricingLevelHeaders(){
       // return [...new Set(pricingLevelMarkups.map(pl => pl.level))];
        if(this.pricingLevelMarkups && this.pricingLevelMarkups.length > 0){
          const pricingLevels = [...new Set(this.pricingLevelMarkups.map(c => c.level))]
          .map(level => (CORE.pricingLevels[level] || `Level ${level}`)); // fallback if no name exists
          return pricingLevels;
        }
        
        return [];

      },
    },
    methods: {
        saveScheme() {
          let markupArray;
          markupArray = this.pricingLevelMarkups.flatMap(item => item.markups);
          
          
          // (pricingLevel => {
          //   reduce((acc, {level, markups}) => {
          //     return [...markups]
          //   })
          // })

          this.$emit('save', markupArray);
        },
        getMarkupValue(category, pricingLevel) {
          let markupsForLevel = this.pricingLevelMarkups.find(m => m.sLevel == pricingLevel)
          const markup = markupsForLevel.markups.find(m => m.sCategory === category);
          return markup ? markup.markup : 0;
        },
        setMarkupValue(category, pricingLevel, newMarkup){
          let markupsForLevel = this.pricingLevelMarkups.find(m => m.sLevel == pricingLevel)
          const markup = markupsForLevel.markups.find(m => m.sCategory === category);
          markup.markup = newMarkup;
        }
    }
  }
  </script>
  
  <style scoped>
  .categoryMarkup {
    flex: 1;
    min-width: 0;
    width: 70%;
    border: 1px solid gray;
    padding: 10px
  }
  
  h2 {
    margin-top: 0;
    text-wrap: nowrap;
  }
  
  .category-markup {
    margin-top: 10px;
    
  }
  
  .markup-input {
    text-align: right;
  }
  
  table {
    width: fit-content;
    border-collapse: collapse;
    font-size: 0.9em;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 6px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  input, select {
    padding: 5px;
    width: 100%;
    box-sizing: border-box;
  }
  
  select {
    margin-top: 5px;
  }
  </style>