<template>
  <div class="projects-table" style="" >
    <div class="filters">
      <input v-model="searchQuery" placeholder="Search projects...">
      <select v-model="sortBy">
        <option value="name">Name</option>
        <option value="price">Price</option>
        <option value="weight">Weight</option>
        <option value="status">Status</option>
        <option value="lastModified">Last Modified</option>
      </select>
      <button @click="sortOrder = sortOrder === 'asc' ? 'desc' : 'asc'">
        {{ sortOrder === 'asc' ? '↑' : '↓' }}
      </button>
      <select v-model="archiveFilter">
        <option value="active">Active Projects</option>
        <option value="archived">Archived Projects</option>
        <option value="all">All Projects</option>
      </select>

      <button @click="createNewProject" class="new-project-btn">Create New Project</button>
    </div>
    
    <div style="max-height: 88vh;overflow-y: scroll;
">
      <table id="table" style="">
        <thead >
          <tr>
            <th>Preview</th>
            <th> </th>
            <th @click="updateSort('name')">Name</th>
            
            <th @click="updateSort('price')">Price</th>
            <th @click="updateSort('weight')">Weight</th>
            <th @click="updateSort('state')">Status</th>
            <th @click="updateSort('lastModified')">Last Modified</th>
            
            <!-- <th>Actions</th> -->
          </tr>
        </thead>
        <tbody v-if="loading">
          <tr>
            <td colspan="10" class="spinner"><ProgressSpinner /></td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="project in filteredAndSortedProjects" :key="project.id">
            <td @click="goToProjectDetail(project.reference)">
              <img v-if="project.image" :src="project.image" alt="Building" style="height:90px">
              <img v-else src='@/assets/images/SteelNinja_horizontal_emblem.png' alt="Building" style="height:90px">
            </td>
            <td id="overflow" class="actions">
              <div class="dropdown">
                <button class="dropbtn"><b>...</b></button>
                <div class="dropdown-content">
                  <a @click="editDesign(project.reference)">Edit Design</a>
                  <a @click="shareProject(project.reference)" :disabled="notDesigned(project)">Share</a>
                  <a v-if="project.state == 1" @click="requestHardQuote(project.reference)">Request Hard Quote</a>
                  <!--<a @click="adjustQuote(project.reference)">Adjust Quote</a>-->
                  <a href="file://C:/ProgramData/SteelNinja/Projects/abb84" :disabled="notDesigned(project)">Open Project Folder</a>
                  <a @click="copyProject(project.reference)">Copy</a>
                  <a @click="archiveProject(project.reference)">Archive</a>
                  <a @click="deleteQuote(project)">Delete</a>
                </div>
              </div>
            </td>
            <td @click="goToProjectDetail(project.reference)" style="text-align: left;">{{ project.name }}</td>
            <td @click="goToProjectDetail(project.reference)">{{ formattedPrice(project.price) }}</td>
            <td @click="goToProjectDetail(project.reference)">{{ formattedWeight(project.weight) }}</td>
            <td @click="goToProjectDetail(project.reference)">{{ dictionary.getTerm(project.state)}}</td>
            <td @click="goToProjectDetail(project.reference)">{{ formatDate(project.modified) }}</td>
            
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <QuoteAdjustmentModal :qaData.sync="qaData"></QuoteAdjustmentModal> -->
    <ProjectCopyModal @afterCopy="updateProjectList" :copyData.sync ="copyData"></ProjectCopyModal>
    
  </div>
</template>

<script>
import { CORE } from '@/constants';
import ContextLogic from '@/lib/contextLogic';

import { mapState, mapActions, mapMutations } from 'vuex';
import VModal from 'vue-js-modal'
import Vue from 'vue'
import Vuex from 'vuex'
import QuoteAdjustmentModal from '@/components/QuoteAdjustmentModal.vue';
import ProjectCopyModal from '@/components/ProjectCopyModal.vue';
import ProgressSpinner from '@/components/ProgressSpinner.vue';
// import Toast from "vue-toastification";
// import "vue-toastification/dist/index.css";
import Dictionary from '../Dictionary.js'
import api from '@/api.js';
import Util from '@/lib/utility.js';
Vue.use(Vuex)
Vue.use(VModal, {dialog:true});

// const toastOptions = {
//   position: "bottom-center",
//   timeout: 3000,
//   closeOnClick: false,
//   pauseOnFocusLoss: true,
//   draggable: false,
//   maxToasts: 2,

// };
// Vue.use(Toast, toastOptions);

export default {
  components: {
    QuoteAdjustmentModal,
    ProgressSpinner,
    ProjectCopyModal
  },
  data() {
    return {
      dictionary: null,
      loading:true,
      searchQuery: '',
      sortBy: 'name',
      sortOrder: 'asc',
      archiveFilter: 'active',
      filter:{
        //archive
        lvl1Entity:{
          enabled:false,
          options:[],
          value:null
        },
        lvl2Entity:{
          enabled:false,
          options:[],
          value:null
        },
      },
      projects: [],
      qaData: {
        ref: null,
        show: false
      },
      copyData:{
        ref: null,
        show: false,
      },
      isAuthenticated: false,
      isSouthWest: false,
      input: {
        disableSave: false,
        error:{
          show: false,
          msg: ""
        }
      },
      saveInProgress: false,

    }
  },
  computed: {
    ...mapState('contextModule', ['user', 'userBusiness', 'current']),
    filteredAndSortedProjects() {
      if(!this.projects)
        return []

      var filteredProjects = [...this.projects];

      // Step 1. FILTER
      filteredProjects = filteredProjects.filter(project =>{
        switch (this.archiveFilter) {
          case 'archived':
            if (!project.archive) return false;
            break;
          case 'active':
            if (project.archive) return false;
            break;
          case 'all':
            // Show all projects regardless of archive status
            break;
          default:
            console.warn('Invalid archiveFilter value. Using "active" as default.');
            if (project.archive) return false;
        }

        // if(this.searchQuery)
        //   return project.name.toLowerCase().includes(this.searchQuery.toLowerCase())

        if(this.searchQuery){
          let projectName =  project.name ? project.name.toLowerCase() : '';
          let lvl1UserName = project.lvl1User ? project.lvl1User.name.toLowerCase() : '';
          let lvl2UserName = project.lvl2User ? project.lvl2User.name.toLowerCase() : '';
          console.log(`project: ${projectName}, lvl1: ${lvl1UserName}, lvl2: ${lvl2UserName}`)

          console.log(`project: ${projectName.includes(this.searchQuery)}, lvl1: ${lvl1UserName.includes(this.searchQuery)}, lvl2: ${lvl2UserName.includes(this.searchQuery)}`)

          return (
            projectName.includes(this.searchQuery) 
            || lvl1UserName.includes(this.searchQuery)
            || lvl2UserName.includes(this.searchQuery)
          )
        }
         

        return true
      });
      

      // const sortedItems = filteredProjects.sort((a, b) => {
      //   const aName = a.name.trim().toLowerCase();
      //   const bName = b.name.trim().toLowerCase();

      //   console.log('compare', `a:${aName} b:${bName} || ${aName.localeCompare(bName)}`)
      //   return aName.localeCompare(bName)
      // });

      return filteredProjects.sort((a, b) => {
        const aName = a.name.trim().toLowerCase();
        const bName = b.name.trim().toLowerCase();
        const modifier = this.sortOrder === 'asc' ? 1 : -1;
        switch (this.sortBy) {
          case 'name':
            // Convert both strings to lowercase for case-insensitive comparison
            return modifier * aName.localeCompare(bName);
          case 'price':
            return modifier * (a.price - b.price);
          case 'weight':
            return modifier * (a.weight - b.weight);
          case 'lastModified':
            return modifier == 1 ? (new Date(a.modified) - new Date(b.modified)) : (new Date(b.modified) - new Date(a.modified));
          case 'status':
            return modifier * (a.state - b.state);
          default:
            return 0;
        }
      });
    },
    projectFileURI(){
      let projectDirectory = ``
    }
  },
  methods: {
    ...mapActions('projectModule', ['fetchProject', 'fetchProjects', 'deleteProject', 'requestAHardQuote', 'updateBuildingQuote']),
    formattedPrice(val){
      return Util.formattedPrice(val);
    },
    formattedWeight(val){
      return Util.formattedWeight(val);
    },
    updateSort(key) {
      if (this.sortBy === key) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'
      } else {
        this.sortBy = key
        this.sortOrder = 'asc'
      }
    },
    notDesigned(project){
      project.design === "";
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString()
    },
    goToProjectDetail(projectRef) {
      this.$router.push(`/project/${projectRef}`)
    },
    createNewProject() {
      this.$router.push('/project/new')
    },
    adjustQuote(ref){
      this.qaData.ref = ref;
      this.qaData.show = true;
    },
    async deleteQuote(project){
      await this.$modal.show('dialog', {
          title: 'Confirmation',
          text: `Are you sure you want to permanently delete this design?`,
          buttons:[
            {
              title: 'Delete',
              handler: async () => {
                let response = await this.deleteProject(project);
                this.projects = await this.fetchProjects();
                this.$toast.success(`Deleted project "${project.name}"`);
                this.$modal.hide('dialog')
              },
            },
            {
              title: 'Cancel',
              handler: async () => {
                this.$modal.hide('dialog')
              }
            },
          ]
      })
     
    },
    async updateProjectList(){
      this.projects = await this.fetchProjects();
    },
    editDesign(ref){
      //project/dkj523/design
      this.$router.push(`/project/${ref}/design`)        
    },
    async shareProject(ref){
      await navigator.clipboard.writeText('');
      try {
        // Generate the shareable link (you may need to adjust this based on your routing structure)
        const shareableLink = `${process.env.VUE_APP_API_BASE_URL}/share/${ref}`;
        
        // Copy the link to clipboard
        await navigator.clipboard.writeText(shareableLink);
        
        // Show a toast notification
        this.$toast.success(`Share link copied to clipboard!`);
      } catch (err) {
        console.error('Failed to copy link:', err);
        this.$toast.error('Failed to copy link. Please try again.');
      }
    },
    async requestHardQuote(reference){
      let response = await this.requestAHardQuote(reference);

      if(response.isError){
        this.$toast.error(`Failed to request hard quote. ${response.data.msg}`, {timeout: 10000});
      }
      else{
        this.$toast.success(`Hard quote requested!`);
      }
    },
    async openProjectFolder(ref){
      let project = this.getProjectFromList(ref);
      let link = "file:///C:/ProgramData/SteelNinja/Projects/abb84"
      window.open(project.projectDirectoryLink);
    },
    copyProject(ref){
      this.copyData.ref = ref;
      this.copyData.show = true;
    },
    async archiveProject(ref){
      let project = this.getProjectFromList(ref);
      project.archive = true;
      await this.commitDetails(project);
      //this.projects = await this.updateProjectList();

    },
    async commitDetails(project){
      this.input.error = {
          msg:'',
          show: false
      }

      this.saveInProgress = true;
      let response;
      response = await this.updateBuildingQuote(project);

      if(response.isError){ 
        this.$toast.error(`Failed to archive project "${project.name}""`);
        //this.commitDetailsError(response.data)
      }
      else{
        this.saveInProgress = false;
        this.$toast.success(`Successfully archived project "${project.name}"`);
        // console.log(response);
       }
    },
    commitDetailsError(errData){
      if(errData.locked){
        this.showAlert_QuoteTemplateLocked();
      }
      else{
        this.$modal.show('dialog', {
            title: 'Warning',
            text: `${errData.msg}`,
            buttons:[
              {
                title: 'OK',
                handler: () => {
                  this.$modal.hide('dialog')
                }
              },
            ]
        })
      }
        
    },
    showAlert_QuoteTemplateLocked(){
      this.$modal.show('dialog', {
          title: 'Warning',
          text: `Could not complete the operation becasue this design's quote file is locked. Please close the quote file and try again.`,
          buttons:[
            {
              title: 'OK',
              handler: () => {
                this.$modal.hide('dialog')
              }
            },
          ]
      })
    },
    getProjectFromList(ref){
      return this.projects.find(project => project.reference == ref);
    },
    showFilter_Lvl1Entities(val){
      this.filter.lvl1Entity.enabled=val;
    },
    showFilter_Lvl2Entities(val){
      this.filter.lvl2Entity.enabled=val;
    },    
    loadFilter_Lvl1Entities(){      
      this.filter.lvl1Entity.options = api.getAllSuppliers();
    },
    loadFilter_Lvl2Entities(){
      if(this.filter.lvl1Entity.value)
        api.getSubBusinesses(this.filter.lvl1Entity.value.id);
      else
        api.getAllContractors();
    },
    loadFilterEntities(){
    this.filter.lvl1Entity.options=[];
    this.filter.lvl2Entity.options=[];
    switch(this.userBusiness.type){
      case 0:
        // enumerate lvl2 entities into values
        this.loadFilter_Lvl1Entities();
        this.loadFilter_Lvl2Entities();
        break;
      case 1: // user works for a supplier
        // enumerate lvl2 entities into values
        this.loadFilter_Lvl2Entities();        
        break;
      case 2: // user works for a contractor
        // no entity filtering capability
        break;
    }
    }
  }, 
  async created(){

    let redirectUrl = sessionStorage.getItem('redirectUrl')
      if (redirectUrl && redirectUrl != '/') {
        console.log('in dashboard, redirecting to: ', redirectUrl)
        sessionStorage.removeItem('redirectUrl');
        this.$router.push({ path: redirectUrl }) // relative path
      }
      
    this.dictionary = new Dictionary();
    await ContextLogic.initContext(this);
    //let sub = this.current.mode === CORE.modes.system ? null : this.$auth.user.sub;

    this.projects = await this.fetchProjects(this.userBusiness.id);
    this.loading=false;

    this.isAuthenticated = this.$auth.isAuthenticated;
    
    if(this.isAuthenticated){              
        let swFeatureflag = this.userBusiness.featureFlags.find(flag => flag.name == 'isSouthwestMetal');
        this.isSouthWest = (swFeatureflag) ? swFeatureflag.enabled : false;
    }

    this.showFilter_Lvl1Entities(false);
    this.showFilter_Lvl2Entities(false);

    switch(this.userBusiness.type){
      case 0:
        // add the contractor drop down
        this.showFilter_Lvl1Entities(true);
        this.showFilter_Lvl2Entities(true);
        // enumerate lvl2 entities into values
        this.filter.lvl1Entity.options=[];
        break;
      case 1: // user works for a supplier
        // add the contractor drop down
        this.showFilter_Lvl2Entities(true);        
        // enumerate lvl2 entities into values
        this.filter.lvl2Entity.options=[];
        break;
      case 2: // user works for a contractor
        // no entity filtering capability
        break;
    }

    //this.loadFilterEntities();
  }
}
</script>

<style lang="scss" scoped>
// Variables
$primary-color: #3498db;
$secondary-color: #2c3e50;
$background-color: #f8f9fa;
$border-color: #e0e0e0;
$text-color: #333;
$hover-color: #f1f8ff;
$green-color: #2ecc71;
$danger-color: #e74c3c;
$box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

// Mixins
@mixin transition($property: all, $duration: 0.3s) {
  transition: $property $duration ease;
}

.projects-table {
  margin: 10px;
  font-family: Arial, sans-serif;
  color: $text-color;
  background-color: $background-color;
  border-radius: 8px;
  box-shadow: $box-shadow;
  padding: 10px;

  .spinner{
    text-align: center;    
  }
  
  .filters {
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;

    input, select {
      padding: 8px 12px;
      border: 1px solid $border-color;
      border-radius: 4px;
      font-size: 14px;
      @include transition;

      &:focus {
        outline: none;
        border-color: $primary-color;
      }
    }

    button {
      padding: 8px 12px;
      background-color: $primary-color;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      @include transition;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }

    .new-project-btn {
      background-color: $green-color;
      margin-left: auto;

      &:hover {
        background-color: darken($green-color, 10%);
      }
    }
  }

  table {
    margin:auto;
    width: fit-content;
    border-collapse: separate;
    border-spacing: 0;
    cursor: pointer;

    th{
      //border: 1px solid $border-color;
      padding: 12px;
      text-align:center;
    }
    td {
      //border: 1px solid $border-color;
      padding: 0px 12px;
      text-align: center;
    }

    th {
      background-color: $secondary-color;
      color: white;
      font-weight: bold;
      cursor: pointer;
      @include transition(background-color);

      &:hover {
        background-color: lighten($secondary-color, 10%);
      }
    }

    tr {
      @include transition(background-color);

      &:hover {
        background-color: $hover-color;
      }
    }

    td#overflow {
      @include transition(background-color);

      &:hover {
         background-color: darken($hover-color, 5%);
      }
    }
  }

  .actions {
    text-align: center;
    width: 5px;
    .dropdown {
      position: relative;
      display: inline-block;

      .dropbtn {
        background-color: transparent;
        color: $text-color;
        padding: 5px 10px;
        font-size: 16px;
        border: none;
        cursor: pointer;
        @include transition;

        &:hover {
          color: $primary-color;
        }
      }

      .dropdown-content {
        display: none;
        position: absolute;
        right: 0;
        background-color: white;
        min-width: 160px;
        box-shadow: $box-shadow;
        z-index: 1;
        border-radius: 4px;
        overflow: hidden;

        a {
          color: $text-color;
          padding: 10px 10px;
          text-decoration: none;
          display: block;
          cursor: pointer;
          @include transition(background-color);

          &:hover {
            background-color: $hover-color;
          }

          &.delete-option {
            color: $danger-color;

            &:hover {
              background-color: lighten($danger-color, 35%);
            }
          }
        }
      }

      
    }

    &:hover .dropdown-content {
        display: block;
      }
  }
}

#table{

  th{
    position: sticky;
    top: 0;
    z-index: 1;
  }  

   td{
    border-top: 1px solid lightgray;
   }
  
}


</style>

