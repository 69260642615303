import * as THREE from 'three';
import { CORE, rebuildTypes,impactTypes } from '../_spec.js'
import { Vector2, Vector3 } from 'three';
import { OBB } from 'three/examples/jsm/math/OBB.js'; // https://threejs.org/examples/?q=bounding#webgl_math_obb
import CollisionHelper from '../helpers/CollisionHelper.js';
import Grabject from './grabject.js'
import Door from './Door.js'
import OptionHelper from '../helpers/optionHelper.js'
import vHelper from '../helpers/vHelper.js'
import MemHelper from '../helpers/memHelper.js';
import Util from '../utility.js';
import TreeNode from '../helpers/TreeNode.js'
import UpdateHelper from '../helpers/UpdateHelper.js';
import _3dWindowBase from '../3d/WindowBase.js'
import _3dGirtHoriC from '../3d/GirtHoriC.js'
import layerHelper from '../helpers/layerHelper.js';
import materialHelper from '../helpers/materialHelper.js';
import _3dDoorRollup from '../3d/DoorRollup.js';
import DoorResizable from './DoorResizable.js';

export default class DoorRollup extends DoorResizable {
    constructor(design) {        
        super(design);
        if(this.design.dir) 
            delete this.design.dir;
    }
  
    migrate(design){
        if(!design)
            return;

        if(typeof design.excludeOh === 'undefined')
            design.excludeOh=false;        
    }

    applyDesign(design){
        this.design.collided= design.collided,
        this.design.pos = new Vector3( design.pos.x, design.pos.y, design.pos.z),        
        this.design.type = design.type,
        //this.design.id = design.id,
        this.design.parent = {};
        this.design.parent.id = design.parent.id;
        this.design.dim= {
            width: design.dim.width,
            height: design.dim.height
        };
        this.design.excludeOh = design.excludeOh;
        this.design.pos.y = (this.design.dim.height)/2; 
        this.design.selected= false;
    }
    
    defaultDesign(limits){

        let des = {
            collided : false,
            pos : new Vector3(0,(9*12)/2,0), 
            parent: {
                type: 'frontSide'
            },
            type: CORE.components.doorRollup,
            id: -1,
            dim:{
                width: 9*12,
                height: 9*12
            },
            excludeOh:false,
            selected:false
        }
        if(limits){
            if(des.dim.height > limits.dim.height.max)
            des.dim.height = limits.dim.height.max;
        }
        des.pos.y = (des.dim.height)/2; //foundation is 6" tall
        return des;
    }

    processImpactFromSelf(impact){
        super.processImpactFromSelf(impact);

        switch(impact.change.name)
        {
            case impactTypes.doorRollupDetail:
                this.addRebuildNeeded(rebuildTypes.full);
                break;
        }
    }
    
    getOptionsSpec(){
        let options = super.getOptionsSpec();
        options.push(OptionHelper.checkbox("excludeOh", "Exclude OH", impactTypes.doorRollupDetail, true));
        return options;
    }
    
    // required by object interface
    getDescription(){

        let widthFt = (this.design.dim.width/12).toFixed(2);
        let heightFt = (this.design.dim.height/12).toFixed(2);
        //return `Rollup Door (${widthFt}' x ${heightFt}')`;
        return `Rollup Door (${this.getDimensionText()})`;
    } 

    

    build() {

        let jambTrimThickness = .5; 
        let jambTrimWidth = 8;
        let halfFrameDepth = jambTrimThickness/2;

        
        let dim = new THREE.Vector3(this.design.dim.width, this.design.dim.height, 1);
        let halfDim = dim.clone().multiplyScalar(.5);
        
        this.sizeDoor = new THREE.Vector3(dim.x, dim.y, 1);
        
        this.group.position.copy(this.design.pos)

        //if(this.settings && this.settings.ohJambLiner!==false)
        let ohJambLiner = this.options && this.options['ohJambLiner']!==false;
        
        let door = new _3dDoorRollup(CORE.preferences.des_levelOfDetail.value, this.design.dim.width, this.design.dim.height, this.structureConfig.trimMaterials.door, this.parent.girtColor, this.design.excludeOh, ohJambLiner);
        this.group.add(door.group)
        door.group.position.z = .25;

        this.contextMenuObject = new THREE.Mesh()
        let trc = this.getTopRightCorner();
        this.contextMenuObject.position.set(trc.x, trc.y, trc.z);
        this.contextMenuObject.visible = false; // this 

        // we need a separate pickMesh on overhead doors because they're largest feature (the door mesh)
        // is inset into the wall, which "hides" the door from rayCasting because the wall pickMesh
        // must be without holes for the sake of component editing.
        
        if(typeof this.pickDetectionMesh === 'undefined'){
            var geoPick = new THREE.BoxGeometry(this.sizeDoor.x, this.sizeDoor.y, halfFrameDepth);
            this.pickDetectionMesh = new THREE.Mesh(geoPick, CORE.materials.transparent);            
            this.pickDetectionMesh.name=`pickMesh for rollup door ${this.design.id}`;
            this.pickDetectionMesh.visible=false;
        }
        this.pickDetectionMesh.userData = {
            type: this.design.type, // not sure this is necessary. copied from wallBase.
            id: this.design.id
        };
        this.outlineMesh = this.pickDetectionMesh;      
        
        this.group.add(this.pickDetectionMesh)
        this.group.add(this.contextMenuObject);        
        this.group.visible = this.visible;

        
        this.buildGrabjects(halfDim)
        this.showGrabjects(this.design.selected);
        
        this.group.rotation.y = this.rot;

        //this.applyPosition();
        this.setCollisionZone();
        
        this.built=true;
    }

    getCollisionClass(){
        return CORE.collisions.classes.doorRollup;
    }

    getTypeDisplayName(){
        return "Rollup Door"
    }

}
