import * as THREE from 'three';
import ColumnBase from './ColumnBase.js'
import {CORE} from '../_spec.js'
import { Vector3 } from 'three';
import CollisionHelper from '../helpers/CollisionHelper.js';
import MemHelper from '../helpers/memHelper.js';
import materialHelper from '../helpers/materialHelper.js';

export default class ColumnC extends ColumnBase{
	constructor(lod, length, addCollisionZone, materialDim, color){		
		super(lod, new Vector3(), new Vector3(), color);
		this.group.name = 'vertical charlie';
		this.length = length;
		this.addCollisionZone = addCollisionZone;
		this.dimensions = materialDim;
		this.build();
    }

    build(){
		// draw a beam
		let flangeWidth = this.dimensions.flange;
		let webWidth = this.dimensions.depth;

		// Create a custom texture for this object
		let texture = CORE.textures.beam.clone();
		texture.wrapT = texture.wrapS = THREE.MirroredRepeatWrapping;
		texture.encoding = THREE.sRGBEncoding;        
		texture.repeat.set(flangeWidth, this.length); 
		
		if(this.isLowDetail())
			this.build_LD(flangeWidth, webWidth, texture);
		else
		{
			this.build_HD(flangeWidth, webWidth, texture);
			this.group.castShadow=true;
		}
        
		//collision bounding box
		this.group.updateWorldMatrix(true);

		if(this.addCollisionZone){
			
			// this used to be the 3d web.
            this.group.children[2].userData = {
                collisionZones: this.buildCollisionZones()
            };
        }
	}

	build_HD(flangeWidth, webWidth, texture) {

		let material = materialHelper.getFrameMaterial_HD(this.color, texture);
		var geoFlange = new THREE.BoxGeometry(flangeWidth, this.length, CORE.steel.thickness_in);
		geoFlange.name = 'ColumnC geoFlange';
		var meshFlange1 = new THREE.Mesh(geoFlange, material);
		meshFlange1.name = 'ColumnC flange1';
		meshFlange1.position.z = +(webWidth / 2) - CORE.steel.thickness_in/2;
		meshFlange1.position.x -= CORE.steel.thickness_in/2;
		meshFlange1.position.y += this.length / 2;
		var meshFlange2 = new THREE.Mesh(geoFlange, material);
		meshFlange2.name = 'ColumnC flange2';
		meshFlange2.position.z = -(webWidth / 2) + CORE.steel.thickness_in/2;
		meshFlange2.position.x -= CORE.steel.thickness_in/2;
		meshFlange2.position.y += this.length / 2;

		var sizeMiddle = new THREE.Vector3(CORE.steel.thickness_in, this.length, webWidth);
		var geoMiddle = new THREE.BoxGeometry(sizeMiddle.x, sizeMiddle.y, sizeMiddle.z);
		geoMiddle.name = 'ColumnC geoMiddle';
		var meshMiddle = new THREE.Mesh(geoMiddle, material);
		meshMiddle.name = 'ColumnC web';
		meshMiddle.position.x -= (flangeWidth / 2);
		meshMiddle.position.y += this.length / 2;

		meshMiddle.castShadow = true;
		meshFlange1.castShadow = true;
		meshFlange2.castShadow = true;
		this.group.add(
			meshFlange1,
			meshFlange2,
			meshMiddle
		);
		
		meshFlange1.layers.set(CORE.layers.frame)
		meshFlange2.layers.set(CORE.layers.frame)
		meshMiddle.layers.set(CORE.layers.frame)
	}

	build_LD(flangeWidth, webWidth, texture) {
		let material = materialHelper.getFrameMaterial_LD(this.color);
		var geoFlange = new THREE.PlaneBufferGeometry(flangeWidth, this.length, 1, 1)
		geoFlange.name = 'ColumnC geoFlange';
		var meshFlange1 = new THREE.Mesh(geoFlange, material);
		meshFlange1.name = 'ColumnC flange1';
		meshFlange1.position.z = +((webWidth / 2));
		meshFlange1.position.y += this.length / 2;
		var meshFlange2 = new THREE.Mesh(geoFlange, material);
		meshFlange2.name = 'ColumnC flange2';
		meshFlange2.position.z = -(webWidth / 2);
		meshFlange2.position.y += this.length / 2;

		//var sizeMiddle = new THREE.Vector3(CORE.steel.thickness_in, this.length, webWidth);
		var geoWeb = new THREE.PlaneBufferGeometry(webWidth, this.length, 1, 1)
		geoWeb.name = 'ColumnC geoMiddle';
		var meshWeb = new THREE.Mesh(geoWeb, material);
		meshWeb.name = 'ColumnC web';
		meshWeb.rotation.y = Math.PI/2;
		meshWeb.position.x -= (flangeWidth / 2);
		meshWeb.position.y += this.length / 2;

		meshWeb.castShadow = false;
		meshFlange1.castShadow = false;
		meshFlange2.castShadow = false;
		this.group.add(
			meshFlange1,
			meshFlange2,
			meshWeb
		);
		
		meshFlange1.layers.set(CORE.layers.frame)
		meshFlange2.layers.set(CORE.layers.frame)
		meshWeb.layers.set(CORE.layers.frame)
	}
}
