import * as THREE from 'three';
import { CORE, rebuildTypes,impactTypes } from '../_spec.js'
import {Vector2,  Vector3 } from 'three';
import Grabject from './grabject.js'
import Door from './Door.js'
import OptionHelper from '../helpers/optionHelper.js'
import vHelper from '../helpers/vHelper.js'
import MemHelper from '../helpers/memHelper.js';
import TreeNode from '../helpers/TreeNode.js'
import UpdateHelper from '../helpers/UpdateHelper.js';

import _3dDoorWalkin from '../3d/DoorWalkin.js'
import layerHelper from '../helpers/layerHelper.js';
export default class DoorWalkin extends Door {
    constructor(design) {
        super(design);
        if(this.design.dir) 
            delete this.design.dir;
    }
    
    processImpactFromSelf(impact){

        impact.handled.push(this.design.id);
        switch(impact.change.name)
        {
            case impactTypes.openingResize:
                this.addRebuildNeeded(rebuildTypes.full);
                break;
            case impactTypes.walkinDetail:
                this.addRebuildNeeded(rebuildTypes.full);
                break;
            case impactTypes.openingPosition:
                this.addRebuildNeeded(rebuildTypes.move);
                break;
        }
    }

    applyDesign(design){
        this.design.collided= design.collided,
        this.design.pos = new Vector3( design.pos.x, design.pos.y, design.pos.z),
        this.design.type = design.type,
        this.design.parent = {};
        this.design.parent.id = design.parent.id;
        this.design.dim= {
            width: design.dim.width,
            height: design.dim.height
        };
        this.design.deadbolt = design.deadbolt;
        this.design.selected= false;
        this.addRebuildNeeded(rebuildTypes.full);
    }

    

    getOptionsSpec(){
        return [
            OptionHelper.selection("alias","Size",impactTypes.newComponent, undefined, [
                    {
                        value: CORE.components.doorWalkin3o7o,
                        text:"3x7"
                    },
                    {
                        value: CORE.components.doorWalkin4o7o,
                        text:"4x7"
                    },
                    {
                        value: CORE.components.doorWalkin6o7o,
                        text:"6x7"
                    }
                ],
                true,
            ()=>{//fnIn
                return this.design.type;
            },    
            undefined,
            (v)=>{//fnChanged
                this.design.type = v;
               
            }),
            OptionHelper.checkbox("deadbolt", "Deadbolt", impactTypes.walkinDetail, true)
        ]
    }
    
    getOutlineMeshes(){
        return [this.outlineMesh];
    }

    init(design) {
        throw "Must be implemented by subclass";
    }

    updatePosition(center)
    {
        if(!DoorWalkin.updatePosition(center, this.design))
            return;

        // move requires no rebuild (except on re-parentage)
        // this.addRebuildNeeded(rebuildTypes.move);
        return UpdateHelper.createImpact(this.design, impactTypes.openingPosition);
    }

    static updatePosition(center, design){
        let affected = false
        if(center.x !=0)
            affected = DoorWalkin.updatePosX(design, center.x) || affected;
        return affected;
    }
    
    updateSize(input){
        throw 'walkin size grabject is not supported'
    }
    


    build() {
        // use the design to build the component

        let dim = new THREE.Vector3(this.design.dim.width, this.design.dim.height, 0);
        this.door = new _3dDoorWalkin(CORE.preferences.des_levelOfDetail.value, this.group, dim, this.structureConfig.trimMaterials.walksAndWindows, CORE.materials.stainless, this.design.deadbolt, this.design.halfGlass, undefined, true);
        this.door.group.name = "singleDoor subgroup for " + this.getDescription();        
       
        
        this.group.position.copy(this.design.pos)//
        //this.group.add(this.door.group);
        this.group.name = this.getDescription();
        
        this.grabjectGroup = new THREE.Group();
        let parentComponentTypes = [
            CORE.components.bayEndLeft,
            CORE.components.bayEndRight,
            CORE.components.baySideBack,
            CORE.components.baySideFront
        ]
        new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(0,0,1), [CORE.indicators.leftRight], new THREE.Vector3(15, 15, 5), CORE.grabjects.position,parentComponentTypes);
        this.group.add(this.grabjectGroup);
        this.showGrabjects(this.design.selected);

        this.group.rotation.y = this.rot;
        
        var geoPickDetection = new THREE.BoxGeometry(dim.x, dim.y, dim.z);
        geoPickDetection.name='door pick geo'
        this.meshPickDetection = new THREE.Mesh(geoPickDetection, CORE.materials.transparent);        
        this.meshPickDetection.visible=false;
        
        this.meshPickDetection.name = "pickDetectionMesh for " + this.getDescription();
        this.meshPickDetection.position.z+=1;
        this.group.add(this.meshPickDetection);
        
        this.setCollisionZone();

        this.outlineMesh = this.meshPickDetection;
        this.contextMenuObject = new THREE.Mesh()
        let trc = this.getTopRightCorner();
        this.contextMenuObject.position.set(trc.x, trc.y, trc.z);
        this.contextMenuObject.visible = false; // this
        this.group.add(this.contextMenuObject);

        var geoDoor = new THREE.BoxGeometry(dim.x, dim.y, 1);
        geoDoor.name='geoDoor'
        let meshDoor = new THREE.Mesh(geoDoor);        
        meshDoor.userData = this.design;
        meshDoor.visible=false;
        meshDoor.name = "walkdoor for " + this.getDescription();
        this.group.add(meshDoor);
        layerHelper.enableLayer(this.group,CORE.layers.quote);
        this.built=true;
    }

    setCollisionZone(){
        this.group.updateMatrix();
        //this.group.updateMatrixWorld();
        if(!this.meshPickDetection)
            return;
        this.meshPickDetection.updateMatrix();
        this.meshPickDetection.updateWorldMatrix(true); // updates this and all ancestors so the collision zone is aligned.
        this.meshPickDetection.renderOrder=1;
        let zone = this.getCollisionZoneTemplate(this.meshPickDetection);
        zone.classes = [
            CORE.collisions.classes.window,
            CORE.collisions.classes.emptyFramedOpening,
            CORE.collisions.classes.louverVent,
            CORE.collisions.classes.doorWalkin3o7o,
            CORE.collisions.classes.doorWalkin4o7o,
            CORE.collisions.classes.doorWalkin6o7o,
            CORE.collisions.classes.doorRollup,
            CORE.collisions.classes.doorSliding,
            CORE.collisions.classes.doorCommGlass,
            CORE.collisions.classes.doorSectional,
            CORE.collisions.classes.doorHangar,
            CORE.collisions.classes.wideOpening,
        ];
        zone.data = {
            id: this.design.id,
            type: this.design.type
        };
        //zone.obb.position = new Vector3(0,0,0);
        this.meshPickDetection.userData.collisionZones = [zone];
    }
    
    removePickingObject(){
        if(this.meshPickDetection)
            delete this.meshPickDetection
    }


    remove() {        
        MemHelper.removeAllChildren(this.group)
        MemHelper.dispose(this.group);
    }

}
