<template>
  <div class="projectView"
        @dragenter.prevent="onDragEnter"
        @dragover.prevent
        @dragleave.prevent="onDragLeave"
        @drop.prevent="onDrop">
    <div class="dragAndDrop"
      :class="{ 'dragging': isDragging }"
        >
    <div class="project-details">
      <v-dialog transition="pop-out" />
      <h1>{{ isNewProject ? 'New Project' : quote.name }}</h1>
      <div class="details">
        
        <div class="details-group">
          <div v-if="isNewProject" class="project-actions form-actions">
            <button type="button" class="btn btn-cancel" v-on:click="navigateBack">Back</button>
            
              <button v-if="isNewProject" class="btn btn-primary" :disabled="input.disableSave || saveInProgress || !isAuthenticated || isDragging" v-on:click="commitAndLaunchDesigner">Save and Open Designer</button>
            
            
          </div>

          <div v-if="!isNewProject" class="project-actions form-actions">

            <div v-if="isAuthenticated" style=''>
              <button v-if="isEditing" style="width:100px;display:inline-block" class="btn btn-primary" :disabled="input.disableSave || saveInProgress || isDragging" v-on:click="commitDetails">Save</button>              
              <button v-else class="btn btn-primary" v-on:click="setProjectEditMode">Edit Project Details</button>
            </div>
            <img v-if="saveInProgress" src="../assets//images/ajax-spinner.gif" style="display: inline-block;width: 30px;height:30px"/>

            <button v-if="isEditing" type="button" class="btn btn-cancel" v-on:click="isEditing = false">Cancel</button>
            
            <button :disabled="isEditing" id="actions-shareDesign" class="btn" v-on:click="shareProject()">
                Share
              </button>
              <button v-if="isSouthWest" id="actions-openProjectFolder" class="btn" v-on:click="openProjectFolder()">
                Open Project Folder
              </button>
              <button :disabled="isEditing" id="actions-copy" class="btn" v-on:click="copyProject()">
                Copy
              </button>
              <button :disabled="isEditing" id="actions-archive" class="btn" v-on:click="setProjectArchiveState()">
                {{quote.archive == true ? "Unarchive" : "Archive"}}
              </button>

              
            <button v-if="!isShareMode && !isNewProject && isAuthenticated" :disabled="isEditing" id="actions-editDesign" class="btn btn-success quote-control-btn" v-on:click="launchDesigner()">Edit in 3D Designer</button>
          </div>
          <div v-if="input.error.show" style="color:red; margin: 10px">{{input.error.msg}}</div>
          <div class="input-group">
            <span for="projectName">Project Name</span>
            <input type="text" id="projectName" v-model="quote.name" v-on:input="inputfieldChanged" :disabled="inputIsDisabled">
            <div v-if="!nameValid" class="required">Required</div>
          </div>
          <div class="input-group">
            <span for="address">Location</span>            
            <!-- <input type="text" id="address" v-model="quote.address" v-on:input="inputfieldChanged" :disabled="inputIsDisabled"> -->
            <AddressInput ref="address" :pAddress="addressData" @address-selected="setAddress" :editMode="isEditing && !addressValid" :editable="isEditing" ></AddressInput>
            <div v-if="!addressValid" class="required">Required</div>
          </div>
          <template v-if="isNewProject">
            <div class="input-group">
              <span for="code">Code</span>
              <select id="code" v-model="quote.buildingCode" v-on:input="inputfieldChanged" :disabled="inputIsDisabled">
                <option value="IBC 2012">IBC 2012</option>
                <option value="IBC 2015">IBC 2015</option>
                <option value="IBC 2018">IBC 2018</option>
                <option value="IBC 2021">IBC 2021</option>
              </select>
            </div>
            <div class="input-group">
              <span>Engineering Seals</span>
              <input type="checkbox" v-model="quote.seals" :disabled="inputIsDisabled">
            </div>
          </template>
          
          <div class="input-group">
            <span> Delivery</span>
            <input type="checkbox" v-model="quote.delivered" :disabled="inputIsDisabled">
          </div>
          <div class="input-group">
            <span for="taxes">Taxes</span>
            <select id="taxes" v-model="quote.tax" v-on:input="inputfieldChanged" :disabled="inputIsDisabled">
              <option value="No - Exempt">No - Exempt</option>
              <option value="No - Out Of State">No - Out Of State</option>
              <option value="Yes - City">Yes - City</option>
              <option value="Yes - Outside City">Yes - Outside City</option>
            </select>
          </div>
          <div class="input-group" v-if="isAuthenticated">
            <span for="projectName">Supplier Owner</span>
            <BusinessUserPicker :disable="inputIsDisabled" :businesses="suppliers" :isSupplierPicker="true" ref="supplierPicker" @user-selected="onSupplierUserSelected"></BusinessUserPicker>
            <div v-if="!supplierUserValid" class="required">Required</div>
          </div>
          <div class="input-group" v-if="isAuthenticated">
            <span for="projectName">Contractor Owner</span>
            <BusinessUserPicker :disable="inputIsDisabled" :businesses="contractors" :isSupplierPicker="false" ref="contractorPicker" @user-selected="onContractorUserSelected"></BusinessUserPicker>
          </div>
          <div class="input-group" id="notes-wrapper">
            <span>Bidsheet Summary Notes</span>
            <textarea id="projectNotes" name="notes" rows="3" cols="10" v-model="quote.notes" :disabled="inputIsDisabled"></textarea>
          </div>
          <div class="input-group" id="notes-wrapper" v-if="isSouthWest">
            <span>Internal Quote Template Notes</span>
            <textarea id="internalQuoteTemplateNotes" name="internalNotes" rows="3" cols="10" v-model="quote.internalQuoteTemplateNotes" :disabled="inputIsDisabled" placeholder="Internal notes can be found on all project Quote Templates."></textarea>
          </div>
          <div class="form-actions">
          

          
          </div>
          
        </div>

        <div class="project-image-wrapper details-group">


          

          <a @click.prevent="launchDesigner" :href="designUrl">
          <ProgressSpinner v-if="loadingProject"></ProgressSpinner>
          <img v-else-if="quote.state == 0" src="../assets/testBuilding.jpg" alt="Building" class="building-image">
          <img v-else :src="quote.image" alt="Building" class="building-image">
          
          </a>
          <div class="project-resources">
            <div id="assets" v-if="isAuthenticated">
              <label><b>Assets:</b></label>
              <div v-if="loadingDetails"><ProgressSpinner /></div>
              <div v-else>
                <div id="bidsheets" v-if="quote.state > 0">
                  <a :disabled="preventBidsheetDownload" class="link" v-on:click="bidsheetPdfView(quote.reference, false)">Full Bid Sheet</a> |
                  <a :disabled="preventBidsheetDownload" class="link" v-on:click="bidsheetPdfView(quote.reference, true)">Summary Only</a>
                </div>
                <div v-else>
                  No bid sheet is available yet. Edit this design and make something great!
                </div>
                <table>
                  <thead>
                    <tr>
                      <th>Structure</th>                
                      <th>Files</th>                  
                    </tr>
                  </thead>
                  <tbody v-if="structureDetails.length > 0">
                    <tr v-for="detail in structureDetails" :key="detail.id">
                      <td>{{ detail.name }}</td>
                      <td>
                        <div class="structureLinks" v-if="!detail.isPorch">
                          <a class="link" v-on:click="downloadCsvBomExport(quote.reference, detail.name, quote.version)">BOM</a> | 
                          <a class="link" v-on:click="requestMBS(quote.reference, detail.name, quote.version)">MBS</a>
                        </div>
                      
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td></td>
                      <td>No download files are available yet. Edit and save this design and make something great!</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div id="data">
              <label><b>Details:</b></label>
              <div id="sensistiveData" v-if="isAuthenticated" style="padding:5px;">
                <div class="">
                  <span style="font-weight:bold">Price: </span>
                  <label  >{{ formattedPrice }}</label>  
                  <div v-if="quote.state > 0" style="display:inline-block; padding-left: 10px;">
                    <button id="actions-adjustQuote" class="btn btn-success quote-control-btn" v-on:click="adjustQuote(quote.reference)">
                        <i class="fa-sharp fa-solid fa-sliders fa-xl"></i>
                        Adjust Quote
                    </button>
                  </div>
                </div>
                <div class="" >
                  <span style="font-weight:bold">Weight: </span>
                  <label >{{ formattedWeight }}</label>  
                </div>
              </div>
              <div id="generalData" class="" v-if="true" style="padding:5px;">
                <div class="">
                  <span style="font-weight:bold">Last Modified: </span><label> {{ lastModifiedTimestamp }}</label><br/>
                  <span style="font-weight:bold">Status: </span>
                  <label  >{{ dictionary.getTerm(quote.state) }}</label>  
                </div>
              </div>
                
            </div>
            <div id="additionalDocuments">
              <label><b>Additional Documents:</b></label>
              <ul v-if="additionalDocuments.length > 0">
                <li class="link" v-for="fileName in additionalDocuments" :key="fileName"><a v-on:click="downloadFileFromDocFolder(fileName)">{{ fileName }}</a><button v-if="isAuthenticated" v-on:click="confirmAdditionalDocDeletion(fileName)">delete</button></li>
              </ul>
              <p v-else style="padding:5px">No additional files uploaded yet. Drag and drop files over this page to add your own files!</p>
            </div>
          </div>
            
        </div>
      </div>
    </div>

    <message-modal
      :show="msgModal.showMessage"
      :message="msgModal.message"
      :issues="msgModal.issues"
      @close="closeMessage"
    />
    <QuoteAdjustmentModal :qaData.sync="qaData"></QuoteAdjustmentModal>
    <ProjectCopyModal :copyData.sync ="copyData"></ProjectCopyModal>
    </div>
  </div>
</template>

<script>
import ContextLogic from '@/lib/contextLogic';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import Util from '../lib/utility';
import VModal from 'vue-js-modal'
import Vue from 'vue'
import Vuex from 'vuex'
import BusinessUserPicker from '@/components/BusinessUserPicker.vue';
import ProgressSpinner from '@/components/ProgressSpinner.vue';
import MessageModal from '@/components/MessageModal.vue';
import { CORE } from '@/constants';
import QuoteAdjustmentModal from '@/components/QuoteAdjustmentModal.vue';
import ProjectCopyModal from '@/components/ProjectCopyModal.vue';
import Dictionary from '../Dictionary.js'
import api from '@/api';
import AddressInput from '@/components/AddressInput.vue';
Vue.use(Vuex)
Vue.use(VModal, {dialog:true});
export default {
  name: 'ProjectDetailsView',
  components:{
    BusinessUserPicker,
    ProgressSpinner,
    MessageModal,
    QuoteAdjustmentModal,
    ProjectCopyModal,
    AddressInput
  },
  data() {
    return {
      dictionary: [],
      loadingProject: true,
      loadingDetails: true,
      isAuthenticated: false,
      quote: {},
      isNewProject: false,
      isShareMode: false,
      isEditing: false,
      creating: false,
      copying: false,
      saveInProgress: false,
      createBuildingFailed: false,
      isSouthWest: false,
      businessType: -1,
      input: {
        disableSave: false,
        error:{
          show: false,
          msg: ""
        }
      },
      copyData:{
        ref: null,
        show: false,
      },
      structureDetails: [],
      additionalDocuments:[],
      suppliers: [],
      contractors: [],
      isDragging: false,
      dragCounter: 0,
      msgModal:{
        showMessage: false,
        message: '',
        issues: [],
      },
      qaData: {
        ref: null,
        show: false
      }
    };
  },
  metaInfo() {
    return {
      title: this.pageTitle,
      meta: [
        { name: 'description', content: "something" },
        { property: 'og:title', content: this.quote.Name },
        { property: 'og:description', content: "something" },
        { property: 'og:image', content: this.quote.image },
        { property: 'og:url', content: `http://localhost:8080/project/${this.quote.ref}/share`},
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: this.quote.name },
        { name: 'twitter:description', content: "something"},
        { name: 'twitter:image', content: this.quote.image },
        { property: 'og:url', content: `http://localhost:8080/project/${this.quote.ref}/share` }
      ]
    }
  },
  computed: {
    ...mapState('contextModule', ['current', 'contractorParentData', 'user', 'userBusiness']),
    ...mapGetters('contextModule',[
        'userIsSysAdmin']),
    addressData(){
      return {
        formatted: this.quote.address,
        zipCode: this.quote.zipCode
      }
    },
    lastModifiedTimestamp(){
      let date = new Date(this.quote.modified);
      const options = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};
      return date.toLocaleDateString(undefined, options) + ' '+ date.toLocaleTimeString('en-US')
    },
    preventBidsheetDownload(){
      return this.isEditing || this.copying;
    },        
    designUrl(){      
      return `/project/${this.quote.reference}/design`;
    },
    nameValid(){
      return this.quote.name && this.quote.name.length !== 0;
    },
    addressValid(){
      return this.quote.address && this.quote.address.length !== 0;
    },
    supplierUserValid(){
      return this.quote.lvl1UserId != -1;
    },
    inputIsDisabled(){
      let disabled = !this.isEditing || this.isShareMode || !this.isAuthenticated;
      console.log(`inputIsDisabled: ${disabled}`)
      return disabled
    },
    formattedPrice(){
        return Util.formattedPrice(this.quote.price);
    },
    formattedWeight(){
      return Util.formattedWeight(this.quote.weight)
    }
    


  },
  methods: {
    ...mapActions('projectModule', ['fetchProject', 'createBuildingQuote', 'updateBuildingQuote', 
    'requestCsvBomExport', 'fetchMBS', 'generateBuildingQuotePDFV3',
    'fetchStructureDetails', 'fetchFileNamesInProjectDocFolder', 'uploadDocuments', 'requestFileFromDocFolder', 'deleteAdditionalDocument']),
     ...mapActions('businessModule', ['fetchAllBusinesses', 'fetchAllSuppliers', 'fetchSubBusinesses', 'fetchContractorsByRep', 'fetchBusiness']),
     ...mapActions('userModule', ['fetchUser', 'fetchUsersFromSupplier', 'fetchUsersFromContractor']),
    
    setAddress(v){
      this.quote.address = v.formattedAddress;
      this.quote.zipcode = v.zipCode;
    },
    navigateBack(){
      this.$router.go(-1) ;
    },

    onDragEnter(e) {
      this.dragCounter++;
      this.isDragging = true;
    },
  
    onDragLeave(e) {
      this.dragCounter--;
      if(this.dragCounter ==- 0)
        this.isDragging = false;
    },
  
    onDrop(e) {
      this.dragCounter = 0;
      e.preventDefault();
      this.isDragging = false;
      const filesToUpload = e.dataTransfer.files;
      let existingFiles = [];
      if(this.additionalDocuments.length>0){
        existingFiles = this.detectExistingFiles(filesToUpload);
      }
   
      if(existingFiles.length > 0){
        let existingNamesList = existingFiles.map(name => `<li><b>${name}</b></li>`).join('');
        this.$modal.show('dialog', {
          title: 'Overwrite existing file(s)?',
          text: `<ul>
                  ${existingNamesList}
                </ul> <br>
                File(s) with this name already exists. Are you sure you want to override to replace it? This action cannot be undone.<br><br>`,
          buttons:[
            {
              title: 'Cancel',
              handler: () => {
                this.$modal.hide('dialog')
              }
            },
            {
              title: 'Overwrite',
              handler: () => {
                this.$modal.hide('dialog')
                this.handleFiles(filesToUpload);
              }
            },
          ]
        })
      }
      else  
        this.handleFiles(filesToUpload);
      
    },
    detectExistingFiles(files){
      let filesThatAlreadyExist = [];
      for(let i = 0; i < files.length; i++){
        const file = this.additionalDocuments.find(f => f === files[i].name)
        if(file){
          filesThatAlreadyExist.push(file);
        }
      }
      return filesThatAlreadyExist;
    },
    async handleFiles(files) {
      let formData = new FormData();
      for(let i = 0; i < files.length; i++){
        const file = files[i];
        formData.append('documents', file, file.name)
      }
      // files.forEach(file => {
      //   formData.append('document', file, file.name)
      // });

      let payload = {ref: this.quote.reference, documents: formData}

      let response = await this.uploadDocuments(payload);
      if(response.isError){
        let issues = response.data.issues ? response.data.issues : [];
        this.$toast.error(`Error occurred during file upload: ${response.data.msg}`);
        //this.showErrorMessage(response.data.msg, issues);
      }
      else{
        // this.showSuccessMessage("File(s) uploaded successfully");
        this.$toast.success("File(s) uploaded successfully")
        this.additionalDocuments = await this.fetchFileNamesInProjectDocFolder(this.quote.reference);
      }
    },
    async confirmAdditionalDocDeletion(fileName){
      this.$modal.show('dialog', {
          title: 'Confirm delete?',
          text: `Are you sure you want to delete ${fileName}?`,
          buttons:[
            {
              title: 'Cancel',
              handler: () => {
                this.$modal.hide('dialog')
              }
            },
            {
              title: 'Delete',
              handler: () => {
                this.$modal.hide('dialog')
                this.removeAdditionalDoc(fileName);
              }
            },
          ]
      }) 
    },
    async removeAdditionalDoc(fileName){
      
      let payload = {ref: this.quote.reference, fileName: fileName}
      let response = await this.deleteAdditionalDocument(payload);
      if(response.isError){
        this.$toast.error(response.data.msg);
      }
      else{
        // this.showSuccessMessage("File(s) uploaded successfully");
        this.$toast.success("File deleted sucessfully.")
        this.additionalDocuments = await this.fetchFileNamesInProjectDocFolder(this.quote.reference);
      }
      
    },
    async setProjectEditMode(){
      if(!this.$route.path.includes("edit")){
        //window.history.replaceState({}, '', window.location.pathname + '/edit'); // this allows the url to visually change without losing state
        //  this.$router.push(`${this.$route.path}/edit`); // this causes a navigation and we lose the state we just set
      }
      this.isEditing=true;
    },
    inputfieldChanged(){
      // this.isEditing = true;
      this.input.error.show = false;

      let msg = this.buildErrorMsg();
      if (msg) {
          this.input.disableSave = true;
          this.input.error = {
              msg,
              show: true
          }
      } else {
          this.input.disableSave = false;
          this.input.error = {
              msg: null,
              show: false
          }
      }
    },
    buildErrorMsg() {
      let fieldList = [];
      if (this.quote.name.length === 0) fieldList.push("Name");

      if(this.quote.address.length === 0){
          fieldList.push('address');
      }


      if (fieldList.length > 0)
          return "Invalid Fields: " + fieldList.join(', ');

      if (this.quote.frameType === "Weld up" || this.quote.frameType === "Weld PLUS") {
          if (parseFloat(this.quote.width) > 60)
              return "Weld-up building width cannot exceed 60ft.";
      }

      if (/[\\/:*?"<>|]/.test(this.quote.name))
          return "A building name cannot contain any of the following characters: \\ / : * ? \" < > |";

      return null;
    },
    async commitAndLaunchDesigner(){
      console.log('new building quote', this.quote)
      await this.commitDetails();
      if (!this.createBuildingFailed)      
          this.launchDesigner();
    },
    async commitDetails(){
      if(!(this.nameValid && 
      this.addressValid && 
      this.supplierUserValid))
      {
        this.input.disableSave = true;
        this.input.error = {
            msg: "Please fill in all required fields",
            show: true
        }
        return;
      }
      else
        this.input.disableSave = false;

      this.input.error = {
          msg:'',
          show: false
      }

      // this.geocodeLocation();
      this.saveInProgress = true;
      let response;
      if(this.creating)
        response = await this.createBuildingQuote(this.quote);
      else
        response = await this.updateBuildingQuote(this.quote);

      if(response.isError){ 
        this.commitDetailsError(response.data)
      }
      else{
        this.creating = false;
        this.isEditing = false;
        this.saveInProgress = false;
        this.createBuildingFailed = false;
        console.log(response);
        this.quote = response; // client learns id and reference
       }
    },
    
    launchDesigner(){
      if(this.creating || this.isEditing || this.copying)
          return;
      let designerUrl;
      this.$router.push(this.designUrl);
    },
    commitDetailsError(errData){
      this.createBuildingFailed = true;
      if(errData.locked){
        this.showAlert_QuoteTemplateLocked();
      }
      else{
        this.$modal.show('dialog', {
            title: 'Warning',
            text: `${errData.msg}`,
            buttons:[
              {
                title: 'OK',
                handler: () => {
                  this.$modal.hide('dialog')
                }
              },
            ]
        })
      }
        
    },
    showAlert_QuoteTemplateLocked(){
      this.$modal.show('dialog', {
          title: 'Warning',
          text: `Could not complete the operation becasue this design's quote file is locked. Please close the quote file and try again.`,
          buttons:[
            {
              title: 'OK',
              handler: () => {
                this.$modal.hide('dialog')
              }
            },
          ]
      })
    },
    async shareProject(){
      await navigator.clipboard.writeText('');
      try {
        // Generate the shareable link (you may need to adjust this based on your routing structure)
        const shareableLink = `${process.env.VUE_APP_API_BASE_URL}/share/${this.quote.reference}`;
        
        // Copy the link to clipboard
        await navigator.clipboard.writeText(shareableLink);
        
        // Show a toast notification
        this.$toast.success(`Share link copied to clipboard!`);
      } catch (err) {
        console.error('Failed to copy link:', err);
        this.$toast.error('Failed to copy link. Please try again.');
      }
    },
    async openProjectFolder(){
      window.open(this.quote.projectDirectoryLink, '_blank');
    },
    copyProject(){
      this.copyData.ref = this.quote.reference;
      this.copyData.show = true;
    },
    async setProjectArchiveState(){
      this.quote.archive = !this.quote.archive
      await this.commitDetails();
    },
    async downloadFileFromDocFolder(fileName){
      let response = await this.requestFileFromDocFolder({id:this.quote.reference, name: fileName});
      if(response.isError){
        this.$toast.error(response.data.msg);
      }
      else{
        let fileData = response;
        let filename = fileData.fileDownloadName;// this.quoteName.replace(/[^a-z0-9_\-]/gi, '_').replace(/_{2,}/g, '_') + ".USR";
        var blob = Util.b64toBlob(fileData.fileContents, fileData.contentType);
        Util.downloadBlob(blob, filename);
        this.$toast.success("File downloaded successfully!")
      }
    },
    async downloadCsvBomExport(ref, structureName){
      let response = await this.requestCsvBomExport({id:ref, name: structureName});
      if(response.isError){
        this.commitDetailsError(response.data)
      }
      else{
        let bomData = response;
        let filename = `${structureName}_BOM.csv`;// this.quoteName.replace(/[^a-z0-9_\-]/gi, '_').replace(/_{2,}/g, '_') + ".USR";
        var blob = Util.b64toBlob(bomData.fileContents, bomData.contentType);
        Util.downloadBlob(blob, filename);
      }

    },
    async requestMBS(ref, structureName) {
      let response = await this.fetchMBS({id: ref, name: structureName});
      if(response.isError){
        this.commitDetailsError(response.data)
      }
      else{
        let mbsData = response;
        let filename = "DesCtrlQuote.USR";// this.quoteName.replace(/[^a-z0-9_\-]/gi, '_').replace(/_{2,}/g, '_') + ".USR";
        var blob = Util.b64toBlob(mbsData.fileContents, mbsData.contentType);
        Util.downloadBlob(blob, filename);
      }
    },
    async bidsheetPdfView(ref, summaryOnly) {
      let response = await this.generateBuildingQuotePDFV3({reference: ref, summaryOnly: summaryOnly});
      if(response.isError){
        this.commitDetailsError(response.data);
      }
      else{
        //https://localhost:7291/projects/openBuildingQuotePDFV3/44253%20-%20border%20%232%20-%20%20Full.pdf?quoteRef=30453986-f00d-4524-8eab-1f7ae92ae53d&summaryOnly=false
        //quoteRef=30453986-f00d-4524-8eab-1f7ae92ae53d&summaryOnly=false
        let filename =encodeURIComponent(response.filename);
        let bidsheetViewUrl = api.getApiRequestPath(`/project/openBuildingQuotePDFV3/${filename}`);
        bidsheetViewUrl+=`?quoteRef=${ref}`
        bidsheetViewUrl+=`&summaryOnly=${summaryOnly}`
        window.open(bidsheetViewUrl);
      }
    },
    async onSupplierUserSelected(id){
      this.contractors = await this.fetchContractorsByRep(id);
      this.quote.lvl1UserId = id;
      this.inputfieldChanged();
    },
    async onContractorUserSelected(userId){
      this.quote.lvl2UserId = userId;
      this.inputfieldChanged();
    },
    adjustQuote(ref){
      this.qaData.ref = ref;
      this.qaData.show = true;
    },
    async init_Anonymous(){
      await this.initAnonymous_PickerData();
    },
    
    async initAnonymous_PickerData(){
      this.$refs.supplierPicker.disablePickers();
      this.$refs.contractorPicker.disablePickers();

      // end-user should never be shown supplier info
      this.$refs.supplierPicker.showBusiness = false; 
      this.$refs.supplierPicker.showUser = false;

      this.$refs.contractorPicker.showBusiness = false;
      this.$refs.contractorPicker.showUser = false;

      //this.$refs.supplierPicker.users = [await this.fetchUser(this.quote.lvl1UserId)];
      //this.$refs.supplierPicker.onUserSelected(this.quote.lvl1UserId);

      // this.$refs.contractorPicker.users = [await this.fetchUser(this.quote.lvl2UserId)];
      // this.$refs.contractorPicker.onUserSelected(this.quote.lvl2UserId);
    },
    async init_Authenticated(){
      await this.initPickerData();  
    },
    async initPickerData(){
      console.log(this.userBusiness.type);
      // Use context to figure out who the heck this project is being made for.
      if(this.businessType == CORE.entities.enterprise){
        this.$refs.supplierPicker.showBusiness = true;
        this.$refs.supplierPicker.showUser = true;
        this.$refs.contractorPicker.showBusiness = true;
        this.$refs.contractorPicker.showUser = true;


        // We have absolutely no clue.
        this.suppliers = await this.fetchAllSuppliers();


        // If it's a pre-exisitng project, we have some clue who the supplier is
        // because every project should have a supplier attached to it
        if(!this.isNewProject){
          if(this.quote.lvl1UserId > -1){
            let lvl1User = await this.fetchUser(this.quote.lvl1UserId);
            this.$refs.supplierPicker.onBusinessSelected(lvl1User.businessId);
            this.$refs.supplierPicker.onUserSelected(this.quote.lvl1UserId);
          }
          if(this.quote.lvl2UserId > -1){
            let lvl2User = await this.fetchUser(this.quote.lvl2UserId);
            this.$refs.contractorPicker.onBusinessSelected(lvl2User.businessId);
            this.$refs.contractorPicker.onUserSelected(this.quote.lvl2UserId);
          }

        }
          
      }
      else if(this.businessType == CORE.entities.supplier){
        // We know who the project supplier is, but not the comapny user nor the contractor user the project is assigned to.
        this.$refs.supplierPicker.showBusiness = false;
        this.$refs.supplierPicker.showUser = true;
        this.$refs.contractorPicker.showBusiness = true;
        this.$refs.contractorPicker.showUser = true;

        // get the users from the supplier
        this.$refs.supplierPicker.users = await this.fetchUsersFromSupplier(this.userBusiness.id);

        // If it's a pre-exisitng project, we have some clue who the supplier is
        // because every project should have a supplier attached to it
        if(!this.isNewProject){
          if(this.quote.lvl1UserId > -1){
            //let lvl1User = await this.fetchUser(this.quote.lvl1UserId);
            this.$refs.supplierPicker.onUserSelected(this.quote.lvl1UserId);
          }
          if(this.quote.lvl2UserId > -1){
            let lvl2User = await this.fetchUser(this.quote.lvl2UserId);
            this.$refs.contractorPicker.onBusinessSelected(lvl2User.businessId);
            this.$refs.contractorPicker.onUserSelected(this.quote.lvl2UserId);
          }

        }
      }
      else{
        // We know who the project supplier, supplier user, and contractor is, but not the contractor user the project is assigned to.
        this.quote.lvl1UserId = this.contractorParentData.rep.sub

        this.$refs.supplierPicker.showBusiness = false;
        this.$refs.supplierPicker.showUser = false;
        this.$refs.contractorPicker.showBusiness = false;
        this.$refs.contractorPicker.showUser = true;
        this.$refs.contractorPicker.users = await this.fetchUsersFromContractor(this.userBusiness.id);     
        
        // If it's a pre-exisitng project, we have some clue who the supplier is
        // because every project should have a supplier attached to it
        if(!this.isNewProject){
          if(this.quote.lvl2UserId > -1){
            this.$refs.contractorPicker.onUserSelected(this.quote.lvl2UserId);
          }

        }
      }      
    },
    showSuccessMessage(message) {
      this.msgModal.message = message
      this.msgModal.issues = []
      this.msgModal.showMessage = true
    },
    showErrorMessage(message, issues = []) {
      this.msgModal.message = message
      this.msgModal.issues = issues
      this.msgModal.showMessage = true
    },
    closeMessage() {
      this.msgModal.showMessage = false
    },
    async postAuthInit(){
      // now that the user is authenticated...
      await ContextLogic.initContext(this);        
      this.businessType = this.userBusiness.type;
      let swFeatureflag = this.userBusiness.featureFlags.find(flag => flag.name == 'isSouthwestMetal');
      let isSystemAdmin = this.userIsSysAdmin;
      this.isSouthWest = isSystemAdmin || ((swFeatureflag) ? swFeatureflag.enabled : false);
      // await this.init_Authenticated(); // this cannot be done here. this.quote must be filled in before we set the pickers
    }
  },
  watch: {
  async '$auth.isAuthenticated'(newVal, oldVal) {
    // coming from a hard-refresh, $auth may take a second to initialize
    if(newVal && this.mounted){
        this.postAuthInit()
    }
  }
},
  async mounted(){
    console.log('contractor parent data',this.contractorParentData)
     let pathParts = this.$route.path.split("/");
     let projectRef = pathParts[2];
      this.isAuthenticated = this.$auth.isAuthenticated;
      if(this.isAuthenticated){
        // when clicking links to navigate, this.$auth will already be initialized
        await this.postAuthInit()

      }

      this.isEditing = this.$route.path.includes("edit") || this.$route.path.includes("new");

      console.log('ProjectView State is New:', this.$route.path.includes('new'))
      this.isNewProject = this.$route.path.includes('new');
      this.isShareMode = this.$route.path.includes('share');



      if(this.isNewProject){
        if(!this.isAuthenticated)
          this.$router.go(-1) ;
        
        
        const newBuildingQuote = {
          id: 0,
          name: "",
          address: '',
          zipCode: '',
          lvl1UserId: -1,
          lvl2UserId: -1,
          archive: false,
          tax: "No - Exempt",
          delivered: false,
          design: "",
          image: "",
          reference: '',
          buildingCode: "IBC 2015",
          seals: true,
          state: 0,    // 0 is NEW
          notes: "",
          internalQuoteTemplateNotes:"",
          version:3,
          priceScheme: 0
        };
        this.quote = newBuildingQuote;

        this.creating = true;
        // tempEditing = true;
        this.copying = false;
        this.loadingProject=false;
        this.loadingDetails=false;
      } 
      else{
        this.quote = await this.fetchProject(projectRef);
        if(!this.quote.id)
          return; 

        if(this.isAuthenticated)
          this.init_Authenticated();

        this.loadingProject=false;     
        this.structureDetails = await this.fetchStructureDetails(this.quote.id);
        this.additionalDocuments = await this.fetchFileNamesInProjectDocFolder(this.quote.reference);    
        this.loadingDetails=false;
        this.$refs.address.setAddressDetails({
          formattedAddress: this.quote.address,
          zipcode:this.quote.zipCode
        })

        // tempEditing = false;
      }

      if(this.isShareMode || !this.isAuthenticated){
        this.initAnonymous_PickerData();
      }
  },
  async created(){
    this.dictionary = new Dictionary();

  },  

}
</script>

<style lang="scss" scoped>
// Variables
$primary-color: #3498db;
$secondary-color: #2c3e50;
$background-color: #f8f9fa;
$border-color: #e0e0e0;
$text-color: #333;
$hover-color: #f1f8ff;
$green-color: #2ecc71;
$danger-color: #e74c3c;
$box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
$inputLabelColor: #eee;
$tablet-width: 768px;

// Mixins
@mixin transition($property: all, $duration: 0.3s) {
  transition: $property $duration ease;
}

// Main Container
.projectView {
  background-color: $background-color;
}

// Drag and Drop Functionality
.dragAndDrop {
  margin: 20px;

  &.dragging {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
    border: 2px dotted #000;
    border-radius: 8px;
  }
}

// Project Details Section
.project-details,
.project-downloads {
  color: $text-color;
  border-radius: 8px;
  box-shadow: $box-shadow;
  padding: 20px;

  .details {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @media (max-width: $tablet-width) {
      flex-direction: column;
    }
  }
}

// Project Image Section
.project-image-wrapper {
  width: 90%;
  margin: 5%;

  @media (min-width: $tablet-width) {
    width: 50%;
  }

  .building-image {
    max-width: 75%;
    height: auto;
  }
}

// Details Group Section
.details-group {
  width: 100%;
  margin: 1%;

  @media (min-width: $tablet-width) {
    width: 50%;
  }

  // Project Actions
  .project-actions {
    display: flex;
    margin: 2% 2% 2% 0%;

    button {
      margin-right: 2%;
    }

    .btn {
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 16px;
      @include transition;
    }
  }

  // Additional Project Actions
  .additional-project-actions {
    display: flex;
    margin-top: 2%;

    .btn {
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 16px;
      @include transition;
    }
  }

  // Price Weight Section
  .price-weight-wrapper {
    display: flex;

    @media (max-width: $tablet-width) {
      display: block;
    }
  }

  // Input Groups
  .input-group {
    display: flex;
    align-items: center;

    span {
      padding: 6px 12px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      color: #555;
      background-color: #eee;
      border: 1px solid #ccc;
      border-radius: 4px 0 0 4px;
      white-space: nowrap;
    }

    input[type="text"],
    input[type="number"],
    select {
      flex-grow: 1;
      padding: 6px 12px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      color: #555;
      border: 1px solid #ccc;
      border-radius: 0 4px 4px 0;
      border-left: none;

      &:focus {
        outline: none;
        border-color: $primary-color;
      }
    }

    input[type="checkbox"] {
      margin-left: 10px;
    }
  }
}

// Notes Section
#notes-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  span {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px 4px 0 0;
    width: 100%;
  }

  textarea {
    width: 100%;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #555;
    border: 1px solid #ccc;
    border-radius: 0 0 4px 4px;
    resize: vertical;

    &:focus {
      outline: none;
      border-color: $primary-color;
    }
  }
}

// Form Actions
.form-actions {
  display: flex;
  gap: 10px;
  width: 100%;
  margin-top: 20px;

  .btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    @include transition;

    &.btn-primary {
      background-color: $green-color;
      color: white;

      &:hover {
        background-color: darken($green-color, 10%);
      }
    }

    &.btn-secondary {
      background-color: $primary-color;
      color: white;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }

    &.btn-cancel {
      background-color: $danger-color;
      color: white;

      &:hover {
        background-color: darken($danger-color, 10%);
      }
    }
  }
}

// Assets Section
#assets {
  text-align: left;
  width: 50%;
  margin: 1%;

  table {
    border-collapse: collapse;

    th,
    td {
      padding: 5px;
      border: 1px solid black;
    }
  }
}

// Data Section
#data {
  text-align: left;
  width: 50%;
  margin: 1%;
}

#additionalDocuments{
  width: 50%;
  text-align: left;
}

// Utility Classes
.link {
  cursor: pointer;
  text-decoration: underline;
}

.required {
  color: red;
}


</style>