import * as THREE from 'three'
export default class MemHelper {
    static dispose(object){
        if(!object)
            return;
        if(object.isGroup)
            object.children.forEach((c)=>{this.dispose(c);});
        else
            this.disposeMesh(object)
            
    }

    static disposeNode(node) {
        //https://medium.com/@driesdesmet_69747/a-tale-of-three-js-snapshots-and-angular-2-or-how-memory-leaks-bad4e9882669
        if (node.isMesh) {
            node.parent = undefined;
            if (node.geometry) {
                node.geometry.dispose();
            }
    
            let material = node.material;
            if (material) {
    
                if (material.map) material.map.dispose();
                if (material.lightMap) material.lightMap.dispose();
                if (material.bumpMap) material.bumpMap.dispose();
                if (material.normalMap) material.normalMap.dispose();
                if (material.specularMap) material.specularMap.dispose();
                if (material.envMap) material.envMap.dispose();
    
                material.dispose();
            }
        } else if (node instanceof THREE.Object3D) {
            node.parent.remove(node);
            node.parent = undefined;
        }
    }

    static disposeMesh(mesh){
        if(!mesh)
            return;
        //console.group(`disposing mesh ${object.name}`)
        if(mesh.geometry)
            mesh.geometry.dispose();
        
        if(mesh.material)
        {
            
            if(mesh.material.map)
            {
                //console.log('map');
                mesh.material.map.dispose();
            }
            if(mesh.material.bumpMap)
            {
                //console.log('bumpMap');
                mesh.material.bumpMap.dispose();
            }
            if(mesh.material.alphaMap)
            {
                //console.log('alphaMap');
                mesh.material.alphaMap.dispose();
            }

            if(mesh.material.dispose)
            {
                //console.log('material');
                mesh.material.dispose();
            }
        }
        //console.groupEnd();
        
    }

    static removeAllChildren(object){
        if(!object || !object.children)
            return;
        if(object.children.length==0)
            return;
        
        while(object.children.length>0)
        {
            if(object.name === 'gWall')
        {
            //console.log('gwalll');
        }
            if(object.type === 'Group')
                this.removeAllChildren(object.children[0]);            

            this.dispose(object.children[0]); // dispose group or mesh resources
            
            object.remove(object.children[0]); // remove group or mesh from scene
            
        }
    }
}