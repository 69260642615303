import * as THREE from 'three';
import Util from '../utility.js'
import {CORE} from '../_spec.js'
import RoofBase from './RoofBase.js'
import layerHelper from '../helpers/layerHelper.js';

import _3dTrimEaveGutter from '../3d/TrimEaveGutter.js'
import _3dTrimEaveAngle from '../3d/TrimEaveAngle.js'
import _3dTrimRake from '../3d/TrimRake.js'
import _3dPurlinC from '../3d/PurlinC.js'
import _3dPurlinZ from '../3d/PurlinZ.js'
import _3dEaveStrut from '../3d/EaveStrut.js'
import _3dRidgeCapSmooth from '../3d/RidgeCapSmooth.js'
import { Euler, Quaternion } from 'three';
import BlueprintHelper from '../helpers/blueprintHelper.js';
import EarcutDataManager from '../helpers/EarcutDataManager.js';
import SheetingHelper from '../helpers/SheetingHelper.js';
import Sheeting from '../3d/Sheeting.js';
import materialHelper from '../helpers/materialHelper.js';


/*
moving toward an object which is independent of blueprint (so that it is generic)
design: {
    dim: {
        width: 40, // horizontal width.
        length: 60 // horizontal length
    }, 
    
    pitch: {
        ratio:
        radians:
        degrees:
    },

    end:{
        left:{        
            style: 'rake' or 'none',
            overhang: 6,            
            wrapEnable: true,
            wrapAngle: 45
        },
        right: {
            style: 'rake' or 'none',
            overhang: 6,
            wrapEnable: true,
            wrapAngle: 45
        }
    }
    side:{
        front:{
            style: 'gutter',
            segments: [],
            overhang: 6
        },
        back: {
            style: 'angle',
            segments: [],
            overhang: 6
        }
    }
}


build(pos, rot)

*/

export default class RoofPorchSlope extends RoofBase{
    constructor(design, frameType, width, length, trimMaterials, pos, rot, rotTexture, pitch, purlinColor){
        super(design)
        this.group.name = "CompGroup RoofSlope";        // component group
        this.group.position.copy(pos);
        this.group.rotateY(rot);


        if(Util.isUndefined(this.design.left))
            this.design.left={
                wrap:false
            }
        
        if(Util.isUndefined(this.design.right))
            this.design.right={
                wrap:true
            }

        this.rotTexture = rotTexture;
        
        this.width = width;
        this.length = length;
        this.halfLength = this.length/2;
        this.halfWidth = this.width/2;
        this.pitchRatio = BlueprintHelper.pitchToPitchRatio(pitch);
        this.pitchRadians = BlueprintHelper.pitchRatioToRadians(this.pitchRatio);        
        this.pitchDegrees = BlueprintHelper.pitchRatioToDegrees(this.pitchRatio);
        this.pitchedPurlinDimY = BlueprintHelper.pitchedPurlinDimY(this.pitchRatio);
        this.pitchHeightInches = BlueprintHelper.pitchHeight(this.width, this.pitchRatio, design.roofType);
        
        let backRoofHeightInches = 0+this.pitchHeightInches;
        this.backLeftRoofPos = new THREE.Vector3(-this.halfLength,backRoofHeightInches,-this.halfWidth);
        // bp5 this.frontLeftRoofPos.z
        this.frontLeftRoofPos = new THREE.Vector3(-this.halfLength,0,this.halfWidth);
        // bp6 this.backRightRoofPos
        this.backRightRoofPos = new THREE.Vector3(this.halfLength,backRoofHeightInches,-this.halfWidth);
        // bp7 this.frontRightRoofPos
        this.frontRightRoofPos = new THREE.Vector3(this.halfLength,0,this.halfWidth);

        this.pitchPurlinDimY =  BlueprintHelper.pitchedPurlinDimY(this.pitchRatio);
        this.RidgePurlinPosY =  this.pitchHeightInches - this.pitchPurlinDimY;

        this.frontLeftEavePos = new THREE.Vector3(-this.halfLength,0-this.pitchPurlinDimY,this.halfWidth);
        this.frontRightEavePos = new THREE.Vector3(this.halfLength,0-this.pitchPurlinDimY,this.halfWidth);
        this.backLeftEavePos = new THREE.Vector3(-this.halfLength,this.RidgePurlinPosY,-this.halfWidth);
        this.backRightEavePos = new THREE.Vector3(this.halfLength,this.RidgePurlinPosY,-this.halfWidth);
        
        this.purlinType = CORE.roof.purlin.types.C;
        if(frameType)
            this.purlinType = CORE.roof.purlin.types.Z;

        // bp10 this.pitchPurlinDimY

        // //let frontEavePosY = columnPosY + frameDimY - pitchedPurlinDimY;
        // //let frontLeftEavePos = new THREE.Vector3(leftEndPosX,frontEavePosY,frontSidePosZ);
        // this.frontLeftEavePos = new THREE.Vector3(0,0-this.pitchPurlinDimY,this.width);

        // //let frontRightEavePos = new THREE.Vector3(rightEndPosX,frontEavePosY,frontSidePosZ);
        // this.frontRightEavePos = new THREE.Vector3(this.length,0-this.pitchPurlinDimY,this.width);

        // this.backLeftEavePos = new THREE.Vector3(0,0-this.pitchPurlinDimY,0);
        // this.backRightEavePos = new THREE.Vector3(this.length,0-this.pitchPurlinDimY,0);


        this.eaveOverhangLength = 0; // 2 inch eave overhang (this value is not properly applied to the rake trim)
        
        // the amount of extension depends on both 
        // 1) the width of the porch 
        // 2) the pitch of the porch

        // width alone gives you the proper value at 0 pitch.
        // the pitch of the adjacent porch (and therefore this porch) determines how much to subtract via *Math.cos()

        this.rakeLength = this.frontLeftRoofPos.distanceTo(this.backLeftRoofPos) 
        
        this.wrapExtX = ((this.rakeLength+(this.eaveOverhangLength*.99))*Math.cos(this.pitchRadians))
        this.horizontalLength = this.frontLeftRoofPos.distanceTo(this.frontRightRoofPos);
        this.frontLeftExt = RoofBase.calcEaveOverhangPoint(this.backLeftRoofPos, this.frontLeftRoofPos, this.eaveOverhangLength);
        if(this.design.left.wrap)
            this.frontLeftExt.x -= this.wrapExtX;

        this.frontRightExt = RoofBase.calcEaveOverhangPoint(this.backRightRoofPos, this.frontRightRoofPos,this.eaveOverhangLength);
        if(this.design.right.wrap)
            this.frontRightExt.x += this.wrapExtX;
            
        this.purlinColor = purlinColor;
        this.buildPorchPurlins()
        this.buildEaves();          
        this.buildPanels();      
        this.buildEaveTrim(trimMaterials.eaveAndRake); // 
        this.buildPorchRakeTrim(trimMaterials.eaveAndRake); // done adjusting for wrapping
        this.buildHipCap(trimMaterials.eaveAndRake);
    }

    buildHipCap( material){
        if(!this.design.left.wrap)
            return;

        let rise = this.backLeftRoofPos.y-this.frontLeftExt.y;
        let cornerLength = this.backLeftRoofPos.distanceTo(this.frontLeftExt);
        let cornerPitch = Math.asin(rise/cornerLength);

        let qRot = new THREE.Quaternion().multiplyQuaternions(
            new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0,1,0).normalize(),Math.PI/4), // order matters
            new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0,0,1).normalize(),cornerPitch),      
        ).normalize();
        
        let midPos = this.backLeftRoofPos.clone().lerp(this.frontLeftExt,.5);
        midPos.y +=.11; // lift the ridge cap vertically above the roof panel
        this.cap = new _3dRidgeCapSmooth(this.group, cornerLength, 8, material, midPos, qRot, 0);        
        layerHelper.enableLayer(this.cap.group, CORE.layers.quote);
    }

    buildPorchRakeTrim(material){
        //Reference: https://res.cloudinary.com/internachi/image/fetch/f_auto,q_auto:best/https://s3.amazonaws.com/uploads-east-1.nachi.org/gallery-images/roofing/terminology/rake-eave-overhang.jpg
        // eave overhang is how far the roof overshoots the side wall, pushing the eaves out and down at the roof's slope
        // rake overhand is how far the roof overshoots the end walls, pushing the rake trim out perfectly horizontaly

        let eaveTrimMatingAngle = 0;
        //let eaveTrimExtension = 3; 
        
        if(this.design.gutters){
            eaveTrimMatingAngle = Math.PI/4;
            //eaveTrimExtension = 0;
        }

        
        //let eaveTrimLerpExt = (this.eaveOverhangLength/length)/2; //half of the ratio of the extension to the overall length
        let pitchRadians = Math.atan(this.pitchRatio);
        
        let mid;
        if(!this.design.left.wrap){            
            mid= this.frontLeftExt.clone();
            mid = mid.lerp(this.backLeftRoofPos,.5);
            let length = this.frontLeftExt.distanceTo(this.backLeftRoofPos);
            let leftRakeTrim = new _3dTrimRake(length, material,pitchRadians, 0, 0, eaveTrimMatingAngle);
            this.group.add(leftRakeTrim.group);
            layerHelper.enableLayer(leftRakeTrim.group, CORE.layers.quote)
            leftRakeTrim.group.position.set(mid.x, mid.y, mid.z);
            leftRakeTrim.group.rotation.y = -Math.PI/2;
            leftRakeTrim.group.rotation.x = Math.atan(this.pitchRatio);        
            this.trimRakes.push(leftRakeTrim);
        }
        
        if(!this.design.right.wrap){
            mid = this.frontRightExt.clone();
            mid = mid.lerp(this.backRightRoofPos,.5);
            let length = this.frontRightExt.distanceTo(this.backRightRoofPos);
            let rightRakeTrim = new _3dTrimRake(length, material, 0, pitchRadians, eaveTrimMatingAngle,0 );
            this.group.add(rightRakeTrim.group);
            layerHelper.enableLayer(rightRakeTrim.group, CORE.layers.quote)
            rightRakeTrim.group.position.set(mid.x, mid.y, mid.z);
            rightRakeTrim.group.rotation.y = Math.PI/2;
            rightRakeTrim.group.rotation.x = Math.atan(this.pitchRatio);
            this.trimRakes.push(rightRakeTrim);
        }
    }    

    buildEaveTrim(trimMaterial){


        let length = this.frontLeftExt.distanceTo(this.frontRightExt);
        let mid = this.frontLeftExt.clone();
        mid = mid.lerp(this.frontRightExt,.5);
        let trim;
        if(this.design.gutters) {
            trim =new _3dTrimEaveGutter(length, trimMaterial, undefined, undefined,Math.PI/4,Math.PI/4);
            layerHelper.enableLayer(trim.group, CORE.layers.quote);
            trim.group.position.set(mid.x, mid.y, mid.z);
            trim.group.rotation.x = Math.atan(this.pitchRatio);
        }
        else{
            trim =new _3dTrimEaveAngle(length, 90-this.pitchDegrees, trimMaterial, undefined, undefined, 0,0);
            layerHelper.enableLayer(trim.group, CORE.layers.quote);
            trim.group.position.set(mid.x, mid.y+.05, mid.z); //.95 for just under the roof
            trim.group.rotation.y = 0;
            trim.group.rotation.x = Math.atan(this.pitchRatio);
        }
        this.group.add(trim.group);
        this.trimEaves.push(trim);
        return trim;
        // Porches have no back eave trim
    }


    buildPanels(){
        this.outlineMeshes=[];
        let frontRoofPoints = [
            this.backLeftRoofPos.x, 0, // left ridge
            this.backRightRoofPos.x, 0, // right ridge
            this.frontRightExt.x, this.rakeLength,
            this.frontLeftExt.x, this.rakeLength,
        ];
        let front = new EarcutDataManager()
        front.setOutline(frontRoofPoints)

        /*
        let frontTieIns = this.getFrontTieIns();
        frontTieIns.forEach((t)=>{
            let halfWidth = t.width/2;            
            
            let leftX = t.offset;//-halfWidth;
            let centerX = t.offset + halfWidth;
            let rightX = t.offset+t.width;
            let childPitchRatio = BlueprintHelper.pitchToPitchRatio(t.pitch)
            let childPitchHeight = BlueprintHelper.pitchHeight(t.width, childPitchRatio, CORE.roof.types.gable);            
            let childPitchHypotenuse = childPitchHeight / Math.sin(this.pitchRadians);

            let p1 = new THREE.Vector2(-this.length/2 + leftX, rakeLength)
            let p2 = new THREE.Vector2(-this.length/2+ centerX , rakeLength - childPitchHypotenuse)
            let p3 = new THREE.Vector2(-this.length/2 + rightX, rakeLength)
            if(p2.y <0)
                p2.y =0;
            front.addTriangularHole(p1, p2, p3);
        });
        */
        //front.addSquareHole(0,240,300,300)
        
        let frontData = front.generate();
        //let offsetX = this.design.left.wrap?.279:0;
        let offsetX = this.design.left.wrap?0:0;
        
        let frontRoofPanelTop = SheetingHelper.defineBackPlane(materialHelper.getExteriorPanelPbrMaterial(this.design.color), false, offsetX);
        let frontRoofPanelBottom = SheetingHelper.defineFrontPlane(materialHelper.getInteriorPanelPbrMaterial(0xEEEEED));

        let frontSheeting = new Sheeting(
            CORE.preferences.des_levelOfDetail.value,
            frontData, 
            frontRoofPanelTop,
            frontRoofPanelBottom,            
            CORE.layers.roof );

        let frontRoof = new THREE.Group();
        frontRoof.position.y = this.pitchHeightInches+.1 // .1 prevents clipping of eave strut
        frontRoof.rotation.x = Math.PI/2 + this.pitchRadians;
        frontRoof.add(frontSheeting.group)
        frontRoof.position.z = -this.width/2;
        this.group.add(frontRoof);
        layerHelper.setGroup(frontSheeting.group, CORE.layers.roof)
        layerHelper.enableLayer(frontSheeting.group,CORE.layers.quote);
        /////
        // generate outline-specific mesh.
        // because the collision zones are being obscured by transparent outline and pickDetection meshes: https://stackoverflow.com/questions/11165345/three-js-webgl-transparent-planes-hiding-other-planes-behind-them

        this.addOutlineMeshesToGroup(frontRoofPoints, frontRoof, true);
    }

    buildPorchPurlins(){
        
        // magic number 8 below is pretty arbitrary. Found during refactor of 100% consistent frame widths to varying widths
        let height = 0 - this.pitchedPurlinDimY;
        let wrappedPurlinBackoff = 2;
        let frontSideLeftEndSquare = new THREE.Vector3(-this.halfLength,height,this.halfWidth); // we need this to get the correct pitched porch width, which is used to space out the purlins

        let frontSideLeftEnd = this.frontLeftExt.clone();
        if(this.design.left.wrap)
            frontSideLeftEnd.x+=wrappedPurlinBackoff
        frontSideLeftEnd.y -= this.pitchedPurlinDimY;
        let backSideLeftEnd = new THREE.Vector3(-this.halfLength,this.RidgePurlinPosY,-this.halfWidth);

        let frontSideRightEnd = this.frontRightExt.clone()
        if(this.design.right.wrap)
            frontSideRightEnd.x-=wrappedPurlinBackoff
        frontSideRightEnd.y -= this.pitchedPurlinDimY;
        let backSideRightEnd = new THREE.Vector3(this.halfLength,this.RidgePurlinPosY ,-this.halfWidth);

        let dist = backSideLeftEnd.distanceTo(frontSideLeftEnd); // get the full run distance to calculate backoffs
        let frontSideLeftEndRidgeBackoff = new THREE.Vector3();        
        frontSideLeftEndRidgeBackoff.lerpVectors(frontSideLeftEnd,backSideLeftEnd,(dist-12)/dist);
        
        let frontSideRightEndRidgeBackoff = new THREE.Vector3();                
        frontSideRightEndRidgeBackoff.lerpVectors(frontSideRightEnd,backSideRightEnd,(dist-12)/dist);

        let backSideLeftEndRidgeBackoff = new THREE.Vector3();        
        backSideLeftEndRidgeBackoff.lerpVectors(backSideLeftEnd,frontSideLeftEnd,(dist-12)/dist);
        let backSideRightEndRidgeBackoff = new THREE.Vector3();        
        backSideRightEndRidgeBackoff.lerpVectors(backSideRightEnd,frontSideRightEnd,(dist-12)/dist);

        dist = frontSideLeftEndRidgeBackoff.distanceTo(frontSideLeftEndSquare);

        // front side
        let currLeftFront = new THREE.Vector3().copy(frontSideLeftEnd);
        let currRightFront = new THREE.Vector3().copy(frontSideRightEnd);

        // number of purlins (dist / spacing)
        let count = Math.ceil(dist / CORE.roof.purlin.spacing.min);
        let spacing = dist / count;        

        // front side
        
        for(let i=1;i<=count;i++){
            currLeftFront.lerpVectors(frontSideLeftEnd,frontSideLeftEndRidgeBackoff,(i*spacing)/dist);
            currRightFront.lerpVectors(frontSideRightEnd,frontSideRightEndRidgeBackoff,(i*spacing)/dist);
            
            let pos = currLeftFront.clone().lerp(currRightFront, .5); 
            let len = currLeftFront.distanceTo(currRightFront);
            let rot = 
                new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1,0,0),this.pitchRadians)
            let p;
            if(this.purlinType === CORE.roof.purlin.types.C)
                p = new _3dPurlinC(CORE.preferences.des_levelOfDetail.value, len, pos, rot, this.purlinColor);
            else
                p = new _3dPurlinZ(CORE.preferences.des_levelOfDetail.value, len, pos, rot, this.purlinColor);

            //pos.y += CORE.roof.purlin.dim.height/2;
            p.group.position.copy(pos);
            p.group.rotation.copy(rot);
            this.group.add(p.group);
            layerHelper.enableLayer(p.group,CORE.layers.quote);
            this.purlins.push(p);
        }
    }

    

    buildEaves(){
        
        let collisionZones = false;

        let left= this.frontLeftExt.clone();
        let right= this.frontRightExt.clone();
        left.y -= this.pitchedPurlinDimY;
        right.y -= this.pitchedPurlinDimY;
        let len = left.distanceTo(right);
        let pos = left.lerp(right, .5);

        // TODO: these quaternions are hacky. They could be calculated inside EaveStrut with existing information as they are consistent, front to back.
        let frontQRot = new THREE.Quaternion().multiplyQuaternions(
            new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1,0,0),this.pitchRadians), 
            new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0,1,0),0)
        );
        pos.y+=this.pitchedPurlinDimY/2;

        let frontEave = new _3dEaveStrut(CORE.preferences.des_levelOfDetail.value, len, pos, frontQRot, collisionZones, this.pitchRatio, this.purlinColor);
        this.group.add(frontEave.group);
        layerHelper.enableLayer(frontEave.group, CORE.layers.quote);
        this.eaves.push(frontEave);

        //porches need no back side eave strut
    }   
}