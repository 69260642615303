import * as THREE from 'three';
import ColumnBase from './ColumnBase.js'
import {CORE} from '../_spec.js'
import { Object3D, Vector2, Vector3, ZeroCurvatureEnding } from 'three';
import ShapeHelper from '../helpers/shapeHelper';
import CollisionHelper from '../helpers/CollisionHelper.js';
import MemHelper from '../helpers/memHelper.js';
import Util from '../utility.js';
import { info } from 'autoprefixer';
import materialHelper from '../helpers/materialHelper.js';
import Quad from './Quad.js';
import Tri from './Tri.js';
import layerHelper from '../helpers/layerHelper.js';
/*https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Ibeam.svg/520px-Ibeam.svg.png

|---------FLANGE---------|__
************************** |
************************** |
            **             | 
            **             |              
            **             | 
            **             DEPTH
            **             | 
            **             | 
            **             | 
            **             | 
************************** |
**************************__
*/
export default class ColumnStraight extends ColumnBase{
    constructor(lod, length, addTransition=true, addCollisionZone, bpFrameLineDim, matingRafterHeight, topPitch, bolted, color ){		
        // remove side  

		super(lod, new Vector3(), new Vector3(), color);
        this.length = length;
		this.group.name ='ColumnStraight I-beam';

		this.addCollisionZone = addCollisionZone;
		this.dimensions = bpFrameLineDim;
        if(this.dimensions===undefined){
            console.log('top')
        }
		this.matingRafterHeight = matingRafterHeight;
		this.pitchAngle = topPitch;
        this.bolted = bolted;

        this.extrusionSettings = {
            depth: .75, //used to be amount
            bevelEnabled: false,
            bevelThickness: 0.1,
            bevelSize: 1,
            bevelSegments: 1,
            material: 0,
            extrudeMaterial: 1
        };
        this.addTransition = addTransition;
        this.build();

        
    }

	build(){
		// draw a beam
        this.buildColumn();        

        if(this.addTransition)
        {
            this.buildTransition();		
            let pt = new Vector3(0,this.length,-this.dimensions.depth)        
            this.transitionPiece.rotation.y = -Math.PI/2;        
            this.transitionPiece.position.copy(pt);
            this.group.add(this.transitionPiece);
        }
        layerHelper.setGroup(this.group, CORE.layers.frame, true);            
        
        if(this.addCollisionZone){
            
            // this used to be the 3d mesh.
            this.group.children[1].userData = {
                collisionZones: this.buildCollisionZones()
            };
        }
	}

    

    buildColumn(){
        
		let flangeWidth = this.dimensions.flange;
		let webWidth =this.dimensions.depth;
        
        
         // Create a custom texture for this object
         let texture = CORE.textures.beam.clone();
         texture.wrapT = texture.wrapS = THREE.MirroredRepeatWrapping;
         //this.texture.wrapT = THREE.ClampToEdgeWrapping
         texture.encoding = THREE.sRGBEncoding;
         //this.texture.anisotropy = 32;
        // 40' width => ~20' rafter => 240" rafter, which uses repeat 1,1 
        // 60' length => 720" purlin. 720 at repeat 1 would stretch the texture, so we scale the length inversely so repeat comes out to 1 per 240 inches.
        // inches in length / 240 => repeat value
        // 720/240 => 3

         // 144 inch tall column /.6 = 240
         
         //144 * .005 = .72 
         // 8 * .1 = .8
         
         //this.texture.repeat.set(dimY * .005, flangeWidth * .1); // good
         //this.texture.repeat.set(dimY * .005, flangeWidth * .1); // 
         texture.repeat.set(1,1); //  great
         
         //this.texture.repeat.set(halfFlangeWidth/texScale, dimY/texScale);
 
         
		if(this.isLowDetail())
            this.buildColumn_LD(flangeWidth, webWidth);
        else
            this.buildColumn_HD(flangeWidth, webWidth, texture);
	}

    buildColumn_LD(flangeWidth, webWidth){
        // since the flanges are planes in low detail, add their thickness to the web
        //webWidth+=2*CORE.steel.thickness_in;         
        let halfWebDepth  = webWidth/2;

        let material = materialHelper.getFrameMaterial_LD(this.color);
		var geoFlange = new THREE.PlaneBufferGeometry(flangeWidth, this.length, 1, 1)
		geoFlange.name = 'ColumnStraight geoFlange';

		var outsideFlange = new THREE.Mesh(geoFlange, material);
		outsideFlange.name = 'ColumnStraight flange1';        
		//outsideFlange.position.z = +CORE.steel.thickness_in
		outsideFlange.position.y += this.length / 2;
        
        //inside beam flange
		var insideFlange = new THREE.Mesh(geoFlange, material);
		insideFlange.name = 'ColumnStraight flange2';        
		insideFlange.position.z = -webWidth //+ CORE.steel.thickness_in;
		insideFlange.position.y += this.length / 2;
        

		//var sizeMiddle = new THREE.Vector3(CORE.steel.thickness_in, this.length, webWidth);
		var geoWeb = new THREE.PlaneBufferGeometry(webWidth, this.length, 1, 1)
		geoWeb.name = 'ColumnStraight geoMiddle';
		var web = new THREE.Mesh(geoWeb, material);
		web.name = 'ColumnStraight web';
		web.rotation.y = Math.PI/2;
		//meshWeb.position.x -= (flangeWidth / 2);
		web.position.y += this.length / 2;
        web.position.z= -halfWebDepth// + CORE.steel.thickness_in;

		web.castShadow = false;
		outsideFlange.castShadow = false;
		insideFlange.castShadow = false;
		this.group.add(
			outsideFlange,
			insideFlange,
			web
		);
		
		outsideFlange.layers.set(CORE.layers.frame)
		insideFlange.layers.set(CORE.layers.frame)
		web.layers.set(CORE.layers.frame)
    }

    buildColumn_HD(flangeWidth, webWidth, texture){
        let halfFlangeWidth=flangeWidth/2;
		let halfWebDepth = webWidth/2;
        let material = materialHelper.getFrameMaterial_HD(this.color, texture)

        /*
        from above, the I beam is built this way (pre-rotation internal or external to this class)

        O => origin

        -X

        | |          | | 
        | |          | |
      O | |----------| |   -Z
        | |----------| |
        | |          | |
        | |          | |

        +X

        */



       // outside flange shape
       let meshOutsideFlange = this.getExtrudedMesh([
           new Vector2(-halfFlangeWidth,0),
           new Vector2(-halfFlangeWidth,this.length),
           new Vector2(halfFlangeWidth,this.length),
           new Vector2(halfFlangeWidth,0)
       ],
       material
       );
       this.group.add(meshOutsideFlange);
       meshOutsideFlange.position.set(0,0,-CORE.steel.thickness_in);

       // depth shape		
       this.meshDepth = this.getExtrudedMesh([
           new Vector2(-halfWebDepth,0),
             new Vector2(-halfWebDepth,this.length),
           new Vector2(halfWebDepth,this.length),
           new Vector2(halfWebDepth,0)
       ],
       material
       )
       this.meshDepth.position.set(0,0,-halfWebDepth);
       this.meshDepth.rotation.y = -Math.PI/2;
       this.group.add(this.meshDepth);

       // inside flange shape
       var meshInsideFlange = this.getExtrudedMesh([
               new Vector2(-halfFlangeWidth,0),
               new Vector2(-halfFlangeWidth,this.length),
               new Vector2(halfFlangeWidth,this.length),
               new Vector2(halfFlangeWidth,0)
           ],
           material
       )
       meshInsideFlange.position.set(0,0,-webWidth);
       this.group.add(meshInsideFlange);
    }
	
    getWeldTransitionDimensions(){
        let growthHeight = this.dimensions.depth * Math.tan(this.pitchAngle);
        // calculate the purely vertical height of the transition piece at the rafter
        let transitionHeightInside = growthHeight; 
        // calculate the purely vertical height of the transition piece at the eave
        let transitionHeightOutside = 0;

        return {
            transitionHeightInside,
            transitionHeightOutside
        }
    }

    getBoltTransitionDimensions(){
        let growthHeight = this.dimensions.depth * Math.tan(this.pitchAngle);
        // calculate the purely vertical height of the transition piece at the rafter
        let transitionHeightInside = this.matingRafterHeight;
        // calculate the purely vertical height of the transition piece at the eave
        let transitionHeightOutside = this.matingRafterHeight - 1*growthHeight

        return {
            transitionHeightInside,
            transitionHeightOutside
        }
    }

    buildTransition_HD(halfFlangeWidth, tranDims, texture){

        let material = materialHelper.getFrameMaterial_HD(this.color, texture);

        if(this.bolted)
        {
            // Transition (Out)Side Flange
            var meshTransitionOutsideFlange = this.getExtrudedMesh([
                new Vector2(-halfFlangeWidth,0),
                new Vector2(-halfFlangeWidth, tranDims.transitionHeightOutside),
                new Vector2(halfFlangeWidth, tranDims.transitionHeightOutside),
                new Vector2(halfFlangeWidth,0)
            ]
            ,        material
            );
            meshTransitionOutsideFlange.rotation.y = Math.PI/2;
            meshTransitionOutsideFlange.position.x = this.dimensions.depth - CORE.steel.thickness_in;
            this.transitionPiece.add(meshTransitionOutsideFlange)

            // Transition Bottom Flange        
            var meshTransitionBottomFlange = this.getExtrudedMesh([
                new Vector2(-halfFlangeWidth,0),
                new Vector2(-halfFlangeWidth, this.dimensions.depth),
                new Vector2(halfFlangeWidth, this.dimensions.depth),
                new Vector2(halfFlangeWidth,0),
            ]
            ,        material
            );
            meshTransitionBottomFlange.rotation.z = Math.PI/2; // Euler rotation order matters
            meshTransitionBottomFlange.rotation.x = Math.PI/2; // Euler rotation order matters
            meshTransitionBottomFlange.position.x = this.dimensions.depth
            this.transitionPiece.add(meshTransitionBottomFlange)
        }
        else{
             // Transition (In)Side Flange
             var meshTransitionInsideFlange = this.getExtrudedMesh([
                new Vector2(-halfFlangeWidth,0),
                new Vector2(-halfFlangeWidth, tranDims.transitionHeightInside),
                new Vector2(halfFlangeWidth, tranDims.transitionHeightInside),
                new Vector2(halfFlangeWidth,0)
            ]
            ,        material
            );
            meshTransitionInsideFlange.rotation.y = Math.PI/2;
            //meshTransitionSideFlange.position.x = this.dimensions.depth
            this.transitionPiece.add(meshTransitionInsideFlange)
        }

        // Transition Top Flange        
        var meshTransitionTopFlange = this.getExtrudedMesh([
            new Vector2(-halfFlangeWidth,0),
            new Vector2(-halfFlangeWidth, this.dimensions.depth/Math.cos(this.pitchAngle)),
            new Vector2(halfFlangeWidth, this.dimensions.depth/Math.cos(this.pitchAngle)),
            new Vector2(halfFlangeWidth,0),
        ]
        ,        material
        );

        meshTransitionTopFlange.rotation.z = Math.PI/2; // Euler rotation order matters
        meshTransitionTopFlange.rotation.x = Math.PI/2; // Euler rotation order matters
        meshTransitionTopFlange.rotation.y = -this.pitchAngle; // Euler rotation order matters
        meshTransitionTopFlange.position.x = this.dimensions.depth
        meshTransitionTopFlange.position.y = tranDims.transitionHeightOutside;
        this.transitionPiece.add(meshTransitionTopFlange)

        // welded frame needs 3 points
        let depthPoints = [
            new Vector2(0,0),
            new Vector2(0, tranDims.transitionHeightInside),
            new Vector2(this.dimensions.depth, tranDims.transitionHeightOutside),            
        ]
        // bolted frame needs 4 points
        if(this.bolted)
            depthPoints.push(new Vector2(this.dimensions.depth,0));
        // Transition Depth        	
        var meshTransitionDepth = this.getExtrudedMesh(depthPoints,material);        
        this.transitionPiece.add(meshTransitionDepth)
    }


    
    buildTransition_LD(depth, flangeWidth, tranDims, texture){
        ;
        let material = materialHelper.getFrameMaterial_LD(this.color);

        if(this.bolted)
        {
            // Transition (Out)Side Flange
            let geoTransitionOutsideFlange = new THREE.PlaneBufferGeometry(flangeWidth, tranDims.transitionHeightOutside, 1, 1)            
            let meshTransitionOutsideFlange = new THREE.Mesh(geoTransitionOutsideFlange, material);
            meshTransitionOutsideFlange.rotation.y = Math.PI/2;
            meshTransitionOutsideFlange.position.x = depth
            meshTransitionOutsideFlange.position.y += tranDims.transitionHeightOutside/2;
            //meshTransitionOutsideFlange.position.x += CORE.steel.thickness_in;
            this.transitionPiece.add(meshTransitionOutsideFlange)
            
            // Transition Bottom Flange      
            let geoTransitionBottomFlange = new THREE.PlaneBufferGeometry(flangeWidth, depth, 1, 1)     
            let meshTransitionBottomFlange =  new THREE.Mesh(geoTransitionBottomFlange, material);
            meshTransitionBottomFlange.rotation.z = Math.PI/2; // Euler rotation order matters
            meshTransitionBottomFlange.rotation.x = Math.PI/2; // Euler rotation order matters
            meshTransitionBottomFlange.position.x = depth/2
            this.transitionPiece.add(meshTransitionBottomFlange)
            
        }
        else{
            
             // Transition (In)Side Flange
             let geoTransitionInsideFlange = new THREE.PlaneBufferGeometry(flangeWidth, tranDims.transitionHeightInside, 1, 1)            
            let meshTransitionInsideFlange = new THREE.Mesh(geoTransitionInsideFlange, material);
            meshTransitionInsideFlange.rotation.y = Math.PI/2;
            //meshTransitionSideFlange.position.x = depth
            this.transitionPiece.add(meshTransitionInsideFlange)
        }

        // Transition Top Flange        
        let geoTransitionTopFlange = new THREE.PlaneBufferGeometry(flangeWidth, depth/Math.cos(this.pitchAngle), 1, 1)            
        let meshTransitionTopFlange = new THREE.Mesh(geoTransitionTopFlange, material);
        meshTransitionTopFlange.rotation.z = Math.PI/2; // Euler rotation order matters
        meshTransitionTopFlange.rotation.x = Math.PI/2; // Euler rotation order matters
        meshTransitionTopFlange.rotation.y = -this.pitchAngle; // Euler rotation order matters
        meshTransitionTopFlange.position.x = depth/2
        meshTransitionTopFlange.position.y = tranDims.transitionHeightOutside 
        this.transitionPiece.add(meshTransitionTopFlange)

        let bl = new Vector3(0, 0, 0)
        let br = new Vector3(depth, 0, 0)
        let tr = new Vector3(depth, tranDims.transitionHeightOutside, 0)
        let tl = new Vector3(0, tranDims.transitionHeightInside, 0)
        

        let triTopRight = new Tri(
            'bottom right triangle',
            [
                // counter clock-wise
                br, //bottom right 
                tr, // top right 
                tl, // top left 
            ],
            [
                              
                1.0, 0, // bottom right
                1.0, 1, // top right
                0.0, 1.0 // top left
            ]
        );

        let triBottomLeft = new Tri(
            'top left triangle',
            [
                br, // bottom right
                tl, // top left 
                bl, // bottom left 
            ],
            [
                1.0, 0, // bottom right
                0.0, 1, // top left
                0.0, 0, // bottom left
            ]
        );
        let meshBottomLeft = new THREE.Mesh(triBottomLeft.geometry, material);
        let mesTopRight = new THREE.Mesh(triTopRight.geometry, material);
        
        // bolted frame needs 4 points
        this.transitionPiece.add(meshBottomLeft)
        if(this.bolted)
            this.transitionPiece.add(mesTopRight)
    }

    buildTransition(){
        ///////////////////
        // Transition Piece
        // This is the "corner" steel between the column and the rafter and ranges in shape from slightly funky to very funky
        
        /*
        O => origin

        slightly funky (triangle) for weld up
        Y+
         |\
         | \
         |  \
         |___\
        O       X+

        
        very funky (half a trapezoid) for bolt up
        Y+
         |\
         | \
         |  \
         |   \
         |   |
         |   |
         |___|
        O      X+


        */

        this.transitionPiece = new THREE.Group();
        
        let tranDims;

        if(this.bolted)
            tranDims = this.getBoltTransitionDimensions();
        else
            tranDims = this.getWeldTransitionDimensions();
        
        let halfFlangeWidth = this.dimensions.flange/2;

         // Create a custom texture for this object
         let texture = CORE.textures.beam.clone();
         texture.wrapT = texture.wrapS = THREE.MirroredRepeatWrapping;
         texture.encoding = THREE.sRGBEncoding;        
         texture.repeat.set(1,1); // not great
        
		if(this.isLowDetail())
            this.buildTransition_LD(this.dimensions.depth,this.dimensions.flange, tranDims, texture);
        else
            this.buildTransition_HD(halfFlangeWidth, tranDims, texture);
        
    }

    
    getExtrudedMesh(points, material){
        let mesh = ShapeHelper.getMeshFromPoints( 
            points,        
        this.extrusionSettings,
        material );        
        mesh.layers.set(CORE.layers.frame);
        mesh.castShadow=true;
        mesh.receiveShadow=true;
        return mesh;
    }


}
