import { CORE,impactTypes  } from '../_spec.js';
import * as THREE from 'three';
import BayEnd from './BayEnd.js';
import BlueprintHelper from '../helpers/blueprintHelper.js'
import _3dDistHori from '../3d/DistHori.js'
import { Quaternion, Vector3 } from 'three';
import SheetingHelper from '../helpers/SheetingHelper.js';
import materialHelper from '../helpers/materialHelper.js';

export default class BayEndLeft extends BayEnd {
    constructor(des,
        structureConfig, 
        bayStart,
        bayEnd,
        bayLength,
        wallLength,        
        maxHeight,
        trimMaterials,
        cbDesignChange,
        girtHeights,
        pos,
        rot,
        supportsWainscot,
        footerCollisions,
        addToQuoteLayer,
        allowDripTrim,
        insulation,
        pitchRatio,
        roofType,
        frameType,
        distToNextFrameline,
        stopFrameLine,
        beamColor,
        girtColor,
        options,
        shedHoles) {            
        des.type = CORE.components.bayEndLeft;
        //let shapePoints = BlueprintHelper.generatePointsWithOffset(bayStart, bayEnd, length, maxHeight, roofType, pitchRatio, 0, length/2)

        let shapePoints = BlueprintHelper.generateBayPoints(bayStart, bayEnd, wallLength, maxHeight, des.height, roofType, pitchRatio, CORE.sides.leftEnd)
        shapePoints.forEach((point)=>
            {
                point.x -= bayStart;
            }
        );

        super(des, structureConfig,bayLength, maxHeight, true, trimMaterials, shapePoints,  cbDesignChange, girtHeights,pos,rot, CORE.sides.leftEnd, supportsWainscot, footerCollisions, addToQuoteLayer,allowDripTrim,insulation,
            pitchRatio,
            roofType,
            frameType,
            distToNextFrameline,
            stopFrameLine, beamColor, girtColor, options,shedHoles)
    }

    getWallBackPlane(){
        return SheetingHelper.defineBackPlane(materialHelper.getInsulationMaterial(0xEEEEEE, this.length, CORE.textures.insulation));
    }

    getWallFrontPlane(){
        return SheetingHelper.defineFrontPlane(materialHelper.getExteriorPanelPbrMaterial(this.structureConfig.wallColor,this.length));
    }

    getWainscotBackPlane(){
        return SheetingHelper.defineBackPlane(materialHelper.getInteriorPanelPbrMaterial(0xEEEEEE, this.length));
    }

    getWainscotFrontPlane(){
        return SheetingHelper.defineFrontPlane(materialHelper.getExteriorPanelPbrMaterial(this.structureConfig.wainscotColor,this.length));
    }
    
    getInteriorSide(){
        return THREE.BackSide
    }

    getExteriorSide(){
        return THREE.FrontSide;
    }
    
    migrate(design){
        super.migrate(design);

        switch(design.v){
            case 1:
                //this.migrateTo2(design);
            break;
        }
        
    }

    
    // required by object interface
   
    getDescription(){
        return `Bay ${this.design.index+1}`
    }    

    // because the collision zones are OOBs, they don't spin around 
    getFooterCollisionZoneCenter(){        
        let world = this.getWorldPositionFromRelative(new Vector3(this.wallMid, 0,0))
        return world;
    }
    
    //TODO-WALL: a wall really shouldn't need to know about framelines. 
    framelineIndexAbsolute(){
        // the absolute frameline (as opposed to a relative frameline, where 0 is local, and 1 is toward the opposite end of the building, starting from either end)
        return this.design.frameLine;
    }

    //TODO-WALL: a wall really shouldn't need to know about framelines. 
    // A) Whatever this is driving should be provided specifically (like distance to next frameline), OR
    // B) the parent component using the walls can put the backbracing in place as necessary, since that's a framing thing and spans two frameelines.
    nextInteriorFramelineIndexAbsolute(){
        return this.framelineIndexAbsolute()+1;
    }

    getComponentDistanceMarkers(comp){      
        let des = comp.design;
        //goal 1: build is list of columns with size and position data.
        // sideWalls have column positions only for OH doors

        let low, high, lowColPos,highColPos, offset,lowWinPos,highWinPos;
        let markerMarginZ = 24;

        //console.table(this.design.columnPositions);
        let searchMargin =2;

        let lowWinX = des.pos.x - des.dim.width/2;
        let highWinX = des.pos.x + des.dim.width/2;
        this.design.columnPositions.forEach((cp)=>{
            // TODO: This seems hacky. 
            // are all columns 4 inches wide? I doubt it.
            cp.width=4;

            if(cp.pos < lowWinX - searchMargin){
                low = cp;
            } else if(cp.pos > highWinX + searchMargin && !high){
                high = cp;
            }
        })

        if(!low || !high)
            return;

        //console.log(low, high)
        let lowColPosX = low.pos; // center of the column width
            // if a corner column, measure from the outside
        if(low.index===0)
            lowColPosX-=low.width/2;// outside edge for first frameline
        if(low.index===this.design.columnPositions.length-1)
            lowColPosX+=low.width/2; // outside edge for last frameline

        let highColPosX = high.pos; // center of the column width
        if(high.index===0)
            highColPosX-=high.width/2;// outside edge for first frameline
        if(high.index===this.design.columnPositions.length-1)
            highColPosX+=high.width/2; // outside edge for last frameline

        lowColPos = new THREE.Vector3(lowColPosX, des.pos.y, des.pos.z);
        highColPos = new THREE.Vector3(highColPosX, des.pos.y, des.pos.z);
        
        offset = new THREE.Vector3(0,0,markerMarginZ);

        lowWinPos = new THREE.Vector3(lowWinX, des.pos.y, des.pos.z);
        highWinPos = new THREE.Vector3(highWinX, des.pos.y, des.pos.z);
        
        let newLowDh = new _3dDistHori(lowColPos, lowWinPos, new THREE.Vector3(),  offset, 1, CORE.layers.openingDimensions, new Vector3(20,10,1));
        this.group.add(newLowDh.group);
        let newHighDh = new _3dDistHori(highColPos, highWinPos, new THREE.Vector3(), offset, 1,  CORE.layers.openingDimensions,new Vector3(20,10,1));
        this.group.add(newHighDh.group);

        return  { 
            newLowDh,
            newHighDh
        }
    }    



}