import { CORE , impactTypes } from '../_spec';
import {Vector3 } from 'three';
import * as THREE from 'three';
import BaySide from './BaySide.js';
import BlueprintHelper from '../helpers/blueprintHelper';
import _3dDistHori from '../3d/DistHori.js'
import SheetingHelper from '../helpers/SheetingHelper';
import materialHelper from '../helpers/materialHelper';
import Wall_Side from './Wall_Side';
import Util from '../utility';
import BaySideFront from './BaySideFront.js';
import DesignHelper from '../helpers/DesignHelper.js';
import { faTachometerAverage } from '@fortawesome/pro-duotone-svg-icons';
import MemHelper from '../helpers/memHelper.js';
export default class FSW extends Wall_Side {
    constructor(
        masterDesign,
        des, 
        structureConfig,
        length,
        maxHeight,              
        trimMaterials, 
        girtHeights, 
        frame,
        supportsWainscot, 
        footerCollisions, 
        addToQuoteLayer,
        allowDripTrim,
        insulation,
        structureLength,
        beamColor,
        girtColor,
        options,
        shedHoles
        ) {            
            
        des.type = CORE.components.fsw;
        // generate points in the xy plane, z = 0;
        super(masterDesign, des, structureConfig, length, maxHeight, true, trimMaterials, girtHeights, frame, supportsWainscot, footerCollisions, addToQuoteLayer,allowDripTrim,insulation, beamColor, girtColor, options) ;
        this.wallOpenings = shedHoles
        this.frames = [];        
        this.frame.frameLines.forEach((fl)=> 
          {
            // TODO: remove posX defect
            this.frames.push({posX:fl.posX + structureLength/2,index:fl.index, width:fl.width});
            }
        );
        this.groupBays = new THREE.Group();
        this.groupBays.name = "bays";        
        this.build();
        this.group.add(this.groupBays);
    }

    getEditableComponentTreeItems(){
        return super.getEditableComponentTreeItems();
    }


////////////////
// Bay building scheme
////////////////
// Wall_Base.buildDynamicOnly() calls Wall_Base.calculateBayDesigns  // handled by Wall_End, Wall_FSW, and Wall_BSW)
// Wall_Base.buildDynamicOnly() calls Wall_Base.buildComponents()
// Wall_Base.buildComponents() calls Wall_Base.buildComponent(component)
// Wall_Base.buildComponent(component) calls buildBay(component)


    
    buildBay(bayDesign){
        


        // c is an existing bay
        //bayDesign = c.design;
        let index = bayDesign.index;
        
        let start = this.frame.design.columnPositions[index];
        let end = this.frame.design.columnPositions[index+1];

        // We have to shift the bay length whenever there is an open bay before or after so the sheeting covers the frame
        let bays = this.design.getFrontSideBays();
        let previousBay = bays[index-1]
        let thisBay = bays[index]
        let nextBay = bays[index+1]
        if (previousBay === undefined || previousBay.openWall === true)
            start -= 4;
        if (nextBay === undefined || nextBay.openWall === true)
            end += 4;

        let bayLength = end-start;

        let bayHoles = this.convertWallHoleToBayHoles(this.dynamicWallOpenings, start, end);
        
        // create a bay component
        let bay = new BaySideFront(bayDesign, 
            this.structureConfig,
            start, 
            end, 
            bayLength,
            this.length,
            this.maxHeight,
            this.trimMaterials, 
            this.girtHeights,
            this.frameLines, 
           
            this.supportsWainscot, 
            this.footerCollisions, 
            this.addToQuoteLayer,
            this.allowDripTrim,
            this.insulation,
            0,        
            length,
            this.beamColor,
            this.girtColor,
            this.options,
            bayHoles
            );
            
            bay.group.position.x = start
            //bay.group.position.z = start/10

            // add the bay to the scene graph
            this.groupBays.add(bay.group);
            return bay;
    }


    
    
    
    calculateBayDesigns(){
        /* 
        important context for this method:
        bay indices are ascending from wall left (0) to wall right (n). 
        frameline indices are ascending from building left to building right        
        At this moment, the framelines may have just increased or decreased and not align with the number of bays.
        */


        // step 1: remember previous left- and right-most bays
            let bayComponentDesigns = this.design.getFrontSideBays();
            let leftMostBay, rightMostBay;

            // find any existing designs we have and preserve the left-most and right-most bay
            if(bayComponentDesigns){
                // find the left-most bay
                leftMostBay = bayComponentDesigns.filter((b) => b.index === 0);
                if(leftMostBay.length>0)
                    leftMostBay=leftMostBay[0];
                else
                    leftMostBay=undefined;


                // determine the highestBayIndex, as this must be the old right-most bay 
                let highestIndex=0;
                bayComponentDesigns.forEach((b)=>{
                    if(b.index>highestIndex)
                        highestIndex = b.index;
                });
                rightMostBay = bayComponentDesigns.filter((b) => b.index === highestIndex);
                if(rightMostBay.length>0)
                    rightMostBay=rightMostBay[0];
                else
                    rightMostBay=undefined;
            }

            // step 2: remove all the bays
            // remove all previous bay graphics
            MemHelper.removeAllChildren(this.groupBays);
            // remove all previous bay components
            this.components = this.components.filter((c) => c.design.type != CORE.components.baySideFront);
            this.design.components = this.design.components.filter((c) => c.type != CORE.components.baySideFront);

            // step 3: recreate all the bays, transplanting the old left- and right-most bays where appropriate
            this.frames.forEach((fl,framelineIndex)=>{
                
                if(framelineIndex == this.frames.length-1)
                    return;
                
                let iBayDesign;
                if(framelineIndex==0 && leftMostBay){
                    iBayDesign = leftMostBay;
                }
                else if(framelineIndex=== this.frames.length-2 && rightMostBay)
                {
                    iBayDesign = rightMostBay;
                    iBayDesign.index = framelineIndex; // re-index this transplanted bay
                }
                else 
                {
                    if(bayComponentDesigns){
                        iBayDesign = bayComponentDesigns.filter((b) => b.index == framelineIndex) ;// DesignHelper.getBayId(i, this.frames.length)})
                        if(iBayDesign.length>0)
                        iBayDesign=iBayDesign[0];
                        else
                        iBayDesign=undefined;
                    }
                }
                
                if(Util.isUndefined(iBayDesign)){
                    iBayDesign = DesignHelper.getDefaultBayDesign(framelineIndex, this.frames.length, CORE.components.baySideFront);                    
                    this.SetBayDesignFromWall(this.design, iBayDesign);
                    DesignHelper.addComponent(this.design, iBayDesign)    
                }
                else {
                    //this.SetBayDesignFromWall(this.design, iBayDesign); // we can't call SetBayDesignFromWall here because bay designs are customized by the end skirting tool
                    iBayDesign.wainscot = {...this.design.wainscot}
                    this.design.components.push(iBayDesign);
                }
                    
                
                //let bay = this.buildBay(iBayDesign);

                //this.components.push(bay);
            })
        }

        migrateFramedOpenings(framedOpenings){
            //let framedOpenings = this.design.components.filter((c) => DesignHelper.allowedFramedOpeningsOnBays.includes(c.type));
            let bayDetails = [];
            this.design.getFrontSideBays().forEach((bay, index)=>{
                let start = this.frame.design.columnPositions[index];
                let end = this.frame.design.columnPositions[index+1];
                if(index == 0)
                    start -= 4;
                if(index == this.frames.length - 2)
                    end += 4;
                bayDetails.push({id: bay.id, start: start, end: end, openWall: bay.openWall, design:bay});
            })
            
            for(const desFramedOpening of framedOpenings){
                for(const [index, bay] of bayDetails.entries()){
                    if((desFramedOpening.pos.x - desFramedOpening.dim.width/2) >= bay.start && (desFramedOpening.pos.x+desFramedOpening.dim.width/2) <= bay.end && !bay.openWall){
                        desFramedOpening.pos.x -= bay.start;
                        DesignHelper.addComponent(bay.design, desFramedOpening);
                        this.components = this.components.filter((c) => c.design.id !== desFramedOpening.id);
                        this.design.components = this.design.components.filter((c) => c.id !== desFramedOpening.id);
                        break;
                    }
                    else{
                        if(index < bayDetails.length - 1){
                            if((desFramedOpening.pos.x - desFramedOpening.dim.width/2) >= bay.start && (desFramedOpening.pos.x - desFramedOpening.dim.width/2) < bayDetails[index + 1].start && (desFramedOpening.pos.x+desFramedOpening.dim.width/2) >= bay.end && (desFramedOpening.pos.x+desFramedOpening.dim.width/2) < bayDetails[index + 1].end && !bay.openWall){
                                desFramedOpening.pos.x -= bay.start;
                                DesignHelper.addComponent(bay.design, desFramedOpening);
                                this.components = this.components.filter((c) => c.design.id !== desFramedOpening.id);
                                this.design.components = this.design.components.filter((c) => c.id !== desFramedOpening.id);
                                break;
                            }
                        }
                    }
                }
            }
        }

    getDescription(){
            return 'Front Side Wall'
    }    

    getFooterCollisionZoneCenter(){        
        let world = this.getWorldPositionFromRelative(new Vector3(this.wallMid, 0,0))
        return world;
    }

    getComponentDistanceMarkers(comp){

        let des = comp.design;
        //goal 1: build is list of columns with size and position data.
        // sideWalls have column positions only for OH doors

        let low, high, lowColPos,highColPos, offset,lowWinPos,highWinPos;
        let markerMarginZ = 24;
        // use framebase or framebase.frameLines to discover the true size and location of frameLine columns on the sideWall.
        let lowWinX = des.pos.x - des.dim.width/2;
        let highWinX = des.pos.x + des.dim.width/2;
        this.frames.forEach((fl)=>{   
            // TODO: remove posX defect         
            if(fl.posX < lowWinX){
                low = fl;                        
            } else if(fl.posX > highWinX && !high){
                high = fl;
            }
        })

        //console.log(low, high)

        if(!low || !high)
            return;
            // TODO: remove posX defect
        let lowColPosX = low.posX; // center of the column width              

        // if a corner column, measure from the outside
        if(low.index===0)
            lowColPosX-=low.width/2;// outside edge for first frameline
        if(low.index===this.frameLines.length-1)
            lowColPosX+=low.width/2; // outside edge for last frameline
        // TODO: remove posX defect
        let highColPosX = high.posX; // center of the column width
        if(high.index===0)
            highColPosX-=high.width/2; // outside edge for first frameline
        if(high.index===this.frameLines.length-1)
            highColPosX+=high.width/2; // outside edge for last frameline

            
        lowColPos = new THREE.Vector3(lowColPosX, des.pos.y, des.pos.z);
        highColPos = new THREE.Vector3(highColPosX, des.pos.y, des.pos.z);
        offset = new THREE.Vector3(0,0,markerMarginZ);

        lowWinPos = new THREE.Vector3(lowWinX, des.pos.y, des.pos.z);
        highWinPos = new THREE.Vector3(highWinX, des.pos.y, des.pos.z);
    
    

        let newLowDh = new _3dDistHori(lowColPos, lowWinPos, new THREE.Vector3(),  offset, 1, CORE.layers.openingDimensions, new Vector3(20,10,1));
        this.gDynamic.add(newLowDh.group);
        let newHighDh = new _3dDistHori(highColPos, highWinPos, new THREE.Vector3(), offset, 1,  CORE.layers.openingDimensions,new Vector3(20,10,1));
        this.gDynamic.add(newHighDh.group);

        return  { 
            newLowDh,
            newHighDh
        }


    }

}