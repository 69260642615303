import * as THREE from 'three'
import {CORE} from '../_spec.js'
import FoldedGeometryHelper from '../helpers/foldedGeometryHelper.js';
import MemHelper from '../helpers/memHelper.js';
import _3dBase from './Base.js'
import layerHelper from '../helpers/layerHelper.js';
import Sphere from '../3d/Sphere.js'

export default class TrimSoffitRake extends _3dBase {
    constructor(length, width, height, material, leftVertAngle, rightVertAngle, leftHoriAngle, rightHoriAngle, leftEndCap = true, rightEndCap = true){
        super()
        //let sphere = new Sphere();
        //this.group.add(sphere.group)
        
        let folds = [
            {dist:-width},
            {deg:-90},
            {dist:height},            
            {deg:90}, 
                                  
        ];

        
        let fgh = new FoldedGeometryHelper(folds, material, leftEndCap, leftEndCap, true, length, leftVertAngle, rightVertAngle, leftHoriAngle, rightHoriAngle)
        this.group.add(fgh.group);
        this.group.name = 'Soffit Rake Trim'
    }
    

}