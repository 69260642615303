
import Base from './Base'
import OptionHelper from '../helpers/optionHelper.js'
import ColorHelper from '../helpers/colorHelper.js'
import * as THREE from 'three';
import {impactTypes, CORE} from '../_spec.js';
import Util from '../utility.js'

export default class Appearance extends Base{
    constructor(design){
        Appearance.migrate(design);
        super(design);
    }

    static migrate(design) {

        //data migration during beta (introduction of trim color mode)
        if(typeof design.trimColorMode === 'undefined'){
            design.trimColorMode = CORE.modes.trimColor.single;
            design.trims = {
                eaveAndRake: design.trim,
                downspout: design.trim,
                corner: design.trim,
                door: design.trim,
                window: design.trim,
            }
        }

        // smp-color removal migration
        // find anything with "white" color D8E7E9
        // find SMP-Color with FFFFFF
        // make them the new white
        //console.log('appearance migration - design', design);
        
        if (design.roof.code == 'WH' || design.roof.code == 'SC')
          design.roof = ColorHelper.getColorByHex("FFFFFF");;

        if (design.wall.code == 'WH' || design.wall.code == 'SC')
          design.wall = ColorHelper.getColorByHex("FFFFFF");;

        if (design.wainscoting.code == 'WH' || design.wainscoting.code == 'SC')
          design.wainscoting = ColorHelper.getColorByHex("FFFFFF");;

        if (design.trimColorMode == CORE.modes.trimColor.single) {
          if (design.trim.code == 'WH' || design.trim.code == 'SC')
            design.trim = ColorHelper.getColorByHex("FFFFFF");;
        } else if (design.trimColorMode == CORE.modes.trimColor.separate) {
          for (const trimType in design.trims) {
            if (design.trims[trimType].code == 'WH' || design.trims[trimType].code == 'SC') {
              design.trims[trimType] = ColorHelper.getColorByHex("FFFFFF");
            }
          }
        }
        
        if(design.trims.window){
            //https://trello.com/c/7k3A3Jye/170-group-walk-door-colors-with-windows-instead-of-oh-doors
            design.trims.walksAndWindows = Util.copy(design.trims.window);
            delete design.trims.window;
        }
    }




    static mergeDesign(src, tgt ){
        
            tgt.type= CORE.components.appearance,
            tgt.trimColorMode= src.trimColorMode,
            tgt.roof= ColorHelper.getColorByColorId(src.roof.id),
            tgt.wall= ColorHelper.getColorByColorId(src.wall.id),
            tgt.wainscoting= ColorHelper.getColorByColorId(src.wainscoting.id),
            tgt.trim= ColorHelper.getColorByColorId(src.trim.id),
            tgt.trims= {
                eaveAndRake: src.trims.eaveAndRake? ColorHelper.getColorByColorId(src.trims.eaveAndRake.id): src.trims.eaveAndRake,
                downspout: src.trims.downspout? ColorHelper.getColorByColorId(src.trims.downspout.id) : src.trims.downspout,
                corner: src.trims.corner? ColorHelper.getColorByColorId(src.trims.corner.id):src.trims.corner,
                door: src.trims.door?ColorHelper.getColorByColorId(src.trims.door.id):src.trims.door,
                walksAndWindows: src.trims.walksAndWindows?ColorHelper.getColorByColorId(src.trims.walksAndWindows.id):src.trims.walksAndWindows,
            }
        
    }

    defaultDesign(){
        return {
            type: CORE.components.appearance,
            roof: '#',
            wall: '#',
            wainscoting: '#',
            trim: '#'
        }
    }
    
    static canEdit(){return true;}
    static canDelete(){return false;}
    static canCopy(){return false;}

    getOutlineMeshes(){        
        return null;
    }
    getDescription (){
        return `Colors`
    }
    
    getOptionsSpec(){
    
        let opts = [
            OptionHelper.header("Main Colors"),
            OptionHelper.color("roof", "Roof", impactTypes.colorRoof, undefined,
            true, // enabled
            ()=>{//fnIn                     
                return Util.copy(this.design.roof); //make a copy so that changes can be detected later
            },
            undefined,
            (v)=>{ // fnChanged
                let hex = ColorHelper.bangToHex(v.bang); // because the bang is what's bound in vue, while nothing else in this complex object is bound
                this.design.roof= ColorHelper.getColorByHex(hex);
            },
            (vOld,vNew)=>{
                return vOld.bang !== vNew.bang
            }),

            OptionHelper.color("wall", "Wall", impactTypes.colorWall, undefined,
            true, // enabled
            ()=>{//fnIn
                return Util.copy(this.design.wall); //make a copy so that changes can be detected later
            },undefined,
            
            (v)=>{ // fnChanged
                let hex = ColorHelper.bangToHex(v.bang); // because the bang is what's bound in vue, while nothing else in this complex object is bound
                this.design.wall= ColorHelper.getColorByHex(hex);
            },
            (vOld,vNew)=>{
                return vOld.bang !== vNew.bang
            }),

            OptionHelper.color("wainscoting", "Wainscoting", impactTypes.colorWainscot, undefined,
            true, // enabled
            ()=>{//fnIn
                return Util.copy(this.design.wainscoting); //make a copy so that changes can be detected later
            },
            undefined,
            (v)=>{ // fnChanged
                let hex = ColorHelper.bangToHex(v.bang); // because the bang is what's bound in vue, while nothing else in this complex object is bound
                this.design.wainscoting= ColorHelper.getColorByHex(hex);
            },
            (vOld,vNew)=>{
                return vOld.bang !== vNew.bang
            }),

            OptionHelper.checkbox("trimColorMode", "Separate Trim Colors", impactTypes.colorModeTrim,            
            true,
              ()=>{ // fnIn
                return this.design.trimColorMode === CORE.modes.trimColor.separate;
            },
            undefined,
            (v)=>{
              if(v) {
                  this.design.trimColorMode = CORE.modes.trimColor.separate;
                  // initialize the specific trim colors to the single trim color (would this be better to do only if the specific trim color is not set?)
                  let cId = this.design.trim.id;
                  for (const trimType in this.design.trims) {
                    //if (this.design.trims[trimType] == null)
                    this.design.trims[trimType] = ColorHelper.getColorByColorId(cId);
                  }
              }
              else
                  this.design.trimColorMode = CORE.modes.trimColor.single;
            }),

            OptionHelper.color("trim", "Trim", impactTypes.colorTrim, undefined,
            this.design.trimColorMode === CORE.modes.trimColor.single, // enabled
            ()=>{//fnIn
                return Util.copy(this.design.trim); //make a copy so that changes can be detected later
            },
            undefined,
            (v)=>{ // fnChanged
                let hex = ColorHelper.bangToHex(v.bang); // because the bang is what's bound in vue, while nothing else in this complex object is bound
                this.design.trim= ColorHelper.getColorByHex(hex);                
            },
            (vOld,vNew)=>{
                return vOld.bang !== vNew.bang
            })

        ];
        
        if(this.design.trimColorMode === CORE.modes.trimColor.separate){
            opts.push(
                OptionHelper.header("Trim Colors"),
            );

            let optTrimColorEaveAndRake = OptionHelper.color("trims.eaveAndRake", "Eave and Rake", impactTypes.colorTrimEaveAndRake, undefined,
                true, // enabled
                ()=>{//fnIn
                    return Util.copy(this.design.trims.eaveAndRake); //make a copy so that changes can be detected later
                },
                undefined,
                (v)=>{ // fnChanged
                    let hex = ColorHelper.bangToHex(v.bang); // because the bang is what's bound in vue, while nothing else in this complex object is bound
                    this.design.trims.eaveAndRake= ColorHelper.getColorByHex(hex);                
                },
                (vOld,vNew)=>{
                    return vOld.bang !== vNew.bang
                })

            let optTrimColorDownspout = OptionHelper.color("trims.downspout", "Downspouts", impactTypes.colorTrimDownspout, undefined,
                true, // enabled
                ()=>{//fnIn
                    return Util.copy(this.design.trims.downspout); //make a copy so that changes can be detected later
                },
                undefined,
                (v)=>{ // fnChanged
                    let hex = ColorHelper.bangToHex(v.bang); // because the bang is what's bound in vue, while nothing else in this complex object is bound
                    this.design.trims.downspout= ColorHelper.getColorByHex(hex);                
                },
                (vOld,vNew)=>{
                    return vOld.bang !== vNew.bang
                });            
                
            let optTrimColorCorner = OptionHelper.color("trims.corner", "Corners and Base Trim", impactTypes.colorTrimCornerAndBase, undefined,
                true, // enabled
                ()=>{//fnIn
                    return Util.copy(this.design.trims.corner); //make a copy so that changes can be detected later
                },
                undefined,
                (v)=>{ // fnChanged
                    let hex = ColorHelper.bangToHex(v.bang); // because the bang is what's bound in vue, while nothing else in this complex object is bound
                    this.design.trims.corner= ColorHelper.getColorByHex(hex);                
                },
                (vOld,vNew)=>{
                    return vOld.bang !== vNew.bang
                });

                
            let optTrimColorDoor = OptionHelper.color("trims.door", "doorRollup Doors", impactTypes.colorTrimDoor, undefined,
                true, // enabled
                ()=>{//fnIn
                    return Util.copy(this.design.trims.door); //make a copy so that changes can be detected later
                },
                undefined,
                (v)=>{ // fnChanged
                    let hex = ColorHelper.bangToHex(v.bang); // because the bang is what's bound in vue, while nothing else in this complex object is bound
                    this.design.trims.door= ColorHelper.getColorByHex(hex);                
                },
                (vOld,vNew)=>{
                    return vOld.bang !== vNew.bang
                });

                
            let optTrimColorWalksAndWindows = OptionHelper.color("trims.walksAndWindows", "Walks/Windows", impactTypes.colorTrimWalksAndWindows, undefined,
                true, // enabled
                ()=>{//fnIn
                    return Util.copy(this.design.trims.walksAndWindows); //make a copy so that changes can be detected later
                },
                undefined,
                (v)=>{ // fnChanged
                    let hex = ColorHelper.bangToHex(v.bang); // because the bang is what's bound in vue, while nothing else in this complex object is bound
                    this.design.trims.walksAndWindows= ColorHelper.getColorByHex(hex);                
                },
                (vOld,vNew)=>{
                    return vOld.bang !== vNew.bang
                });
                
            opts.push(...[
                optTrimColorEaveAndRake,
                optTrimColorDownspout,
                optTrimColorCorner,
                optTrimColorDoor,
                optTrimColorWalksAndWindows
            ]);
        }

        return opts;
    }

    build(){        
    }
    
    remove(){
    }
    
    getCollisionClass(){
        return [CORE.collisions.classes.none];
    }

    getTypeDisplayName(){
        return "Colors"
    }
}
