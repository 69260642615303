import * as THREE from 'three';
import { Vector3 } from 'three';
import _3dDistHori from '../3d/DistHori.js';
import _3dTrimOpeningFace from '../3d/TrimOpeningFace.js';
import { CORE, impactTypes, rebuildTypes } from '../_spec.js';
import CollisionHelper from '../helpers/CollisionHelper.js';
import TreeNode from '../helpers/TreeNode.js';
import UpdateHelper from '../helpers/UpdateHelper.js';
import layerHelper from '../helpers/layerHelper.js';
import MemHelper from '../helpers/memHelper.js';
import OptionHelper from '../helpers/optionHelper.js';
import Util from '../utility.js';
import cOpening from './Opening.js';
import Grabject from './grabject.js';

export default class EmptyFramedOpening extends cOpening{
    constructor(design) {        
        // EFO = empty framed opening
        super(design);

        if(this.design.dir) 
            delete this.design.dir;
        
        this.migrateGirtHeight(this.design);
        
        if(!this.design.fixedHeader)
            this.design.fixedHeader={
                height: 7.3333,
                enabled:true
            }
    }

    migrateGirtHeight(design) {
        // girt location changed from using 7.34 to 7.3333
        if (design.fixedHeader && design.fixedHeader == 7.34) {
            design.fixedHeader == 7.3333;
        }
    }

    initRebuildHierarchy(){        
        this.rebuildTypeRoot = new TreeNode(null, rebuildTypes.full); // size is lumped in here
        this.rebuildTypeRoot.addChildNode(rebuildTypes.move);
    }

    processImpactFromSelf(impact){

        impact.handled.push(this.design.id);
        switch(impact.change.name)
        {
            case impactTypes.openingResize:
            // case impactTypes.windowDetail:
                this.addRebuildNeeded(rebuildTypes.full);
                break;            
            case impactTypes.openingPosition:
                this.addRebuildNeeded(rebuildTypes.move);
                break;
        }
    }
    
    detectImpact(impact){
        
        if(this.shouldProcessOwnImpact(impact, this.design.id))
            this.processImpactFromSelf(impact);
    }

    
    updateSize(input){

        const {role, relPosition} = input;

        let halfHeight = this.design.dim.height/2;
        if(Util.isUndefined(relPosition)){
            throw 'new empty framed opening size grabject position is undefined'
        }
        let newCoordX = relPosition.x; 
        let newCoordY = relPosition.y;
        let oldCoordY = this.design.pos.y;

        let affected = false;
        switch(role){
            case CORE.grabjects.topLeft:
                affected = this.grabjectChangeLeft(newCoordX) || affected;
                affected = this.grabjectChangeTop(halfHeight, newCoordY, oldCoordY) || affected;
                break;
            case CORE.grabjects.top:
                affected = this.grabjectChangeTop(halfHeight, newCoordY, oldCoordY) || affected;
                break;
            case CORE.grabjects.topRight:
                affected = this.grabjectChangeRight( newCoordX) || affected;
                affected = this.grabjectChangeTop(halfHeight, newCoordY, oldCoordY) || affected;
                break;
            case CORE.grabjects.right:
                affected = this.grabjectChangeRight(newCoordX) || affected
                break;
            case CORE.grabjects.bottomRight:
                affected = this.grabjectChangeRight( newCoordX) || affected;
                affected = this.grabjectChangeBottom(halfHeight, newCoordY, oldCoordY) || affected;
                break;
            case CORE.grabjects.bottom:
                affected = this.grabjectChangeBottom(halfHeight, newCoordY, oldCoordY) || affected;
                break;
            case CORE.grabjects.bottomLeft:
                affected = this.grabjectChangeLeft( newCoordX) || affected;
                affected = this.grabjectChangeBottom(halfHeight, newCoordY, oldCoordY) || affected;
                break;
            case CORE.grabjects.left:
                affected = this.grabjectChangeLeft( newCoordX) || affected;
                break;
        }
        // return details regarding the impact this input change had
        if(affected){
            return UpdateHelper.createImpact(this.design, impactTypes.openingResize);
        }
    }
    
    processRebuild(type){
        // rebuild the minimal amount of 3d objects for this component
        switch(type){
            case rebuildTypes.full:
                this.rebuildFull();
                break;
            case rebuildTypes.move:
                this.rebuildMove();
                break;
        }
    }
    
    rebuildFull(){
        this.remove();
        this.build();
    }

    rebuildMove(){
        //console.log(`Empty Framed Opening group: ${this.group.uuid} rebuildMove ${this.design.pos.x} ${this.design.pos.y} ${this.design.pos.z}`)
        this.group.position.copy(this.design.pos);        
    }
    
    initParent(parent){
        this.parent = parent; // EFO needs to know the parent because a parent wall may be built normal or inverted (two modes for the sake of debugging math more easily)
        this.rot = parent.openingRot(); // the inverted walls are not rotated into place, but built in place, therefore, openings on the walls must be rotated
        
    }

    migrate(design){
        if(typeof design.girtToGirt === 'undefined'){
            design.girtToGirt = true;
        }

        // if(typeof design.style === 'undefined'){
        //     design.style=CORE.window.styles.doubleHung;
        // }
    }

    getOptionsSpec(){

        let options= [
            OptionHelper.numericUpDown("dim.width","Width", impactTypes.openingResize, "in.", 12, 300, 1, true),
            OptionHelper.numericUpDown("dim.height","Height", impactTypes.openingResize, "in.", 12, 120, 1, 
            true,
            undefined, //fnIn
            undefined, //fnOut
            (v)=>{//fnChange
                // keep the top of the EFO where it is
                let oldHeight= this.design.dim.height;  
                this.design.dim.height = v;
                if(this.design.fixedHeader.enabled)
                    this.design.pos.y = (this.design.fixedHeader.height*12)-(this.design.dim.height/2);
                else
                    this.design.pos.y = (this.design.pos.y+oldHeight/2)-(this.design.dim.height/2);
            }),
            OptionHelper.checkbox("fixedHeader.enabled", "Fix Header", impactTypes.openingPosition, 
            true,
            undefined,
            undefined,
            (v)=>{
                this.design.fixedHeader.enabled=v;
                if(this.design.fixedHeader.enabled)
                    this.design.pos.y = (this.design.fixedHeader.height*12)-(this.design.dim.height/2);
            }),
            OptionHelper.numericUpDown("fixedHeader.height","Fixed Header Height", impactTypes.openingPosition, "ft.", 4, 20, .5,
            true,
            undefined,
            undefined,
            (v)=>{ // fnChange
                this.design.fixedHeader.height = v;
                if(this.design.fixedHeader.enabled)
                    this.design.pos.y = (this.design.fixedHeader.height*12)-(this.design.dim.height/2);
            }),

            OptionHelper.checkbox("girtToGirt", "Girt To Girt Connection", impactTypes.openingPosition, true),
            
        //     OptionHelper.selection("style","Style", impactTypes.windowDetail, undefined, [
        //         {
        //             value: CORE.window.styles.doubleHung,
        //             text: `Double Hung`
        //         },
        //         {
        //             value: CORE.window.styles.picture,
        //             text: `Picture`
        //         }
        //     ],
        //     true,
        //     undefined,   
        // undefined,
        // undefined),
        ]
        return options;
    }
    
    getOutlineMeshes(){
        return [this.outlineMesh];
    }

    applyDesign(design){
        this.design.collided= design.collided,
        this.design.pos = new Vector3( design.pos.x, design.pos.y, design.pos.z),
        this.design.type = design.type,
        this.design.parent = {};
        this.design.parent.id = design.parent.id;
        this.design.dim= {
            width: design.dim.width,
            height: design.dim.height
        };
        this.design.fixedHeader={
            enabled: design.fixedHeader.enabled,
            height: design.fixedHeader.height            
        } 
        // this.design.style = design.style;
        this.design.girtToGirt = design.girtToGirt;
        this.design.selected= false;
        this.addRebuildNeeded(rebuildTypes.full);
    }
    
    defaultDesign(dim){
        return {
            collided : false,
            pos : new Vector3(),
            parent: {
                type: 'frontSide'
            },
            type: CORE.components.emptyFramedOpening,
            //style: stylePreference,
            id: -1,
            girtToGirt: true,
            dim: dim || {
                width: 36,
                height: 60
            },
            fixedHeader:{
                height: 7.3333,
                enabled: true
            },
            selected:false
        }
    }
    
    static canEdit(){
        return true;
    }
    static canDelete(){
        return true;
    }
    static canCopy(){
        return true;
    }
    
    
    getInitialGrabjectUserData(){
        let grabData = super.getInitialGrabjectUserData();
        grabData.allowedParentComponentTypes = [
            CORE.components.bayEndLeft,
            CORE.components.bayEndRight,
            CORE.components.baySideBack,
            CORE.components.baySideFront
            //CORE.components.wallSide
        ]
        return grabData;
    }    

    static parentComponentTypes(){
        // used when moving a grabject to get a list of component types this feature can land on
        return [
            CORE.components.bayEndLeft,
            CORE.components.bayEndRight,
            CORE.components.baySideBack,
            CORE.components.baySideFront
        ]
    }
    
    static updatePosX(design, x){
        if(design.pos.x === x)
            return false;
        design.pos.x = x;
        return true;
    }

    static updatePosY(design, y){
        if(design.fixedHeader.enabled)
            y = (design.fixedHeader.height*12)-(design.dim.height/2);

        if(design.pos.y === y)
            return false;
        design.pos.y = y;
        return true;
    }

    updatePosition(center)
    {
        if(!EmptyFramedOpening.updatePosition(center, this.design))
            return; 
        
        // move requires no rebuild (except on re-parentage)
        return UpdateHelper.createImpact(this.design,impactTypes.openingPosition);
    }

    static updatePosition(center, design){
        let affected = false;
        affected = EmptyFramedOpening.updatePosX(design, center.x) || affected;
        affected = EmptyFramedOpening.updatePosY(design, center.y) || affected; 

        if(!affected)
        {
            //console.log('no effect')
        }
        return affected;
    }

    updateWidth(w){
        if(w<12) // about as small as a EFO can get before grabjects run together
            return false; // signal that we can't go any smaller
        if(w>300)
            return false;
        this.design.dim.width=w;
        
        return true;
    }

    left(){
        let left=-this.design.dim.width/2;
        if(this.parent.isInverted())
            left*=-1;
        return this.design.pos.x + left;
    }

    right(){
        let right=this.design.dim.width/2;
        if(this.parent.isInverted())
            right*=-1;
        return this.design.pos.x + right;
    }

    grabjectChangeLeft(newVal){
        // math is written based on positive-Z facing EFO, where:
        // left grabject moved to be left implies change < 0)
        // left grabject moved to the right implies change > 0
        // 
        // when flipped
        // left grabject moved to the left implies change > 0
        // left grabject moved to the right implies change < 0
        let change = newVal - this.left(); // change may be positive or negative because left does not necessarily mean a lower X value, since BSW and REW windows are flipped

        if(change===0)
            return;
        let widthChange = change;
        if(this.parent.isInverted())
            widthChange*=-1;
        // apply the full, positive-only change to the width
        if(!this.updateWidth(this.design.dim.width-widthChange))
            return;
        // apply half of the change (+ or -) to the position
        EmptyFramedOpening.updatePosX(this.design, this.design.pos.x + change/2);
        return true;
    }

    grabjectChangeRight(newVal){
        let change = newVal - this.right(); // change may be positive or negative because left does not necessarily mean a lower X value, since BSW and REW windows are flipped
        if(change===0)
            return;
        let widthChange = change;
        if(this.parent.isInverted())
            widthChange*=-1;
        // apply the full, positive-only change to the width
        if(!this.updateWidth(this.design.dim.width+widthChange))
            return;        
        // apply half of the change (+ or -) to the position
        EmptyFramedOpening.updatePosX(this.design, this.design.pos.x + change/2);
        return true;
    }

    grabjectChangeBottom(halfHeight, newBottom, posY){
        let oldTop = posY+halfHeight;
        let newHeight = oldTop - newBottom;
        if(!this.updateHeight(newHeight))
            return; 
        this.design.pos.y = oldTop - this.design.dim.height/2;                
        return true;
    }

    updateHeight(h){
        if(h<12) // about as small as an it can get before grabjects run together
            return false; // signal that we can't go any smaller
        if(h>120)
            return false;
        this.design.dim.height=h;
        return true;
    }

    grabjectChangeTop(halfHeight, newTop, posY){
        if(this.design.fixedHeader.enabled)
            return;
        let oldBottom = posY-halfHeight;
        let newHeight = newTop - oldBottom;
        if(!this.updateHeight(newHeight))
            return; 
        this.design.pos.y = newTop - this.design.dim.height/2;                
        return true;
    }

    
    show(v){
        this.visible=v;
        this.group.visible = this.visible;
    }

    // required by object interface
    getContextMenuPosition(){        
        // this returns the position of the contextMenuObject (created in build function) 
        // in terms of world position (considering all object groups, and transforms)
        return this.contextMenuObject.getWorldPosition(new THREE.Vector3());
    }

    getTopRightCorner(){
         let offset = new THREE.Vector3(1.3*this.design.dim.width/2,
                                        .8*this.design.dim.height/2,
                                        0);
         return offset;
    }
    // required by object interface
    getDescription(){
        return `Empty Framed Opening ${this.design.dim.width.toFixed()}" x ${this.design.dim.height.toFixed()}"`;
    }
   
    // required by object interface
    getCollisionClass(){
        return CORE.collisions.classes.emptyFramedOpening;
    }
    // required by object interface
    getCollisionZones() {
        return [this.zone];
    }

    setGrabjectTransparency(transparent){


        if(this.grabjectGroup){
            // if(transparent)
            //     console.log('made transparent')
            // else
            //     console.log('made opaque')
            this.grabjectGroup.children.forEach((g)=>{
                g.material.transparent = transparent;            
                if(transparent)
                    g.material.opacity= .5;
                    else
                    g.material.opacity= 1;

            })
        }
    }

    // buildWindow(dim, trimMaterial){
    //     let window;
    //     if(this.design.style===CORE.window.styles.picture)
    //         window = new _3dWindowPicture(CORE.preferences.des_levelOfDetail.value, this.group, dim, trimMaterial);//this.buildWindowPicture(dim);
    //     else 
    //         window = new _3dWindowDoubleHung(CORE.preferences.des_levelOfDetail.value, this.group, dim, trimMaterial);//buildWindowDoubleHung(dim);
                
    //     return window.group;
    // }

    // required by object interface
    build() {
        
        // dim here is the size of the opening.        
        let dim = new THREE.Vector3(this.design.dim.width, this.design.dim.height, 1);
        
        this.group.position.copy(this.design.pos)

        // trim is a bit wider and taller than the opening
        let dimTrimMaterial = new THREE.Vector3(2,2,1.5);
        let trim = new _3dTrimOpeningFace(CORE.preferences.des_levelOfDetail.value, dim, dimTrimMaterial, this.structureConfig.trimMaterials.walksAndWindows, true);
        layerHelper.enableLayer(trim.group, CORE.layers.quote);
        this.group.add(trim.group);
        this.group.position.z = .25;
        
        this.pickSize = new THREE.Vector3(dim.x, dim.y, 1.5);

        this.contextMenuObject = new THREE.Mesh()
        let trc = this.getTopRightCorner();
        this.contextMenuObject.position.set(trc.x, trc.y, trc.z);
        this.contextMenuObject.visible = false; // this 

        if(typeof this.pickDetectionMesh === 'undefined'){
            //console.log(`Empty Framed Opening group: ${this.group.uuid} instantiate pickmesh`)
            var geoPick = new THREE.BoxGeometry(this.pickSize.x, this.pickSize.y, this.pickSize.z);
            geoPick.name = 'empty framed opening picking'
            //CORE.materials.transparent.depthWrite=false;
            //CORE.materials.transparent.depthTest=false;
            this.pickDetectionMesh = new THREE.Mesh(geoPick, CORE.materials.transparent);
            this.pickDetectionMesh.name=`pickMesh for empty framed opening ${this.design.id}`;
            this.pickDetectionMesh.visible=false;
            this.pickDetectionMesh.castShadow=false;
            this.pickDetectionMesh.receiveShadow=false;
            this.pickDetectionMesh.renderOrder =1;
            this.pickDetectionMesh.position.z +=2;
        }
        this.pickDetectionMesh.userData = {
            type: this.design.type, // not sure this is necessary. copied from wallBase.
            id: this.design.id
        };
        
        this.outlineMesh = this.pickDetectionMesh;
        
        this.group.add(this.pickDetectionMesh)
        this.group.add(this.contextMenuObject);        
        this.group.visible = this.visible;
        
        this.buildDimensions();
        this.buildGrabjects();
        this.showGrabjects(this.design.selected);
        
        
        this.group.rotation.y = this.rot; 
        
        this.setCollisionZone();
        
        this.built=true;
    }

    buildDimensions(){
        let width = new _3dDistHori(new Vector3(-this.design.dim.width/2,this.design.dim.height/4,10),new Vector3(this.design.dim.width/2,this.design.dim.height/4,10), new Vector3(0,0,0), new Vector3(0,0,0), 1, CORE.layers.openingDimensions, new Vector3(20,10,1))
        this.group.add(width.group);
        let height = new _3dDistHori(new Vector3(this.design.dim.width/4,this.design.dim.height/2,10),new Vector3(this.design.dim.width/4,-this.design.dim.height/2,10), new Vector3(0,0,0), new Vector3(0,0,0), 1, CORE.layers.openingDimensions, new Vector3(20,10,1))
        this.group.add(height.group);
    }

    setCollisionZone(){

        this.group.updateMatrix();
        //this.group.updateMatrixWorld();
        //console.log(`Empty Framed Opening group: ${this.group.uuid} reference pickmesh`)
        this.pickDetectionMesh.updateMatrix();
        //this.pickDetectionMesh.renderOrder=0;
        //this.pickDetectionMesh.depthTest=false;
        this.pickDetectionMesh.updateWorldMatrix(true);
        //let zone = this.getCollisionZoneTemplate(meshPickDetection);
        let margin = CollisionHelper.getMargin(
            CORE.preferences.des_marginWindowEdge.value,
            CORE.preferences.des_marginWindowEdge.value,
            CORE.preferences.des_marginWindowEdge.value,
            CORE.preferences.des_marginWindowEdge.value,0,0);
        this.zone = CollisionHelper.getZone2(
            this.group,
            undefined,
            true,
            [
                CORE.collisions.classes.window,
                CORE.collisions.classes.emptyFramedOpening,
                CORE.collisions.classes.louverVent,
                CORE.collisions.classes.doorWalkin3o7o,
                CORE.collisions.classes.doorWalkin4o7o,
                CORE.collisions.classes.doorWalkin6o7o,
                CORE.collisions.classes.doorRollup,
                CORE.collisions.classes.doorSliding,
                CORE.collisions.classes.doorCommGlass,
                CORE.collisions.classes.doorSectional,
                CORE.collisions.classes.doorHangar,
                CORE.collisions.classes.wideOpening,
            ],
            this.pickSize,
            margin, 
            this.getDescription()
        )
        
        this.zone.data = {
            id:this.design.id,
            type:this.design.type
        };

        this.pickDetectionMesh.userData.collisionZones= [this.zone];
    }
    


    buildGrabjects(){   
        // TODO: this can be applied to other components using grabjects.     
        let grabjectSize = 8;
        let grabjectSizeVector = new THREE.Vector3(grabjectSize, grabjectSize, 5);
        
        this.grabjectGroup = new THREE.Group();
        this.grabjectGroup.name= 'grabjects'
        let parentComponentTypes = [
            CORE.components.bayEndLeft,
            CORE.components.bayEndRight,
            CORE.components.baySideBack,
            CORE.components.baySideFront
        ];
        let dim = new THREE.Vector3(this.design.dim.width, this.design.dim.height, 1);
        let halfDim = dim.clone().multiplyScalar(.5);
        
         let positionIndicators;
        if(this.design.fixedHeader.enabled){
            positionIndicators = [CORE.indicators.leftRight];   
        }
        else
        {
            positionIndicators = [CORE.indicators.upDownLeftRight];

            this.gtl = new Grabject(
                this.grabjectGroup, 
                this.design.id, 
                new THREE.Vector3(-halfDim.x,halfDim.y,0), 
                //[CORE.indicators.bottomRight,CORE.indicators.topLeft],
                [CORE.indicators.sphere],
                grabjectSizeVector,                 
                CORE.grabjects.topLeft,parentComponentTypes);
            this.gt = new Grabject(
                this.grabjectGroup, 
                this.design.id, 
                new THREE.Vector3(0,halfDim.y,0), 
                //[CORE.indicators.upDown],
                [CORE.indicators.sphere],
                grabjectSizeVector, 
                CORE.grabjects.top,parentComponentTypes);
            this.gtr = new Grabject(
                this.grabjectGroup, 
                this.design.id, 
                new THREE.Vector3(halfDim.x,halfDim.y,0), 
                //[CORE.indicators.bottomLeft,CORE.indicators.topRight],
                [CORE.indicators.sphere],
                grabjectSizeVector, 

                CORE.grabjects.topRight,parentComponentTypes);
        }
        this.positionGrabject = new Grabject(
            this.grabjectGroup, 
            this.design.id, 
            new THREE.Vector3(0,0,0), 
            positionIndicators, 
            new THREE.Vector3(10, 10, 5), 
            
            CORE.grabjects.position,parentComponentTypes);
        this.gr = new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(halfDim.x,0,0), 
        //[CORE.indicators.leftRight],
        [CORE.indicators.sphere], grabjectSizeVector, CORE.grabjects.right,parentComponentTypes);
        this.gbr = new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(halfDim.x,-halfDim.y,0), 
        //[CORE.indicators.bottomRight,CORE.indicators.topLeft], 
        [CORE.indicators.sphere],grabjectSizeVector, CORE.grabjects.bottomRight,parentComponentTypes);
        this.gb = new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(0,-halfDim.y,0), 
        //[CORE.indicators.upDown],
        [CORE.indicators.sphere], grabjectSizeVector, CORE.grabjects.bottom,parentComponentTypes);
        this.gbl = new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(-halfDim.x,-halfDim.y,0), 
        //[CORE.indicators.bottomLeft,CORE.indicators.topRight], 
        [CORE.indicators.sphere],grabjectSizeVector, CORE.grabjects.bottomLeft,parentComponentTypes);
        this.gl = new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(-halfDim.x,0,0),
         //[CORE.indicators.leftRight],
         [CORE.indicators.sphere],          grabjectSizeVector, CORE.grabjects.left,parentComponentTypes);
        this.grabjectGroup.position.z+=1;
        this.group.add(this.grabjectGroup);
    }


    removeGrabjects(){
        this.group.remove(this.grabjectGroup);
        MemHelper.dispose(this.grabjectGroup);
        delete this.grabjectGroup;
    }

    removePickingObject(){
        if(this.pickDetectionMesh)
            delete this.pickDetectionMesh
    }
    
    remove() {
        this.group.remove(this.pickDetectionMesh);
        this.removeGrabjects(); 
        this.removePickingObject();
        MemHelper.dispose(this.group);
    }
    
    getTypeDisplayName(){
        return "Empty Framed Opening"
    }
}
