
import DoorWalkin from './DoorWalkin.js'
import { Vector2, Vector3 } from 'three';
import { CORE,impactTypes  } from '../_spec.js'

export default class DoorWalkin4o7o extends DoorWalkin {
    constructor(design) {
        super(design);
    }

    migrate(design){
        design.dim.width = 4*12;
        delete design.halfGlass; // this design can come from another door type which supports halfGlass
    }

    init(design) {
        this.migrate(design);
        this.design = design;
        
    }    
    
    defaultDesign(deadboltPreference){
        return {
            collided : false,
            pos : new Vector3(0,(7*12)/2,0),
            parent: {
                type: 'frontSide'
            },
            type: CORE.components.doorWalkin4o7o,
            id: -1,
            dim:{
                width: 4 * 12 ,
                height: 7 * 12 
            },
            selected:false,
            deadbolt: deadboltPreference
        }
    }

    getDescription(){
        
        return `Walk-in 4' x 7'`; 
    }

    getTypeDisplayName(){
        return `4' Walk Door`;
    }
}