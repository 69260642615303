<template>
    <div class="old-blue2 swm-home-box2">
        <div class="swm-box-header2 j-flex-h-center j-flex-v-center">{{title}}</div>

        <div class="swm-box-body2">
            <slot name="content">
            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'swmbox',
        props: ['title']
    }
</script>
