import * as THREE from 'three';
import ColumnBase from './ColumnBase.js'
import {CORE} from '../_spec.js'
import { Vector3 } from 'three';
import CollisionHelper from '../helpers/CollisionHelper.js';
import MemHelper from '../helpers/memHelper.js';
import ColumnC from './ColumnC.js';
import _3dBase from './Base.js';
import materialHelper from '../helpers/materialHelper.js';

export default class Sphere extends _3dBase {
    constructor(){		
		super(undefined,undefined,undefined, 'low');
		this.group.name = 'marker sphere';		

        this.group.add(new THREE.Mesh(new THREE.SphereGeometry(.5,5,5), materialHelper.getPlasticMaterial_HD(0xff0000)));
    }
}