
import * as THREE from 'three';

import {CORE, rakeTrimStyles, eaveTrimStyles, rebuildTypes, impactTypes} from '../_spec.js';
import RoofMainSlope from './RoofMainSlope';
import RoofMainGable from './RoofMainGable';
import cStructureBase from './StructureBase'
import Appearance from './appearance'

import Util from '../utility.js'
import FramelineHelper from '../blueprints/FramelineHelper.js'
import {  Vector3 } from 'three';

import _3dSideLabel from '../3d/SideLabel';
import _3dTrimCorner from '../3d/TrimCorner.js'
import _3dTrimGutterDownspout from '../3d/TrimGutterDownspout.js'
import _3dDistHori from '../3d/DistHori.js'
import DesignManager from '../design.js';
import MemHelper from '../helpers/memHelper.js';
import { faUfo } from '@fortawesome/pro-duotone-svg-icons';
import ConstraintHelper from '../helpers/ConstraintHelper.js';
import { issueFlagNotices } from 'tailwindcss/lib/featureFlags.js';
import BlueprintHelper from '../helpers/blueprintHelper.js';
import Grabject from './grabject.js';
import OptionHelper from '../helpers/optionHelper.js';
import SpriteHelper from '../helpers/SpriteHelper.js';
import layerHelper from '../helpers/layerHelper.js';
import debugHelper from '../helpers/debugHelper.js';
import DesignHelper from '../helpers/DesignHelper.js';
import ComponentHelper from '../helpers/featureHelper.js';

export default class StructureMain extends cStructureBase{
    /*
    this class represents a standard rectangular structure 
    The Ameristall raised center aisle would require another structure class
    */
    constructor(design, side, mDesign, model){
        super(design, side, mDesign, model);
        
    }

    specificInit(){
        this.design.type = CORE.components.structure;

        this.calculateFramelines();
        this.frameDesign.isPorch = false
        
        this.appearanceDesign = this.design.getAppearance();
        Appearance.migrate(this.appearanceDesign);
        // get all the wall designs
        this.applyColors(this.appearanceDesign);
        let lesDesign = this.design.getLeftEndSkirt();
        if(lesDesign)
            lesDesign.wallColor = this.appearanceDesign.wall.tjs;                
        let resDesign = this.design.getRightEndSkirt();
        if(resDesign)
            resDesign.wallColor = this.appearanceDesign.wall.tjs;
        
        this.optionDesign = this.design.getOptions();

        this.jobSpecsDesign = this.design.getJobSpecs();
        
        this.roofDesign.isPorch = false;
        
        this.updateConstraineeContraints();
    }


    // getOptionsSpec(){
    //     let opts = super.getOptionsSpec();
        
    //     opts.splice(11,0,
            
    //          OptionHelper.numericUpDown("rotRad", "Rotation", impactTypes.structureRotation, "degrees", undefined, undefined, 15, true,
	// 		()=>{ //fnIn
	// 			// load the value as degrees
	// 			return Math.round(this.design.rotRad*180/Math.PI);
	// 		},
	// 		undefined,			
	// 		(v)=>{//fnChange				
	// 			this.applyChangeRotation(v);
	// 		}
	// 		)
    //     )
    //     return opts;
    // }


    // 
	// applyChangeRotation(v){
	// 	if(v<0)
	// 		v+=360; // don't let the rotation be negative
	// 	// normalize the value
	// 	v=v%360;
	// 	// convert the value for storage in radians
	// 	this.design.rotRad = v*Math.PI/180;
	// }
    initBlueprints(){
        
        super.initBlueprints();
    }

    disableRotationInput(){
        super.disableRotationInput();

        if(this.foundation)
            this.foundation.disableRotationInput();
    }




    enableRotationInput(){
        super.enableRotationInput();

        // if(this.foundation)
        //     this.foundation.enableRotationInput();
    }

    showGrabjects(visible){
        if(this.getRotationInputEnabled())
            super.showGrabjects(visible);

    }

    buildGrabjects(){
        
        this.grabjectGroup = new THREE.Group();        
        this.grabjectGroup.visible=this.getRotationInputEnabled();
		this.group.add(this.grabjectGroup);
	
		this.grabPosition = new Grabject(
			this.grabjectGroup, 
			this.design.id, 
			new Vector3(0,500,0), 		
			[CORE.indicators.upDownLeftRight],
			new Vector3(80,80,80), CORE.grabjects.position, CORE.components.ground, undefined, new Vector3(0,.5,0) )
		this.grabPosition.group.rotation.x = -Math.PI/2;

		layerHelper.enableLayer(this.grabjectGroup, CORE.layers.constraint, true);
		layerHelper.setGroup(this.grabjectGroup, CORE.layers.constraint, true);
	
    }
    
    defaultDesign(){
        return DesignHelper.GetBuiltOutStructureDesign();
    }

    getInitialGrabjectUserData(){
        let grabData = super.getInitialGrabjectUserData();
        grabData.allowedParentComponentTypes = [
            CORE.components.ground,
        ]
        return grabData;
    }

    getDescription(){        
        return this.design.name || "Main Building"
    }

    processImpactFromSelf(impact){
        super.processImpactFromSelf(impact);
        impact.handled.push(this.design.id);
        switch(impact.change.name){
            case impactTypes.structureWainscot:
                this.addRebuildNeeded(rebuildTypes.wall);
                break;
        }
    }




    shouldProcessImpact(impact){
        // see if this component is connected to this one
        // or a child of a component connected to this one

        // get id of this structure component
        let thisStructureId = this.design.id;
        
        // get ids of ancestors of impacted component
        let impactedComponentAncestorIds = this.masterDesign.getComponentAncestorIds(impact.id);
                
        if(impactedComponentAncestorIds.includes(thisStructureId))
            return true;

        // find any constraint connecting those two components
        let constraint = ConstraintHelper.areConstrainedTogether(thisStructureId, impact.id, this.masterDesign.constraintRootNode);
        if(constraint == null)
            return false;
        return true;
    }


    remove(){
        super.remove();

        MemHelper.removeAllChildren(this.group);
    }

    build(){
        super.build();
        this.setPosition(this.design);     
        this.buildGrabjects();
        this.buildNameLabel();
        this.buildSideLabels();
        //this.group.add(debugHelper.getCoordPlanes());
    }

    getBackSideLabelText(){
        if(this.getRoofType() == CORE.roof.types.slope)
            return "High Side"
        else
            return "BSW"
    }

    getFrontSideLabelText(){
        if(this.getRoofType() == CORE.roof.types.slope)
            return "Low Side"
        else
            return "FSW"
    }


    buildNameLabel(){
        
        // // perspective label
        // this.nameLabel = SpriteHelper.makeTextSprite(this.design.name, null, { fontsize: 15} );   
        // this.nameLabel.material.sizeAttenuation = false;
        // this.nameLabel.scale.multiplyScalar(.1/this.nameLabel.scale.x);
        // this.nameLabel.position.set( 0,this.getRoofRidgeHeight()+3,0);
        // this.nameLabel.center.x = .40;
        // this.nameLabel.center.y = 0;
        // //this.groupMetal.add(this.nameLabel);

        this.groupName = new THREE.Group(); // make a new group so we can destory this sub-group only during rebuilds
        this.groupMetal.add(this.groupName); // add the name group to the "aboveFoundation" group

        let labelBuilding = this.create2dLabelAtPosition(this.design.name, new Vector3( 0,this.getRoofRidgeHeight()+20,0), this.design.id+'name');
        labelBuilding.layers.set(CORE.layers.buildingLabels);        
        this.groupName.add(labelBuilding) // add the label to the name group





        // // othrographic label
        // this.nameLabelOrtho = SpriteHelper.makeTextSprite(this.design.name, null, { fontsize: 35} );   
        // this.nameLabelOrtho.material.sizeAttenuation = true;
        // //this.nameLabelOrtho.scale.multiplyScalar(this.nameLabelOrtho.scale.x/10);
        // this.nameLabelOrtho.position.set( 0,this.getRoofRidgeHeight()+3,0);
        // this.nameLabelOrtho.center.x = .50;
        // this.nameLabelOrtho.center.y = 0;
        // this.nameLabelOrtho.layers.set(CORE.layers.constraint);
        // this.groupMetal.add(this.nameLabelOrtho);
    }

    detectImpact(impact){
        // impact is an impact from any component (including itself)
        // return 0+ impacts for itself 

        // if a component changed and that component is this component, and it's not already been processed, process change
        // if a component changed and that component is not this component, process that change 


        // if this impact affects my design, 
        //  update my design 
        //  generate an impact.
        //  set rebuildType
        //console.log(`this is ${this.design.id}`);

        // if this component has changed and not yet been processed
        if(this.shouldProcessOwnImpact(impact, this.design.id))
            // process impact from self
            this.processImpactFromSelf(impact);
        else{

            // impact is from a descendent
            // impact is from a contrained descendent            
            
            if(!this.shouldProcessImpact(impact, this.design.id)){
                return;
            }
        }
        
        super.detectImpact(impact);
        
        
        

        let comp2roof = this.components[2];

        

        
        let wallIndices = [];
        switch(impact.change.name){
            
            case impactTypes.porchWidth:
                this.addRebuildNeeded(rebuildTypes.full);
                break;
            case impactTypes.porchHeight:
            case impactTypes.porchPitch:
            case impactTypes.porchWrap:
            case impactTypes.addPorch:
            case impactTypes.porchLength:
            case impactTypes.deletePorch:            
                this.addRebuildNeeded(rebuildTypes.roof);                
                break;

            case impactTypes.secondaryStructureConstraintChange:
            case impactTypes.structureMainFrame:
            case impactTypes.structureOpenBays:
            case impactTypes.wallPosition:
            case impactTypes.structureFrameType:  
            case impactTypes.structureRoofType:
            case impactTypes.structureHeight:
            case impactTypes.structureWidth:
            case impactTypes.structureLength:
            case impactTypes.structureRoofPitch:
            case impactTypes.structureGirting:
            case impactTypes.structureBaySpacing:
            case impactTypes.structureEndSkirt:            
                this.addRebuildNeeded(rebuildTypes.full);
                break;
            
            case impactTypes.structureRotation:
            case impactTypes.structurePosition:
            case impactTypes.foundationHeight:
                this.addRebuildNeeded(rebuildTypes.move);
                break;
            
            case impactTypes.structureWainscot:
            case impactTypes.wallHeight:
                    this.addRebuildNeeded(rebuildTypes.wall);
                    break;

            case impactTypes.colorRoof:                
                this.addRebuildNeeded(rebuildTypes.roof);
                
                comp2roof.design.color = impact.design.roof.tjs;
                
                break;
            case impactTypes.colorWall:
                this.addRebuildNeeded(rebuildTypes.wall);

                this.components.forEach((c,i)=>{
                    if(c.design.type == (CORE.components.lew || CORE.components.rew || CORE.components.fsw || CORE.components.bsw))
                        wallIndices.push(i);
                })
                wallIndices.forEach((i)=>{
                    this.components[i].design.color = impact.design.wall.tjs;    
                })

                //this.components[10].design.color = impact.design.wall.tjs;
                //this.components[11].design.color = impact.design.wall.tjs;
                //this.components[12].design.color = impact.design.wall.tjs;
                //this.components[13].design.color = impact.design.wall.tjs;
                break;
            case impactTypes.colorWainscot:
                this.addRebuildNeeded(rebuildTypes.wall);
                
                this.components.forEach((c,i)=>{
                    if(ComponentHelper.objectTypeIsWall(c.design.type))
                        wallIndices.push(i);
                })
                wallIndices.forEach((i)=>{
                    this.components[i].design.wainscot.color = impact.design.wainscoting.tjs;
                })
                break;
            case impactTypes.colorTrim:
                this.addRebuildNeeded(rebuildTypes.trim);                
                this.applyColors(impact.design);
                let appearance = this.design.getAppearance();
                appearance = impact.design;
                //this.recalculateTrimColors();
                break;
            

            case impactTypes.colorModeTrim:
                //this.recalculateTrimColors();
                this.addRebuildNeeded(rebuildTypes.full);
                break;
            case impactTypes.colorTrimEaveAndRake:
                this.appearanceDesign.trims.eaveAndRake = impact.design.trims.eaveAndRake; 
                //this.recalculateTrimColors();
                this.addRebuildNeeded(rebuildTypes.trim);
                break;
            case impactTypes.colorTrimDownspout:
                this.appearanceDesign.trims.downspout = impact.design.trims.downspout; 
                //this.recalculateTrimColors();
                this.addRebuildNeeded(rebuildTypes.trim);
                break;
            case impactTypes.colorTrimCornerAndBase:
                this.appearanceDesign.trims.corner = impact.design.trims.corner; 
                //this.recalculateTrimColors();
                this.addRebuildNeeded(rebuildTypes.trim);
                break;
            case impactTypes.colorTrimDoor:
                this.appearanceDesign.trims.door = impact.design.trims.door; 
                //this.recalculateTrimColors();
                this.addRebuildNeeded(rebuildTypes.full);
                break;
            case impactTypes.colorTrimWalksAndWindows:
                this.appearanceDesign.trims.walksAndWindows = impact.design.trims.walksAndWindows; 
                //this.recalculateTrimColors();
                this.addRebuildNeeded(rebuildTypes.full);
                break;                                
                // if other porch got shorter, update wrapValidity, issue change if appropriate
                // if other porch got longer, update wrapValidity, issue change if appropriate
            case impactTypes.optionsGalvanizedBeams:
                //this.frameDesign.beamColor = impact.design.galvBeams;
                this.addRebuildNeeded(rebuildTypes.full);
                break;
            case impactTypes.optionsGalvanizedPurlins:
                //this.roofDesign.purlinColor = impact.design.galvPurlins ? CORE.frame.galvanizedColor : CORE.frame.color;
                this.addRebuildNeeded(rebuildTypes.full);
                break;
        }
      
        this.calculateFramelines();
    }


    buildDistanceMarkers(){
        // removeDistanceMarks undoes what's build here.
        super.buildDistanceMarkers();
     
        let left = this.getWallFrontLeftBottom()
        let right = this.getWallFrontRightBottom()
        this.frontDistHori = new _3dDistHori(left, right, new THREE.Vector3(0,10,5), new THREE.Vector3(0,0,60), 1/12, CORE.layers.dimensions);
        this.groupMetal.add(this.frontDistHori.group);

        // peak depends on roof type
        if(this.roofDesign.roofType === CORE.roof.types.slope)
        {
            this.peakHeightDistHori = new _3dDistHori(this.getWallBackLeftBottom(), this.getWallBackLeftTop(),new THREE.Vector3(-5,.1,0), new THREE.Vector3(-20,.1,0), 1/12, CORE.layers.dimensions);
            this.groupMetal.add(this.peakHeightDistHori.group);
            this.lowHeightDistHori = new _3dDistHori(this.getWallFrontLeftBottom(), this.getWallFrontLeftTop(),new THREE.Vector3(-5,.1,0), new THREE.Vector3(-20,.1,0), 1/12, CORE.layers.dimensions);
            this.groupMetal.add(this.lowHeightDistHori.group);
        }
        else{
            let roofRidgeLeftAtGround = this.getGableRidgeLeft();
            roofRidgeLeftAtGround.y = 0;
            this.peakHeightDistHori = new _3dDistHori(roofRidgeLeftAtGround, this.getGableRidgeLeft(),new THREE.Vector3(-5,.1,0), new THREE.Vector3(-30,.1,0), 1/12, CORE.layers.dimensions);
            this.groupMetal.add(this.peakHeightDistHori.group);
            this.lowHeightDistHori = new _3dDistHori(this.getWallBackLeftBottom(), this.getWallBackLeftTop(),new THREE.Vector3(-5,.1,0), new THREE.Vector3(-20,.1,-50), 1/12, CORE.layers.dimensions);
            this.groupMetal.add(this.lowHeightDistHori.group);
        }

        //https://trello.com/c/irgWXrL7/183-web-quote-render-bay-spacings
        // draw bay spacings along the back wall

        this.backBayDistHoris = [];
        let columnWidth = 8;
        let structureHalfLengthInches = this.getStructureLengthInches()/2
        for(var fli = 1;fli<this.design.frameLines.length;fli++){
            
            let vLeft = this.getWallBackLeftBottom()
            let vRight = this.getWallBackRightBottom();
            vLeft.x = this.design.frameLines[fli-1] -structureHalfLengthInches;
            vRight.x = this.design.frameLines[fli] -structureHalfLengthInches;

            if(fli ===1) 
                vLeft.x-=columnWidth/2;
            if(fli === this.design.frameLines.length-1)
                vRight.x+=columnWidth/2;
            
                let backBayDistHori = new _3dDistHori(vLeft, vRight, new THREE.Vector3(0,10,-5), new THREE.Vector3(0,0,-60), 1/12, CORE.layers.dimensions)
            this.backBayDistHoris.push(backBayDistHori);
            this.groupMetal.add(backBayDistHori.group);
        }
    }
}
