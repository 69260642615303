import {CORE} from '../_spec.js';
import DesignHelper from './DesignHelper.js';
import StructureHelper from './StructureHelper.js';
import BlueprintHelper from './blueprintHelper.js';

// This method does not detect collissions between decendants 
function isValidBuildingLink(parentStructure, childStructure, constraint, isTest=false)
{   

    if(isTest)
      DesignHelper.initDesignManagerAndLoadedComponents(parentStructure, childStructure)
    // let type = parentStructure.type;
    // let type2 = childStructure.type;
    parentStructure = (parentStructure.type == "building") ? parentStructure.getStructure() : parentStructure;
    childStructure = (childStructure.type == "building") ? childStructure.getStructure(): childStructure;

    let pFrame = parentStructure.getFrame();
    let pRoof = parentStructure.getRoof().roofType;
    let pMatingSide = constraint.parent.matingSide;
    let pEave = BlueprintHelper.getStructureHeightInches(pFrame);
    let pPeak = BlueprintHelper.peakHeight(pFrame.width, parentStructure.getRoof().pitch, pRoof, pEave);
    let pMatingOffsetLength = constraint.parent.length;
    let pMatingSideLength = StructureHelper.getLengthOfSide(parentStructure, pMatingSide);
    
    let cFrame = childStructure.getFrame();
    let cRoof = childStructure.getRoof().roofType;
    let cMatingSide = constraint.child.matingSide;
    let cEave = BlueprintHelper.getStructureHeightInches(cFrame);
    let cPeak = BlueprintHelper.peakHeight(cFrame.width, childStructure.getRoof().pitch, cRoof, cEave);
    let cMatingSideLength = StructureHelper.getLengthOfSide(childStructure, cMatingSide);
    
    /*if(!isValidBuildingMatingAlignment(pMatingOffsetLength, pMatingSideLength, cMatingSideLength))
    {
      return false;
    }*/
  
    //  END TO END MATING
    if(isEndToEndMatingPair(pMatingSide, cMatingSide))
    {
        return true;
    } 
    //  SIDE TO SIDE MATING
    else if(isSideToSideMatingPair(pMatingSide, cMatingSide))
    {
      return isValidSideToSide(pRoof, pMatingSide, pEave, pPeak, cRoof, cMatingSide, cEave, cPeak)
    } 
    // SIDE TO END MATING
    else if(isSideToEndMatingPair(pMatingSide, cMatingSide))
    {
      return isValidSideToEnd(pRoof, pMatingSide, pEave, pPeak, cRoof, cMatingSide, cEave, cPeak)
    }
  }
  
  /* helper method to isValidBuildingLink */
  function isValidBuildingMatingAlignment(pMatingOffsetLength, pMatingSideLength, cMatingSideLength)
  {
    // Determine if parent or child is the larger building.
    // If parent is bigger than child, ensure parent fully encompasses child. 
    // If child is bigger than parent, ensure child fully encompasses parent.
    if(pMatingSideLength >= cMatingSideLength) 
    {
        // positive offset means bigger parent consumes smaller child left corner,
        // need to make sure child right corner is also fully consumed by parent
        if(Math.sign(pMatingOffsetLength) > -1)
            return (pMatingSideLength - pMatingOffsetLength) >= cMatingSideLength;
        else
          return false;
    }
    else
    {
        // negative offset means bigger child left corner is past smaller parent left corner, 
        // need to make sure child right corner is to or past parent right corner
        if(Math.sign(pMatingOffsetLength) <= 0)
            return (pMatingSideLength - pMatingOffsetLength) <= cMatingSideLength;
        else
          return false;
    }

  }
  
  /* helper method to isValidBuildingLink */
  function isEndToEndMatingPair(pMatingSide, cMatingSide)
  {
    // END TO END
    if((pMatingSide == CORE.sides.leftEnd || pMatingSide == CORE.sides.rightEnd) &&
    (cMatingSide == CORE.sides.leftEnd || cMatingSide == CORE.sides.rightEnd))
      return true;
  
    return false;
  }
  
  /* helper method to isValidBuildingLink */
  function isSideToSideMatingPair(pMatingSide, cMatingSide)
  {
    // SIDE TO SIDE
    if((pMatingSide == CORE.sides.frontSide || pMatingSide == CORE.sides.backSide) &&
    (cMatingSide == CORE.sides.frontSide || cMatingSide == CORE.sides.backSide))
      return true;
  
    return false;
  }
  
  /* helper method to isValidBuildingLink */
  function isSideToEndMatingPair(pMatingSide, cMatingSide)
  {
    // SIDE TO END
    if(((pMatingSide == CORE.sides.frontSide || pMatingSide == CORE.sides.backSide) && (cMatingSide == CORE.sides.leftEnd || cMatingSide == CORE.sides.rightEnd)) ||
    ((pMatingSide == CORE.sides.leftEnd || pMatingSide == CORE.sides.rightEnd) && (cMatingSide == CORE.sides.frontSide || cMatingSide == CORE.sides.backSide)))
      return true;
  
    return false;
  }
  
  /* helper method to isValidBuildingLink */
  function isValidSideToSide(pRoof, pMatingSide, pEave, pPeak, cRoof, cMatingSide, cEave, cPeak)
  {
    let coreRoofGable = CORE.roof.types.gable
    let coreRoofSlope = CORE.roof.types.slope
  
    // gable(FB) to slope(B) <--> slope(B) to gable(FB)
    if(((pRoof == coreRoofGable && cRoof == coreRoofSlope) && cMatingSide == CORE.sides.backSide) ||
    ((pRoof == coreRoofSlope && cRoof == coreRoofGable) && pMatingSide == CORE.sides.backSide)) 
    {
      if((pRoof == coreRoofGable && pEave >= cPeak) || (pRoof == coreRoofSlope && pPeak <= cEave))
      {
          return true;
      }
    } 
    // slope(F) to slope(B) <--> slope(B) to slope (F)
    else if(pRoof == coreRoofSlope && cRoof == coreRoofSlope)
    {
      if((pMatingSide == CORE.sides.frontSide && cMatingSide == CORE.sides.backSide) ||
      (pMatingSide == CORE.sides.backSide && cMatingSide == CORE.sides.frontSide))
      {
        if((pMatingSide == CORE.sides.frontSide && pEave >= cPeak) || (pMatingSide == CORE.sides.backSide && pPeak <= cEave))
        {
            return true;
        }
      }
      // slope(B) to slope(B)
      else if(pMatingSide == CORE.sides.backSide && cMatingSide == CORE.sides.backSide)
      {
        return true;
      }
    }
  
    return false;
  }
  
  /* helper method to isValidBuildingLink */
  function isValidSideToEnd(pRoof, pMatingSide, pEave, pPeak, cRoof, cMatingSide, cEave, cPeak)
  {
    let coreRoofGable = CORE.roof.types.gable
    let coreRoofSlope = CORE.roof.types.slope
    // gable(FB) to gable(LR) <--> gable(LR) to gable(FB)
    if(pRoof == coreRoofGable && cRoof == coreRoofGable)
    {
      // short circuit Gable (FB) to Gable (R)
      if((pMatingSide == CORE.sides.rightEnd && pPeak > cEave) || (cMatingSide == CORE.sides.rightEnd && cPeak > pEave))
        return false;

      if(((pMatingSide == CORE.sides.frontSide || pMatingSide == CORE.sides.backSide) && (pEave >= cEave) && (pPeak >= cPeak)) || 
      ((pMatingSide == CORE.sides.leftEnd || pMatingSide == CORE.sides.rightEnd) && (pEave <= cEave) && (pPeak <= cPeak)))
      {
        return true;
      } 
    }
    // slope(FB) to gable(LR) <--> gable(LR) to slope(FB)
    // *** you cannot have gable side to slope end ***
    else if((pRoof == coreRoofSlope && cRoof == coreRoofGable) ||
    (pRoof == coreRoofGable && cRoof == coreRoofSlope))
    {
      // short circuit slope(FB) to Gable(R)
      if((pMatingSide == CORE.sides.rightEnd && pPeak > cEave) || (cMatingSide == CORE.sides.rightEnd && cPeak > pEave))
        return false;

      // slope(F) to gable(LR) <--> gable(LR) to slope(F)
      if((pRoof == coreRoofSlope && pMatingSide == CORE.sides.frontSide && pEave >= cPeak) ||
      (cRoof == coreRoofSlope && cMatingSide == CORE.sides.frontSide && pPeak <= cEave))
      {
        return true;
      }
      // slope(B) to gable(LR) <--> gable(LR) to slope(B)
      else if((pRoof == coreRoofSlope && pMatingSide == CORE.sides.backSide) ||
        (cRoof == coreRoofSlope && cMatingSide == CORE.sides.backSide))
      {
            return true;
      }
      
    } 
    // slope(FB) to slope(LR) <--> slope(LR) to slope(FB)
    else if(pRoof == coreRoofSlope && cRoof == coreRoofSlope)
    {
      // slope(F) to slope(LR) <--> slope(LR) to slope(F)
      if((pMatingSide == CORE.sides.frontSide && pEave >= cPeak) ||
      (cMatingSide == CORE.sides.frontSide && pPeak <= cEave))
      {
           return true;      
      }
      // slope(B) to slope(LR) <--> slope(LR) to slope(B)
      else if(pMatingSide == CORE.sides.backSide || cMatingSide == CORE.sides.backSide)
      {
        return true;
      }
      
    }

    return false;
  
  }
  
  export default {isEndToEndMatingPair, isSideToSideMatingPair, isSideToEndMatingPair, isValidSideToSide, isValidSideToEnd, isValidBuildingMatingAlignment, isValidBuildingLink}