import * as THREE from 'three'
import {CORE} from '../_spec.js'
import MemHelper from '../helpers/memHelper.js';
import _3dBase from './Base.js'
//TODO: rename this class to TrimEdgeHori
export default class TrimWallBottom extends _3dBase{
//reference material:
/*
Opens toward Y+
built along X+/-

|   |
|   |
|   |  Height
|_*_|
Depth

* = origin
*/

    constructor(length, height, depth, material){
        super()
        // length: length in the x dimension (as built)
        // height: length in the y dimension (as built)
        //  depth: length in the z dimension (as built)
        this.group.name = "TrimWallBottom";
        if(typeof height === 'undefined')
            height = 4;
        
        let geoFace = new THREE.PlaneGeometry(length, height, 1,1);
        geoFace.name='trimwallbottom f'
        let geoBottom = new THREE.PlaneGeometry(length, depth, 1,1);
        geoBottom.name='trimwallbottom b'
        let meshFront = new THREE.Mesh(geoFace, material);
        let meshBack =  new THREE.Mesh(geoFace, material);
        let meshBottom = new THREE.Mesh(geoBottom, material);
        
        meshFront.position.set(0,height/2,depth/2);
        meshBack.position.set(0,height/2,-depth/2);
        meshBottom.rotation.x = Math.PI/2;

        meshFront.layers.set(CORE.layers.walls)
        meshBack.layers.set(CORE.layers.walls)
        meshBottom.layers.set(CORE.layers.walls)

        this.group.add(meshFront);
        this.group.add(meshBack);        
        this.group.add(meshBottom);        
    }

}