import _3dBase from './Base.js'
import * as THREE from 'three';
import {Vector2,  Vector3 } from 'three';
import MemHelper from '../helpers/memHelper.js';
import ShapeHelper from '../helpers/shapeHelper.js'
import { CORE } from '../_spec.js'
import _3dGirtHoriC from './GirtHoriC.js'
import _3dTrimOpeningFace from './TrimOpeningFace.js';
import _3dTrimOpeningJamb from './TrimOpeningJamb.js';
import materialHelper from '../helpers/materialHelper.js';
import HangarDoorSection from './HangarDoorSection.js';
import layerHelper from '../helpers/layerHelper.js';

export default class DoorHangar extends _3dBase{

    constructor(lod, width, height, style, color, trimMaterial, girtColor, radians, ohJambLiner){
        super(undefined, undefined, undefined, lod)
        this.width = width;
        this.height = height;
        this.color = color;
        this.build(width, height, style, color, trimMaterial, girtColor, radians, ohJambLiner)
    }

    build(width, height, style, color, trimMaterial, girtColor, radians, ohJambLiner) {
        // use the design to build the component
       
        let jambTrimThickness = .5; 
        let jambTrimWidth = 8;
        //radians=0;

        let dim = new THREE.Vector3(width, height, 1);
        let halfDim = dim.clone().multiplyScalar(.5);
        let doorGroup = new THREE.Group();
        if(style === 'bi-fold'){
            let topHalf = new HangarDoorSection(this.lod, this.width, this.height/2, this.color, trimMaterial);
            let bottomHalf = new HangarDoorSection(this.lod, this.width, this.height/2, this.color, trimMaterial);


            // calculate from the top down
            let centerZOfTopHalf = Math.cos(radians)*this.height/4;
            let rotationHeight = Math.sin(radians)*this.height/4;
            
            let centerYOfTopHalf = (this.height/2-rotationHeight);
            topHalf.group.position.set(0,centerYOfTopHalf - Math.cos(radians)*4.5,centerZOfTopHalf) // this makes the center of the top half, orbit around the top edge of the top half.
            topHalf.group.rotation.x = -Math.PI/2+radians;

            // we need to know where the bottom edge is.
            let bottomZOfTopHalf = Math.cos(radians)*this.height/2;
            let bottomYOfTopHalf = this.height/2 - Math.sin(radians)*this.height/2;

            let centerZOfBottomHalf = Math.cos(-radians)*this.height/2;
            let centerYOfBottomHalf = bottomYOfTopHalf - rotationHeight;
            
            
            bottomHalf.group.position.set(0, centerYOfBottomHalf,centerZOfTopHalf)
            bottomHalf.group.rotation.x = Math.PI/2-radians;

            
            //bottomHalf.group.rotation.x = -radians;
            
            doorGroup.add(topHalf.group)
            doorGroup.add(bottomHalf.group)
        }
        else
        {
            let noFold = new HangarDoorSection(this.lod, this.width, this.height,this.color, trimMaterial);            

            // calculate from the top down
            let centerZOfTopHalf = 4+Math.cos(radians)*this.height/2;
            let rotationHeight = Math.sin(radians)*this.height/2;
            let centerYOfTopHalf = this.height/2-rotationHeight;
            noFold.group.position.set(0,centerYOfTopHalf,centerZOfTopHalf) // this makes the center of the top half, orbit around the top edge of the top half.
            noFold.group.rotation.x = -Math.PI/2+radians;

            doorGroup.add(noFold.group);            
        }
        this.group.add(doorGroup);
        doorGroup.position.z -=8;

        // trim        
        let dimTrimMaterial = new THREE.Vector3(2,2,1.5);
        let trim = new _3dTrimOpeningFace(this.lod, dim, dimTrimMaterial, trimMaterial, false);
        layerHelper.enableLayer(trim.group, CORE.layers.quote);
        this.group.add(trim.group);

        if (ohJambLiner)
        {
            let jamb = new _3dTrimOpeningJamb(this.lod, dim, jambTrimThickness, jambTrimWidth, trimMaterial);
            this.group.add(jamb.group);        
        }

        /// FRAMING 
        let gFrame = new THREE.Group();
        gFrame.name = 'hangar door header'
        this.group.add(gFrame)
        let frameInset = 1;
      let header = new _3dGirtHoriC(this.lod, new THREE.Vector3(0, 0, 0), new THREE.Vector3(width, 0, 0), CORE.dir.rel.up, false, girtColor);
        header.build();
        gFrame.add(header.group);
        header.group.position.set(0,height/2,-header.dim.web/2-frameInset);

    }


}
