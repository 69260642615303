import * as THREE from 'three';
import { CORE, rebuildTypes,impactTypes } from '../_spec.js'
import { Vector2, Vector3 } from 'three';
import { OBB } from 'three/examples/jsm/math/OBB.js'; // https://threejs.org/examples/?q=bounding#webgl_math_obb
import CollisionHelper from '../helpers/CollisionHelper';
import Grabject from './grabject.js'
import Door from './Door.js'
import OptionHelper from '../helpers/optionHelper.js'
import vHelper from '../helpers/vHelper.js'
import MemHelper from '../helpers/memHelper.js';
import Util from '../utility.js';
import TreeNode from '../helpers/TreeNode.js'
import UpdateHelper from '../helpers/UpdateHelper.js';
import Base from './Base.js';
import _3dGirtHoriC from '../3d/GirtHoriC.js'

export default class Model extends Base {
    constructor(design) {        
        super(design);
    }

    initRebuildHierarchy(){        
        this.rebuildTypeRoot = new TreeNode(null, rebuildTypes.full);
        this.rebuildTypeRoot.addChildNode(rebuildTypes.move);
    }
    detectImpact(impact){
        if(this.shouldProcessOwnImpact(impact, this.design.id))
            this.processImpactFromSelf(impact);
    }
    
    processRebuild(type){
        // rebuild the minimal amount of 3d objects for this component
        switch(type){
            case rebuildTypes.full:
                this.rebuildFull();
                break;
            case rebuildTypes.move:
                this.rebuildMove();
                break;
        }
    }

    processImpactFromSelf(impact){
        switch(impact.change.name){
            case impactTypes.modelPosition:
                this.addRebuildNeeded(rebuildTypes.move);
                break;

        }
    }
    
    
    rebuildMove(){
        this.group.position.x = this.design.pos.x;
        this.group.position.z = this.design.pos.z;
        // this.group.position.y = 0;
        // let found=false;
        // this.masterDesign.design.getAllStructures().forEach((s)=>{
        //     if(found)return;

        //     if(s.pos.x - s.getFrame().length/2 > this.design.pos.x )
        //         found=true;
        // });
        // if(found)
        //     this.group.position.y = 6;
    }

    migrate(design){
        if(!design)
            return;
  
    }

    static canEdit(){
        return true;
    }
    static canDelete(){
        return true;
    }

    applyDesign(design){
        this.design.type = design.type;
        this.design.model = design.model;
        this.design.pos.x = design.pos.x;
        this.design.pos.z = design.pos.z;
        this.masterDesign.getall
        //this.masterDesign;
        //if(this.design.pos.x )
    }
    
    defaultDesign(){

        return {
            model: 'jeep',
            pos:{
                x:0,
                z:0
            },
            type: CORE.components.model
        }
    }
    
    getOptionsSpec(){
        return [
            // TODO: handle conversion of these back and forth between feet and inches
            OptionHelper.selection("model","Model", impactTypes.none, undefined, 
            [
                {
                    value: 'Jeep',
                    text: `Jeep`
                },
            ],
            true            
            )            
        ]
    }
    
    getOutlineMeshes(){
        return [];
    }

    static parentComponentTypes(){
        // used when moving a grabject to get a list of component types this feature can land on
        return [
            CORE.components.ground,
            CORE.components.foundation,
        ];
    }
    
    static updatePosX(design, x){
        if(design.pos.x == x)
            return false;
        design.pos.x=x;
        return true;
    }

    static updatePosY(design) {
        // calculate the pos.y so that the door always touches the ground
        let y = design.dim.height/2;
        if(design.pos.y === y)
            return false;
        design.pos.y = y;
        return true;
    }

    getDeleteImpactType(){
        return impactTypes.deleteProp;
    }

    setPosition(){
        if(!this.design.pos)
            return;
        
        this.group.position.x = this.design.pos.x;
        this.group.position.z = this.design.pos.z;
    }
    updatePosition(center)
    {
        Model.updatePosition(center, this.design)
    }

    static updatePosition(center, design){
        let affected = false
        // moves the built object, so it doesn't have to be rebuilt.
        affected = Model.updatePosX(design, center.x) || affected;
        affected = Model.updatePosY(design) || affected;
        return affected;
    }



    setPickMeshGeometry(){
        //TODO: cleanup redundant variables frameDepth and halfFrameDepth that were copied from build() for the sake of rebuilds
        let frameDepth = 8; // 8 inches
        let halfFrameDepth = frameDepth/2;
        this.pickDetectionMesh.geometry = new THREE.BoxGeometry(this.design.dim.width, this.design.dim.height, halfFrameDepth);
    }

    show(v){
        this.visible=v;
        this.group.visible = this.visible;
    }
        
    // required by object interface
    getContextMenuPosition(){        
        // this returns the position of the contextMenuObject (created in build function) 
        // in terms of world position (considering all object groups, and transforms)
        return this.contextMenuObject.getWorldPosition(new THREE.Vector3());
    }

    getTopRightCorner(){
         let offset = new THREE.Vector3(1.3*this.design.dim.width/2,
                                        .8*this.design.dim.height/2,
                                        0);
         return offset;
    }

    // required by object interface
    getDescription(){
        return `Model`
    }
    
    

    // required by object interface
    styleCollision() {
    }
    
    // required by object interface
    getCollisionClass(){
        return CORE.collisions.classes.none;
    }
    
    // required by object interface
    getCollisionZones() {
        return [];
    }

    getInitialGrabjectUserData(){
        let grabData = super.getInitialGrabjectUserData();
        grabData.allowedParentComponentTypes = [
            CORE.components.ground,
            CORE.components.foundation
        ]
        return grabData;
    }

    build() {
        /* so this works now, but
         - it's late and I have to go to bed
         - it's adding a new jeep every move instead of moving
         - it keeps adding until I click
         - structures don't do that.
        */



        // use the design to build the component        
        if(!CORE.models || !CORE.models.length>0 || !CORE.models[0].data || !CORE.models[0].data.children.length>0)
            return;
        let car = CORE.models[0].data.children[0];
        car.scale.set(40,40,40);
        this.group.add(car.clone())
        
        //this.group.add(this.contextMenuObject);        
        this.group.visible = true;

        this.buildGrabjects()
        this.showGrabjects(this.design.selected);
        
        if(typeof this.pickDetectionMesh === 'undefined'){            
            var geoPick = new THREE.BoxGeometry(80,70,150);
            geoPick.name = 'model picking'
            this.pickDetectionMesh = new THREE.Mesh(geoPick, CORE.materials.transparent);
            this.pickDetectionMesh.name=`pickMesh for model ${this.design.id}`;
            this.pickDetectionMesh.visible=false;
            this.pickDetectionMesh.castShadow=false;
            this.pickDetectionMesh.receiveShadow=false;
            this.pickDetectionMesh.position.y=40;
        }
        this.pickDetectionMesh.userData = {
            type: this.design.type, // not sure this is necessary. copied from wallBase.
            id: this.design.id
        };
        this.outlineMesh = this.pickDetectionMesh;
        this.group.add(this.pickDetectionMesh);
        this.built=true;
    }

    buildGrabjects(){
            
        let grabjectSize = 8;
        let grabjectSizeVector = new THREE.Vector3(grabjectSize, grabjectSize, 5);
        this.grabjectGroup = new THREE.Group();
        let parentComponentTypes = [
            CORE.components.ground,
            CORE.components.foundation
        ]
        this.positionGrabject = new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(0,0,0), [CORE.indicators.inOutLeftRight],
         new THREE.Vector3(15, 15, 5), CORE.grabjects.position, parentComponentTypes, undefined, new THREE.Vector3(0,96,0));
        
        this.group.add(this.grabjectGroup);
    }

    setCollisionZone(){
        
    }
    
    remove() {
        MemHelper.removeAllChildren(this.group);
        MemHelper.dispose(this.group);
    }

    getTypeDisplayName(){
        return "Renegade"
    }

}
