import * as THREE from 'three';
import _3dBase from './Base.js'
import layerHelper from '../helpers/layerHelper.js';
import { CORE } from '../_spec.js';
import materialHelper from '../helpers/materialHelper.js';
import Util from '../utility.js';

export default class RoofValleyFlange extends _3dBase {
    constructor(lod,layer, length, leftRadians, rightRadians){
        super(undefined,undefined,undefined, lod);        
        let width = 8;
        let leftXOffset = Math.cos(leftRadians)*width/2;
        let leftYOffset = Math.sin(leftRadians)*width/2;
        let rightXOffset = Math.cos(rightRadians)*width/2;
        let rightYOffset = Math.sin(rightRadians)*width/2;

        if(Util.isDefined(leftRadians))
            this.buildHalf(length, width, leftRadians, -leftXOffset, leftYOffset)
        if(Util.isDefined(rightRadians))
            this.buildHalf(length, width, -rightRadians, rightXOffset, rightYOffset)
        layerHelper.setGroup(this.group, layer, true);
    }

    buildHalf(length, width, radians, x, z){
        
        let geo = new THREE.PlaneBufferGeometry(width, length,1,1);
        let material;
        if(this.isHighDetail())
            material = materialHelper.getFrameMaterial_HD();
        else
            material = materialHelper.getFrameMaterial_LD();

        let mesh = new THREE.Mesh(geo, material);
        mesh.material.side = THREE.DoubleSide;
        //mesh.rotation.y = radians;
        //mesh.position.x = x;
        mesh.position.y = length/2;
        //mesh.position.z = z;
        this.group.add(mesh);        
    }



    
}

