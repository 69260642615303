import * as THREE from 'three'
import _3dBase from './Base.js'
import {CORE} from '../_spec.js'
import layerHelper from '../helpers/layerHelper.js'
import MemHelper from '../helpers/memHelper.js';

export default class DownspoutPipe extends _3dBase {
//reference material:
//https://images.squarespace-cdn.com/content/v1/569685c1df40f31bc5cd0840/1601301987021-XWFLFFCB1FCB2ELJM91S/ke17ZwdGBToddI8pDm48kNnbBUj9eSp7j6QYKRqkc1QUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYy7Mythp_T-mtop-vrsUOmeInPi9iDjx9w8K4ZfjXt2dk-7GxrQNx-DJlyQFCDfyfTvaqLCzhT1I4jf7RojfHbjW07ycm2Trb21kYhaLJjddA/front.jpg?format=1500w

    constructor(length, material, width=4, depth=3){
        super()
        // vertical rectangular downspout
        let geoMain = new THREE.BoxGeometry(width, length, depth);
        let mesh = new THREE.Mesh(geoMain, material);
        this.group.add(mesh);
    }
}