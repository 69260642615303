import BpFrameLine from '../blueprints/bpFrameLineMain.js'
import * as THREE from 'three'

import {CORE} from '../_spec.js';
import { Vector3 , Vector2, Quaternion} from 'three';
import BlueprintHelper from '../helpers/blueprintHelper.js'
import vHelper from '../helpers/vHelper.js'
import CollisionHelper from '../helpers/CollisionHelper.js';
import layerHelper from '../helpers/layerHelper.js'
import _3dColumnStraight from '../3d/ColumnStraight.js';
import  _3dColumnTapered from '../3d/ColumnTapered.js';
import _3dColumnC from '../3d/ColumnC.js';
import _3dColumnSquare from '../3d/ColumnSquare.js'
import _3dRafterStraight from '../3d/RafterStraight.js';
import _3dRafterC from '../3d/RafterC.js';
import _3dRafterTapered from '../3d/RafterTapered.js';
import materialHelper from '../helpers/materialHelper.js';


export default class FrameLine{
    constructor(
        structureConfig,
        bpFrameLine,
        intermediateColumnsOpenTo,         
        frameLineType, 
        isPorch, 
        frameType,
        width, 
        lowEaveHeight,
        buildIntermediateColumns,
        rafterCollisionZones,
        columnCollisionZones,
        rafterCOpenEnd,
        baySpacing,
        supportsAnEndPorch,
        leftEndWallFrameline,
        rightEndWallFrameline,
        wrapTubePorchColumns,
        roofFrameType,
        pitchRatio,
        roofType,
        beamColor,
        purlinColor
        ){

        let frontTopY;
        if(roofType == CORE.roof.types.gable)
            frontTopY = bpFrameLine.yPosBackColumnAndRafter;
            else
            frontTopY = bpFrameLine.yPosFrontColumnAndRafter;

        this.group = new THREE.Group();
        this.group.name = 'FrameLine'
        this.intermediateColumnsOpenTo = intermediateColumnsOpenTo;
        this.baySpacing = baySpacing;
        // TODO: The filling of this array assumes frameLines lie in the z axis only which will not be the case with some porches, or additional structures
        this.columnPositions = [];
        this.leftEndWallFrameline = leftEndWallFrameline;
        this.rightEndWallFrameline = rightEndWallFrameline;
        // these are all purposefully the outside edge of the frame so that varying column widths can be taken into account per column.
        
        this.frameType = frameType;
        this.lowEaveHeight = lowEaveHeight;
        this.roofType=roofType;
        this.roofFrameType = roofFrameType;
        this.beamColor = beamColor;
        this.purlinColor = purlinColor;
        this.length = width;
        this.pitchHeight = BlueprintHelper.pitchHeight(this.length, pitchRatio, roofType);
        this.pitchRatio = pitchRatio;
        this.pitchRadians = BlueprintHelper.pitchRatioToRadians(this.pitchRatio);
        if(isPorch){
            this.buildPorchFrameLine(structureConfig, bpFrameLine, rafterCollisionZones, columnCollisionZones,wrapTubePorchColumns);
        }
        else
        {
            this.buildMainStructureFrameLine(bpFrameLine, frontTopY, buildIntermediateColumns,rafterCollisionZones,                columnCollisionZones,                rafterCOpenEnd,                supportsAnEndPorch);
        }
            
        // make sure column positions are always in ascending order.
        this.columnPositions.sort((a,b) => a-b);
        
    }

    getRafterStraightBasePosZ(side, bpFrameLine){
        let rafterMargin = 1//TODO: centralize me with other rafter type margins (all rafter type margins are 1 I think)
        if(side === CORE.sides.frontSide){
            if(this.frameType === CORE.frame.types.weld || this.frameType ===CORE.frame.types.weldPlus) // weld
                return this.length/2 -rafterMargin;
            else // bolt
                return this.length/2 -rafterMargin - bpFrameLine.materialDim.depth;
        }
        if(side === CORE.sides.backSide){
            if(this.frameType === CORE.frame.types.weld || this.frameType ===CORE.frame.types.weldPlus)
                return -this.length/2 + rafterMargin;
            else{
                return -this.length/2 + rafterMargin + bpFrameLine.materialDim.depth;
            }
        }
    }

    getRafterStraightPeakPosZ(bpFrameLine, isPorch){
        let rafterMargin = 1//TODO: centralize me with other rafter type margins (all rafter type margins are 1 I think)
        if(this.frameType === CORE.frame.types.weld || this.frameType ===CORE.frame.types.weldPlus){ // weld
            if(this.roofType === CORE.roof.types.gable){
                // welded gabled-roof frame
                return 0;
            }
            else // slope
            {
                if(isPorch)
                    return -this.length/2 -rafterMargin;
                else
                    // welded sloped-roof frame
                    return -this.length/2+rafterMargin;
            }
        }
        else{ //bolt
            if(this.roofType === CORE.roof.types.gable){

                // bolted gabled-roof frame
                return 0
            }
            else // slope
            {
                if(isPorch)
                    return -this.length/2-rafterMargin; // invade the airspace of the main structure so the porch appears connected
                else // bolted sloped-roof frame
                    return -this.length/2+rafterMargin + bpFrameLine.materialDim.depth
            }
        }
    }

    getRafterCBasePosZ(side){
        let margin = 1;//TODO: centralize me with other rafter type margins (all rafter type margins are 1 I think)
        if(side === CORE.sides.backSide)
            return -this.length/2 + margin;
        else
            return  this.length/2 - margin;        
    }

    getRafterCPeakPosZ(isPorch){
        let margin = 1; //TODO: centralize me with other rafter type margins (all rafter type margins are 1 I think)
        if(this.roofType === CORE.roof.types.gable){
            return 0;
        }
        else // single slope
        {
            if(isPorch)
                return -this.length/2 - margin
            else
                return -this.length/2 + margin;
        }
    }

    getRafterTaperedPeakPosZ(materialDim){
        let margin = 1;//TODO: centralize me with other rafter type margins (all rafter type margins are 1 I think)
        if(this.roofType === CORE.roof.types.gable)
            return 0
        else
            return -this.length/2 + margin + materialDim.depthHaunch;
    }

    getRafterTaperedBasePosZ(side, materialDim){
        let margin = 1;//TODO: centralize me with other rafter type margins (all rafter type margins are 1 I think)
        if(side == CORE.sides.frontSide)
            return this.length/2 -margin - materialDim.depthHaunch;
        else{
            if(this.frameType === CORE.frame.types.weld  || this.frameType ===CORE.frame.types.weldPlus)
                return -this.length/2 + margin;
            else
                return -this.length/2 + margin + materialDim.depthHaunch;
        }
    }

    buildPorchFrameLine(structureConfig, bpFrameLine, rafterCollisionZones,
        columnCollisionZones,
        wrapTubePorchColumns
        ){        
        //this.posX = frontBottom.x;
        // get the frame dimensions from blueprintHelper        
        let marginDepth = 1;
        let colHeight;// = ft.y-fb.y;

        if(this.roofType == CORE.roof.types.gable)
            colHeight = bpFrameLine.yPosBackColumnAndRafter;
        else
            colHeight = bpFrameLine.yPosFrontColumnAndRafter;
        
        let columnZ = this.length/2;
        if((this.frameType === CORE.frame.types.weld  || this.frameType ===CORE.frame.types.weldPlus) && this.length <= 15*12) // 15 feet in inches because width is in inches
        {

            let frameMaterial;
            let columnSquareFrameSideLength = 4            
            let columnSquareTrimSideLength = columnSquareFrameSideLength + .2;
            let pos = new THREE.Vector3(0,0,columnZ - columnSquareFrameSideLength/2);
            // we have to add two square columns here because of the ability to toggle layers on/off
            // add the framing square column with appropriate LOD
            if(CORE.preferences.des_levelOfDetail.value === CORE.levelOfDetail.high)
                frameMaterial=materialHelper.getSquareColumnFrameMaterial_HD(colHeight, columnSquareFrameSideLength, this.beamColor);
            else
                frameMaterial=materialHelper.getFrameMaterial_LD(this.beamColor);

            this.frontColumn = new _3dColumnSquare(colHeight, frameMaterial, columnSquareFrameSideLength, CORE.layers.frame);
            this.frontColumn.group.position.copy(pos.clone())
            this.frontColumn.group.rotation.y = 0;

            // if also wrapped, also add the trimmed square column
            
            if(wrapTubePorchColumns){
                let trimmedColumn = new _3dColumnSquare(colHeight, structureConfig.trimMaterials.corner, columnSquareTrimSideLength, CORE.layers.walls );
                trimmedColumn.group.position.copy(pos.clone())
                trimmedColumn.group.rotation.y = 0;
                this.group.add(trimmedColumn.group);
                layerHelper.enableLayer(trimmedColumn.group, CORE.layers.quote);
            }
        } 
        else
        {            
            
            // COLUMNS
            if(bpFrameLine.materialDim.shape === CORE.frame.shapes.straight)
            {
                let frontCollisionZone = columnCollisionZones;
                if(frontCollisionZone)
                    frontCollisionZone = {
                        dim: new Vector3(bpFrameLine.flange+24, colHeight, bpFrameLine.depth),
                        position: new Vector3(0,colHeight/2,0),
                        margin: CollisionHelper.getMargin(xp,xn,0,0,12,6),
                    }    
                
                this.frontColumn = new _3dColumnStraight(CORE.preferences.des_levelOfDetail.value, colHeight, true, frontCollisionZone, bpFrameLine.materialDim, bpFrameLine.rafterDimYAtColumn, this.pitchRadians, this.frameType === CORE.frame.types.bolt, this.beamColor);
                this.frontColumn.group.position.set(0,0,columnZ)
                this.frontColumn.group.rotation.y = 0;
            }
            else
            {
                this.frontColumn = new _3dColumnTapered(CORE.preferences.des_levelOfDetail.value, colHeight, this.pitchRadians, columnCollisionZones, CORE.sides.backSide, bpFrameLine, this.roofFrameType === CORE.roof.frameTypes.bypass, this.beamColor);
                this.frontColumn.group.position.set(0,0,columnZ)
                this.frontColumn.group.rotation.y = 0;
            }
        }
        this.group.add(this.frontColumn.group);//checked
        layerHelper.enableLayer(this.frontColumn.group, CORE.layers.quote);
        this.columnPositions.push(columnZ);
            
        // RAFTERS
        if(bpFrameLine.materialDim.shape === CORE.frame.shapes.straight){
            // FRONT SIDE SLOPED RAFTER
            let pos = new Vector3(0, bpFrameLine.yPosFrontColumnAndRafter, this.getRafterStraightBasePosZ(CORE.sides.frontSide, bpFrameLine));
            let peak = new Vector3(0, bpFrameLine.yPosColumnAndRafterPeak, this.getRafterStraightPeakPosZ(this.bpFrameLine, true));
            let length = pos.distanceTo(peak);
            
            let frontRot = new Quaternion().multiplyQuaternions(
                // back rafter is the standard, and quaternions are in world coordinates, so 
                new Quaternion().setFromAxisAngle(new Vector3(1,0,0),this.pitchRadians), 
                new Quaternion().setFromAxisAngle(new Vector3(0,1,0),Math.PI)
            );
            
            this.frontRafter = new _3dRafterStraight(
                CORE.preferences.des_levelOfDetail.value,
                this.group,
                pos,
                frontRot,
                length,
                this.pitchRadians,
                rafterCollisionZones ,
                bpFrameLine, 
                this.frameType === CORE.frame.types.bolt,
                this.roofFrameType === CORE.roof.frameTypes.bypass,
                this.beamColor
                ); // porch slope
        }
        else
        {
            throw `Porch frame cannot be ${bpFrameLine.materialDim.shape}`;
        }
        layerHelper.enableLayer(this.frontRafter.group, CORE.layers.quote);
    }

    buildMainStructureFrameLine(bpFrameLine, frontTopY, buildIntermediateColumns,rafterCollisionZones,
        columnCollisionZones,
        rafterCOpenEnd,
        supportsAnEndPorch
        ){
            //this.posX = backBottom.x; // for wall subcomponent distance markings
            let middleColumnCollisionZones=false;
            // get the frame dimensions from blueprintHelper
            this.bpFrameLine = bpFrameLine;
            
            
            let marginDepth = 1;
            // COLUMNS
            // main can be straight or tapered, only at front and back sides
            // postAndBeam and standard are straight and Cee respectively, with intermediate columns.
            if(bpFrameLine.lineType === CORE.frame.lineTypes.main){
                marginDepth=0; // allows alignment of main frame column positions and LEW and REW porch mainframe column positions
                // build the end columns (straight or tapered)
                if(this.bpFrameLine.materialDim.shape === CORE.frame.shapes.straight){
                    // front straight column
                    // calculate column position (front)

                    let colHeight;

                    if(this.roofType == CORE.roof.types.gable)
                        colHeight = bpFrameLine.yPosBackColumnAndRafter;
                    else
                        colHeight = bpFrameLine.yPosFrontColumnAndRafter;
                 
                    let frontCollisionZone = true
                    if(frontCollisionZone){
                        let xp = 0;
                        let xn = 0;
                        let zp = 6; // make barely visible on front wall
                        let zn = 0;
                                                
                        if(this.leftEndWallFrameline){
                            xn = .5; // shorten the local x-negative margin                            
                            zn = .5; // lengthen the local z-negative margin
                        }
                        if(this.rightEndWallFrameline){
                            xp = .5; // shorten the local x-negative margin
                            zn = .5; // lengthen the local z-negative margin
                        }

                        let margin = CollisionHelper.getMargin(xp,xn,0,0,zp,zn);
                        //margin = CollisionHelper.getMargin();

                        frontCollisionZone={
                            dim: new Vector3(this.bpFrameLine.materialDim.flange, colHeight, this.bpFrameLine.materialDim.depth),
                            margin,
                            position: new Vector3(0,colHeight/2, -this.bpFrameLine.materialDim.depth/2)                            
                        }
                    }

                    // frontSide column requires zero rotation of the column
                    this.frontColumn = new _3dColumnStraight(CORE.preferences.des_levelOfDetail.value, colHeight, true, frontCollisionZone, 
                        this.bpFrameLine.materialDim,
                        this.bpFrameLine.rafterDimYAtColumn,
                        this.pitchRadians, this.frameType === CORE.frame.types.bolt,
                        this.beamColor);

                    let columnZ = this.length/2;
                    this.frontColumn.group.position.set(0,0,columnZ)
                    this.frontColumn.group.rotation.y =0;
                    this.group.add(this.frontColumn.group);//checked
                    layerHelper.enableLayer(this.frontColumn.group, CORE.layers.quote);
                    this.columnPositions.push(columnZ);
                    
                    // back straight column
                    // calculate column position (back)
                    
                    let pitch = this.pitchRadians; // pitch the top of back column up toward the roof ridge

                    if( this.roofType === CORE.roof.types.slope){
                        // this re-generation of the bpframeline solves something with negative slopes required for back columns on a single-slope
                        pitch = -this.pitchRadians; // pitch the top of this back column down parallel with the roof surface
                    }
                        
                    colHeight = this.bpFrameLine.yPosBackColumnAndRafter

                    let backCollisionZone = columnCollisionZones
                    if(backCollisionZone){
                        let xp = 0;
                        let xn = 0;
                        let zp = 6;// make barely visible on front wall
                        let zn = 0;
                                                
                        if(this.leftEndWallFrameline){
                            xp = .5; // shorten the local x-negative margin 
                            zn = .5; // lengthen the local z-negative margin
                        }
                        if(this.rightEndWallFrameline){
                            xn = .5; // shorten the local x-negative margin
                            zn = .5; // lengthen the local z-negative margin
                        }

                        let margin = CollisionHelper.getMargin(xp,xn,0,0,zp,zn);
                        //margin = CollisionHelper.getMargin();
                        backCollisionZone={
                            dim: new Vector3(this.bpFrameLine.materialDim.flange, colHeight, this.bpFrameLine.materialDim.depth),
                            margin,
                            position: new Vector3(0,colHeight/2, -this.bpFrameLine.materialDim.depth/2)                            
                        }
                    }
                    this.backColumn = new _3dColumnStraight(CORE.preferences.des_levelOfDetail.value, colHeight, true, backCollisionZone, this.bpFrameLine.materialDim, this.bpFrameLine.rafterDimYAtColumn, pitch, this.frameType === CORE.frame.types.bolt, this.beamColor);
                    columnZ = -this.length/2;
                    this.backColumn.group.position.set(0,0,columnZ)
                    this.backColumn.group.rotation.y =Math.PI;
                    this.group.add(this.backColumn.group);
                    layerHelper.enableLayer(this.backColumn.group, CORE.layers.quote);
                    this.columnPositions.push(columnZ);

                    this.width = this.bpFrameLine.materialDim.flange; // for wall subcomponent distance markings
                }
                else // tapered
                {
                    // front tapered column
                    let colHeight;

                    if(this.roofType == CORE.roof.types.gable)
                        colHeight = bpFrameLine.yPosBackColumnAndRafter;
                    else
                        colHeight = bpFrameLine.yPosFrontColumnAndRafter;

                    let frontCollisionZone = true
                    if(frontCollisionZone){
                        let xp = 0;
                        let xn = 0;
                        let zp = 6; // make barely visible on front wall
                        let zn = 0;
                                                
                        if(this.leftEndWallFrameline){
                            xn = .5; // shorten the local x-negative margin                            
                            zn = .5; // lengthen the local z-negative margin
                        }
                        if(this.rightEndWallFrameline){
                            xp = .5; // shorten the local x-negative margin
                            zn = .5; // lengthen the local z-negative margin
                        }

                        let margin = CollisionHelper.getMargin(xp,xn,0,0,zp,zn);
                        //margin = CollisionHelper.getMargin();

                        frontCollisionZone={
                            dim: new Vector3(this.bpFrameLine.materialDim.flange, colHeight+this.bpFrameLine.materialDim.depthHaunch, this.bpFrameLine.materialDim.depthHaunch),
                            margin,
                            position: new Vector3(0,(colHeight+this.bpFrameLine.materialDim.depthHaunch)/2, -this.bpFrameLine.materialDim.depthHaunch/2)                            
                        }
                    }

                    this.frontColumn = new _3dColumnTapered(CORE.preferences.des_levelOfDetail.value, colHeight, this.pitchRadians, frontCollisionZone, CORE.sides.backSide, this.bpFrameLine, this.roofFrameType === CORE.roof.frameTypes.bypass, this.beamColor);
                    let columnZ = this.length/2;
                    let columnPos = new THREE.Vector3(0,0,columnZ)
                    this.frontColumn.group.position.set(0,0,columnZ)
                    this.frontColumn.group.rotation.y = 0;
                    this.group.add(this.frontColumn.group);
                    layerHelper.enableLayer(this.frontColumn.group, CORE.layers.quote);
                    this.columnPositions.push(columnZ);
                    
                    // Back tapered column
                    
                    let pitch = this.pitchRadians; // pitch the top of back column up toward the roof ridge
                    if( this.roofType === CORE.roof.types.slope){
                        let dims = BlueprintHelper.getMainFrameMaterialSize(this.length, this.frameType)
                        let bpFl = BpFrameLine.generate(this.bpFrameLine.lineType, dims, this.lowEaveHeight, this.frameType, -this.pitchRadians, this.roofType, this.pitchHeight, BlueprintHelper.pitchedPurlinDimY(this.pitchRatio),this.length, this.pitchRatio);
                        //bt.y = bpFl.yPosBackColumnAndRafter;
                        pitch = -this.pitchRadians;// pitch the top of this back column down parallel with the roof surface
                    }
                    
                    colHeight = this.bpFrameLine.yPosBackColumnAndRafter

                    //length = bt.y-bb.y;
                    let backCollisionZone = columnCollisionZones
                    if(backCollisionZone){
                        let xp = 0;
                        let xn = 0;
                        let zp = 6;// make barely visible on front wall
                        let zn = 0;
                                                
                        if(this.leftEndWallFrameline){
                            xp = .5; // shorten the local x-negative margin 
                            zn = .5; // lengthen the local z-negative margin
                        }
                        if(this.rightEndWallFrameline){
                            xn = .5; // shorten the local x-negative margin
                            zn = .5; // lengthen the local z-negative margin
                        }

                        let margin = CollisionHelper.getMargin(xp,xn,0,0,zp,zn);
                        //margin = CollisionHelper.getMargin();
                        backCollisionZone={
                            dim: new Vector3(this.bpFrameLine.materialDim.flange, colHeight+this.bpFrameLine.materialDim.depthHaunch, this.bpFrameLine.materialDim.depthHaunch),
                            margin,
                            position: new Vector3(0,(colHeight+this.bpFrameLine.materialDim.depthHaunch)/2, -this.bpFrameLine.materialDim.depthHaunch/2)                            
                        }
                    }

                    columnZ = -this.length/2;
                    columnPos = new THREE.Vector3(0,0,columnZ);
                    this.backColumn = new _3dColumnTapered(CORE.preferences.des_levelOfDetail.value, colHeight, pitch, backCollisionZone, CORE.sides.frontSide, this.bpFrameLine, this.roofFrameType === CORE.roof.frameTypes.bypass, this.beamColor);
                    this.backColumn.group.position.set(0,0,columnZ);
                    this.backColumn.group.rotation.y = Math.PI;

                    this.group.add(this.backColumn.group);
                    layerHelper.enableLayer(this.backColumn.group, CORE.layers.quote);
                    this.columnPositions.push(columnZ);
                    this.width = this.bpFrameLine.materialDim.flange;  // for wall subcomponent distance markings
                }

                // build the intermediate columns required by a porch
                if(supportsAnEndPorch){
                    let pAndBFrameDims = BlueprintHelper.getPostAndBeamFrameMaterialSize()
                    let frameLinePostAndBeam = BpFrameLine.generate(CORE.frame.lineTypes.postAndBeam, pAndBFrameDims, this.lowEaveHeight, this.frameType, -this.pitchRadians, this.roofType, this.pitchHeight, BlueprintHelper.pitchedPurlinDimY(this.pitchRatio),this.length, this.pitchRatio);
                    this.buildColumns(
                        frontTopY,
                        false, // buildEndColumns, false because we literally just built two gigantic straight or taperend end columns
                        true, // buildIntermediateColumns
                        false, // columnCollisionZones
                        false, // middleColumnCollisionZones
                        CORE.frame.lineTypes.postAndBeam,
                        frameLinePostAndBeam);
                }
            }
            else{

                this.buildColumns(
                    frontTopY,
                    true,
                    buildIntermediateColumns,
                    columnCollisionZones,
                    middleColumnCollisionZones,
                    bpFrameLine.lineType,
                    this.bpFrameLine);
            }
            
        // RAFTERS
        if(bpFrameLine.lineType === CORE.frame.lineTypes.main || bpFrameLine.lineType === CORE.frame.lineTypes.postAndBeam){
            if(this.bpFrameLine.materialDim.shape === CORE.frame.shapes.straight)
            {
                if(this.roofType === CORE.roof.types.gable)
                {
                    /*
                        there is a bug here
                        The rafter is pitched/rotated correctly in 3d
                        however, the rafter peak is not at the 100% correct location
                        because the length of the rafter (calculated)
                        and the position of the rafter use only partially overlapping 
                        variables
                        
                        Something is probably wrong with the peak point or the pos.z value

                        I have to move on because I'm in the middle of wrapped porches
                        try to free up my straight rafter for use as a hipRafter
                        instead of just a front/back frame rafter
                    */
                    
                    let centerBackoff=0;
                    if(this.frameType === CORE.frame.types.bolt)
                        centerBackoff = (.05+CORE.steel.thickness_in) / Math.cos(this.pitchRadians); // not perfect, but very close

                    let pos = new Vector3(0, this.bpFrameLine.yPosFrontColumnAndRafter, this.getRafterStraightBasePosZ(CORE.sides.frontSide, this.bpFrameLine));
                    let peak = new Vector3(0, this.bpFrameLine.yPosColumnAndRafterPeak, this.getRafterStraightPeakPosZ(this.bpFrameLine, false));
                    let length = pos.distanceTo(peak);
                    length -= centerBackoff; 

                    let frontRot = new Quaternion().multiplyQuaternions(
                        // back rafter is the standard, and quaternions are in world coordinates, so 
                        new Quaternion().setFromAxisAngle(new Vector3(1,0,0),this.pitchRadians), 
                        new Quaternion().setFromAxisAngle(new Vector3(0,1,0),Math.PI)
                    );
                    this.frontRafter = new _3dRafterStraight(
                        CORE.preferences.des_levelOfDetail.value, 
                        this.group, 
                        pos, 
                        frontRot, 
                        length, 
                        this.pitchRadians, 
                        rafterCollisionZones,
                        this.bpFrameLine, 
                        this.frameType === CORE.frame.types.bolt, // bolted
                        this.roofFrameType === CORE.roof.frameTypes.bypass, // includeBoltPlateTop
                        this.beamColor
                        ); // main gable front

                    layerHelper.enableLayer(this.frontRafter.group, CORE.layers.quote);

                    pos = new Vector3(0, this.bpFrameLine.yPosFrontColumnAndRafter, this.getRafterStraightBasePosZ(CORE.sides.backSide, this.bpFrameLine));
                    peak = new Vector3(0, this.bpFrameLine.yPosColumnAndRafterPeak, this.getRafterStraightPeakPosZ(this.bpFrameLine, false));
                    length = pos.distanceTo(peak);
                    //console.log(pos);
                    //console.log(peak);
                    //console.log(length);
                    //console.log(centerBackoff);
                    length -= centerBackoff;
                    //console.log(length);
                    let backRot = new Quaternion().setFromAxisAngle(new Vector3(1,0,0),-this.pitchRadians);
                    

                    this.backRafter = new _3dRafterStraight(
                        CORE.preferences.des_levelOfDetail.value, 
                        this.group, 
                        pos,
                        backRot, 
                        length, 
                        this.pitchRadians, 
                        rafterCollisionZones,
                        this.bpFrameLine,
                        this.frameType === CORE.frame.types.bolt, // bolted
                        this.roofFrameType === CORE.roof.frameTypes.bypass, // includeBoltPlateTop
                        this.beamColor
                        ); // main gable back
                    layerHelper.enableLayer(this.backRafter.group, CORE.layers.quote);
                    // check the triangle
                    //let rise = peak.y - pos.y;
                    //let run = (peak.z - pos.z);
                    //let calcAngle = Math.atan(rise/run);
                    //console.log(`rise ${rise}, run ${run}`)
                    //console.log(`set ${this.pitchRadians*180/Math.PI} deg vs calc`, calcAngle*180/Math.PI , ' deg' )

                    /*
                    let getExtrudedMesh = function(points, material){
                        let mesh = ShapeHelper.getMeshFromPoints( 
                            points,        
                        {
                                depth: .1, //used to be amount
                                bevelEnabled: false,
                                bevelThickness: 0.1,
                                bevelSize: 1,
                                bevelSegments: 1,
                                material: 0,
                                extrudeMaterial: 1
                            }
                            ,
                        material );        
                        mesh.layers.set(CORE.layers.frame);
                        mesh.castShadow=false;
                        return mesh;
                    }

                    let scale = 2;
                    let peakPoint = getExtrudedMesh([
                        new Vector2(0,0),
                        new Vector2(2/3*scale,scale),
                        new Vector2(scale,scale),
                        new Vector2(scale,2/3*scale),
                    ], CORE.materials.debug)
                    peakPoint.position.set(peak.x,peak.y,peak.z);
                    this.group.add(peakPoint)
                    
                    scale = 2;
                    let basePoint = getExtrudedMesh([
                        new Vector2(0,0),
                        new Vector2(2/3*scale,scale),
                        new Vector2(scale,scale),
                        new Vector2(scale,2/3*scale),
                    ], CORE.materials.debug)
                    basePoint.position.set(pos.x,pos.y,pos.z);
                    this.group.add(basePoint)
                    */
                }
                else{
                    let pos = new Vector3(0, this.bpFrameLine.yPosFrontColumnAndRafter, this.getRafterStraightBasePosZ(CORE.sides.frontSide, this.bpFrameLine));
                    let peak = new Vector3(0, this.bpFrameLine.yPosColumnAndRafterPeak, this.getRafterStraightPeakPosZ(this.bpFrameLine, false));
                    let length = pos.distanceTo(peak);
                    let frontRot = new Quaternion().multiplyQuaternions(
                        // back rafter is the standard, and quaternions are in world coordinates, so 
                        new Quaternion().setFromAxisAngle(new Vector3(1,0,0),this.pitchRadians), 
                        new Quaternion().setFromAxisAngle(new Vector3(0,1,0),Math.PI)
                    );

                    this.frontRafter = new _3dRafterStraight(
                        CORE.preferences.des_levelOfDetail.value, 
                        this.group, 
                        pos, 
                        frontRot, 
                        length, 
                        this.pitchRadians, 
                        rafterCollisionZones, 
                        this.bpFrameLine,
                        this.frameType === CORE.frame.types.bolt, // bolted
                        this.roofFrameType === CORE.roof.frameTypes.bypass, // includeBoltPlateTop
                        this.beamColor
                        ); // main slope front
                    layerHelper.enableLayer(this.frontRafter.group, CORE.layers.quote);

                }
                    
            }
            else{
                if(this.roofType === CORE.roof.types.gable){
                    let frontBase = new Vector3(0, this.bpFrameLine.yPosFrontColumnAndRafter, this.getRafterTaperedBasePosZ(CORE.sides.frontSide, this.bpFrameLine.materialDim));
                    let frontPeak = new Vector3(0, this.bpFrameLine.yPosColumnAndRafterPeak, this.getRafterTaperedPeakPosZ(this.bpFrameLine.materialDim));
                    let frontLength = frontBase.distanceTo(frontPeak);
                    let centerBackoff = (.05+CORE.steel.thickness_in) / Math.cos(this.pitchRadians);
                    frontLength -= centerBackoff; // this allows the two beams not to actually quite touch, peak bolt plates are flush for pitches 0/12 to 6/12 (// not perfect, but very close)

                    let frontRot = new Quaternion().multiplyQuaternions(
                        // back rafter is the standard, and quaternions are in world coordinates, so 
                        new Quaternion().setFromAxisAngle(new Vector3(1,0,0),this.pitchRadians), 
                        new Quaternion().setFromAxisAngle(new Vector3(0,1,0),Math.PI)
                    ); 

                    this.frontRafter = new _3dRafterTapered(CORE.preferences.des_levelOfDetail.value, this.group, frontBase, frontRot, frontLength, this.pitchRadians, false, rafterCollisionZones, this.bpFrameLine, this.roofFrameType === CORE.roof.frameTypes.bypass, this.beamColor);
                    layerHelper.enableLayer(this.frontRafter.group, CORE.layers.quote);

                    let backBase = new Vector3(0, this.bpFrameLine.yPosFrontColumnAndRafter, this.getRafterTaperedBasePosZ(CORE.sides.backSide, this.bpFrameLine.materialDim));
                    let backPeak = new Vector3(0, this.bpFrameLine.yPosColumnAndRafterPeak, this.getRafterTaperedPeakPosZ(this.bpFrameLine.materialDim));
                    let backLength = backBase.distanceTo(backPeak);
                    backLength -= centerBackoff; // this allows the two beams not to actually quite touch, peak bolt plates are flush for pitches 0/12 to 6/12 (// not perfect, but very close)

                    let backRot = new Quaternion().multiplyQuaternions(
                        // back rafter is the standard, and quaternions are in world coordinates, so 
                        new Quaternion().setFromAxisAngle(new Vector3(1,0,0),-this.pitchRadians), 
                        new Quaternion().setFromAxisAngle(new Vector3(0,1,0),0)
                    ); 
                    this.backRafter = new _3dRafterTapered(CORE.preferences.des_levelOfDetail.value, this.group, backBase, backRot, backLength, this.pitchRadians, false, rafterCollisionZones, this.bpFrameLine, this.roofFrameType === CORE.roof.frameTypes.bypass, this.beamColor);
                    layerHelper.enableLayer(this.backRafter.group, CORE.layers.quote);
                }
                else{
                    let frontBase = new Vector3(0, this.bpFrameLine.yPosFrontColumnAndRafter, this.getRafterTaperedBasePosZ(CORE.sides.frontSide, this.bpFrameLine.materialDim));
                    let frontPeak = new Vector3(0, this.bpFrameLine.yPosColumnAndRafterPeak, this.getRafterTaperedPeakPosZ(this.bpFrameLine.materialDim));
                    let slopeLength = frontBase.distanceTo(frontPeak);

                    let frontRot = new Quaternion().multiplyQuaternions(
                        // back rafter is the standard, and quaternions are in world coordinates, so 
                        new Quaternion().setFromAxisAngle(new Vector3(1,0,0),this.pitchRadians), 
                        new Quaternion().setFromAxisAngle(new Vector3(0,1,0),Math.PI)
                    ); 

                    this.frontRafter = new _3dRafterTapered(CORE.preferences.des_levelOfDetail.value, this.group, frontBase, frontRot, slopeLength, this.pitchRadians, true, rafterCollisionZones, this.bpFrameLine, this.roofFrameType === CORE.roof.frameTypes.bypass, this.beamColor);
                    layerHelper.enableLayer(this.frontRafter.group, CORE.layers.quote);
                    
                }
            }
        }
        else
        {
            if(this.roofType === CORE.roof.types.gable){
                let xF = this.getRafterCPosX(0, this.frameType, this.bpFrameLine.materialDim, rafterCOpenEnd);
                let frontPeak = new Vector3(xF, this.bpFrameLine.yPosColumnAndRafterPeak, this.getRafterCPeakPosZ(false)); 
                let frontBase = new Vector3(xF, this.bpFrameLine.yPosFrontColumnAndRafter, this.getRafterCBasePosZ(CORE.sides.frontSide)); 
                let length = frontBase.distanceTo(frontPeak);
                let frontRot = new Quaternion().multiplyQuaternions(
                    // back rafter is the standard, and quaternions are in world coordinates, so 
                    new Quaternion().setFromAxisAngle(new Vector3(1,0,0),this.pitchRadians), 
                    new Quaternion().setFromAxisAngle(new Vector3(0,1,0),Math.PI)
                );
                let depthFlag = this.getRafterCDepthFlag(rafterCOpenEnd, CORE.sides.frontSide);
                this.frontRafter = new _3dRafterC(CORE.preferences.des_levelOfDetail.value, this.group, frontBase, frontRot, length, this.pitchRadians, depthFlag, rafterCollisionZones, this.bpFrameLine, this.purlinColor);
                layerHelper.enableLayer(this.frontRafter.group, CORE.layers.quote);

                let xB = this.getRafterCPosX(0, this.frameType, this.bpFrameLine.materialDim, rafterCOpenEnd);
                let backPeak = new Vector3(xB, this.bpFrameLine.yPosColumnAndRafterPeak, this.getRafterCPeakPosZ(false)); 
                let backBase = new Vector3(xB, this.bpFrameLine.yPosBackColumnAndRafter, this.getRafterCBasePosZ(CORE.sides.backSide)); 
                length = backBase.distanceTo(backPeak);
                let backRot = new Quaternion().setFromAxisAngle(new Vector3(1,0,0),-this.pitchRadians);
                depthFlag = this.getRafterCDepthFlag(rafterCOpenEnd, CORE.sides.backSide);
                this.backRafter = new _3dRafterC(CORE.preferences.des_levelOfDetail.value, this.group, backBase, backRot, length, this.pitchRadians, depthFlag, rafterCollisionZones, this.bpFrameLine, this.purlinColor);
                layerHelper.enableLayer(this.backRafter.group, CORE.layers.quote);
            }
            else{
                let xF = this.getRafterCPosX(0, this.frameType, this.bpFrameLine.materialDim, rafterCOpenEnd);
                let frontPeak = new Vector3(xF, this.bpFrameLine.yPosColumnAndRafterPeak, this.getRafterCPeakPosZ(false)); 
                let frontBase = new Vector3(xF,this. bpFrameLine.yPosFrontColumnAndRafter, this.getRafterCBasePosZ(CORE.sides.frontSide)); 
                let length = frontBase.distanceTo(frontPeak);

                let frontRot = new Quaternion().multiplyQuaternions(
                    // back rafter is the standard, and quaternions are in world coordinates, so 
                    new Quaternion().setFromAxisAngle(new Vector3(1,0,0),this.pitchRadians), 
                    new Quaternion().setFromAxisAngle(new Vector3(0,1,0),Math.PI)
                );
                let depthFlag = this.getRafterCDepthFlag(rafterCOpenEnd, CORE.sides.frontSide);
                this.frontRafter = new _3dRafterC(CORE.preferences.des_levelOfDetail.value, this.group, frontBase, frontRot, length, this.pitchRadians, depthFlag, rafterCollisionZones, this.bpFrameLine, this.purlinColor);
                layerHelper.enableLayer(this.frontRafter.group, CORE.layers.quote);
            }
                
        }
    }

    getRafterCDepthFlag(openEnd, side){
        if(side === CORE.sides.frontSide){
            if(openEnd === CORE.sides.leftEnd)
                return -1;
            else if(openEnd === CORE.sides.rightEnd)
                return 1;
            throw "invalid getRafterCDepth openEnd argument"
        }
        else if(side === CORE.sides.backSide){

            if(openEnd === CORE.sides.leftEnd)
                return 1
            else if(openEnd === CORE.sides.rightEnd)
                return -1;
            throw "invalid getRafterCDepth openEnd argument"
        }
        throw "invalid getRafterCDepth side argument"
    }

    getRafterCPosX(bottomX, frameType, materialDim, openEnd){

        let flange = materialDim.flange;
        let halfFlange = flange/2;
        let depth = materialDim.depth;
        let halfDepth = depth/2;

        if(frameType!==CORE.frame.types.bolt)
            return bottomX;

        let offset = halfFlange+halfDepth; // flange from the rafter, depth from the columns
        switch(openEnd){
            case CORE.sides.leftEnd:
                return bottomX - offset;
            case CORE.sides.rightEnd:
                return bottomX + offset;
            default:
                throw 'invalid unexpected openEnd for rafterC';
        }
    }


    buildColumns(
        minHeight,
        buildEndColumns,
        buildIntermediateColumns,
        columnCollisionZones,
        middleColumnCollisionZones,
        frameLineType,
        frameLine){
        

         let centerSpacing=this.length;
         let nonCenterSpacing=0;
        if(this.baySpacing)
        {
            centerSpacing = this.baySpacing.center;
            nonCenterSpacing = this.baySpacing.nonCenter;
        }
        else
        {
            console.log('no bay spacing')
        }

        let columnPoints =BlueprintHelper.generateColumnPositions2(-this.length/2, this.length/2, centerSpacing, nonCenterSpacing, frameLine.materialDim.flange); //TODO: these spacing and halfColumnWidth parameters need to be context sensitive
        this.midColumns = [];
        let collisionZones;
        for(var ci =0;ci<columnPoints.length;ci++)
        {
            let intermediateColumn = ci>0 && ci < columnPoints.length-1;
            if( (!intermediateColumn && buildEndColumns === false) || 
                (intermediateColumn && buildIntermediateColumns===false))
                // on a wall line, the wall needs to build its own dynamic columns are wall sub-components (windows, doors, etc.)
                continue;

            //
            collisionZones = ((ci === 0 || ci===columnPoints.length-1) && columnCollisionZones) ||
                                ((ci>0 && ci < columnPoints.length-1) && middleColumnCollisionZones);

            let columnPosZ= columnPoints[ci] - this.length/2;
            // x coordinate is not changing as this is a frameline
            // y coordinate is calculated from z coordinate
            // z coordinate is c
            
            let bottomPos = new Vector3(0, 0, columnPosZ);
            let y= BlueprintHelper.getColumnHeightAlongFrameLine(columnPosZ, this.length, minHeight, this.pitchRatio, this.roofType);
            let topPos = new Vector3(0, y, columnPosZ);

            // intermediate columns can open toward front or back
            let openTowardSide = CORE.sides.backSide;
            if(columnPosZ>0)
                openTowardSide = CORE.sides.frontSide;
            
                let rot = 0;
                let mid;
            if(frameLineType === CORE.frame.lineTypes.postAndBeam || 
                this.frameType === CORE.frame.types.weld || 
                this.frameType ===CORE.frame.types.weldPlus){
                let end = openTowardSide    
                if(intermediateColumn){
                    if(this.intermediateColumnsOpenTo === CORE.sides.rightEnd)
                    {
                        end = CORE.sides.rightEnd; // intermediate columns on left-most frame-line open to the right
                        bottomPos.x-=2;
                    }
                    else{
                        end = CORE.sides.leftEnd; // intermediate columns on right-most frame-line open to the left
                        bottomPos.x+=2;
                    }
                }
                
                // calculate column position
                // calculate column rotation
                    let xp = 4;
                    let xn = 12;
                    let zp = 0;
                    let zn = 0;

                switch(end)
                {
                    case CORE.sides.backSide:
                        rot =  Math.PI;
                        xn = 12;
                        xp = 4;
                        zn = 12;
                        zp=4; // visible through the back wall
                        break;
                    case CORE.sides.leftEnd:
                        rot = Math.PI/2;
                        break;
                    case CORE.sides.rightEnd:
                        rot = -Math.PI/2;
                        break;
                    case CORE.sides.frontSide:                        
                        xp = 12;
                        xn = 4;
                        zn = 12;
                        zp = 4 // visible through the front wall
                        break;                        
                }

                let length = topPos.y-bottomPos.y;
                let collisionZoneDetails = collisionZones;
                if(collisionZoneDetails){

                    let margin = CollisionHelper.getMargin(xp,xn,0,0,zp,zn);

                    collisionZoneDetails = {
                        dim: new Vector3(frameLine.materialDim.flange, length, frameLine.materialDim.depth),
                        margin,
                        position: new Vector3(0,length/2,0)
                    }
                }
                
                mid = new _3dColumnStraight(CORE.preferences.des_levelOfDetail.value, length, true, collisionZoneDetails, frameLine.materialDim, frameLine.rafterDimYAtColumn, this.pitchRadians, this.frameType === CORE.frame.types.bolt, this.beamColor)
                
                mid.group.position.copy(bottomPos.clone())
                mid.group.rotation.y =rot;
                this.group.add(mid.group);
                layerHelper.enableLayer(mid.group, CORE.layers.quote);
                this.midColumns.push(mid);
                this.width = frameLine.materialDim.flange; // for wall subcomponent distance markings
            }
            else{
                let length = topPos.y-bottomPos.y;
                
                let xp = 0;
                let xn = 0;
                let zp = 0;
                let zn = 0;
                // it's built to open toward the right end
                switch(openTowardSide)
                {
                    case CORE.sides.frontSide:
                        rot = Math.PI/2;
                        if(this.leftEndWallFrameline){
                            zp = 0; // front-left corner world X+
                            zn = 2; // front-left corner world X-
                        }
                        if(this.rightEndWallFrameline){
                            zn = 0; // front-right corner world X-
                            zp = 2; // front-right corner world X+
                        }
                        break;
                    case CORE.sides.backSide:
                        rot = -Math.PI/2;
                        if(this.leftEndWallFrameline)
                        {
                            zn = 0; // back-left corner world X+
                            zp = 2; // back-left cornerworld  X-
                        }
                        if(this.rightEndWallFrameline)
                        {
                            zp = 0; // back-right corner world X-
                            zn = 2; // back-right corner world X+
                        }
                        break;
                    case CORE.sides.leftEnd:
                        rot = Math.PI;
                        break;
                    case CORE.sides.rightEnd:
                        break;
                }
                

                if(collisionZones){

                    if(this.leftEndWallFrameline){
                        xp = 0; // left end interior depth in the Z axis
                        xn = 4;  // left end exterior depth in the Z axis
                    }
                    if(this.rightEndWallFrameline){
                        xp = 0; 
                        xn = 4;
                    }

                    let margin = CollisionHelper.getMargin(xp,xn,0,0,zp,zn);

                    collisionZones = {
                        dim: new Vector3(frameLine.materialDim.flange, length, frameLine.materialDim.depth),
                        margin,
                        position: new Vector3(0,length/2,0)
                    }
                }
                
                mid = new _3dColumnC(CORE.preferences.des_levelOfDetail.value, length, collisionZones, frameLine.materialDim, this.purlinColor);
                mid.group.position.copy(bottomPos.clone())
                mid.group.rotation.y = rot;
                this.group.add(mid.group);
                layerHelper.enableLayer(mid.group, CORE.layers.quote);
                this.midColumns.push(mid);
                this.width = frameLine.materialDim.depth; // for wall subcomponent distance markings
            }                
            this.columnPositions.push(bottomPos.z)
        };
    }
    
    build(){
        
    }

    remove(){
        if(this.midColumns)
        this.midColumns.forEach((c)=>{
            c.remove();
        });
        
        if(this.frontColumn)
            this.frontColumn.remove();
        
        if(this.backColumn)
            this.backColumn.remove();
        
        if(this.frontRafter){
            this.frontRafter.remove();
        }

        if(this.backRafter){
            this.backRafter.remove();  
        }
            
    }
}
