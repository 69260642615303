import * as THREE from 'three'
import {CORE} from '../_spec.js'
import vHelper from './vHelper.js';
import EarcutDataManager from './EarcutDataManager.js';
import SheetingHelper from './SheetingHelper.js';
import Sheeting from '../3d/Sheeting.js';
import materialHelper from './materialHelper.js';
export default class FoldedGeometryHelper {
//reference material:
//https://www.jagmetalsllc.com/wp-content/uploads/2018/04/rake-trim_catalogfinal.png

    constructor(folds, material, leftEnd, rightEnd, backSide, length,
        leftVertAngle=0, 
        rightVertAngle=0, 
        leftHoriAngle = Math.PI/4, 
        rightHoriAngle = Math.PI/4
        ){
            
        this.halfLength =length/2;
        this.pos = new THREE.Vector3();
        this.deg = 0;
        this.leftHoriAngle = leftHoriAngle;
        this.rightHoriAngle = rightHoriAngle;
        this.leftVertAngle = leftVertAngle;
        this.rightVertAngle = rightVertAngle;
        this.geometries = [];
        this.leftEndPoints = [];
        this.rightEndPoints = [];

        this.processFolds(folds);
        this.group = new THREE.Group();
        if(leftEnd)
        {
            //let geoLeftEndCap = this.getGeometryFromPointsX(this.leftEndPoints, this.endOriginIndex)
            
            let leftCap = new EarcutDataManager(2);

            let points = [];
            this.leftEndPoints.forEach((lep)=>{
                points.push(lep.z);
                points.push(lep.y);
            });

            leftCap.setOutline(points)
            let data =leftCap.generate();
                
            let leftSheet = new Sheeting(
            'low',
            data, 
            SheetingHelper.defineFrontPlane(material),
            SheetingHelper.defineBackPlane(material),
            );
            
            this.group.add(leftSheet.group);
            leftSheet.group.rotation.y = -Math.PI/2
            leftSheet.group.position.x = -this.halfLength;
        }
        if(rightEnd){       

            let rightCap = new EarcutDataManager(2);

            let points = [];
            this.rightEndPoints.forEach((lep)=>{
                points.push(lep.z);
                points.push(lep.y);
            });

            rightCap.setOutline(points)
            let data =rightCap.generate();
                
            let rightSheet = new Sheeting(
            'low',
            data, 
            SheetingHelper.defineFrontPlane(material),
            SheetingHelper.defineBackPlane(material),
            );            
            this.group.add(rightSheet.group);
            rightSheet.group.rotation.y = -Math.PI/2
            rightSheet.group.position.x = +this.halfLength;
        }
        if(backSide){
            /*
            This appears to no longer produce a valid geometry
            let points = [
                this.leftEndPoints[0],            
                this.leftEndPoints[this.leftEndPoints.length-1],
                this.rightEndPoints[this.rightEndPoints.length-1],
                this.rightEndPoints[0],
            ];
            let geoBack = this.getGeometryFromPointsX(points)
            geoBack.setIndex(0,2,3,1);
            this.geometries.push(geoBack);
            */
        }

        
        this.processGeometries(material);
    }
    
    processGeometries(material){
        this.geometries.forEach((g)=>{
            let mesh = new THREE.Mesh(g, material);
            this.group.add(mesh)
        });
    }

    processFolds(folds){
        folds.forEach((s)=>{
            if(s.hasOwnProperty('mdist'))
                this.processDist(s, false);
            if(s.hasOwnProperty('dist'))
                this.processDist(s);
            if(s.hasOwnProperty('deg'))
                this.deg -= s.deg * Math.PI/180;
            if(s.hasOwnProperty('markEndOrigin'))
                this.endOriginIndex = this.leftEndPoints.length-1;
        })
    }

    processDist(s, addGeo=true){


        let val = s.dist || s.mdist;
        
        // use this.deg to calculate a new z and y.
        // x doesn't change.

        let oy = this.pos.y;
        let oz = this.pos.z;

        let ny = this.pos.y + (val * Math.sin(this.deg));
        let nz = this.pos.z + (val * Math.cos(this.deg));

        let olExtH = oz*Math.tan(this.leftHoriAngle);
        let orExtH = oz*Math.tan(this.rightHoriAngle);
        let olExtV = oy*Math.tan(this.leftVertAngle);
        let orExtV = oy*Math.tan(this.rightVertAngle);
        let olx = this.pos.x-this.halfLength-olExtH-olExtV;
        let orx = this.pos.x+this.halfLength+orExtH+orExtV;

        let nlExtH = nz*Math.tan(this.leftHoriAngle); // new left extension due to horizontal angle (45 degree cut)
        let nrExtH = nz*Math.tan(this.rightHoriAngle); // new right extension due to horizontal angle (45 degree cut)
        let nlExtV = ny*Math.tan(this.leftVertAngle); // new left extension due to vertical angle
        let nrExtV = ny*Math.tan(this.rightVertAngle); // new right extension due to vertical angle
        
        let nlx = this.pos.x-this.halfLength-nlExtH-nlExtV; // factor in extensions required for horizontal and vertical cuts
        let nrx = this.pos.x+this.halfLength+nrExtH+nrExtV;

        // update current position
        this.pos.set(this.pos.x, ny, nz);

        if(!addGeo)
            return;

        let ap1 = new THREE.Vector3( olx,  oy, oz );
        let ap2 = new THREE.Vector3( orx, oy, oz );
        let ap3 = new THREE.Vector3( nrx, ny, nz );
        let points = [
            ap1,            
            ap2,
            ap3
        ];

        let geoA = this.getGeometryFromPointsX(points)
        geoA.setIndex([0,2,1]);
        this.geometries.push(geoA);

        let bp1 = new THREE.Vector3( olx,  oy, oz )                      
        let bp2 = new THREE.Vector3( nrx, ny, nz )
        let bp3 = new THREE.Vector3( nlx, ny, nz )
        points = [
            bp1,
            bp2,
            bp3
        ];
        let geoB = this.getGeometryFromPointsX(points)
        geoB.setIndex([0,2,1]);
        this.geometries.push(geoB);

        if(this.leftEndPoints.length===0)
            this.leftEndPoints.push(ap1);
        this.leftEndPoints.push(bp3);

        if(this.rightEndPoints.length===0)
            this.rightEndPoints.push(ap2);
        this.rightEndPoints.push(ap3);
        
    }

    getGeometryFromPoints(points){
        const geometry = new THREE.BufferGeometry();
        let verts = vHelper.getFloat32ArrayFromPoints(points);
        geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( verts, 3 ) );

        let normals = [verts.length];
        for(let i=0; i<points.length; i+=3){
            var p = new THREE.Plane();
            p.setFromCoplanarPoints(points[i],points[i+1],points[i+2]);
            normals.push(p.normal.x, p.normal.y, p.normal.z);
            normals.push(p.normal.x, p.normal.y, p.normal.z);
            normals.push(p.normal.x, p.normal.y, p.normal.z);
        }
        
        geometry.setAttribute( 'normal', new THREE.Float32BufferAttribute( normals, 3 ) );
        //geometry.vertices = points;        
        //geometry.faces.push( new THREE.Face3( 0, 1, 2 ) );
        //geometry.computeFaceNormals();
        //geometry.computeVertexNormals();
        return geometry;
    }

    getGeometryFromPointsX(points, originIndex = 0){
        const geometry = new THREE.BufferGeometry();        
        let verts = vHelper.getFloat32ArrayFromPoints(points);
        geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( verts, 3 ) );
        
        let normals = [];
        // this iterates through strip of triangles
        // i+=2 because there are two new points between each set of triangles
        for(let i=0; i<points.length; i+=2){
            
            try{
                // first triangle is points i+0,i+2,i+1 (to achieve CCW)
                let first = new THREE.Triangle(points[i],points[i+2],points[i+1])
                let firstNormal = new THREE.Vector3();                
                first.getNormal(firstNormal);
                normals.push(firstNormal.x, firstNormal.y, firstNormal.z);
                normals.push(firstNormal.x, firstNormal.y, firstNormal.z);
                normals.push(firstNormal.x, firstNormal.y, firstNormal.z);

                // second triangle is points i+0,i+3,i+2 (to achieve CCW)
                let second = new THREE.Triangle(points[i],points[i+3],points[i+2])                
                let secondNormal = new THREE.Vector3();                
                second.getNormal(secondNormal);
                normals.push(secondNormal.x, secondNormal.y, secondNormal.z);
                normals.push(secondNormal.x, secondNormal.y, secondNormal.z);
                normals.push(secondNormal.x, secondNormal.y, secondNormal.z);
            }
            catch(e){
                console.log(e);
            }            
        }
        geometry.setAttribute( 'normal', new THREE.Float32BufferAttribute( normals, 3 ) );
        
        return geometry;
    }


    
}
