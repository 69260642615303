
import Base from './Base'
import OptionHelper from '../helpers/optionHelper.js'
import ColorHelper from '../helpers/colorHelper.js'
import {CORE,impactTypes, buildingCodes } from '../_spec.js';
import Util from '../utility.js'

export default class JobSpecs extends Base{
    constructor(design){

       
        super(design);
    }

    migrate(design)
    {
        // is this necessary? maybe from the parent (building)
        
    }


    defaultDesign(){//*
        return {
            type: CORE.components.jobspecs,
            buildingCode: buildingCodes.ibc15,
            seals: true
        }
    }
    
    static canEdit(){return true;}//*
    static canDelete(){return false;}
    static canCopy(){return false;}

    getOutlineMeshes(){        
        return null;
    }
    getDescription (){
        return `Job Specs.`
    }
    
    getOptionsSpec(){ //*
    
        let opts = [
            
            OptionHelper.header('Job Specs'),
            // tax 
            // ibc 
            OptionHelper.selection("buildingCode","Code", impactTypes.none, undefined, [
                {
                    value: buildingCodes.ibc12,
                    text:`IBC 2012`
                },
                {
                    value: buildingCodes.ibc15,
                    text:`IBC 2015`
                },
                {
                    value: buildingCodes.ibc18,
                    text:`IBC 2018`
                },
                {
                    value: buildingCodes.ibc21,
                    text:`IBC 2021`
                }
            ],
            (v) => {
                // if frameType is Bolt Up, option enabled
                let system = this.masterDesign.design.getSystem();
                let struct = system.getStructure();
                let frame = struct.getFrame();
                return (frame.frameType === CORE.frame.types.bolt);
            },
            (v)=>{//fnIn
                let system = this.masterDesign.design.getSystem();
                let struct = system.getStructure();
                 let frame = struct.getFrame();
                return (frame.frameType === CORE.frame.types.bolt) ? v : this.defaultDesign().buildingCode;
            },
            undefined,//fnOut
            (v) => {
                this.design.buildingCode = v;
            }, //fnChange
            ),
            // seals
            OptionHelper.checkbox("seals", "Engineering Seals", impactTypes.none, 
            () => {
                // if frameType is Bolt Up, option enabled
                let system = this.masterDesign.design.getSystem();
                let struct = system.getStructure();
                 let frame = struct.getFrame();
                return (frame.frameType === CORE.frame.types.bolt);
            },
            (v) => {//fnIn
                // if frameType is Bolt Up, option enabled
                let system = this.masterDesign.design.getSystem();
                let struct = system.getStructure();
                let frame = struct.getFrame();
                return (frame.frameType === CORE.frame.types.bolt) ? v : false;
            }, 
            undefined, //fnOut
            (v)=>{//fnChange
                this.design.seals = v;
            }),
        ];
        return opts;
    }

    build(){        
    }
    
    remove(){
    }
    
    getCollisionClass(){
        return [CORE.collisions.classes.none];
    }

    getTypeDisplayName(){ //*
        return "Job Specs."
    }
}