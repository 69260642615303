
import * as THREE  from 'three'
import _3dBase from './Base.js'
import _3dTrimOpeningFace from './TrimOpeningFace.js'
import { CORE } from '../_spec.js'
import layerHelper from '../helpers/layerHelper.js'
import { Vector3 } from 'three'
import materialHelper from '../helpers/materialHelper.js'
import TrimOpeningFace from './TrimOpeningFace.js'

export default class WindowBase extends _3dBase{
    constructor(lod, parentGroup, dim, trimMaterial){
        super(parentGroup, undefined, undefined, lod);

        let dimTrimMaterial = new THREE.Vector3(2,2,1.5);
        let trimSize = dim.clone().add(new Vector3(.1,.1,.1))
        this.trimMaterial = trimMaterial;
        let trim = new _3dTrimOpeningFace(this.lod, trimSize, dimTrimMaterial, trimMaterial);
        layerHelper.enableLayer(trim.group, CORE.layers.quote);
        trim.group.position.z+=.15;
        this.group.add(trim.group);
    }

    setWindowGroup(group){
        layerHelper.setGroup(group, CORE.layers.windows, true);
    }

    buildSash(dimSash, dimMaterial){
        if(this.isHighDetail())
            return this.buildSash_HD(dimSash, dimMaterial);
        else
            return this.buildSash_LD(dimSash, dimMaterial);
    }

    
    buildSash_LD(dimSash, dimMaterial){
        // dimSash is the OUTSIDE dimensions of the sash
        
        let dimGlass = new THREE.Vector3(dimSash.x-dimMaterial.x*2, dimSash.y-dimMaterial.y*2, dimMaterial.z-.25) // .25 is subtracted so that sash material always contains the glass pane 
        let halfDimSize = dimSash.clone().multiplyScalar(.5);
        let halfDimMaterial = dimMaterial.clone().multiplyScalar(.5);
        let gSash = new THREE.Group();

        let whitePlastic = materialHelper.getPlasticMaterial_LD(0xFFFFFF);
        let sash = new _3dTrimOpeningFace(this.lod, dimGlass, dimMaterial, whitePlastic);
        layerHelper.enableLayer(sash.group, CORE.layers.quote);
        gSash.add(sash.group);


        this.group.add(gSash);
        return gSash;
    }

    buildSash_HD(dimSash, dimMaterial){
        // dimSash is the OUTSIDE dimensions of the sash
        
        let dimGlass = new THREE.Vector3(dimSash.x-dimMaterial.x*2, dimSash.y-dimMaterial.y*2, dimMaterial.z-.25) // .25 is subtracted so that sash material always contains the glass pane 
        let halfDimSize = dimSash.clone().multiplyScalar(.5);
        let halfDimMaterial = dimMaterial.clone().multiplyScalar(.5);
        let gSash = new THREE.Group();

        // Glass
        var geoGlass = new THREE.BoxGeometry(dimGlass.x, dimGlass.y, dimGlass.z);
        
        let matGlass = materialHelper.getGlassMaterial_HD();
        

        let meshGlass = new THREE.Mesh(geoGlass, matGlass);
        meshGlass.renderOrder=1;
        meshGlass.name = 'window glass'
        meshGlass.castShadow=false;
        gSash.add(meshGlass);
        
        let matSash = new THREE.MeshPhongMaterial({ 
            color: 0xAAAAAA, 
            side: THREE.FrontSide,
            shininess: 10,
            specular: new THREE.Color(Number(0xAAAAAA))

        });
        
        matSash =  materialHelper.getPlasticMaterial_HD(0xffffff)
        // Rail
        let geoRail = new THREE.BoxGeometry(dimSash.x, dimMaterial.y, dimMaterial.z);
        geoRail.position = new THREE.Vector3(0, halfDimSize.y, 0);
        let mRailTop = new THREE.Mesh(geoRail, matSash)
        mRailTop.name = 'window rail top'
        mRailTop.position.set(0, halfDimSize.y - halfDimMaterial.y, 0);
        let mRailBottom = new THREE.Mesh(geoRail, matSash)
        mRailBottom.name = 'window rail bottom'
        mRailBottom.position.set(0, -halfDimSize.y + halfDimMaterial.y, 0)
        gSash.add(mRailTop);
        gSash.add(mRailBottom);

        //Stiles 
        this.geoStile = new THREE.BoxGeometry(dimMaterial.x, dimSash.y, dimMaterial.z);
        let mStileLeft = new THREE.Mesh(this.geoStile, matSash)
        mStileLeft.name = 'window stile left'
        mStileLeft.position.set(-halfDimSize.x + halfDimMaterial.x, 0, 0)
        let mStileRight = new THREE.Mesh(this.geoStile, matSash)
        mStileRight.name = 'window stile right'
        mStileRight.position.set(halfDimSize.x - halfDimMaterial.x, 0, 0)                
        gSash.add(mStileLeft);
        gSash.add(mStileRight);

        this.group.add(gSash);
        return gSash;
    }
}