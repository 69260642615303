<template>
    <div class="subPanel">
        
        <!-- <div style="display: block; font-weight: bold; text-align: center; cursor: pointer;"><span style="">Additions</span></div> -->
        
        <div v-if="this.addingComponent">
            Place the {{this.addingComponent}} in the design.
            
            <button v-on:click="cancel" class="btn btn90" style='background:#df0404;' >Cancel</button>

        </div>

        <div v-if="!this.addingComponent">

            <!----<div class="trayItem dropdown" style="margin-left: 0px;" v-on:mouseleave="hideArea('windowSizes')">
                <button v-on:click="addWindow(36,60)" class="btn btn90">Window</button>
            </div>

            <button class="trayItem btn btn90" v-on:click="addEmptyFramedOpening(30,60)">Empty Framed Opening</button>-->

            <div class="trayItem dropdown" style="margin-left: 0px;" v-on:mouseleave="hideArea('framedOpenings')">
                <button v-on:click="toggleArea('framedOpenings')" class="btn btn90" >Framed Openings<font-awesome-icon icon="fa-duotone fa-chevron-down" /></button>
                <div v-if="show.framedOpenings" id="framedOpeningsDropDown" class="dropdown-content">
                <a v-on:click="_addWindow(36,60)">Window</a>
                <a v-on:click="_addEmptyFramedOpening(36,60)">Empty Framed Opening</a>
                <a v-on:click="_addLouverVent(36)">Louvered Vent</a>
                </div>
            </div>

            <div class="trayItem dropdown" style="margin-left: 0px;" v-on:mouseleave="hideArea('doorSizes')">
                <button v-on:click="toggleArea('doorSizes')" class="btn btn90" >Walk Door <font-awesome-icon icon="fa-duotone fa-chevron-down" /></button>
                <div v-if="show.doorSizes" id="walkDoorsDropDown" class="dropdown-content">
                <a v-on:click="_addWalkDoor(3)">3' x 7'</a>
                <a v-on:click="_addWalkDoor(4)">4' x 7'</a>
                <a v-on:click="_addWalkDoor(6)">6' x 7'</a>
                </div>
            </div>

            <div class="trayItem dropdown" style="margin-left: 0px;" v-on:mouseleave="hideArea('doorCommSizes')">
                <button v-on:click="toggleArea('doorCommSizes')" class="btn btn90" >Commercial Door <font-awesome-icon icon="fa-duotone fa-chevron-down" /></button>
                <div v-if="show.doorCommSizes" id="commercialWalkDoorsDropDown" class="dropdown-content">
                <a v-on:click="_addCommGlassDoor(3)">3' x 7'</a>
                <a v-on:click="_addCommGlassDoor(4)">4' x 7'</a>
                <a v-on:click="_addCommGlassDoor(6)">6' x 7'</a>
                </div>
            </div>


            <div class="trayItem dropdown" style="margin-left: 0px;" v-on:mouseleave="hideArea('doorOverheadTypes')">
                <button v-on:click="toggleArea('doorOverheadTypes')" class="btn btn90" >Overhead Doors<font-awesome-icon icon="fa-duotone fa-chevron-down" /></button>
                <div v-if="show.doorOverheadTypes" id="overheadDoorsDropDown" class="dropdown-content">
                <a v-on:click="_addDoorRollup">Rollup</a>
                <a v-on:click="_addDoorSectional">Sectional</a>
                <a v-on:click="_addSliding">Sliding</a>
                <a v-on:click="_addHangarDoor">Hangar</a>
                </div>
            </div>

            <button class="trayItem btn btn90" v-on:click="_addWideOpening">Visual Wide Opening...</button>

            <button class="trayItem btn btn90" v-on:click="_addStructure">New Structure</button>

            <button class="trayItem btn btn90" v-on:click="_addCupola">Cupola</button>

            <!-- <button class="trayItem btn btn90" v-on:click="addModel">Prop</button> -->
        </div>


    </div>
</template>

<script>
import {CORE} from '../js/_spec.js'
import porchControls from './porchControls.vue'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

export default {
    data: ()=>{
        return {
            show:{
                windowSizes: false, // 
                framedOpenings: false,
                doorSizes: false,
                porchSides:false,
                doorCommSizes: false,
                doorOverheadTypes: false,
            },
            CORE
        }
    },
    computed:{
        ...mapState('designModule', [
            'addingComponent',
            ]),       
    },
    methods:{        
        ...mapActions('designModule',[
                'cancelAddingComponent',        
                'addWindow',
                'addEmptyFramedOpening',
                'addLouverVent',
                'addWalkDoor',
                'addCommercialGlassDoor',
                'addDoorRollup',
                'addDoorSectional',
                'addHangarDoor',
                'addSliding',
                'addWideOpening',
                'addStructure',
                'addCupola',
                'addModel',
                '',
            ]),

        cancel(){            
            this.cancelAddingComponent();
        },
        _addWindow(width,height){
            this.hideArea('all')
            this.addWindow({width,height});      
        },        
        _addEmptyFramedOpening(width, height){
            this.hideArea('all')
            this.addEmptyFramedOpening({width,height});
        },
        _addLouverVent(sideLength){
            this.hideArea('all')
            this.addLouverVent(sideLength);
        },
        _addWalkDoor(w){
            this.hideArea('all')
            this.addWalkDoor(w);      
        },
        _addCommGlassDoor(w) {
            this.hideArea('all')
            this.addCommercialGlassDoor(w);
        },
        _addDoorRollup(){
            this.hideArea('all')
            this.addDoorRollup();
        },
        _addDoorSectional(){
            this.hideArea('all')
            this.addDoorSectional();
        },
        _addHangarDoor(){
            this.hideArea('all')
            this.addHangarDoor();
        },
        _addSliding(){
            this.hideArea('all')
            this.addSliding();
        },
        _addWideOpening(){
            this.hideArea('all')
            this.addWideOpening();
        },
        _addStructure(){
            this.hideArea('all')
            this.addStructure();
        },
        _addCupola(){
            this.hideArea('all')
            this.addCupola();
        },
        _addModel(){
            this.hideArea('all')
            this.addModel();
        },
        toggleArea(mode){
            let oldWindowSizeState = this.show.windowSizes;
            let oldframedOpeningsState = this.show.framedOpenings;
            let oldDoorSizesState = this.show.doorSizes;
            let oldDoorCommSizesState = this.show.doorCommSizes;
            let oldDoorOverheadTypesState = this.show.doorOverheadTypes;
            this.hideArea('all');
            switch(mode){
                case 'windowSizes':
                    this.show.windowSizes=!oldWindowSizeState;                    
                    break;
                case 'framedOpenings':
                    this.show.framedOpenings=!oldframedOpeningsState;
                    break;
                case 'doorSizes':                    
                    this.show.doorSizes = !oldDoorSizesState;
                    break;                
                case 'doorCommSizes':                    
                    this.show.doorCommSizes = !oldDoorCommSizesState
                    break;
                case 'doorOverheadTypes':
                    this.show.doorOverheadTypes = !oldDoorOverheadTypesState
                    break;
            }
        },
        hideArea(area){
            switch(area){
                case 'windowSizes':
                    this.show.windowSizes=false;
                    break;
                case 'framedOpenings':
                    this.show.framedOpenings=false;
                    break;
                case 'doorSizes':
                    this.show.doorSizes = false;
                    break;
                case 'doorCommSizes':
                    this.show.doorCommSizes = false;
                    break;
                case 'doorOverheadTypes':
                    this.show.doorOverheadTypes = false;
                    break;
                case 'all':
                    this.show.windowSizes=false;
                    this.show.framedOpenings=false;
                    this.show.doorSizes = false;
                    this.show.doorCommSizes = false;
                    this.show.doorOverheadTypes = false;
                    break;

            }
        }        
    },
    components:{
            porchControls
        }
}
</script>

<style scoped>
.btn90{
  width:90%;
}

</style>