<template>
    <div class="address-layout">
        <transition name="fade" mode="out-in">
            <div v-if="!showNewAddressForm" class="">
                <ul style="list-style-type: none; padding-left:5px;">

                    <li v-for="(addr, index) in value.addresses">
                        <div v-bind:id="`addr-radio-${index}`" class="j-clickable address-li" v-bind:class="{'j-radio-selected': value.selectedAddress == addr.addressKey}">
                            <label>
                                <input type="radio" v-model="value.selectedAddress" name="value.selectedAddress" :value="addr.addressKey">
                                <span v-if="addr.addressKey.toLowerCase() == '485 north east loop 564|75773'" style="font-weight:bold">Pick up at Southwest Metal (CPU)<br></span>
                                <span v-else-if="addr.addressKey.toLowerCase() == '2824 milam road east|76266'" style="font-weight:bold">Pick up at Sanger<br></span>
                                {{addr.friendlyString}}
                            </label>
                        </div>
                    </li>

                </ul>

                <div class="j-2col-fr" style="grid-gap:15px;">
                    <button v-on:click="showNewAddressForm = !showNewAddressForm" type="button" class="btn btn-default">Add New Address</button>
                    <button id="confirm-address-btn" v-on:click="$emit('selected')" type="button" class="btn btn-primary">Use this address</button>
                </div>
            </div>
        </transition>
        <transition name="fade" mode="out-in">

            <div v-if="showNewAddressForm" style="margin-top:10px;">
                <div class="j-2col-full new-address-inputs" style="grid-template-columns: 100px 1fr">
                    <div class="" id="newAddress">Address</div>
                    <div>
                        <input v-model="value.add.address1" class="form-control" type="text" aria-describedby="newAddress">
                    </div>
                </div>
                <div v-if="!value.isShipping" class="j-2col-full new-address-inputs">
                    <div class="" id="newAddress2">Address Line 2</div>
                    <div>
                        <input v-model="value.add.address2" class="form-control" type="text" aria-describedby="newAddress2">
                    </div>
                </div>
                <div v-if="!value.isShipping" class="j-2col-full new-address-inputs">
                    <div class="" id="newAddress3">Address Line 3</div>
                    <div>
                        <input v-model="value.add.address3" class="form-control" type="text" aria-describedby="newAddress3">
                    </div>
                </div>
                <div v-if="!value.isShipping" class="j-2col-full new-address-inputs">
                    <div class="" id="newAddress4">Address Line 4</div>
                    <div>
                        <input v-model="value.add.address4" class="form-control" type="text" aria-describedby="newAddress4">
                    </div>
                </div>
                <div v-if="!value.isShipping" class="j-2col-full new-address-inputs">
                    <div class="" id="newAddress5">Address Line 5</div>
                    <div>
                        <input v-model="value.add.address5" class="form-control" type="text" aria-describedby="newAddress5">
                    </div>
                </div>
                <div class="j-2col-full new-address-inputs">
                    <div class="" id="newCity">City</div>
                    <div>
                        <input v-model="value.add.city" class="form-control" type="text" aria-describedby="newCity">
                    </div>
                </div>
                <div class="j-2col-full new-address-inputs">
                    <div class="" id="dl-state">State:</div>
                    <div>
                        <select v-model="value.add.state" aria-describedby="dl-state" style="width: 100%; height: 30px;">
                            <option v-for="option in value.states" v-bind:value="option.value">
                                {{ option.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="j-2col-full new-address-inputs">
                    <div class="" id="newZip">Postal Code</div>
                    <div>
                        <input v-model="value.add.postalCode" class="form-control" type="text" aria-describedby="newZip">
                    </div>
                </div>

            </div>
        </transition>

        <transition name="fade" mode="out-in">
            <div v-if="showNewAddressForm" class="j-2col-full" style="grid-gap:15px;">
                <button v-on:click="showNewAddressForm = false" type="button" class="btn btn-default">Back</button>
                <button v-on:click="$emit('newaddressadded')" type="button" class="btn btn-primary">Add Address</button>
            </div>
        </transition>
        <div class="swm-box-body2">
            <slot name="content">
            </slot>
        </div>
    </div>
</template>

<script>
    export default
        {
            name: 'swmaddaddress',
            props: ['value'],
            data: function () {
                return {
                    showNewAddressForm: false
                };
            },
        }
</script>