import Vue from 'vue'
import Vuex from 'vuex'
import projectModule from './modules/projectModule'
import businessModule from './modules/businessModule'
import contextModule from './modules/contextModule'
import userModule from './modules/userModule'
import {designModule,v,c,m} from './modules/designModule'
import itemModule from './modules/itemModule'
import pricingModule from './modules/pricingModule'
import createPlugin from 'logrocket-vuex'
import LogRocket from 'logrocket'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
Vue.use(Vuex)

const toastOptions = {
  position: "bottom-center",
  timeout: 3000,
  closeOnClick: false,
  pauseOnFocusLoss: true,
  draggable: false,
  maxToasts: 2,

};
Vue.use(Toast, toastOptions);

export default new Vuex.Store({
  state: {
    project:{}
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    projectModule,
    businessModule,
    contextModule,
    userModule,
    designModule,
    itemModule,
    pricingModule
  },
  plugins:[createPlugin(LogRocket)],
})

export {
v,c,m
}
