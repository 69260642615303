import api from '@/api';

const state = {
};

const mutations = {
};

const actions = {
  async fetchAllBusinesses({ commit }) {
    try {
      console.log('fetchAllBusinesses');
      return await api.getAllBusinesses();
    } catch (error) {
      console.error('Error fetching entities:', error);
      throw error;
    }
  },
  async fetchAllSuppliers({ commit }){
    try {
      console.log('fetchAllSuppliers');
      return await api.getAllSuppliers();
    } catch (error) {
      console.error('Error fetching entities:', error);
      throw error;
    }
  },
  async fetchSubBusinesses({ commit }, businessId) {
    try {
      console.log('fetchSubBusinesses');
      return await api.getSubBusinesses(businessId);
    } catch (error) {
      console.error('Error fetching all companies:', error);
      throw error;
    }
  },
  async fetchContractorsByRep({commit}, sub){
    try {
      console.log('fetchEntitiesByRep');
      return await api.getContractorsByRep(sub);
    } catch (error) {
      console.error('Error fetching entities by rep:', error);
      throw error;
    }
  },
  async fetchBusiness({ commit }, businessId){
    try {
      return await api.getBusiness(businessId);
    } catch (error) {
      console.error('Error fetching entity:', error);
      throw error;
    }
  },
  async addBusiness({ commit }, businessData) {
    try {
      const response = await api.post('/entities', businessData);
      return response.data;
    } catch (error) {
      console.error('Error adding entity:', error);
      throw error;
    }
  },
  async updateBusinessInfo({commit}, business){
    try {
      const response = await api.put(`/business/${business.id}`, business);
      return response.data;
    } catch (error) {
      console.error('Error updating entity:', error);
      throw error;
    }
  },
  async getBusinessSettings({commit}, businessId){
    try{
      const response = await api.get(`/business/getBusinessSettings/${businessId}`)
      return response;
    } catch(error){
      console.error("Error retrieving entity's settings")
      throw error;
    }
  },
  async getBusinessSetting({commit}, settingData){
    let {businessId, settingName} = settingData;
    try{
      const response = await api.get(`/business/getBusinessSetting/${businessId}/${settingName}`)
      return response;
    } catch(error){
      console.error("Error retrieving entity setting")
      throw error;
    }
  },
  async getBusinessTrimProfiles({commit}, business){
    try{
      const response = await api.getTrimProfilesForEntity(business)
      return response;
    } catch(error){
      console.error("Error retrieving entity's settings")
      throw error;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};