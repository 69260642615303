<template>
    <div class="main">

        <div class="logo" style='margin:0px 10px'>
            <img class="img" :src="!branding.logo?'https://swbuildnet.com/customer/images/logobig.png':branding.logo">
        </div>
                
        <div class='menuItem title hidden sm:inline-block'>
            <span style="margin:0px 10px;" class='primary'>{{branding.name}}</span>
            <span style="margin:0px 10px;" class='secondary'>3D Designer </span><span class='beta'>(BETA)</span>
        </div>

        <div class="meta">
            <div style="margin:0px 10px;" class='menuItem name text-right md:text-right'>{{name}}</div>
            <div style="margin:0px 10px;" class='menuItem specs text-right md:text-right'>{{specs}}</div>
            <div style="margin:0px 10px;" class='zipCode text-right'>{{zipCode}}</div>
        </div>  

        <div v-if="shouldShowQuoteLayer && editable" style="align-items:center;margin:0px 10px;">
            <div style="">
              <template v-if="!$store.state.designModule.engRevRequired">
                <QuoteAmount style='text-align:center'/>
              </template>                
              <div v-if="$store.state.designModule.engRevRequired" style="border: 3px dashed red; text-align: center; color: white;">
                Requires Engineering Review
              </div>                
            </div>
            <div style='text-align:center;display:flex;'>
              <p style='font-size:10px'>
                <span style='font-weight:bold'>Note:</span> Quotes do not include windows, overhead doors, and visual wide openings.
              </p>
            </div>
        </div>

        
        <div class='burgerContainer' @click="toggleMenu">
            <div class="burger">
                <div class="line1"></div>
                <div class="line2"></div>
                <div class="line3"></div>
            </div>
        </div>

        <div class="menuItems" v-if="showDropDownMenu">
        
            <div class='menuItem' v-if="editable" style='width: 115px;'>
                <div class='' style=''>
                    Instant Quote
                    <ToggleButton v-model="$store.state.designModule.showQuoting" :sync="true" :font-size="12" @change="updateQuoting" :labels="{ unchecked: 'Off', checked: 'On'}"/>
                </div>
            </div>
            

            <!--<div class='menuItem  ' v-if="editable" style='width: 95px;    margin: 0;     padding: 0;'>
                <div class='btn save' :class="{active: showSaveResult || save.inProgress}" style='width:85px;text-align:center;margin:0;transform: translate(-50%, -50%);    position: absolute;    top: 50%;    left: 50%;' v-if="editable" @click="$emit('save')">
                    <span v-if="!save.inProgress && !showSaveResult">Save</span>
                    <span v-if="save.inProgress" >Saving <img class="loadingSpinner" :src="require('@/assets/images/ajax-spinner.gif')" style='width:20px;display:inline;'/></span>
                    <span v-if="showSaveResult && !save.inProgress && save.success">Success ✔️</span>
                    <span v-if="showSaveResult && !save.inProgress && !save.success">Failed ❌</span>
                </div>
            </div>-->
            
            <div class='menuItem  ' v-if="editable" style=''>
                <div class='btn' style='text-align:center;margin:auto' v-if="!shareMode && !authenticated" @click="$emit('login')">Login</div>
            </div>
            <div class='menuItem  ' v-if="editable" style=''>
                <div class=' btn' style='text-align:center;margin:auto;' v-if="editable" @click="$emit('close')">Exit Designer</div>
            </div>

        </div>
    </div>
</template>

<script>
import {designModes, cameraModes} from '../js/_spec.js'
import { ToggleButton } from 'vue-js-toggle-button' //https://www.npmjs.com/package/vue-js-toggle-button
import { CORE } from '../js/_spec.js';
import QuoteAmount  from './quoteAmount.vue'
import {mapActions, mapMutations, mapGetters, mapState} from 'vuex'
export default {    
    props:[
        'name',
        'specs',
        'mode',
        'branding'        
        ],
    computed:{
        ...mapState('designModule',[
            'editable',
            'shareMode',
            'price',
        ]),
        ...mapGetters('designModule',[
            'showDropDownMenu',
            'shareMode',
            'save',
            'quote',
            'shouldShowQuoteLayer',

        ]),
        authenticated() {
            return this.$store.state.authenticated;
        },
        unsavedChanges(){
            return this.$store.getters.unsavedChanges;
        },
        areCollisions(){
            return this.$store.getters.collisions.length>0;
        },
        collisionCount(){
            return this.$store.getters.collisions.length;
        },
        collisionQuoteText(){
            let count = this.$store.getters.collisions.length;
            let msg = '';
            //return "Fix collisions to quote";
            if(count===1){
                msg = "Fix "+ count+" collision for a quote!";
            }
            else{
                msg = "Fix "+count+" collisions for a quote!";
            }
            return msg;
        },
        guidedDesignMode(){
            return this.$store.getters.designModes === designModes.guided;
        },
        advDesignMode(){
            return this.$store.getters.designModes === designModes.advanced;
        },
        zipCode() {
            return this.$store.getters.zipCode
        },
    },
    data(){
        return {
            showSaveResult: false,         
        }
    },
    methods:{
        ...mapActions('designModule',[
            'updateQuoting',
            'switchToCamera',
        ]),
        ...mapMutations('designModule',[
            'toggleMenu',
            'resolveCollisions',
        ]),
    
        resolveCollisions(){
            this.resolveCollisions(); 
        },
        /*doneSaving(){ // implies nothing about the success or failures of the attempt to save
            console.log('done saving started')
            this.showSaveResult=true;
            setTimeout(()=>{
                this.showSaveResult=false;
            }, 3000)            
        },*/
       
        useFirstPersonCamera(){
            this.switchToCamera(cameraModes.first);
        },
       
    },
    components: {
        ToggleButton,
        QuoteAmount
    }

}
</script>

<style scoped>

.main {    
    background-color: #222;
    border-color: #080808;
    width: 100%;
    height: 70px;
    left:0px;
    top:0px;
    color:white;
    display: flex;
    align-items: center;
}

.meta{
    display:inline-block;
    margin-left:10px;
}

.name {
    font-size: 1em;
}

.burgerContainer{
    cursor: pointer;
    align-items: center;
    margin: 10px;
    justify-self: flex-end;
    position: absolute;
    left: 95%;
    border: 1px solid darkgray;
    border-radius: 6px;
    padding: 7px;
}

.burger div {
    width:  30px;
    height: 2px;
    background-color: #fff;
    margin: 5px;   
}

.specs {
    font-size: .7em;
}

.zipCode {
    font-size: .7em;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-right: 50px;
}

.logo{
    width:150px;
    position:relative;
}

.logo .img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 65px;
}

.menuItems{
    position:absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    right: 1%;
    top: 70px;
    background: #222;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index:50;
}
.menuItem {
    position:relative;
    display: flex;
    flex-wrap: none;
    align-items: center;
    flex-direction: column;
    margin: 10px 15px;
    white-space: nowrap;
}

.menuItem.boxed{
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 3px;
    text-align: center;
}

.modeLabel{
    display: block;
    text-align: center;
}


.menuItem.title{
    overflow: hidden
}

.title .primary{
    font-weight: bold;
    font-style: italic;
    max-width: 95vw;
    font-size: 25px;
    display: block;
}

.title .beta{
    font-weight: bold;
    font-style: italic;
    max-width: 95vw;
    color:coral;
    font-size: 15px;
}

.title .beta{
    font-weight: bold;
    max-width: 95vw;
    font-size: 13px;
}

.btn {
    display:inline-block;
    width:auto;
    height: fit-content;
}

.quote{
  display:block;
}

</style>
