import * as THREE from 'three'

export default class SheetingHelper {

    static definePlane(material, side, invert, offsetX= 0, wireframe=false){
        let m2 = material.clone();
        if(material.map)
        {
            m2.map = material.map.clone(); // this could be a memory leak if this came from a clone OR if this map is then cloned again
            m2.map.offset.x=offsetX;
        }

        if(material.bumpMap){
            m2.bumpMap = material.bumpMap.clone(); // this could be a memory leak if this came from a clone OR if this map is then cloned again
            m2.bumpMap.offset.x=offsetX;
            if(invert)
                m2.bumpScale = -1;
        }

        return {
            material: m2,
            invert,
            side,
            wireframe
        }
    }

    static defineFrontPlane(material, invert=false, offsetX)
    {
        return this.definePlane(material, THREE.FrontSide, invert, offsetX);
    }

    static defineBackPlane(material, invert=false, offsetX)
    {
        return this.definePlane(material, THREE.BackSide, invert,offsetX);
    }
}