import * as THREE from 'three';
import vHelper from '../helpers/vHelper.js'
export default class ShapeHelper{
    // this shape helper was built to take 3D absolute points 
    // I really need a Shape2DHelper and a separate Flatten coordinate function
    // 

    constructor(points, minY, maxY, isSquare){
        this.shapePoints = [];
        this.minY = minY;
        this.maxY = maxY;
        
        // make a copy of each point, and as we go,
        //  bounding the Y values

        points.forEach((p)=> { 
            // bound the Y
            if(minY && p.y < minY)
            p.y = minY;
            if(maxY && p.y > maxY)
            p.y = maxY;
            this.shapePoints.push(new THREE.Vector2(p.x, p.y))
        });

        this.supportProtrusions=isSquare

        if(this.supportProtrusions){
             // it's ok to set and use bl,tl,tr,br in here
            let leftMost, rightMost, topMost, bottomMost;

            this.shapePoints.forEach((p)=> { 
                // figure out corners
                if(leftMost === undefined || p.x < leftMost)
                    leftMost = p.x;
                if(rightMost === undefined || p.x > rightMost)
                    rightMost = p.x;
                if(topMost === undefined || p.y > topMost)
                    topMost = p.y;
                if(bottomMost === undefined || p.y < bottomMost)
                    bottomMost = p.y;   
            });

            // label which points are which corner
            this.shapePoints.forEach((p)=> {
                if(p.x === leftMost)
                {
                    if(p.y === topMost && typeof this.tl=== 'undefined')
                        this.tl = p; // topleft
                    else
                        this.bl = p; //bottomleft         
                }
                else
                {
                    if(p.y === topMost && typeof this.tr=== 'undefined')
                        this.tr = p; //topright
                    else
                        this.br = p; //bottomright                    
                }
            });
            
            this.validatePoint(this.bl, "BL");
            this.validatePoint(this.tl, "TL");
            this.validatePoint(this.tr, "TR");
            this.validatePoint(this.br, "BR");
        }
        
        this.protrusions=[];
        this.holes = [];
    }

    validatePoint(p, name)
    {
        if(!p)
            console.error(`ShapeHelper does not have a ${name} point`)
    }

    getShape(){
        // build a shape with x or z (depending on the direction this frame faces) and y
        let finalPoints;
        if(this.supportProtrusions)
            finalPoints= this.getPointsWithProtrusions();
        else
            finalPoints=this.shapePoints;

        for(var i=0;i<finalPoints.length;i++)
            if(!finalPoints[i]) {
                console.error('shapeHelper has an undefined point.', JSON.stringify(finalPoints));
            }
        
        //close the loop
        finalPoints.push(finalPoints[0])
        
        let shape = new THREE.Shape();
        for(var p=0;p<finalPoints.length;p++)
        {
            let point = finalPoints[p];
            
            if(p===0)
                shape.moveTo(point.x, point.y);
            else{
                shape.lineTo(point.x, point.y);
            }
                
        }
        this.holes.forEach((h)=>{shape.holes.push(h);});

        return shape;
    }

    getPointsWithProtrusions(){
        let finalPoints = [];
        finalPoints.push(this.bl);
        finalPoints.push(this.tl);

        // put the protrusions in order by x ascending
        this.protrusions.sort((a,b)=>{
            let bx = b.getCenter().x;
            let ax = a.getCenter().x;
            return ax - bx;
        })
        // process the protrusions into the finalPoints
        this.protrusions.forEach((psh)=>{
            let bottomPoints = psh.getProtrusionBelow(this.tl.y);
            finalPoints.push(...bottomPoints);
        })
        
        // top line here
        finalPoints.push(this.tr);
        finalPoints.push(this.br);
        //finalPoints.push(this.bl); // 2021.03.10 this closing point appears is added by the caller
        return finalPoints;
    }

    removeShape(shape, isSquare){
        // detects whether the shape 
        if(!this.supportProtrusions){
            this.holes.push(shape);
            return;
        }

        // if any point violates the bounds of the this path, it's not a hole, it's a different path for the main shape.

        // figure out if the shape protrudes or cuts a hole, or has nothing to do with this shape
        //  A protrusion here is only where the entire bottom of a square shape is inside another square shape.
        // figure out the order in which to apply protrusions, based on COM
        // figure out the points that protrude

        let pointsInside = 0;
        let pointsOutside = 0;

        let pointsToRemove = shape.getPoints().slice(0,4);
        pointsToRemove.forEach((p)=>{
            if(pointsInside!=0 && pointsOutside!=0)
                return;
            
            // treat points on the edge as being outside
            if(p.x > this.tl.x && p.x < this.tr.x &&
                p.y> this.bl.y && p.y < this.tl.y)
            pointsInside++;
                else
            pointsOutside++;
        })

        if(pointsInside===0)
            return; // nothing to do

        if(pointsOutside==0)
            this.holes.push(shape);
        else
            this.protrusions.push(new ShapeHelper(pointsToRemove, undefined, undefined, isSquare))
            
    }

    getCenter(){
        let width = this.tr.x - this.tl.x;
        let height = this.tl.y - this.bl.y;
        let posX = this.tl.x+width/2;
        let posY = this.bl.y+height/2;
        return new THREE.Vector2(posX,posY);
    }

    getProtrusionBelow(yEdge){
        let tl = this.tl;
        let tr = this.tr;
        
        // we already know the bottom left and bottom right points are inside, 
        // so just bound the top points down to the yEdge
        if(tl.y>yEdge)
            tl.y = yEdge;
        if(tr.y>yEdge)
            tr.y = yEdge;

        return [
            tl, // bounded
            this.bl, 
            this.br,
            tr // bounded
        ]
    }


    static getMeshFromPoints(points, extrusionSettings, material){
        let shape = new THREE.Shape(points);
		var geo = new THREE.ExtrudeGeometry(shape, extrusionSettings);
        return new THREE.Mesh( geo, material );        
    }
}