import Util from "../utility";
import { CORE } from "../_spec";
import StructureHelper from "../helpers/StructureHelper";
import BlueprintHelper from "../helpers/blueprintHelper";
export default class SystemLayoutAnalyzer{
    constructor(){
         this.structureCoordinates = [];
         this.xMax = -9999999;
         this.xMin = 9999999;
         this.yMax = -9999999;
         this.yMin = 0;
         this.zMax = -9999999;
         this.zMin = 9999999;

    }

    getIndex(index, arrLength){
        let i = ((index % arrLength) + arrLength) % arrLength
        return i;
    }

    interpolateFeelerPoints(structure){
        // Returns three points (roughly 1 foot or less away from the wall)
        // that wrap around the corner of a structure. Their purpose is to 
        // extend beyond the corner and "feel" if it is in the boundary of another building

        //                .           
        //            .  COI  .
        //   COI is always from structureA

        let interpolatedPoints = [];
        structure.corners.forEach((corner, index) =>{
            let cornerFeelers = [];
            let previousCorner = structure.corners[this.getIndex(index-1, structure.corners.length)].coordinate;
            let nextCorner = structure.corners[this.getIndex(index+1, structure.corners.length)].coordinate;
            let feelerLeftLengthFt = (nextCorner.distanceTo(corner.coordinate) / 12);
            let feelerRightLengthFt = (previousCorner.distanceTo(corner.coordinate) / 12);

            let feelerPointOnRightEdge = StructureHelper.getInterpolatedPoint(nextCorner, corner.coordinate, feelerLeftLengthFt - 1, false);
            let feelerPointOnLeftEdge = StructureHelper.getInterpolatedPoint(previousCorner, corner.coordinate, feelerRightLengthFt - 1, false);

            let feelerLeftTemp = StructureHelper.getInterpolatedPoint(nextCorner, corner.coordinate, feelerLeftLengthFt + 1, false);
            let feelerRightTemp = StructureHelper.getInterpolatedPoint(previousCorner, corner.coordinate, feelerRightLengthFt + 1, false);

            let feelerLeft = StructureHelper.getInterpolatedPoint(feelerPointOnLeftEdge, feelerLeftTemp, (feelerPointOnLeftEdge.distanceTo(feelerLeftTemp) / 12)/2, false);
            let feelerRight = StructureHelper.getInterpolatedPoint(feelerPointOnRightEdge, feelerRightTemp, (feelerPointOnRightEdge.distanceTo(feelerRightTemp) / 12)/2, false);
            let feelerDiagonal = StructureHelper.getInterpolatedPoint(feelerLeftTemp, feelerRightTemp, (feelerLeftTemp.distanceTo(feelerRightTemp) / 12)/2, false);

            cornerFeelers.push({position: CORE.interpolatedPointDirections.left, coordinate: feelerLeft});
            cornerFeelers.push({position: CORE.interpolatedPointDirections.right, coordinate: feelerRight});
            cornerFeelers.push({position: CORE.interpolatedPointDirections.diagonal, coordinate: feelerDiagonal});

            interpolatedPoints.push({corner: corner.corner, cornerFeelers})
        })
       
        return interpolatedPoints;
    }

    interpolateSecondaryPoints(structure){
        // Returns four points (roughly 3 feet away from the corner)
        // that create a box around the corner of a structure. Their purpose is to
        // extend beyond the corner, outside of structureB's epsilon to prevent false postives
        // for B mating side

        //                .           
        //            .  COI  . 
        //                . 
        //   COI is always from structureA

        let interpolatedPoints = [];
        structure.corners.forEach((corner, index) =>{
            let cornerFeelers = [];
            let previousCorner = structure.corners[this.getIndex(index-1, structure.corners.length)].coordinate;
            let nextCorner = structure.corners[this.getIndex(index+1, structure.corners.length)].coordinate;
            let feelerLeftLengthFt = (nextCorner.distanceTo(corner.coordinate) / 12);
            let feelerRightLengthFt = (previousCorner.distanceTo(corner.coordinate) / 12);
            

            let insideLeft = StructureHelper.getInterpolatedPoint(previousCorner, corner.coordinate, feelerRightLengthFt - 3);
            let insideRight = StructureHelper.getInterpolatedPoint(nextCorner, corner.coordinate, feelerLeftLengthFt - 3);
            let outsideLeft = StructureHelper.getInterpolatedPoint(nextCorner, corner.coordinate, feelerLeftLengthFt + 3);
            let outsideRight = StructureHelper.getInterpolatedPoint(previousCorner, corner.coordinate, feelerRightLengthFt + 3);

            cornerFeelers.push({position: CORE.interpolatedPointDirections.insideLeft, coordinate: insideLeft});
            cornerFeelers.push({position: CORE.interpolatedPointDirections.insideRight, coordinate: insideRight});
            cornerFeelers.push({position: CORE.interpolatedPointDirections.outsideLeft, coordinate: outsideLeft});
            cornerFeelers.push({position: CORE.interpolatedPointDirections.outsideRight, coordinate: outsideRight});
            
            interpolatedPoints.push({corner: corner.corner, cornerFeelers})
            
        })

        return interpolatedPoints
    }

    getCornerTrimOrientation(hits){
        let isFeelerLeftHit = false;
        let isFeelerRightHit = false;
        let isFeelerDiagonalHit = false;

        for(const hit of hits){
            switch(hit){
                case CORE.interpolatedPointDirections.left:
                    isFeelerLeftHit = true;
                    break;
                case CORE.interpolatedPointDirections.right:
                    isFeelerRightHit = true;
                    break;
                case CORE.interpolatedPointDirections.diagonal:
                    isFeelerDiagonalHit = true;
                    break;
            }
        }

        if(!isFeelerLeftHit && !isFeelerRightHit && !isFeelerDiagonalHit)
            return CORE.cornerTrimOrientations.outside;

        if((isFeelerLeftHit && !isFeelerRightHit && !isFeelerDiagonalHit) || 
        (!isFeelerLeftHit && isFeelerRightHit && !isFeelerDiagonalHit) ||
        (isFeelerLeftHit && isFeelerRightHit && isFeelerDiagonalHit))
            return CORE.cornerTrimOrientations.untrimmed;
    
        if(isFeelerLeftHit && !isFeelerRightHit && isFeelerDiagonalHit)
            return CORE.cornerTrimOrientations.insideRight;

        if(!isFeelerLeftHit && isFeelerRightHit && isFeelerDiagonalHit)
            return CORE.cornerTrimOrientations.insideLeft;

        if(isFeelerLeftHit && isFeelerRightHit && !isFeelerDiagonalHit)
            return CORE.cornerTrimOrientations.insideFlipped;
        
    }

    getStructureBMatingSide(outsideStructureCorners, cornerOfInterest, hitFeeler){
        let epsilon = .01;
        let insideLeft = hitFeeler.cornerFeelers[0].coordinate;
        let insideRight = hitFeeler.cornerFeelers[1].coordinate;
        let outsideLeft = hitFeeler.cornerFeelers[2].coordinate;
        let outsideRight = hitFeeler.cornerFeelers[3].coordinate;


        for(const [index, corner] of outsideStructureCorners.entries()){
            let currentCorner = corner.coordinate;
            let nextCorner = outsideStructureCorners[this.getIndex(index + 1, outsideStructureCorners.length)].coordinate;

            let xMinBoundary = (currentCorner.x < nextCorner.x) ? currentCorner : nextCorner;
            let xMaxBoundary = (currentCorner.x < nextCorner.x) ? nextCorner : currentCorner

            let zMinBoundary = (currentCorner.z < nextCorner.z) ? currentCorner : nextCorner;
            let zMaxBoundary = (currentCorner.z < nextCorner.z) ? nextCorner : currentCorner
            
            if(cornerOfInterest.x >= (xMinBoundary.x - epsilon)  && cornerOfInterest.x <= (xMaxBoundary.x + epsilon) && cornerOfInterest.z >= (zMinBoundary.z - epsilon) && cornerOfInterest.z <= (zMaxBoundary.z + epsilon) ){
                    if( (insideLeft.x >= (xMinBoundary.x - epsilon)  && insideLeft.x <= (xMaxBoundary.x + epsilon) && insideLeft.z >= (zMinBoundary.z - epsilon) && insideLeft.z <= (zMaxBoundary.z + epsilon)) || 
                    (outsideRight.x >= (xMinBoundary.x - epsilon)  && outsideRight.x <= (xMaxBoundary.x + epsilon) && outsideRight.z >= (zMinBoundary.z - epsilon) && outsideRight.z <= (zMaxBoundary.z + epsilon)))
                        return StructureHelper.getSideFromCornerPair(corner.corner, outsideStructureCorners[this.getIndex(index + 1, outsideStructureCorners.length)].corner);
                    else if( (insideRight.x >= (xMinBoundary.x - epsilon)  && insideRight.x <= (xMaxBoundary.x + epsilon) && insideRight.z >= (zMinBoundary.z - epsilon) && insideRight.z <= (zMaxBoundary.z + epsilon)) || 
                    (outsideLeft.x >= (xMinBoundary.x - epsilon)  && outsideLeft.x <= (xMaxBoundary.x + epsilon) && outsideLeft.z >= (zMinBoundary.z - epsilon) && outsideLeft.z <= (zMaxBoundary.z + epsilon)))
                        return StructureHelper.getSideFromCornerPair(corner.corner, outsideStructureCorners[this.getIndex(index + 1, outsideStructureCorners.length)].corner);
            }

                    
                // if(hitFeeler.x >= (xMinBoundary.x - epsilon)  && hitFeeler.x <= (xMaxBoundary.x + epsilon) && hitFeeler.z >= (zMinBoundary.z - epsilon) && hitFeeler.z <= (zMaxBoundary.z + epsilon))
                //         return StructureHelper.getSideFromCornerPair(corner.corner, outsideStructureCorners[this.getIndex(index + 1, outsideStructureCorners.length)].corner);
                
                 
            
        }

    }

    getStructureOfInterestMatingSide(cornerOfInterest, feeler){
        switch(cornerOfInterest){
            case CORE.corners.FL:
                switch(feeler){
                    case CORE.interpolatedPointDirections.left: return CORE.sides.frontSide;
                    case CORE.interpolatedPointDirections.right: return CORE.sides.leftEnd;                        
                    case CORE.interpolatedPointDirections.diagonal: return null;
                    
                }
            case CORE.corners.BL:
                switch(feeler){
                    case CORE.interpolatedPointDirections.left: return CORE.sides.leftEnd;
                    case CORE.interpolatedPointDirections.right: return CORE.sides.backSide;                        
                    case CORE.interpolatedPointDirections.diagonal: return null;
                }
            case CORE.corners.BR:
            switch(feeler){
                case CORE.interpolatedPointDirections.left: return CORE.sides.backSide;
                case CORE.interpolatedPointDirections.right: return CORE.sides.rightEnd;                        
                case CORE.interpolatedPointDirections.diagonal: return null;
            }
            case CORE.corners.FR:
                switch(feeler){
                    case CORE.interpolatedPointDirections.left: return CORE.sides.rightEnd;
                    case CORE.interpolatedPointDirections.right: return CORE.sides.frontSide;                        
                    case CORE.interpolatedPointDirections.diagonal: return null;
                }

        }
    }

    getCornerOfInterestPositionOnBMatingWall(bMatingSideLength, leftCornerB, rightCornerB, cornerOfInterest, roofType){
        // we essentially have coordinates in system space
        // we want to keep the overlap in local space 
        let overlap;
        let middle = bMatingSideLength / 2;

        // find how far along bMatingWall the cornerOfInterest is
        if(leftCornerB.x !== rightCornerB.x)
            overlap = Math.abs(leftCornerB.x - cornerOfInterest.x);
        else
            overlap = Math.abs(leftCornerB.z - cornerOfInterest.z);    
        

        // account for which side of the roof we are on 
        switch(roofType){
            case CORE.roof.types.gable:
                if(overlap > middle) 
                    overlap = bMatingSideLength - overlap;
                break;
            case CORE.roof.types.slope:
                overlap = bMatingSideLength - overlap;
                break;
        }

        return overlap
    }

    getHeightOfBAtCornerOfInterest(bMatingSide, structureB, leftCornerB, rightCornerB, cornerOfInterest){
        let bMatingSideLength = StructureHelper.getLengthOfSide(structureB.design, bMatingSide) * 12;

        switch(bMatingSide){
            case CORE.sides.leftEnd:
            case CORE.sides.rightEnd:
                let startingPoint = this.getCornerOfInterestPositionOnBMatingWall(bMatingSideLength,leftCornerB, rightCornerB, cornerOfInterest, structureB.roofType)
                return (structureB.eaveHeight*12)+(startingPoint * structureB.pitchRatio);
            case CORE.sides.backSide:
                return BlueprintHelper.getBackSideWallHeightInches(structureB.design.getRoof(), structureB.design.getFrame());
            case CORE.sides.frontSide:
                return BlueprintHelper.getStructureHeightInches(structureB.design.getFrame());
        }
    }

    getTrimOrientationsAtCorner(structureOfInterestID, structuresAtCorner){
        // order array by heights descending and start at the structure of interest
        let sortedStructures = Object.keys(structuresAtCorner).sort((a,b) => structuresAtCorner[b].height - structuresAtCorner[a].height)
        let structureOfInterestIndex = sortedStructures.indexOf(structureOfInterestID.toString());

        // We're not concerned with structure's taller than struct of interest since a building is only concerned with its own trim
        let sortedSlicedStructures = sortedStructures.slice(structureOfInterestIndex); 
        let cornerDetails = [];
        for(const [index,structure] of sortedSlicedStructures.entries()){
            let isStructureOfInterest = index === 0;
             // determine trim orientations down the edge of a wall at a specific corner
            let currentStructure = structuresAtCorner[structure];
            let currentHeight = currentStructure.height;
            let currentOrientation = this.getCornerTrimOrientation(currentStructure.hits);

            if(sortedStructures.length === 1){
                // short circuit because the structure of interest is not mated to any other structures
                cornerDetails.push({height: currentHeight, orientation: currentOrientation})
                continue;
            }
            if(isStructureOfInterest){
                // Index is the current structure we looking at in sortedSlicedStructures[]. 
                // structureOfInterestIndex is the index of structureOfInterest in sortedStructures[] indicating how tall it is compared to other structs in the system.
                // sortedSlicedStructures[0] will ALWAYS be the structureOfInterest, so we will always start with the structureOfInterest

                //  tallestBuilding is a specific case
                if(structureOfInterestIndex === 0){
                    // The structure of interest is the tallest out of all structures in the sliced array.
                    if(index+1 < sortedSlicedStructures.length){
                        let nextStructureID = sortedSlicedStructures[index+1];
                        let nextItem = structuresAtCorner[nextStructureID];
                        currentOrientation = (currentHeight === nextItem.height) ? this.getCornerTrimOrientation(nextItem.hits) : currentOrientation;
                        currentHeight = (currentHeight === nextItem.height) ? currentHeight : currentHeight - nextItem.height;
                        cornerDetails.push({height: currentHeight, orientation: currentOrientation})
                    }
                    
                }
                else if(structureOfInterestIndex === 1){
                    // The structure of interest is the second tallest structure in the sliced array.
                    let previousStructureID = sortedStructures[sortedStructures.indexOf(structure)-1];
                    let previousItem = structuresAtCorner[previousStructureID];
                    currentOrientation = this.getCornerTrimOrientation(previousItem.hits);
                    if(index+1 < sortedSlicedStructures.length){
                        let nextStructureID = sortedSlicedStructures[index+1];
                        let nextItem = structuresAtCorner[nextStructureID];
                        currentHeight = (currentHeight === nextItem.height) ? currentHeight : currentHeight - nextItem.height;
                    }
                    cornerDetails.push({height: currentHeight, orientation: currentOrientation})
                }
                //  shortestBuilding is a specific cases 
                else if(structureOfInterestIndex === sortedStructures.length-1){
                    // the structure is the shortest structure in an array of 3 structures or greater
                    // in which case we need the previous two mated buildings to get accurate trim
                    let thirdBeforeLastID = sortedStructures[sortedStructures.indexOf(structure)-2]
                    let thirdBeforeLastItem = structuresAtCorner[thirdBeforeLastID];
                    let previousStructureID = sortedStructures[sortedStructures.indexOf(structure)-1];
                    let previousItem = structuresAtCorner[previousStructureID];
                    let hits = thirdBeforeLastItem.hits
                    hits.push(...previousItem.hits)
                    currentOrientation = this.getCornerTrimOrientation(hits);
                    cornerDetails.push({height: currentHeight, orientation: currentOrientation})
                }
                
            }
            else{
                // we are not looking at the structure of interest
                // if we are not looking at the structure of interest we know:
                // there will always be a taller structutre 
                if(index+1 < sortedSlicedStructures.length){
                    let nextStructureID = sortedSlicedStructures[index+1];
                    let nextItem = structuresAtCorner[nextStructureID];
                    currentHeight = (currentHeight === nextItem.height) ? currentHeight : currentHeight - nextItem.height;
                }

                cornerDetails.push({height: currentHeight, orientation: currentOrientation})
                

                if(sortedStructures.indexOf(structure) === 2 || currentOrientation === CORE.cornerTrimOrientations.untrimmed){
                    break
                }
            }
        }
        // Clean up the corner details array in case there are duplicates
        let filteredCornerDetails = cornerDetails.filter((d, index) => {return index === cornerDetails.findIndex(i => d.height === i.height)})
        return filteredCornerDetails;
    }

    getStructureCornerTrimOrientations(structure, index){
            let allCorners = {};
            //       .                 .           
            //   .  COI  .         .  COI  . 
            //                         . 
            // interpolatedPoints   secondaryInterpolatedPoints
            let interpolatedPoints = this.interpolateFeelerPoints(structure) // left, right, diagonal - the 3 main feeler points to detect if the structure is mated to another structure
            let secondaryInterpolatedPoints = this.interpolateSecondaryPoints(structure) // inside left, inside right, outside left, outside right - 4 secondary feeler points to help detect mating side 
            interpolatedPoints.forEach((ipCorner) => {
                // get a list of all structures in the system excluding the current structure we're analyzng 
                let structureCoordinatesCopy = this.structureCoordinates.slice();
                structureCoordinatesCopy.splice(index, 1);

                let structuresAtCorner = {};

                // Unlike some of the other structures, the structure we're currently analyzing only needs to 
                // to be added once, so we use a flag
                let haveAddedStructureA = false; 
                
                ipCorner.cornerFeelers.forEach((feeler) => {
                     // store the coordinates of corner we are currently analyzing
                     let cornerOfInterest = structure.corners.find(corner => corner.corner === ipCorner.corner)
                     let aHeightAtCornerOfInterest;
                     if(cornerOfInterest.corner === CORE.corners.BL || cornerOfInterest.corner === CORE.corners.BR)
                         aHeightAtCornerOfInterest = BlueprintHelper.getBackSideWallHeightInches(structure.design.getRoof(), structure.design.getFrame())
                     else    
                         aHeightAtCornerOfInterest = BlueprintHelper.getStructureHeightInches(structure.design.getFrame())
                
                    if(!haveAddedStructureA){
                        // We will need to compare the structure of interest height to other building heights to calculate trim height.
                        // It's hits will always be empty since the structure cannot be mated to itself
                        structuresAtCorner[structure.componentID]={height: aHeightAtCornerOfInterest, hits: []}
                        haveAddedStructureA = true;
                    }
                    // short circuit if it's the only building bc we know all corners will be outside trim at full eave height
                    if(structureCoordinatesCopy.length > 0){
                        for(const outsideStructure of structureCoordinatesCopy){
                            if(outsideStructure.design.type === CORE.components.porch)
                                continue
                            // check if corner's edge is right up against another building's wall by
                            // testing if feeler's x and z coordinates are between the boundaries of the other building's x and z min and max (the bottom left corner and top right corner)
                            if(feeler.coordinate.x >= outsideStructure.xMin && feeler.coordinate.x <= outsideStructure.xMax && feeler.coordinate.z >= outsideStructure.zMin && feeler.coordinate.z <= outsideStructure.zMax)
                            {
                                ///////////
                                /// WE DON'T REALLY NEED aMatingSide BUT WE STILL HAVE THE FUNCTIONALITY. IT'S PROBABLY BROKEN CURRENTLY or behind in logic
                                // aMatingSide will help us find the correct corners to test against outsideStructure boundaries to find bMatingSide
                                // let feelerPosition
                                // if(feeler.position === CORE.interpolatedPointDirections.diagonal)
                                //     feelerPosition = structuresAtCorner[outsideStructure.componentID].hits[0]
                                // else
                                //     feelerPosition = feeler.position      
                                // let aMatingSide = this.getStructureOfInterestMatingSide(cornerOfInterest.corner, feelerPosition);
                                // let [leftCorner, rightCorner] = StructureHelper.getLeftAndRightCornerPairFromSide(aMatingSide);
                                // let leftCornerA = structure.corners.find(corner => corner.corner === leftCorner);
                                // let rightCornerA = structure.corners.find(corner => corner.corner === rightCorner);
                                // let point2 = cornerOfInterest.corner === leftCorner ? rightCornerA : leftCornerA;
                                // let newFeelerLength = (cornerOfInterest.coordinate.distanceTo(point2.coordinate) / 12);
                                // let newFeeler = StructureHelper.getInterpolatedPoint(point2.coordinate, cornerOfInterest.coordinate, newFeelerLength - 3);
                                //////

                                // determine which side of the outside struct we just found is mated to structureA
                                let secondaryFeelersAtCorner = secondaryInterpolatedPoints.find(corner => corner.corner === ipCorner.corner)
                                let bMatingSide = this.getStructureBMatingSide(outsideStructure.corners, cornerOfInterest.coordinate, secondaryFeelersAtCorner);
                                if(Util.isUndefined(bMatingSide)){
                                    structuresAtCorner[outsideStructure.componentID]={height: outsideStructure.eaveHeight, hits: [CORE.interpolatedPointDirections.insideLeft, CORE.interpolatedPointDirections.insideRight, CORE.interpolatedPointDirections.diagonal]}
                                    continue;
                                }

                                let [leftCorner, rightCorner] = StructureHelper.getLeftAndRightCornerPairFromSide(bMatingSide);
                                let leftCornerB = outsideStructure.corners.find(corner => corner.corner === leftCorner);
                                let rightCornerB = outsideStructure.corners.find(corner => corner.corner === rightCorner);
                                //let bHeight = (bMatingSide == CORE.sides.backSide) ? BlueprintHelper.getBackSideWallHeightInches(outsideStructure.design.getRoof(), outsideStructure.design.getFrame()) : BlueprintHelper.getStructureHeightInches(outsideStructure.design.getFrame());    
                                let bHeightAtCornerA = this.getHeightOfBAtCornerOfInterest(bMatingSide, outsideStructure, leftCornerB.coordinate, rightCornerB.coordinate, cornerOfInterest.coordinate);
                                if(aHeightAtCornerOfInterest == bHeightAtCornerA || aHeightAtCornerOfInterest < bHeightAtCornerA){
                                    // We need the height of B AT the intersection, 
                                    // so if A is shorter than B (or equal) and A corner is not flush with B corner,
                                    // the intersection height is A. 
                                    bHeightAtCornerA = aHeightAtCornerOfInterest;
                                }

                                // Add the intersectionHeight and hit feeler 
                                if(outsideStructure.componentID in structuresAtCorner){ // We've hit this building once with another feeler
                                    let updatedHits = structuresAtCorner[outsideStructure.componentID].hits;
                                    updatedHits.push(...[feeler.position])
                                    structuresAtCorner[outsideStructure.componentID]={height: bHeightAtCornerA, hits: updatedHits}
                                    break;
                                }
                                else{ // We've never hit this building before
                                    structuresAtCorner[outsideStructure.componentID]={height: bHeightAtCornerA, hits: [feeler.position]}
                                    break;
                                }
                            } 
                        }
                    }
                })
                
                allCorners[ipCorner.corner] = this.getTrimOrientationsAtCorner(structure.componentID, structuresAtCorner);
        })
        
        return allCorners;
    }
    
    getSystemCornerTrimDetails(){
        let structureCornerDetails = {};
        this.structureCoordinates.forEach((structure, index) =>{
            if(structure.design.type !== CORE.components.porch){
                let cornerDetails = this.getStructureCornerTrimOrientations(structure,index)
                structureCornerDetails[structure.componentID] = cornerDetails;
            }
        })
        return structureCornerDetails;
    }
}

//      Everything in this class is highly coupled...

//      1. getSystemCornerTrimDetails() - loops through structures in 1 system
//             - note: all coordinates are unrotated system coordinates
//             - known defects: lean-tos coordinates are incorrect
//             - returns a list of structures within a system and the respective trim heights and orientations down each corner
//      2. getStructureCornerTrimOrientations(structure, index) - loops through each corner of a given structure
//           - interpolates three main feeler points (left, right, diagonal)
//           - collects a list of buildings at each corner with the height at intersection and the feeler that hits them (the structure parameter is also added to the list with its own height and 0 feeler hits)
//               -- StructuresAtCorner e.g. {5:{height: 144, hits: []}, 29:{height: 180, hits: [right, diagonal] }}
//           - a feeler hits another building if its x and z coordinates are between the boundaries of the other building's x and z min and max (the bottom left corner and top right corner)
//           - returns a list of trim heights and orientations for every corner of a single structure
//      3. getTrimOrientationsAtCorner(structureOfInterestID, structuresAtCorner) - loops through array and determines the height of trim and actual trim orientation at a single corner
//           - structure of interest = SOI
//           - order previously collected list of buildings by heights in descending order
//           - slice array so that the SOI (the one handed to getStructureCornerTrimOrientations) is the starting element at [0]
//           - There are 2 main perspectives when looping through structuresAtCorner[]
//               -- 1. perspective from the structure of interest (structureOfInterestID)
//               -- 2. perspective from a structure mated to the structure of interest
//           - returns a list of the differnt trim heights and different trim orientations down a single corner
//      4. getCornerTrimOrientation(hits) - looks at the feeler hits as a whole and returns orientation 


// Known Issues:
/**
 * When adding lean-tos and porches to the system, getSystemCornerTrimDetails is called twice.
 * The first call is triggered by "this.building.rebuildFull()" from the secondary structures' adding methods.
 * The second call is triggered by "this.applyImpactsAndRebuilds(impact)" a few lines below the latter method call. 
 * 
 * During the first call, the secondary structure's position is still in local space. Although it is updated to
 * world space by the second call, adding a left-end lean-to on a front-side lean-to throws an error during the first call. 
 */


