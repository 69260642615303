
import * as THREE from 'three';
import { Vector3, Quaternion, Matrix4, Matrix3, ZeroSlopeEnding } from 'three';
import MatrixHelper from '../helpers/matrixHelper.js';
import MemHelper from '../helpers/memHelper.js';
import cStructureMain from './StructureMain'
import cBase from './Base'
import Null from './Null.js'
import {rebuildTypes, impactTypes, CORE } from '../_spec.js'
import TreeNode from '../helpers/TreeNode.js'

import _3dColumnStraight from '../3d/ColumnStraight'
import Util from '../utility.js';
import StructureBase from './StructureBase';
import StructureHelper from '../helpers/StructureHelper.js';
import ConstraintHelper from '../helpers/ConstraintHelper.js';
import StructureMain from './StructureMain';
import StructurePorch from './StructurePorch.js';
import { faCommentsDollar } from '@fortawesome/pro-duotone-svg-icons';
import CollisionHelper from '../helpers/CollisionHelper.js';
import CompassRose from '../3d/CompassRose.js';
import layerHelper from '../helpers/layerHelper.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import Model from './model.js';
import System from './System.js';

// proper terminology:
// this represents a DESIGN
export default class Building extends cBase{
    constructor( dManager, model){
        super(dManager.design);
        this.masterDesign = dManager;
        this.model = model;
        // build it at the origin       
        //this.onDetectImpact = new CustomEvent('detectImpact', { detail: elem.dataset.time });
        this.build();
        
        this.matCollisionZone = new THREE.MeshLambertMaterial( { color: 0xff0000, opacity:.3, transparent:true, side: THREE.DoubleSide } )
        this.collisionZones = [];        
    }

    getDimensions(){
        
        let sysDims = [];;
        this.components.forEach((system, ci)=> {
            if(system.design.type == CORE.components.null)
            return;
            let dims =this.components[ci].getDimensions();
            sysDims.push(dims);
        })         

        // aggregate those by selecting the minimal of all world mins and maximal of all world maxs
        let aggregatedDims = {}        
        sysDims.forEach((d) => {
            
            if(aggregatedDims.xmin) 
                aggregatedDims.xmin = Math.min(aggregatedDims.xmin, d.xmin)
            else
                aggregatedDims.xmin = d.xmin;
            
            if(aggregatedDims.xmax)
                aggregatedDims.xmax = Math.max(aggregatedDims.xmax, d.xmax)
            else
                aggregatedDims.xmax = d.xmax;

            if(aggregatedDims.zmin)
                aggregatedDims.zmin = Math.min(aggregatedDims.zmin, d.zmin)
            else
                aggregatedDims.zmin = d.zmin;

            if(aggregatedDims.zmax)
                aggregatedDims.zmax = Math.max(aggregatedDims.zmax, d.zmax)
            else
                aggregatedDims.zmax = d.zmax;
        })

        return aggregatedDims;
    }
    
    initRebuildHierarchy(){        
        this.rebuildTypeRoot = new TreeNode(null, rebuildTypes.full);
        this.rebuildTypeRoot.addChildNode(rebuildTypes.footprint);
        this.rebuildTypeRoot.addChildNode(rebuildTypes.model);
    }

    

    

    processRebuild(type){
        switch(type)
        {
            case rebuildTypes.move:                
                break;
            default:
                this.rebuildFull();
                break;

        }
    }

    

    getChildSystems(){
        return this.design.components.filter((c) => c.type === CORE.components.system );
    }


    create(){
        this.build();
    }

    destroy(){
        
        this.removeSystems();        
        this.components=[];
    }

    removeSystems(){
        this.components.forEach((s)=>{
            this.removeSystem(s);
        });
        this.components = this.components.filter((x) => x.design.type === CORE.components.null);
    }

    removeSystem(s){
        s.remove();
    }
        
    updateNullComponent(){
        this.components[0] = new Null(this.nullComponentDesign);
        //this.components[0].components.push(newComponent);
    }

    build(){
        
        this.nullComponentDesign = this.masterDesign.design.components[0];
        this.addComponent(new Null(this.nullComponentDesign))

        this.buildSystems();

        //this.rebuildModels();
     /*
        let cr = new CompassRose(50);
        cr.group.position.x = -700;
        cr.group.position.z = -700;
        this.group.add(cr.group);
        layerHelper.setGroup(cr.group, CORE.layers.constraint, true);
*/
        // null component is added programmatically to the end of the design template
        this.collisionZones = [];
    }


    rebuildFull(){
        this.destroy();
        this.create();
    }
    
    buildSystems(){        
        let systemDesigns = this.getChildSystems();
        systemDesigns.forEach((s,i)=>{
            let system = this.buildSystem(s);            
            this.addComponent(system);
            //if(this.components.length>1) 
                //this.components[i+1] = structure;
        });

    }


    buildSystem(sDes){
        let system = new System(sDes, this.masterDesign, this.model);
        this.group.add(system.group);
        system.setPosition(sDes);
        return system;
    }



    defaultDesign(){
        return {};
    }

    getComponentsNotOnWalls(){
        let notOnWalls = [];

        // loop through all systems
        this.components.forEach((c)=>{
            if(c.design.type ===  CORE.components.null)
                return;
            let system = c;
            let now = system.getComponentsNotOnWalls();
            notOnWalls.push(...now)
        })
        
        return notOnWalls;
    }

    getAllCollisions(){
        let collisions = [];
        // get all collision zones
        let zones = this.getAllCollisionZones();
        zones.forEach((z)=>{
            let o = new THREE.Object3D();
            o.name = 'temp collision object'
            o.position.copy(z.position.clone());
            z.parentGroup.add(o);
            o.updateMatrix();
            z.obb = CollisionHelper.createObb(z.bounds);
            
            let aWorldPos = new Vector3();
            o.getWorldPosition(aWorldPos);
            let aWorldOrient = new Quaternion();
            o.getWorldQuaternion(aWorldOrient);
            aWorldOrient.normalize();

            z.obb.center.set(aWorldPos.x,aWorldPos.y, aWorldPos.z);
            z.parentGroup.remove(o);                       
            
            //o.setRotationFromQuaternion(aWorldOrient);
            z.obb.rotation = MatrixHelper.getM3FromQuaternion(aWorldOrient)
        })
        zones = zones.map((z, index)=> { z.id = index; return z;}) // apply a temporary and unique ID to all zones
        // just the resolvable zones 
        let resolvableZones = zones.filter(f=> f.resolvable)

        // compare each to all others, checking for collisions
        for(var rz=0;rz<resolvableZones.length;rz++) {
            for(var z=0;z<zones.length;z++){
                let rZone = resolvableZones[rz];
                let zone = zones[z];
                if(rZone.id===zone.id) // if this resolvableZone is the same zone being tested, skip to prevent false positives
                    continue;
                
                if(this.checkZoneCollision(rZone, zone))
                {
                    //console.log(rZone);
                    collisions.push(
                        {
                            object: rZone,
                            zone: zone
                        }
                    );
                }
            }
        }
        // window and door objects are the problem, not columns, rafters, and eaves
        // return those in objects collision. 
        return collisions;
    }

    getAllCollisionZones(){
        return this.childrenGetAllCollisionZones(this.group.children)
    }

    childrenGetAllCollisionZones(children){
        let _this = this;
        let zones = [];
        children.forEach(function(child){
            if(child instanceof THREE.Mesh){
                let newChildZones = _this.childGetAllCollisionZones(child)
                if(newChildZones && newChildZones.length>0)
                {
                    child.updateWorldMatrix(true);
                    newChildZones.forEach((z) => {z.matrixWorld = child.matrixWorld;})
                    zones.push(...newChildZones);
                }
            }else if(child instanceof THREE.Group){
                let newChildrenZones = _this.childrenGetAllCollisionZones(child.children);
                if(newChildrenZones && newChildrenZones.length>0){
                    child.updateWorldMatrix(true);
                    newChildrenZones.forEach((z) => {z.matrixWorld = child.matrixWorld;})
                    zones.push(...newChildrenZones);
                }
            }            
        });
        return zones; 
    }

    childGetAllCollisionZones(child){
        if(child.userData===null ||
            typeof(child.userData) === 'undefined' ||
            typeof(child.userData.collisionZones) === 'undefined')
            return [];
        //child.userData.collisionZones.matrixWorld = child.matrixWorld;
        return child.userData.collisionZones;
    }
    
    checkZoneCollision(zA, zB) {

        //let classMatches = zA.classes.filter(x => zB.classes.includes(x));
        let classMatches = zB.classes.filter(x => x === zA.data.type);
        if(classMatches.length===0)
            return false;
        /////////////////////////////////////////////////
        // the problem here (likely) was that I was not also transforming zoneB similarly!
        // let aWorldPos = new Vector3();
        // zA.parentGroup.getWorldPosition(aWorldPos);
        // let aWorldOrient = new Quaternion();
        // zA.parentGroup.getWorldQuaternion(aWorldOrient);


        // zA.obb.center.set(aWorldPos.x,aWorldPos.y, aWorldPos.z);
        
        // aWorldOrient.normalize();
        // let o = new THREE.Object3D();
        // o.setRotationFromQuaternion(aWorldOrient);
        // zA.obb.rotation = MatrixHelper.getM3FromQuaternion(aWorldOrient)
        /////////////////////////////////////////////////
        let intersects = zB.obb.intersectsOBB( zA.obb );
        if(intersects){
            //console.log(`${zA.name} collides with ${zB.name}`, zA, zB);
        }
        return intersects;
    }
    
    showCollisionZones(zoneClass){
        this.childrenShowCollisionZones(this.group.children, zoneClass)
    }    

    childrenShowCollisionZones(children,zoneClass){
        let _this = this;
        let res = false;
        children.forEach(function(child){
            
            if(child instanceof THREE.Mesh ){                
                _this.childShowCollisionZones(child,zoneClass)
                
            }else if(child instanceof THREE.Group){
                _this.childrenShowCollisionZones(child.children,zoneClass);
            }            
        });
    }

    childShowCollisionZones(child,zoneClass){
        //console.log('checking a child...');
        if(child.userData===null ||
            typeof(child.userData) === 'undefined' ||
            typeof(child.userData.collisionZones) === 'undefined')
            return;

        child.userData.collisionZones.forEach((z) => {
            if(z.classes.includes(zoneClass))
                this.childShowCollisionZone(z);
        });
    }

    childShowCollisionZone(z){
        let sz = new Vector3().copy(z.bounds.halfSize).multiplyScalar(2);
        let geometry = new THREE.BoxBufferGeometry(sz.x,sz.y,sz.z);
        let mesh = new THREE.Mesh( geometry,  this.matCollisionZone);
        mesh.renderOrder=1;
        // competing requriements make this interesting
        // obb has to be in correct world space location for collision detection to work
        // final world position of a point isn't known at build time since the entire scene graph may or may not be done 
        //      (this could be changed sequencing 1) object creation, 2) group assignment, and 3) the object build process)
        // collision mesh is drawn relative to the collisionZone parent group, so world matrix data is inappropriate to use here
        // obb uses center, not position which is quirky

        if(z.parentGroup){
            z.parentGroup.add(mesh);
            mesh.position.copy(z.position);
        }
       

        this.collisionZones.push(mesh);
    }

    removeCollisionZones(){
        this.collisionZones.forEach((z)=>{
            let parent = z.parent;
            if(parent)
                parent.remove(z);
                else
                {
                    try{
                                        //Rollbar.error("parent of collisionZone is falsey", z);
                    }
                    catch{
                        
                    }
                }
            MemHelper.dispose(z);

        })
        this.collisionZones=[];
    }

    getDescription(){
        return "Building";
    }     

    remove(){
        this.components.forEach((c)=>{
            c.remove();
        })
        super.remove();
    }
}