import Util from "../utility.js";
import {CORE} from '../_spec.js';
import SegmentHelper from "./SegmentHelper.js";
import StructureHelper from "./StructureHelper.js";
import BlueprintHelper from "./blueprintHelper.js";

export default class ConstraintHelper{
/*   
    Constraint System
        allows structures to be coupled together 

    Data Structure
        Tree of Constraint Nodes is used, 
            where each node represents a structure
        Since a structure may have multiple constraints,
            each node has a list of constraints describing its behavior
        

            
    CONSTRAINT DESIGN

     - The constraint tree is more efficient to search than a flat list
     - The tree doesn't waste much space with redundant data
     - The tree more naturally represents the inherent parent/child relationship required in order to resolve constraints predictably
    
    EXAMPLE CONSTRAINT TREE
    tree: 
    {
        id: 1
        children: [
            {
                id:17
                children:[]
                constraints: []
            }
        ]

        constraints: [
            {
                parent:{
                    id:1,
                    ms,
                    rs,
                    len
                },
                child:{
                    id:17,
                    ms,
                    rs,
                    len
                }
            }
        ]
    }
    

*/
static GetRootConstraintNodeId() {
    return 0;
} 

static rootNode(){
    if(!this.root)
        this.root = this.createConstraintNode(this.GetRootConstraintNodeId());
    return this.root;
}

static applyConstraintData(ref, data){
    ConstraintHelper.applyPartData(ref.parent,data.parent);
    ConstraintHelper.applyPartData(ref.child, data.child);
}
static applyPartData(ref, data){
    ref.length = Number(data.length);
    ref.matingSide = data.matingSide;
    ref.structureID = data.structureID;
}

static cloneConstraint(c){
    return{
        id: c.id,
        color: c.color,
        partyWallOwnerID: c.partyWallOwnerID,
        parent: this.cloneConstraintPart(c.parent),
        child:this.cloneConstraintPart(c.child),
    }
}


static cloneConstraintPart(p){
    return {
        length: p.length,
        matingSide: p.matingSide,
        structureID: p.structureID
    }
}


static getConstraintWithId(branch, id){
    let found;
    branch.constraints.forEach((con)=>{
        if(found)
            return;
        if(con.id === id)
            found = con;
    })

    if(found)
        return found;

    
    branch.childNodes.forEach((branch)=>{        
        if(found)
            return;        
        let localFound = this.getConstraintWithId(branch, id);
        if(localFound)
            found = localFound;
    })
    return found;
}
    
static getConstraintGuiData(constraint, designManager){

    let result={}

    result.id = constraint.id;
    result.color = constraint.color;
    result.partyWallOwnerID = constraint.partyWallOwnerID
    if(constraint.parent.structureID===this.GetRootConstraintNodeId()){
        return
    }
        
    result.parent = this.getConstraintPartGuiData(constraint.parent, designManager);
    result.child = this.getConstraintPartGuiData(constraint.child, designManager);
    if(!result.parent || !result.child)
        return null;
    

return result;
}

static getConstraintPartGuiData(part, designManager){
    if(part.structureID == null) 
        return; // if this parent or child has not structure ID, there's no gui data necessary
    let result={};    
    result.length = part.length;
    result.structureID = part.structureID
    let structureDesign = designManager.getComponentDesignById(part.structureID);        
    // if the parent of a porch was deleted, 
    if(!structureDesign)
        return null;
    result.structureName = structureDesign.name;
    result.matingSide = part.matingSide;
    return result;
}


    static doesConstraintConnect(s1Id, s2Id, constraint){
        if(constraint.parent.structureID === s1Id && constraint.child.structureID === s2Id)
            return constraint;
        else if(constraint.child.structureID === s1Id && constraint.parent.structureID === s2Id)
            return constraint;
        return false;
    }

    static areConstrainedTogether(s1Id, s2Id, node){
        // if the IDs are the same
        if(s1Id === s2Id)
            // short-circuit
            return null;
        
        let found = null;
        // check every constraint in this constraintNode
        node.constraints.forEach((constraint)=>{            
            if(found) return;
            found = ConstraintHelper.doesConstraintConnect(s1Id, s2Id, constraint)
        })

        if(found)
            return found;        
            
        // search all the constraints in this node    
        node.childNodes.forEach((childNode)=>{
            if(found) return;
            found = ConstraintHelper.areConstrainedTogether(s1Id, s2Id, childNode)
        });

        return found;        
    }
    
    static getSecondaryStructuresAttachedToStructureSide(secondaryStructureType, designManager, dStructure, side){
        let structureId = dStructure.id;        
        // find all structures constrained to this side of the structure
        let structureConstraints = ConstraintHelper.findConstraintsForNodeWithId(designManager.constraintRootNode, dStructure.id);
        let dSideConstraints = structureConstraints.filter((c) => { return (c.parent.structureID === structureId && c.parent.matingSide === side)});
        let dPorchConstraints  = [];
        
        // narrow it down to just the porches
        dSideConstraints.forEach((c)=>{
            let cChild = designManager.getComponentDesignById(c.child.structureID);
            if(cChild && cChild.type === secondaryStructureType)
                dPorchConstraints.push(c); 
        });

        return dPorchConstraints;
    }

    
    static getStructuresOnSide(designManager, dStructure, side){
        let structureId = dStructure.id;        
        // find all structures constrained to this side of the structure
        let structureConstraints = ConstraintHelper.findConstraintsForNodeWithId(designManager.constraintRootNode, dStructure.id);
        let dSideConstraints = structureConstraints.filter((c) => { return (c.parent.structureID === structureId && c.parent.matingSide === side)});
        let dStructureConstraints  = [];
        
        // narrow it down to just the porches
        dSideConstraints.forEach((c)=>{
            let cChild = designManager.getComponentDesignById(c.child.structureID);
            if(cChild && (cChild.type === CORE.components.porch || cChild.type === CORE.components.structure))
                dStructureConstraints.push(c);
        });

        return dStructureConstraints;
    }

    static getConstraintsOnSide(designManager, dStructure, side){
        let structureId = dStructure.id;        
        // find all structures constrained to this side of the structure
        let constraintsByParentId = ConstraintHelper.findConstraintsForNodeWithId(designManager.constraintRootNode, structureId);
        let dSideConstraints = constraintsByParentId.filter((c) => { return (c.parent.structureID === structureId && c.parent.matingSide === side)});
        
        let constraintByChildId = ConstraintHelper.getConstraintWithChildId(designManager.constraintRootNode, structureId);
        if(constraintByChildId.child.structureID === structureId && constraintByChildId.child.matingSide === side)
        dSideConstraints.push(constraintByChildId);

        return dSideConstraints
    }

    static  getConstraintMatingAngle(constraint){
        let y = 0;
        let halfPi = Math.PI/2;
        switch(constraint.parent.matingSide){
            case CORE.sides.frontSide:
                switch(constraint.child.matingSide){
                    case CORE.sides.frontSide:
                        y=Math.PI;
                        break;
                    case CORE.sides.leftEnd:
                        y=-halfPi;
                        break;
                    case CORE.sides.backSide:
                        y=0;
                        break;
                    case CORE.sides.rightEnd:
                        y=halfPi;
                        break;
                }
                break;
            case CORE.sides.leftEnd:
                switch(constraint.child.matingSide){
                    case CORE.sides.frontSide:
                        y=halfPi;
                        break;
                    case CORE.sides.leftEnd:
                        y=Math.PI;
                        break;
                    case CORE.sides.backSide:
                        y=-halfPi;
                        break;
                    case CORE.sides.rightEnd:
                        y=0;
                        break;
                }
                break;
            case CORE.sides.backSide:
                switch(constraint.child.matingSide){
                    case CORE.sides.frontSide:
                        y=0;
                        break;
                    case CORE.sides.leftEnd:
                        y=halfPi;
                        break;
                    case CORE.sides.backSide:
                        y=Math.PI;
                        break;
                    case CORE.sides.rightEnd:
                        y=-halfPi;
                        break;
                }
                break;
            case CORE.sides.rightEnd:
                switch(constraint.child.matingSide){
                    case CORE.sides.frontSide:
                        y=-halfPi;
                        break;
                    case CORE.sides.leftEnd:
                        y=0;
                        break;
                    case CORE.sides.backSide:
                        y=halfPi;
                        break;
                    case CORE.sides.rightEnd:
                        y=Math.PI;
                        break;
                }
                break;
        }
        return y;
        
    }

    static getPorchesWithNonBoltFrameType(designManager, desParent){
        let porchNamesWithNonBoltFT = [];
        let parentId = desParent.id;
        let parentConstraints = ConstraintHelper.findConstraintsForNodeWithId(designManager.constraintRootNode, parentId);
        parentConstraints.forEach((c) => {
            let desChild = designManager.getComponentDesignById(c.child.structureID);
            if(desChild && desChild.type === CORE.components.porch && desChild.getFrame().frameType !== CORE.frame.types.bolt)
                porchNamesWithNonBoltFT.push(desChild.name);
        })
        
        return porchNamesWithNonBoltFT;
    }

    static getS1MatingSegment(s1Constraint, dS1, s2Constraint, dS2){
        // return the segment of linear space where s2 overlaps s1
        // this is calculated and returned in terms of s1 because s1 is the main structure for porches

        //length of main on the mating side        
        //let s1SideLength = StructureHelper.getLengthOfSide(dS1, s1Constraint.matingSide);
        //length of porch on the mating side
        let s2SideLength = StructureHelper.getLengthOfSide(dS2, s2Constraint.matingSide);

        // ASSUMPTION: that s2 is somewhere in s1

        // calculate segment taken up by this structure
        let start = ConstraintHelper.getAlignmentDistanceOfConstraint(s1Constraint, dS1);
        let end = start+s2SideLength;

        return SegmentHelper.getSegment(start, end);        
    }

    static getAlignmentDistanceOfConstraint(constraintSection, design){
        let sideLength = StructureHelper.getLengthOfSide(design, constraintSection.matingSide);
        let alignmentDistance = constraintSection.length;

        switch(constraintSection.matingSide){
            case CORE.sides.frontSide:
                return alignmentDistance;
            case CORE.sides.backSide:
                return sideLength - alignmentDistance;
            case CORE.sides.leftEnd:                
                return alignmentDistance;
            case CORE.sides.rightEnd:
                return sideLength - alignmentDistance;            
            default:
                console.error(`gadc: invalid side ${side} requested`)
                break;
        }
    }


    // section = part
    static createConstraintSection(id, matingSide, length){
        return {
            structureID: id,
            matingSide,
            length
        }
    }
    static createConstraint(parent, child){    
        return {
            parent,
            child
        }
    }

    static createConstraintNode(id){
        return {
            //parentId, // id of parent structure (undefined for root)
            id, // id of structure parenting all the following constraints
            constraints: [], // the constraints this node is parent in          
            childNodes: [], // next level down
            // partyWallOwnerID: CORE.partyWallOwner.neither,
        }
    }


    /*
    static findConstraintConnecting(node, id1, id2, startFromRoot=true){
        // the first time through, we need to make sure we start from the top so we don't miss a cousin node
        if(startFromRoot)
            node = ConstraintHelper.getRootNode(node);
        
        let constraints = [];
        if(node.id === id)
        {
            constraints.push(node.constraints);
            return constraints;
        }
        // for all children, 
        node.childNodes.forEach(childNode => {
            if(childConstraints.length>0)
                return;
            let childConstraints = ConstraintHelper.findConstraintsForNodeWithId(childNode, id, false);            
            constraints.push(...childConstraints);
        })
        return constraints;
    }*/

    static getConstraintWithChildId(node, id){        
        let constraint;        
        node.constraints.forEach((c)=> {
            if(Util.isDefined(constraint))
                return;
            if(c.child.structureID === id)
            constraint = c;
        })

        if(!constraint)
        node.childNodes.forEach((cn)=>{
            let temp = this.getConstraintWithChildId(cn, id)
            if(temp)
                constraint = temp;
        });
        return constraint;
    }

    static getAllChildrenUnderNodeWithId(childrenUnderNode, node, id){        
        node.constraints.forEach((c)=> {
            if(c.parent.structureID === id){
                childrenUnderNode.push(c.child.structureID);
                let root = ConstraintHelper.getRootNode(node)
                this.getAllChildrenUnderNodeWithId(childrenUnderNode, this.findFirstConstraintNodeWithId(root, c.child.structureID), c.child.structureID)
            }
        })
       
        return childrenUnderNode
    }


    static findConstrainerOfNodeWithId(node, id, startFromRoot=true){
         // the first time through, we need to make sure we start from the top so we don't miss a cousin node
         if(startFromRoot)
         node = ConstraintHelper.getRootNode(node);

        
        if(node.id === id)
        {            
            return node.parentNode;
        }
        // for all children, 
        let constrainer;
        node.childNodes.forEach(childNode => {
            if(Util.isDefined(constrainer))
                return;
            constrainer = ConstraintHelper.findConstrainerOfNodeWithId(childNode, id, false);                        
        })
        return constrainer;
    }


    static findConstraintsForNodeWithId(node, id, startFromRoot=true){
        // the first time through, we need to make sure we start from the top so we don't miss a cousin node
        if(startFromRoot)
            node = ConstraintHelper.getRootNode(node);
        
        let constraints = [];
        if(node.id === id)
        {
            constraints.push(...node.constraints);
            return constraints;
        }
        // for all children, 
        let childConstraints = [];
        node.childNodes.forEach(childNode => {
            if(childConstraints.length>0)
                return;
            childConstraints = ConstraintHelper.findConstraintsForNodeWithId(childNode, id, false);            
            constraints.push(...childConstraints);
        })
        return constraints;
    }
    
    static findFirstConstraintNodeWithId(node, id){
        if(!node){
            console.error('ffcnwi: node must not be falsey');
            return;
        }
        if(Util.isUndefined(id)){
            console.error('ffcnwi: id must not be undefined');
            return;
        }
        // root node is allowed to be null
        //if(id === null){            
            //console.error('ffcnwi: id must not be null');
            //return;
        //}

        let rootNode = ConstraintHelper.getRootNode(node);

        let descendantNode = ConstraintHelper.descendantsIncludeConstraintNodeId(rootNode, id);
        if(descendantNode)
            return descendantNode;

        return null;
    }

    static isConstraintValidGableTieIn(constraint, parent, child) {
        if (!constraint || !constraint.parent || !constraint.child || !parent || !child)
            return false;

        let pRoof = parent.getRoof();
        let pFrame = parent.getFrame();

        let childRoof = child.getRoof();
        let childFrame = child.getFrame();


        // child structure - only the left side is supported
        if (constraint.child.matingSide != CORE.sides.leftEnd)
            return false;
            
        // parent structure - end wall connections are never gable tie in
        if (constraint.parent.matingSide == CORE.sides.leftEnd || constraint.parent.matingSide == CORE.sides.rightEnd)
            return false;
        // parent structure - back side connection can only be a tie-in for gabled roofs
        if (constraint.parent.matingSide == CORE.sides.backSide)
            if (pRoof.roofType == CORE.roof.types.slope)
                return false;   
        
        // front is valid for both roof types

        // do the heights make sense for gable?
        let parentRidgeHeight = pFrame.height + BlueprintHelper.pitchHeight(pFrame.width, pRoof.pitch, pRoof.roofType);
        let childRidgeHeight = childFrame.height + BlueprintHelper.pitchHeight(childFrame.width, childRoof.pitch, childRoof.roofType);

        if (parentRidgeHeight < childRidgeHeight || pFrame.height < childFrame.height || pFrame.height > childRidgeHeight)
            return false;

        return true;
    }


    static ancestorsIncludeConstraintNodeId(node, id){
    /// checks initial node and ancestors until first match
        if(node.id === id) // the provided node has the id
            return node;

        // return whether or not this id in in the ancestry
        if(node.parentNode)            
            return this.ancestorsIncludeConstraintNodeId(node.parentNode, id);        
        
        return null; // the root structure has been reached without a match
    }

    
    static descendantsIncludeConstraintNodeId(node, id){        
    /// checks initial node and depth-first descendents until first match
        if(node.id === id) // the provided node has the id
            return node;

        let found=null;
        node.childNodes.forEach(childNode => {
            if(found)
                return;
            
            let subNodes = ConstraintHelper.descendantsIncludeConstraintNodeId(childNode, id);
            if(subNodes)
                found = subNodes;
            
        });

        return found;
    }

   

    static getRootNode(node){        
        if(!node)
            return;
        if(node.parentNode)            
            return ConstraintHelper.getRootNode(node.parentNode);        
        else
            return node; 
    }


    static maxConstraintId(node){
        let maxId = 0;

        node.constraints.forEach((constraint) => {
            if(constraint.id > maxId)
                maxId = constraint.id;
        });

        node.childNodes.forEach((childNode)=> {
            let localMax = this.maxConstraintId(childNode);
            if(localMax > maxId)
                maxId = localMax;            
        });
        return maxId;
    }

    static addConstraintToNode(node, constraint){
        // verify the constraint is being added to the proper node
        if(constraint.parent.structureID !== node.id){
            console.error('constraint parent id and node id must match')
            return null;
        }

        // make sure there are no cycles in the node tree
        let found = ConstraintHelper.findFirstConstraintNodeWithId(node, constraint.child.structureID);
        if(found){
            console.error(`A node is already constraining child ${constraint.child.structureID}:`, node);
            return null;
        }

        // apply a unique identifier to the constraint itself
        //constraint.id = uniqueId; // move this to it's own helper
        // add the constraint to this node
        node.constraints.push(constraint);
        
        // create a child node for this constraint with the child's structureID
        let childNode = ConstraintHelper.createConstraintNode(constraint.child.structureID)
        // link up the parent node
        childNode.parentNode = node;
        // add the child node to the parent
        node.childNodes.push(childNode);
        // return constraint for identifiation
        return constraint;
    }

    static deleteConstraint(root, nodeId){
        // remove constraint nodes referring to this node        
        //  we would move the orphaned branch up to the first tier

        // you can't delete the root
        if(nodeId === 0)
            return;

        // if you delete a node
        let nodeToDelete = ConstraintHelper.findFirstConstraintNodeWithId(root, nodeId)
        if(!nodeToDelete)
            return;

        // any children need to be re-rooted at first-tier
        if(nodeToDelete.childNodes.length>0)
            ConstraintHelper.moveChildNodesUnderRoot(root, nodeToDelete);

        // remove this constraint from the parent
        nodeToDelete.parentNode.constraints = nodeToDelete.parentNode.constraints.filter((c) => {
            return c.child.structureID !== nodeId
        })
        // the parent needs updating
        ConstraintHelper.unlinkNodeFromParent(nodeToDelete);

    }

    static createRootChildConstraintForId(id){         
        // root node has id null
        // (tier 1) children of the root, accurately, have a parentId that is null
        // (tier 2) children of tier 1 have a child.StructureID and a parent.StructureID that are non-null
        return ConstraintHelper.createConstraintFromDetails(this.GetRootConstraintNodeId(),null,null,id,null,null);
    }

    static addConstraintToParent(newConstraint, designManager){
        // adds a constraint to the parent specified in the constraint
        let pStructureID = newConstraint.parent.structureID;
        let parentConstraintNode = ConstraintHelper.findFirstConstraintNodeWithId(designManager.constraintRootNode, pStructureID);
        if(!parentConstraintNode){
            console.error("no constraint for the current structure could be found.");
            return;
        }
            
        designManager.addConstraintToNode(parentConstraintNode, newConstraint);
    }

    // this is for delete a constraint and moving the newly un-constrained structure under the rootnode
    static moveNodeUnderRoot(root, movingNode){

        // isolate the parent's constraint
        let constraint = movingNode.parentNode.constraints.filter(c => c.child.structureID === movingNode.id)
        
        let newConstraint = ConstraintHelper.cloneConstraint(constraint[0]);//ConstraintHelper.createConstraintFromDetails(null,null,null,movingNode.id,null,null);
        newConstraint.parent.structureID=0;
        newConstraint.parent.matingSide = null;
        newConstraint.parent.length=null;
        newConstraint.child.matingSide = null;
        newConstraint.child.length=null;
        
        // add it to the root
        root.constraints.push(newConstraint);
        // remove the constraint for the moving node from the parent's constraints
        movingNode.parentNode.constraints = movingNode.parentNode.constraints.filter(c => c.child.structureID !== movingNode.id)
        // remove the constraint node for the moving node from the parent's constraint nodes
        movingNode.parentNode.childNodes = movingNode.parentNode.childNodes.filter(n => n.id !== movingNode.id)
        // move the node to the root
        movingNode.parentNode = root;
        root.childNodes.push(movingNode);
    }

    ///This is for deleting a structure, and handling the orphan child structure constraints under it
    static moveChildNodesUnderRoot(root, deletingNode){
        

        // make a copy of deleting node's children so we can remove references to them from the deleting node itself
        let copiesOfChildNodes = Array.from(deletingNode.childNodes); 

        // remove links from deleting node to its children
        deletingNode.childNodes = [];
        let copiesOfConstraints = Array.from(deletingNode.constraints);
        
        // migrate constraints of deleting node to root node
        copiesOfConstraints.forEach((c)=>{
            // JQUESTIONS: shouldn't it be createConstraintSection(0,null,null)
            c.parent = ConstraintHelper.createConstraintSection(null,null,null);
            c.child = ConstraintHelper.createConstraintSection(c.child.structureID, null,null);
        })
        root.constraints.push(...copiesOfConstraints);

        // JQUESTIONS: this is done twice, once here and another in the original method call in deleteConstraint
        // remove the constraint for the deleting node from the parent's constraints
        deletingNode.parentNode.constraints = deletingNode.parentNode.constraints.filter(c => c.child.structureID !== deletingNode.id)

        // move the child nodes under the root
        copiesOfChildNodes.forEach((child)=>{
            // link the children of the deleting node to the root
            child.parentNode = root;
            // linke the root to the children of the deleting node
            root.childNodes.push(child);            
        })
    }

    
    static moveNode(movingNode, newParent, newConstraint){
        // de-link moving node from it's parent
        movingNode.parentNode.childNodes = movingNode.parentNode.childNodes.filter(childNode => childNode.id != movingNode.id)
        // remove the constraint for the moving node from the parent's constraints
        movingNode.parentNode.constraints = movingNode.parentNode.constraints.filter(c => c.child.structureID !== movingNode.id)

        
        
        // link moving node to new parent
        movingNode.parentNode = newParent;
        // add moving node to parent
        newParent.childNodes.push(movingNode);
        // add (different) constraint to newParent
        newParent.constraints.push(newConstraint);
        
    }

    static unlinkNodeFromParent(node){
        // use node to access parent
        // filter out this node from parent childNodes
        let parent = node.parentNode;
        parent.childNodes = parent.childNodes.filter(cn => cn.id != node.id)
    }

    
  static createConstraintFromDetails(pStructureID, pMatingSide, pLength, cStructureID, cMatingSide, cLength){
    // s1 should always be the existing structure
    let parent = ConstraintHelper.createConstraintSection(
      pStructureID, 
      pMatingSide, 
      pLength);
    
    let child =  ConstraintHelper.createConstraintSection(
      cStructureID, 
      cMatingSide, 
      cLength);  

    let newConstraint = ConstraintHelper.createConstraint(parent,child);    
    return newConstraint;
  }

  static migratePartyWallOwnership(c, designManager){
    if(Util.isDefined(c.partyWallOwnerID))
        return;

    let desParent = designManager.getComponentDesignById(c.parent.structureID);  
    let pMatingWall = StructureHelper.getWallDesFromSide(desParent, c.parent.matingSide);

    let desChild = designManager.getComponentDesignById(c.child.structureID);  
    let cMatingWall = StructureHelper.getWallDesFromSide(desChild, c.child.matingSide)

    if(desChild.type === CORE.components.porch){
        c.partyWallOwnerID = desParent.id;
        return;
    }

    // as of 6/5/2024, "backWall" and "openToMain" options have been removed,
    // and lean-tos have only been children, never parents
    if(desChild.type === CORE.components.leanTo){
        if((desChild.openTomain && desChild.backWall) || (!pMatingWall.openWall && !cMatingWall.openWall)){
            // if both options are ticked, this indicates both structures are responsible for the party wall.
            // this is invalid and not supported, so default to open.
            c.partyWallOwnerID = CORE.partyWallOwner.neither;
            return;
        }
        if(desChild.backWall || (pMatingWall.openWall && !cMatingWall.openWall)){
            // ticking "include back wall" only indicates the child is responsible for the party wall
            c.partyWallOwnerID = desChild.id;
            return;
        }
        if(desChild.openTomain || (pMatingWall.openWall && cMatingWall.openWall)){
            // ticking "open to main" only indicates neither structure is responsible for the party wall
            c.partyWallOwnerID = CORE.partyWallOwner.neither;
            return;
        }
        if((!desChild.openTomain && !desChild.backWall) || (!pMatingWall.openWall && cMatingWall.openWall)){
            // unticking both options indicate the parent is responsible for the party wall
            c.partyWallOwnerID = desParent.id;
            return;
        }
    }
    else if(desChild.type === CORE.components.structure){
        if((pMatingWall.type === CORE.components.lew || pMatingWall.type === CORE.components.rew) && (cMatingWall.type === CORE.components.lew || cMatingWall.type === CORE.components.rew)){
            // if constraint is end to end mating pair

            if((pMatingWall.openWall && cMatingWall.openWall) || (!pMatingWall.openWall && !cMatingWall.openWall)){
                // if both mating walls are open, then neither structure is responsible for the party wall.
                // if both mating walls are closed, this is invalid and not supported, so default to open.
                c.partyWallOwnerID = CORE.partyWallOwner.neither;
                return;
            }
            if(pMatingWall.openWall){
                // if parent mating wall only is open, then the child is responsible for the party wall
                c.partyWallOwnerID = desChild.id;
                return;
            }
            if(cMatingWall.openWall){
                // if child mating wall only is open, then then parent is responsible for the party wall
                c.partyWallOwnerID = desParent.id;
                return;
            }
        
        }
        c.partyWallOwnerID = CORE.partyWallOwner.neither;
    }
  }
}