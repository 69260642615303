import * as THREE from 'three'

import CollisionHelper from '../helpers/CollisionHelper'
import {CORE} from '../_spec'
import Util from '../utility';

import { FloatType, Quaternion, Vector3, ZeroCurvatureEnding } from 'three';
import MemHelper from '../helpers/memHelper.js';


export default class _3dBase{

    constructor(parentGroup, pos, rot, lod){
        // we instantly swap in a new group 
        this.group = new THREE.Group();       
        this.group.name = `3d base group`;

        this.lod = lod; // level of detail

        if(parentGroup)
            parentGroup.add(this.group);
        
        if(pos)
            this.group.position.copy(pos);

        if(rot instanceof Quaternion){
            this.group.applyQuaternion(rot);
        }
        else if(rot)
            this.group.rotateY(rot);
    }
    
    static getPitchPurlinDimY(){
        let purlinDimY = CORE.roof.purlin.dim.height;
        return purlinDimY / Math.cos(pitchAngle); // the vertical height of a purlin pitched to match the roof pitch
    }


    isHighDetail(){
        return !this.isLowDetail();    
    }
    isLowDetail(){
        if(this.lod == CORE.levelOfDetail.high)
            return false;
        return true;
    }

    getCollisionClasses(){
        return [];
    }
    
    buildCollisionZones(){

        let zone = CollisionHelper.getZone2(
            this.group,
            this.addCollisionZone.position,
            false,
            this.getCollisionClasses(),
            this.addCollisionZone.dim,
            this.addCollisionZone.margin,
            this.group.name
        );
        
        return [zone];
    }

    
	remove(){
        MemHelper.removeAllChildren(this.group);
        //parentGroup.remove(this.group);
        MemHelper.dispose(this.group);
	}
}