import BlueprintHelper from "../helpers/blueprintHelper.js";


export default class BuildLogic{


    static GetFrameline(framelines, frameline){
        return framelines[frameline];
    }
    static GetLeftEndWallPosX(structureLengthInches){
        return -structureLengthInches/2;
        //let framePos = BlueprintHelper.getFramelinePositionXByIndex(structureLength, frameLinePositions, frameline, isPorch);
        //let halfColumnDepth = BlueprintHelper.getStandardFrameMaterialSize().depth/2;
        //return framePos - halfColumnDepth;
    }

    static GetRightEndWallPosX(structureLength){
        return structureLength/2;
        //let framePos = BlueprintHelper.getFramelinePositionXByIndex(structureLength, frameLinePositions, frameline, isPorch);
        //let halfColumnDepth = BlueprintHelper.getStandardFrameMaterialSize().depth/2;
        //return framePos + halfColumnDepth;
    }

    static getOutsideFramelineInset(){
        return {outsideFrameLineInset: 4}
    }
    


}