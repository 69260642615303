export const testProject = {
    id: 'test-project-1',
    name: 'Bluth Home 1',
    price: 1000,
    weight: 50, 
    lastModified: '2024-07-30',
    designInfo: 'This is a test project design',
    owner: 'Gob Bluth',
    screenshot: 'https://example.com/test-screenshot.jpg',
    stageHistory: [
      {id: 1, name: 'Design', date: '2024-07-01'},
      {id: 2, name: 'Review', date: '2024-07-15'},
    ],
    customerInfo: 'Stan Sitwell',
    locationInfo: 'Middle East, Iraq',
    archived: false,
    links: [
      {id: 1, name: 'Test Link 1', url: 'https://example.com/link1'},
      {id: 2, name: 'Test Link 2', url: 'https://example.com/link2'},
    ],
  };
  
  export const testProjects = [
        // { id: 1, name: 'Project Alpha', price: 1000, weight: 50, lastModified: '2024-07-15', owner: 'John Doe' },
        // { id: 2, name: 'Project Beta', price: 1500, weight: 75, lastModified: '2024-07-20', owner: 'Jane Smith' },
        // { id: 3, name: 'Project Gamma', price: 2000, weight: 100, lastModified: '2024-07-25', owner: 'Bob Johnson' },
        // { id: 4, name: 'Project Delta', price: 1200, weight: 60, lastModified: '2024-07-28', owner: 'Alice Brown' },
        // { id: 5, name: 'Project Epsilon', price: 1800, weight: 90, lastModified: '2024-07-29', owner: 'Charlie Wilson' },
    testProject,
    {
      id: 'test-project-2',
      name: 'Bluth Home 2',
      price: 2000,
      weight: 80, 
      lastModified: '2024-07-30',
      designInfo: 'This is a test project design',
      owner: 'Maybe Funke',
      designInfo: 'This is another test project',
      screenshot: 'https://example.com/test-screenshot-2.jpg',
      stageHistory: [
        {id: 1, name: 'Planning', date: '2024-07-10'},
      ],
      customerInfo: 'Bob LobLaw',
      locationInfo: 'Test Location 2, Test City 2',
      archived: true,
      links: [],
    },
  ];

  export const testEntities = [
    {
      id: 'test-entity-1',
      name: 'MetalStream',
      email: 'metalStream@gmail.com',
      phone: '0000000000',
      address: 'Mineola, Tx',
      type: 0
    },
    {
      id: 'test-entity-2',
      name: 'SWM',
      email: 'swmetal@gmail.com',
      phone: '0000000000',
      address: 'Mineola, Tx',
      type: 1
    },
    {
      id: 'test-entity-3',
      name: 'Myers',
      email: 'meyers@gmail.com',
      phone: '1111111111',
      address: 'Huntsville, Tx',
      type: 2
    },
    {
      id: 'test-entity-4',
      name: 'Iron Brothers',
      email: 'ironbrothers@gmail.com',
      phone: '1111111111',
      address: 'Lindale, Tx',
      type: 1
    },
];