import * as THREE from 'three';

export default class ColorHelper{


    static getConstraintColorFromInt(val){
        let index = val%10;
        return this.getConstraintPalette()[index].hex;
    }

    static getConstraintPalette(){
        return [            
            {id: 0, hex:`ff0000`},
            {id: 1, hex:`fc4444`},
            {id: 2, hex:`fc6404`},
            {id: 3, hex:`fcd444`},            
            {id: 4, hex:`8cc43c`},
            {id: 5, hex:`029658`},
            {id: 6, hex:`1abc9c`},
            {id: 7, hex:`5bc0de`},
            {id: 8, hex:`6454ac`},
            {id: 9, hex:`fc8c84`},
        ]
    }

    static getRandomColor() {
        // Generate random RGB values between 0 and 1
        var r = Math.random();
        var g = Math.random();
        var b = Math.random();
      
        // Create and return the THREE.Color object
        return new THREE.Color(r, g, b);
      }

      
    static getColorByHex(hex, defVal = "FFFFFF"){
        let palette = this.getFullPalette();
        for(var c=0;c<palette.length;c++){
            if(palette[c].hex === hex)
                return palette[c];
        }
        return getColorByHex(defVal); // recursive call to default to a valid color
    }

    static getColorByColorId(id, defVal = "A0000-1106157318"){
        let palette = this.getFullPalette();
        for(var c=0;c<palette.length;c++){
            if(palette[c].id === id)
                return palette[c];
        }
        return getColorByColorId(defVal); // recursive call to default to a valid color
    }

    // static getColorByColorId(id) {
    //     let palette = this.getFullPalette();
    //     for (var c = 0; c < palette.length; c++) {
    //         if (palette[c].id === id)
    //             return palette[c];
    //     }
    //     return null;
    // }

    static bangToHex(bang){
        return bang.substr(1);
    }

    static tjsToHex(tjs){
        return bang.substr(2);
    }

    static hexToTjs(hex){
        return `0x${hex}`
    }

    static hexToBang(hex){
        return `#${hex}`
    }

        static getFullPalette(){
        return [            
            {id: `B0000-1106158207`,    name:`Brown`,               code:`BR`, hex:`4B3D2A`, classes:`general, panel, trim`,bang:`#4B3D2A`,tjs:`0x4B3D2A`},
            {id: `180000-1109185637`,   name:`Burnished Slate`,     code:`BS`, hex:`40433D`, classes:`general, panel, trim`,bang:`#40433D`,tjs:`0x40433D`},
            {id: `100000-1106606089`,   name:`Charcoal`,            code:`CH`, hex:`333333`, classes:`general, panel, trim`,bang:`#333333`,tjs:`0x333333`},
            {id: `190000-1110397371`,   name:`Colony Green`,        code:`CG`, hex:`627F70`, classes:`general, panel, trim`,bang:`#627F70`,tjs:`0x627F70`},            
            {id: `230000-1125427965`,   name:`Crimson`,             code:`CR`, hex:`FF3333`, classes:`general, panel, trim`,bang:`#FF3333`,tjs:`0xFF3333`},
            {id: `290000-1150993119`,   name:`Desert Sand`,         code:`DS`, hex:`88826E`, classes:`general, panel, trim`,bang:`#88826E`,tjs:`0x88826E`},
            {id: `210000-1123619118`,   name:`Fern Green`,          code:`FG`, hex:`4A4F44`, classes:`general, panel, trim`,bang:`#4A4F44`,tjs:`0x4A4F44`},
            {id: `260000-1129214940`,   name:`Gallery Blue`,        code:`GB`, hex:`2E465F`, classes:`general, panel, trim`,bang:`#2E465F`,tjs:`0x2E465F`},
            {id: `110000-1107183390`,   name:`Galvalume`,           code:`GL`, hex:`B9B9B9`, classes:`general, panel, trim`,bang:`#B9B9B9`,tjs:`0xB9B9B9`}, // this color is made up because in the database, it has the same hex (40433D) as burnished slate
            {id: `F0000-1106174911`,    name:`Gray`,                code:`GR`, hex:`9A928A`, classes:`general, panel, trim`,bang:`#9A928A`,tjs:`0x9A928A`},
            {id: `1D0000-1112805104`,   name:`Hawaiian Blue`,       code:`HB`, hex:`1E7498`, classes:`general, panel, trim`,bang:`#1E7498`,tjs:`0x1E7498`},
            {id: `90000-1106157308`,    name:`Hunter Green`,        code:`HG`, hex:`294922`, classes:`general, panel, trim`,bang:`#294922`,tjs:`0x294922`},
            {id: `D0000-1106158270`,    name:`Lt Stone`,            code:`LS`, hex:`D0CBA8`, classes:`general, panel, trim`,bang:`#D0CBA8`,tjs:`0xD0CBA8`},
            // {id: `80000044-1242676260`, name:`Poly-Gray`,           code:`PG`, hex:`9A928A`, classes:`general, panel, trim`,bang:`#9A928A`,tjs:`0x9A928A`},
            // {id: `80000043-1242676254`, name:`Poly-LtStone`,        code:`PL`, hex:`D0CBA8`, classes:`general, panel, trim`,bang:`#D0CBA8`,tjs:`0xD0CBA8`},
            // {id: `80000042-1242676246`, name:`Poly-Tan`,            code:`PT`, hex:`BBA373`, classes:`general, panel, trim`,bang:`#BBA373`,tjs:`0xBBA373`},
            // {id: `80000041-1242676237`, name:`Poly-White`,          code:`PW`, hex:`D8E7E9`, classes:`general, panel, trim`,bang:`#D8E7E9`,tjs:`0xD8E7E9`},
            {id: `E0000-1106161169`,    name:`Rustic Red`,          code:`RR`, hex:`AB3824`, classes:`general, panel, trim`,bang:`#AB3824`,tjs:`0xAB3824`},
            {id: `C0000-1106158223`,    name:`Tan`,                 code:`TN`, hex:`BBA373`, classes:`general, panel, trim`,bang:`#BBA373`,tjs:`0xBBA373`},
            {id: `A0000-1106157318`,    name: `White`,              code: `WH`, hex: `FFFFFF`, classes: `general, panel, trim`, bang: `#FFFFFF`, tjs:`0xFFFFFF`}, // // this color is made up. Change a long time ago and now hex #D8E7E9 throws an error in ColorChip for some reason
            // {id: `80000077-1280408425`, name:`Pre Galvanized`,      code:`GV`, hex:`333333`, classes:`general, panel, trim`,bang:`#333333`,tjs:`0x333333`},
            // {id: `80000078-1280408438`, name:`Red Oxide`,           code:`RO`, hex:`892614`, classes:`general`,bang:`#892614`,tjs:`0x892614`},
            // {id: `1E0000-1121276778`,   name:`Gold`,                code:`GD`, hex:`EDD940`, classes:`general, panel, trim`,bang:`#EDD940`,tjs:`0xEDD940`},
            {id: `80000080-1289334086`, name:`Copper Metallic`,     code:`CM`, hex:`F5AD38`, classes:`general, panel, trim`,bang:`#F5AD38`,tjs:`0xF5AD38`}, 
            {id: `80000036-1176479161`, name:`Black`,               code:`BL`, hex:`111111`, classes:`general, panel, trim`,bang:`#111111`,tjs:`0x111111`},
            // {id: `80000088-1310579230`, name:`Poly-Color`,          code:`PC`, hex:`FFFFFF`, classes:`general, panel, trim`,bang:`#FFFFFF`,tjs:`0xFFFFFF`},
            //{id: `80000087-1310579219`, name:`SMP-Color`,           code:`SC`, hex:`FFFFFF`, classes:`general, panel, trim`,bang:`#FFFFFF`,tjs:`0xFFFFFF`},
            // {id: `8000008A-1326140963`, name:`Secondary Galvalume`, code:`NG`, hex:`40433D`, classes:`general, panel, trim`,bang:`#40433D`,tjs:`0x40433D`},
            // {id: `80000098-1441372782`, name:`White-20yr`,          code:`AW`, hex:`D8E7E9`, classes:`general, panel, trim`,bang:`#D8E7E9`,tjs:`0xD8E7E9`},
            // {id: `80000099-1441372792`, name:`Lt Stone-20yr`,       code:`AL`, hex:`D0CBA8`, classes:`general, panel, trim`,bang:`#D0CBA8`,tjs:`0xD0CBA8`},
            // {id: `8000009A-1441372807`, name:`Gray-20yr`,           code:`AG`, hex:`9A928A`, classes:`general, panel, trim`,bang:`#9A928A`,tjs:`0x9A928A`},
            // {id: `8000009B-1441372816`, name:`Tan-20yr`,            code:`AT`, hex:`BBA373`, classes:`general, panel, trim`,bang:`#BBA373`,tjs:`0xBBA373`},
        ]
    }
}
