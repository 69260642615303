<template>
  <div id="login" class="login-container">
    
    <div class="login-card">
      <div>
        <img src="@/assets/images/SteelNinja_vertical_onWhite.png" width="90%" @click="login"/> 
      </div> 
      
      <div id="loginContainer" v-if="!$auth.loading" style="width:400px">
        <button v-if="!$auth.isAuthenticated" @click="login" class="login-button">
          Log in
        </button>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    targetUrl: {
      type: String,
      targetUrl: ''
    }
  },
  name: 'LoginView',
  methods: {
    login() {
      if(this.targetUrl){
        console.log('after login, redirecting to: ', this.targetUrl)
        sessionStorage.setItem('redirectUrl', this.targetUrl)
      }
        
      this.$auth.loginWithRedirect();
    },
  }
}
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-card {
  display:flex;
  flex-direction: row;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: center;
    align-items: center;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 100%;
  max-width: 500px;
  text-align: center;
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

.title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.welcome-message, .login-message {
  margin-bottom: 1.5rem;
  color: #666;
}

.login-button {
  background: black;
  border: none;
  color: white;
  padding: .75rem 1.5rem;
  font-size: 1rem;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  width:100%  
}

.login-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.login-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

@media (max-width: 480px) {
  .login-card {
    width: 90%;
    padding: 1.5rem;
  }

  .title {
    font-size: 2rem;
  }
}

#loginContainer{
  height:100%;
  background:white;
}

img{
  cursor: pointer;
}
</style>