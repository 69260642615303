import * as THREE from 'three';
import { CORE, rebuildTypes,impactTypes } from '../_spec.js'
import {Vector2,  Vector3 } from 'three';
import Grabject from './grabject.js'
import Door from './Door.js'
import OptionHelper from '../helpers/optionHelper.js'
import vHelper from '../helpers/vHelper.js'
import MemHelper from '../helpers/memHelper.js';
import TreeNode from '../helpers/TreeNode.js'
import UpdateHelper from '../helpers/UpdateHelper.js';
import TrimOpeningFace from '../3d/TrimOpeningFace.js';
import _3dDoorGlass from '../3d/DoorGlass.js'
import layerHelper from '../helpers/layerHelper.js';
import mathHelper from '../helpers/mathHelper.js';
export default class DoorCommGlass extends Door {
    constructor(design) {
        super(design);
        if(this.design.dir) 
            delete this.design.dir;
    }
    
    processImpactFromSelf(impact){

        impact.handled.push(this.design.id);
        switch(impact.change.name)
        {
            case impactTypes.openingResize:
                this.addRebuildNeeded(rebuildTypes.full);
                break;
            case impactTypes.commGlassDetail:
                this.addRebuildNeeded(rebuildTypes.full);
                break;
            case impactTypes.openingPosition:
                this.addRebuildNeeded(rebuildTypes.move);
                break;
        }
    }

    applyDesign(design){
        this.design.collided= design.collided,
        this.design.pos = new Vector3( design.pos.x, design.pos.y, design.pos.z),
        this.design.type = design.type,
        this.design.parent = {};
        this.design.parent.id = design.parent.id;
        this.design.dim= {
            width: design.dim.width,
            height: design.dim.height
        };
        this.design.deadbolt = design.deadbolt;
        this.design.selected= false;
        this.addRebuildNeeded(rebuildTypes.full);
    }

    defaultDesign(width = 6, deadboltPreference = true){
        return {
            collided : false,
            pos : new Vector3(0,(7*12)/2,0),
            parent: {
                type: 'frontSide'
            },
            type: CORE.components.doorCommGlass,
            id: -1,
            dim:{
                width: width * 12,
                height: 7 * 12 
            },
            selected:false,
            deadbolt: deadboltPreference
        }
    }

    getOptionsSpec(){
        return [
            OptionHelper.selection("alias","Size",impactTypes.commGlassDetail, undefined, [
                    {
                        value: 36,
                        text:"3x7"
                    },
                    {
                        value: 48,
                        text:"4x7"
                    },
                    {
                        value: 72,
                        text:"6x7"
                    }
                ],
                true,
            ()=>{//fnIn
                return this.design.dim.width;
            },    
            undefined,
            (v)=>{//fnChanged
                this.design.dim.width = v;
               
            }),
            OptionHelper.checkbox("deadbolt", "Deadbolt", impactTypes.commGlassDetail, true)
        ]
    }
    
    getOutlineMeshes(){
        return [this.outlineMesh];
    }

    init(design) {
        this.design = design;
        //throw "Must be implemented by subclass";
    }    

    updatePosition(center)
    {
        if(!DoorCommGlass.updatePosition(center, this.design))
            return;

        // move requires no rebuild (except on re-parentage)
        // this.addRebuildNeeded(rebuildTypes.move);
        return UpdateHelper.createImpact(this.design, impactTypes.openingPosition);
    }
    
    static updatePosition(center, design){
        let affected = false
        if(center.x !=0)
            affected = DoorCommGlass.updatePosX(design, center.x) || affected;
        return affected;
    }

    
    updateSize(input){
        throw 'walkin size grabject is not supported'
    }
    


    build() {
        // use the design to build the component
        this.group.position.copy(this.design.pos)
        this.group.name = this.getDescription();

        let dim = new THREE.Vector3(this.design.dim.width, this.design.dim.height, .1);

        if (dim.x == 72) {
            let singleDoorWidth = dim.x/2;
            let singleDoorDim = new THREE.Vector3(singleDoorWidth, dim.y, dim.z);

            let leftDoor = new _3dDoorGlass(CORE.preferences.des_levelOfDetail.value, singleDoorDim, CORE.materials.stainless, false);
            leftDoor.group.position.x = -singleDoorWidth/2;
            this.group.add(leftDoor.group)

            let rightDoor = new _3dDoorGlass(CORE.preferences.des_levelOfDetail.value, singleDoorDim, CORE.materials.stainless, this.design.deadbolt);
            rightDoor.group.position.x = singleDoorWidth/2;            
            rightDoor.group.scale.multiply(new THREE.Vector3(-1, 1, 1));
            //rightDoor.group.rotation.y = Math.PI;
            this.group.add(rightDoor.group);
            
        } else {
            let singleDoor = new _3dDoorGlass(CORE.preferences.des_levelOfDetail.value, dim, CORE.materials.stainless, this.design.deadbolt);
            singleDoor.group.name = "singleDoor subgroup for " + this.getDescription();
            this.group.add(singleDoor.group);
        }
        
        let dimTrim = new Vector3(2,2,1);
        let trim = new TrimOpeningFace(this.lod, dim, dimTrim, this.structureConfig.trimMaterials.walksAndWindows, false);
        
        trim.group.position.z+=.25;        
        
        this.group.add(trim.group);
        layerHelper.setGroup(trim.group, CORE.layers.doorsWalkin, true);
        layerHelper.enableLayer(trim.group, CORE.layers.quote);
        

        this.grabjectGroup = new THREE.Group();
        let parentComponentTypes = [
            CORE.components.bayEndLeft,
            CORE.components.bayEndRight,
            CORE.components.baySideBack,
            CORE.components.baySideFront
        ]
        new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(0,0,1), [CORE.indicators.leftRight], new THREE.Vector3(15, 15, 5), CORE.grabjects.position,parentComponentTypes);
        this.group.add(this.grabjectGroup);
        this.grabjectGroup.position.z += 1.1;
        this.showGrabjects(this.design.selected);

        this.group.rotation.y = this.rot;
        
        var geoPickDetection = new THREE.BoxGeometry(dim.x, dim.y, dim.z);
        geoPickDetection.name='door pick geo'
        this.meshPickDetection = new THREE.Mesh(geoPickDetection, CORE.materials.transparent);        
        this.meshPickDetection.visible=false;
        
        this.meshPickDetection.name = "pickDetectionMesh for " + this.getDescription();
        this.meshPickDetection.position.z += 1;
            
        this.meshPickDetection.userData = {
            type: this.design.type,
            id: this.design.id
        };
        this.group.add(this.meshPickDetection);
        
        this.setCollisionZone();

        this.outlineMesh = this.meshPickDetection;
        this.contextMenuObject = new THREE.Mesh()
        let trc = this.getTopRightCorner();
        this.contextMenuObject.position.set(trc.x, trc.y, trc.z);
        this.contextMenuObject.visible = false; // this
        this.group.add(this.contextMenuObject);

        /*
        var geoDoor = new THREE.BoxGeometry(dim.x, dim.y, 1);
        geoDoor.name='geoDoor'
        let meshDoor = new THREE.Mesh(geoDoor);        
        meshDoor.userData = this.design;
        meshDoor.visible=false;
        meshDoor.name = "walkdoor for " + this.getDescription();
        this.group.add(meshDoor);
        layerHelper.enableLayer(this.group,CORE.layers.quote);
        */
        this.built=true;
    }

    setCollisionZone(){
        this.group.updateMatrix();
        //this.group.updateMatrixWorld();
        if(!this.meshPickDetection)
            return;
        this.meshPickDetection.updateMatrix();
        this.meshPickDetection.updateWorldMatrix(true); // updates this and all ancestors so the collision zone is aligned.
        this.meshPickDetection.renderOrder=1;
        let zone = this.getCollisionZoneTemplate(this.meshPickDetection);
        zone.classes = [
            CORE.collisions.classes.window,
            CORE.collisions.classes.emptyFramedOpening,
            CORE.collisions.classes.louverVent,
            CORE.collisions.classes.doorWalkin3o7o,
            CORE.collisions.classes.doorWalkin4o7o,
            CORE.collisions.classes.doorWalkin6o7o,
            CORE.collisions.classes.doorRollup,
            CORE.collisions.classes.doorSliding,
            CORE.collisions.classes.doorCommGlass,
            CORE.collisions.classes.doorSectional,
            CORE.collisions.classes.doorHangar,
            CORE.collisions.classes.wideOpening,
        ];
        zone.data = {
            id: this.design.id,
            type: this.design.type
        };
        //zone.obb.position = new Vector3(0,0,0);
        this.meshPickDetection.userData.collisionZones = [zone];
    }
    
    // required by object interface
    getCollisionClass(){
        return CORE.collisions.classes.doorWalkin3o7o;
    }

    removePickingObject(){
        if(this.meshPickDetection)
            delete this.meshPickDetection
    }


    remove() {        
        MemHelper.removeAllChildren(this.group)
        MemHelper.dispose(this.group);
    }

    getDescription(){
        //return `Glass Door ${this.design.dim.width/12}' x 7'`;
        return `Glass Door ${mathHelper.inToFtIn(this.design.dim.width)} x 7'`;
    }

    getTypeDisplayName(){
        return `${this.design.dim.width/12}' Glass Door`;
    }
}
