import {CORE} from './constants.js'

export default class Dictionary{
    /*
    this class represents a dictionary with terms that are 'defined' various ways in various contexts.
    Intent for use is to store alternate names for the same thing
    */

    constructor(){

        this.terms = [];

        this.terms[CORE.projectState.canceled] = 'Canceled'; 
        this.terms[CORE.projectState.new] = 'New';
        this.terms[CORE.projectState.designed] = 'Designed';
        this.terms[CORE.projectState.engreviewRequested] = 'Engineering Review (Requested)';
        this.terms[CORE.projectState.engreviewDateSet] = 'Engineering Review (Date Set)';
        this.terms[CORE.projectState.engreviewInProgress] = 'Engineering Review (In Progress)';
        this.terms[CORE.projectState.pmreview] = 'Project Manager Review';
        this.terms[CORE.projectState.custreview] = 'Customer Review';
        this.terms[CORE.projectState.contracted] = 'Contracted';
        this.terms[CORE.projectState.complete] = 'Complete';
    }

    getTerm(key){
        if(!Number.isInteger(key))
            return '';

        let val = this.terms[key];

        if(val == -1)
            return ''

        return val
    }
}