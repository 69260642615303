import * as THREE  from 'three'
import layerHelper from '../helpers/layerHelper.js';
import { CORE } from '../_spec.js'
import _3dBase from "./Base";


export default class TrimOpeningFace extends _3dBase {
    constructor(lod, openingSize, dimTrimMaterial, trimMaterial, includeBottom=true, trimOutside=true){
        super(undefined,undefined,undefined,lod);
        
        let dimTrimOutside = openingSize.clone();
        // dimSize is the dimensions of the window opening
        // the window opening is the interior dimensions of the trim
        // use the dimensions of the trim material to generate exterior dimensions of the trim
        dimTrimOutside.x += trimOutside ? dimTrimMaterial.x*2 : 0;
        dimTrimOutside.y += trimOutside ? dimTrimMaterial.y*2 : 0;
        // buildPerimeter needs exterior dimensions
        this.group = this.build(dimTrimOutside,dimTrimMaterial,trimMaterial,includeBottom);    
        //this.group.add(trim);
    }


    build(dimSize, dimMaterial, material,includeBottom){
        if(this.isLowDetail())
            return this.build_LD(dimSize, dimMaterial, material,includeBottom);
        else
            return this.build_HD(dimSize, dimMaterial, material,includeBottom);
    }
    build_LD(dimSize, dimMaterial, material,includeBottom=true){
        // dimSize is the OUTSIDE dimensions
        let debugShape= false;
        // dimMaterial is 
        //  x: horizontal width of vertical sections
        //  y: vertical width of horizontal sections
        //  z: depth of all sections

        let halfDimSize = dimSize.clone().multiplyScalar(.5);
        let halfDimMaterial = dimMaterial.clone().multiplyScalar(.5);
        let gPerimeter = new THREE.Group();

        // Horizontal sections
        let geoRail = new THREE.PlaneBufferGeometry(dimSize.x, dimMaterial.y,1,1);
        geoRail.position = new THREE.Vector3(0, halfDimSize.y, 0);
        if(debugShape)
            material = new THREE.MeshBasicMaterial({color: 0xff0000});
        let mRailTop = new THREE.Mesh(geoRail, material)
        
        mRailTop.name = 'trimOpeningFace top'
        mRailTop.position.set(0, halfDimSize.y - halfDimMaterial.y, 0);
        gPerimeter.add(mRailTop);
        if(includeBottom)
        {
            
            let mRailBottom = new THREE.Mesh(geoRail, material)
            mRailBottom.name = 'trimOpeningFace bottom'
            mRailBottom.position.set(0, -halfDimSize.y + halfDimMaterial.y, 0)            
            gPerimeter.add(mRailBottom);
        }

        if(debugShape)
            material = new THREE.MeshBasicMaterial({color: 0x00ff00});
        //Vertical sections  
        this.geoStile = new THREE.PlaneBufferGeometry(dimMaterial.x, dimSize.y-2*dimMaterial.y, 1,1);
        let mStileLeft = new THREE.Mesh(this.geoStile, material)
        mStileLeft.name = 'trimOpeningFace left'
        mStileLeft.position.set(-halfDimSize.x + halfDimMaterial.x, 0, 0)
        let mStileRight = new THREE.Mesh(this.geoStile, material)
        mStileRight.name = 'trimOpeningFace right'
        mStileRight.position.set(halfDimSize.x - halfDimMaterial.x, 0, 0)                
        gPerimeter.add(mStileLeft);
        gPerimeter.add(mStileRight);        
        layerHelper.setGroup(gPerimeter, CORE.layers.walls, true);        
        //layerHelper.enableLayer(gPerimeter, CORE.layers.quote);
        return gPerimeter;
    }

    build_HD(dimSize, dimMaterial, material,includeBottom=true){
        // dimSize is the OUTSIDE dimensions
        
        // dimMaterial is 
        //  x: horizontal width of vertical sections
        //  y: vertical width of horizontal sections
        //  z: depth of all sections

        let halfDimSize = dimSize.clone().multiplyScalar(.5);
        let halfDimMaterial = dimMaterial.clone().multiplyScalar(.5);
        let gPerimeter = new THREE.Group();

        // Horizontal sections
        let geoRail = new THREE.BoxGeometry(dimSize.x, dimMaterial.y, dimMaterial.z);
        geoRail.position = new THREE.Vector3(0, halfDimSize.y, 0);
        let mRailTop = new THREE.Mesh(geoRail, material)
        mRailTop.name = 'trimOpeningFace top'
        mRailTop.position.set(0, halfDimSize.y - halfDimMaterial.y, 0);
        gPerimeter.add(mRailTop);
        if(includeBottom)
        {
            let mRailBottom = new THREE.Mesh(geoRail, material)
            mRailBottom.name = 'trimOpeningFace bottom'
            mRailBottom.position.set(0, -halfDimSize.y + halfDimMaterial.y, 0)            
            gPerimeter.add(mRailBottom);
        }

        //Vertical sections  
        this.geoStile = new THREE.BoxGeometry(dimMaterial.x, dimSize.y-2*dimMaterial.y, dimMaterial.z);
        let mStileLeft = new THREE.Mesh(this.geoStile, material)
        mStileLeft.name = 'trimOpeningFace left'
        mStileLeft.position.set(-halfDimSize.x + halfDimMaterial.x, 0, 0)
        let mStileRight = new THREE.Mesh(this.geoStile, material)
        mStileRight.name = 'trimOpeningFace right'
        mStileRight.position.set(halfDimSize.x - halfDimMaterial.x, 0, 0)                
        gPerimeter.add(mStileLeft);
        gPerimeter.add(mStileRight);        
        layerHelper.setGroup(gPerimeter, CORE.layers.walls, true);        
        //layerHelper.enableLayer(gPerimeter, CORE.layers.quote);
        return gPerimeter;
    }
}