
import Base from './Base'
import OptionHelper from '../helpers/optionHelper.js'
import * as THREE from 'three';
import {CORE,impactTypes, rebuildTypes } from '../_spec.js';
import BayEndLeft from './BayEndLeft.js'
import BayEndRight from './BayEndRight.js'
import BayBase from './BayBase.js'
import Util from '../utility.js';
import MemHelper from '../helpers/memHelper.js';
import vHelper from '../helpers/vHelper.js'
import UpdateHelper from '../helpers/UpdateHelper.js';
import TreeNode from '../helpers/TreeNode.js'

import BlueprintHelper from '../helpers/blueprintHelper.js'
import { Vector3 } from 'three';
import DesignManager from '../design';

import _3dColumnStraight from '../3d/ColumnStraight.js';
import _3dColumnC from '../3d/ColumnC.js';
import _3dColumnSquare from '../3d/ColumnSquare.js'
import _3dTrimEdgeVert from '../3d/TrimEdgeVert.js'
import _3dTrimCorner from '../3d/TrimCorner.js'
import _3dGirtHoriC from '../3d/GirtHoriC.js'
import layerHelper from '../helpers/layerHelper';
import materialHelper from '../helpers/materialHelper';
import DesignHelper from '../helpers/DesignHelper.js';

export default class EndSkirt extends Base{
/*
    Background:
        EndSkirts only exist on the left and right ends
        The skirts are made up of 1 or more walls
        Walls can be on the End (which vary in shape with the roof Type)
        Walls can be on the front side and back side
        Individual walls can be toggled on and off
        Once a skirt wall has been toggled on or off, that setting is kept in the design
        The skirts are built with the end facing the positive Z axis
        The skirts are rotated into place by their parent (Structure)
*/


    constructor(
        design,
        structureConfig, 
        length,
        width,
        height,
        frameType,
        distToNextFrameline,        
        trimMaterials, 
        endGirtHeights, 
        frontGirtHeights, 
        backGirtHeights, 
        wallColor,
        pitchRatio,
        beamColor){
        super(design);
        this.structureConfig = structureConfig;
        this.group.name = "CompGroup EndSkirt";        // component group
        this.length = length;
        this.width = width;
        this.height = height;
        this.frameType = frameType;
        this.distToNextFrameline = distToNextFrameline;
        this.pitchRatio = pitchRatio;
        this.trimMaterials = trimMaterials;
        this.wallColor = wallColor;
        this.pitchHeight = design.pitchHeight;
        this.roofType = design.roofType;
        this.beamColor = beamColor; // add this to the constructor!

        if(!design.frame)
            design.frame={}
        design.frame.height= this.height;    

        Util.merge(design,this.defaultDesign());
        
        let defaultHeightFt = (this.design.frame.height/12)-1;

        // assert values
        if(Util.isUndefined(this.design.openHeightOffset))
            // height in feet from foundation to one foot below eaveline
            this.design.openHeightOffset = defaultHeightFt; 

        // bound input
        
        let verticalPurlinHeightInches = 10; // this is a rough estimate of the vertical height of purlins (despite varying height at varying pitches)
        this.maxHeightFt = (this.design.frame.height-verticalPurlinHeightInches)/12;
        this.boundHeight()        
        this.calculateBottom();


        this.endGirtHeights = endGirtHeights
        this.frontGirtHeights =frontGirtHeights
        this.backGirtHeights = backGirtHeights;
        this.trims = [];
    }

    
    initRebuildHierarchy(){        
        this.rebuildTypeRoot = new TreeNode(null, rebuildTypes.full);        
        this.rebuildTypeRoot.addChildNode(rebuildTypes.wall);
        this.rebuildTypeRoot.addChildNode(rebuildTypes.trim);
    }


    migrate(design){
        super.migrate(design);

        

        switch(design.v)
        {
            case 1:
                //this.migrateTo2(design);
                break;
        }
    }

    migrateTo2(design){
        if(typeof design.openSupport === 'undefined')
            design.openSupport = CORE.endWallColumns.columnsToGround.value;
        design.v=2;
    }


    calculateBottom(){
        this.openHeightOffsetInches  = this.design.frame.height-(this.design.openHeightOffset*12);

        this.design.bottom = -(this.design.pitchHeight + this.openHeightOffsetInches);
    }


    static canEdit() {return true;}
    static canDelete() {return false;}
    static canCopy() {return false;}
    
    boundHeight(){
        if( this.design.openHeightOffset > this.maxHeightFt)
            this.design.openHeightOffset = this.maxHeightFt
    }

    detectImpact(impact){

        if(this.shouldProcessOwnImpact(impact, this.design.id))
            this.processImpactFromSelf(impact);

        switch(impact.change.name){
            case impactTypes.endSkirtHeight:
            default:
                this.addRebuildNeeded(rebuildTypes.full)
                break;
        }
    }

    processRebuild(type){

    
        switch(type){
                
        default:
            this.rebuildFull();
            break;
        }
    }

    rebuildFull(){
        this.remove();
        this.build();
    }

    getOptionsSpec(){
        let opts = [
            OptionHelper.numericUpDown("openHeightOffset","Height", impactTypes.endSkirtHeight, "ft.", CORE.skirt.dim.height.min, this.design.frame.height, 1,
            true, 
            //OptionHelper.numericUpDown("openHeightOffset","Height", impactTypes.endSkirtHeight, "ft.", CORE.skirt.dim.height.min, this.design.frame.height, 1, 
            undefined,
            undefined,
            (v)=>{//fnChanged
                this.design.openHeightOffset = v;
                this.boundHeight();
                this.calculateBottom();
            }),    
        ];


        
        let openSupport =  OptionHelper.selection("openSupport","Open Area Column", impactTypes.none, "", [
            {
                value: CORE.endWallColumns.columnsToGround.value,
                text:  CORE.endWallColumns.columnsToGround.name
            },
            {
                value: CORE.endWallColumns.columnsToHeader.value,
                text:  CORE.endWallColumns.columnsToHeader.name
            },
            {
                value: CORE.endWallColumns.backBrace.value,
                text:  CORE.endWallColumns.backBrace.name
            },
        ],
        true,
        ()=>{//fnIn
            return this.design.openSupport;
        },
        undefined,
        (v)=>{//fnChanged
            this.design.openSupport = v;
        }
        );

        opts.push(openSupport);

        // generate an option for front side and back side of each bay that's open
        for(var prop in this.design.walls)
            if(this.design.walls.hasOwnProperty(prop)){                
                let wall = this.design.walls[prop];
                if(wall.valid)
                    opts.push(this.getWallOption(wall));

            }
        return opts;
    }

    getWallOption(w){
        let opt =  OptionHelper.checkbox(`walls.${w.key}`,w.optionLabel, impactTypes.none, 
        true,
            ()=>{ //fnIn
                return this.design.walls[w.key].enabled;
            }, 
            undefined, //fnOut
            (v)=>{
                this.design.walls[w.key].enabled = v;
            });
        return opt;
    }

    getOutlineMeshes(){
        return this.outlineMeshes;            
    }

    static getWallKey(bay, side){
        // returns a unique identifier for a wall on a skirt
        // examples: leftEnd, rightEnd, frontSide_1, backSize_2
        if(side === CORE.sides.leftEnd || side === CORE.sides.rightEnd)
            return `${side}`;
        return `${side}_${bay.index}`;
    }

    static getWallLabel(type, bay, side){
        // returns a human readable label for an individual wall on the skirt
        if(side === CORE.sides.leftEnd) 
            return `Left End`;
        if(side === CORE.sides.rightEnd)
            return `Right End`;

        let sideName;
        if(side === CORE.sides.frontSide)
            sideName = 'Front'
        else
            sideName = 'Back';

        let bayName = bay.index+1;
        if(type === CORE.components.skirtRight)
            bayName = bay.index;
        
        return `${sideName} of bay ${bayName}`;
    }

    static addWallDesign(type, walls, bay, side){
        let key = this.getWallKey(bay, side);
        let optionLabel =  this.getWallLabel(type, bay, side);
        walls[key]= {
            key,
            optionLabel,
            bay,
            side,
            enabled: true,
            valid: true
        }
    }

    static setWallValidity(design, key, valid){
        design.walls[key].valid=valid;
    }

    static invalidateAllWalls(design){
        for(var prop in design.walls)
            if(design.walls.hasOwnProperty(prop))
                EndSkirt.setWallValidity(design, prop, false);            
    }


    static applyDesign(design, width, roofType, pitchHeight, openBays, wallColor){
        /*
        type: left/right
        width        
        frontHeight
        gabledHeight
        slopedHeight
        baySpacing
        top is assumed to be origin because the top is stationary relative to the structure
        bottom is calculated from heightG or heightS since the bottom is not stationary relative to the structure
        */

        // design needs to have an array of front/back/end walls, each with their own on/off
        
        let walls= {};

        // add a wall for end
        let end = CORE.sides.rightEnd;
        if(design.type === CORE.components.skirtLeft)
            end = CORE.sides.leftEnd;        
        this.addWallDesign(design.type, walls, undefined, end)

        let runningLength = 0;
        // foreach bayNumber
        openBays.forEach((bay)=>{
            bay.start = runningLength;
            runningLength+=bay.length;
            // add a frontSide wall
            this.addWallDesign(design.type, walls, bay, CORE.sides.frontSide)
            // add a backSide wall
            this.addWallDesign(design.type, walls, bay, CORE.sides.backSide)
        });
        
        design.width = width;
        design.roofType = roofType;
        design.pitchHeight = pitchHeight;
        design.walls = walls;
        design.wallColor = wallColor;
    }


    static updateDesign(design, width, roofType, pitchHeight, bays, wallColor){
        // in case there are now fewer options which means some existing options should not be shown (but we can remember them for later)
        EndSkirt.invalidateAllWalls(design);

        design.width = width;
        design.roofType = roofType;
        design.pitchHeight = pitchHeight;
        design.wallColor = wallColor;
        
        // wall for the end   
        let end = CORE.sides.rightEnd;
        if(design.type === CORE.components.skirtLeft)
            end = CORE.sides.leftEnd;
        // add the end wall
        EndSkirt.assertWall(design, undefined, end);
        
        // in case there are now more options which must be added
        let runningLength = 0;
        // front and back side walls
        bays.forEach((bay)=>{
            bay.start = runningLength;
            runningLength+=bay.length;
            EndSkirt.assertWall(design, bay, CORE.sides.frontSide);
            EndSkirt.assertWall(design, bay, CORE.sides.backSide);
            
        });
    }

    static assertWall(design, bay, side){
        let key = EndSkirt.getWallKey(bay, side);
        if(design.walls.hasOwnProperty(key))
        {
            design.walls[key].valid = true;
            if(design.walls[key].bay && bay){

                design.walls[key].bay.length = bay.length;
                design.walls[key].bay.start = bay.start;
            }else {
                design.walls[key].bay = bay;
            }


        }
        else
            EndSkirt.addWallDesign(design.type, design.walls, bay, side);
    }

    static getDesign(type, width, roofType, pitchHeight, openBays, wallColor){
        /*
        type: left/right
        width        
        frontHeight
        gabledHeight
        slopedHeight
        baySpacing
        top is assumed to be origin because the top is stationary relative to the structure
        bottom is calculated from heightG or heightS since the bottom is not stationary relative to the structure
        */

        // design needs to have an array of front/back/end walls, each with their own on/off
        
        let walls= {};

        // add a wall for end
        let end = CORE.sides.rightEnd;
        if(type === CORE.components.skirtLeft)
            end = CORE.sides.leftEnd;        
        this.addWallDesign(type, walls, undefined, end)

        let runningLength = 0;
        // foreach bayNumber
        openBays.forEach((bay)=>{
            bay.start = runningLength;
            runningLength+=bay.length;
            // add a frontSide wall
            this.addWallDesign(type, walls, bay, CORE.sides.frontSide)
            // add a backSide wall
            this.addWallDesign(type, walls, bay, CORE.sides.backSide)
        });

        /*
        // apply pre-existing wall settings
        if(oldDesign.walls){
            for(var key in oldDesign.walls){
                if(oldDesign.walls.hasOwnProperty(key) && walls.hasOwnProperty(key)){
                    walls[key].enabled = oldDesign.walls[key].enabled;
                }
            }
        }*/

        return {
            type, 
            width,
            roofType,
            pitchHeight,
            walls,
            wallColor, 
            openSupport: CORE.endWallColumns.columnsToHeader.value
        }
        /*
        oldDesign.type = type;
        oldDesign.width = width;
        oldDesign.roofType = roofType;
        oldDesign.pitchHeight = pitchHeight;
        oldDesign.walls = walls;
        oldDesign.wallColor = wallColor;
        oldDesign.openSupport = CORE.endWallColumns.columnsToHeader.value
        */
    }

build(){
    try{
    console.log('building '+this.getDescription());

        this.halfWidth = this.design.width/2
        

        let bayCount =0;
        for(let key in this.design.walls){
            let wall = this.design.walls[key];
            // if it's not the end wall, and it's a valid bay
            if(wall.bay && wall.valid)
                // count it
                bayCount++;
        }
        // halve it since we've counted fronts and backs
        bayCount/=2;
        
        // this is a set of walls
        // end wall will have 5 points
        // openBay walls will have 4 points
        // each bay has a front and back wall
        // same openHeight is applied to all walls

        // options are dynamically generated 
        // design needs to store the settings, from one (option change/build) to another

        // generate design, based on structure input
        // generate options from design
        // persist options changes in design
        // obey design when building
        this.outlineMeshes=[];
        this.builtWalls = [];

        let hasEndWall;
        let hasFrontWallAdjacentToEnd; 
        let hasBackWallAdjacentToEnd; 
        
        let frontHeightOffsetInches = this.openHeightOffsetInches; 
        let backHeightOffsetInches = this.getBackTrimHeight(); // depends on gable vs. slope

        for(var wallKey in this.design.walls){
            if(!this.design.walls.hasOwnProperty(wallKey))
                continue;
            let desWall = this.design.walls[wallKey];

            // if the wall is not valid (because the frame or design changed after configuration of an end skirt)
            // OR if the wall is not enabled,
            if(!desWall.valid || !desWall.enabled)
                // invalid:
                // means the sideWall position is valid based on the wall's bay vs. the # of openBays 
                // if there are 4 open bays and that is reduced to 2, there will be 4 invalid walls, whose settings
                // we want to hang onto

                // enabled:
                // means the wall (side or end) is toggled on

                // don't bother with this invalid or disabled wall
                continue;
            
            // left end and bay 0
            if(this.design.type === CORE.components.skirtLeft && desWall.bay && desWall.bay.numRelative === 1)
                // if front side
                if(desWall.side === CORE.sides.frontSide )
                    hasFrontWallAdjacentToEnd = true;
                // otherwise, if backside
                else if(desWall.side === CORE.sides.backSide)
                    hasBackWallAdjacentToEnd = true;

            // right end and last bay
            if(this.design.type === CORE.components.skirtRight && desWall.bay && desWall.bay.numRelative === 1)
                // if front side
                if(desWall.side === CORE.sides.frontSide )
                    hasFrontWallAdjacentToEnd = true;
                // otherwise, if backside
                else if(desWall.side === CORE.sides.backSide)
                    hasBackWallAdjacentToEnd = true;
            
            // track whether we need to build corner trim
            if(desWall.side === CORE.sides.leftEnd)
                hasEndWall = true;
            if(desWall.side === CORE.sides.rightEnd)
                hasEndWall = true; 
            
            let skirtWall = this.getWall(desWall);
            if(skirtWall){
                this.builtWalls.push(skirtWall);
// this clone() errors for  right now.
// I'm here trying to get it to work for main structures so I can apply it to porches
                //skirtWall.mesh.userData = null;  // this bypasses the issue, breaking other things, I feel sure.
                let outlineMesh = skirtWall.gWallPick.children[0].clone();
                outlineMesh.visible=false;
                outlineMesh.userData = {
                    id: this.design.id,
                    type: this.design.type
                };
                skirtWall.gWall.add(outlineMesh)
                outlineMesh.material = CORE.materials.transparent;
                outlineMesh.layers.set(CORE.layers.world)

                this.outlineMeshes.push(outlineMesh);
                
            }
        }



        // trim out enabled side walls, given all side walls are toggleable
        for(var wallKey in this.design.walls){
            if(!this.design.walls.hasOwnProperty(wallKey))
                continue;
            let desWall = this.design.walls[wallKey];

            // if the wall is not valid or an end wall
            if(!desWall.valid || !desWall.enabled || !desWall.bay)
                // don't bother with this invalid wall
                continue;

            let trimOutsideEdge = false;
            let trimInsideEdge = false;
            /////////////////////////////////////
            // check outside end of this side wall
            // if this is the outermost sidewall
            if(desWall.bay.numRelative == 1){
                if(!hasEndWall){
                    // trim to the end
                    trimOutsideEdge = true;
                }
            }
            else{
                // otherwise
                // get key for next sideWall over
               let key = EndSkirt.getWallKey({index:this.getNextOuterBayIndex(desWall.bay.index)}, desWall.side);
               let nextOuterSideWall = this.design.walls[key];

               trimOutsideEdge = nextOuterSideWall.valid && !nextOuterSideWall.enabled;
            }

            /////////////////////////////////////
            // check interior end of this side wall
            // if this is the innermost sidewall
            if(desWall.bay.numRelative < bayCount)
            { 
                
                // check the interior end of this sideWall
                let key = EndSkirt.getWallKey({index:this.getNextInnerBayIndex(desWall.bay.index)}, desWall.side);
                let nextInnerSideWall  = this.design.walls[key];
                trimInsideEdge = nextInnerSideWall.valid && !nextInnerSideWall.enabled;
            }

            if(trimInsideEdge)
                this.trimInsideEdge(desWall);

            if(trimOutsideEdge)
                this.trimOutsideEdge(desWall);
        }


        // this.group.add(this.group);

        // detect need for corner trim
        let needsFrontCornerTrim = hasEndWall && hasFrontWallAdjacentToEnd;
        let needsBackCornerTrim = hasEndWall && hasBackWallAdjacentToEnd;
        
        if(needsFrontCornerTrim){
            // front corner trim height is always the same
            let trimF = new _3dTrimCorner(frontHeightOffsetInches, this.trimMaterials.corner);
            this.group.add(trimF.group);
            //layerHelper.enableLayer(trimF.group, CORE.layers.quote);
            this.orientFrontTrim(trimF.group);
            this.trims.push(trimF);
        }
        else{
            if(hasEndWall)
            {
                // end wall front trim only
                let frontEdge = new _3dTrimEdgeVert(frontHeightOffsetInches, 4, .15, this.trimMaterials.corner)
                this.group.add(frontEdge.group);
                //layerHelper.enableLayer(frontEdge.group, CORE.layers.quote);
                if(this.design.type === CORE.components.skirtLeft)
                    frontEdge.group.position.x = (this.width/2);
                else
                    frontEdge.group.position.x = -(this.width/2);
                frontEdge.group.position.y = -(this.pitchHeight+frontHeightOffsetInches/2);
                frontEdge.group.position.z = .35;
                this.trims.push(frontEdge)
            }
        }

        if(needsBackCornerTrim){
            let trimB = new _3dTrimCorner(backHeightOffsetInches, this.trimMaterials.corner);
            this.group.add(trimB.group);
            //layerHelper.enableLayer(trimB.group, CORE.layers.quote);
            this.orientBackTrim(trimB.group)   
            this.trims.push(trimB)         
        }
        else{
            if(hasEndWall)
            {
                // end wall back trim only
                // end wall front trim only
                let backEdge = new _3dTrimEdgeVert(backHeightOffsetInches, 4, .15, this.trimMaterials.corner)
                this.group.add(backEdge.group);
                //layerHelper.enableLayer(backEdge.group, CORE.layers.quote);
                if(this.design.roofType=== CORE.roof.types.gable)
                    backEdge.group.position.y = -(this.pitchHeight+backHeightOffsetInches/2);
                else
                    backEdge.group.position.y = -(backHeightOffsetInches/2);
                    
                if(this.design.type === CORE.components.skirtLeft)
                    backEdge.group.position.x = -(this.width/2);                    
                else
                    backEdge.group.position.x = +(this.width/2);

                backEdge.group.position.z = .35;
                backEdge.group.rotation.y = Math.PI;
                this.trims.push(backEdge)
            }
        }
    }
        catch(e)
        {
            console.error('brokem',e)

        }
    }

    getTrimHeightForWall(desWall){
        if(desWall.side === CORE.sides.frontSide)        
            return this.openHeightOffsetInches; 
        else
            return this.getBackTrimHeight(); // depends on gable vs. slope
    }

    getTrimPositionForWall(desWall, height){
        
        if(desWall.side === CORE.sides.frontSide || this.roofType === CORE.roof.types.gable)
            return -(this.pitchHeight+height/2);
        else
            return -(height/2);
    }

    trimOutsideEdge(desWall){
        let x = (.05+this.width/2);
        if(this.design.type === CORE.components.skirtLeft){
            if(desWall.side === CORE.sides.backSide)
            x*=-1;
        }
        else{
            if(desWall.side === CORE.sides.frontSide)
            x*=-1;
        }
        

        let height = this.getTrimHeightForWall(desWall);

        let endEdge = new _3dTrimEdgeVert(height, 4, .15,  this.trimMaterials.corner)
        this.group.add(endEdge.group);
        endEdge.group.position.x = x

        endEdge.group.position.y = this.getTrimPositionForWall(desWall, height);
        endEdge.group.position.z = .35-desWall.bay.start;
        endEdge.group.rotation.y = -Math.PI/2;
        this.trims.push(endEdge)
    }

    trimInsideEdge(desWall){
        let x = (.05+this.width/2);
        if(desWall.side === CORE.sides.backSide)
            x*=-1;

        let height = this.getTrimHeightForWall(desWall);

        let endEdge = new _3dTrimEdgeVert(height, 4, .15, this.trimMaterials.corner)
        this.group.add(endEdge.group);
        endEdge.group.position.x = x
        endEdge.group.position.y = -(this.pitchHeight+height/2);
        endEdge.group.position.z = -(.35+desWall.bay.start+desWall.bay.length);
        endEdge.group.rotation.y = Math.PI/2;
        this.trims.push(endEdge)
    }


    buildEndSkirtWall(){
    
        if(this.design.baySpacing.mode === CORE.modes.baySpacing.auto)
            this.buildAutomaticSupportColumns(); // CompGroup checks out ok sometimes and not others
        else
            this.buildManualSupportColumns();
    }


    buildAutomaticSupportColumns(){
        this.columns = [];
        this.privateColumns = [];
        let columnPoints = [];

        // put columns on both sides of each overhead, open sides facing out.

        // TODO: centralize and/or correct this static 4 from standard flange
        // TODO: why is this full flange, but called halfColumnWidth?
        let halfColumnWidth = BlueprintHelper.getStandardFrameMaterialSize().flange;
        let defaultColumnPoints = BlueprintHelper.generateColumnPositions2(0, this.design.width, this.design.baySpacing.center, this.design.baySpacing.nonCenter, halfColumnWidth); //TODO: the halfColumnWidth parameter needs to be context sensitive
        defaultColumnPoints.forEach((dp)=>{
            columnPoints.push({
                required: false,
                source: 'default',
                pos: dp
            });
        })


        columnPoints.sort((a,b)=>{
            if(a.pos < b.pos)
                return -1;
            else 
                return 1;
        });

        columnPoints[0].required=true;
        columnPoints[columnPoints.length-1].required=true;

        try{
            this.addColumnsAtPoints(columnPoints);
        }catch(error){
            console.error("error adding column points: ",error);
        }
    }

    buildManualSupportColumns(){
        this.columns = [];
        this.privateColumns = [];
        let columnPoints = [];
        
        let componentColumnPoints = [];//super.getIntrabaySubcomponentColumns(); 
        columnPoints.push(...componentColumnPoints);
        // build wall columns here
        let halfColumnWidth = BlueprintHelper.getStandardFrameMaterialSize().flange;
        let points = BlueprintHelper.generateColumnPositions2(0, this.design.width, this.design.baySpacing.center, this.design.baySpacing.nonCenter, halfColumnWidth); //TODO: the halfColumnWidth parameter needs to be context sensitive
        
        points.forEach((p)=>{
            columnPoints.push(
                {
                    pos:p,
                    required:true,
                    source:'default' 
                }
            )
        });

        // sort the column positions in ascending order so the first and last can be tossed out
        columnPoints.sort((a,b)=>{
            if(a.pos < b.pos)
                return -1;
            else 
                return 1;
        });

        this.addColumnsAtPoints(columnPoints);
    }

    addColumnsAtPoints(columnPoints){
        let collisionZones;
        this.design.columnPositions = [];
        for(var ci = 0;ci<columnPoints.length;ci++){
            
            let columnPoint = columnPoints[ci];
            let columnPosAlongWall = columnPoint.pos;
            
            let side = columnPoint.side;
            let source = columnPoint.source;

            columnPoint.atSideWall = (ci === 0 || ci === columnPoints.length-1)
            
            // columns purely for overhead doors and windows are not structural (i.e. a porch can't tie into them)
            columnPoint.structural = (source !== CORE.components.doorRollup && 
                                    source !== CORE.components.window)

            // record all columnPositions for sharing with sub-components of the corresponding frameSide (i.e. porches)
            this.design.columnPositions.push(columnPoint);

            // skip the outer columns
            if(columnPoint.atSideWall)
                // outside columns (at front side wall or back side wall) are always built by frameBase            
                continue;

            // intermediate columns are always built by the wall
            if(this.design.baySpacing.mode === CORE.modes.baySpacing.auto)
                collisionZones = ci === 0 || ci === columnPoints.length-1;
            else
                collisionZones=columnPoint.source==='default';

            let z= -4.5
           
            // bottomPos is where the column bottom goes
            let bottomPos = new THREE.Vector3(columnPosAlongWall-this.design.width/2, 0, z);
            // y is the distance between the frameHeight and the roof line
            let y= BlueprintHelper.getColumnHeightAlongFrameLine(columnPosAlongWall, this.bp, 0, this.pitchRatio, this.roofType);
            // topPos is the actual position at the roofline
            let topPos = new THREE.Vector3(columnPosAlongWall, y-this.pitchHeight, z);
            let vertLengthY;
            switch(this.design.openSupport){                
                case CORE.endWallColumns.columnsToGround.value:
                    //relative pos y of 0 is at the peak 
                    //relative pos y of -pitchHeight is at frame height
                    //relative pos y of -pitchHeight - dimY is at foundation height
                    bottomPos.y = -(this.height +this.pitchHeight);    
                    vertLengthY = topPos.y-7;
                    break;
                case CORE.endWallColumns.columnsToHeader.value:
                case CORE.endWallColumns.backBrace.value:
                    bottomPos.y = -this.openHeightOffsetInches -this.pitchHeight;    
                    vertLengthY = (topPos.y - bottomPos.y)-7;                    
                    break;
            }

            let rot =0;
            let col;
                    

            //             
            if(this.design.lineType === CORE.frame.lineTypes.standard)
            {
                rot = 0; // open to the right
                // flip columns after the half-way point
                if(columnPosAlongWall>this.width/2)
                    rot = -Math.PI; // open to the left

                // TODO: relative: make sure the end skirt columns on both left and right ends are properly rotated, whatever that means
                let length = (topPos.y - bottomPos.y)-7;
                col = new _3dColumnC(CORE.preferences.lod, length, false, BlueprintHelper.getStandardFrameMaterialSize());
                col.group.position.copy(bottomPos.clone());
                col.group.rotation.y = rot;
                this.columns.push(col);
                if(this.design.openSupport === CORE.endWallColumns.backBrace.value){
                    
        //             //length depends on the height of the wall, position of the column, and the distance to the next frameline

        //                   |\
        //       vertLengthY | \ braceLength
        //                   |__\
        //                   Dist

        //                 vertLengthY is already known (top.Y - bottom.Y or top.Y - openHeight)
        //                 top.y  based on column position
        //                 vertLengthY is top.y, adjusted for open height to know total vertical run
        //                 solve for H

        //                 Solve for angle:
        //                 sohcahtoa
        //                 tan(theta) = o/a
        //                 atan(o/a) = theta
        //                 cos(theta) = a/h
                    

                    let backBraceAngleRadians = Math.atan(vertLengthY/this.distToNextFrameline);
                    let braceLength = this.distToNextFrameline / Math.cos(backBraceAngleRadians);
                    let columnSquareSideDim = 4;

                    let frameMaterial;
                    if(CORE.preferences.des_levelOfDetail.value === CORE.levelOfDetail.high)
                        frameMaterial = materialHelper.getSquareColumnFrameMaterial_HD(columnSquareSideDim, colHeight, this.beamColor)
                    else
                        frameMaterial=materialHelper.getFrameMaterial_LD(this.beamColor);

                    let brace = new _3dColumnSquare(braceLength, frameMaterial,columnSquareSideDim, CORE.layers.frame); // only ever square, per Adam on 2021.09.09 via Twist
                    let bracePos = new Vector3().copy(bottomPos);
                    bracePos.y+=3;
                    bracePos.z -=columnSquareSideDim/2;
                    brace.group.position.copy(bracPos.clone())
                    brace.group.rotation.y = 0;
                    brace.group.rotation.x = backBraceAngleRadians - Math.PI/2;
                    this.privateColumns.push(brace);
                }
            }
            else
            {
                // calculate column position
                // calculate column rotation
                // endWall can only be left or right end. No need to support the back side.

                rot = -Math.PI/2;
                if(bottomPos.x > 0){
                    rot = Math.PI/2;
                }            
                
                let length = (topPos.y - bottomPos.y)-7;
                
                col = new _3dColumnStraight(
                    CORE.preferences.des_levelOfDetail.value, 
                    length, 
                    false,
                    collisionZones, 
                    BlueprintHelper.getPostAndBeamFrameMaterialSize(),  
                    undefined, 
                    BlueprintHelper.pitchRatioToRadians(this.pitchRatio), 
                    this.frameType === CORE.frame.types.bolt
                    );
                col.group.position.copy(bottomPos.clone())
                col.group.rotation.y = rot;
                this.columns.push(col);

                if(this.design.openSupport === CORE.endWallColumns.backBrace.value){
                    let backBraceAngleRadians = Math.atan(vertLengthY/this.distToNextFrameline);
                    let braceLength = this.distToNextFrameline / Math.cos(backBraceAngleRadians);

                    let columnSquareSideDim = 4;
                    let frameMaterial;
                    if(CORE.preferences.des_levelOfDetail.value === CORE.levelOfDetail.high)
                        frameMaterial = materialHelper.getSquareColumnFrameMaterial_HD(columnSquareSideDim, colHeight, this.beamColor)
                    else
                        frameMaterial=materialHelper.getFrameMaterial_LD(this.beamColor);

                    let brace = new _3dColumnSquare(braceLength, frameMaterial, columnSquareSideDim, CORE.layers.frame); // only ever square, per Adam on 2021.09.09 via Twist
                    let bracePos = new Vector3().copy(bottomPos);
                    bracePos.y+=3;
                    bracePos.z -=columnSquareSideDim/2;
                    brace.group.position.copy(bracPos.clone())
                    brace.group.rotation.y = 0;
                    brace.group.rotation.x = backBraceAngleRadians - Math.PI/2;
                    this.privateColumns.push(brace);
                }                
            }                        
        }
    }
    
    getBackTrimHeight(){
        let backTrimHeight = this.openHeightOffsetInches
        if(this.design.roofType=== CORE.roof.types.slope)
            backTrimHeight = this.openHeightOffsetInches + this.design.pitchHeight
        return backTrimHeight;
    }

    getContextMenuPosition(){
        return new THREE.Vector3();
    }

    getGabledRoofEndWallPoints2D(bottomPosY){
        
        let points = [];
        points.push(new THREE.Vector2(-this.halfWidth,bottomPosY))
        points.push(new THREE.Vector2(-this.halfWidth,bottomPosY+this.openHeightOffsetInches))
        points.push(new THREE.Vector2(0,0))
        points.push(new THREE.Vector2(this.halfWidth,bottomPosY+this.openHeightOffsetInches))
        points.push(new THREE.Vector2(this.halfWidth,bottomPosY))
         return points;
    }

    getWall(wallDes){
        

        let height = this.openHeightOffsetInches;
        if(wallDes.side === CORE.sides.backSide)
            height += this.roofType == CORE.roof.types.gable?0:this.design.pitchHeight;

        let openHeight = this.height - height;

        let wallDesign = {
            components: [],
            height,
            color: this.wallColor,
            wainscot:{
                enabled: false,
                height: 0,
                color: '0x8f8f8f'
            },
            lineType: CORE.frame.lineTypes.standard,
            //this.design.bottom is the vertical length from the top of the peak to the bottom of the sheet (thus the negative)
            // what we need for the wall openHeightOffset is the distance from the top of the dimY down to the bottom of the sheet (+ value)
            baySpacing: this.design.baySpacing,
            openSupport: this.design.openSupport,
            openHeight
        }

        DesignHelper.addFunctions(wallDesign); // wall components requires these functions

        let wall;
        let EWLength = this.width;
        switch(wallDes.side){
            
            case CORE.sides.frontSide:
                wall = this.getFrontSideWall(wallDesign,wallDes, this.height)                
                this.group.add(wall.group);
                break;

            case CORE.sides.backSide:
                let backHeightMax=0;
                if(this.roofType === CORE.roof.types.slope) 
                    backHeightMax = this.height + this.pitchHeight;
                else
                    backHeightMax = this.height;

                wall = this.getBackSideWall(wallDesign, wallDes, backHeightMax)
                this.group.add(wall.group);
                break;

            
            case CORE.sides.leftEnd:
                
                let lewpos = new Vector3(-this.width/2,-(this.height+this.pitchHeight),.3);
                wall = new BayEndLeft(
                    wallDesign, 
                    this.structureConfig, 
                    EWLength,  
                    this.height,
                    this.trimMaterials,                    
                    undefined,
                    this.endGirtHeights, 
                    lewpos, 
                    0,
                    false, // wainscot
                    {enabled:false}, // collisions
                    false, // addToQuoteLayer
                    false, // allowDripTrim
                    false, // insulation
                    this.pitchRatio, // pitchRatio
                    this.design.roofType,
                    this.frameType,
                    this.distToNextFrameline, // distToNextFrameline
                    0) 
                    this.group.add(wall.group);
                    
                break;

            case CORE.sides.rightEnd:
                
                wall = new BayEndRight(
                    wallDesign, 
                    this.structureConfig, 
                    EWLength,
                    this.height,
                    this.trimMaterials, 
                    undefined, 
                    this.endGirtHeights, 
                    new Vector3(this.width/2,-(this.height+this.pitchHeight),.5),  
                    Math.PI, 
                    false,
                    {enabled:false},
                    false,
                    false,
                    false,
                    this.pitchRatio,
                    this.design.roofType,
                    this.frameType,
                    this.distToNextFrameline, // distToNextFrameline
                    0) // add to quote layer
                    this.group.add(wall.group);
                    
                break;
        }
        return wall;
    }

    remove(){
        this.group.remove(this.group);

        if(this.columns)
            this.columns.forEach((c)=>{
                c.remove();
            });

        if(this.privateColumns)
            this.privateColumns.forEach((c)=>{
                c.remove();
            })


        this.builtWalls.forEach((w)=>{
            w.remove(undefined, 'skirtWall'); //  specify context here so that the wall knows to remove picking meshes
        })
        this.builtWalls = [];

        // remove all trim pieces
        this.trims.forEach((trim)=>{
            if(trim && trim.group)
                this.group.remove(trim.group);    
        })

        MemHelper.removeAllChildren(this.group);
        this.trims = [];
    }

    getCollisionClass(){
        return CORE.collisions.classes.none;
    }

    getTypeDisplayName(){
        return `End Skirt`;
    }
    
    getDeleteImpactType(){
        return impactTypes.deleteEndSkirt;
    }

    getAddImpactType(){
        return impactTypes.addEndSkirt;
    }
}