<template>
  <div id="designer" tabindex="0" style='width:100%;height:100%'>
    
    <v-dialog transition="pop-out" />
    
    
    <div id="loading" class="overlayLoading" v-if="this.loading" >
      <div class="loadingHeader">
        {{loadingMessage}}
        <img class="loadingSpinner" :src="require('@/assets/images/ajax-spinner.gif')" />
      </div>
    </div>

    <div id="loadFailed" class="overlayErrored" v-if="this.errored" >
       <div class="loadingHeader" style='width:85%'>        
        {{this.error.message}}<br />
        <br />
        <div style='max-width: 1000px;margin:auto' v-if="!editable">
          <b>Design Incompatability Detected</b><br>
          The 3d Designer has been updated and cannot load this design at the moment - Sorry for the inconvenience. We're working to provide backwards compatibility. Thanks for your patience.
          To go back, click <b>Back.</b><br />  
          <div class="btn" style='display:inline-block; width:fit-content; margin-top:6px; padding:6px;' @click="close" >Back</div>      
        </div>

        <br />
        <!----<div style='max-width:1000px; margin:auto'>
          <b>{{name}} - {{specs}}</b><br />
          <img class="loadingSpinner" :src="$store.getters.error.image" alt="design image" style="max-width: 60vh; max-height: 60vh;" />
        </div>-->
      </div>
    </div>

<!-- HEADER MENU --> 
    <topMenu class="topMenuWrapper" ref="topMenu" 
    :name="name" 
    :branding="branding" 
    :specs="specs" 
    @close="promptClose" 
    @login="redirectLogin"
    v-bind:mode.sync="mode"/>

  <!-- CAMERA CONTROLS AND LAYER TRAY -->
  

    <div id="row2">
      <div id="panelLeft" class="panel">

<!-- SAVE, CANCEL, ETC -->
      
        <div class="designControl">
          <div id="flexBox" style="display:flex;justify-content: space-between;">
            <div class='btn save' :class="{active: showSaveResult || save.inProgress}" :disabled="save.inProgress" style='width:80px;display:inline-block' v-if="editable" @click="saveDesign">
                        <span v-if="!save.inProgress && !showSaveResult">Save</span>
                        <span v-if="save.inProgress" >Saving <img class="loadingSpinner" :src="require('@/assets/images/ajax-spinner.gif')" style='width:20px;display:inline;'/></span>
                        <span v-if="showSaveResult && !save.inProgress && save.success">Success ✔️</span>
                        <span v-if="showSaveResult && !save.inProgress && !save.success">Failed ❌</span>
            </div>            
          </div>
        </div>



        
<!-- TOOLS -->
<div class="lvl1Header">Tools:</div>
        <div class='toolMenu' v-if="advDesignMode && editable">

          <div style="display:inline-block" v-if="!isToolModalVisible">
            <div class='tools' >              
              <!-- <font-awesome-icon icon="fa-duotone fa-wrench" style="margin:5px;vertical-align: top;"/> -->
              <button class="btn tool" v-for="tool in tools" v-on:click="runTool(tool);">
                {{ tool.name }} 
              </button>
            </div>
          </div>

          <div class='' v-if="isToolModalVisible">
                <toolSkirt v-if="tool.current.id==='skirt'" :tool="tool.current"/>
                <toolWallHeight v-if="tool.current.id==='wallHeight'" :tool="tool.current"/>
                <toolInset v-if="tool.current.id==='inset'" :tool="tool.current"/>
          </div>
         
        </div>

<!-- COMPONENT TREE -->

        <VerticalPanelDivider>
          <template v-slot:top>
            <div class="lvl1Header">Building Details:</div>
            <Tree id="tree" ref="tree" />
          </template>
          <template v-slot:bottom v-if="showOptionsPanel">
              <div class="lvl1Header">Configuration:</div>
              <div class='advancedOptions'>
                <componentOptions />
              </div>
          </template>
        </VerticalPanelDivider>

        <div class='addOptions'>
              <componentOptions ref="options" v-if="show.addOptions"/>
            </div>
      </div>

      <div id="renderPane"  >

        
        <div id="objs" style="position:absolute;left:300px;"></div>
        <div id="tris" style="position:absolute;left:400px;"></div>
        <div id="verts" style="position:absolute;left:500px;"></div>

        <div v-if="true" class="overlayTextBackground fade" :class="{show: inCameraModeFirst, hide: !inCameraModeFirst}" >
          <div class="">
            
            Press ESC to show the cursor<br />
            Look around with the mouse.<br />
            Move around with W, A, S, D and arrow keys.<br />        
          </div>
        </div>

        
        <div class="poweredByLogo" v-if="!editable">
          <img class="loadingSpinner" :src="require('@/assets/images/powered_by_SW_36x100.png')" />
        </div>

      </div>

      <div id="panelRight" class="panel">

          <div class="lvl1Header">Visual:</div>
          <ViewControls id="viewControls"  />

            <template v-if='editable'>  

              <div class='trayVert' v-if="isInLayoutView" style="text-align: center;">
                <div class="lvl1Header">Layout:</div>
                <layoutControls :pAllowCancel="true"/>          
                
              </div>
              <div class='trayVert' v-if="inCameraModeOrbit" style="text-align: center;">
                <div class="lvl1Header">Additions:</div>
                <componentAdd id="addControls"/>
              </div>

            </template>
            
          </div> 

    </div>
    

    


      

      
    
    <!-- <sessionTimeoutWarning style='position:absolute' /> -->
    
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import {designModes, CORE, cameraModes, modes, initStage} from './js/_spec.js'
import TopMenu from './components/topMenu.vue'
import Tree from './components/tree.vue'

import verticalPanelDivider from './components/verticalPanelDivider.vue'
import Status from './components/status.vue'
import ContextMenu from './components/contextMenu.vue'
import DesignHelper from './js/helpers/DesignHelper.js'
import componentOptions from './components/componentOptions.vue'
import componentAdd from './components/componentAdd.vue'
import layoutControls from './components/layoutControls.vue'
import VisibilityToggle from './components/visibilityToggle.vue'
import sessionTimeoutWarning  from './components/sessionTimeoutWarning.vue'
import Dictionary from './js/helpers/Dictionary.js'

import toolSkirt from  './components/toolSkirt.vue'
import toolInset from  './components/toolInset.vue'
import toolWallHeight from './components/toolWallHeight.vue'
import ViewControls from './components/viewControls.vue'
import {designModule,c,m,v} from './../store/modules/designModule.js'
const maximumMobileWidth = 800;
import VModal from 'vue-js-modal'
import Vue from 'vue'
import VerticalPanelDivider from './components/verticalPanelDivider.vue'

Vue.use(VModal, {dialog:true});

let dictionary = new Dictionary();

export default {
  name: 'App',
  
  data: function(){
    return {               
      
      CORE,
    }
  },
  computed:{
    ...mapState('designModule',[
      'showSaveResult',
      'engRevRequired',
      'bqRef',
      'layers',
    ]),
    ...mapGetters('designModule', [
      'apiEndpoint',
      'loading',
      'error',
      'errored',
      'show',
      'tool',
      'tools',
      'showOptionsPanel',
      'inCameraModeFirst',
      'inCameraModeOrbit',
      'inCameraModeLayout',
      'branding',
      'isToolModalVisible',
      'loadingMessage',
      'name',
      'specs',
      'editable',
      'designMode',
      'unsavedChanges',
      'save',
      'initStage',
      'isInLayoutView',
      'view',
      'showQuote',
      'designerWarningShow',
      'shouldShowQuoteLayer',
      'shouldShowToggleLayers',
      'camera',
      'designerWarningTitle',
      'designerWarningMessage',
    ]),
   
    mode(){
      return this.designMode;      
    },
    layerFrame(){
      return this.view.layers.current.frame;
    },
    layers() {
        return this.view.layers.current;
    },
    showLayerArrowUp(){
      if(this.editable)
        return !this.show.layerTray;
      else
        return this.show.layerTray;
    },
    showLayerArrowDown(){
      if(this.editable)
        return this.show.layerTray;
      else
        return !this.show.layerTray;
    },    
    advDesignMode(){
      return true;
    },    
    pointerLocked(){
      return this.camera.pointerLocked;
    },
    isInToggleView(){
      return this.shouldShowToggleLayers;
    },
    isInQuoteView(){
      return this.shouldShowQuoteLayer;
    },
    showQuoting(){
        return this.showQuote;
    },
    showDesignerWarning() {
      return this.designerWarningShow;
    },
    warningTitle(){
      return this.designerWarningTitle; 
    },
    warningMessage() {
      return this.designerWarningMessage;
    }
  },
  components: {
    TopMenu,
    Tree,
    Status,
    ContextMenu,
    VisibilityToggle,
    componentOptions,
    componentAdd,
    layoutControls,
    sessionTimeoutWarning,
    
    toolSkirt,
    toolWallHeight,
    toolInset,
    ViewControls,
    VerticalPanelDivider
  },
  watch:{
  },
  created: function(){
    setTimeout(()=>{        
      this.showBetaWarning(); // commented because the v-dialog component is not imported or a dependency anymore for some reason
    }, 3 * 1000) // 10 sec
    
    this.setupState(); 
    //this.startAutoSaveTimer(); // this generates a lot of extra processing and emails that are not helpful
  },
  async mounted(){  

    console.log(this.$modal)
    // attempting to initialize vue data objects here won't work properly because of binding    
    //await this.init();
    await this.setInitStageAppMounted(this);
    console.log('before this.container?')
    //await this.setInitStageAppMounted', this);
    
  },
  methods: {
    
...mapMutations('designModule',[
      'collapseComponentTree',
      'saveInProgress',
      'saveSuccess',
      'updateLastSavedDesign',
      'updateCurrentDesign',
      'setEditingMode_View',
    ]),
    
    ...mapActions('designModule', [
      'setInitStageAppMounted',
      'runTool',
      'selectComponent',
      'switchToCamera',
      'cycleOrbitCamera',
      'saveDesign',
      ]),
    toggleLayerSelector(){
      this.show.layerTray=!this.show.layerTray;
    },
    useAdvDesignMode(){
      this.designMode = designModes.advanced;
    },
    useGuidedDesignMode(){
        this.designMode = designModes.guided;
    },
    async runTool(tool){
      await this.runTool(tool);
    },
    
    migrate(){
    },
    setupState(){
      let innerWidth = window.innerWidth
      if(innerWidth<maximumMobileWidth)
        this.collapseComponentTree();
    },
    
    startAutoSaveTimer(){
      // don't bother in dev
      if(window.location.host.includes('localhost'))
        return;
      if(this.editable===true)
        setTimeout(()=>{        
          this.saveDesign(false);
          this.startAutoSaveTimer();
        }, 30 * 60 * 1000) // 4 min * 60 sec/min * 1000 ms/sec
    },   
    showBetaWarning(){
      let hide = localStorage.getItem('betaHide2024.04.30');
      let show = hide!=='1';
      if(show)
      this.$modal.show('dialog', {
        title: 'Warning: 3D Designer (v3) is in Beta',
        text: `The 3D Designer is still in development. </br></br>
        <ul>
          <li>Saved designs may be deleted without warning.</li>
          <li>Quoted price and weight may change.</li>
          <li>Contact your sales rep for accurate pricing.</li>
        </ul>
            
        </br>
        Submit suggestions to: dev@metalstream.tech`,
        buttons: [
          {
            title: 'OK',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: `Don't show this again`,
            handler: () => {
              localStorage.setItem('betaHide2024.04.30', '1');
              this.$modal.hide('dialog')
            }
          },
        ]
      })
    },
    closeWarning() {
      this.designerWarningShow =false;
      this.designerWarningMessage='';
    }, 
    
    
    async delay(){
      return new Promise(resolve =>{
        console.log('fake save start');
        setTimeout(()=>{
          console.log('fake save end');
          resolve();
          
        }, 2000)
      });
      
    },
    promptClose(){
      if(this.unsavedChanges){
        this.$modal.show('dialog', {
          title: 'Warning: Unsaved Changes',
          text: `There are changes which have not be saved!`,          
          buttons: [
            {
              title: 'Save & Close',
              handler: async () => {

                this.$modal.hide('dialog')
                console.log('saving')
                let saveSuccess = await this.saveDesign();
                if(saveSuccess){
                  console.log('saved')
                  this.close();
                }
              }
            },
            {
              title: 'Discard & Close',
              handler: () => {
                this.$modal.hide('dialog')
                this.close();
              }
            },
            {
              title: `Cancel & stay here.`,
              handler: () => {
                this.$modal.hide('dialog')
              }
            },
            
          ]
        })
      }
      else{
        this.close();
      }
    },
    close(){
      if(this.editable){
        this.$router.back();
      }
      else
      {
        window.close();        
      }
    },
    redirectLogin() {
      // go to login page
      if (window.corejs) {
        let url = window.corejs.fnSiteGetUrl("login"); // get the url for the login page
        url += "?ReturnUrl=" + window.location.pathname + window.location.search; // tack on the current location into a ReturnUrl param so we get redirected back here after loggin in
        window.location = url;
      }

    },
    initLayers:function(){
      v.setLayersByName(this.layers.current)      
    },    
    getObjects: function(){    
      return m.getEditableComponents();     
    },
    // give the tree component everything and let it sort it out
    getObjectTreeData:function(){      
        return  m.getComponentTreeMenuData();
    },      
    viewMode: function(){
      this.setEditingMode_View()
    },
    getContext: function(){      
      return c.getContextMenuData();      
    },
    getContextMenuData: function(id){
      return c.getContextMenuDataForComponent(id);      
    },
    toggleLayer(layer){

      v.getViewContext().camera.layers.toggle(layer);
    },
    
    async useOrbitCamera(){
      // this is used simply to switch from another camera mode (first or layout) to orbit (no rotation)
      await this.switchToCamera(cameraModes.orbit);
    },
    async cycleOrbitCamera(){
      // this is used to iterate through three orbit modes (no rotation, slow rotation, fast rotation)
      await this.cycleOrbitCamera();
    },
    async useFirstPersonCamera(){
      await this.switchToCamera(cameraModes.first);
    },
    async useLayoutCamera(){
      await this.switchToCamera(cameraModes.layout);
    } ,       

  }
}
</script>

<style>

#designer {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  margin-top: 0px;
  width:100%;
  height:100%;
  display:flex;
  flex-direction: column;
}

#row2{
  background: black;
  display: flex;
  width: 100%;
  height: calc(100vh - 65px);
}

#panelLeft{  
  display: flex;
  /*flex-flow: column wrap;*/
  flex-direction: column;
  vertical-align: top;
  padding:2px; 
  border-right:solid 1px black;
  min-width: 15%;
  width: 15%;
  height: 100%;
  /* overflow: auto; */
}


#renderPane {
  height:100%;
  margin:0px;
  overflow: hidden;
  flex: 1;
  width: 75%;
}

#panelRight{  
  display: flex;
  /*flex-flow: column wrap;*/
  flex-direction: column;
  vertical-align: top;
  border-right:solid 1px black;
  width: 10%;
  height: 100%;
  /* overflow: auto; */
}

#tabContainer{
  background-color: white;
  position: absolute;
  top: 0px;
  left: 10px;
  white-space: nowrap;
  padding: 5px;
  border: 1px solid cornflowerblue;
  border-radius: 4px;
}

.designControl{
  margin-bottom: 2px; 
  display:block; 
}


#cameraModeTabs{
  display:flex;
  justify-content: center;
  width: 100%;
  border: 1px solid black;  
  color: white;
}

.cameraModeTab{
  background-color: #4c78b9;
  border: 1px solid gray;
  width: 100%;
  margin:0 1px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.cameraModeTab:hover{
  background-color: #5e92df;
}

.tabContentContainer{
  display: flex;
  flex-direction: column;
  background-color: darkgrey;
  border: 1px solid gray;
  /* justify-content: center; */
  align-items:center;
  overflow-y: auto;
}

.tabContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  margin: 3px;
  width: 98%;
}



#renderPane canvas{
  outline: none;

}


.floatTray{
  border: 2px gray solid;
  background: darkgray;
  opacity: 1;  
  padding: 4px;
  text-align: center;
  /* position: absolute; */
  width: 192px;  
  float:right;
}

.tray{
  /* display:inline-block; */
  /* position:absolute;    */
  /* border: solid 1px black;   */
  background-color:darkgrey;   
  
  opacity: 1;  
  padding: 4px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.brLayers{
  bottom: 10px;
  right: 10px
}

.rightAbs{  
  right:10px;
  float:right;
  border: 1px solid green;
  width:200px;
  margin-top: 100px;  
}

.addComps{
  /* top:350px; */
  margin-top:3px;
  right: -1px;
  width: 190px;
  /* transform: translate(0, -50%); */
}

.viewOpt{
  display:block;
  cursor:pointer;
  font-size: 13px;  
}

.viewOpt label{
  cursor:pointer;
}

.viewOpt:hover{
  background:lightgrey;
}

.trayHori{
  width:auto;
}

.trayVert{
  height:auto;
}

.trayItem{
  margin:4px;
  width:90%;
}

/* Dropdown Button */
.btn {
  background: #4c78b9;
  color: white;
  padding: 2px 5px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  border-radius: 3px;
}

.tabOption {
  color: #e5e4e4
}

.btn.cancel {
  background: #df0404;
}
.btn.cancel:hover{
  background: #f01919;
}

.btnGreen{
    background: green;
}

.btn:hover{
  background-color: #5e92df;
}

.btn.thin{
   padding: 2px 6px;  
}

.btn.active{  
  /*border-bottom: 2px solid white;*/
  color: white;  
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;    
  display: inline-block;
  width:100%;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  position: sticky;
  left: 15px;
  background-color: #f1f1f1;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 10px 10px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd}



.mainBtn{
  background: #6ea1ee;
  padding: 5px;
  font-size: 16px;
  border-radius: 4px;
  width: 80px;
  cursor:pointer;
}

.mainBtn:hover{
  background: lightblue;
}

.save{
/*  position:absolute;
  top: 400px;
  right: 10px;
  display:block;*/
  display: block;
    text-align: center;
}

.exit{
  position:absolute;
  top: 440px;
  right: 10px;
  display:block;
}

.wizard{
  bottom: 0px;
  height: auto;
  display:inline-block;  
  left: 5%;
  right: 5%
}

.guideCenter{
  display:block;
  margin:auto;
}

.guideControl{
  /* border-top: 2px solid gray; */
  border-radius: 3px;
  display: block;
  text-align: center;
  margin: auto;  
}


.overlayLoading{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 6;
  background: white;
}

.loadingHeader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.overlayErrored{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 6;
  background: white;
}

  .overlayWarning {
    padding: 12px;
    top: 80px;
    left: 33%;
    width:33%;
    position: absolute;
    border-radius: 12px;
    z-index: 25;
    background: lightsalmon;
  }
.warningbody {
    font-size: large;
    text-align: center;
    padding: 25px 5px 25px 5px;
}
.warningfooter {
  border-top: 1px solid black;
  text-align: center;
  padding-top: 12px;
}

#btnCloseWarning {
  width: 18%;
  line-height:25px;
}

.loadingSpinner{
  text-align: center;
  margin: auto;
}

.poweredByLogo{
  left: 5px;
  bottom: 5px;
  background: gray;    
  position: absolute;    
  border-radius: 5px;
  padding: 4px;
}

.addOptions{
  position:absolute;
  top: 40%; /* slightly above center so it's not in the way; matches guideOptions*/
  left: 50%;
  transform: translate(-50%, -50%);
}

div.advancedOptions{
  /* margin-left: 5px; */
  /* display:inline-block; */
  min-width:200px;  
  /* position: absolute; */
  width: 100%;
  height: 100%;
}

.fade{
  transition: opacity 1s, display 3s
}

.overlayTextBackground{
  background-color: rgba(186, 186, 186, 0.7);
    position: absolute;
  top: 80px;
  right: 60px;
  transform: translate(-50%, 0);
  padding: 10px;
  border-radius: 4px;
  
}

.hide{
  opacity: 0%
}

.show {
  opacity: 100%
}

.orbit{
    width: 35px;
    height: 8px;
    border-radius: 50%;
    border: 2px solid white;
    position: relative;
    left: 1px;
    top: 14px;
    z-index: 0;
    border-top: none;
}

.selected{
  background: #5e92df;
  /*border: 1px solid white;*/
}

button.selected:hover{
  background: #5e92df
}

.subheader {
  font-weight:bold; 
  font-size: 14px;
  margin: 5px;
}

button[disabled] {
  background:gray ;
  color: darkgrey;
}

.label3d {
				color: #FFF;
				font-family: sans-serif;
				padding: 2px;
				background: rgba( 0, 0, 0, .6 );
			}

      #tree{
        height:100%;
      }

.toolMenu{
} 


.tools{
  display:flex;
  justify-content: space-between;
} 
.tool{
  margin: 3px;
}   

.vue-dialog-content ul{
  list-style:circle;
  margin-left: 20px;
}

#viewControls{
}

#addControls{

}

.lvl2Header{
  font-weight: bold;
  font-size: 12px;
  padding:0px;
  text-align: left;
}

 /* .lvl1Header{
  background: rgb(2,0,36);
  background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(95,131,152,1) 0%, rgba(33,127,232,1) 31%, rgba(37,106,147,1) 100%, rgba(0,212,255,1) 100%);
  color: white;
  font-weight: bold;
  font-size: 12px;
  padding: 0px 3px ;
  text-align: left;
}  */

 .lvl1Header
{
  font-size: 0px;
  border-bottom:1px solid rgb(61, 61, 61) ;

}

.panel{
  background-color: darkgray;
}

.subPanel{
  background-color: darkgray;
  /* border-top: solid 1px rgb(60, 60, 60);; */
  border-bottom: solid 1px rgb(60, 60, 60);;
}

.noTextSelection{
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
}

</style>
