import { faRotate } from '@fortawesome/pro-duotone-svg-icons';
import Util from './utility.js'
import * as THREE from 'three';

// this is the place to define all 
// constants
// constraints
// rarely-changed variables
 

    // These are constants throughout the designer 
    // that do not depend on what is built in the designer
    // everything is in inches!
    export let CORE = {
        //Convert: Convert,
        currentVersion:3,
        levelOfDetail: {
            low: 'low',
            high: 'high'
        },
        nullComponentId: 3,
        materials :{
            transparent: new THREE.MeshStandardMaterial({ color: 0x000000,  side: THREE.DoubleSide, opacity:0, transparent:true, wireframe: false}),
            debug: new THREE.MeshBasicMaterial({ color: 0x3333ff,  side: THREE.DoubleSide, opacity:.5, transparent:true, wireframe: false}),
            debug2: new THREE.MeshBasicMaterial({ color: 0xff3333,  side: THREE.DoubleSide, opacity:.5, transparent:true, wireframe: false}),
            bolt: new THREE.MeshLambertMaterial({ color: 0x888888, wireframe: false}),
            stainless: new THREE.MeshStandardMaterial({ 
                color: 0xEEEEEE,
                metalness:1,
                roughness:0,                
                emissive: Number(0x54535B),
                emissiveIntensity: 1 }),
            //frame: this.matFrame, //new THREE.MeshLambertMaterial({ color: CORE.frame.color, wireframe: false}),
            //frameWhite: this.matFrameWhite,
            trim: new THREE.MeshPhongMaterial( {  wireframe: false} ),
            trims:{
                gutter: new THREE.MeshPhongMaterial( {  wireframe: false} ),
                downspout: new THREE.MeshPhongMaterial( {  wireframe: false} ),
                rake: new THREE.MeshPhongMaterial( {  wireframe: false} ),
                corner: new THREE.MeshPhongMaterial( {  wireframe: false} ),
                door: new THREE.MeshPhongMaterial( {  wireframe: false} ),
                window: new THREE.MeshPhongMaterial( {  wireframe: false} )
            }
        },
        debug:{
            pickingMeshes: false
        },
        dir:{            
            rel:{
                front:0,
                right:1,
                back:2,
                left:3,
                in:4,
                up:5,
                down:6
            }
        },
        steel: {
            thickness_in: .75,
            outside_in: 8,
            inside_in: 8
        },
        girts:{
            four:'four',
            seven:'seven',
            fourAndSeven:'fourAndSeven'
        },
        endWallColumns: {
            columnsToGround: {                
                name: 'Columns to Ground',
                value: 'CTG'
            },
            columnsToHeader: {                
                name: 'Columns to Header',
                value: 'CTH'
            },
            backBrace: {                
                name: 'Back Brace',
                value: 'BB'
            },
            getByVal: (v) => {
                switch(v){
                    case columnsToGround.value: return CORE.endWallColumns.columnsToGround;
                    case columnsToHeader.value: return CORE.columnsToHeader.columnsToGround;
                    case backBrace.value: return CORE.backBrace.columnsToGround;
                }
            }
        },
        frame: {
            redOxideColor: 0x632525,// hex = 6497573 dec; 0x995555 with texture//0x663333 // 0x331919             
            galvanizedColor: 0xD6D6D6,//0xB4B2B5,//0x333333,
            types:{
                bolt: 'bolt',
                weld: 'weld',
                weldPlus: 'weldPlus'
            },
            shapes:{
                straight: 'straight',
                tapered: 'tapered'
            },
            dim:{
                width:{
                    min: Util.Convert.ftToIn(20),
                    max: Util.Convert.ftToIn(100) // 100
                },
                length:{
                    min: Util.Convert.ftToIn(10),
                    max: Util.Convert.ftToIn(300) // 300
                },
                height:{
                    min: Util.Convert.ftToIn(7),
                    max: Util.Convert.ftToIn(20)
                }
            },
            side: {
                spacing:{
                    equalBay:{
                        min: 10,
                        max: 28
                    },
                    endBay:{
                        min: 5,
                        max: 28
                    }
                }
            },
            end:{
              spacing:{
                  center:{
                    min: 0,
                    max: 27,
                    default: 20
                  },
                  nonCenter:{
                    min: 5,
                    max: 27,
                    default: 20
                  }                  
              }
            },
            lineTypes:{
                standard: 'standard', //cold-formed
                postAndBeam: 'postAndBeam',
                main: 'main'
            }
        },
        roof:{
            purlin:{
                dim:{
                    height: 8,
                    thickness: .25,
                    width: 3.5
                },
                spacing:{
                    min: 60 + 1 // 5' + 1"
                },
                types:{
                    C: 'C',
                    Z: 'Z'
                }
            },
            types:{
                gable: 'gable',
                slope: 'slope'
            },
            frameTypes:{
                flush: 'flush',
                bypass: 'bypass'
            }
        },
        skirt:{
            dim:{
                height:{
                    min: 0,
                } 
            }
        },
        foundation:{
            height: 6
        },        
        corners:{
            FR:'FR',
            FL:'FL',
            BR:'BR',
            BL:'BL'
        },
        partyWallOwner:{
            neither: 0
        },
        sides:{
            frontSide: 'frontSide',
            backSide: 'backSide',
            leftEnd: 'leftEnd',
            rightEnd: 'rightEnd',
            getName: (side)=>{
                switch(side){
                    case CORE.sides.frontSide: return 'Front Side'
                    case CORE.sides.backSide: return 'Back Side'
                    case CORE.sides.leftEnd: return 'Left End'
                    case CORE.sides.rightEnd: return 'Right End'
                }
            }
        },
        cornerTrimOrientations:{
            untrimmed: 'untrimmed',
            insideLeft: 'insideLeft',
            insideRight: 'insideRight',
            insideFlipped: 'insideFlipped',
            outside: 'outside'
        },
        interpolatedPointDirections:{
            left: 'left',
            right: 'right',
            diagonal: 'diagonal',
            insideLeft:  'insideLeft',
            insideRight:  'insideRight',
            outsideLeft: 'outsideLeft',
            outsideRight: 'outsideRight'
        },
        components:{
            null: 'null',
            design: 'design',
            system: 'system',
            foundation: 'foundation',
            structure: 'structure', // the only type of primary structure
            frame: 'frame',
            frameSide: 'frameSide',
            roof: 'roof',
            ground: 'ground',// not used because there's one and it's not interactive     
            cupola: 'cupola',           
            doorWalkin3o7o: 'doorWalkin3o7o',
            doorWalkin4o7o: 'doorWalkin4o7o',
            doorWalkin6o7o: 'doorWalkin6o7o',
            doorCommGlass: 'doorCommGlass',
            doorOverhead: 'doorOverhead', // moved to doorRollup, left here temporarily for backwards compatability
            doorRollup: 'doorRollup',
            doorSectional: 'doorSectional',
            doorSliding: 'doorSliding',
            doorHangar: 'doorHangar',
            window: 'window',
            emptyFramedOpening: 'emptyFramedOpening',
            louverVent: 'louverVent',
            fsw: 'fsw',
            bsw: 'bsw',
            lew: 'lew',
            rew: 'rew',
            tvp: 'tvp', // transverse partition
            wallEnd: 'wallEnd', // must be made generic
            wallSide: 'wallSide', // must be made generic
            roofPanel: 'roofPanel', //not used yet
            purlin: 'purlin',
            girt: 'girt',
            grabject: 'grabject',
            porch: 'porch', // a type of secondary structure with no walls
            roofRidge: 'roofRidge',
            skirtLeft: 'skirtLeft',
            skirtRight: 'skirtRight',
            appearance: 'appearance',
            options: 'options',
            jobspecs: 'jobSpecs',            
            wideOpening: 'wideOpening',
            model: 'model',
            bayEndLeft: 'bayEndLeft',
            bayEndRight: 'bayEndRight',
            baySideFront: 'baySideFront',
            baySideBack: 'baySideBack',
            leanTo: 'leanTo' // a type of secondary structure with walls
        },
        layers:{
            world: 0,
            walls: 1,
            roof: 3,
            frame: 4,
            windows: 5,
            doorsWalkin: 6,
            doorsOverhead: 7,
            dimensions: 8,
            openingDimensions: 9,
            quote: 10,
            constraint: 11,
            buildingLabels: 12
        },
        colors:{
            invalidObject: 0xFF000,
            grabject:{
                inactive: 0x5555ff,
                active: 0x00FF00
            }
        },
        collisions:{
            classes:{
                window:'window',
                emptyFramedOpening: 'emptyFramedOpening',
                louverVent: 'louverVent',
                doorWalkin3o7o:'doorWalkin3o7o',
                doorWalkin4o7o:'doorWalkin4o7o',
                doorWalkin6o7o:'doorWalkin6o7o',
                doorRollup:'doorRollup',
                doorSectional: 'doorSectional',
                doorSliding: 'doorSliding',
                doorCommGlass: 'doorCommGlass',
                doorHangar: 'doorHangar',
                wideOpening: 'wideOpening',
                cupola: 'cupola',
                none:'none'
            }
        },
        indicators:{
            sphere:'sphere',
            up:'up',
            down:'down',
            left:'left',
            right:'right',
            in:'in',
            out:'out',
            topLeft:'topleft',
            topRight:'topright',
            bottomLeft:'bottomLeft',
            bottomRight:'bottomRight',
            leftRight: 'leftRight',
            upDown: 'upDown',
            inOut: 'inOut',
            upDownLeftRight: 'upDownLeftRight',
            inOutLeftRight: 'inOutLeftRight',
            rotation: 'rotation'
        },
        grabjects:{
            topLeft:'topLeft', 
            top:'top', 
            topRight:'topRight', 
            right:'right', 
            bottomRight:'bottomRight',
            bottom:'bottom',
            bottomLeft:'bottomLeft',
            left:'left',
            position:'position',
            pitch:'pitch',
            rotation:'rotation'
        },
        updates:{
            blueprint:'blueprint',
            parent:'parent',
            self: 'self',
            none: 'none',
        },
        modes:{
            trimColor:{
                single:'single',
                separate:'separate'
            },
            baySpacing:{
                auto: 'auto',
                manual: 'manual'
            },
        },
        window:{
            styles:{
                picture: 'picture',
                doubleHung: 'doubleHung'
            }
        },
        insulation:{            
            insulband:{
                none:{                    
                    value: 'Add Insul-Band',
                    text:'Add Insul-Band'
                },
                R30: {                    
                    value:'Insul-Band-R30',
                    text:'Insul-Band-R30',
                },
                R35:{                 
                    value:'Insul-Band-R35',
                    text:'Insul-Band-R35',
                }
            },
            standard:{
                none:{
                    value: 'None',
                    text: `None`
                },
                VR3:{
                    value: 'VR.3_(R_10)',
                    text: `VR.3(R_10)`
                },
                VR4:{
                    value: 'VR.4_(R_13)',
                    text: `VR.4(R_13)`
                },
                VR6:{
                    value: 'VR.6_(R_19)',
                    text: 'VR.6(R_19)'
                }
            }
        },
        doorSectional: {
            styles: {
                regular: 'regular',
                lightA: 'lightA',
                lightB: 'lightB'
            }
        }
    }


export let cameraModes = {
    orbit: 'orbit',
    free: 'free',
    first: 'first'    ,
    layout: 'layout'
    }
    
export let editingModes={
    view: 'view',
    edit: 'edit',
    editGrabject: 'editGrabject'
  }

export let modes={
    viewEdit: 'viewEdit',
    demo: 'demo',
    sandbox: 'code',
}

export let  initStage={    
    loading: 'loading', // starting state
    appMounted: 'appMounted', // vue app is mounted 
    loaded: 'loaded', // design and settings is loaded
    errored: 'errored'// not like this =(
  }

export let designModes={
    guided: 'guided',
    advanced: 'advanced'
  }

export let rakeTrimStyles={
    rake: 'rake',
    none: 'none'
}

export let eaveTrimStyles={
    gutter: 'gutter',
    angle: 'angle'
}

export let inputTypes={
    grabject: 'grabject',
    option: 'option'
}

export let rebuildTypes={
    none: 'none', // don't rebuild
    building: 'building',
    full: 'full', // everything    
    dynamicOnly: 'dynamicOnly', // walls use this to quickly rebuild holey walls

    length: 'length', 
    width: 'width',
    pitch: 'pitch',
    height: 'height',
    footprint: 'footprint',
    roof: 'roof',
    wall: 'wall',
    trim: 'trim',
    
    lew: 'leftEnd', // entire left end for structure (frame, wall)
    rew: 'rightEnd', // entire right end for structure (frame, wall)

    move: 'move', // don't rebuild, just move
    model: 'model',
    name: 'name'
}

export let impactTypes={
    // these should be specific so 
    // A) they are clear what's been impacted
    // B) one is not used in multiple components
    // C) the component that does use them can remove/rebuild only what absolutely needs to be re-drawn
    none: 'none',
    newComponent: 'newComponent',
    deleteStructure: 'deleteStructure',
    deletePorch: 'deletePorch',
    addPorch: 'addPorch',
    deleteLeanTo: 'deleteLeanTo',
    addLeanTo: 'addLeanTo',
    addOpening: 'addOpening',
    deleteOpening: 'deleteOpening',
    addEndSkirt: 'addEndSkirt',
    deleteEndSkirt: 'deleteEndSkirt',
    deleteProp: 'deleteProp',
    deleteCupola: 'deleteCupola',
    deletePartition: 'deletePartition',
    
    porchWidth: 'porchWidth',
    porchWrap: 'porchWrap',
    porchLength: 'porchLength',
    porchHeight: 'porchHeight',
    porchPitch: 'porchPitch',
    porchRoofFrameType: 'porchRoofFrameType',
    porchBack: 'porchBack', // used by porch when back wall is toggled on/off
    secondaryStructureConstraintChange: 'secondaryStructureConstraintChange',

    leanToWidth: 'leanToWidth',
    leanToLength: 'leanToLength',
    leanToHeight: 'leanToHeight',
    leanToPitch: 'leanToPitch',
    leanToRoofFrameType: 'leanToRoofFrameType',
    leanToBack: 'leanToBack', // used by leanTo when back wall is toggled on/off

    openingResize: 'openingResize',
    openingPosition: 'openingPosition',
    
    colorRoof: 'colorRoof',
    colorWall: 'colorWall',
    colorWainscot: 'colorWainscot',
    colorModeTrim: 'colorModeTrim',
    colorTrim: 'colorTrim',
    colorTrimEaveAndRake: 'colorTrimEaveAndRake',
    colorTrimDownspout: 'colorTrimDownspout',
    colorTrimCornerAndBase: 'colorTrimCornerAndBase',
    colorTrimDoor: 'colorTrimDoor',
    colorTrimWalksAndWindows: 'colorTrimWalksAndWindows',

    endSkirtHeight: 'endSkirtHeight',

    optionsGutter: 'optionsGutter',
    optionsDripTrim: 'optionsDripTrim',
    optionsWrapTubePorchCols: 'optionsWrapTubePorchCols',
    optionsWrapTubeLeanToCols: 'optionsWrapTubeLeanToCols',
    optionsTouchUpPaint: 'optionsTouchUpPaint',

    //optionsTrimPackagePremium: 'optionsTrimPackagePremium',
    optionsGalvanizedBeams: 'optionsGalvanizedBeams',
    optionsGalvanizedPurlins: 'optionsGalvanizedPurlins',    
    
    optionsInsulationWall: 'optionsInsulationWall',
    optionsInsulationRoof: 'optionsInsulationRoof',
    optionsInsulationEnergySaverFramelineStart: 'optionsInsulationEnergySaverFramelineStart',
    optionsInsulationEnergySaverFramelineStop: 'optionsInsulationEnergySaverFramelineStop',
    optionsInsulationChickenWire: 'optionsInsulationChickenWire',
    optionsJambCounterFlashing: 'optionsJambCounterFlashing',
    optionsOhJambLiner: 'optionsOhJambLiner',
    optionsTackyTapeRoof: 'optionsTackyTapeRoof',
    optionsPlusGirtsCTL: 'optionsPlusGirtsCTL',

    optionsRoofExtLeft: 'optionsRoofExtLeft',
    optionsRoofExtRight: 'optionsRoofExtRight',
    optionsRoofExtFront: 'optionsRoofExtFront',
    optionsRoofExtBack: 'optionsRoofExtBack',

    structureMainFrame: 'structureMainFrame',
    structureOpenBays: 'structureOpenBays',
    structureFrameType: 'structureFrameType',
    structureRoofType: 'structureRoofType',
    structureRoofFrameType: 'structureRoofFrameType',
    structureHeight: 'structureHeight',
    structureWidth: 'structureWidth',
    structureLength: 'structureLength',
    structureRoofPitch: 'structureRoofPitch',
    structureGirting: 'structureGirting',
    structureBaySpacing:'structureBaySpacing',
    structureEndSkirt: 'structureEndSkirt',    
    structureWainscot: 'structureWainscot',
    structurePosition: 'structurePosition',
    structureRotation: 'structureRotation',    
    structureWalls: 'strcutureWalls', // used by porch option openToMain impacting main structure
    structureName: 'structureName',

    systemPosition: 'systemPosition',
    systemRotation: 'systemRotation',

    wallOpen: 'wallOpen',
    wallHeight: 'wallHeight',
    wallPosition: 'wallPosition',
    wallColumnOption: 'wallColumnOption',

    walkinDetail: 'walkinDetail' ,
    commGlassDetail: 'commGlassDetail',
    doorRollupDetail: 'doorRollupDetail',
    doorSectionalDetail: 'doorSectionalDetail',
    doorSlidingDetail: 'doorSlidingDetail',
    doorHangarDetail: 'doorHangarDetail',
    windowDetail: 'windowDetail',
    louverVentDetail: 'louverVentDetail',

    foundationHeight: 'foundationHeight',
    foundationRotation: 'foundationRotation',
    modelPosition: 'modelPosition',

    cupolaPosition: 'cupolaPosition',
    cupolaDetails: 'cupolaDetails'
}


export let selectionTypes = {
    all: 'all',
    default: 'default',
    componentType: 'componentType',
    connected: 'connected',
    joined: 'joined'
}

export let buildingCodes = {
    cbc16: 'CBC2016',
    ibc12: 'IBC 2012',
    ibc15: 'IBC 2015',
    ibc18: 'IBC 2018',
    ibc21: 'IBC 2021'
}

export let designVersion = [
    {
        val: 3.01,
        desc: `added systems and bays`
    },    
]

