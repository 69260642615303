<template>
  <div class="statusWrapper">
    <div @click="viewMode">
        <label ><b>Mode:</b> {{mode}}</label><label v-if="mode==='edit'" > (Esc to cancel)</label>
    </div>
  </div>
  
</template>

<script>
export default {
  props: {mode: String
  },
  data: ()=>{
      return {
          
      };
  },
  methods: {
      viewMode: function(){
        this.$emit('viewMode');
      }
  }
}
</script> 

<style scoped>


div.statusWrapper{    

    text-align: center;
    
    z-index: 10001;
    background: lightgray;
    border-radius: 4px;
    padding:4px;
    cursor: pointer;
    opacity: .85;
}


</style>


