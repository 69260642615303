
import * as THREE from 'three';
import { Vector2, Vector3 } from 'three';
import { CORE, rebuildTypes, impactTypes} from '../_spec.js';
import Grabject from './grabject.js'
import TreeNode from '../helpers/TreeNode.js'
import CollisionHelper from '../helpers/CollisionHelper';
import cBase from './Base.js';
import _3dCupola from '../3d/Cupola.js';
import OptionHelper from '../helpers/optionHelper.js';
import MemHelper from '../helpers/memHelper.js';
import Util from '../utility.js';
import UpdateHelper from '../helpers/UpdateHelper.js';
import layerHelper from '../helpers/layerHelper.js';
import materialHelper from '../helpers/materialHelper.js';
import { layer } from '@fortawesome/fontawesome-svg-core';


export default class Cupola extends cBase {
    constructor(design, options) {
        super(design);
        
        //this.build()
    }
    
    build(options) {
        let panelColor = options.panelColor;
        let trimColor = options.trimColor;
        let roofColor = options.roofColor;
        let pitchAdjustment = options.pitchAdjustment;
        let size = this.design.size;

        this.group.position.copy(this.design.pos);
        this.dim = new THREE.Vector3(size, size * 2, size)

        let cupola = new _3dCupola(CORE.levelOfDetail, size, panelColor, trimColor, roofColor);
        this.group.add(cupola.group);
        
        
        // base below the cupola base to account for the roof's pitch
        let geoBase = new THREE.BoxGeometry(size + 4 + 0.01, pitchAdjustment, size + 4 + 0.01);
        let matBase = materialHelper.getTrimMaterial(trimColor);
        let base = new THREE.Mesh(geoBase, matBase);
        base.position.y = -size - pitchAdjustment/2 + 0.01
        base.name = 'pitch adjusted cupola base'
        cupola.group.add(base);

        // context menu
        this.contextMenuObject = new THREE.Mesh();
        //let trc = this.getTopRightCorner();
        //this.contextMenuObject.position.set(trc.x, trc.y, trc.z);
        //this.contextMenuObject.visible = false; // this 


        // cupola pickmesh
        let pickGeo = new THREE.BoxGeometry(size + 3, size * 2 + 3, size + 3);
        pickGeo.name = 'cupola pickmesh geo'
        this.pickDetectionMesh = new THREE.Mesh(pickGeo, CORE.materials.transparent);
        this.pickDetectionMesh.name = 'cupola pickmesh';
        this.pickDetectionMesh.visible = false;
        this.pickDetectionMesh.userData = {
            type: this.design.type,
            id: this.design.id
        };

        this.outlineMesh = this.pickDetectionMesh;

        this.group.add(this.pickDetectionMesh)
        this.group.add(this.contextMenuObject);        
        
        //this.group.visible = this.true;

        //this.group.rotation.y = this.rot;

        // add to the roof layer
        layerHelper.setGroup(this.group, CORE.layers.roof, true);
        layerHelper.enableLayer(this.group, CORE.layers.quote, true);
        
        this.group.name = 'cupola'

        let halfDim = 5;
        this.buildGrabjects(halfDim)
        this.showGrabjects(this.design.selected);

        this.setCollisionZone();
        
        this.built=true;
    }

    initRebuildHierarchy(){        
        this.rebuildTypeRoot = new TreeNode(null, rebuildTypes.full);
        this.rebuildTypeRoot.addChildNode(rebuildTypes.move);
    }

    applyDesign(design){
        this.design.collided= design.collided,
        this.design.pos = new Vector3( design.pos.x, design.pos.y, design.pos.z),
        this.design.type = design.type,
        this.design.parent = {};
        this.design.parent.id = design.parent.id;
        this.design.size = design.size;
        this.design.selected= false;
    }
    
    defaultDesign(){

        // 2, 2.5, 3 ft 
        let des = {
            collided : false,
            pos : new Vector3(), 
            parent: {
                type: 'roof'
            },
            type: CORE.components.cupola,
            id: -1,
            size: 30,
            selected: false
        }        
        return des;
    }

    processImpactFromSelf(impact){
        super.processImpactFromSelf(impact);

        switch(impact.change.name)
        {
            case impactTypes.cupolaDetails:
                this.addRebuildNeeded(rebuildTypes.full);
                break;
            case impactTypes.cupolaPosition:
                this.addRebuildNeeded(rebuildTypes.move);
                break;
        }
    }
    
    detectImpact(impact) {
        // 
        if(this.shouldProcessOwnImpact(impact, this.design.id))
            this.processImpactFromSelf(impact);
    }

    processRebuild(type){
        // rebuild the minimal amount of 3d objects for this component
        switch(type){
            case rebuildTypes.full:
                this.rebuildFull();
                break;
            case rebuildTypes.move:
                this.rebuildMove();
                break;
        }
    }

    initParent(parent, context){
        this.parent = parent; // window needs to know the parent because a parent wall may be built normal or inverted (two modes for the sake of debugging math more easily)
        //this.rot = parent.openingRot(); // the inverted walls are not rotated into place, but built in place, therefore, openings on the walls must be rotated
    }

    rebuildFull(){
        this.remove();
        this.build();
    }

    rebuildMove(){
        this.group.position.copy(this.design.pos);
        this.setCollisionZone();
    }

    static canEdit(){return true;}    
    static canCopy(){return true;}    
    static canDelete(){return true;}    

    static parentComponentTypes(){
        // used when moving a grabject to get a list of component types this feature can land on
        return [
            CORE.components.roof
        ]
    }
    
    // required by object interface
    show(v){
        this.visible=v;
        this.group.visible = this.visible;
    }
    
    // required by object interface
    getContextMenuPosition(){        
        // this returns the position of the contextMenuObject (created in build function) 
        // in terms of world position (considering all object groups, and transforms)
        return this.contextMenuObject.getWorldPosition(new THREE.Vector3());
    }
    
    getTopRightCorner(){
        let offset = new THREE.Vector3(1.3*this.design.dim.width/2,
                                       .8*this.design.dim.height/2,
                                       0);
        return offset;
   }


     // required by object interface
     styleCollision(colliding) {
        this.colliding = colliding;
        if(colliding)
            this.matGlass.color = new THREE.Color(0xff0000);
    }

    
    // required by object interface
    getCollisionClass(){
        return CORE.collisions.classes.cupola;
    }

    
    // this is for pending object being placed
    // pending doors should not collide with zone that don't include the door collision class
    getCollisionZones() {
        let zone = this.getCollisionZoneTemplate();
        zone.classes = [];
        zone.data = this.design;
        return [zone];
    }

    getCollisionZoneTemplate(mesh){
        let mw;
        if(mesh){
            //mesh.updateMatrixWorld(true);
            mw=mesh.matrixWorld;
        }            
        else
            mw = this.group.matrixWorld
            
        let zone = CollisionHelper.getZone2(
            this.group,
            undefined,
            true,
            [CORE.collisions.classes.cupola],
            this.dim,
            undefined,
            this.getDescription()
        )
        
        return zone;
    }

    setCollisionZone(){
        if(!this.pickDetectionMesh)
            return;
        this.group.updateMatrix();
        //this.group.updateMatrixWorld();
        this.pickDetectionMesh.updateMatrix();
        this.pickDetectionMesh.updateWorldMatrix(true);
        this.pickDetectionMesh.renderOrder=1;
        let margin = CollisionHelper.getMargin(
            4,
            4,
            0,
            0,0,0);
        this.zone = CollisionHelper.getZone2(
            this.group,
            undefined,
            true,
            [
                CORE.collisions.classes.cupola,
            ],
            this.dim,
            margin,
            this.getDescription()
        )
        this.zone.data = {
            id:this.design.id,
            type:this.design.type
        };

        this.pickDetectionMesh.userData.collisionZones= [this.zone];
        
    }

    // Grabjects
    buildGrabjects(halfDim){
            
        let grabjectSize = 8;
        let grabjectSizeVector = new THREE.Vector3(grabjectSize, grabjectSize, 5);
        this.grabjectGroup = new THREE.Group();
        let parentComponentTypes = [
            CORE.components.roof,
        ]
        
        this.positionGrabject = new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(-this.design.size,0,0), [CORE.indicators.leftRight],
         new THREE.Vector3(15, 15, 5), CORE.grabjects.position, parentComponentTypes);

        this.positionGrabject = new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(this.design.size,0,0), [CORE.indicators.leftRight],
         new THREE.Vector3(15, 15, 5), CORE.grabjects.position, parentComponentTypes);
        
         /*
        new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(-halfDim.x,halfDim.y,halfDim.z), 
        //[CORE.indicators.topLeft, CORE.indicators.bottomRight],
        [CORE.indicators.sphere],
         grabjectSizeVector, CORE.grabjects.topLeft, parentComponentTypes);
         
        new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(0,halfDim.y,0), 
        //[CORE.indicators.upDown],
        [CORE.indicators.sphere],
         grabjectSizeVector, CORE.grabjects.top, parentComponentTypes);
        */
       /*
         new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(halfDim.x,halfDim.y,0), 
        //[CORE.indicators.topRight, CORE.indicators.bottomLeft],
        [CORE.indicators.sphere],
         grabjectSizeVector, CORE.grabjects.topRight, parentComponentTypes);
        */
        /*
         new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(this.design.size,0,0), 
        [CORE.indicators.leftRight], grabjectSizeVector, CORE.grabjects.right, parentComponentTypes);
        */
        

        /*
        new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(-halfDim.x,0,0),         
        //[CORE.indicators.leftRight],
        [CORE.indicators.sphere],
         grabjectSizeVector, CORE.grabjects.left, parentComponentTypes);
        this.group.add(this.grabjectGroup);

        new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(halfDim.x,0,0),         
        //[CORE.indicators.leftRight],
        [CORE.indicators.sphere],
         grabjectSizeVector, CORE.grabjects.right, parentComponentTypes);
        */


        this.group.add(this.grabjectGroup);
        
    }

    getInitialGrabjectUserData(){
        let grabData = super.getInitialGrabjectUserData();
        grabData.allowedParentComponentTypes = [
            CORE.components.roof
        ]
        return grabData;
    } 

    updateSize(input){

        const {role, relPosition} = input;
        if(Util.isUndefined(relPosition)){
            throw 'cupola size grabject position is undefined'
        }

        let newCoordX = relPosition.x; 
        let newCoordY = relPosition.y;

        let affected = false;
        switch(role){
            case CORE.grabjects.topLeft:
                affected = this.grabjectChangeLeft(newCoordX) || affected;
                affected = this.grabjectChangeTop(newCoordY) || affected;
                break;
            case CORE.grabjects.top:
                affected = this.grabjectChangeTop(newCoordY) || affected;
                break;
            case CORE.grabjects.topRight:
                affected = this.grabjectChangeRight(newCoordX) || affected;
                affected = this.grabjectChangeTop(newCoordY) || affected;
                break;
            case CORE.grabjects.right:
                affected = this.grabjectChangeRight(newCoordX) || affected;
                break;            
            case CORE.grabjects.left:
                affected = this.grabjectChangeLeft(newCoordX) || affected;
                break;
        }
         // return details regarding the impact this input change had
         if(affected){
            this.setPickMeshGeometry();
            
            return UpdateHelper.createImpact(this.design, impactTypes.openingResize);
        }
    }

    grabjectChangeLeft(newVal){
        // math is written based on positive-Z facing window, where:
        // left grabject moved to be left implies change < 0)
        // left grabject moved to the right implies change > 0
        // 
        // when flipped
        // left grabject moved to the left implies change > 0
        // left grabject moved to the right implies change < 0
        let change = newVal - this.left(); // change may be positive or negative because left does not necessarily mean a lower X value, since BSW and REW windows are flipped

        if(change===0)
            return;
        let widthChange = change;
        if(this.parent.isInverted())
            widthChange*=-1;
        // apply the full, positive-only change to the width
        if(!Cupola.updateWidth(this.design, this.design.dim.width-widthChange))
            return;
        
        // apply half of the change (+ or -) to the position
        Cupola.updatePosX(this.design, this.design.pos.x + change/2);
        return true;
    }

    grabjectChangeRight(newVal){
        let change = newVal - this.right(); // change may be positive or negative because left does not necessarily mean a lower X value, since BSW and REW windows are flipped
        if(change===0)
            return;
        let widthChange = change;
        if(this.parent.isInverted())
            widthChange*=-1;
        // apply the full, positive-only change to the width
        if(!Cupola.updateWidth(this.design, this.design.dim.width+widthChange))
            return;        
        // apply half of the change (+ or -) to the position
        Cupola.updatePosX(this.design, this.design.pos.x + change/2);
        return true;
    }

    grabjectChangeTop(newTop){
        if(!Cupola.updateHeight(this.design, newTop))
            return false;

            Cupola.updatePosY(this.design)
        return true;
    }

    updatePosition(center)
    {
        if(!Cupola.updatePosition(center, this.design))
            return

        // move requires no rebuild (except on re-parentage)
         return UpdateHelper.createImpact(this.design, impactTypes.cupolaPosition);
    }
    
    static updatePosition(center, design){
        let affected = false
        // moves the built object, so it doesn't have to be rebuilt.
        affected = Cupola.updatePosX(design, center.x) || affected;
        //affected = Cupola.updatePosY(design, 0) || affected;
        
        return affected;
    }

    static updatePosX(design, posX){
        console.log(design.pos.x + " ===> " + posX);
        if(design.pos.x===posX)
            return false
        design.pos.x = posX;
        return true;
    }
    static updatePosY(design, posY){
        if(design.pos.y===posY)
            return false
        design.pos.y = posY;
        return true;
    }
    
    
    // TEST THESE, are they necessary?

    getOutlineMeshes(){
        return [this.outlineMesh];
    }

    // required by object interface
    getContextMenuPosition(){        
        // this returns the position of the contextMenuObject (created in build function) 
        // in terms of world position (considering all object groups, and transforms)
        return this.contextMenuObject.getWorldPosition(new THREE.Vector3());
    }

    getTopRightCorner(){
         let offset = new THREE.Vector3(1.3*this.design.dim.width/2,
                                        .8*this.design.dim.height/2,
                                        0);
         return offset;
    }

    // options and description


    getOptionsSpec(){
        return [
            OptionHelper.radios(
            'size', //key 
            'Size', //name
            impactTypes.cupolaDetails, //impactType
            [{  //selOptions,
                value: 24,
                text:"2 ft."
            },
            {
                value: 30,
                text:"2.5 ft."
            },
            {
                value: 36,
                text:"3 ft."
            }],
            (v) => {//fnIn,
                return this.design.size;
            }, 
            (v) => { //fnOut
                this.design.size = v;
                this.parent.processRebuild(rebuildTypes.dynamicOnly)
            },
            undefined) //fnChange)
        ]
    }
    
    getTypeDisplayName(){
        return "Cupola"
    }

    // required by object interface
    getDescription(){
        return `Cupola ${this.design.size}"`;
    } 

    removeGrabjects(){
        this.group.remove(this.grabjectGroup);
        MemHelper.dispose(this.grabjectGroup);
        delete this.grabjectGroup;
    }

    removePickingObject(){
        if(this.pickDetectionMesh)
            delete this.pickDetectionMesh
    }
    
    remove() {
        this.group.remove(this.pickDetectionMesh);
        this.removeGrabjects(); 
        this.removePickingObject();
        MemHelper.removeAllChildren(this.group);
        MemHelper.dispose(this.group);
    }

    getDeleteImpactType(){
        return impactTypes.deleteCupola;
    }
    /*
    remove() {
        MemHelper.removeAllChildren(this.group);
        MemHelper.dispose(this.group);
    }*/
}