<template>
  <div id="pricing">
    Pricing Models Assignment:<br/>
    <br/>
    <p style="text-align:left;font-size:medium"><b>Default Pricing</b> is used when a project is quoted without a contractor assigned.<br/>    
    </p>
    <br/>
    <table style="border-collapse: collapse;">
      <tr>
        <th>
          Default Pricing
        </th>
        <th>
          Pricing Model
        </th>
        <th>
          Bolt-Up Pricing
        </th>
        <th>
          Weld-Up Pricing
        </th>        
      </tr>
      <BusinessPricingRecord :pBusiness="defaultPricing" v-on:update="updateBusiness" :key="defaultPricing.id"></BusinessPricingRecord>
      <br/>
      <tr>
        <th>
          Contractor Pricing
        </th>
        <th>
          Pricing Model
        </th>
        <th>
          Bolt-Up Pricing
        </th>
        <th>
          Weld-Up Pricing
        </th>        
      </tr>
      <BusinessPricingRecord v-for="cont in contractors" :pBusiness="cont" v-on:update="updateBusiness" :key="cont.id"></BusinessPricingRecord>
    </table>
  </div>        
</template>

<script>
import {mapState} from 'vuex'
import api from '@/api';
import EntityPricingRecord from "./BusinessPricingRecord.vue"
import BusinessPricingRecord from './BusinessPricingRecord.vue';
export default {
  name: 'ContractorPricing',
  data() {
    return {
      defaultPricing: {},
      contractors: []
    };
  },
  computed: {
    ...mapState('contextModule', ['current']),
  },
  methods: {
    async updateBusiness(businessPricing){
      console.log('updateBusiness');
      await api.setPricingConfig(businessPricing.businessId, businessPricing);
    },
    async getContractorPricing(){
      let contractorResp = await api.getPricingConfig(this.current.supplier.id);
      return contractorResp
    }
  },
  async created(){
    
  },
  async mounted(){
    this.contractors = await this.getContractorPricing();
    // pull out the company default as it's own setting
    this.defaultPricing = this.contractors.filter( x => x.businessId == this.current.supplier.id)[0]
    // remove the company default from the list for contractors
    this.contractors = this.contractors.filter( x => x.businessId != this.current.supplier.id)
  },
  components:{
    BusinessPricingRecord
  }
};
</script>

<style scoped>
td, th{
  border:1px solid black;
  padding:5px;
  
}
th {
  font-size: medium;
}

#pricing {
  border: 2px solid gray;
  padding:10px;
  height: fit-content;
}

</style>