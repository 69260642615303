import { OBB } from 'three/examples/jsm/math/OBB.js'; // https://threejs.org/examples/?q=bounding#webgl_math_obb

import { Vector3 } from 'three';
import Util from '../utility';
export default class CollisionHelper{

    static createObb(bounds){
        let obb = new OBB();
        obb.halfSize = bounds.fullSize.clone().multiplyScalar(.5);
        obb.fullSize = bounds.fullSize.clone();
        return obb;
    }

    static getZone2(parentGroup, pos, resolvable, classes, size, margin, name){

        let bounds = CollisionHelper.getMarginedBounds(size, margin);
        let position = new Vector3(0,0,0); 
        // if it's not provided, use blank.
        if(Util.isDefined(pos))
            position.copy(pos.clone());
        
        position.add(bounds.centerDelta.clone()); // adjust the center based on the margins added        

        let zone = {
            parentGroup,            
            bounds,
            classes,
            resolvable,
            name,
            position
        }
        return zone;
    }

    static getMarginedBounds(sz, m){
        //m = margin
        
        if(m === undefined)
            m = CollisionHelper.getMargin(); // default to no margins
        // if there is no margin needed on the right (mr=0), then shift this bound to the left by ml
        m=CollisionHelper.sanitizeMargin(m);
        //you can have positive or negative values for each of the 6 axis directions
        // calculate the size of margin in each axis
        let margin = new Vector3(m.xp+m.xn, m.yp+m.yn, m.zp+m.zn);
        // add the margin to the original size to get the full bounded volume
        let fs = new Vector3().copy(sz).add(margin);
        // slice everything in half for halfSize (oriented Bounding box needs halfSize)
        let hs = new Vector3().copy(fs).multiplyScalar(.5);

        let centerDelta =new Vector3(
            // difference between half the size, adjusted for margins
            ((sz.x+m.xp-m.xn)/2)-(sz.x/2),
            ((sz.y+m.yp-m.yn)/2)-(sz.y/2),
            ((sz.z+m.zp-m.zn)/2)-(sz.z/2));
        return {
            fullSize: fs,
            halfSize: hs,
            centerDelta: centerDelta
        }
    }

    static sanitizeMargin(m){
        return {
            xp: Number(m.xp),
            xn: Number(m.xn),
            yp: Number(m.yp),
            yn: Number(m.yn),
            zp: Number(m.zp),
            zn: Number(m.zn)
        }
    }
    
    static getMargin(xp,xn,yp,yn,zp,zn){
        //x,y,z = dimension
        //p,n = positive/negative side of axis
        return {
            xp:xp===undefined?0:xp,
            xn:xn===undefined?0:xn,
            yp:yp===undefined?0:yp,
            yn:yn===undefined?0:yn,
            zp:zp===undefined?0:zp,
            zn:zn===undefined?0:zn
        }
    }

    
}