<template>
    <div :style="customStyle">
        <input type="checkbox"  @change="change" :checked="val" :disabled="disable"/>
        <StatusIndicator ref="status" :successText="'Saved'"></StatusIndicator>
    </div>
</template>

<script>
import StatusIndicator from './StatusIndicator.vue';

    export default {
        name:'user-setting-checkbox',
        props: ['id', 'name', 'val', 'description', 'customStyle', 'disable'],
            data: function () {
                return {
                }
            },
        computed: {
            containerId: function() {
                return 'setting_' + this.id;
            }
        },
        watch: {

        },
        mounted: function () {
        },
        updated: function () {

        },
        methods: {
            change: function(e) {
                this.$emit('change', { id: this.id, val: e.target.checked })
            },
            loading: function () {
                this.$refs.status.loading();
            },
            success: function () {
                this.$refs.status.success();
            },
            fail: function () {
                this.$refs.status.fail();
            }
        },
        components:{
            StatusIndicator
        }
    }
</script>

<style scoped>

    .classes.header {
        margin-left: 20px;
        color: green;
    }
</style>
