import * as THREE  from 'three'
import layerHelper from '../helpers/layerHelper.js';
import { CORE } from '../_spec.js'
import _3dBase from "./Base";


export default class TrimOpeningJamb extends _3dBase {
    constructor(lod, dim, jambTrimThickness, jambTrimWidth, material){
        super(undefined,undefined,undefined,lod);
        
        this.build(dim, jambTrimThickness, jambTrimWidth, material)
    }

    build(dim, jambTrimThickness, jambTrimWidth, material){
        if(this.isLowDetail())
            this.build_LD(dim, jambTrimThickness, jambTrimWidth, material);
        else
            this.build_HD(dim, jambTrimThickness, jambTrimWidth, material);
    }

    build_LD(dim, jambTrimThickness, jambTrimWidth, material){
        // jambs
        let halfDim = dim.clone().multiplyScalar(.5);
        let halfJambThickness = jambTrimThickness/2;
        let geoTop = new THREE.PlaneBufferGeometry(dim.x,jambTrimWidth,1,1);        
        geoTop.position = new THREE.Vector3(0, halfDim.y, 0);        
        let meshTop = new THREE.Mesh(geoTop, material)        
        meshTop.name = 'trimOpeningJamb top'
        meshTop.rotation.x=Math.PI/2;
        meshTop.position.set(0, halfDim.y - halfJambThickness, -jambTrimWidth/2);        
        meshTop.layers.set(CORE.layers.walls);        
        meshTop.layers.enable(CORE.layers.quote);
        this.group.add(meshTop);
        let geoVert = new THREE.PlaneBufferGeometry(jambTrimWidth, dim.y, 1,1);
        
        let meshLeft = new THREE.Mesh(geoVert, material)
        meshLeft.name = 'trimOpeningJamb left'
        meshLeft.position.set(-halfDim.x + halfJambThickness, 0, -jambTrimWidth/2)
        meshLeft.rotation.y  = Math.PI/2;
        meshLeft.layers.set(CORE.layers.walls);
        meshLeft.layers.enable(CORE.layers.quote);
        this.group.add(meshLeft);
        
        let meshRight = new THREE.Mesh(geoVert, material)
        meshRight.name = 'trimOpeningJamb right'
        meshRight.position.set(halfDim.x - halfJambThickness, 0, -jambTrimWidth/2)
        meshRight.rotation.y  = Math.PI/2;
        meshRight.layers.set(CORE.layers.walls);        
        meshRight.layers.enable(CORE.layers.quote);
        this.group.add(meshRight);        
    }

    build_HD(dim, jambTrimThickness, jambTrimWidth, material){
        // jambs
        let halfDim = dim.clone().multiplyScalar(.5);
        let halfJambThickness = jambTrimThickness/2;
        let geoTop = new THREE.BoxGeometry(dim.x, jambTrimThickness, jambTrimWidth);
        geoTop.position = new THREE.Vector3(0, halfDim.y, 0);        
        let meshTop = new THREE.Mesh(geoTop, material)        
        meshTop.name = 'trimOpeningJamb top'
        meshTop.position.set(0, halfDim.y - halfJambThickness, -jambTrimWidth/2);        
        meshTop.layers.set(CORE.layers.walls);        
        meshTop.layers.enable(CORE.layers.quote);
        this.group.add(meshTop);
        let geoVert = new THREE.BoxGeometry(jambTrimWidth, dim.y, jambTrimThickness);
        
        let meshLeft = new THREE.Mesh(geoVert, material)
        meshLeft.name = 'trimOpeningJamb left'
        meshLeft.position.set(-halfDim.x + halfJambThickness, 0, -jambTrimWidth/2)
        meshLeft.rotation.y  = Math.PI/2;
        meshLeft.layers.set(CORE.layers.walls);
        meshLeft.layers.enable(CORE.layers.quote);
        this.group.add(meshLeft);
        
        let meshRight = new THREE.Mesh(geoVert, material)
        meshRight.name = 'trimOpeningJamb right'
        meshRight.position.set(halfDim.x - halfJambThickness, 0, -jambTrimWidth/2)
        meshRight.rotation.y  = Math.PI/2;
        meshRight.layers.set(CORE.layers.walls);        
        meshRight.layers.enable(CORE.layers.quote);
        this.group.add(meshRight);        
    }
}