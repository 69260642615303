<template>
    <div>
        <div class="lvl2Header">Add Lean-Tos</div>
        <div>
        <table style="margin:0px auto 5px auto;">
              <tr>
                  <td></td>
                  <td>
                      <button class="leanToToggle" @click="toggleLeanTo(CORE.sides.backSide)" :class="{selected: hasBackSideLeanTo}" :disabled="isBackLeanToDisabled">
                          Back
                          <!--<br /> <font-awesome-icon icon="fa-regular fa-square-arrow-up" size="xl"/>  -->
                      </button>
                  </td>
              </tr>
              <tr>
                  <td>
                      <button class="leanToToggle" @click="toggleLeanTo(CORE.sides.leftEnd)" :class="{selected: hasLeftEndLeanTo}" :disabled="isDisabled">
                          <!-- <font-awesome-icon icon="fa-regular fa-square-arrow-left" size="xl" /> -->                        
                          <!-- <font-awesome-icon icon="fa-duotone fa-arrow-left-from-line" size="xl" /> -->
                          Left
                      </button>
                  </td>
                  <td>
                    <font-awesome-icon icon="fa-duotone fa-location-crosshairs" size="xl" :title="structureName"/>
                  </td>
                  <td>
                      <button class="leanToToggle" @click="toggleLeanTo(CORE.sides.rightEnd)" :class="{selected: hasRightEndLeanTo}" :disabled="isDisabled">
                          <!-- <font-awesome-icon icon="fa-regular fa-square-arrow-right" size="xl"/> -->
                          Right
                      </button>
                  </td>
              </tr>
              
              <tr>
                <td></td>
                  <td>
                      <button class="leanToToggle" @click="toggleLeanTo(CORE.sides.frontSide)" title=''  :class="{selected: hasFrontSideLeanTo}" :disabled="isDisabled">                    
                          <!-- <font-awesome-icon icon="fa-regular fa-square-arrow-down" size="xl" /> <br /> -->
                          <span>Front</span>
                      </button>
                  </td>
              </tr>
          </table>
        </div>
      </div>
    
  </template>
  
  <script>
  import {CORE} from '../js/_spec.js'
  import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

  export default {
    props: { },
    data: ()=>{
        return {
           CORE,
        };
    },
    mounted(){
      
    },  
    computed:{
        ...mapGetters('designModule', [
      'structure',
      'selectedComponent',
    ]),
        structureName(){
        if(this.structure)
            return this.structure.design.name;
        return "";
    },
      selectedComp(){
            return this.selectedComponent
      },
      isDisabled(){
        if(this.selectedComp){
            if(this.selectedComp.design.type === CORE.components.structure || this.selectedComp.design.type === CORE.components.leanTo)
             return false;
        }

        return true;
      },
      isParentLeanTo(){
        if(this.selectedComp && this.selectedComp.design.type === CORE.components.leanTo){
            return true
        }
        return false;
      },
      isBackLeanToDisabled(){
        if(!this.isDisabled){
            if(!this.isParentLeanTo)
                return false
        }
        return true;
      },
      frontSideLeanTo(){
          if(!this.structure)
              return null;
          return this.structure.getFrontSideLeanTo();     
      },
      backSideLeanTo(){
          if(!this.structure)
              return null;
          return this.structure.getBackSideLeanTo();
      },
      leftEndLeanTo(){
          if(!this.structure)
              return null;
          return this.structure.getLeftEndLeanTo();
      },
      rightEndLeanTo(){
          if(!this.structure)
              return null;
          return this.structure.getRightEndLeanTo();
      },    
      hasFrontSideLeanTo(){
          if(!this.structure)
              return null;
          return this.structure.hasFrontSideLeanTo();
          // return this.frontSidePorch !== null        
      },
      hasBackSideLeanTo(){
          if(!this.structure)
              return null;
          return this.structure.hasBackSideLeanTo();
          // return this.backSidePorch !== null        
      },
      hasLeftEndLeanTo(){
          if(!this.structure)
              return null;
          return this.structure.hasLeftEndLeanTo();
      },
      hasRightEndLeanTo(){
          if(!this.structure)
              return null;
          return this.structure.hasRightEndLeanTo();
          // return this.rightSidePorch !== null        
      },
    },
  
    methods: {
        ...mapActions('designModule',[
        'toggleLeanTo',        
      ]),
    },
    components:{
      
    }
  }
  </script> 
  
  <style scoped>
  
  .leanToToggle{
      width: 46px;
    white-space: nowrap;
      border: 1px solid gray; 
    padding: 0 4px;
  
    border-radius: 4px;;
    background: green;
    color:white;
  }
  
  .leanToToggle.selected{
      background: #34ab34;
      border: 1px solid white; 
  }
  
  
  button.leanToToggle[disabled] {
    background:gray ;
    color: darkgrey;
  }
  
  .btnGreen{
      background: green;
  }
  
  .btnGreen:hover{
      background: #34ab34;
  }
  
  button{
    cursor: pointer;
  }
  </style>
  
  
  