import * as THREE from 'three'
import {CORE} from '../_spec.js'
import FoldedGeometryHelper from '../helpers/foldedGeometryHelper.js';
import MemHelper from '../helpers/memHelper.js';
import _3dBase from './Base.js'
import layerHelper from '../helpers/layerHelper.js';
import Sphere from '../3d/Sphere.js'

export default class TrimSoffitEave extends _3dBase {
    constructor(length, width, height, bendDeg, material){
        super()

        //let sphere = new Sphere();
        //this.group.add(sphere.group)
        
        let folds = [
            {dist:width},
            {deg:-180+bendDeg}, 
            {dist:height},         
            
        ];

        let fgh = new FoldedGeometryHelper(folds, material, true, true, true, length, 0, 0, 0, 0)
        this.group.add(fgh.group);
        this.group.name = 'Soffit Eave Trim'
    }
    

}