
import DoorWalkin from './DoorWalkin.js'
import { Vector2, Vector3 } from 'three';
import { CORE,impactTypes  } from '../_spec.js'
import OptionHelper from '../helpers/optionHelper.js'

export default class DoorWalkin3o7o extends DoorWalkin {
    constructor(design) {
        super(design);
    }

    migrate(design){
        if(typeof design.halfGlass === 'undefined' || design.halfGlass === null){
            design.halfGlass=false;
        }
    }

    init(design) {        
        this.design = design;
        this.design.dim.width = 3*12;
    }

    // required by object interface
    getCollisionClass(){
        return CORE.collisions.classes.doorWalkin3o7o;
    }
    
    defaultDesign(deadboltPreference){
        return {
            collided : false,
            pos : new Vector3(0,(7*12)/2,0),
            parent: {
                type: 'frontSide'
            },
            type: CORE.components.doorWalkin3o7o,
            id: -1,
            dim:{
                width: 3 * 12 ,
                height: 7 * 12 
            },
            selected:false,
            halfGlass:false,
            deadbolt: deadboltPreference
        }
    }

    applyDesign(design){
        super.applyDesign(design);
        this.design.halfGlass = design.halfGlass;
    }

    getDescription(){
        return `Walkin 3' x 7'`;
    }

    getOptionsSpec(){
        let opts = super.getOptionsSpec();
        opts.push(...[
            OptionHelper.checkbox("halfGlass", "Half Glass", impactTypes.walkinDetail, true)
        ]
        );
        return opts;
    }

    getTypeDisplayName(){
        return `3' Walk Door`;
    }
}