import * as THREE from 'three';
import {CORE} from '../_spec.js'
import { OBB } from 'three/examples/jsm/math/OBB.js'; // https://threejs.org/examples/?q=bounding#webgl_math_obb
import CollisionHelper from '../helpers/CollisionHelper';
import ShapeHelper from '../helpers/shapeHelper';
import { Vector3, Vector2, Shape, Quaternion, Matrix4, Matrix3, Object3D } from 'three';
import MaterialHelper from '../helpers/materialHelper.js';

import _3dRafterBase from './RafterBase.js'
import _3dFastenerHex from './FastenerHex.js'
import layerHelper from '../helpers/layerHelper.js';
import Tri from './Tri.js';
import debugHelper from '../helpers/debugHelper.js';
import BlueprintHelper from '../helpers/blueprintHelper.js';

/*
        ST = shear triangle
        CORE RECTANGLE 
        GROWTH TRIANGLE

        the tapered frame is built in this horizontal orientation (top flange is level)

        *****************************************************
        *              CORE RECTANGLE                  *ST*
        *************************************************  
        *                                     *
        *   GROWTH TRIANGLE       *
        *            *
        * 
        
        */

export default class RafterTapered extends _3dRafterBase {
    constructor(lod, parentGroup, pos, rot, length, pitch, singleSlope, addCollisionZone, bpFrameLine, includeBoltPlateTop, rafterColor){
        super(parentGroup, pos, rot,lod);
        this.addCollisionZone = addCollisionZone;
        this.dimensions = bpFrameLine.materialDim;        
        this.pitchAngle = pitch;
        this.group.name='Tapered rafter';        
        this.singleSlope = singleSlope
        if(singleSlope){
            this.dimensions.depthPeak = this.dimensions.depthHaunch;
        }

        this.extrusionSettings = {
            depth: .75, //used to be amount
            bevelEnabled: false,
            bevelThickness: 0.1,
            bevelSize: 1,
            bevelSegments: 1,
            material: 0,
            extrudeMaterial: 1
        };

        this.bottomFlangeLength = length;
        // calculate the amount of haunch depth not in the peak depth
        let growthDepth = this.dimensions.depthHaunch - this.dimensions.depthPeak;
        // calculate the angle between the bottom flange and the top flange
        this.growthAngle = Math.asin(growthDepth/this.bottomFlangeLength)
        // calculate the length of the core (rectanglular prism with depth of the peak depth)
        let coreLength = Math.cos(this.growthAngle) * this.bottomFlangeLength;
        // calculate the length of the top of the shear triangle
        let shearLength = this.dimensions.depthPeak * Math.tan(this.pitchAngle);
        // calculate the length of the top flange
        if(this.singleSlope){            
            // sloped taper has no peak shear triangle
            this.topFlangeLength = coreLength;
        }
        else
            this.topFlangeLength = coreLength + shearLength;

        this.includeBoltPlateTop = includeBoltPlateTop;

        this.rafterColor = rafterColor ? rafterColor : CORE.frame.redOxideColor;

        this.build();
    }
    
	build(){
        // these are first built on end, then sheared, then rotated into place
        // the shearing affects appearance of the corner, so some tweaks in the y dimension fix z dimension discrepancies
        
        this.buildRafter();
        let czPositionMarker = new Object3D(); // add an object purely for marking the position of the collisionZone center
        czPositionMarker.position.z = this.topFlangeLength/2; // position the marker locally (it's global position is calculated automatically)
        czPositionMarker.position.y = this.dimensions.depthHaunch/2;
        this.group.add(czPositionMarker) // add it to the tree 

        
        this.group.updateMatrix();
        this.group.updateMatrixWorld();
        
        if(this.addCollisionZone){
            //collision bounding box
            let position = new Vector3(0,0,this.topFlangeLength/2);
            let size = new Vector3(this.dimensions.flange, this.dimensions.depthHaunch,  this.topFlangeLength) // x,z,y
            let margin = CollisionHelper.getMargin(4,4,0,0,0,0);
            let zone = CollisionHelper.getZone2(
                this.group,
                position,
                false,
                [
                    CORE.collisions.classes.window,
                    CORE.collisions.classes.emptyFramedOpening,	
                    CORE.collisions.classes.louverVent,
					CORE.collisions.classes.doorWalkin3o7o,
					CORE.collisions.classes.doorWalkin4o7o,
					CORE.collisions.classes.doorWalkin6o7o,
					CORE.collisions.classes.doorRollup,
                    CORE.collisions.classes.doorSliding,
                    CORE.collisions.classes.doorCommGlass,
                    CORE.collisions.classes.doorSectional,
                    CORE.collisions.classes.doorHangar,
                    CORE.collisions.classes.wideOpening,
                ],
                size,
                //this.group.matrixWorld, 
                margin,
                'tapered rafter');

            zone.position.z = this.topFlangeLength/2;
            
            if (this.meshDepth) {
                this.meshDepth.userData = {
                    collisionZones: [zone]
                };
            }
        }        
    }

    buildRafter(){
        if(this.isLowDetail())
            this.buildRafter_LD(this.length)
        else 
            this.buildRafter_HD()        
        layerHelper.setGroup(this.group, CORE.layers.frame, true);
    }

    buildRafter_LD(length){     
        length+=CORE.steel.thickness_in*2;   
        let flangeWidth = this.dimensions.flange;
        let halfFlangeWidth=flangeWidth/2;
        let ld_TopFlangeLength = this.topFlangeLength + CORE.steel.thickness_in*1;   
        let ld_BottomFlangeLength = this.bottomFlangeLength + CORE.steel.thickness_in*1;
        let ld_depthPeak = this.dimensions.depthPeak// +  CORE.steel.thickness_in*2;
        let ld_depthHaunch = this.dimensions.depthHaunch// +  CORE.steel.thickness_in*2;
        // sloped roof with tapered columns
        let repeatX=1,repeatY=1;
		// top flange shape
        let mat = MaterialHelper.getFrameMaterial_LD(this.rafterColor)
        let geoTopFlange = new THREE.PlaneBufferGeometry(flangeWidth, ld_TopFlangeLength, 1,1)
		let meshTopFlange = new THREE.Mesh(geoTopFlange, mat);        
        meshTopFlange.rotation.x = Math.PI/2;
        meshTopFlange.position.z = ld_TopFlangeLength/2;
        meshTopFlange.position.y = ld_depthHaunch;
        this.group.add(meshTopFlange);

		// depth shape		
		let bl = new Vector3(0,0, 0)
		let tl = new Vector3(0,ld_TopFlangeLength, 0)
        let tr = new Vector3(ld_depthPeak,ld_BottomFlangeLength, 0)
        let br = new Vector3(ld_depthHaunch,0,0)
        
		let triTopRight = new Tri(
            'bottom right triangle',
            [
                // counter clock-wise
                br, //bottom right 
                tr, // top right 
                tl, // top left 
            ],
            [
                              
                1.0, 0, // bottom right
                1.0, 1, // top right
                0.0, 1.0 // top left
            ]
        );

        let triBottomLeft = new Tri(
            'top left triangle',
            [
                br, // bottom right
                tl, // top left 
                bl, // bottom left 
            ],
            [
                1.0, 0, // bottom right
                0.0, 1, // top left
                0.0, 0, // bottom left
            ]
        );
        
        let meshDepthBottomLeft = new THREE.Mesh(triBottomLeft.geometry, mat);
        let meshDepthTopRight = new THREE.Mesh(triTopRight.geometry, mat);

		meshDepthBottomLeft.position.y = ld_depthHaunch;
        meshDepthBottomLeft.rotation.y = -Math.PI/2;
        meshDepthBottomLeft.rotation.z = -Math.PI/2;
		this.group.add(meshDepthBottomLeft);

        meshDepthTopRight.position.y = ld_depthHaunch;
        meshDepthTopRight.rotation.y = -Math.PI/2;
        meshDepthTopRight.rotation.z = -Math.PI/2;
		this.group.add(meshDepthTopRight);
        
		// bottom flange shape		
        let geoBottomFlange = new THREE.PlaneBufferGeometry(flangeWidth, ld_BottomFlangeLength, 1,1)
		let meshBottomFlange = new THREE.Mesh(geoBottomFlange, mat);        

        let shiftOver = ld_BottomFlangeLength/2;
        
        meshBottomFlange.position.z = shiftOver;
        // SOH CAH TOA
        // tan(0) = Opp/Adj
        // Adj * tan(0) = Opp
        // because shifting the (slanted) bottom flange horizontally also requires it be shifted vertically.
        let shiftUp = Math.tan(this.growthAngle) * shiftOver; 
        meshBottomFlange.position.y = shiftUp;
        meshBottomFlange.rotation.x = Math.PI/2 - this.growthAngle;
        this.group.add(meshBottomFlange);
        

        // haunch bolt plate
        let boltPlateMargin = new Vector2(4,4);        
        let boltPlateTopMargin = 4;
        if(!this.includeBoltPlateTop)        
            boltPlateTopMargin=0;
        this.haunchPlate = new THREE.Group();  
        let plateHeight = boltPlateTopMargin + this.dimensions.depthHaunch + boltPlateMargin.y;
        let geoHaunchBoltPlate = new THREE.PlaneBufferGeometry(2*boltPlateMargin.x, plateHeight,1,1);
       
        let meshHaunchBoltPlate = new THREE.Mesh(geoHaunchBoltPlate, mat)            
        this.haunchPlate.add(meshHaunchBoltPlate);
        this.haunchPlate.position.y =-boltPlateMargin.y + (plateHeight)/2
        this.group.add(this.haunchPlate);

        //this.group.add(debugHelper.getCoordPlanes());
    }
    


        
    buildRafter_HD(){        
        let flangeWidth = this.dimensions.flange;
        let halfFlangeWidth=flangeWidth/2;
        // sloped roof with tapered columns
        let repeatX=1,repeatY=1;
		// top flange shape
		let meshTopFlange = this.getExtrudedMesh( [
            new Vector2(-halfFlangeWidth,0),
		    new Vector2(-halfFlangeWidth,this.topFlangeLength),
		    new Vector2(halfFlangeWidth,this.topFlangeLength),
            new Vector2(halfFlangeWidth,0)        
        ],
            MaterialHelper.getFrameMaterialWithTexture(this.texFrame, this.rafterColor, repeatX, repeatY)
        )
        meshTopFlange.rotation.x = Math.PI/2;        
        meshTopFlange.position.y = this.dimensions.depthHaunch;
        this.group.add(meshTopFlange);

		// depth shape				
		this.meshDepth = this.getExtrudedMesh( [
            new Vector2(this.dimensions.depthHaunch,0),
		    new Vector2(0,0),
		    new Vector2(0,this.topFlangeLength),
		    new Vector2(this.dimensions.depthPeak,this.bottomFlangeLength)
        ],
            MaterialHelper.getFrameMaterialWithTexture(this.texFrame, this.rafterColor, repeatX, repeatY)
        )
		this.meshDepth.position.y = this.dimensions.depthHaunch;
        this.meshDepth.rotation.y = -Math.PI/2;
        this.meshDepth.rotation.z = -Math.PI/2;
		this.group.add(this.meshDepth);
        
		// bottom flange shape		
		var meshBottomFlange = this.getExtrudedMesh( [
            new Vector2(-halfFlangeWidth,0),
		    new Vector2(-halfFlangeWidth,this.bottomFlangeLength),
		    new Vector2(halfFlangeWidth,this.bottomFlangeLength),
		    new Vector2(halfFlangeWidth,0)
        ],
            MaterialHelper.getFrameMaterialWithTexture(this.texFrame, this.rafterColor, repeatX, repeatY)
        )
        meshBottomFlange.rotation.x = Math.PI/2 - this.growthAngle;        
        this.group.add(meshBottomFlange);

        
        let boltPlateMargin = new Vector2(4,4);
        this.haunchPlate = new THREE.Group();  
        let boltPlateTopMargin = 4;
         if(!this.includeBoltPlateTop)   
         boltPlateTopMargin=0;
        // haunch bolt plate        		
		var meshHaunchBoltPlate = this.getExtrudedMesh( [
            new Vector2(-boltPlateMargin.x,-boltPlateMargin.y),
		    new Vector2(-boltPlateMargin.x,boltPlateTopMargin+this.dimensions.depthHaunch),
		    new Vector2(boltPlateMargin.x,boltPlateTopMargin+this.dimensions.depthHaunch),
		    new Vector2(boltPlateMargin.x,-boltPlateMargin.y)
        ],
            MaterialHelper.getFrameMaterialWithTexture(this.texFrame, this.rafterColor, repeatX, repeatY)
        )

        this.haunchPlate.add(meshHaunchBoltPlate);
        
        // haunch bolt plate bolts
        let nutPos = []; // array of y positions 
        nutPos.push( -(boltPlateMargin.y * 2/3)) // low low
        nutPos.push( +(boltPlateMargin.y * 2/3)) // low high
        nutPos.push( this.dimensions.depthHaunch/2) // middle
        nutPos.push(this.dimensions.depthHaunch +(-boltPlateMargin.y * 2/3)) // high lo
        if(this.includeBoltPlateTop)
            nutPos.push(this.dimensions.depthHaunch +(+boltPlateTopMargin * 2/3)) // high high

        let nutRot = new Quaternion().setFromAxisAngle(new Vector3(1,0,0),-Math.PI/2);

        
        nutPos.forEach((y)=>{
            new _3dFastenerHex(this.haunchPlate, 2, 
                new Vector3(boltPlateMargin.x * 2/3,y,0),  // pos
                nutRot, // quaternion
            );   
            new _3dFastenerHex(this.haunchPlate, 2, 
                new Vector3(-boltPlateMargin.x * 2/3,y,0),  // pos
                nutRot, // quaternion
            );
        });
        
        this.group.add(this.haunchPlate);

        // peak bolt plate
        // this plate is built so the origin is the underside of the rafter at the peak (the bottom corner of the shear triangle)
        this.peakPlate = new THREE.Group();
        var meshPeakBoltPlate = this.getExtrudedMesh( [
            new Vector2(-boltPlateMargin.x,this.dimensions.depthPeak+ boltPlateTopMargin),
		    new Vector2(boltPlateMargin.x,this.dimensions.depthPeak + boltPlateTopMargin),
		    new Vector2(boltPlateMargin.x,- boltPlateMargin.y),
		    new Vector2(-boltPlateMargin.x,- boltPlateMargin.y)
        ],
            MaterialHelper.getFrameMaterialWithTexture(this.texFrame, this.rafterColor, repeatX, repeatY)
        )
        
        
        let peakPlatePosZ = this.bottomFlangeLength;
        this.peakPlate.add(meshPeakBoltPlate);

        // peak bolt plate bolts
        nutPos = []; // array of y positions 
        nutPos.push( -(boltPlateMargin.y * 2/3)) // low low
        nutPos.push( (boltPlateMargin.y * 2/3)) // low high
        if(this.singleSlope)
            nutPos.push( this.dimensions.depthHaunch/2) // low high
        nutPos.push( this.dimensions.depthPeak - (boltPlateMargin.y * 2/3)) // high lo
        if(this.includeBoltPlateTop)
            nutPos.push( this.dimensions.depthPeak + (boltPlateMargin.y * 2/3)) // high high

        nutPos.forEach((y)=>{
            new _3dFastenerHex(this.peakPlate, 1, 
                new Vector3(boltPlateMargin.x * 2/3,y,0),  // pos
                nutRot, // quaternion
            )   
            new _3dFastenerHex(this.peakPlate, 1, 
                new Vector3(-boltPlateMargin.x * 2/3,y,0),  // pos
                nutRot, // quaternion
            )
        });

        
        this.peakPlate.position.z = peakPlatePosZ;
        if(!this.singleSlope){
            this.peakPlate.rotation.x = this.pitchAngle
        }
        
        // position the origin of the peak plate to match how it was built
        this.peakPlate.position.y = this.dimensions.depthHaunch-this.dimensions.depthPeak; 
        this.group.add(this.peakPlate);

    }

    getExtrudedMesh(points, material){
        //TODO: this is duplicate code with rafterStraight
        let mesh = ShapeHelper.getMeshFromPoints( 
            points,        
        this.extrusionSettings,
        material );        
        mesh.layers.set(CORE.layers.frame);
        mesh.castShadow=true;
        mesh.receiveShadow=true;
        return mesh;
    }

}