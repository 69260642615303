import * as THREE from 'three';

export default class SpriteHelper{
//https://github.com/stemkoski/stemkoski.github.com/blob/master/Three.js/Labeled-Geometry.html

            
    static makeTextSprite(message, scale, parameters )
    {
        if ( parameters === undefined ) parameters = {};
        
        var fontface = parameters.hasOwnProperty("fontface") ? 
            parameters["fontface"] : "Arial";
        
        var fontsize = parameters.hasOwnProperty("fontsize") ? 
            parameters["fontsize"] : 20;
        
        var borderThickness = parameters.hasOwnProperty("borderThickness") ? 
            parameters["borderThickness"] : 1;
        
        var borderColor = parameters.hasOwnProperty("borderColor") ?
            parameters["borderColor"] : { r:0, g:0, b:0, a:1.0 };
        
        var backgroundColor = parameters.hasOwnProperty("backgroundColor") ?
            parameters["backgroundColor"] : { r:255, g:255, b:255, a:1.0 };

        var canvas = document.createElement('canvas');        
        var context = canvas.getContext('2d');        
        context.font = "Bold " + fontsize + "px " + fontface;      
        
        // get size data (height depends only on font size)
        var metrics = context.measureText( message );        
        let height = fontsize * 1.3 + borderThickness;
        canvas.height = height//metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
        var textWidth = metrics.width;
        let width = textWidth + borderThickness+5;
        canvas.width = width;
        

        var context = canvas.getContext('2d');        
        context.font = "Bold " + fontsize + "px " + fontface;      
        
        // background color
        context.fillStyle   = "rgba(" + backgroundColor.r + "," + backgroundColor.g + ","
                                    + backgroundColor.b + "," + backgroundColor.a + ")";
        // border color
        context.strokeStyle = "rgba(" + borderColor.r + "," + borderColor.g + ","
                                    + borderColor.b + "," + borderColor.a + ")";

        context.lineWidth = borderThickness;
        let x = borderThickness/2;
        let y = borderThickness/2
        let bgWidth = width-2*borderThickness;
        let bgHeight = height - borderThickness; 
        this.roundRect(context, x,y, bgWidth, bgHeight, 6);
        // 1.4 is extra height factor for text below baseline: g,j,p,q.
        
        // text color
        context.fillStyle = "rgba(0, 0, 0, 1.0)";

        context.fillText( message, borderThickness, fontsize + borderThickness);
        
        
        // canvas contents will be used for a texture
        var texture = new THREE.CanvasTexture(canvas) 
        texture.needsUpdate = true;

        var spriteMaterial = new THREE.SpriteMaterial( 
            { map: texture} );
        var sprite = new THREE.Sprite( spriteMaterial );

        // 0 => 0
        // ?? => finalWidth/2
        // 1 => 150 

        // 0 / 150 => 0
        // 44/150 => 
        //150 / 150 => 1
        

        
        //sprite.center.set( .1,  1);
        //sprite.scale.set(300,150,1.0);
        if(scale)
            sprite.scale.copy(scale);
        else
            sprite.scale.set(width,height,1.0);
        
        return sprite;	
    }

    // function for drawing rounded rectangles
    static roundRect(ctx, x, y, w, h, r) 
    {
        ctx.beginPath();
        ctx.moveTo(x+r, y);
        ctx.lineTo(x+w-r, y);
        ctx.quadraticCurveTo(x+w, y, x+w, y+r);
        ctx.lineTo(x+w, y+h-r);
        ctx.quadraticCurveTo(x+w, y+h, x+w-r, y+h);
        ctx.lineTo(x+r, y+h);
        ctx.quadraticCurveTo(x, y+h, x, y+h-r);
        ctx.lineTo(x, y+r);
        ctx.quadraticCurveTo(x, y, x+r, y);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();   
    }

}