import {CORE} from '../_spec.js'
import {Vector2, Vector3} from 'three'

export default class vHelper{
    static addToCopy(v, x,y,z){
        let newV = new Vector3().copy(v);
        if(x)
        newV.x+=x;
        if(y)
        newV.y+=y;
        if(z)
        newV.z+=z;
        return newV;
    }


    static getVector2XZ(v){
        return new Vector2(v.x,v.z);
    }
    static getFloat32ArrayFromPoints(points){
        let float32Points =new Array();
            
        points.forEach((p)=>{
            float32Points.push(p.x);
            float32Points.push(p.y);
            float32Points.push(p.z);
        })
        let verts = new Float32Array(float32Points.length);
        verts.set(float32Points);
        return verts;
    }


    
    static generateIndices(segX, segY){
        const indices = [];

        for ( let i = 0; i < segX; i ++ ) {

            for ( let j = 0; j < segY; j ++ ) {

                const a = i * ( segX + 1 ) + ( j + 1 );
                const b = i * ( segX + 1 ) + j;
                const c = ( i + 1 ) * ( segY + 1 ) + j;
                const d = ( i + 1 ) * ( segY + 1 ) + ( j + 1 );

                // generate two faces (triangles) per iteration

                indices.push( a, b, d ); // face one
                indices.push( b, c, d ); // face two

            }
        }
        return indices;
  
    }

}