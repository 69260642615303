<template>
  <div>
    
        <div class="lvl2Header">Add Porches</div>
        <div>
            <table style='margin:0px auto 5px auto;'>
                <tr>

                    <td>
                        <button class="porchToggle" @click="_wrapShed(CORE.sides.backSide, CORE.sides.leftEnd)" :class="{selected: hasBackLeftPorch}" :disabled="backLeftDisabled" title='Back Left'> 
                            <!-- <font-awesome-icon icon="fa-regular fa-square-arrow-up-left" size="xl"/> -->                        
                            <font-awesome-icon icon="fa-duotone fa-arrow-up-left" size="xl"/>
                            
                        </button>
                    </td>
                    <td>
                        <button class="porchToggle" @click="togglePorch(CORE.sides.backSide)" :class="{selected: hasBackSidePorch}" :disabled="isBackPorchDisabled">
                            Back
                            <!--<br /> <font-awesome-icon icon="fa-regular fa-square-arrow-up" size="xl"/>  -->
                        </button>
                    </td>
                    <td>
                        <button class="porchToggle" @click="_wrapShed(CORE.sides.rightEnd, CORE.sides.backSide)" :class="{selected: hasBackRightPorch}" :disabled="backRightDisabled" title='Back Right'>
                            <!-- <font-awesome-icon icon="fa-regular fa-square-arrow-up-right" size="xl"/> -->
                            <font-awesome-icon icon="fa-duotone fa-arrow-up-right" size="xl"/>
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <button class="porchToggle" @click="togglePorch(CORE.sides.leftEnd)" :class="{selected: hasLeftSidePorch}" :disabled="isDisabled">
                            <!-- <font-awesome-icon icon="fa-regular fa-square-arrow-left" size="xl" /> -->                        
                            <!-- <font-awesome-icon icon="fa-duotone fa-arrow-left-from-line" size="xl" /> -->
                            Left
                        </button>
                    </td>
                    <td>
                        <font-awesome-icon icon="fa-duotone fa-location-crosshairs" size="xl" :title="structureName"/>
                    </td>
                    <td>
                        <button class="porchToggle" @click="togglePorch(CORE.sides.rightEnd)" :class="{selected: hasRightSidePorch}" :disabled="isDisabled">
                            <!-- <font-awesome-icon icon="fa-regular fa-square-arrow-right" size="xl"/> -->
                            Right
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <button class="porchToggle" @click="_wrapShed(CORE.sides.leftEnd, CORE.sides.frontSide)" title='Front Left' :class="{selected: hasFrontLeftPorch}" :disabled="frontLeftDisabled">
                            <!-- <font-awesome-icon icon="fa-regular fa-square-arrow-down-left" size="xl" /> -->
                            <font-awesome-icon icon="fa-duotone fa-arrow-down-left" size="xl"/>
                        </button>
                    </td>
                    <td>
                        <button class="porchToggle" @click="togglePorch(CORE.sides.frontSide)" title=''  :class="{selected: hasFrontSidePorch}" :disabled="isDisabled">                    
                            <!-- <font-awesome-icon icon="fa-regular fa-square-arrow-down" size="xl" /> <br /> -->
                            <span>Front</span>
                        </button>
                    </td>
                    <td>
                        <button class="porchToggle" @click="_wrapShed(CORE.sides.frontSide, CORE.sides.rightEnd)" title='Front Right' :class="{selected: hasFrontRightPorch}" :disabled="frontRightDisabled">                    
                            <!-- <font-awesome-icon icon="fa-regular fa-square-arrow-down-right" size="xl"  /> -->
                            <font-awesome-icon icon="fa-duotone fa-arrow-down-right" size="xl"/>
                        </button>
                    </td>
                </tr>
            </table>
        </div>
    </div>
  
</template>

<script>
import { trueGray } from 'tailwindcss/colors.js';
import {CORE} from '../js/_spec.js'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  props: { },
  data: ()=>{
      return {
         CORE,
      };
  },
  mounted(){
    
  },  
  computed:{
    ...mapGetters('designModule', [
      'structure',
      'selectedComponent',
    ]),

    structureName(){
        if(this.structure)
            return this.structure.design.name;
        return "";
    },
    selectedComp(){
        return this.selectedComponent     
    },
    isDisabled(){
        if(this.selectedComp){
            if(this.selectedComp.design.type === CORE.components.structure || this.selectedComp.design.type === CORE.components.leanTo)
             return false;
        }

        return true;
    },
    isParentLeanTo(){
        if(this.selectedComp && this.selectedComp.design.type === CORE.components.leanTo){
            return true
        }
        return false;
    },
    isBackPorchDisabled(){
        if(!this.isDisabled){
            if(!this.isParentLeanTo)
                return false
        }
        return true;
      },
    isMainStructure(){
        return this.selectedComp && this.selectedComp.design.type === CORE.components.structure;
    },
    frontSidePorch(){
        if(!this.isMainStructure && !this.isParentLeanTo)
            return null;
        return this.selectedComp.getFrontSidePorch();     
    },    
    backSidePorch(){
        if(!this.isMainStructure)
            return null;
        return this.selectedComp.getBackSidePorch();
    },
    leftSidePorch(){
        if(!this.isMainStructure && !this.isParentLeanTo )
            return null;
        return this.selectedComp.getLeftEndPorch();
    },
    rightSidePorch(){
        if(!this.isMainStructure && !this.isParentLeanTo)
            return null;
        return this.selectedComp.getRightEndPorch();
    },    
    hasFrontSidePorch(){
        if(!this.isMainStructure)
            return null;
        return this.selectedComp.hasFrontSidePorch();
        // return this.frontSidePorch !== null        
    },
    hasBackSidePorch(){
        if(!this.isMainStructure)
            return null;
        return this.selectedComp.hasBackSidePorch();
        // return this.backSidePorch !== null        
    },
    hasLeftSidePorch(){
        if(!this.isMainStructure)
            return null;
        return this.selectedComp.hasLeftEndPorch();
    },
    hasRightSidePorch(){
        if(!this.isMainStructure)
            return null;
        return this.selectedComp.hasRightEndPorch();
        // return this.rightSidePorch !== null        
    },
    hasFrontLeftPorch(){        
        let frontPorch = this.frontSidePorch
        if(frontPorch !== null)
            return frontPorch.left.wrapEnabled && frontPorch.left.wrapValid;
        return false;
    },
    hasFrontRightPorch(){        
        let frontPorch = this.frontSidePorch
        if(frontPorch !== null)
            return frontPorch.right.wrapEnabled && frontPorch.right.wrapValid;
        return false;
    },
    hasBackLeftPorch(){        
        let backPorch = this.backSidePorch
        if(backPorch !== null)
            return backPorch.right.wrapEnabled && backPorch.right.wrapValid;
        return false;
    },
    hasBackRightPorch(){        
        let backPorch = this.backSidePorch
        if(backPorch !== null)
            return backPorch.left.wrapEnabled && backPorch.left.wrapValid;
        return false;
    },
    hasFullWrapPorch(){
        return this.hasFrontLeftPorch && 
        this.hasFrontRightPorch &&
        this.hasBackLeftPorch && 
        this.hasBackRightPorch 
    },
    backRightDisabled(){
        // this button does not requires both porches exist
        let backPorch = this.backSidePorch
        let backRightDisabled = false;
        let res = backRightDisabled || this.isDisabled;
        if(backPorch == null) return true;

        if(this.rightSidePorch == null) return true;

        // but if both porches do exist, then they need to match
        backRightDisabled = !backPorch.left.wrapValid; // wrapValid strictly requires both porches exist and match
        
        return res;
    },
    backLeftDisabled(){
        // this button does not requires both porches exist
        let backPorch = this.backSidePorch
        let backLeftDisabled = false;
        let res = backLeftDisabled || this.isDisabled;
        if(backPorch == null) return true;

        if(this.leftSidePorch == null) return true ;

        // but if both porches do exist, then they need to match
        backLeftDisabled = !backPorch.right.wrapValid; // wrapValid strictly requires both porches exist and match
        return res;
    },
    frontRightDisabled(){
        // this button does not requires both porches exist
        let frontPorch = this.frontSidePorch
        let frontRightDisabled = false;
        let res = frontRightDisabled || this.isDisabled;
        if(frontPorch == null) return true;

        if(this.rightSidePorch == null) return true;

        // but if both porches do exist, then they need to match
        frontRightDisabled = !frontPorch.right.wrapValid; // wrapValid strictly requires both porches exist and match
        return res;
    },
    frontLeftDisabled(){
        // this button does not requires both porches exist
        let frontPorch = this.frontSidePorch
        let frontLeftDisabled = false;
        let res = frontLeftDisabled || this.isDisabled;
        if(frontPorch == null) return true;

        if(this.leftSidePorch == null) return true;

        // but if both porches do exist, then they need to match
        frontLeftDisabled = !frontPorch.left.wrapValid; // wrapValid strictly requires both porches exist and match
        return res;
    }
  },

  methods: {
    
...mapActions('designModule',[
        'togglePorch',        
        'wrapShed',
        'fullWrapPorch',
      ]),
    _wrapShed(sideL, sideR){
        this.wrapShed({sideL, sideR});
    },
  },
  components:{
    
  }
}
</script> 

<style scoped>

.porchToggle{
    width: 46px;
  white-space: nowrap;
    border: 1px solid gray; 
  padding: 0 4px;

  border-radius: 4px;;
  background: green;
  color:white;
}

.porchToggle.selected{
    background: #34ab34;
    border: 1px solid white; 
}


button.porchToggle[disabled] {
  background:gray ;
  color: darkgrey;
}

.btnGreen{
    background: green;
}

.btnGreen:hover{
    background: #34ab34;
}

button{
    cursor: pointer;
  }

</style>


