export default class Util{


    static RoundToNearest(val,interval){
        return Math.round(val/interval) * interval;
    }

    static debounce (func, wait) {
        // Originally inspired by  David Walsh (https://davidwalsh.name/javascript-debounce-function)
        // Returns a function, that, as long as it continues to be invoked, will not
        // be triggered. The function will be called after it stops being called for
        // `wait` milliseconds.
        let timeout;
    
        return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
    
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        };
    }
    
    static checkChange(a,b, impactType){
        if(a==b)
            return;
        a = b;
        return impactType;
    }
    
    static isDefined(o){
        return typeof o !== 'undefined';
    }

    static isUndefined(o){
        return typeof o === 'undefined';
    }

    static get Convert(){
        return {            
            inToFt(i){
                return i/12;
            }, ftToIn(f){
                return f*12;
            }
        }
    }


    static deepCopy(o){
        
    }

    static copy(b){
        let a = {}
        if(Array.isArray(b))
            a = new Array();
        this.merge(a, b); //make a copy so that changes can be detected later
        return a;
    }

    static merge(tgtObj,srcObj, overwrite=true){
        //
        for (var key in srcObj) {

            let paType = typeof(tgtObj[key]);
            let pbType = typeof(srcObj[key]);
            let paIsNull = tgtObj[key] === null;
            let pbIsNull = srcObj[key] === null;
            
            //console.log(`copying ${t}`);

            switch(pbType){
                case 'undefined':
                    // for the undefined primitive
                    if(!overwrite)
                        break;
                    
                    if(paType != 'undefined')
                        delete tgtObj[key];
                    
                    break;                
                case 'function':                    
                    tgtObj[key] = srcObj[key];
                    break;
                case 'object':
                    // for objects (and null!)
                    
                    /*
                    cases
                        b[key] is an object
                            1 a[key] is an object: merge with overwrite
                            2 a[key] is null: if overwrite, overwrite
                            3 a[key] is undefined: set it to b[key]
                        b[key] is null
                            4 a[key] is an object: if overwrite, overwrite.
                            5 a[key] is null: do nothing
                            6 a[key] is undefined: set it to b[key]
                    */
                    
                    if(paType === 'undefined')                                                
                        tgtObj[key] = this.copy(srcObj[key]); // case 3 and 6
                    else{
                        if(pbIsNull){
                            this.merge(tgtObj[key], srcObj[key], overwrite); // case 4 & 5
                        }
                        else{
                            if(paIsNull){
                                // case 2
                                // a is null. null is a valid value that should not overwritten unless explicitly instructed
                                if(overwrite)
                                    tgtObj[key]= this.copy(srcObj[key]);
                            }
                            else
                            {
                                // case 1
                                // a is an object, so merge
                                this.merge(tgtObj[key], srcObj[key], overwrite); 
                            }
                        }
                    }    
                    break;
                default: 
                    // for primitive types (boolean, string, number, bigint etc)
                    if(srcObj.hasOwnProperty(key)){
                        if(overwrite){
                            // just force a[key] to get the primitive
                            tgtObj[key]=srcObj[key]
                        } else // otherwise
                        // if a does not have this property or it's value is null
                        if(tgtObj[key] === undefined || tgtObj[key] === null){
                            // set a
                            tgtObj[key] = srcObj[key]
                        }
                    }
                    break;
            }
        }
    }

    
}