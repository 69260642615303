<template>
      <div id="sidebar2Link" class="sb2l" v-on="$listeners" @click="routeTo" :class="{active:active}" >
        <slot></slot>
      </div>        
  </template>
  
  <script>

import { mapState, mapActions, mapMutations } from 'vuex';
  export default {
    name: 'Sidebar2Link',
    props: ['routePath', 'query'],
    data() {
      return {
        linkRoute: null
      };
    },    
    computed: {      
      active(){
        return this.alreadyHere();
      }      
    },
    methods: {
      ...mapMutations('contextModule',['refresh']),
      alreadyHere(){
        if(!this.$route || !this.linkRoute)
          return false;
          console.log(this.$route.path, this.routePath, JSON.stringify(this.$route.query), JSON.stringify(this.query));
        return this.$route.path == this.routePath && 
        (typeof this.query == 'undefined' ||
                  JSON.stringify(this.$route.query) == JSON.stringify(this.query))
      },
      routeTo(e){
        if(!this.linkRoute)
          this.$emit('click',e)
        if(this.alreadyHere())
          this.refresh();
        else
          this.$router.push({path: this.routePath, query: this.query});
      }
    },
    mounted(){      
      this.linkRoute =  this.$router.matcher.match(this.routePath);
    }
  };
  </script>
  
  <style scoped>

.sb2l{
  cursor:pointer;
  margin:1px 5px;
  padding: 4px 1px;
  border-radius: 5px;;  
}

.sb2l.active{
  font-weight:bold;
  background: rgba(93, 130, 233, 0.75);
  color:white;
}

.sb2l:hover{
  background: rgba(136, 145, 200, 0.5);
  color:white;
}

  .sb2l{
    text-decoration: none;
  }
  
  
  </style>