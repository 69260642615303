
    /*

    IT MAKES SENSE for the building to build a structure and position and rotate it
    It also makes sense that the structure detect when it is outdated
    It doesn't make sense that on rebuild, the structure has to position/orient itself.
    problem is that the parent may not know the rebuild happened in order to re-position the child component   

    Should the buliding detect when the structure is outdated,
    should the building just be able to handle rebuilding the structure

    updateComponentDesign
    replaceComponent

    3dobjects are dumb
    - they need to make a group called "group"
    - the parent thing (whether a component or another 3dobject can position the child 3dobject)

    components are not dumb
    - they have the ability to rebuild themselves
    - there is not currently a universal mechanism, to my knowledge, that lets the parent know that the child needs to be rebuilt.
    - this would be pretty easy to implement by passing a parent reference down to the child (and keeping it updated)
    - wall openings do this
    - if the children had a parent reference then the parent could always reposition the child, but is that necessary?
    - the child knows it has rebuilt, why not re-make yourself and leave the group where it?

    

    */


import { CORE, rebuildTypes, inputTypes, impactTypes} from '../_spec.js'
import ComponentHelper from '../helpers/featureHelper.js'
import OptionHelper from '../helpers/optionHelper.js'
import MemHelper from '../helpers/memHelper.js';
import UpdateHelper from '../helpers/UpdateHelper.js';
import * as THREE from 'three';
import TreeNode from '../helpers/TreeNode.js';
import Util from '../utility.js'
import { Vector3 } from 'three';
export default class Base{

    constructor(
        design,
        masterDesign
        ){
        this.group = new THREE.Group();
        
        // TODO: consider always adding a pick group 
        // that way every component has all three
        if(masterDesign)
            this.masterDesign = masterDesign;
        this.components=[];

        if (!design)
            design = this.defaultDesign();

        

        this.migrate(design);
        this.design=design;
        this.assertConfig();
        this.group.name = 'Component: '+ this.getDescription();
        //this.settings = settings;

         // a reference is important here, so that we can fully leverage encapsulation, such that when this objects' size is updated it updates the source (the full design) too.        
        this.built = false;
            
        
        //TODO: rename this rebuildsNeeded
        this.resetRebuildsNeeded();
        this.initRebuildHierarchy();
    }

    assertConfig(){
        if(!this.design.config){
            this.design.config = {};
        }
    }

    initDynamicGroup(){
        this.gDynamic = new THREE.Group();
        this.group.add(this.gDynamic);
        this.gDynamic.name='dynamic'
    }

    initStaticGroup(){
        this.gStatic = new THREE.Group();        
        this.group.add(this.gStatic);
        this.gStatic.name='static'
    }

    initPickGroup(){
        this.gPick = new THREE.Group();        
        this.group.add(this.gPick);
        this.gPick.name='pick'
    }

    destroyDynamicGroup(){
        MemHelper.removeAllChildren(this.gDynamic);
        this.group.remove(this.gDynamic);
        delete this.gDynamic;
    }

    destroyStaticGroup(){
        MemHelper.removeAllChildren(this.gStatic);
        this.group.remove(this.gStatic);
        delete this.gStatic;
    }
    
    destroyPickGroup(){
        MemHelper.removeAllChildren(this.gPick);
        this.group.remove(this.gPick);
        delete this.gPick;
    }

    processImpactFromSelf(){
        
    }

    processImpactFromOther(){
        
    }

    shouldProcessOwnImpact(impact){
        return this.isOwnImpact(impact) && !impact.handled.includes(this.design.id)
    }


    isOwnImpact(impact){
        if(!this.design)
            {
                console.error("design.id is blank")
            }
            if(!impact)
            {
                console.error("impact.id is blank")
            }
        return impact.id === this.design.id;
    }

    hasHandledImpact(impact){
        if(!impact.handled)
            return false;
        return impact.handled.includes(this.design.id)
    }

    initRebuildHierarchy(){                
    }

    resetRebuildsNeeded(){
        this.rebuildTypes = []
    }

    processInput(input){  
        // return the impact of the input              
        switch(input.type){
            case inputTypes.grabject:
                return this.processInputGrabject(input);
            case inputTypes.option:
                return this.processInputOption(input);
        }
    }
    
    processInputOption(input){
        let changeResult = this.applyChange(input.change.options);

        if(!changeResult.context)
            return; // an object in collision may produce a no-op.

        if(changeResult.context.option.changed)
            return UpdateHelper.createImpact(this.design,changeResult.context.option.impactType)
        else if (changeResult.context.canceled) {
            let change = { name: changeResult.context.option.impactType, canceled: changeResult.context.canceled, message: changeResult.context.message} 
            return UpdateHelper.createImpact(this.design, changeResult.context.option.impactType, change)
        }
    }

    processInputGrabject(input){
        if(input.role === CORE.grabjects.position)
            return this.updatePosition(input.relPosition);
        else
            return this.updateSize(input);
    }

    /*
    If the design looks like this:

    structure
        frameSide Front
            Porch1
                wall Front
                wall Back
        frameSide Back
            Porch2
                wall Front
                wall Back
            Porch3
                wall Front
                wall Back

    Editable tree looks like this:

    Structure
        Porch1
            wall front
            wall back
        Porch2
            wall front
            wall back
        Porch3
            wall front
            wall back



    */


    /*
    Design change is propagated down the component tree
    We need to check for collisions as soon as possible
     - changes  originated by a moveable object can be cancelled
     - changes  originated by an umoveable object should not be cancelled

    Cascading design changes may result during propagation of the originating design change
    




Example: A side porch updated to match three other porches.
    All four porches are impacted: the validity of component options is impacted for all four.

    original porch design changes
    that change is propagated down the component tree
    that propagation generates two other design changes (note the dispatcher)
    We check for collisions, if appropriate (windows and doors only for now)

    if no collisions, continue propagating the generated design changes
    if a component receives a change it dispatched, it can ignore it to prevent infinite changes.

    Once no changes are generated, the design change has been resolved.
    Now we can propagate a re-build down the component tree


    Problem: We can't check for collisions without re-building the object. Only the moveable objects needs the collision check.
    

    


    


Example 2: Fully wrapped porch pitch is adjusted. 
    All four porch desings need to be impacted.

    - design change types:
      - Add
      - Remove
      - Position
      - Grabject
      - Option

    one porch grabject is changed:
    update that design
    first-pass: propagate gen1 design change to the entire tree
    second-pass: propagate gen2 design changes (x2) to the entire tree
        This generates two identical design changes for the porch opposite the originally adjusted porch, so 
        1) process both (wasteful)
        2) only process one: keep the first (ignore the second)
        3) only process one: keep the second one (overwrite the first)
    third-pass: propagate gen3 design changes (x1)
        This generates no design changes
    
    The design is now resolved

    Application of design changes generates buildUpdate (Id, type)
     

    As the design is resolved, we have to keep up with the buildUpdates 
    
    We need to boil the buildUpdates down to get the real short list of changes that is appropriate
    Changes:
     - blueprint (rebuild my building)
     - parent (rebuild my parent)
     - self (rebuild me)
     - none 

    a single blueprint trumps all others.
    a parent update trumps any child's parent update.
    a parent update trumps a self update from the same component
    a self update trumps any child self update
    

    to actually improve granularity of what's built, we need very specific update types
     - length
     - width
     - height
     - roof style
     - roof pitch
     - 
    This specific change approach is accurate and possible but way too much work to tackle right now in the midst of other changes and defects.
    This would unlock incredibly smooth editing IF and ONLY IF position grabject changes are supported.
    And that's tough because position changes may change parents. 
    Maybe we can propagate a change only after the parents have changed and been updated.

    

    

    
    */





    detectImpact()
    {
       

        // can return additional impacts

        // allows for overriding without impeding calls to children or requiring overrides call super.processComponentChange() at the end        
    }
    
    processRebuilds(){
        //console.log(`processRebuilds ${this.design.id} ${this.getDescription()}`)
        this.rebuildTypes.forEach((type)=>{
            this.processRebuild(type.id);
        });
        this.resetRebuildsNeeded();
    }

    processRebuild(type){
        //this.remove();
        //this.build();
    }

    addRebuildNeeded(typeId){
        // we have to keep the list of things to rebuild minimized

        // if type is identical to one already being tracked, ignore type
        // if type is a child of x, ignore type
        // if type is a parent of x, replace x with type to track type instead of x

        if(!this.rebuildTypeRoot)
            return;

        let newType = this.rebuildTypeRoot.findNodeAtOrUnderById(typeId);
        // if this type isn't recognized
        if(!newType)
            // stop, this is a big issue either in the rebuildType tree setup for component , or with the rebuild type being provided
            throw `rebuildType tree does not contain the rebuildtype being added ${typeId}`

        let add = true;
        
        for(let ri = 0;ri<this.rebuildTypes.length;ri++){
            let existingTypeNode = this.rebuildTypes[ri];
            //let existingTypeNode = this.rebuildTypeRoot.findDescendentNodeById(existingType);
            if(!existingTypeNode)
                throw `rebuildType tree does not contain rebuildtype ${existingTypeNode.id}`

            // if the new Type is an existing rebuildType 
            if(existingTypeNode.id === typeId){
                add=false;
                break; // ignore it so the same thing is not built twice
            }

            // if the new Type is a child of an existing rebuildType,
            if(existingTypeNode.hasDescendent(typeId)){
                add=false;
                // ignore it A) so a child is not rebuilt when its parent is already getting rebuilt,
                // and B) so we don't downgrade an existing higher-level (more-encompassing) rebuild type
                break; 
            }
                
            // if the new Type is an ancestor of an existing rebuildType,
            if(existingTypeNode.hasAncestor(typeId)){
                // replace the existing rebuildType with the new one
                this.rebuildTypes[ri] = newType;
                add=false;
           }
           // it is possible add is still true because the rebuildType hierarchy supports branching
           // if there's any branching at all, the new rebuildType may be a sibling or cousin node to an existing rebuildType node 
        }

        if(add)
            this.rebuildTypes.push(newType);
    }

    detectImpactInternal(change)
    {
        let allImpacts = [];
        let impact = this.detectImpact(change)
        if(impact)        
            allImpacts.push(impact);
            
        this.components.forEach((comp) => {            
            let impacts = comp.detectImpactInternal(change);
            if(impacts && impacts.length>0)
                allImpacts.push(...impacts);
        });
        return allImpacts;
    }

    rebuildsNeededIncludes(typeId, exactMatch=true){
        // if looking for an exact match
        if(exactMatch === true){
            // for loop so we can exit immediately for performance reasons (pre-maturely optimzed, given how often this is going to be getting invoked)
            for(let ri=0;ri<this.rebuildTypes.length;ri++) {
                if(this.rebuildTypes[ri].id === typeId)
                    return true;
             }                         
        }
        else{
            // see if typeId is contained implicitly in the rebuildsNeeded by seeing if it's under an existingType in the rebuildType tree

            // does this type even exist in the tree?
            if(!this.rebuildTypeRoot.hasDescendent(typeId)){
                return false;
            }

            // is this type under any of our existing rebuildsNeeded?
            // for loop so we can exit immediately for performance reasons (pre-maturely optimzed, given how often this is going to be getting invoked)
            for(let ri=0;ri<this.rebuildTypes.length;ri++) {
                let existingType = this.rebuildTypes[ri];
                if(existingType.hasDescendent(typeId))
                    return true;
            }            
        }
        return false;        
    }

    processRebuildsInternal(){
        
        // for walls, rebuilding full causes the children to get build from scratch
        // for structureMain, rebuilding full 
        
        //console.log('rb', this.design.type);
        if(this.rebuildTypes.length > 0)
        {
            //console.log('rebuilding: ', this.design.type, this.design.id, this.rebuildTypes)
            this.processRebuilds();            
        }
        else{
            //console.log('NOT rebuilding: ', this.design.type, this.design.id)
        }

        this.processRebuildsInternal_Children();

        this.rebuildsDone();
    }

    rebuildsDone(){
        
    }

    processRebuildsInternal_Children(){
        if(this.components.length>0){
            //console.group();
            this.components.forEach((comp) => {            
                comp.processRebuildsInternal();
            });
            //console.groupEnd();
        }
    }
    
    detectSelection(){        
    }

    detectSelectedInternal(change, excludeFromBroadcast, type){
        // this propagates the data down the component tree
        let affectedDesigns = []; // 
        let design;
        
        if(!excludeFromBroadcast.includes(this.design.id))
            design = this.detectSelection(change, type);

        if(design) // if this component was affected, keep the design 
            affectedDesigns.push(design); //

        this.components.forEach((comp) => {            
            let designs = comp.detectSelectedInternal(change, excludeFromBroadcast, type);
            if(designs && designs.length>0)
                affectedDesigns.push(...designs);
        });
        return affectedDesigns;
    }

    hideAllGrabjectsInternal(){
        this.showGrabjects(false);
        this.components.forEach((comp) => {            
            comp.hideAllGrabjectsInternal();
        });
    }

    getTreeItemData(){
        return {
            collided: this.design.collided,
            selected: this.design.selected,
            description: this.getDescription(),
            id: this.design.id,
        }
    }

    getEditableComponentTreeItems(){
        // for each child component in this component
        //  if that component is editable, 
        //      include it
        //  otherwise, 
        //      include any of its children

        let comps = [];
        // for every real child
        this.components.forEach((realComp) => {

            if(realComp.design.type=== CORE.components.foundation)
                return;

            // if the real child component is editable,
            if(realComp.canEdit()){
                // make a copy of the real child
                let compCopy = realComp.getTreeItemData();
                // update the list of child components under the copy to be only truly editable components
                compCopy.components = realComp.getEditableComponentTreeItems()                
                comps.push(compCopy);
            }
            // otherwise, the real component is not editable,
            else{
                // ignore this real component, but search under it for other editable components
                // example is Structure (Editable) > frameSide (not editable) > porch (editable) 
                comps.push(...realComp.getEditableComponentTreeItems());
            } 
        });
        
        return comps;
    }

    getEditableComponentTreeChildren(){
        let comps = []
        return 
    }

    canEdit(){
        // this is used by the component tree vue components to 
        let type = this.design.type;
        let componentClass = ComponentHelper.getClassOfComponentType(type);        
        let editable = componentClass.canEdit();
        return editable
    }

    migrate(){
        
    }

    migrateTo2(){
        // empty but declared so that it can always be invoked
    }

    

    drawDistanceMarkers(){
        
    }

    applyChange(options){
        let context = null;
        let _this = this;
        
        let oldValues = this.getOptions();        
        // sort this since options so as to keep indices aligned betwen oldValues and options, since options must also be sorted by procOrder (process order).
        oldValues = oldValues.sort((a,b)=>{return a.procOrder - b.procOrder}); 
        
        let optionKeys = options.map(o =>o.key); // make a list of just the keys of options to apply

        // filter the list of oldValues by the keys of options being applied so the indicies align
        oldValues = oldValues.filter((ov)=>{
            return optionKeys.includes(ov.key);
        })

        oldValues = oldValues.map(o => o.value); // make oldValues just the values

        options.sort((a,b)=>{return a.procOrder - b.procOrder}); // sort these to process them in order.

        options.forEach((opt, oIndex) => {
            let value = opt.value;
            if(opt.type==='nud'){
                value = Number(value)
                oldValues[oIndex] = Number(oldValues[oIndex]);
            }
            let fnOutValue = value;
            if(opt.fnOut) // this is used to convert option values as their processed OUT of the menu
                fnOutValue = opt.fnOut(value);
            
            let changed = false;
            if(opt.fnHasChanged) // this is used to notify the option that it's value has changed
                changed = opt.fnHasChanged(oldValues[oIndex],value);
            else
            {
                let t = typeof(value);
                if(Object.is(NaN, value) && Object.is(NaN, oldValues[oIndex])) // because some bozo added nud options with null values and those become NaN, and NaN!=Nan and NaN!==NaN
                    changed=false;
                else
                    changed = value !== oldValues[oIndex]; // frameType needs to compare true/false, not bolt/weld
            }

            if(changed){
                opt.changed = true;
                if(opt.fnChange) {
                    // apply this value in an option-specific manner
                    let result = opt.fnChange(fnOutValue) // roofType needs fnOutValueHere
                    if (result && result.canceled) {
                        opt.changed = false;
                        context = {option: opt, canceled: result.canceled, message: result.message}
                    }
                    else
                        context = {option: opt};
                } else {
                    // generically apply this value
                    OptionHelper.setValueOfProperty(_this.design, opt.key, value);
                    context = {option: opt};
                }
                //context = {option: opt}; // this is used for wallbase when leftOpenBays or rightOpenBays changes
            }
        });

        return {
            context  // allows the component being rebuilt to consider the context of change (Specifically, walls need this for the pickDetection mesh updates)
        };
    }

    getOptions(){
        let options = this.getOptionsSpec();
        if(!options)
            return [];
        let _this = this;
        options.forEach((opt)=>{   
            opt.componentId = _this.design.id;         
            let value;
            try
            {
                value = OptionHelper.getValueOfProperty(_this.design, opt.key);
            }
            catch{
                return;
            }

            if(opt.fnIn) // this is used to convert option values during loading IN to the menu
                value = opt.fnIn(value);  // this affects the _this.design
            opt.value = value;
            if(opt.enabled!==undefined && typeof opt.enabled === 'function')
                opt.enabled = opt.enabled(opt.value);

            if(!opt.procOrder)
                opt.procOrder = 99;
        });

        if(this.design && this.design.type){
            let classType = ComponentHelper.getClassOfComponentType(this.design.type)
            if(classType.canDelete())
                options.push(OptionHelper.delete())
            if(classType.canCopy())
                options.push(OptionHelper.copy())            
            if(classType.canCenter())
                options.push(OptionHelper.center())
        }

        
        return options;
    }
    
    getOptionsSpec(){
        // add an object to every component that specifies what part of the component's design is editable
        // the properties of those options, etc.

        // use that object here        
        //throw "getOptionsSpec must be implemented by subclass";
        return null;
    }

    static canClick(){
        return true;
    }
    static canEdit(){
        throw "canEdit Must be implemented by subclass";
    }
    static canDelete(){
        return false;
    }
    static canCopy(){
        return false;
    }
    static canCenter(){
        return false;
    }

    getAllComponentIds(){
        let componentIds=[];
        this.components.forEach(function(c){
            componentIds.push(c.design.id);
            let childComponentIds = c.getAllComponentIds(); // for debugging
            componentIds.push(...childComponentIds)
        })        
        return componentIds;
    }


    removeComponentById(id){
        this.components = this.components.filter(c=>c.design.id !== id)
    }

    addComponent(c){
        if(!c)
            return;
        if(this.structureConfig)
            c.structureConfig = this.structureConfig;
        c.masterDesign = this.masterDesign;
        this.components.push(c);
    }
    

    getChildComponentById(id){
        return this.getComponentById(id, false);
    }

    

    getComponentById(id, recurse = true){
        let result = null;
        this.components.forEach(function(c){ 
            if(result!=null)
                return;
            if(c.design.id === id)
                result = c;
            else if(recurse)
                result = c.getComponentById(id);
        });         
        return result;   
    }

    getComponents(){
        let components=[];
        this.components.forEach(function(c){
            components.push(c);
            let childComponents = c.getComponents(); // for debugging
            components.push(...childComponents)
        })        
        return components; //[...this.front.objects,...this.back.objects,...this.left.objects,...this.right.objects];
    }
    
    buildComponents(){

        if(!this.design)
            return;

        
        this.design.components.forEach((d)=>{
            // design d is guaranteed to be present
            // component c is not guaranteed to be present yet (like in the case of bays)
            let c = this.components.find((c)=> c.design.id === d.id)
            if(!c && d.type !== 'bay'){
                console.log('c not found');
            }
            this.buildComponent(c, d);

        })
//        if(this.components)
            //this.components.forEach((c)=>{
                //this.buildComponent(c);
            //});

        if(this.design.pseudoComponents){
            this.removePseudoComponents();
            this.pseudoComponents = [];
            this.design.pseudoComponents.forEach((pc)=>{
                let c = ComponentHelper.createComponentWithDesign(pc);
                this.buildComponent(c);
                this.pseudoComponents.push(c);
            })
        }
    }

    removePseudoComponents(){
        if(!this.pseudoComponents)
            return;
        this.pseudoComponents.forEach((c)=>{
            c.remove();
        })
    }
    
    buildComponent(des, buildContext){
        if(des.type === CORE.components.null)
            return;
        let c = ComponentHelper.createComponentWithDesign(des)
        c.masterDesign = this.masterDesign;
        c.build();
        this.components.push(c);
        return c;
    }



    
    defaultDesign(){
        return null;
    }
    
    showGrabjects(visible){
        if(this.isRotationInputEnabled===false && visible)
            return;
            
        if(this.grabjectGroup){
            this.grabjectGroup.visible = visible;
        }
    }
    
    parentComponentTypes(){
        // used when moving a grabject to get a list of component types this feature can land on
        throw "parentComponentTypes Must be implemented by subclass";
    }

    // required by object interface
    update(center, dist, parent){        
        throw "update Must be implemented by subclass";
    }

    // required by object interface
    getContextMenuPosition(){    
        // this returns the position of the contextMenuObject (created in build function) 
        // in terms of world position (considering all object groups, and transforms)        
        throw "getContextMenuPosition Must be implemented by subclass";
    }
    
    getTypeDisplayName(){
        throw "getTypeDisplayName Must be implemented by subclass"
    }
    // required by object interface
    getDescription(){
        throw "getDescription Must be implemented by subclass";
    }
    
    // required by object interface
    styleCollision(colliding) {
        this.colliding = colliding;
        if(colliding)
            this.matGlass.color = new THREE.Color(0xff0000);
    }
    // required by object interface
    getCollisionClass(){
        throw "getCollisionClass Must be implemented by subclass";
    }
    // required by object interface
    getCollisionZones() {
        return [this.zone];
    }
    // required by object interface
    build() {
        
    }

    remove(){
        
    }

    create(){
        
    }
    destroy(){
        this.components = [];
    }

    getInitialGrabjectUserData(){
        return {
            role: CORE.grabjects.position,
            type: CORE.components.grabject,
            parent:{
                id: this.design.id,
                type: this.design.type
            }
        };
    }
 
    applyPreset(a,b){
        if(a===null)
            return;
        for (var key in b) {
            // if this is specific to b
            if(b.hasOwnProperty(key)){                
                if(typeof b[key] === 'object'){
                    this.applyPreset(a[key], b[key]);
                }else
                    a[key] = b[key];
            }
        }
    }

    removeParentObjects(){
        if(this.parentObjs){
            this.parentObjs.forEach((obj)=>{                
            });
        }
    }

    stopEditing(){
        this.removeParentObjects();
    }

    delete(){
        this.removeParentObjects();
    }

    // required by object interface
    init(design) {
        this.migrate(design);
        //separate from constructor so that pending objects can be redefined (re-init'd) on the fly
        this.design = design;
    }   

    removeComponent(comp){
        let index = this.components.indexOf(comp);
        this.components.splice(index, 1);
    }

    
}