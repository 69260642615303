import { CORE,impactTypes  } from '../_spec.js';
import * as THREE from 'three';
import { Quaternion, Vector3 } from 'three';
import Wall_End from "./Wall_End.js";
import LEW from "./Wall_LEW.js";
import BayEndLeft from "./BayEndLeft.js";
import BayEndRight from "./BayEndRight.js";
import OptionHelper from '../helpers/optionHelper.js'
import _3dDistHori from '../3d/DistHori.js'

export default class TransversePartition extends Wall_End
{
    /*
        transverse partition is needed for "inset EW"
        it is essentially a LEW or REW
        it can only be placed on a frame line
        it cannot be placed on the EW.



        sequencing:
         - The better sequence would have been to break endskirts first by adding partitions and leaving the EW in place.
         - We already bit off the work to fix bays so we're in the middle of two enhancements that impact all openings and all walls, which are the most complex parts.


    */


    constructor(
        masterDesign,
        des,
        structureConfig, 
        length,        
        maxHeight,
        trimMaterials,
        cbDesignChange,
        girtHeights,
        supportsWainscot,
        footerCollisions,
        addToQuoteLayer,
        allowDripTrim,
        insulation,
        pitchRatio,
        roofType,
        frameType,
        distToNextFrameline,
        stopFrameLine, // stopFrameLine
        beamColor,
        girtColor,
        options, // shedHoles
        ){
    
    // figure out which type of wall to build
    des.type = CORE.components.tvp;
    let typeDetail
    let facePoz
    if (des.sheetingLeft) {
        typeDetail = CORE.sides.leftEnd;
        facePoz = true;
    } else if(des.sheetingRight) {
        typeDetail = CORE.sides.rightEnd;
        facePoz = false;
    }

    super(masterDesign,des, structureConfig,length,maxHeight, 
        facePoz, 
        trimMaterials,
        cbDesignChange,//() => {},
        girtHeights,
        typeDetail, //typeDetail
        supportsWainscot,
        footerCollisions,
        addToQuoteLayer,
        allowDripTrim,
        insulation,
        pitchRatio,
        roofType,
        frameType,
        distToNextFrameline,
        stopFrameLine, 
        beamColor,
        girtColor,
        options, //shedHoles
        )

    this.frameLine = des.frameLine;

    this.groupBays = new THREE.Group();
    this.groupBays.name = "bays";
    this.group.add(this.groupBays);
    this.build();


    }

    
    getOptionsSpec(){
        let spec = []
            
        spec.push(...super.getOptionsSpec());
        // TODO: improve how endwall inherits from Wall_Base and TVP inherits from Wall_END so there's not relative splicing locations in each getOptionsSpec()
        spec.splice(3,1); // remove main frame option
        spec.splice(0,1); // remove open wall option
        return spec;
    }

    
    getPositionAlongFSW(){
        if(!this.masterDesign)
            return;
        let parentStructureId = this.design.parent.id
        let parentStructureDesign = this.masterDesign.getComponentDesignById(parentStructureId);
        let parentStructureFrameDesign = parentStructureDesign.getFrame();
        //let parentStructureLength = parentStructureFrameDesign.length;

        let index = this.design.frameLine;
        if(parentStructureDesign.frameLines.length<index)
        return 0;

        let location = parentStructureDesign.frameLines[index];
        return location;    
    }

    getPositionAlongBSW(){
        if(!this.masterDesign)
            return;
        let parentStructureId = this.design.parent.id
        let parentStructureDesign = this.masterDesign.getComponentDesignById(parentStructureId);
        let parentStructureFrameDesign = parentStructureDesign.getFrame();
        let parentStructureLength = parentStructureFrameDesign.length;

        let index = this.design.frameLine;
        if(parentStructureDesign.frameLines.length<index)
        return 0;

        let location = 12*parentStructureLength - parentStructureDesign.frameLines[index];
        return location;
    }

    hasAdjoinedSecondaryStructure(){
        return false;        
    }

    static canEdit(){return true;}

    getStartOfBayAtIndex(columns, index)
    {
        return columns[index];
    }

    getEndOfBayAtIndex(columns, index){
        return columns[index+1];
    }

    buildBay(bayDesign){
        // let designCopy = {}
        //  Util.merge(designCopy,this.design)

        let index = bayDesign.index;

        // use the columns with a source of 'default' only (not those from overhead or sliding doors)
        let defaultColumns = this.getDefaultColumnsFromArray(this.design.columnPositions)
        //let start = defaultColumns[index];
        //let end = defaultColumns[index+1];
        let start = this.getStartOfBayAtIndex(defaultColumns, index)
        let end = this.getEndOfBayAtIndex(defaultColumns, index)

        if(!start || !end) {
            console.log('undefined');
            return;
        }
        // first and last column are offset by 4, so recorrect it
        if(index == 0)
            start.pos -= 4;
        if(index == defaultColumns.length - 2)
            end.pos += 4;

        let bayLength = end.pos - start.pos;

        if (this.design.sheetingLeft) {

            let bay = new BayEndLeft(bayDesign, 
                this.structureConfig,
                start.pos,
                end.pos,
                bayLength,
                this.length,
                this.maxHeight,
                this.trimMaterials,
                undefined,
                this.girtHeights,
                new Vector3(start.pos,0,0), 
                0, 
                this.supportsWainscot, 
                this.footerCollisions, 
                this.addToQuoteLayer,
                this.allowDripTrim,
                this.insulation,
                this.pitchRatio,
                this.roofType,
                this.frameType,
                this.distToNextFrameline,
                0,
                this.beamColor,
                this.girtColor,
                this.options,
                this.wallOpenings,
                );
        


            //this.group.add(bay.group);
            bay.group.position.x = start.pos
            this.groupBays.add(bay.group);
            return bay;
        }

        if (this.design.sheetingRight) {

            let bay = new BayEndRight(bayDesign, 
                this.structureConfig,
                start.pos,
                end.pos,
                bayLength,
                this.length,
                this.maxHeight,
                this.trimMaterials,
                undefined,
                this.girtHeights,
                new Vector3(start.pos,0,0), 
                0, 
                this.supportsWainscot, 
                this.footerCollisions, 
                this.addToQuoteLayer,
                this.allowDripTrim,
                this.insulation,
                this.pitchRatio,
                this.roofType,
                this.frameType,
                this.distToNextFrameline,
                0,
                this.beamColor,
                this.girtColor,
                this.options,
                this.wallOpenings,
                );
        


            //this.group.add(bay.group);
            bay.group.position.x = start.pos
            this.groupBays.add(bay.group);
            return bay;
        } 
    }

    getDescription(){
        let desc = 'Partition'
        if(this.design.sheetingLeft)
            desc += ' (Left)'
        if(this.design.sheetingRight)
            desc += ' (Right)'
        return desc;
    }

    static canDelete(){
        return true;
    }

    getDeleteImpactType(){
        return impactTypes.deletePartition;
    }

    getComponentDistanceMarkers(comp){      
        let des = comp.design;
        //goal 1: build is list of columns with size and position data.
        // sideWalls have column positions only for OH doors

        let low, high, lowColPos,highColPos, offset,lowWinPos,highWinPos;
        let markerMarginZ = 24;

        //console.table(this.design.columnPositions);
        let searchMargin =2;

        if(des.pos == undefined)
            return
        let lowWinX = des.pos.x - des.dim.width/2;
        let highWinX = des.pos.x + des.dim.width/2;
        this.design.columnPositions.forEach((cp)=>{
            // TODO: This seems hacky. 
            // are all columns 4 inches wide? I doubt it.
            cp.width=4;

            if(cp.pos < lowWinX - searchMargin){
                low = cp;
            } else if(cp.pos > highWinX + searchMargin && !high){
                high = cp;
            }
        })

        if(!low || !high)
            return;

        //console.log(low, high)
        let lowColPosX = low.pos; // center of the column width
            // if a corner column, measure from the outside
        if(low.index===0)
            lowColPosX-=low.width/2;// outside edge for first frameline
        if(low.index===this.design.columnPositions.length-1)
            lowColPosX+=low.width/2; // outside edge for last frameline

        let highColPosX = high.pos; // center of the column width
        if(high.index===0)
            highColPosX-=high.width/2;// outside edge for first frameline
        if(high.index===this.design.columnPositions.length-1)
            highColPosX+=high.width/2; // outside edge for last frameline

        lowColPos = new THREE.Vector3(lowColPosX, des.pos.y, des.pos.z);
        highColPos = new THREE.Vector3(highColPosX, des.pos.y, des.pos.z);
        
        offset = new THREE.Vector3(0,0,markerMarginZ);

        lowWinPos = new THREE.Vector3(lowWinX, des.pos.y, des.pos.z);
        highWinPos = new THREE.Vector3(highWinX, des.pos.y, des.pos.z);
        
        let newLowDh = new _3dDistHori(lowColPos, lowWinPos, new THREE.Vector3(),  offset, 1, CORE.layers.openingDimensions, new Vector3(20,10,1));
        this.group.add(newLowDh.group);
        let newHighDh = new _3dDistHori(highColPos, highWinPos, new THREE.Vector3(), offset, 1,  CORE.layers.openingDimensions,new Vector3(20,10,1));
        this.group.add(newHighDh.group);

        return  { 
            newLowDh,
            newHighDh
        }
    }    

}