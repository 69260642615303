
import { Vector3, Quaternion, Matrix4, Matrix3 } from 'three';

export default class MatrixHelper {

    static getM4forM3(M3){
        let m = new Matrix3().copy(M3);        
        let m3 = m.elements;
        let m4 = new Matrix4()
        m4.set( m3[0],m3[3],m3[6],0,
                m3[1],m3[4],m3[7],0,
                m3[2],m3[5],m3[8],0,
                0,0,0,0);
        return m4;
    }

    static getQuaternionFromM3(m3){
       let m4 = MatrixHelper.getM4forM3(m3);        
       return new Quaternion().setFromRotationMatrix(m4);
    }

    static getM3FromQuaternion(q){
        let m4 = new Matrix4().compose(new Vector3(0,0,0),
            q, 
            new Vector3(1,1,1)
            );
            m4 = m4.elements;
        let m3 = new Matrix3();
        m3.set( m4[0],m4[4],m4[8],
                m4[1],m4[5],m4[9],
                m4[2],m4[6],m4[10])
        return m3;
    }
}