<template>
  <div class="">    

       {{lead}}   <select  :disabled="childIsSecondaryStruct" class='value' @change="change" v-model="matingSide">
            <option value='frontSide'>{{sideLabels.fsw}}</option>
            <option value='backSide'>{{sideLabels.bsw}}</option>
            <option value='leftEnd'>Left End</option>
            <option value='rightEnd'>Right End</option>
          </select>
          of <br />
          <span>{{getStructureName}}</span>
          <!-- <select class='value' @change="change" v-model="structureID">
            <option v-for="structure in structureMap" v-bind:key="structure.id"  v-bind:value="structure.id" >{{structure.name}}</option>
          </select> -->
          <template v-if='!child'>
          <br />
          starting at <input type='number' v-model="length"  @change="change" inputmode="decimal"/> ft. 
          <br />
          from {{getLeftReferenceSideName}}
          </template>
  </div>  
</template>


<script>

import { ToggleButton } from 'vue-js-toggle-button' //https://www.npmjs.com/package/vue-js-toggle-button
import BlueprintHelper from '../js/helpers/blueprintHelper';
import { CORE } from '../js/_spec';
import colorPicker from './colorPicker.vue'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  props: {
    part: Object,
    lead: '',
    child: Boolean,
    childIsSecondaryStruct: false,
    sideLabels: {}
  },
  computed:{
    ...mapGetters('designModule', [
      'structureNameMap',
    ]),

    structureMap(){
      let map = this.structureNameMap;
      return map;
    },
    getStructureName(){
      if(this.structureID==0)
        return '';
      let map =  this.structureNameMap;
      console.log(map)
      let obj = map.filter((e)=> e.id == this.structureID);
      console.log(obj)
      if(!obj)
        return `structure ${this.structureID}`;
      if(obj.length!=1)
        throw `exactly one structure was not found with id ${this.structureID}` 
      else{
        console.log(`exactly one structure was found with id ${this.structureID}` )
      }
      return obj[0].name;
    },    
    getLeftReferenceSideName(){
      const {left,  right} = BlueprintHelper.getAdjacentSides(this.matingSide);       
      return CORE.sides.getName(left);
    },
    getReferenceSides(){
      console.log(`references sides for ${this.matingSide}`);
       const {left,  right} = BlueprintHelper.getAdjacentSides(this.matingSide);       
       return [
        {
          value: left,
          text: CORE.sides.getName(left)
          },
        {
          value: right,
          text: CORE.sides.getName(right)
          }
        ]
              
    }
  },
  data: ()=>{
      return {       
        length:0,
        matingSide:'frontSide',
        structureID: 0
      };
  },
  created: function(){
  },
  mounted: function(){
    if(this.part)
    {
      console.log(`part`, this.part)
     const { length,  matingSide, structureID } = this.part;
     
     this.length = length;
     this.matingSide = matingSide;
     this.structureID = structureID;
     console.log(`init mating side ${this.matingSide}`);
     console.log(this.structureID)
    }
  },
  methods: {
    getPart(){
      return {       
        length:this.length,
        matingSide:this.matingSide,
        structureID: this.structureID
      };
    },
    change:function(e){
      this.$emit('change');
    },
  },
  components:{
    ToggleButton,
    colorPicker
  }
}
</script> 

<style scoped>

tr td {
  white-space: nowrap;
}

td{
  padding-top: 1px;
  padding-bottom: 1px;
}

.name{
  text-align: right;
}
.value{
  text-align: left;
}

.option{
  display:block;
  width: max-content;
  margin: 5px;
}

input{
  width: 40px
}

input[type~="radio"]{
  width: 10px
}


input[type~="text"]{
  width: 100%;
}

div.header{
  overflow: auto;  
  text-align: left;
  margin-top: 10px;
  font-weight: bold;
  border-top: solid 1px black;
}

div.contextWrapper{
  display: inline-block;    
  text-align: center;
  z-index: 10001;
  background: lightgray;
  border-radius: 4px;
  border: 1px solid black;
  padding:4px;    
  /* opacity: .85; */
  position:relative;
  top: 0px;
  font-size: 12px;
}

.ib{
  display:inline-block
}

.b{
  display:block;
}

.title{
  display:inline;
}
.close{  
  float:right;
  display:inline;
}
.close svg:hover{
  color:red; 
}
button {
  display: inline;
  margin: 3px;
}

#tblOpts td * {
  margin-right:5px;
}

#tblOpts td input {
  padding-left:2px;
}

button[name='Done'] {
  background:#00a700;
}

button[name='Delete'] {
  background:#df0404;
}

button[name='Cancel'] {
  background:#df0404;
}

</style>
