export default class Util{
    static debounce (func, wait) {
        // Originally inspired by  David Walsh (https://davidwalsh.name/javascript-debounce-function)
        // Returns a function, that, as long as it continues to be invoked, will not
        // be triggered. The function will be called after it stops being called for
        // `wait` milliseconds.
        let timeout;
    
        return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
    
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        };
    }

    static b64toBlob(b64Data, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
    
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
    
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
    
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
    
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    static downloadBlob(blob, fileName) {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        // first replace makes turns any character not a-z, 0-9, underscore, dash into an undersore and the 2nd replace removes any consecutive underscores
        link.download = fileName;
    
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    
    static formattedPrice(price){
        let USDollar = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        maximumFractionDigits: 0
                    });
        return USDollar.format(price);
    }

    static formattedWeight(weight){
        return new Intl.NumberFormat('en-US', {
                        style: 'decimal',
                        maximumFractionDigits: 0
                    }).format(weight) + ' Lbs';
    }
}