<template id="objTemplate">
  <div id="objectRoot" style="position: relative;" class="treeLevel">

    <div class="treeNodeToggle" v-if="hasAnyChildren" style="display:inline-block" @click="toggle">
      <font-awesome-icon v-if="!expanded" icon="fa-duotone fa-plus" size="1x"/>
      <font-awesome-icon v-if="expanded" icon="fa-duotone fa-minus" size="1x"/>
    </div>

    <div @mousemove="hover = true"
        @mouseleave="hover = false" 
        @click="select"   
        @dblclick="toggle"
        v-bind:class='{selected: component.selected, collided: component.collided}'  class='component noTextSelection' >
      <div id='description' ref="description" class='componentSelector' v-if="describe">
        {{component.description}}
      </div>      
    </div>

    <div id="objChildren" v-if="expanded" class="">
        <treeObjectList ref="treeList" :comps="component.components" />
    </div>
    
  </div>
</template>

<script>
//import treeObjectList  from './treeObjectList.vue'
import {mapActions, mapMutations} from 'vuex'
export default {
  name: 'treeObject',
  props: {
    obj: Object,
    describe: false,
  },
  data: ()=>{    
      return {
        hover: false,
        expanded: true,
      };
  },
  computed:{
      component:{
          get(){
              return this.obj;
          }
      },
      hasAnyChildren() {
        return this.component.components && this.component.components.length>0
      }

  },
  mounted(){
  },
  methods: {
    ...mapActions('designModule',[
            'selectComponent',
            'showEditComponentOptions',
        ]),
    expandAll(){
      this.expanded=true;
      if(this.$refs.treeList)
        this.$refs.treeList.expandAll();
    },
    collapseAll(){
      this.expanded=false;
      this.$nextTick(()=>{
      if(this.$refs.treeList)
        this.$refs.treeList.collapseAll();
      });
    },
    toggle(){      
      this.expanded=!this.expanded;
    },
      select: function(){
        this.selectComponent(this.component.id);
      },
      context: function(){
        this.select();
        this.showEditComponentOptions(this.component.id);
      },

  },
  components:{
    treeObjectList: () => import('./treeObjectList.vue')
  }
}
</script> 


<style scoped>

.selected{
  background: white;
  border: solid 1px black;
}

.collided {
  color: red;
  font-weight:bold;
}

.component:hover{
  background: lightgray;
}

.component.selected:hover{
  background: white;
  border: solid 1px black;
}

.componentSelector{  
  border-radius: 4px;  
  display: inline-block;
  white-space: nowrap;  
}

.componentOptions{
  display:block;
  float:right;
  width: 25px;
  background: #4c78b9;
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  border-radius: 5px;
  margin-top:3px;
  margin-right:3px;
  margin-left:3px;
color: lightgray;
}


.componentOptions:hover{
  background: #5e92df;
  color: white;
}

div.component{    
  cursor: pointer;
  border-radius: 4px;  
  display: inline-block;  
  padding: 0 4px;
}

.treeLevel{
    margin-left: 15px;
}

.hideOptionArrow{
  visibility: hidden;
}

.treeNodeToggle{
  position: absolute;
  left: -15px;
  cursor: pointer;


}

.treeNodeToggle:hover{
  color:white;
}


</style>
