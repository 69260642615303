import * as THREE from 'three';
import _3dBase from './Base.js'
import {CORE} from '../_spec.js'
import { Vector3 } from 'three';
import CollisionHelper from '../helpers/CollisionHelper.js';

export default class GirtBase extends _3dBase {
    constructor(lod, a, b){
		super(undefined,undefined,undefined, lod);		
		this.a = a;		
		this.b = b
		this.length = this.a.distanceTo(this.b);
	}
}