<template>
    <div style="    height: calc(100% - 70px - 12px);">
      <!-- 
        70 px for the top menu and stuff above the top panel of the verticalPanelDivider component
        12 px for the divider itself

       -->
      <div ref="top" class="top" :style="{height:`${height}px`}">
        <slot name="top" ></slot>
      </div>
      <div id="divider" ref="divider"  @mouseup="release" @mousedown="grab" >
        <div v-if="grabbed" class="captured" @mousemove="movement">
        </div>
        <div id="grabBar" class="noTextSelection" >
          <div> </div>
          <div> </div>
          <div class="dot"> </div>
          <div class="dot"> </div>
          <div class="dot"> </div>
          <div> </div>
          <div> </div>

          <!-- <div> </div> -->
          <!-- <div> </div> -->
        </div>
      </div>
      <div ref="bottom" class="bottom" :style="{height:`${heightOfBottom}px`}">
        <slot name="bottom" style="overflow-y: scroll;background:blue;" ></slot>
      </div>
    </div>
</template>

<script>
import Util from '../js/utility';

export default {
  computed: {
    heightOfBottom (){
      let height = this.totalHeight - this.height;      
      return height;
    } 
  },
  data: () =>{
    return {
      grabbed:false,
      oldY: 0,
      height: 100,
      totalHeight: 1,
      minBottomHeight: 180,
      minTopHeight: 100
    }
  },
  created(){
  },
  mounted(){
    this.resize();
    let resize = this.resize;
    this.dbResize = Util.debounce(async ()=> {
        
        await resize();
      }, 250),
    window.addEventListener("resize",  this.dbResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.dbResize);
  },
  methods: {
    dbResize: ()=>{},
  resize(){
    this.totalHeight = this.$el.clientHeight;
    let loadedHeight = localStorage.getItem('3dleftPaneDividerPos')        
    if(!loadedHeight)
    {
      loadedHeight = 300; // default for first usage on a machine      
    }    
    
    this.height =Number(loadedHeight);
  },
    catchup(e){
      if(this.grabbed)
        this.processMovementTo(e.clientY);
    },  
    grab(){
      this.grabbed=true;
    },
    release(){
      //console.log('release')
      this.grabbed=false;
      localStorage.setItem('3dleftPaneDividerPos', this.height);
      
    },
    movement(e){
      if(!this.grabbed)
      {
        //console.log('not grabbed')
        return;
      }
      if(!this.oldY){
        this.oldY = e.clientY;
        return;
      }

      this.processMovementTo(e.clientY)
    },
    processMovementTo(newY){      
      let delta = newY - this.oldY;      
      let height = this.$refs.top.clientHeight
      height+=delta;

      // validate top half
      if(height<this.minTopHeight)
        return;

        // validate bottom half
      let newHeightOfBottom = this.totalHeight - height;
      if(newHeightOfBottom < this.minBottomHeight)
        return;      

      // lock it in for the next event
      this.oldY = newY;
      this.height = height;
    },

  },
  components:{
  }
}
</script> 

<style scoped>

#divider{
  background:gray;
  color:black;
  height:15px;
  z-index:5;
  cursor:ns-resize;
  position:relative;
}

#grabBar
{
  display:flex;
  width: 100%;
  justify-content: space-evenly;
  position:absolute;
  top:6px;
}

#grabBar div.dot{
  display:block;
  position: relative;
  background:black;
  border-top: 1px solid white;
  border-left: 1px solid white;
  width:3px;
  height:3px;
  border-radius: 3px;
}

#divider div{
  font-weight: bold;
  line-height: 2px;
}

#divider:hover{
  background:lightgray;
}

.top{
  overflow:hidden
}

.bottom{
  overflow:hidden
}

.captured{
  /* debugging color */
  /* background:green;  */

  /* this height allows for fast movement without the mouse coming off the panel */
  height:300px; 
  
  position:relative;
  top:-150px;
}
</style>


