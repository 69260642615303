//import _3dRafterBase from '../3d/RafterBase.js'

import * as THREE from 'three';
import {CORE} from '../_spec.js'
import { OBB } from 'three/examples/jsm/math/OBB.js'; // https://threejs.org/examples/?q=bounding#webgl_math_obb
import CollisionHelper from '../helpers/CollisionHelper';
import ShapeHelper from '../helpers/shapeHelper';
import { Vector2, Vector3, Quaternion, Matrix4, Matrix3, Object3D } from 'three';
import MaterialHelper from '../helpers/materialHelper.js';
import MatrixHelper from '../helpers/matrixHelper.js';


import _3dRafterBase from './RafterBase.js'
import _3dFastenerHex from './FastenerHex.js'
import Tri from './Tri.js';
import layerHelper from '../helpers/layerHelper.js';

export default class RafterStraight extends _3dRafterBase{
    

    /*

     
        ST = shear triangle allows rafter to mate with vertical seam at bolt plate or weld
        CORE RECTANGLE 

        the straight frame is built in this horizontal orientation (top flange is level)

             ******************************************************
           *ST*              CORE RECTANGLE                  *ST*   
        ******************************************************          
        
        
       
        
        
        // for the topFlange, the peak has shearLength added to it. The column end has shearLength removed from it


    Abstract with inheritance?
        bolt vs weld


    */

    constructor(lod, parentGroup, pos, rot, length, pitch, addCollisionZone, bpFrameLine, bolted, includeBoltPlateTop, rafterColor){
        //rot=0;
        super(parentGroup, pos, rot,lod);
        this.length = length;
        this.pitchAngle = pitch;
        this.absPitchAngle = Math.abs(this.pitchAngle);
        this.addCollisionZone = addCollisionZone;
        this.dimensions = bpFrameLine.materialDim;
        this.bpFrameLine = bpFrameLine;
        this.bolted = bolted;
        this.includeBoltPlateTop = includeBoltPlateTop;
        this.group.name='3d Rafter Straight';
        
        this.rafterColor = rafterColor ? rafterColor : CORE.frame.redOxideColor;
        
        this.extrusionSettings = {
            depth: CORE.steel.thickness_in, //used to be amount
            bevelEnabled: false,
            bevelThickness: 0.1,
            bevelSize: 1,
            bevelSegments: 1,
            material: 0,
            extrudeMaterial: 1
        };
        this.build();
    }
    

	build(){
        // these are first built on end, then sheared, then rotated into place
        // the shearing affects appearance of the corner, so some tweaks in the y dimension fix z dimension discrepancies

        // calculate the length of the top of the shear triangle
        this.shearLength = this.dimensions.depth * Math.tan(this.absPitchAngle);
        
        this.buildRafter();

        let czPositionMarker = new Object3D(); // add an object purely for marking the position of the collisionZone center
        czPositionMarker.position.z = this.length/2; // position the marker locally (it's global position is calculated automatically)
        czPositionMarker.position.y = this.dimensions.depth/2;
        this.group.add(czPositionMarker) // add it to the tree 
		
        this.group.updateMatrix();
        this.group.updateMatrixWorld();
        this.group.updateWorldMatrix(true,true);
        
    }

    getCollisionZone(){
        
            //collision bounding box
            let position = new Vector3(0,0,this.length/2);
            let size = new Vector3(this.dimensions.flange,  this.dimensions.depth, this.length) // x,z,y
            let margin = CollisionHelper.getMargin(4,4,0,0,0,0);
            let zone = CollisionHelper.getZone2(
                this.group,
                position,
                false,
                [
                    CORE.collisions.classes.window,
                    CORE.collisions.classes.emptyFramedOpening,	
                    CORE.collisions.classes.louverVent,
					CORE.collisions.classes.doorWalkin3o7o,
					CORE.collisions.classes.doorWalkin4o7o,
					CORE.collisions.classes.doorWalkin6o7o,
					CORE.collisions.classes.doorRollup,
                    CORE.collisions.classes.doorSliding,
                    CORE.collisions.classes.doorCommGlass,
                    CORE.collisions.classes.doorSectional,
                    CORE.collisions.classes.doorHangar,
                    CORE.collisions.classes.wideOpening,
                ],
                size,
                //this.group.matrixWorld,
                margin,
                'straight rafter');


           return zone;
        
    }

    buildRafter_HD(){
        let repeatX =1, repeatY = 1;
        let halfFlangeWidth = this.dimensions.flange/2;
		// top flange shape
		let meshTopFlange = this.getExtrudedMesh([
            new Vector2(-halfFlangeWidth,0),
		    new Vector2(-halfFlangeWidth,this.length),
		    new Vector2(halfFlangeWidth,this.length),
            new Vector2(halfFlangeWidth,0),
        ],
            MaterialHelper.getFrameMaterialWithTexture(this.texFrame, this.rafterColor, repeatX, repeatY)
        );
        meshTopFlange.rotation.x = Math.PI/2;
        meshTopFlange.position.z = this.shearLength;
        meshTopFlange.position.y = this.dimensions.depth;
        this.group.add(meshTopFlange);

		// depth shape				
		this.meshDepth = this.getExtrudedMesh([
            new Vector2(this.dimensions.depth,0),
            new Vector2(0,this.shearLength),
            new Vector2(0,this.shearLength+this.length),
            new Vector2(this.dimensions.depth,this.length)
        ], MaterialHelper.getFrameMaterialWithTexture(this.texFrame, this.rafterColor, repeatX, repeatY)
        )
		this.meshDepth.position.y = this.dimensions.depth;
        this.meshDepth.position.x = CORE.steel.thickness_in/2;
        this.meshDepth.rotation.y = -Math.PI/2;
        this.meshDepth.rotation.z = -Math.PI/2;
		this.group.add(this.meshDepth);
        
		// bottom flange shape		
		var meshBottomFlange = this.getExtrudedMesh([
            new Vector2(-halfFlangeWidth,0),
		    new Vector2(-halfFlangeWidth,this.length),
		    new Vector2(halfFlangeWidth,this.length),
		    new Vector2(halfFlangeWidth,0)
        ], MaterialHelper.getFrameMaterialWithTexture(this.texFrame, this.rafterColor, repeatX, repeatY)
        );
        meshBottomFlange.rotation.x = Math.PI/2; 
        let y = CORE.steel.thickness_in;       
        meshBottomFlange.position.y = y;
        this.group.add(meshBottomFlange);

		// side plate
		let meshSide = this.getExtrudedMesh([
            new Vector2(-halfFlangeWidth,0),
		    new Vector2(-halfFlangeWidth,this.bpFrameLine.rafterDimYAtColumn),
		    new Vector2(halfFlangeWidth,this.bpFrameLine.rafterDimYAtColumn),
		    new Vector2(halfFlangeWidth,0)
        ],
            MaterialHelper.getFrameMaterialWithTexture(this.texFrame, this.rafterColor, repeatX, repeatY)
        );
        meshSide.rotation.x = this.absPitchAngle;
        meshSide.position.z -= CORE.steel.thickness_in;
		this.group.add(meshSide);

        if(this.bolted){
            this.buildBoltPlate(repeatX, repeatY);
        }

        if(this.addCollisionZone)
            this.meshDepth.userData = {
                collisionZones: [this.getCollisionZone()]
            };
    }

    buildRafter_LD(length){
        length+=CORE.steel.thickness_in*2;
        let repeatX =1, repeatY = 1;
        let flangeWidth = this.dimensions.flange;

		// top flange shape
        let depth = this.dimensions.depth + 0* CORE.steel.thickness_in;
        let mat = MaterialHelper.getFrameMaterial_LD(this.rafterColor)
        // top flange shape
        let geoTopFlange = new THREE.PlaneBufferGeometry(flangeWidth, length, 1,1)
        let meshTopFlange = new THREE.Mesh(geoTopFlange, mat)
        meshTopFlange.rotation.x = Math.PI/2;
        meshTopFlange.position.z = this.shearLength + length/2;
        meshTopFlange.position.y = depth;
        this.group.add(meshTopFlange);

        // depth shape		
		let bl = new Vector3(0,this.shearLength, 0)
		let tl = new Vector3(0,this.shearLength+length, 0)
        let tr = new Vector3(depth,length, 0)
        let br = new Vector3(depth,0,0)
        
		let triTopRight = new Tri(
            'bottom right triangle',
            [
                // counter clock-wise
                br, //bottom right 
                tr, // top right 
                tl, // top left 
            ],
            [
                              
                1.0, 0, // bottom right
                1.0, 1, // top right
                0.0, 1.0 // top left
            ]
        );

        let triBottomLeft = new Tri(
            'top left triangle',
            [
                br, // bottom right
                tl, // top left 
                bl, // bottom left 
            ],
            [
                1.0, 0, // bottom right
                0.0, 1, // top left
                0.0, 0, // bottom left
            ]
        );
            
        let meshDepthBottomLeft = new THREE.Mesh(triBottomLeft.geometry, mat);
        let meshDepthTopRight = new THREE.Mesh(triTopRight.geometry, mat);
		meshDepthTopRight.position.y = depth;
        meshDepthTopRight.rotation.y = -Math.PI/2;
        meshDepthTopRight.rotation.z = -Math.PI/2;
		this.group.add(meshDepthTopRight);
        meshDepthBottomLeft.position.y = depth;
        meshDepthBottomLeft.rotation.y = -Math.PI/2;
        meshDepthBottomLeft.rotation.z = -Math.PI/2;
		this.group.add(meshDepthBottomLeft);
        if(this.addCollisionZone)
            meshDepthBottomLeft.userData = {
                collisionZones: [this.getCollisionZone()]
            };
        
		// bottom flange shape		
        let meshBottomFlange = new THREE.Mesh(geoTopFlange, mat)
        meshBottomFlange.rotation.x = Math.PI/2;
        meshBottomFlange.position.z = this.shearLength + length/2;        
        this.group.add(meshBottomFlange);

		// side plate
        let geoOutside = new THREE.PlaneBufferGeometry(flangeWidth, this.bpFrameLine.rafterDimYAtColumn, 1, 1);
        let meshSide = new THREE.Mesh(geoOutside, mat);
        meshSide.rotation.x = this.absPitchAngle;
        meshSide.position.z = CORE.steel.thickness_in;
        meshSide.position.y = this.bpFrameLine.rafterDimYAtColumn/2;;
		this.group.add(meshSide);

        if(this.bolted){
            this.buildBoltPlate(repeatX, repeatY);
        }
    }

    buildRafter(){
        if(this.isLowDetail())
            this.buildRafter_LD(this.length)
        else 
            this.buildRafter_HD()        
        layerHelper.setGroup(this.group, CORE.layers.frame, true);
    }

    buildBoltPlate_HD(){
        let repeatX = 1;
        let repeatY = 1;
        let boltPlateMargin = new Vector2(4, 4);
        let boltPlateTopMargin = 4;
        if (!this.includeBoltPlateTop)
            boltPlateTopMargin = 0;
        this.haunchPlate = new THREE.Group();

        //let haunchDepth = this.dimensions.depth - CORE.roof.purlin.dim.thickness;
        // haunch bolt plate        		
        var meshHaunchBoltPlate = this.getExtrudedMesh([
            new Vector2(-boltPlateMargin.x, -boltPlateMargin.y),
            new Vector2(-boltPlateMargin.x, boltPlateTopMargin + this.dimensions.depth),
            new Vector2(boltPlateMargin.x, boltPlateTopMargin + this.dimensions.depth),
            new Vector2(boltPlateMargin.x, -boltPlateMargin.y)
        ],
            MaterialHelper.getFrameMaterialWithTexture(this.texFrame, this.rafterColor, repeatX, repeatY));
        this.haunchPlate.add(meshHaunchBoltPlate);

        // haunch bolt plate bolts
        let nutPos = []; // array of y positions 
        nutPos.push(-(boltPlateMargin.y * 2 / 3)); // low low
        nutPos.push(+(boltPlateMargin.y * 2 / 3)); // low high
        nutPos.push(this.dimensions.depth + (-boltPlateMargin.y * 2 / 3)); // high lo
        if (this.includeBoltPlateTop)
            nutPos.push(this.dimensions.depth + (+boltPlateTopMargin * 2 / 3)); // high high

        let nutRot = new Quaternion().setFromAxisAngle(new Vector3(1, 0, 0), -Math.PI / 2);

        nutPos.forEach((y) => {
            new _3dFastenerHex(this.haunchPlate, 2,
                new Vector3(boltPlateMargin.x * 2 / 3, y, 0),
                nutRot
            );
            new _3dFastenerHex(this.haunchPlate, 2,
                new Vector3(-boltPlateMargin.x * 2 / 3, y, 0),
                nutRot
            );
        });
        this.haunchPlate.rotation.x = this.absPitchAngle;
        this.group.add(this.haunchPlate);

        // peak bolt plate
        this.peakPlate = new THREE.Group();
        var meshPeakBoltPlate = this.getExtrudedMesh([
            new Vector2(-boltPlateMargin.x, this.dimensions.depth + boltPlateTopMargin),
            new Vector2(boltPlateMargin.x, this.dimensions.depth + boltPlateTopMargin),
            new Vector2(boltPlateMargin.x, -boltPlateMargin.y),
            new Vector2(-boltPlateMargin.x, -boltPlateMargin.y)
        ],
            MaterialHelper.getFrameMaterialWithTexture(this.texFrame, this.rafterColor, repeatX, repeatY));
        let peakPlatePosZ = this.length;
        this.peakPlate.add(meshPeakBoltPlate);



        // peak bolt plate bolts
        nutPos = []; // array of y positions 
        nutPos.push(-(boltPlateMargin.y * 2 / 3)); // low low
        nutPos.push((boltPlateMargin.y * 2 / 3)); // low high
        nutPos.push(this.dimensions.depth - (boltPlateMargin.y * 2 / 3)); // high lo
        if (this.includeBoltPlateTop)
            nutPos.push(this.dimensions.depth + (boltPlateTopMargin * 2 / 3)); // high high

        nutPos.forEach((y) => {
            new _3dFastenerHex(this.peakPlate, 1,
                new Vector3(boltPlateMargin.x * 2 / 3, y, 0),
                nutRot
            );
            new _3dFastenerHex(this.peakPlate, 1,
                new Vector3(-boltPlateMargin.x * 2 / 3, y, 0),
                nutRot
            );

        });

        this.peakPlate.position.z = peakPlatePosZ;
        this.peakPlate.rotation.x = this.absPitchAngle;
        this.peakPlate.position.y = 0; //this.dimensions.depth-this.dimensions.depth;
        this.group.add(this.peakPlate);
    }

    
    buildBoltPlate_LD(length){
        length += 2*CORE.steel.thickness_in;
        let boltPlateMargin = new Vector2(4, 4);
        let boltPlateTopMargin = 4;
        if (!this.includeBoltPlateTop)
            boltPlateTopMargin = 0;
        this.haunchPlate = new THREE.Group();
        let plateHeight = boltPlateTopMargin + this.dimensions.depth + boltPlateMargin.y;
        // haunch bolt plate        		
        let geoHaunchBoltPlate = new THREE.PlaneBufferGeometry(2*boltPlateMargin.x, plateHeight,1,1);

        // var meshHaunchBoltPlate = this.getExtrudedMesh([
        //     new Vector2(-boltPlateMargin.x, -boltPlateMargin.y),
        //     new Vector2(-boltPlateMargin.x, boltPlateTopMargin + this.dimensions.depth),
        //     new Vector2(boltPlateMargin.x, boltPlateTopMargin + this.dimensions.depth),
        //     new Vector2(boltPlateMargin.x, -boltPlateMargin.y)
        // ],
        let mat = MaterialHelper.getFrameMaterial_LD(this.rafterColor);
        //mat = MaterialHelper.getFrameMaterial_LD(0xff0000);
        let meshHaunchBoltPlate = new THREE.Mesh(geoHaunchBoltPlate, mat)            
        this.haunchPlate.add(meshHaunchBoltPlate);
        this.haunchPlate.rotation.x = this.absPitchAngle;
        this.haunchPlate.position.y =-boltPlateMargin.y + (plateHeight)/2
        this.group.add(this.haunchPlate);

        // peak bolt plate
        this.peakPlate = new THREE.Group();
        // var meshPeakBoltPlate =  this.getExtrudedMesh([
        //     new Vector2(-boltPlateMargin.x, this.dimensions.depth + boltPlateTopMargin),
        //     new Vector2(boltPlateMargin.x, this.dimensions.depth + boltPlateTopMargin),
        //     new Vector2(boltPlateMargin.x, -boltPlateMargin.y),
        //     new Vector2(-boltPlateMargin.x, -boltPlateMargin.y)
        // ],
        let meshPeakBoltPlate = new THREE.Mesh(geoHaunchBoltPlate, mat)    
            //MaterialHelper.getFrameMaterialWithTexture(this.texFrame, this.rafterColor, 1, 1));
        let peakPlatePosZ = length;
        this.peakPlate.add(meshPeakBoltPlate);

        this.peakPlate.position.z = peakPlatePosZ;
        this.peakPlate.rotation.x = this.absPitchAngle;
        this.peakPlate.position.y = -boltPlateMargin.y + (plateHeight)/2
        this.group.add(this.peakPlate);
    }

    buildBoltPlate() {

        if(this.isLowDetail())
            this.buildBoltPlate_LD(this.length);
        else
            this.buildBoltPlate_HD();

    }

    getExtrudedMesh(points, material){
        let mesh = ShapeHelper.getMeshFromPoints( 
            points,        
        this.extrusionSettings,
        material );        
        mesh.layers.set(CORE.layers.frame);
        mesh.castShadow=true;
        mesh.receiveShadow=true;
        return mesh;
    }
    
}