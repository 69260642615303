import * as THREE from 'three'
import _3dBase from './Base.js'
import {CORE} from '../_spec.js'
import layerHelper from '../helpers/layerHelper.js'
import MemHelper from '../helpers/memHelper.js';

export default class TrimGutterDownspout extends _3dBase {
//reference material:
//https://images.squarespace-cdn.com/content/v1/569685c1df40f31bc5cd0840/1601301987021-XWFLFFCB1FCB2ELJM91S/ke17ZwdGBToddI8pDm48kNnbBUj9eSp7j6QYKRqkc1QUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYy7Mythp_T-mtop-vrsUOmeInPi9iDjx9w8K4ZfjXt2dk-7GxrQNx-DJlyQFCDfyfTvaqLCzhT1I4jf7RojfHbjW07ycm2Trb21kYhaLJjddA/front.jpg?format=1500w

    constructor(height, material){
        super()
        let width = 4; // x
        let depth = 3; // z
        // vertical square downspout
        let geoMain = new THREE.BoxGeometry(width, height, depth);
        geoMain.name = 'gutter downspout'
        let mesh = new THREE.Mesh(geoMain, material);
        let pos = new THREE.Vector3(0,0,0);
        mesh.position.set(pos.x, pos.y-height/2, pos.z+depth/2);

        // bottom elbow/chute
        // elbow is built with the origin at the top back corner of the chute
        // so that it can be rotated into the downspout without repositioning
        let groupElbow = new THREE.Group();
        let elAngle = Math.PI/4;
        let elLen = 6;


        
        let gElbowSide = new THREE.PlaneGeometry(width, elLen);
        let gElbowEnd = new THREE.PlaneGeometry(depth, elLen);
        let mElbowL = new THREE.Mesh(gElbowEnd, material);        
        mElbowL.position.x= -width/2;
        mElbowL.position.y= -elLen/2;
        mElbowL.position.z= depth/2;
        mElbowL.rotation.y= Math.PI/2;

        let mElbowR = new THREE.Mesh(gElbowEnd, material);        
        mElbowR.position.x= width/2;
        mElbowR.position.y= -elLen/2;
        mElbowR.position.z= depth/2;        
        mElbowR.rotation.y= Math.PI/2;

        let mElbowT = new THREE.Mesh(gElbowSide, material);        
        mElbowT.position.y= -elLen/2;
        mElbowT.position.z= depth;

        let mElbowB = new THREE.Mesh(gElbowSide, material);        
        mElbowB.position.y= -elLen/2;
        
        groupElbow.rotation.x = -elAngle;        
        groupElbow.add(mElbowL);
        groupElbow.add(mElbowR);
        groupElbow.add(mElbowT);
        groupElbow.add(mElbowB);
        

        this.group.add(groupElbow);
        groupElbow.position.y = -height;
        this.group.add(mesh);
        layerHelper.setGroup(this.group, CORE.layers.walls,true);        
    }
}