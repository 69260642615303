import * as THREE from 'three';
import ColumnBase from './ColumnBase.js'
import {CORE} from '../_spec.js'
import { Vector3, Vector2, Shape } from 'three';
import CollisionHelper from '../helpers/CollisionHelper.js';
import ShapeHelper from '../helpers/shapeHelper';
import MemHelper from '../helpers/memHelper.js';
import materialHelper from '../helpers/materialHelper.js';
import Tri from './Tri.js';
import layerHelper from '../helpers/layerHelper.js';
/* https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Ibeam.svg/520px-Ibeam.svg.png

		|---------FLANGE---------|__
		************************** |
	___	************************** |
	|				**             | 
	|				**             |              
	|				**             | 
	WEB				**             DEPTH
	|				**             | 
	|				**             | 
	|				**             | 
	___				**             | 
		************************** |
		**************************__
*/

export default class ColumnTapered extends ColumnBase{
	// TODO: change this widenTowardSide to just side like rafterTapered and flip all values and params from front->back

	constructor(lod, length, pitch, addCollisionZone, widenTowardSide, bpFrameLine, includeBoltPlateTop, color){
		super(lod, new Vector3(), new Vector3(), color);
		
		this.addCollisionZone = addCollisionZone;
		this.dimensions = bpFrameLine.materialDim;
		this.bpFrameLine = bpFrameLine;
		this.widenTowardSide=widenTowardSide;
		this.length = length;
		this.pitchAngle = pitch;

		this.group.name ='Tapered column';

		this.extrusionSettings = {
            depth: .75, //used to be amount
            bevelEnabled: false,
            bevelThickness: 0.1,
            bevelSize: 1,
            bevelSegments: 1,
            material: 0,
            extrudeMaterial: 1
		};
		
		this.includeBoltPlateTop = includeBoltPlateTop;
		this.build();
    }

	build( ){
		// draw a tapered column


		this.buildColumn();		
		// tapered column group position/rotation

		this.buildTransition();
		this.transitionPiece.position.set(0,this.length,-this.dimensions.depthHaunch);
		this.transitionPiece.rotation.y = -Math.PI/2;
		this.group.add(this.transitionPiece);

		//collision bounding box
		this.group.updateWorldMatrix(true);

		if(this.addCollisionZone){
            
			// used to be meshDepth
            this.group.children[1].userData = {
                collisionZones: this.buildCollisionZones()
            };
        }
        
	}

	buildColumn_HD(baseDepth,insideHaunchDepth , outsideHaunchDepth, halfFlangeWidth, texture)
	{
		let halfBaseDepth = baseDepth/2;
		let material = materialHelper.getFrameMaterial_HD(this.color, texture);		

		// outside flange shape
		let meshOutsideFlange = this.getExtrudedMesh([
			new Vector2(-halfFlangeWidth,0),
			new Vector2(-halfFlangeWidth,this.length),
			new Vector2(halfFlangeWidth,this.length),
			new Vector2(halfFlangeWidth,0)
		]
		,material)
		this.group.add(meshOutsideFlange);

		// depth shape		
		this.meshDepth = this.getExtrudedMesh([
			new Vector2(-halfBaseDepth,0),
		  	new Vector2(-insideHaunchDepth,this.length),
			new Vector2(outsideHaunchDepth,this.length),
			new Vector2(outsideHaunchDepth,0)
		]
		,material)
		this.meshDepth.position.set(0,0,-halfBaseDepth);
		this.meshDepth.rotation.y = -Math.PI/2;
		this.group.add(this.meshDepth);

		// inside flange shape
		// we know the top of this triangle
		let aSide = (this.dimensions.depthHaunch-this.dimensions.depthBase);
		// we know the angle (therefore all angles of this right triangle)
		let angle = Math.atan(aSide/this.length);
		// use the Law of Sines to find the hypotenuse slightly longer than this.length 
		// a/sin(A) = c/sin(C) => c = a*sin(C)/sin(A)
		let angledLength =  aSide*Math.sin(Math.PI/2)/Math.sin(angle);
		
		var meshInsideFlange = this.getExtrudedMesh([
			new Vector2(-halfFlangeWidth,0),
			new Vector2(-halfFlangeWidth,angledLength),
			new Vector2(halfFlangeWidth,angledLength),
			new Vector2(halfFlangeWidth,0)
		]
		,material)
		meshInsideFlange.position.set(0,0,-baseDepth);
		meshInsideFlange.rotation.x = -angle;
		this.group.add(meshInsideFlange);
	}

	buildColumn_LD(baseDepth , insideHaunchDepth, outsideHaunchDepth, flangeWidth)
	{
		let halfBaseDepth = baseDepth/2;
		let material = materialHelper.getFrameMaterial_LD(this.color);
		var geoOutsideFlange = new THREE.PlaneBufferGeometry(flangeWidth, this.length, 1, 1)
		// outside flange shape
		let meshOutsideFlange =new THREE.Mesh(geoOutsideFlange, material);
		meshOutsideFlange.position.y = this.length/2;
		this.group.add(meshOutsideFlange);

		// depth shape		
		let bl = new Vector3(-halfBaseDepth, 0, 0)
		let tl = new Vector3(-insideHaunchDepth, this.length, 0)
        let tr = new Vector3(outsideHaunchDepth, this.length, 0)
        let br = new Vector3(outsideHaunchDepth, 0, 0)

		// this.meshDepth = this.getExtrudedMesh([
		// 	new Vector2(-halfBaseDepth,0),
		//   	new Vector2(-insideHaunchDepth,this.length),
		// 	new Vector2(outsideHaunchDepth,this.length),
		// 	new Vector2(outsideHaunchDepth,0)
		// ]
		// ,material)

		let triTopRight = new Tri(
            'bottom right triangle',
            [
                // counter clock-wise
                br, //bottom right 
                tr, // top right 
                tl, // top left 
            ],
            [
                              
                1.0, 0, // bottom right
                1.0, 1, // top right
                0.0, 1.0 // top left
            ]
        );

        let triBottomLeft = new Tri(
            'top left triangle',
            [
                br, // bottom right
                tl, // top left 
                bl, // bottom left 
            ],
            [
                1.0, 0, // bottom right
                0.0, 1, // top left
                0.0, 0, // bottom left
            ]
        );

		let debugShape = false;
		if(debugShape)
		material = materialHelper.getFrameMaterial_LD(0xff0000);
        let meshBottomLeft = new THREE.Mesh(triBottomLeft.geometry, material);
		if(debugShape)
		material = materialHelper.getFrameMaterial_LD(0x00ff00);
        let mesTopRight = new THREE.Mesh(triTopRight.geometry, material);

		meshBottomLeft.position.set(0,0,-halfBaseDepth);
		meshBottomLeft.rotation.y = -Math.PI/2;
		mesTopRight.position.set(0,0,-halfBaseDepth);
		mesTopRight.rotation.y = -Math.PI/2;

		this.group.add(meshBottomLeft);
		this.group.add(mesTopRight);

		// inside flange shape
		// we know the top of this triangle
		let aSide = (this.dimensions.depthHaunch-this.dimensions.depthBase);
		// we know the angle (therefore all angles of this right triangle)
		let angle = Math.atan(aSide/this.length);
		// use the Law of Sines to find the hypotenuse slightly longer than this.length 
		// a/sin(A) = c/sin(C) => c = a*sin(C)/sin(A)
		let angledLength =  aSide*Math.sin(Math.PI/2)/Math.sin(angle);
		
		var geoInsideFlange = new THREE.PlaneBufferGeometry(flangeWidth, angledLength, 1, 1)
		// Inside flange shape
		if(debugShape)
		material = materialHelper.getFrameMaterial_LD(0x0000ff);
		let meshInsideFlange =new THREE.Mesh(geoInsideFlange, material);

		//let bl = new Vector3(-halfBaseDepth, 0, 0)
        //let br = new Vector3(outsideHaunchDepth, 0)

		//meshInsideFlange.position.set(0,angledLength/2,-outsideHaunchDepth - baseDepth);
		
		meshInsideFlange.rotation.x = -angle;
		
		let shiftUp = angledLength/2; 
		meshInsideFlange.position.y = shiftUp;
		//let shiftUp = Math.tan(this.growthAngle) * shiftOver; 
		let shiftOver = Math.tan(-angle) * shiftUp;  // shifting up requires a shift over
		meshInsideFlange.position.z = -baseDepth + shiftOver;
		this.group.add(meshInsideFlange);


	}


	buildColumn(){
		let flangeWidth = 6;
		let halfFlangeWidth=flangeWidth/2;
		
		let baseDepth =this.dimensions.depthBase;
		let halfBaseDepth = baseDepth/2;
		let insideHaunchDepth = this.dimensions.depthHaunch - halfBaseDepth;
		let outsideHaunchDepth = halfBaseDepth;

		 // Create a custom texture for this object
         let texture = CORE.textures.beam.clone();
         texture.wrapT = texture.wrapS = THREE.MirroredRepeatWrapping;
         texture.encoding = THREE.sRGBEncoding;        
         texture.repeat.set(1,1); // not great
        
		if(this.isLowDetail())
            this.buildColumn_LD(baseDepth, insideHaunchDepth, outsideHaunchDepth,this.dimensions.flange);
        else
            this.buildColumn_HD(baseDepth, insideHaunchDepth , outsideHaunchDepth, halfFlangeWidth, texture);
	}


	buildTransition_HD(halfFlangeWidth, transitionGrowthWidth, transitionHeightInside, transitionHeightOutside, transitionTopLength, texture){
		let transitionPitch = this.pitchAngle;
		let material = materialHelper.getFrameMaterial_HD(this.color, texture);
        // Transition Side Flange
        var meshTransitionSideFlange = this.getExtrudedMesh( [
            new Vector2(-halfFlangeWidth,0),
		new Vector2(-halfFlangeWidth, transitionHeightOutside),
		new Vector2(halfFlangeWidth, transitionHeightOutside),
		new Vector2(halfFlangeWidth,0)
		]
		,material)
        meshTransitionSideFlange.rotation.y = Math.PI/2;
        meshTransitionSideFlange.position.x = this.dimensions.depthHaunch
        this.transitionPiece.add(meshTransitionSideFlange)

        // Transition Bottom Flange        
        var meshTransitionBottomFlange = this.getExtrudedMesh( [
            new Vector2(-halfFlangeWidth,0),
            new Vector2(-halfFlangeWidth, this.dimensions.depthHaunch),
            new Vector2(halfFlangeWidth, this.dimensions.depthHaunch),
            new Vector2(halfFlangeWidth,0),
		]
		,material)
        meshTransitionBottomFlange.rotation.z = Math.PI/2; // Euler rotation order matters
        meshTransitionBottomFlange.rotation.x = Math.PI/2; // Euler rotation order matters
        meshTransitionBottomFlange.position.x = this.dimensions.depthHaunch
        this.transitionPiece.add(meshTransitionBottomFlange)

        // Transition Top Flange        
        var meshTransitionTopFlange = this.getExtrudedMesh( [
        new Vector2(-halfFlangeWidth,0),
		new Vector2(-halfFlangeWidth, transitionTopLength),
		new Vector2(halfFlangeWidth, transitionTopLength),
		new Vector2(halfFlangeWidth,0),
        ]
		,material);

        meshTransitionTopFlange.rotation.z = Math.PI/2; // Euler rotation order matters
        meshTransitionTopFlange.rotation.x = Math.PI/2; // Euler rotation order matters
        meshTransitionTopFlange.rotation.y = -transitionPitch; // Euler rotation order matters
        meshTransitionTopFlange.position.x = this.dimensions.depthHaunch
        meshTransitionTopFlange.position.y = transitionHeightOutside
        this.transitionPiece.add(meshTransitionTopFlange)

        // Transition Depth        	
        var meshTransitionDepth = this.getExtrudedMesh( [
            new Vector2(0,0),
            new Vector2(transitionGrowthWidth, transitionHeightInside),
            new Vector2(this.dimensions.depthHaunch, transitionHeightOutside),
            new Vector2(this.dimensions.depthHaunch,0)
        ]
		,material);
		this.transitionPiece.add(meshTransitionDepth)

        let boltPlateMargin = new Vector2(4,4);
		let boltPlateTopMargin = 4;
		if(!this.includeBoltPlateTop)
			boltPlateTopMargin = 0
        this.haunchPlate = new THREE.Group();     
        
		// haunch bolt plate        		
		var meshHaunchBoltPlate = this.getExtrudedMesh( [
            new Vector2(-boltPlateMargin.x,-boltPlateMargin.y),
		    new Vector2(-boltPlateMargin.x,boltPlateTopMargin+this.dimensions.depthHaunch),
		    new Vector2(boltPlateMargin.x,boltPlateTopMargin+this.dimensions.depthHaunch),
		    new Vector2(boltPlateMargin.x,-boltPlateMargin.y)
        ]
		,material)
		this.haunchPlate.add(meshHaunchBoltPlate);
	
		this.haunchPlate.rotation.x = Math.PI/2
		this.haunchPlate.rotation.z = Math.PI/2
		if(this.slopedTaperBack)
		{
			this.haunchPlate.rotation.y = this.pitchAngle - Math.PI/2;
			// put the unit circle to work. move the bolt plate toward the outside
			this.haunchPlate.position.x = (2*CORE.steel.thickness_in)*Math.cos(this.pitchAngle);
			// move the bolt plate toward
			this.haunchPlate.position.y = Math.sin(this.pitchAngle);
		}
		else{
			this.haunchPlate.rotation.y = -this.pitchAngle - Math.PI/2;
			// put the unit circle to work. move the bolt plate toward the outside
			this.haunchPlate.position.x = (.05+CORE.steel.thickness_in)*Math.cos(this.pitchAngle);
			// move the bolt plate toward
			this.haunchPlate.position.y = -(.05+CORE.steel.thickness_in)*Math.sin(this.pitchAngle);
		}
	
		// haunch bolt plate bolts
		let nutPos = []; // array of y positions 
		nutPos.push( -(boltPlateMargin.y * 2/3)) // low low
		nutPos.push( +(boltPlateMargin.y * 2/3)) // low high
		nutPos.push( this.dimensions.depthHaunch/2) // middle
		nutPos.push(this.dimensions.depthHaunch +(-boltPlateMargin.y * 2/3)) // high lo
		nutPos.push(this.dimensions.depthHaunch +(boltPlateTopMargin * 2/3)) // high high

		nutPos.forEach((y)=>{
			this.haunchPlate.add(this.getNut(boltPlateMargin.x * 2/3,y,0,1, materialHelper.materials.bolt));
			this.haunchPlate.add(this.getNut(-boltPlateMargin.x * 2/3,y,0,1,materialHelper.materials.bolt));            
		});
		
        this.transitionPiece.add(this.haunchPlate);
	}
	buildTransition_LD(flangeWidth, transitionGrowthWidth, transitionHeightInside, transitionHeightOutside, transitionTopLength){
		let halfFlangeWidth  = flangeWidth/2;
		let material = materialHelper.getFrameMaterial_LD(this.color);
        // Transition Outside Flange
		let debugShape =false;
		if(debugShape)
		material = materialHelper.getFrameMaterial_LD(0xff0000);
		let getTransitionSideFlange = new THREE.PlaneBufferGeometry(flangeWidth, transitionHeightOutside, 1, 1)		
		let meshTransitionSideFlange =new THREE.Mesh(getTransitionSideFlange, material);
        meshTransitionSideFlange.rotation.y = Math.PI/2;
        meshTransitionSideFlange.position.x = this.dimensions.depthHaunch
		meshTransitionSideFlange.position.y = transitionHeightOutside/2;
        this.transitionPiece.add(meshTransitionSideFlange)
		if(debugShape)
		material = materialHelper.getFrameMaterial_LD(0x00ff00);
        // Transition Bottom Flange        
		let geoTransitionBottomFlange = new THREE.PlaneBufferGeometry(flangeWidth, this.dimensions.depthHaunch, 1, 1)		
		let meshTransitionBottomFlange =new THREE.Mesh(geoTransitionBottomFlange, material);        
        meshTransitionBottomFlange.rotation.z = Math.PI/2; // Euler rotation order matters
        meshTransitionBottomFlange.rotation.x = Math.PI/2; // Euler rotation order matters
        meshTransitionBottomFlange.position.x = this.dimensions.depthHaunch/2
        this.transitionPiece.add(meshTransitionBottomFlange)
		if(debugShape)
		material = materialHelper.getFrameMaterial_LD(0x0000ff);
		// Transition Top Flange                
		let geoTransitionTopFlange = new THREE.PlaneBufferGeometry(flangeWidth, transitionTopLength, 1, 1)		
		let meshTransitionTopFlange =new THREE.Mesh(geoTransitionTopFlange, material);        
        meshTransitionTopFlange.rotation.z = Math.PI/2; // Euler rotation order matters
        meshTransitionTopFlange.rotation.x = Math.PI/2; // Euler rotation order matters
        meshTransitionTopFlange.rotation.y = -this.pitchAngle; // Euler rotation order matters
        meshTransitionTopFlange.position.x = this.dimensions.depthHaunch/2
        meshTransitionTopFlange.position.y = transitionHeightOutside
        this.transitionPiece.add(meshTransitionTopFlange)

        // Transition Depth
        let bl = new Vector3(0, 0, 0)        
        let tr = new Vector3(this.dimensions.depthHaunch, transitionHeightOutside, 0)
        let tl = new Vector3(transitionGrowthWidth, transitionHeightInside, 0)
		let br = new Vector3(this.dimensions.depthHaunch,0, 0)

        let triTopRight = new Tri(
            'bottom right triangle',
            [
                // counter clock-wise
                br, //bottom right 
                tr, // top right 
                tl, // top left 
            ],
            [
                              
                1.0, 0, // bottom right
                1.0, 1, // top right
                0.0, 1.0 // top left
            ]
        );

        let triBottomLeft = new Tri(
            'top left triangle',
            [
                br, // bottom right
                tl, // top left 
                bl, // bottom left 
            ],
            [
                1.0, 0, // bottom right
                0.0, 1, // top left
                0.0, 0, // bottom left
            ]
        );
        let meshBottomLeft = new THREE.Mesh(triBottomLeft.geometry, material);
        let mesTopRight = new THREE.Mesh(triTopRight.geometry, material);
        

		this.transitionPiece.add(meshBottomLeft)
		this.transitionPiece.add(mesTopRight)

        let boltPlateMargin = new Vector2(4,4);
		let boltPlateTopMargin = 4;
		if(!this.includeBoltPlateTop)
			boltPlateTopMargin = 0
        this.haunchPlate = new THREE.Group();     
        

		// removed from low detail model for simplicity
		// // haunch bolt plate        		
		// var meshHaunchBoltPlate = this.getExtrudedMesh( [
        //     new Vector2(-boltPlateMargin.x,-boltPlateMargin.y),
		//     new Vector2(-boltPlateMargin.x,boltPlateTopMargin+this.dimensions.depthHaunch),
		//     new Vector2(boltPlateMargin.x,boltPlateTopMargin+this.dimensions.depthHaunch),
		//     new Vector2(boltPlateMargin.x,-boltPlateMargin.y)
        // ]
		// ,material)
		// this.haunchPlate.add(meshHaunchBoltPlate);
	
		// this.haunchPlate.rotation.x = Math.PI/2
		// this.haunchPlate.rotation.z = Math.PI/2
		// if(this.slopedTaperBack)
		// {
		// 	this.haunchPlate.rotation.y = this.pitchAngle - Math.PI/2;
		// 	// put the unit circle to work. move the bolt plate toward the outside
		// 	this.haunchPlate.position.x = (2*CORE.steel.thickness_in)*Math.cos(this.pitchAngle);
		// 	// move the bolt plate toward
		// 	this.haunchPlate.position.y = Math.sin(this.pitchAngle);
		// }
		// else{
		// 	this.haunchPlate.rotation.y = -this.pitchAngle - Math.PI/2;
		// 	// put the unit circle to work. move the bolt plate toward the outside
		// 	this.haunchPlate.position.x = (.05+CORE.steel.thickness_in)*Math.cos(this.pitchAngle);
		// 	// move the bolt plate toward
		// 	this.haunchPlate.position.y = -(.05+CORE.steel.thickness_in)*Math.sin(this.pitchAngle);
		// }

        this.transitionPiece.add(this.haunchPlate);
	}


	buildTransition(){ 
        let flangeWidth = this.dimensions.flange;
        let halfFlangeWidth=flangeWidth/2;
		
		let transitionPitch = this.pitchAngle;

        ///////////////////
        // Transition Piece
        // This is the "corner" steel between the column and the rafter and ranges in shape from slightly funky to very funky
        this.transitionPiece = new THREE.Group();     
        let transitionGrowthWidth = this.dimensions.depthHaunch * Math.sin(transitionPitch);
        // calculate the purely vertical height of the transition piece at the rafter
        let transitionHeightInside = this.dimensions.depthHaunch * Math.cos(transitionPitch); 
        // calculate the purely vertical height of the transition piece at the eave
        let transitionHeightOutside = transitionHeightInside - (this.dimensions.depthHaunch - transitionGrowthWidth) * Math.tan(transitionPitch);
        // calculate the hypotenuse of the right triangle at the top of the transition piece (where H = top, base is the width of the core, and height is the height added by roof pitch, and theta is at the outside corner)
        let transitionTopLength = (this.dimensions.depthHaunch - transitionGrowthWidth) / Math.cos(transitionPitch);


		 // Create a custom texture for this object
         let texture = CORE.textures.beam.clone();
         texture.wrapT = texture.wrapS = THREE.MirroredRepeatWrapping;
         texture.encoding = THREE.sRGBEncoding;        
         texture.repeat.set(1,1);
         
		 
		 if(this.isLowDetail())
		 this.buildTransition_LD(flangeWidth, transitionGrowthWidth, transitionHeightInside, transitionHeightOutside, transitionTopLength);
	 else
		 this.buildTransition_HD(halfFlangeWidth, transitionGrowthWidth, transitionHeightInside, transitionHeightOutside, transitionTopLength, texture);
		 layerHelper.setGroup(this.group, CORE.layers.frame, true);

    }
	
    getExtrudedMesh(points, material){		
        //TODO: this is duplicate code with rafterTapered
        let mesh = ShapeHelper.getMeshFromPoints( 
            points,        
        this.extrusionSettings,
        material );        
        mesh.layers.set(CORE.layers.frame);
        mesh.castShadow=true;
		mesh.receiveShadow=true;
        return mesh;
	}

	
    getNut(x,y,z,height, material){
        if(!height)
            height = 1;
        // deadbolt        
        var geoNut = new THREE.CylinderGeometry( 
            1, // radius
            1, // radius
            height, // height
            6 // segments
            );
        let meshNut = new THREE.Mesh(geoNut, material);
		meshNut.name = 'nut'
        meshNut.rotation.x = Math.PI / 2;
        meshNut.position.set(x,y,z);
        meshNut.layers.set(CORE.layers.frame)
        return meshNut;
        
    }
}
