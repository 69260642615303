import * as THREE from 'three';
import Util from '../utility.js'
import {CORE, rebuildTypes} from '../_spec.js'

import layerHelper from '../helpers/layerHelper.js'
import PickHelper from '../helpers/PickHelper.js';
import ComponentHelper from '../helpers/featureHelper.js';
import TreeNode from '../helpers/TreeNode.js'

import RoofBase from './RoofBase.js'

import _3dTrimEaveGutter from '../3d/TrimEaveGutter.js'
import _3dTrimEaveAngle from '../3d/TrimEaveAngle.js'
import _3dTrimRake from '../3d/TrimRake.js'
import _3dPurlinC from '../3d/PurlinC.js'
import _3dPurlinZ from '../3d/PurlinZ.js'
import _3dEaveStrut from '../3d/EaveStrut.js'
import _3dInsulation from '../3d/Insulation.js'
import _3dRoofRidgeCap from '../3d/RoofRidgeCap.js'
import BuildLogic from './BuildLogic.js';
import { Quaternion, Vector3 } from 'three';
import BlueprintHelper from '../helpers/blueprintHelper.js';
import EarcutDataManager from '../helpers/EarcutDataManager.js';
import SheetingHelper from '../helpers/SheetingHelper.js';
import Sheeting from '../3d/Sheeting.js';
import materialHelper from '../helpers/materialHelper.js';
import RoofValleyFlange from '../3d/RoofValley.js';
import MemHelper from '../helpers/memHelper.js';
import TrimGutterDownspout from '../3d/TrimGutterDownspout.js';
import DownspoutOffset from '../3d/DownspoutOffset.js';
import RafterStraight from '../3d/RafterStraight.js';
import _3dCupola from '../3d/Cupola.js';
import TrimSoffitRake from '../3d/TrimSoffitRake.js';
import TrimSoffitEave from '../3d/TrimSoffitEave.js';
import Sphere from '../3d/Sphere.js';
import mathHelper from '../helpers/mathHelper.js';

export default class RoofMainGable extends RoofBase{    
    
    // this class SHOULD 
    //  encapsulate all the business logic for options, calcs, etc
    //  be unit testable
    
    // this class SHOULD NOT
    //  import threejs meshes, geometries (Vectors and Quaternion are ok)


    
    constructor(design, length, width, eaveHeight, purlinType, trimMaterials, 
        frontEaveSegments, 
        frontDownspouts,
        backEaveSegments, 
        backDownspouts,
        insulation, 
        beamColor,
        purlinColor, 
        leftTieIn, // the roof this gable ties into on the left end
        rightTieIn, // the roof this gable ties into on the right end
        tieIns = [], // a list of gables tieing into this roof
        panelColor,
        trimColor,        
        roofExt,
        framelines,
        config
        ){
        super(design )
        this.length = length; // this is a frame length (extensions not included here)
        this.halfLength = length/2;
        this.width = width; // this is a frame width (extensions not included here)
        this.eaveHeight = eaveHeight;
        this.halfWidth = width/2;
        this.purlinType = purlinType; 
        this.leftTieIn = leftTieIn;
        this.rightTieIn = rightTieIn;
        this.tieIns = tieIns;
        this.panelColor = panelColor;
        this.roofExt = roofExt;
        this.framelines = framelines;

        this.frontSideExtensionLength = roofExt.front*12
        this.backSideExtensionLength = roofExt.back*12;

        this.leftEndExtensionLength = roofExt.left*12;
        this.rightEndExtensionLength = roofExt.right*12;
        
        

         // we instantly swap in a new group 
        this.group.name = "CompGroup RoofMainGable";        // component group
        this.front = new THREE.Group();
        this.back = new THREE.Group();
        this.group.add(this.front);
        this.group.add(this.back);
        this.pitchRatio = BlueprintHelper.pitchToPitchRatio(design.pitch);
        this.pitchRadians = BlueprintHelper.pitchRatioToRadians(this.pitchRatio);        
        this.pitchDegrees = BlueprintHelper.pitchRatioToDegrees(this.pitchRatio);
        this.pitchHeight = BlueprintHelper.pitchHeight(width, this.pitchRatio, this.design.roofType)
        let gutterOffsetBendSpacing = 4;// arbitrary 4 simulates space to bend gutter downspout twice
        this.eaveGutterOffsetPitchRadians = BlueprintHelper.pitchRatioToRadians(3/12) 

        this.front.position.y = this.pitchHeight+.1 // .1 prevents clipping of eave strut
        this.front.rotation.x=.01  // debug
        this.front.rotation.x = Math.PI/2 + this.pitchRadians;
        this.frontSideExtensionPitchHeight = this.frontSideExtensionLength * Math.sin(this.pitchRadians);
        this.frontSideExtensionPitchDepth = this.frontSideExtensionLength * Math.cos(this.pitchRadians);
        this.frontGutterOffsetHeight = this.frontSideExtensionPitchDepth * Math.tan(this.eaveGutterOffsetPitchRadians);
        this.frontDownspoutDropDistance = this.frontSideExtensionPitchHeight + this.frontGutterOffsetHeight
            if(this.frontDownspoutDropDistance > 0)
            this.frontDownspoutDropDistance+= CORE.roof.purlin.dim.height+gutterOffsetBendSpacing; 
        
        this.back.position.y = this.pitchHeight+.1 // .1 prevents clipping of eave strut
        this.back.rotation.x=-.01; // debug
        this.back.rotation.x = - Math.PI/2 - this.pitchRadians;
        this.backSideExtensionPitchHeight = this.backSideExtensionLength * Math.sin(this.pitchRadians);
        this.backSideExtensionPitchDepth = this.backSideExtensionLength * Math.cos(this.pitchRadians);
        this.backGutterOffsetHeight = this.backSideExtensionPitchDepth * Math.tan(this.eaveGutterOffsetPitchRadians);
        this.backDownspoutDropDistance = this.backSideExtensionPitchHeight + this.backGutterOffsetHeight
            if(this.backDownspoutDropDistance > 0)
            this.backDownspoutDropDistance+= CORE.roof.purlin.dim.height+gutterOffsetBendSpacing; 

        this.frontEaveSegments = frontEaveSegments;
        this.frontDownspouts = frontDownspouts;
        this.backEaveSegments = backEaveSegments;
        this.backDownspouts = backDownspouts;
        this.insulation = insulation;
        this.purlinColor = purlinColor;
        this.beamColor = beamColor;
        this.trimMaterials = trimMaterials;
        this.trimColor = trimColor;
        this.config = config;
        
        this.build(design, length, width, eaveHeight, purlinType, trimMaterials, frontEaveSegments, backEaveSegments, 
            insulation, purlinColor, leftTieIn, rightTieIn, tieIns = []);
    }


    build(design, length, width, eaveHeight, purlinType, trimMaterials, frontEaveSegments, backEaveSegments,
        insulation, purlinColor, leftTieIn, rightTieIn, tieIns = []) {
        
        
        this.outlineMeshes=[];

        // how far to the left does this ridge go?
        // assumption parent even is eave with child
        // assumption parent roof shape is gabled
        this.leftRidgeExtension = 0;
        this.leftGableTieRakeLengthFromRidge=0;
        if(this.leftTieIn){
            let leftTieInRatio = BlueprintHelper.pitchToPitchRatio(this.leftTieIn.pitch);
            // calculate eave offset
            this.leftEaveOffset = (this.leftTieIn.eave*12) - this.eaveHeight;
            this.leftRidgeExtension = (this.pitchHeight-this.leftEaveOffset) / leftTieInRatio;            

            // this is the length of the vertical edge (as building 2D, pre-rotation) of a cutout for the parent building eave
            let eaveCutAlongRake = this.leftEaveOffset/Math.sin(this.pitchRadians);
            // subtracting from the total back rake gives us length from ridge, toward eave (for use with valley framing and purlins)
            this.leftGableTieRakeLengthFromRidge=this.getFrameRakeLength()-eaveCutAlongRake;
        }

        this.rightRidgeExtension = 0;
        this.rightGableTieRakeLengthFromRidge=0;
        if(this.rightTieIn){
            let rightTieInRatio = BlueprintHelper.pitchToPitchRatio(this.rightTieIn.pitch);
            this.rightEaveOffset = (this.rightTieIn.eave*12) - this.eaveHeight;
            this.rightRidgeExtension = (this.pitchHeight-this.rightEaveOffset) / rightTieInRatio;            

            // this is the length of the vertical edge (as building 2D, pre-rotation) of a cutout for the parent building eave, from this eave toward ridge.
            let eaveCutAlongRake = this.rightEaveOffset/Math.sin(this.pitchRadians); 
            // subtracting from the total back rake gives us length from ridge, toward eave (for use with valley framing and purlins)
            this.rightGableTieRakeLengthFromRidge=this.getFrameRakeLength()-eaveCutAlongRake;
        }

        this.buildPurlins2();
        this.buildEaves();
        this.buildPanels();
        this.buildRidgeCap(trimMaterials.eaveAndRake);
        if(this.config.gutters)
            this.buildDownspouts(trimMaterials.downspout);
        // use the blueprint
        
        this.buildEaveTrim(trimMaterials.eaveAndRake); 
        this.buildEndTrim(trimMaterials.eaveAndRake)
        
        this.buildSidewallExtensions();

        this.buildLeftValleyFraming();
        this.buildRightValleyFraming();

        if(insulation.roof !== CORE.insulation.standard.none.value)
            this.buildInsulation_Standard();
        if(insulation.energySaver.type !== CORE.insulation.insulband.none.value)
            this.buildInsulation_Insulband();

        this.addVerticalBackboard();

        /*
        let c = new Cupola();
        let cdesign = c.defaultDesign();
        c = ComponentHelper.createComponentWithDesign(cdesign);
        c.initParent(this);
        c.build();
        this.group.add(c.group);
        */
        //let c = new Cupola('', 30, 45, 0xff0000);
        //this.group.add(c.group);

        this.design.components.forEach((design) => {
            let c = ComponentHelper.createComponentWithDesign(design)
            this.components.push(c);
        })
        this.buildComponents();
    }

    buildSidewallExtensions(){
        // build an ext rafter at each rafter 

        // length is frame versus back/front
        this.framelines.forEach((fl)=>{
            this.buildExtensionRafters(fl);
        });

        this.buildExtensionPurlin();

    }

    buildExtensionPurlin(){
        if(this.frontSideExtensionLength && this.frontSideExtensionLength>0)
            this.buildFrontExtensionPurlin();
        if(this.backSideExtensionLength && this.backSideExtensionLength>0)
            this.buildBackExtensionPurlin();
    }

    buildFrontExtensionPurlin(){
        // get the "rakeLength" of the roof
        let frameLength = this.getFrameRakeLength();
        let frontLength = this.getFrontRakeLength() - frameLength;

        let frontPurlinStart = 0;
        let frontPurlinEnd = frontLength
        if(frontPurlinEnd<frontPurlinStart)
            return;
        
        let frontPurlinRange = frontPurlinEnd - frontPurlinStart;
        let frontPurlinCount = Math.ceil(frontPurlinRange / CORE.roof.purlin.spacing.min);
        let frontPurlinSpacingActual = frontPurlinRange / frontPurlinCount;
        let purlinCurr = frontPurlinStart + frontPurlinSpacingActual;
        while(purlinCurr <= frontPurlinEnd){
            for(let i in this.frontEaveSegments){
                let len = this.frontEaveSegments[i].start.distanceTo(this.frontEaveSegments[i].end)
                // let posX = this.frontEaveSegments[i].start.x - len/2;
                let mid = this.frontEaveSegments[i].start.clone();
                mid = mid.lerp(this.frontEaveSegments[i].end,.5);
                
                let pos = new THREE.Vector3(mid.x,frameLength+purlinCurr-CORE.roof.purlin.dim.width/2,.05+CORE.roof.purlin.dim.height/2);
                let p;
                if(this.purlinType === CORE.roof.purlin.types.C || purlinCurr == frontPurlinEnd)
                    p = new _3dPurlinC(CORE.preferences.des_levelOfDetail.value, len, undefined, undefined, this.purlinColor);
                else
                    p = new _3dPurlinZ(CORE.preferences.des_levelOfDetail.value, len, undefined, undefined, this.purlinColor);
                p.group.position.copy(pos);            
                p.group.rotation.x = -Math.PI/2;
                this.front.add(p.group);
                layerHelper.enableLayer(p.group,CORE.layers.quote);
                
            }
            purlinCurr+=frontPurlinSpacingActual;
        }
    
        // #region Old Extension Purlin Code (does not segment purlin)
        // while(purlinCurr <= frontPurlinEnd){
        //     let len = this.length;
        //     let posX = 0;
            
        //     let leftEndAddition = this.getLeftEndAdditionAtFrontPosition(frameLength+purlinCurr);
        //     len += leftEndAddition;
        //     // any additional left end length requires we shift the center toward that end
        //     posX -= leftEndAddition/2;

        //     let rightEndAddition = this.getRightEndAdditionAtFrontPosition(frameLength+purlinCurr);
        //     len += rightEndAddition;
        //     // any additional right end length requires we shift the center toward that end
        //     posX += rightEndAddition/2;

        //     let pos = new THREE.Vector3(posX,frameLength+purlinCurr-CORE.roof.purlin.dim.width/2,.05+CORE.roof.purlin.dim.height/2);
        //     let p;
        //     if(this.purlinType === CORE.roof.purlin.types.C || purlinCurr == frontPurlinEnd)
        //         p = new _3dPurlinC(CORE.preferences.des_levelOfDetail.value, len, undefined, undefined, this.purlinColor);
        //     else
        //         p = new _3dPurlinZ(CORE.preferences.des_levelOfDetail.value, len, undefined, undefined, this.purlinColor);
        //     p.group.position.copy(pos);            
        //     p.group.rotation.x = -Math.PI/2;
        //     this.front.add(p.group);
        //     layerHelper.enableLayer(p.group,CORE.layers.quote);
        //     purlinCurr+=frontPurlinSpacingActual;
        // }
        //#endregion
    }

    
    buildBackExtensionPurlin(){
        // get the "rakeLength" of the roof
        let frameLength = this.getFrameRakeLength();
        let backLength = this.getBackRakeLength() - frameLength;

        let backPurlinStart = 0;
        let backPurlinEnd = backLength
        if(backPurlinEnd<backPurlinStart)
            return;
        
        let backPurlinRange = backPurlinEnd - backPurlinStart;
        let backPurlinCount = Math.ceil(backPurlinRange / CORE.roof.purlin.spacing.min);
        let backPurlinSpacingActual = backPurlinRange / backPurlinCount;
        let purlinCurr = backPurlinStart + backPurlinSpacingActual;

        while(purlinCurr <= backPurlinEnd){
            for(let i in this.backEaveSegments){
                let len = this.backEaveSegments[i].start.distanceTo(this.backEaveSegments[i].end);
                //let posX = 0;
                let mid = this.backEaveSegments[i].start.clone();
                mid = mid.lerp(this.backEaveSegments[i].end,.5);
    
                let pos = new THREE.Vector3(mid.x,frameLength+purlinCurr-CORE.roof.purlin.dim.width/2,-.05-CORE.roof.purlin.dim.height/2);
                let p;
                if(this.purlinType === CORE.roof.purlin.types.C || purlinCurr == backPurlinEnd)
                    p = new _3dPurlinC(CORE.preferences.des_levelOfDetail.value, len, undefined, undefined, this.purlinColor);
                else
                    p = new _3dPurlinZ(CORE.preferences.des_levelOfDetail.value, len, undefined, undefined, this.purlinColor);
                p.group.position.copy(pos);
                p.group.rotation.x = -Math.PI/2;
                this.back.add(p.group);
                layerHelper.enableLayer(p.group,CORE.layers.quote);
                
            }
            purlinCurr+=backPurlinSpacingActual;
        }
        
        //#region Old Extension Purlin Code (does not segment purlin)
        // while(purlinCurr <= frontPurlinEnd){
        //     let len = this.length;
        //     let posX = 0;
            
        //     let leftEndAddition = this.getLeftEndAdditionAtFrontPosition(frameLength+purlinCurr);
        //     len += leftEndAddition;
        //     // any additional left end length requires we shift the center toward that end
        //     posX -= leftEndAddition/2;

        //     let rightEndAddition = this.getRightEndAdditionAtFrontPosition(frameLength+purlinCurr);
        //     len += rightEndAddition;
        //     // any additional right end length requires we shift the center toward that end
        //     posX += rightEndAddition/2;

        //     let pos = new THREE.Vector3(posX,frameLength+purlinCurr-CORE.roof.purlin.dim.width/2,-.05-CORE.roof.purlin.dim.height/2);
        //     let p;
        //     if(this.purlinType === CORE.roof.purlin.types.C || purlinCurr == frontPurlinEnd)
        //         p = new _3dPurlinC(CORE.preferences.des_levelOfDetail.value, len, undefined, undefined, this.purlinColor);
        //     else
        //         p = new _3dPurlinZ(CORE.preferences.des_levelOfDetail.value, len, undefined, undefined, this.purlinColor);
        //     p.group.position.copy(pos);
        //     p.group.rotation.x = -Math.PI/2;
        //     this.back.add(p.group);
        //     layerHelper.enableLayer(p.group,CORE.layers.quote);
        //     purlinCurr+=backPurlinSpacingActual;
        // }
        // #endregion
    }

    buildExtensionRafters(fl){
        if(this.frontSideExtensionLength && this.frontSideExtensionLength>0){
            let flPos = BlueprintHelper.getFramelinePositionXByIndex(this.length/12,this.framelines,this.framelines.indexOf(fl),false)
            this.frontEaveSegments.forEach(segment =>{
                if(flPos >= segment.start.x && flPos <= segment.end.x)
                    this.buildFrontExtensionRafter(fl); 
            })

        }
            
        if(this.backSideExtensionLength && this.backSideExtensionLength>0){
            let flPos = BlueprintHelper.getFramelinePositionXByIndex(this.length/12,this.framelines,this.framelines.indexOf(fl),false)
            this.backEaveSegments.forEach(segment =>{
                if(flPos >= segment.start.x && flPos <= segment.end.x)
                    this.buildBackExtensionRafter(fl); 
            })
        }
            
    }

    buildFrontExtensionRafter(fl){
        let frameRakeLength = this.getFrameRakeLength();
        let overlap = 12; //https://trello.com/c/9HVHHprQ/287-30h-eave-ew-extensions-and-sw-overhangs
        let length = (this.getFrontRakeLength() - frameRakeLength) + overlap;
        let materialDim = BlueprintHelper.get8x4StraightFraming();
        let rafterDepthAtColumn = 8; // to match 8x4 materialDim
        let rafterDimYAtColumn = rafterDepthAtColumn / Math.cos(this.pitchRadians); // pure vertical dimension of a pitched rafter
        let ext = new RafterStraight(CORE.preferences.des_levelOfDetail.value, this.front, new Vector3(), 0, length, 0, false, {materialDim, rafterDimYAtColumn}, false, false, this.beamColor)
        ext.group.position.set(-this.length/2 + fl,frameRakeLength-overlap,  + .05)
        ext.group.rotation.y = Math.PI;
        ext.group.rotation.x = Math.PI/2;
        layerHelper.setGroup(ext.group, CORE.layers.frame);
        layerHelper.enableLayer(ext.group, CORE.layers.quote);
        this.front.add(ext.group);
    }
    
    buildBackExtensionRafter(fl){
        let frameRakeLength = this.getFrameRakeLength();
        let overlap = 12; //https://trello.com/c/9HVHHprQ/287-30h-eave-ew-extensions-and-sw-overhangs
        let length = (this.getBackRakeLength() - frameRakeLength) + overlap;
        let materialDim = BlueprintHelper.get8x4StraightFraming();
        let rafterDepthAtColumn = 8; // to match 8x4 materialDim
        let rafterDimYAtColumn = rafterDepthAtColumn / Math.cos(this.pitchRadians); // pure vertical dimension of a pitched rafter
        let ext = new RafterStraight(CORE.preferences.des_levelOfDetail.value, this.front, new Vector3(), 0, length, 0, false, {materialDim, rafterDimYAtColumn}, false, false, this.beamColor)
        ext.group.position.set(-this.length/2 + fl,frameRakeLength-overlap, - .05) // .75 is the thickness of the rafter
        ext.group.rotation.x = -Math.PI/2;
        layerHelper.setGroup(ext.group, CORE.layers.frame);
        layerHelper.enableLayer(ext.group, CORE.layers.quote);
        this.back.add(ext.group);
    }

    

    buildDownspouts(trimMaterial){
        let offsetFromStructure = .25;

        if(this.frontDownspouts)
        this.frontDownspouts.forEach((d)=>{
            let offset = new DownspoutOffset(10,this.frontSideExtensionPitchDepth, 10, this.eaveGutterOffsetPitchRadians, trimMaterial)
            this.front.add(offset.group);
            let g = new TrimGutterDownspout(d.length - this.frontDownspoutDropDistance, trimMaterial);
            this.front.add(g.group);

            // frontSideExtensionPitchHeight can be used as a hypotenuse to find the 
            // adjacent and opposite sides in order to position this downspout
            

            let yOffset = this.frontDownspoutDropDistance*Math.sin(this.pitchRadians);
            let zOffset = this.frontDownspoutDropDistance*Math.cos(this.pitchRadians);
            g.group.position.set(d.x, this.getFrameRakeLength()+offsetFromStructure+yOffset, +zOffset);
            g.group.rotation.x = - Math.PI/2 - this.pitchRadians; 
            g.group.rotation.y = 0;//-Math.PI;

             // y+3.5 represents the depth of the gutter plus the offsetFromStructure (which is affected by the roof pitch)
            offset.group.position.set(d.x, this.getFrontRakeLength()+3.5,+CORE.roof.purlin.dim.height/2);
            offset.group.rotation.y = 0;//-Math.PI;
            offset.group.rotation.x =-  Math.PI/2 - this.pitchRadians;
            layerHelper.setGroup(offset.group, CORE.layers.walls);
            layerHelper.enableLayer(offset.group, CORE.layers.quote);
            layerHelper.enableLayer(g.group, CORE.layers.quote);
        });

        if(this.backDownspouts)
        this.backDownspouts.forEach((d)=>{
            let offset = new DownspoutOffset(10,this.backSideExtensionPitchDepth, 10, this.eaveGutterOffsetPitchRadians, trimMaterial)
            this.back.add(offset.group);
            let g = new TrimGutterDownspout(d.length - this.backDownspoutDropDistance, trimMaterial);
            this.back.add(g.group);

            // backSideExtensionPitchHeight can be used as a hypotenuse to find the ORE.materials.trims.downspout
            // adjacent and opposite sides in order to position this downspout
            

            let yOffset = this.backDownspoutDropDistance*Math.sin(this.pitchRadians);
            let zOffset = this.backDownspoutDropDistance*Math.cos(this.pitchRadians);
            g.group.position.set(d.x, this.getFrameRakeLength()+offsetFromStructure+yOffset, -zOffset);
            g.group.rotation.x = Math.PI/2 + this.pitchRadians; 
            g.group.rotation.y = Math.PI;

             // y+3.5 represents the depth of the gutter plus the offsetFromStructure (which is affected by the roof pitch)
            offset.group.position.set(d.x, this.getBackRakeLength()+3.5,-CORE.roof.purlin.dim.height/2);
            offset.group.rotation.y = Math.PI;
            offset.group.rotation.x = Math.PI/2 + this.pitchRadians;

            layerHelper.setGroup(offset.group, CORE.layers.walls);
            layerHelper.enableLayer(offset.group, CORE.layers.quote);
            layerHelper.enableLayer(g.group, CORE.layers.quote);
        });
    }

    getLeftX(){
        return -this.halfLength;
    }
    getRightX(){
        return this.halfLength;
    }

    getBackZ(){
        return -this.halfWidth
    }

    getFrontZ(){
        return this.halfWidth;
    }

    getRidgeRight(){
        return new Vector3(this.getRightX(), this.pitchHeight, 0)
    }
    getRidgeLeft(){
        return new Vector3(this.getLeftX(), this.pitchHeight, 0)
    }
    getPanelFrontLeft(){
        return new Vector3(this.getLeftX(),0, this.getFrontZ())
    }
    getPanelFrontRight(){
        return new Vector3(this.getRightX(),0, this.getFrontZ())
    }
    getPanelBackLeft(){
        return new Vector3(this.getLeftX(),0, this.getBackZ())
    }
    getPanelBackRight(){
        return new Vector3(this.getRightX(),0, this.getBackZ())
    }
    getRidgePurlinBottom(){
        return this.pitchHeight - BlueprintHelper.pitchedPurlinDimY(this.pitchRatio);
    }
    getEaveBottom(){
        return -BlueprintHelper.pitchedPurlinDimY(this.pitchRatio);
    }
    getRidgePurlinLeft(){
        return new Vector3(this.getLeftX(),this.getRidgePurlinBottom(), 0);
    }
    getRidgePurlinRight(){
        return new Vector3(this.getRightX(),this.getRidgePurlinBottom(), 0);
    }
    getEaveBackLeft(){
        return new Vector3(this.getLeftX(), this.getEaveBottom(), this.getBackZ());
    }
    getEaveBackRight(){
        return new Vector3(this.getRightX(), this.getEaveBottom(), this.getBackZ());
    }
    getEaveFrontLeft(){
        return new Vector3(this.getLeftX(), this.getEaveBottom(), this.getFrontZ());
    }
    getEaveFrontRight(){
        return new Vector3(this.getRightX(), this.getEaveBottom(), this.getFrontZ());
    }    

    buildRidgeCap(trimMaterial){
        let len = this.length;
        let posX = 0;
        let leftEndAddition = this.getLeftEndAdditionAtBackPosition(0);
        len += leftEndAddition;
        // any additional left end length requires we shift the center toward that end
        posX -= leftEndAddition/2;

        let rightEndAddition = this.getRightEndAdditionAtBackPosition(0);
        len += rightEndAddition;
        // any additional right end length requires we shift the center toward that end
        posX += rightEndAddition/2;
        let pos = new THREE.Vector3(posX,0+this.pitchHeight,0);

        //////////////////////
        let width = 6; // trim width in inches
        this.ridgeCap = new _3dRoofRidgeCap(len, width, this.pitchDegrees, trimMaterial);
        this.group.add(this.ridgeCap.group);

        pos.z -= width;
        this.ridgeCap.group.position.copy(pos);
        this.ridgeCap.group.rotation.x -= this.pitchRadians;
        layerHelper.setGroup(this.ridgeCap.group, CORE.layers.roof);
        layerHelper.enableLayer(this.ridgeCap.group, CORE.layers.quote);
    }

    buildInsulation_Standard(){
        let zBufferPadding = 2; // this is the amount of space added to prevent z-buffer fighting since the insulation is close to the roof panel exterior
        // add this standard insulation in the interior
        let startFrameX = BuildLogic.GetFrameline(this.insulation.framelines, Number(this.insulation.leftWall));
        let stopFrameX = BuildLogic.GetFrameline(this.insulation.framelines, Number(this.insulation.rightWall));
        
        let length = stopFrameX- startFrameX;
        
        let insPosX = startFrameX + length/2 - this.length/2;
        let insWidth = this.getPanelFrontLeft().distanceTo(this.getRidgeLeft())
        let insZFromCenter = (this.getPanelFrontLeft().z - this.getRidgeLeft().z)/2        
        //console.log('halfInsWidth', halfInsWidth);
        // back side
        this.roofInsulationStandardBack = new _3dInsulation(length, insWidth, .01);
        this.roofInsulationStandardBack.group.position.copy(new THREE.Vector3(  
            insPosX,
             (this.pitchHeight/2)-zBufferPadding ,
            -insZFromCenter),);		
        this.roofInsulationStandardBack.group.rotation.x = Math.atan(-this.pitchRatio);
        this.group.add(this.roofInsulationStandardBack.group)



        // front side
        this.roofInsulationStandardFront = new _3dInsulation(length, insWidth, .01);
        this.roofInsulationStandardFront.group.position.copy( new THREE.Vector3(  
            insPosX,
            + (this.pitchHeight/2)-zBufferPadding,
            +insZFromCenter));
        this.roofInsulationStandardFront.group.rotation.x = Math.atan(this.pitchRatio);
        this.group.add(this.roofInsulationStandardFront.group)
        
        layerHelper.setGroup(this.roofInsulationStandardBack.group,CORE.layers.roof, true);
        layerHelper.setGroup(this.roofInsulationStandardFront.group,CORE.layers.roof, true);
        layerHelper.enableLayer(this.roofInsulationStandardBack.group,CORE.layers.quote, true);
        layerHelper.enableLayer(this.roofInsulationStandardFront.group,CORE.layers.quote, true);
        
    }

    
    buildInsulation_Insulband(){
        let zBufferPadding = 2; // this is the amount of space added to prevent z-buffer fighting since the insulation is close to the roof panel exterior
        if( Number(this.insulation.energySaver.framelineStop)>=this.insulation.framelines.length)
            this.insulation.energySaver.framelineStop = this.insulation.framelines.length-1;
        let startFrameX = BuildLogic.GetFrameline(this.insulation.framelines, Number(this.insulation.energySaver.framelineStart));
        let stopFrameX = BuildLogic.GetFrameline(this.insulation.framelines, Number(this.insulation.energySaver.framelineStop));
        let length = stopFrameX - startFrameX;
        let insPosX = startFrameX + length /2;
        let insWidth = this.getPanelFrontLeft().distanceTo(this.getRidgeLeft())
        let halfInsWidth = insWidth/2;
        let insZFromCenter = (this.getPanelFrontLeft().z - this.getRidgeLeft().z)/2
        let insulationThickness =  BlueprintHelper.pitchedPurlinDimY(this.pitchRatio) -1 - zBufferPadding;
        insZFromCenter -= (insulationThickness/2) * Math.tan(this.pitchRatio); // https://trello.com/1/cards/6299f3c7d40c9a7e7b4b496a/attachments/62cd994260374646ec84fc34/previews/62cd994360374646ec84fcac/download/image.jpeg.jpg
        
        // back side
        this.roofInsulationInsulbandBack = new _3dInsulation(length, insWidth, insulationThickness- .1);
        this.roofInsulationInsulbandBack.group.position.copy(
            new THREE.Vector3(   insPosX - this.length/2,
            (this.pitchHeight/2)- insulationThickness/2 - zBufferPadding ,
           -insZFromCenter ));

        this.roofInsulationInsulbandBack.group.rotation.x = Math.atan(-this.pitchRatio);
        this.group.add(this.roofInsulationInsulbandBack.group)

        // front side
        this.roofInsulationInsulbandFront =new _3dInsulation(length, insWidth, insulationThickness- .1);

            this.roofInsulationInsulbandFront.group.position.copy(
                new THREE.Vector3(   insPosX - this.length/2,
                (this.pitchHeight/2)- insulationThickness/2 - zBufferPadding ,
               +insZFromCenter))
            this.roofInsulationInsulbandFront.group.rotation.x = Math.atan(this.pitchRatio);
            this.group.add(this.roofInsulationInsulbandFront.group)

        layerHelper.setGroup(this.roofInsulationInsulbandBack.group,CORE.layers.roof, true);
        layerHelper.setGroup(this.roofInsulationInsulbandFront.group,CORE.layers.roof, true);
        layerHelper.enableLayer(this.roofInsulationInsulbandBack.group,CORE.layers.quote, true);
        layerHelper.enableLayer(this.roofInsulationInsulbandFront.group,CORE.layers.quote, true);        
    }

    buildEndTrim(eaveAndRake){
        if(this.leftTieIn){
            this.buildLeftEndValleyTrim();
        }
        else
            this.buildLeftEndRakeTrim( eaveAndRake);

        if(this.rightTieIn){
            this.buildRightEndValleyTrim();
        }
        else
            this.buildRightEndRakeTrim( eaveAndRake);
        
    }

    buildLeftEndValleyTrim(){
        // this is a detail that requires limiting the intersection of panels
        // to make space for the valley trim
    }

    buildRightEndValleyTrim(){
        // this is a detail that requires limiting the intersection of panels
        // to make space for the valley trim
    }

    buildLeftEndRakeTrim( trimMaterial){
        
        let eaveTrimMatingAngle = 0;
        
        
        if(this.config.gutters){
            eaveTrimMatingAngle = Math.PI/4;
        }

        // front left
        let frontLength = this.getFrontRakeLength()
        let rakeTrimFL = new _3dTrimRake(frontLength, trimMaterial, this.pitchRadians, 0, 0, eaveTrimMatingAngle);
        let frontLeftEndAddition = this.getLeftEndAdditionAtFrontPosition(frontLength/2);
        rakeTrimFL.group.position.set(-this.length/2-frontLeftEndAddition, frontLength/2, 0);
        rakeTrimFL.group.rotation.z = Math.PI/2;
        rakeTrimFL.group.rotation.y = -Math.PI/2;
        layerHelper.enableLayer(rakeTrimFL.group, CORE.layers.quote)
        this.front.add(rakeTrimFL.group)
        this.trimRakes.push(rakeTrimFL);

        // back left
        let backLength = this.getBackRakeLength()        
        let rakeTrimBL = new _3dTrimRake(backLength, trimMaterial, 0, this.pitchRadians, eaveTrimMatingAngle, 0);
        let backLeftEndAddition = this.getLeftEndAdditionAtBackPosition(backLength/2);
        rakeTrimBL.group.position.set(-this.length/2-backLeftEndAddition, backLength/2, 0);
        rakeTrimBL.group.rotation.z = -Math.PI/2;
        rakeTrimBL.group.rotation.y = -Math.PI/2;
        layerHelper.enableLayer(rakeTrimBL.group, CORE.layers.quote)
        this.back.add(rakeTrimBL.group)
        this.trimRakes.push(rakeTrimBL);
    }    

    buildRightEndRakeTrim( trimMaterial){
        
        let eaveTrimMatingAngle = 0;
        let eaveTrimExtension = 3; 
        
        if(this.config.gutters){
            eaveTrimMatingAngle = Math.PI/4;
            eaveTrimExtension = 0;
        }

        let length = this.getPanelFrontLeft().distanceTo(this.getRidgeLeft()) + eaveTrimExtension;
        let eaveTrimLerpExt = (eaveTrimExtension/length)/2; //half of the ratio of the extension to the overall length

         // front right
        let frontLength = this.getFrontRakeLength()
        let rakeTrimFR = new _3dTrimRake(frontLength, trimMaterial, 0, this.pitchRadians, eaveTrimMatingAngle, 0);
        let frontRightEndAddition = this.getRightEndAdditionAtFrontPosition(frontLength/2);
        rakeTrimFR.group.position.set(this.length/2+frontRightEndAddition, frontLength/2, 0);
        rakeTrimFR.group.rotation.z = -Math.PI/2;
        rakeTrimFR.group.rotation.y = Math.PI/2;
        layerHelper.enableLayer(rakeTrimFR.group, CORE.layers.quote)
        this.front.add(rakeTrimFR.group)
        this.trimRakes.push(rakeTrimFR);

        // back right
        let backLength = this.getBackRakeLength()        
        let rakeTrimBR = new _3dTrimRake(backLength, trimMaterial, this.pitchRadians, 0, 0, eaveTrimMatingAngle);
        let backRightEndAddition = this.getRightEndAdditionAtBackPosition(backLength/2);
        rakeTrimBR.group.position.set(this.length/2+backRightEndAddition, backLength/2, 0);
        rakeTrimBR.group.rotation.z = Math.PI/2;
        rakeTrimBR.group.rotation.y = Math.PI/2;
        layerHelper.enableLayer(rakeTrimBR.group, CORE.layers.quote)
        this.back.add(rakeTrimBR.group)
        this.trimRakes.push(rakeTrimBR);

    }    

    buildEaveTrim(trimMaterial){
        if(this.frontEaveSegments)
            this.frontEaveSegments.forEach((seg,i)=>{
                seg.start.y = this.getFrontRakeLength();
                seg.end.y = this.getFrontRakeLength();
                seg.start.z = 0;
                seg.end.z = 0;
                if(i==0)
                    seg.start.x -=this.leftEndExtensionLength;
                if(i==this.frontEaveSegments.length-1)
                    seg.end.x +=this.rightEndExtensionLength;

                let trim;
                
                if(this.frontSideExtensionLength && this.frontSideExtensionLength>0){
                    trim = this.buildEaveTrimSegment( seg, trimMaterial, 8);
                    if(this.config.gutters){
                        trim.group.rotation.x = -Math.PI/2;
                    }
                    else
                        trim.group.rotation.x = -Math.PI/2 + this.pitchRadians;
                }
                else{
                    trim = this.buildEaveTrimSegment( seg, trimMaterial, 6);   
                    trim.group.rotation.x = -Math.PI/2;
                }
                trim.group.position.y = this.getFrontRakeLength();
                this.front.add(trim.group);
            });

        if(this.backEaveSegments)
            this.backEaveSegments.forEach((seg,i)=>{
                seg.start.y = this.getBackRakeLength();
                seg.end.y = this.getBackRakeLength();
                seg.start.z = 0;
                seg.end.z = 0;
                if(i==0)
                    seg.start.x -=this.leftEndExtensionLength;
                if(i==this.backEaveSegments.length-1)
                    seg.end.x +=this.rightEndExtensionLength;


                let trim
                if(this.backSideExtensionLength && this.backSideExtensionLength>0){
                    trim = this.buildEaveTrimSegment( seg, trimMaterial, 8);
                    if(this.config.gutters){
                        trim.group.rotation.x = Math.PI/2;
                    }
                    else
                    trim.group.rotation.x = Math.PI/2 - this.pitchRadians;
                    
                }
                else{
                    trim = this.buildEaveTrimSegment( seg, trimMaterial, 6);   
                    trim.group.rotation.x = Math.PI/2;
                }

                trim.group.rotation.y= Math.PI;
                //trim.group.rotation.x = Math.PI/2;
                trim.group.position.y = this.getBackRakeLength();
                this.back.add(trim.group);
            });
    }

    buildEaveTrimSegment( segment, trimMaterial, angleHeight){
        let length = segment.start.distanceTo(segment.end);
        let mid = segment.start.clone();
        mid = mid.lerp(segment.end,.5);
        
        let trim;        
        if(this.config.gutters) {
            trim =new _3dTrimEaveGutter(length, trimMaterial, undefined, undefined, segment.leftHoriAngle, segment.rightHoriAngle);
            trim.group.position.set(mid.x, 0, mid.z);
        }
        else{            
            trim =new _3dTrimEaveAngle(length, 90-this.pitchDegrees, trimMaterial, undefined, undefined, 0, 0, angleHeight);
            trim.group.position.set(mid.x, 0+.05, mid.z);
        }
                
        layerHelper.enableLayer(trim.group,CORE.layers.quote);
        
        return trim;
    }

    

    buildPanels(){
        this.outlineMeshes=[];
        // BACK HALF
        this.buildBackRoof();
        this.buildBackSoffit();

        // FRONT HALF        
        this.buildFrontRoof();
        this.buildFrontSoffit();


    }

    getFrontPoints(){
        let frontRakeLength = this.getFrontRakeLength();
        let frontRoofPoints = [
            this.getPanelFrontLeft().x - this.leftEndExtensionLength - this.leftRidgeExtension, 0,
            this.getPanelFrontRight().x + this.rightEndExtensionLength + this.rightRidgeExtension, 0, // right ridge
        ];
        if (this.rightTieIn) {
            frontRoofPoints.push(...[this.getPanelFrontRight().x, this.rightGableTieRakeLengthFromRidge]);
        }
        frontRoofPoints.push(...[
            this.getRidgeRight().x + this.rightEndExtensionLength, frontRakeLength,
            this.getRidgeLeft().x - this.leftEndExtensionLength, frontRakeLength
        ]);

        if (this.leftTieIn) {
            frontRoofPoints.push(...[this.getPanelFrontLeft().x, this.leftGableTieRakeLengthFromRidge]);
        }
        return frontRoofPoints;
    }

    getHoleCoordinatesForGableTieTriangleBase(p1,p2,p3, rakeLengthWithExtension, extensionLength, soffitLengthSubtraction){
        let coordinates = [];
        coordinates.push({x: p1.x, y: (p1.y - soffitLengthSubtraction)}) // triangle base bottom left
        coordinates.push({x: p3.x, y: (p3.y - soffitLengthSubtraction)}) // triangle base bottom right

        let mTopRight = mathHelper.getSlopeFromCoordinates(p3, p2);
        let bTopRight = mathHelper.getYIntercept(mTopRight, p2);
        let yTopRight = rakeLengthWithExtension - extensionLength - soffitLengthSubtraction;
        coordinates.push({x: mathHelper.getXFromY(yTopRight, bTopRight, mTopRight), y: yTopRight}) // triangle base top right

        let mTopLeft = mathHelper.getSlopeFromCoordinates(p1, p2);
        let bTopLeft = mathHelper.getYIntercept(mTopLeft, p2);
        let yTopLeft = rakeLengthWithExtension - extensionLength - soffitLengthSubtraction
        coordinates.push({x: mathHelper.getXFromY(yTopLeft, bTopLeft, mTopLeft), y: yTopLeft}) // triangle base top left
  
        return coordinates;
    }

    getCoordinatePairFromY(y, b, m){
        // (y-b)/m = x
        return {x: (y-b)/m, y: y}
    }

    buildFrontSoffit(){
        let front = new EarcutDataManager();
        let soffitLengthSubtraction = CORE.roof.purlin.dim.height * Math.tan(this.pitchRadians)
        if (this.frontSideExtensionLength == 0)
            soffitLengthSubtraction = 0;

        let soffitPoints = this.getFrontPoints();
        // Front left y
        soffitPoints[soffitPoints.length-1] -= soffitLengthSubtraction
        // front right y
        soffitPoints[soffitPoints.length-3] -= soffitLengthSubtraction;
        front.setOutline(soffitPoints);

        // poke a hole for the front frame
        let rakeLength = this.getFrameRakeLength();
        front.addSquareHole(0,(rakeLength-soffitLengthSubtraction)/2,this.length,rakeLength-soffitLengthSubtraction)
        
        let frontTieIns = this.getFrontTieIns();
        frontTieIns.forEach((t) => {
            let halfWidth = t.width / 2;
            let eaveHeightOffset = this.eaveHeight - (t.eave * 12);
            let childPitchRatio = BlueprintHelper.pitchToPitchRatio(t.pitch);
            let childPitchRadians = BlueprintHelper.pitchRatioToRadians(childPitchRatio);
            let eaveOffsetWidth = eaveHeightOffset / Math.tan(childPitchRadians);

            let leftX = t.offset + eaveOffsetWidth;
            let centerX = t.offset + halfWidth;
            let rightX = t.offset + t.width - eaveOffsetWidth;
            let childPitchHeight = BlueprintHelper.pitchHeight(t.width, childPitchRatio, CORE.roof.types.gable);
            let childPitchHypotenuse = (childPitchHeight - eaveHeightOffset) / Math.sin(this.pitchRadians);
            let frontRakeLength = this.getFrontRakeLength();
            let p1 = new THREE.Vector2(-this.length / 2 + leftX, frontRakeLength);
            let p2 = new THREE.Vector2(-this.length / 2 + centerX, frontRakeLength - childPitchHypotenuse);
            let p3 = new THREE.Vector2(-this.length / 2 + rightX, frontRakeLength);

            front.addHoleFromVertices(this.getHoleCoordinatesForGableTieTriangleBase(p1, p2, p3, frontRakeLength, this.frontSideExtensionLength, soffitLengthSubtraction));
        });

        let frontData = front.generate();

        let frontRoofPanelTop = SheetingHelper.defineBackPlane(materialHelper.getExteriorPanelPbrMaterial(0xEEEEED));
        let frontRoofPanelBottom = SheetingHelper.defineFrontPlane(materialHelper.getInteriorPanelPbrMaterial(this.design.color));

        let frontSheeting = new Sheeting(
            CORE.preferences.des_levelOfDetail.value,
            frontData,
            frontRoofPanelTop,
            frontRoofPanelBottom,
            CORE.layers.roof);
            layerHelper.enableLayer(frontSheeting.group, CORE.layers.quote)
        frontSheeting.group.position.z=CORE.roof.purlin.dim.height +.1; // .75 gets the panel covering the eave struct and extension rafters
        frontSheeting.group.position.y= CORE.roof.purlin.dim.height * Math.tan(this.pitchRadians);//CORE.roof.purlin.dim.height/Math.tan(Math.PI/2-this.pitchRadians);
        this.front.add(frontSheeting.group);
       
        this.buildFrontSoffitTrim()
    }

    buildFrontRoof() {
        let frontRoofPoints = this.getFrontPoints();
        let front = new EarcutDataManager();
        front.setOutline(frontRoofPoints);

        let frontTieIns = this.getFrontTieIns();
        frontTieIns.forEach((t) => {
            let halfWidth = t.width / 2;
            let eaveHeightOffset = this.eaveHeight - (t.eave * 12);
            let childPitchRatio = BlueprintHelper.pitchToPitchRatio(t.pitch);
            let childPitchRadians = BlueprintHelper.pitchRatioToRadians(childPitchRatio);
            let eaveOffsetWidth = eaveHeightOffset / Math.tan(childPitchRadians);

            let leftX = t.offset + eaveOffsetWidth;
            let centerX = t.offset + halfWidth;
            let rightX = t.offset + t.width - eaveOffsetWidth;
            let childPitchHeight = BlueprintHelper.pitchHeight(t.width, childPitchRatio, CORE.roof.types.gable);
            let childPitchHypotenuse = (childPitchHeight - eaveHeightOffset) / Math.sin(this.pitchRadians);
            let frontRakeLength = this.getFrontRakeLength();
            let p1 = new THREE.Vector2(-this.length / 2 + leftX, frontRakeLength);
            let p2 = new THREE.Vector2(-this.length / 2 + centerX, frontRakeLength - childPitchHypotenuse);
            let p3 = new THREE.Vector2(-this.length / 2 + rightX, frontRakeLength);

            front.addTriangularHole(p1, p2, p3);
        });

        let frontData = front.generate();

        let frontRoofPanelTop = SheetingHelper.defineBackPlane(materialHelper.getExteriorPanelPbrMaterial(this.design.color));
        let frontRoofPanelBottom = SheetingHelper.defineFrontPlane(materialHelper.getInteriorPanelPbrMaterial(0xEEEEED));

        let frontSheeting = new Sheeting(
            CORE.preferences.des_levelOfDetail.value,
            frontData,
            frontRoofPanelTop,
            frontRoofPanelBottom,
            CORE.layers.roof);

        layerHelper.enableLayer(frontSheeting.group, CORE.layers.quote)
        this.front.add(frontSheeting.group);
        this.group.add(this.front);


        /////
        // generate outline-specific mesh.
        // because the collision zones are being obscured by transparent outline and pickDetection meshes: https://stackoverflow.com/questions/11165345/three-js-webgl-transparent-planes-hiding-other-planes-behind-them
        this.addOutlineMeshesToGroup(frontRoofPoints, this.front, true);
    }

    getBackPoints(){
        let backRakeLength = this.getBackRakeLength();
        // the slopes are built upside down so they can simply be rotated (like opening a book) into place
        let backRoofPoints = [
            this.getRidgeLeft().x - this.leftEndExtensionLength - this.leftRidgeExtension, 0,
            this.getRidgeRight().x + this.rightEndExtensionLength + this.rightRidgeExtension, 0, // right ridge (bottom right as built in 2D, pre-rotation)
        ];

        if (this.rightTieIn) {
            backRoofPoints.push(...[this.getPanelFrontRight().x, this.rightGableTieRakeLengthFromRidge]);
        }
        // 
        backRoofPoints.push(
            ...[this.getRidgeRight().x + this.rightEndExtensionLength, backRakeLength,
            this.getRidgeLeft().x - this.leftEndExtensionLength, backRakeLength] // back left eave (top left as built in 2D, pre-rotation)
        );
        if (this.leftTieIn) {
            backRoofPoints.push(...[this.getPanelFrontLeft().x, this.leftGableTieRakeLengthFromRidge]);
        }
        return backRoofPoints;
    }

    buildBackSoffit(){

        let back = new EarcutDataManager();
        let testHeight = CORE.roof.purlin.dim.height;
        let testTan = Math.tan(this.pitchRadians);
        let soffitLengthSubtraction = CORE.roof.purlin.dim.height * Math.tan(this.pitchRadians);
        if (this.backSideExtensionLength == 0)
            soffitLengthSubtraction = 0;
        
        let soffitPoints = this.getBackPoints();
        // Front left y
        soffitPoints[soffitPoints.length-1] -= soffitLengthSubtraction
        // front right y
        soffitPoints[soffitPoints.length-3] -= soffitLengthSubtraction;
        back.setOutline(soffitPoints);
        
        let rakeLength = this.getFrameRakeLength();
        back.addSquareHole(0,(rakeLength-soffitLengthSubtraction)/2,this.length,rakeLength-soffitLengthSubtraction)
        
        let backTieIns = this.getBackTieIns();
        backTieIns.forEach((t) => {
            let halfWidth = t.width / 2;
            let centerX = t.offset + halfWidth;
            let eaveHeightOffset = this.eaveHeight - (t.eave * 12);
            let childPitchRatio = BlueprintHelper.pitchToPitchRatio(t.pitch);
            let childPitchRadians = BlueprintHelper.pitchRatioToRadians(childPitchRatio);
            let eaveOffsetWidth = eaveHeightOffset / Math.tan(childPitchRadians);
            let leftX = t.offset + eaveOffsetWidth;
            let rightX = t.offset + t.width - eaveOffsetWidth;
            let childPitchHeight = BlueprintHelper.pitchHeight(t.width, childPitchRatio, CORE.roof.types.gable);
            let childPitchHypotenuse = (childPitchHeight - eaveHeightOffset) / Math.sin(this.pitchRadians);
            let backRakeLength = this.getBackRakeLength();
            let p1 = new THREE.Vector2(this.length/2 - leftX, backRakeLength);
            let p2 = new THREE.Vector2(this.length/2 - centerX, backRakeLength - childPitchHypotenuse);
            let p3 = new THREE.Vector2(this.length/2 - rightX, backRakeLength)
            
            back.addHoleFromVertices(this.getHoleCoordinatesForGableTieTriangleBase(p1, p2, p3, backRakeLength, this.backSideExtensionLength, soffitLengthSubtraction));
        });

        let backData = back.generate();

        let backSheeting = new Sheeting(
            CORE.preferences.des_levelOfDetail.value,
            backData,
            SheetingHelper.defineBackPlane(materialHelper.getInteriorPanelPbrMaterial(this.design.color), undefined),
            SheetingHelper.defineFrontPlane(materialHelper.getExteriorPanelPbrMaterial(0xEEEEED), true, -.01),
            CORE.layers.roof);
            layerHelper.enableLayer(backSheeting.group, CORE.layers.quote)

            backSheeting.group.position.z=-CORE.roof.purlin.dim.height -.1; // .75 for the extension rafter (which is overside) and .1 to get beyond it
            backSheeting.group.position.y=CORE.roof.purlin.dim.height/Math.tan(Math.PI/2-this.pitchRadians);
        this.back.add(backSheeting.group);     

        // soffit trim
        this.buildBackSoffitTrim()
    }

    buildBackSoffitTrim(trimMat){
        let backSoffitTrims = new THREE.Group();
        //A flat, smooth, piece of trim starting behind the eave/rake trim (whether guttered or not) and going “down” (that is following the frame depth which may be angled) to meet the soffit.
        
        let trimMaterials = trimMat ? trimMat : this.trimMaterials.eaveAndRake;
        
        let debugShape = false;
        // if(debugShape)
        //     trimMaterials = new THREE.MeshBasicMaterial({color: 0xff0000});
        
        let lExtension = this.leftEndExtensionLength;
        let rExtension = this.rightEndExtensionLength;
        let bExtension = this.backSideExtensionLength;
        
        let roofRotationShift = CORE.roof.purlin.dim.height * Math.tan(this.pitchRadians);

        let soffitTrimWidth = 2;
        let soffitTrimThickness = 2;

        let backRakeLength = this.getBackRakeLength();

        if (bExtension > 0) 
            backRakeLength -= roofRotationShift;        

        let specialLeftEave = (lExtension > 0 && bExtension == 0);
        let specialRightEave = (rExtension > 0 && bExtension == 0);

        // back left rake
        if (lExtension > 0) {
            if(debugShape)
                trimMaterials = new THREE.MeshBasicMaterial({color: 0x0000ff}); // blue

            let extraRakeLength = (bExtension > 0) ? soffitTrimWidth/2 : 0; 
            let rakeLen = backRakeLength + extraRakeLength;
            let bottomRakeVerticalAngle = specialLeftEave ? -this.pitchRadians : 0;
            let rake = new TrimSoffitRake(rakeLen, soffitTrimWidth, soffitTrimThickness, trimMaterials, this.pitchRadians, bottomRakeVerticalAngle, 0, 0, false, false)
            rake.group.position.x = - this.length/2 - lExtension + .05;
            rake.group.position.y = rakeLen/2;
            rake.group.position.z = /*-BlueprintHelper.pitchedPurlinDimY(this.pitchRatio)*/ - CORE.roof.purlin.dim.height + /*(soffitTrimThickness/Math.cos(this.pitchRadians))*/ - .11;
            rake.group.rotateZ(Math.PI/2)
            rake.group.rotateX(Math.PI/2)
            rake.group.name='back left soffit rake trim mesh';
            backSoffitTrims.add(rake.group);
        }
        else if (lExtension == 0 && bExtension > 0) {
            if(debugShape)
                trimMaterials = new THREE.MeshBasicMaterial({color: 0xff0000}); // red

            let rakeLen = bExtension - roofRotationShift + soffitTrimWidth;
            let bottomRakeVerticalAngle = specialLeftEave ? -this.pitchRadians : 0;
            let rake = new TrimSoffitRake(rakeLen, soffitTrimWidth, soffitTrimThickness, trimMaterials, this.pitchRadians, bottomRakeVerticalAngle, 0, 0, false, false)
            rake.group.position.x = - this.length/2 - lExtension + .05;
            rake.group.position.y = backRakeLength - rakeLen/2 + soffitTrimWidth/2;
            rake.group.position.z = /*-BlueprintHelper.pitchedPurlinDimY(this.pitchRatio)*/ - CORE.roof.purlin.dim.height + /*(soffitTrimThickness/Math.cos(this.pitchRadians))*/ - .11;
            rake.group.rotateZ(Math.PI/2)
            rake.group.rotateX(Math.PI/2)
            rake.group.name='back left soffit rake trim mesh';
            backSoffitTrims.add(rake.group);
        } 
        // back right rake
        if (rExtension > 0) {
            if(debugShape)
                trimMaterials = new THREE.MeshBasicMaterial({color: 0xffde21}); // yellow

            let extraRakeLength = (bExtension > 0) ? soffitTrimWidth/2 : 0;
            let rakeLen = backRakeLength + extraRakeLength;
            let bottomRakeVerticalAngle = specialRightEave ? -this.pitchRadians : 0;
            let rake = new TrimSoffitRake(rakeLen, soffitTrimWidth, soffitTrimThickness, trimMaterials, bottomRakeVerticalAngle, this.pitchRadians, 0, 0, false, false)
            rake.group.position.x = + this.length/2 + rExtension - .05;
            rake.group.position.y = rakeLen/2;
            rake.group.position.z = - CORE.roof.purlin.dim.height - .11;
            rake.group.rotateZ(-Math.PI/2)
            rake.group.rotateX(Math.PI/2)
            rake.group.name='back right soffit rake trim mesh';
            backSoffitTrims.add(rake.group);
        }
        else if (rExtension == 0 && bExtension > 0) {
            if(debugShape)
                trimMaterials = new THREE.MeshBasicMaterial({color: 0x89f336}); // lime green 

            let rakeLen = bExtension - roofRotationShift + soffitTrimWidth;
            let bottomRakeVerticalAngle = specialRightEave ? -this.pitchRadians : 0;
            let rake = new TrimSoffitRake(rakeLen, soffitTrimWidth, soffitTrimThickness, trimMaterials, bottomRakeVerticalAngle, this.pitchRadians, 0, 0, false, false)
            rake.group.position.x = + this.length/2 + rExtension - .05;
            rake.group.position.y = backRakeLength - rakeLen/2 + soffitTrimWidth/2;
            rake.group.position.z = - CORE.roof.purlin.dim.height - .11;
            rake.group.rotateZ(-Math.PI/2)
            rake.group.rotateX(Math.PI/2)
            rake.group.name='back right soffit rake trim mesh';
            backSoffitTrims.add(rake.group);
        } 

        // EAVE Soffit Trims

        if (bExtension == 0) {
            // special eave soffit trims when there is no back extension (the eave struts are vertical)

            // left
            if (lExtension > 0) {
                if(debugShape)
                    trimMaterials = new THREE.MeshBasicMaterial({color: 0xff5c00}); // orange

                let test = Math.tan(this.pitchRadians);
                let eaveTrimHeight = soffitTrimThickness*1.2;
                let trimPitchedY = eaveTrimHeight/Math.cos(this.pitchRadians);
                let eave = new TrimSoffitEave(lExtension + soffitTrimWidth, trimPitchedY, soffitTrimWidth, 90-this.pitchDegrees, trimMaterials);
                eave.group.position.x = - this.length/2 - lExtension/2 - soffitTrimWidth/2;
                eave.group.position.y = + backRakeLength - soffitTrimWidth*(test)+.11;
                eave.group.position.z = - CORE.roof.purlin.dim.height + eaveTrimHeight - .11;
                eave.group.rotateX(-Math.PI+this.pitchRadians);
                eave.group.name='back left soffit eave trim mesh';
                backSoffitTrims.add(eave.group);
            }
            if (rExtension > 0) {
                if(debugShape)
                    trimMaterials = new THREE.MeshBasicMaterial({color: 0x8a00c4}); // purple

                let test = Math.tan(this.pitchRadians);
                let eaveTrimHeight = soffitTrimThickness*1.2;
                let trimPitchedY = eaveTrimHeight/Math.cos(this.pitchRadians);
                let eave = new TrimSoffitEave(rExtension + soffitTrimWidth, trimPitchedY, soffitTrimWidth, 90-this.pitchDegrees, trimMaterials);
                eave.group.position.x = + this.length/2 + rExtension/2 + soffitTrimWidth/2;
                eave.group.position.y = + backRakeLength - soffitTrimWidth*(test)+.11;
                eave.group.position.z = - CORE.roof.purlin.dim.height + eaveTrimHeight - .11;
                eave.group.rotateX(-Math.PI+this.pitchRadians);
                eave.group.name='back right soffit eave trim mesh';
                backSoffitTrims.add(eave.group);
            }
        } else {
            if(debugShape)
                trimMaterials = new THREE.MeshBasicMaterial({color: 0xff21d6}); // pink

            this.backEaveSegments.forEach(segment => {
                // eave spanning across the whole back side
                // trim between eave and soffit
                let eaveTrimHeight = soffitTrimThickness*1.2;
                let mid = segment.start.clone();
                mid = mid.lerp(segment.end,.5);
                let eaveLength = this.length+lExtension+rExtension+2*soffitTrimWidth;
                let eave = new TrimSoffitEave(segment.start.distanceTo(segment.end), eaveTrimHeight, soffitTrimWidth, 90, trimMaterials);         
        
                eave.group.position.x = mid.x; //rExtension/2 - lExtension/2; //+ this.length/2 + rExtension/2 + soffitTrimWidth/2;
                eave.group.position.y = + backRakeLength + soffitTrimWidth/2// - soffitTrimWidth*(test)/4+.11;
                eave.group.position.z = -CORE.roof.purlin.dim.height + eaveTrimHeight - .11;
                eave.group.rotateX(-Math.PI+this.pitchRadians-this.pitchRadians);
                eave.group.name='back eave soffit trim';
                backSoffitTrims.add(eave.group);
            })
        }


        
        let pitchedPurlinDimY = BlueprintHelper.pitchedPurlinDimY(this.pitchRatio);
        backSoffitTrims.position.y += roofRotationShift;
        //layerHelper.setGroup(backSoffitTrims, CORE.layers.roof);
        this.back.add(backSoffitTrims);
        
    }

    buildFrontSoffitTrim(trimMat){
        let frontSoffitTrims = new THREE.Group();

        let trimMaterials = trimMat ? trimMat : this.trimMaterials.eaveAndRake;

        let debugShape = false;
        // if(debugShape)
        //         trimMaterials = new THREE.MeshBasicMaterial({color: 0xff0000});

        let lExtension = this.leftEndExtensionLength;
        let rExtension = this.rightEndExtensionLength;
        let fExtension = this.frontSideExtensionLength;

        let roofRotationShift = CORE.roof.purlin.dim.height * Math.tan(this.pitchRadians);
            
        let soffitTrimWidth = 2;
        let soffitTrimThickness = 2;

        let frontRakeLength = this.getFrontRakeLength();
        
        if (fExtension > 0) 
            frontRakeLength -= roofRotationShift;

        let specialLeftEave = (lExtension > 0 && fExtension == 0);
        let specialRightEave = (rExtension > 0 && fExtension == 0);
        //front left rake
        if (lExtension > 0) {
            if(debugShape)
                    trimMaterials = new THREE.MeshBasicMaterial({color: 0x0000ff}); // blue
            // rake from ridge to eave
            let extraRakeLength = (fExtension > 0) ? soffitTrimWidth/2 : 0; // eave a little more closer in the special eave trim scenario (fExtension = 0)
            let rakeLen = frontRakeLength + extraRakeLength;
            let bottomRakeVerticalAngle = specialLeftEave ? -this.pitchRadians : 0;
            let rake = new TrimSoffitRake(rakeLen, soffitTrimWidth, soffitTrimThickness, trimMaterials, bottomRakeVerticalAngle, this.pitchRadians, 0, 0, false, false)
            rake.group.position.x = - this.length/2 - lExtension + .05;
            rake.group.position.y = rakeLen/2;
            rake.group.position.z = + CORE.roof.purlin.dim.height + .11;
            rake.group.rotateZ(-Math.PI/2)
            rake.group.rotateX(-Math.PI/2)
            rake.group.name='front left soffit rake trim mesh';
            frontSoffitTrims.add(rake.group);
        }
        else if (lExtension == 0 && fExtension > 0) {
            if(debugShape)
                trimMaterials = new THREE.MeshBasicMaterial({color: 0xff0000}); // red
            // rake from eave strut to extension
            let rakeLen = fExtension - roofRotationShift + soffitTrimWidth;
            let bottomRakeVerticalAngle = specialLeftEave ? -this.pitchRadians : 0;
            let rake = new TrimSoffitRake(rakeLen, soffitTrimWidth, soffitTrimThickness, trimMaterials, bottomRakeVerticalAngle, this.pitchRadians, 0, 0, false, false)
            rake.group.position.x = - this.length/2 - lExtension + .05;
            rake.group.position.y = frontRakeLength - rakeLen/2 + soffitTrimWidth/2;
            rake.group.position.z = + CORE.roof.purlin.dim.height + .11;
            rake.group.rotateZ(-Math.PI/2)
            rake.group.rotateX(-Math.PI/2)
            rake.group.name='front left soffit rake trim mesh';
            frontSoffitTrims.add(rake.group);
        }
        // front right rake

        if (rExtension > 0) {
            if(debugShape)
                trimMaterials = new THREE.MeshBasicMaterial({color: 0xffde21}); // yellow
            // rake from ridge to eave
            let extraRakeLength = (fExtension > 0) ? soffitTrimWidth/2 : 0; // eave a little more closer in the special eave trim scenario (fExtension = 0)
            let rakeLen = frontRakeLength + extraRakeLength;
            let bottomRakeVerticalAngle = specialRightEave ? -this.pitchRadians : 0;
            let rake = new TrimSoffitRake(rakeLen, soffitTrimWidth, soffitTrimThickness, trimMaterials, this.pitchRadians, bottomRakeVerticalAngle, 0, 0, false, false)
            rake.group.position.x = + this.length/2 + rExtension - .05;
            rake.group.position.y = rakeLen/2;
            rake.group.position.z = + CORE.roof.purlin.dim.height + .11;
            rake.group.rotateZ(Math.PI/2)
            rake.group.rotateX(-Math.PI/2)
            rake.group.name='front right soffit rake trim mesh';
            frontSoffitTrims.add(rake.group);
        }
        else if (rExtension == 0 && fExtension > 0) {
            if(debugShape)
                trimMaterials = new THREE.MeshBasicMaterial({color: 0x89f336}); // lime green 
            // rake from eave strut to extension
            let rakeLen = fExtension - roofRotationShift + soffitTrimWidth;
            let bottomRakeVerticalAngle = specialRightEave ? -this.pitchRadians : 0;
            let rake = new TrimSoffitRake(rakeLen, soffitTrimWidth, soffitTrimThickness, trimMaterials, this.pitchRadians, bottomRakeVerticalAngle, 0, 0, false, false)
            rake.group.position.x = + this.length/2 + rExtension - .05;
            rake.group.position.y = frontRakeLength - rakeLen/2 + soffitTrimWidth/2;
            rake.group.position.z = + CORE.roof.purlin.dim.height + .11;
            rake.group.rotateZ(Math.PI/2)
            rake.group.rotateX(-Math.PI/2)
            rake.group.name='front right soffit rake trim mesh';
            frontSoffitTrims.add(rake.group);
        }

        if (fExtension == 0) {
            if(debugShape)
                trimMaterials = new THREE.MeshBasicMaterial({color: 0xff5c00}); // orange
            // special eave soffit trims on left or right when there is no back extension (the eave struts are vertical)
            if (lExtension > 0) {
                let test = Math.tan(this.pitchRadians);
                let eaveTrimHeight = soffitTrimThickness;
                let trimPitchedY = eaveTrimHeight/Math.cos(this.pitchRadians);
                let eave = new TrimSoffitEave(lExtension + soffitTrimWidth, trimPitchedY, soffitTrimWidth, 90-this.pitchDegrees, trimMaterials);
                eave.group.rotateY(Math.PI);
                eave.group.position.x = - this.length/2 - lExtension/2 - soffitTrimWidth/2;
                eave.group.position.y = + frontRakeLength - soffitTrimWidth*(test)+.11;
                eave.group.position.z = + CORE.roof.purlin.dim.height - eaveTrimHeight + .11;
                eave.group.rotateX(-Math.PI+this.pitchRadians);
                eave.group.name='front left soffit eave trim mesh';
                frontSoffitTrims.add(eave.group);
            }
            if (rExtension > 0) {
                if(debugShape)
                    trimMaterials = new THREE.MeshBasicMaterial({color: 0x8a00c4}); // purple
                let test = Math.tan(this.pitchRadians);
                let eaveTrimHeight = soffitTrimThickness;
                let trimPitchedY = eaveTrimHeight/Math.cos(this.pitchRadians);
                let eave = new TrimSoffitEave(rExtension + soffitTrimWidth, trimPitchedY, soffitTrimWidth, 90-this.pitchDegrees, trimMaterials);
                eave.group.rotateY(Math.PI);
                eave.group.position.x = + this.length/2 + rExtension/2 + soffitTrimWidth/2;
                eave.group.position.y = + frontRakeLength - soffitTrimWidth*(test)+.11;
                eave.group.position.z = + CORE.roof.purlin.dim.height - eaveTrimHeight + .11;
                eave.group.rotateX(-Math.PI+this.pitchRadians);
                eave.group.name='front right soffit eave trim mesh';
                frontSoffitTrims.add(eave.group);
            }


        } else {
            if(debugShape)
                trimMaterials = new THREE.MeshBasicMaterial({color: 0xff21d6}); // pink
            // regular eave trim that spans the whole front sides
            let tieins = this.getFrontTieIns();
            this.frontEaveSegments.forEach(segment => {
                let eaveTrimHeight = soffitTrimThickness*1.2;
                let mid = segment.start.clone();
                mid = mid.lerp(segment.end,.5);
                let eaveLength = this.length+lExtension+rExtension+2*soffitTrimWidth;
                let eave = new TrimSoffitEave(segment.start.distanceTo(segment.end), eaveTrimHeight, soffitTrimWidth, 90, trimMaterials);         
                
                eave.group.rotateY(Math.PI);
                 eave.group.rotateX(Math.PI);
              
                eave.group.position.x = mid.x//rExtension/2 - lExtension/2; //+ this.length/2 + rExtension/2 + soffitTrimWidth/2;
                eave.group.position.y = + frontRakeLength + soffitTrimWidth/2// - soffitTrimWidth*(test)/4+.11;
                eave.group.position.z = + CORE.roof.purlin.dim.height -eaveTrimHeight + .11;
               
                eave.group.name='uilack eave soffit trim';
                frontSoffitTrims.add(eave.group);
            })
            
            //#region old regular eave
            // let eaveTrimHeight = soffitTrimThickness*1.2;
            // let eaveLength = this.length+lExtension+rExtension+2*soffitTrimWidth;
            // let eave = new TrimSoffitEave(eaveLength, eaveTrimHeight, soffitTrimWidth, 90, trimMaterials);         
            
            // eave.group.rotateY(Math.PI);
            
            // eave.group.position.x = rExtension/2 - lExtension/2; //+ this.length/2 + rExtension/2 + soffitTrimWidth/2;
            // eave.group.position.y = + frontRakeLength + soffitTrimWidth/2// - soffitTrimWidth*(test)/4+.11;
            // eave.group.position.z = + CORE.roof.purlin.dim.height -eaveTrimHeight + .11;
            // eave.group.rotateX(-Math.PI);
            // eave.group.name='back eave soffit trim';
            // frontSoffitTrims.add(eave.group);
            // #endregion
            
            
        }
        /*
            // old rake 
            let trimGeo = new THREE.BoxGeometry(soffitTrimWidth, frontRakeLength, soffitTrimThickness);        
            let trimMesh = new THREE.Mesh(trimGeo, trimMaterials);
    
            trimMesh.name='front left soffit rake trim mesh';
            trimMesh.position.x = - this.length/2 - lExtension - soffitTrimWidth/2 + .05;   // + 0.05 moves the trim piece towards the main building enough to fill the gap to the soffit
            trimMesh.position.y = frontRakeLength/2;
            // TEST if z's purlin height should be changed purlinPitchY?
            trimMesh.position.z = + CORE.roof.purlin.dim.height - soffitTrimThickness/2 + .11; // +.11 moves the trim piece down enough to fill the gap
            //frontSoffitTrims.add(trimMesh);

            // old eave
            // trim between eave and soffit
            let trim2Geo = new THREE.BoxGeometry(lExtension+soffitTrimThickness, soffitTrimWidth, soffitTrimThickness);            
            let trim2Mesh = new THREE.Mesh(trim2Geo, trimMaterials);
    
            trim2Mesh.name='front left soffit eave trim mesh';
            trim2Mesh.position.x = - this.length/2 - lExtension/2 - soffitTrimWidth/2;
            trim2Mesh.position.y = frontRakeLength;
            trim2Mesh.position.z = CORE.roof.purlin.dim.height - soffitTrimThickness/2 + .11; // match the position of the rake trim
            frontSoffitTrims.add(trim2Mesh);
        } else {
            if (fExtension > 0) {
                // trim between  rake and soffit
                let tLength = fExtension;
                let trimGeo = new THREE.BoxGeometry(soffitTrimWidth, tLength, soffitTrimThickness);        
                let trimMesh = new THREE.Mesh(trimGeo, trimMaterials);
        
                trimMesh.name='front left soffit rake trim mesh';
                trimMesh.position.x = - this.length/2 - lExtension - soffitTrimWidth/2 + .05;   // + 0.05 moves the trim piece towards the main building enough to fill the gap to the soffit
                trimMesh.position.y = + frontRakeLength - tLength/2;
                trimMesh.position.z = + CORE.roof.purlin.dim.height - soffitTrimThickness/2 + .11; // +.11 moves the trim piece down enough to fill the gap
                frontSoffitTrims.add(trimMesh);
            }
        }
        */
        /*
        if (rExtension > 0) {
            // trim between  rake and soffit
            let trimGeo = new THREE.BoxGeometry(soffitTrimWidth, frontRakeLength+soffitTrimWidth, soffitTrimThickness); 
            let trimMesh = new THREE.Mesh(trimGeo, trimMaterials);
    
            trimMesh.name='front right soffit rake trim mesh';
            trimMesh.position.x = this.length/2 + rExtension + soffitTrimWidth/2 + 0.05;
            trimMesh.position.y = frontRakeLength/2 + soffitTrimWidth;
            trimMesh.position.z = CORE.roof.purlin.dim.height - soffitTrimThickness/2 + .11;
            frontSoffitTrims.add(trimMesh);

            // trim between eave and soffit
            let trim2Geo = new THREE.BoxGeometry(rExtension + soffitTrimThickness, soffitTrimWidth, soffitTrimThickness);
            let trim2Mesh = new THREE.Mesh(trim2Geo, trimMaterials);
    
            trim2Mesh.name='back soffit trim mesh';
            trim2Mesh.position.x = + this.length/2 + rExtension/2 + soffitTrimWidth/2;
            trim2Mesh.position.y = + frontRakeLength + soffitTrimWidth;
            trim2Mesh.position.z = CORE.roof.purlin.dim.height - soffitTrimThickness/2 + .11;
            frontSoffitTrims.add(trim2Mesh);
        }
        
        if (fExtension > 0) {
            // trim between eave and soffit
            let trimEGeo = new THREE.BoxGeometry(this.length+soffitTrimThickness*2, soffitTrimWidth, soffitTrimThickness)
            let trimEMesh = new THREE.Mesh(trimEGeo, trimMaterials)
    
            trimEMesh.name='front soffit trim mesh';
            trimEMesh.position.x = 0; //+ this.length/2 + rExtension/2 + soffitTrimWidth/2;
            trimEMesh.position.y = frontRakeLength;
            trimEMesh.position.z = CORE.roof.purlin.dim.height - soffitTrimThickness/2 + .11;
            frontSoffitTrims.add(trimEMesh);

            if (lExtension == 0) {
                /*
                // trim between  rake and soffit
                let tLength = fExtension;
                let trimGeo = new THREE.BoxGeometry(soffitTrimWidth, tLength, soffitTrimThickness);        
                let trimMesh = new THREE.Mesh(trimGeo, trimMaterials);
        
                trimMesh.name='front left soffit rake trim mesh';
                trimMesh.position.x = - this.length/2 - lExtension - soffitTrimWidth/2 + .05;   // + 0.05 moves the trim piece towards the main building enough to fill the gap to the soffit
                trimMesh.position.y = + frontRakeLength - tLength/2;
                trimMesh.position.z = + CORE.roof.purlin.dim.height - soffitTrimThickness/2 + .11; // +.11 moves the trim piece down enough to fill the gap
                frontSoffitTrims.add(trimMesh);
                
            }

            if (rExtension == 0) {
                // trim between  rake and soffit
                let trimGeo = new THREE.BoxGeometry(soffitTrimWidth, fExtension, soffitTrimThickness); 
                let trimMesh = new THREE.Mesh(trimGeo, trimMaterials);
        
                trimMesh.name='front right soffit rake trim mesh';
                trimMesh.position.x = this.length/2 + rExtension + soffitTrimWidth/2 + 0.05;
                trimMesh.position.y = frontRakeLength - fExtension/2;
                trimMesh.position.z = CORE.roof.purlin.dim.height - soffitTrimThickness/2 + .11;
                frontSoffitTrims.add(trimMesh);
            }
        }
        */
        let frontEaveY = (CORE.roof.purlin.dim.height/2) * Math.sin(this.pitchRadians)
        let pitchedPurlinDimY = BlueprintHelper.pitchedPurlinDimY(this.pitchRatio);
        frontSoffitTrims.position.y += roofRotationShift; //roofRotationShift - soffitTrimWidth;
        layerHelper.setGroup(frontSoffitTrims, CORE.layers.roof);
        this.front.add(frontSoffitTrims);
        
    }

    buildBackRoof() {
        let backRakeLength = this.getBackRakeLength();
        let backRoofPoints = this.getBackPoints();
        let back = new EarcutDataManager();
        back.setOutline(backRoofPoints);

        let backTieIns = this.getBackTieIns();
        backTieIns.forEach((t) => {
            let halfWidth = t.width / 2;
            let centerX = t.offset + halfWidth;
            let eaveHeightOffset = this.eaveHeight - (t.eave * 12);
            let childPitchRatio = BlueprintHelper.pitchToPitchRatio(t.pitch);
            let childPitchRadians = BlueprintHelper.pitchRatioToRadians(childPitchRatio);
            let eaveOffsetWidth = eaveHeightOffset / Math.tan(childPitchRadians);
            let leftX = t.offset + eaveOffsetWidth;
            let rightX = t.offset + t.width - eaveOffsetWidth;

            let childPitchHeight = BlueprintHelper.pitchHeight(t.width, childPitchRatio, CORE.roof.types.gable);

            //let parentPitchAngle = BlueprintHelper.pitchRatioToRadians(childPitchRatio);
            let childPitchHypotenuse = (childPitchHeight - eaveHeightOffset) / Math.sin(this.pitchRadians);
            //
            //let eaveHeightOffset = this.eaveHeight-(t.eave*12);
            //let childPitchHypotenuse = (childPitchHeight-eaveHeightOffset) / Math.sin(this.pitchRadians);
            //
            back.addTriangularHole(
                { x: this.length / 2 - leftX, y: backRakeLength },
                { x: this.length / 2 - centerX, y: backRakeLength - childPitchHypotenuse },
                { x: this.length / 2 - rightX, y: backRakeLength }
            );
        });

        let backData = back.generate();

        let backSheeting = new Sheeting(
            CORE.preferences.des_levelOfDetail.value,
            backData,
            SheetingHelper.defineBackPlane(materialHelper.getInteriorPanelPbrMaterial(0xEEEEED), undefined),
            SheetingHelper.defineFrontPlane(materialHelper.getExteriorPanelPbrMaterial(this.design.color), true, -.01),
            CORE.layers.roof);

        layerHelper.enableLayer(backSheeting.group, CORE.layers.quote)
        this.back.add(backSheeting.group);
        //this.group.add(backRoof);
        this.addOutlineMeshesToGroup(backRoofPoints, this.back, false);
    }

    buildLeftValleyFraming(){
        if(!this.leftTieIn)
            return;
        // one hypotenuse must be found to find another hypotenuse, when two angled surfaces meet to create a valley
        let valleyGroup = new THREE.Group();
        let leftTieInPitchRatio = BlueprintHelper.pitchToPitchRatio(this.leftTieIn.pitch);
        let leftTieInPitchRadians = BlueprintHelper.pitchRatioToRadians(leftTieInPitchRatio);
        
        let zCountourPoint = this.width/2;
        zCountourPoint = this.leftGableTieRakeLengthFromRidge*Math.cos(this.pitchRadians);
        let yContourPoint = this.leftGableTieRakeLengthFromRidge*Math.sin(this.pitchRadians);
        // left tie in, front valley
        let triangle1Theta = Math.atan((zCountourPoint)/this.leftRidgeExtension)
        let triangle1Hypot = this.leftRidgeExtension/Math.cos(triangle1Theta);
        let triangle2Theta = Math.atan(yContourPoint/triangle1Hypot);
        let triangle2Hypot = triangle1Hypot/Math.cos(triangle2Theta);
        let xRot = triangle2Theta - Math.PI/2;
        let yRot = Math.PI/2-triangle1Theta

        

        let valleyFrontLeft = new RoofValleyFlange(CORE.preferences.des_levelOfDetail.value, CORE.layers.frame, triangle2Hypot, 
            //leftTieInPitchRadians -triangle2Theta, 
            undefined,
            this.pitchRadians-triangle2Theta
            );
        valleyFrontLeft.group.position.x = -this.length/2;
        valleyFrontLeft.group.position.z = zCountourPoint;
        valleyFrontLeft.group.position.y = this.pitchHeight-yContourPoint
        
        valleyFrontLeft.group.setRotationFromQuaternion(
            new THREE.Quaternion().multiplyQuaternions(
            // WELCOME TO QUATERNIONS:
                // Quaternions are in world coordinates
                new THREE.Quaternion().setFromAxisAngle(new Vector3(0,1,0),yRot+Math.PI),
                new THREE.Quaternion().setFromAxisAngle(new Vector3(1,0,0),+Math.PI/2-triangle2Theta), 
            )
        );

        valleyGroup.add(valleyFrontLeft.group);



        // left tie in, back valley
        let valleyBackLeft = new RoofValleyFlange(CORE.preferences.des_levelOfDetail.value, CORE.layers.frame, triangle2Hypot, 
            leftTieInPitchRadians -triangle2Theta, 
            undefined,
            //this.pitchRadians-triangle2Theta
            );
        valleyBackLeft.group.position.x = -this.length/2;
        valleyBackLeft.group.position.z = -zCountourPoint
        valleyBackLeft.group.position.y = this.pitchHeight-yContourPoint
        valleyBackLeft.group.setRotationFromQuaternion(
            new THREE.Quaternion().multiplyQuaternions(
            // WELCOME TO QUATERNIONS:
                // Quaternions are in world coordinates
                new THREE.Quaternion().setFromAxisAngle(new Vector3(0,1,0),Math.PI/2+triangle1Theta),
                new THREE.Quaternion().setFromAxisAngle(new Vector3(1,0,0),-Math.PI/2+triangle2Theta), 
            )
        );

        valleyGroup.add(valleyBackLeft.group);
        //valleyGroup.position.y =-1;
        this.group.add(valleyGroup);



    }

    buildRightValleyFraming(){
        if(!this.rightTieIn)
            return;
        // one hypotenuse must be found to find another hypotenuse, when two angled surfaces meet to create a valley
        let rightTieInPitchRatio = BlueprintHelper.pitchToPitchRatio(this.rightTieIn.pitch);
        let rightTieInPitchRadians = BlueprintHelper.pitchRatioToRadians(rightTieInPitchRatio);
        let zCountourPoint = this.width/2;
        zCountourPoint = this.rightGableTieRakeLengthFromRidge*Math.cos(this.pitchRadians);
        let yContourPoint = this.rightGableTieRakeLengthFromRidge*Math.sin(this.pitchRadians);
        // right tie in, front valley
        let triangle1Theta = Math.atan((zCountourPoint)/this.rightRidgeExtension)
        let triangle1Hypot = this.rightRidgeExtension/Math.cos(triangle1Theta);
        let triangle2Theta = Math.atan(yContourPoint/triangle1Hypot);
        let triangle2Hypot = triangle1Hypot/Math.cos(triangle2Theta);
        let xRot = triangle2Theta - Math.PI/2;
        let yRot = -Math.PI/2+triangle1Theta

        

        let valleyFrontRight = new RoofValleyFlange(CORE.preferences.des_levelOfDetail.value, CORE.layers.frame, triangle2Hypot, 
            rightTieInPitchRadians -triangle2Theta)//, this.pitchRadians-triangle2Theta);
        valleyFrontRight.group.position.x = this.length/2;
        valleyFrontRight.group.position.z = zCountourPoint;
        valleyFrontRight.group.position.y = this.pitchHeight-yContourPoint
        //valley.group.rotation.x = -Math.PI/2+this.pitchRadians
        //valley.group.rotation.z = yRot;

        valleyFrontRight.group.setRotationFromQuaternion(
            new THREE.Quaternion().multiplyQuaternions(
            // WELCOME TO QUATERNIONS:
                // Quaternions are in world coordinates
                new THREE.Quaternion().setFromAxisAngle(new Vector3(0,1,0),+(+triangle1Theta)-Math.PI/2),
                new THREE.Quaternion().setFromAxisAngle(new Vector3(1,0,0),-Math.PI/2+triangle2Theta), 
            )
        );

        this.group.add(valleyFrontRight.group);

        // right tie in, back valley
        let valleyBackRight = new RoofValleyFlange(CORE.preferences.des_levelOfDetail.value, CORE.layers.frame, triangle2Hypot,
            // rightTieInPitchRadians -triangle2Theta, 
            undefined,            this.pitchRadians-triangle2Theta);
        valleyBackRight.group.position.x = this.length/2;
        valleyBackRight.group.position.z = -zCountourPoint;
        valleyBackRight.group.position.y = this.pitchHeight-yContourPoint

        
        valleyBackRight.group.setRotationFromQuaternion(
            new THREE.Quaternion().multiplyQuaternions(
            // WELCOME TO QUATERNIONS:
                // Quaternions are in world coordinates
                new THREE.Quaternion().setFromAxisAngle(new Vector3(0,1,0),-(-Math.PI/2+triangle1Theta)+Math.PI),
                new THREE.Quaternion().setFromAxisAngle(new Vector3(1,0,0),-Math.PI/2+triangle2Theta), 
            )
        );

        this.group.add(valleyBackRight.group);



    }

    

    buildEaves(){
        
        let collisionZones = true;
        
        let frontPurlinEnd = this.getFrameRakeLength();// - CORE.roof.purlin.dim.width//-CORE.roof.purlin.spacing.min

        let len = this.length;
        let posX = 0;

        
        let leftEndAddition = this.getLeftEndAdditionAtFrontPosition(frontPurlinEnd);
        len += leftEndAddition;
        // any additional left end length requires we shift the center toward that end
        posX -= leftEndAddition/2;

        let rightEndAddition = this.getRightEndAdditionAtFrontPosition(frontPurlinEnd);            
        len += rightEndAddition;
        // any additional right end length requires we shift the center toward that end
        posX += rightEndAddition/2;
        // find the horizontal width of the eave strut, given that the flanges are 3.5 in length and angled at pitchRadians.
        let frontEaveY = (CORE.roof.purlin.dim.height/2) * Math.sin(this.pitchRadians); //cos(theta) = adj/hyp, so hyp*cos(theta)=adj  
        // the eave strut is naturally vertical, built on a roof that is standing on end, rotated CW (from positive X perspective), 
        // is skewed with pitchRadians, so half the height sticks above the end of the roof, so we much 
        // the eave strut origin is at the outside of the C shape, so add the sin of half of the height of the eave strut
        let pos = new THREE.Vector3(
            posX,
            frontPurlinEnd+(frontEaveY),
            .05+CORE.roof.purlin.dim.height/2)
        
        let frontQRot = new THREE.Quaternion().multiplyQuaternions(
            new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1,0,0),-Math.PI/2),  //-Math.atan(+this.pitchRatio)
            new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0,1,0),0)
        );
        
        let frontEave = new _3dEaveStrut(CORE.preferences.des_levelOfDetail.value, len, pos, 
            //undefined,
             frontQRot,
             collisionZones, this.pitchRatio, this.purlinColor);
        this.front.add(frontEave.group);
        layerHelper.enableLayer(frontEave.group,CORE.layers.quote);




        let backPurlinEnd = this.getFrameRakeLength()// - CORE.roof.purlin.dim.width//-CORE.roof.purlin.spacing.min
        len = this.length;       
        posX=0; 
        leftEndAddition = this.getLeftEndAdditionAtBackPosition(backPurlinEnd);
        len += leftEndAddition;
        // any additional left end length requires we shift the center toward that end
        posX -= leftEndAddition/2;

        rightEndAddition = this.getRightEndAdditionAtBackPosition(backPurlinEnd);            
        len += rightEndAddition;
        // any additional right end length requires we shift the center toward that end
        posX += rightEndAddition/2;
        let backEaveY = (CORE.roof.purlin.dim.height/2) * Math.sin(this.pitchRadians);
        pos = new THREE.Vector3(posX,
            backPurlinEnd+backEaveY,// - CORE.roof.purlin.dim.thickness - CORE.roof.purlin.dim.width/2,
        -.05-CORE.roof.purlin.dim.height/2)
        // Quaternions are in world space
        let backQRot = new THREE.Quaternion().multiplyQuaternions(
            new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1,0,0),Math.PI/2), //Math.atan(-this.pitchRatio)
            new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0,1,0),-Math.PI)
        );

        let backEave = new _3dEaveStrut(CORE.preferences.des_levelOfDetail.value, len, pos,  backQRot, collisionZones, this.pitchRatio, this.purlinColor);
        this.back.add(backEave.group);
        layerHelper.enableLayer(backEave.group,CORE.layers.quote);

    }

    // buildPurlins(){      
        
    //     let height = - BlueprintHelper.pitchedPurlinDimY(this.pitchRatio)
        
    //     let neghl = -this.halfLength; // negative half length (building used to be centered on the origin instead of origin being at Back left corner)
    //     let hl = this.halfLength;
    //     let neghw = -this.halfWidth; // negative half width (building used to be centered on the origin instead of origin being at Back left corner)
    //     let hw = this.halfWidth;

    //     let backSideLeftEnd = new THREE.Vector3(neghl-this.leftEndExtensionLength,height,hw);

    //     let ridgeLeft = this.getRidgePurlinLeft();
    //     ridgeLeft.x -= this.leftRidgeExtension
    //     ridgeLeft.x -= this.leftEndExtensionLength;

    //     let frontSideLeftEnd = new THREE.Vector3(neghl-this.leftEndExtensionLength,height,neghw);
        
    //     let backSideRightEnd = new THREE.Vector3(hl+this.rightEndExtensionLength,height,hw);
    //     let ridgeRight = this.getRidgePurlinRight();
    //     ridgeRight.x += this.rightRidgeExtension
    //     ridgeRight.x += this.rightEndExtensionLength;
    //     let frontSideRightEnd = new THREE.Vector3(hl+this.rightEndExtensionLength,height,neghw);

    //     let dist = ridgeLeft.distanceTo(backSideLeftEnd); // get the full run distance to calculate backoffs
    //     let backSideLeftEndRidgeBackoff = new THREE.Vector3();
    //     backSideLeftEndRidgeBackoff.lerpVectors(backSideLeftEnd,ridgeLeft,(dist-this.design.peakSpace)/dist);
        
    //     let backSideRightEndRidgeBackoff = new THREE.Vector3();                
    //     backSideRightEndRidgeBackoff.lerpVectors(backSideRightEnd,ridgeRight,(dist-this.design.peakSpace)/dist);

    //     let frontSideLeftEndRidgeBackoff = new THREE.Vector3();        
    //     frontSideLeftEndRidgeBackoff.lerpVectors(frontSideLeftEnd,ridgeLeft,(dist-this.design.peakSpace)/dist);
    //     let frontSideRightEndRidgeBackoff = new THREE.Vector3();        
    //     frontSideRightEndRidgeBackoff.lerpVectors(frontSideRightEnd,ridgeRight,(dist-this.design.peakSpace)/dist);

    //     dist = backSideLeftEndRidgeBackoff.distanceTo(backSideLeftEnd);

    //     // back side
    //     let currLeftBack = new THREE.Vector3().copy(backSideLeftEnd);
    //     let currRightBack = new THREE.Vector3().copy(backSideRightEnd);
        
    //     let currLeftFront = new THREE.Vector3().copy(frontSideLeftEnd);
    //     let currRightFront = new THREE.Vector3().copy(backSideRightEnd);

    //     // number of purlins (dist / spacing)
    //     let count = Math.ceil(dist / CORE.roof.purlin.spacing.min);
    //     let spacing = dist / count;
    //     for(let i=1;i<=count;i++){
    //         currLeftBack.lerpVectors(backSideLeftEnd,backSideLeftEndRidgeBackoff,(i*spacing)/dist);
    //         currRightBack.lerpVectors(backSideRightEnd,backSideRightEndRidgeBackoff,(i*spacing)/dist);

    //         let pos = new THREE.Vector3().copy(currLeftBack).lerp(currRightBack, .5); 
    //         let len = currLeftBack.distanceTo(currRightBack);
    //         let p;
    //         let rot = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1,0,0),Math.atan(this.pitchRatio));

    //         if(this.purlinType === CORE.roof.purlin.types.C)
    //             p = new _3dPurlinC(CORE.preferences.des_levelOfDetail.value, len, pos, rot, this.purlinColor);
    //         else
    //             p = new _3dPurlinZ(CORE.preferences.des_levelOfDetail.value, len, pos, rot, this.purlinColor);
    //         //pos.y += CORE.roof.purlin.dim.height/2;
    //         p.group.position.copy(pos);
    //         p.group.rotation.copy(rot);
    //         this.group.add(p.group);
    //         layerHelper.enableLayer(p.group,CORE.layers.quote);
    //     }

    //     // front side
        
    //     for(let i=1;i<=count;i++){
    //         currLeftFront.lerpVectors(frontSideLeftEnd,frontSideLeftEndRidgeBackoff,(i*spacing)/dist);
    //         currRightFront.lerpVectors(frontSideRightEnd,frontSideRightEndRidgeBackoff,(i*spacing)/dist);

    //         let pos = new THREE.Vector3().copy(currLeftFront).lerp(currRightFront, .5); 
    //         let len = currLeftFront.distanceTo(currRightFront);
    //         let p;
    //         let rot = new THREE.Quaternion().multiplyQuaternions(
    //             new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1,0,0),Math.atan(-this.pitchRatio)), // TODO: CBH, I think this atan(-this.pitch) is a defect and should be just -this.pitch withuot atan, because pitch is already in radians. and atan(O/A) = theta, while tan(theta) = O/A
    //             new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0,1,0),Math.PI)
    //         );
    //         if(this.purlinType === CORE.roof.purlin.types.C)
    //             p = new _3dPurlinC(CORE.preferences.des_levelOfDetail.value, len, pos, rot, this.purlinColor);
    //         else
    //             p = new _3dPurlinZ(CORE.preferences.des_levelOfDetail.value, len, pos, rot, this.purlinColor);
    //         //pos.y += CORE.roof.purlin.dim.height/2;
    //         p.group.position.copy(pos);
    //         p.group.rotation.copy(rot);
    //         this.group.add(p.group);
    //         layerHelper.enableLayer(p.group,CORE.layers.quote);
    //     }
    // }



    getFrameRakeLength(){
        return (this.width/2)/Math.cos(this.pitchRadians);
    }

    getFrontRakeLength(){
        return this.getFrameRakeLength() + this.frontSideExtensionLength;
    }
    
    getBackRakeLength(){
        return this.getFrameRakeLength() + this.backSideExtensionLength;
    }
    

    buildPurlins2(){      
        
        // get the "rakeLength" of the roof
        let frontLength = this.getFrameRakeLength()
        // subtract peak space
        let purlinIndex = 0;
        let frontPurlinStart = this.design.peakSpace;
        let frontPurlinEnd = frontLength; //-CORE.roof.purlin.spacing.min
        if(frontPurlinEnd<frontPurlinStart)
            return;
        let frontPurlinRange = frontPurlinEnd - frontPurlinStart;
        let frontPurlinSpaceCount = Math.ceil(frontPurlinRange / CORE.roof.purlin.spacing.min);
        let frontPurlinSpacingActual = frontPurlinRange / frontPurlinSpaceCount;
        //let purlinCurr = frontPurlinStart
        
        while(purlinIndex < frontPurlinSpaceCount){
            let len = this.length;
            let posX = 0;

            let purlinCurr = frontPurlinStart + frontPurlinSpacingActual * purlinIndex;
            let leftEndAddition = this.getLeftEndAdditionAtFrontPosition(purlinCurr);
            len += leftEndAddition;
            // any additional left end length requires we shift the center toward that end
            posX -= leftEndAddition/2;

            let rightEndAddition = this.getRightEndAdditionAtFrontPosition(purlinCurr);
            len += rightEndAddition;
            // any additional right end length requires we shift the center toward that end
            posX += rightEndAddition/2;

            let pos = new THREE.Vector3(posX,purlinCurr,.05+CORE.roof.purlin.dim.height/2);
            let p;
            if(this.purlinType === CORE.roof.purlin.types.C)
                p = new _3dPurlinC(CORE.preferences.des_levelOfDetail.value, len, undefined, undefined, this.purlinColor);
            else
                p = new _3dPurlinZ(CORE.preferences.des_levelOfDetail.value, len, undefined, undefined, this.purlinColor);
            p.group.position.copy(pos);
            p.group.rotation.x = Math.PI/2;
            this.front.add(p.group);
            layerHelper.enableLayer(p.group,CORE.layers.quote);
            //purlinCurr+=frontPurlinSpacingActual;
            purlinIndex++;
        }

        purlinIndex = 0;
        // get the "rakeLength" of the roof
        let backLength = this.getFrameRakeLength()
        // subtract peak space
        let backPurlinStart = this.design.peakSpace;
        let backPurlinEnd = backLength; //-CORE.roof.purlin.spacing.min
        if(backPurlinEnd<backPurlinStart)
            return;
        let backPurlinRange = backPurlinEnd - backPurlinStart;
        let backPurlinSpaceCount = Math.ceil(backPurlinRange / CORE.roof.purlin.spacing.min);
        let backPurlinSpacingActual = backPurlinRange / backPurlinSpaceCount;
        //purlinCurr = backPurlinStart
        
        while(purlinIndex < backPurlinSpaceCount){
            let len = this.length;
            let posX = 0;
            let purlinCurr = backPurlinStart + backPurlinSpacingActual * purlinIndex;
            
            let leftEndAddition = this.getLeftEndAdditionAtBackPosition(purlinCurr);
            len += leftEndAddition;
            // any additional left end length requires we shift the center toward that end
            posX -= leftEndAddition/2;

            let rightEndAddition = this.getRightEndAdditionAtBackPosition(purlinCurr);
            len += rightEndAddition;
            // any additional right end length requires we shift the center toward that end
            posX += rightEndAddition/2;

            let pos = new THREE.Vector3(posX,purlinCurr,-.05-CORE.roof.purlin.dim.height/2);
            let p;
            if(this.purlinType === CORE.roof.purlin.types.C)
                p = new _3dPurlinC(CORE.preferences.des_levelOfDetail.value, len, undefined, undefined, this.purlinColor);
            else
                p = new _3dPurlinZ(CORE.preferences.des_levelOfDetail.value, len, undefined, undefined, this.purlinColor);
            p.group.position.copy(pos);
            p.group.rotation.y = Math.PI;
            p.group.rotation.x = -Math.PI/2;
            
            this.back.add(p.group);
            layerHelper.enableLayer(p.group,CORE.layers.quote);
            //purlinCurr+=backPurlinSpacingActual;
            purlinIndex++;
        }
    }   


    getRightEndAdditionAtFrontPosition(distFromRidge) {
        let rightEndAddition = 0;

        if (this.rightTieIn) {
            // if this point in the hypotenus is part of the tie
            //          use purlinCurr here
            // adjust the length and position accordingly
            if(distFromRidge > this.rightGableTieRakeLengthFromRidge)
                return 0;
            // TODO: this is only correct at distFromRidge = 0
            rightEndAddition = this.rightRidgeExtension *(1-(distFromRidge/this.rightGableTieRakeLengthFromRidge))
        }

        else {
            // add the right extension
            rightEndAddition += this.rightEndExtensionLength;
        }
        return rightEndAddition;
    }

    getLeftEndAdditionAtFrontPosition(distFromRidge) {
        let leftEndAddition = 0;
        if (this.leftTieIn) {
            if(distFromRidge > this.leftGableTieRakeLengthFromRidge)
                return 0;
            leftEndAddition = this.leftRidgeExtension *(1-(distFromRidge/this.leftGableTieRakeLengthFromRidge))
        }
        else {
            // add the left extension
            leftEndAddition += this.leftEndExtensionLength;
        }
        return leftEndAddition;
    }

    
    getRightEndAdditionAtBackPosition(distFromRidge) {
        let rightEndAddition = 0;

        if (this.rightTieIn) {            
            if(distFromRidge > this.rightGableTieRakeLengthFromRidge)
                return 0;
            rightEndAddition = this.rightRidgeExtension *(1-(distFromRidge/this.rightGableTieRakeLengthFromRidge))
        }

        else {
            // add the right extension
            rightEndAddition += this.rightEndExtensionLength;
        }
        return rightEndAddition;
    }

    getLeftEndAdditionAtBackPosition(distFromRidge) {
        let leftEndAddition = 0;
        if (this.leftTieIn) {
            if(distFromRidge > this.leftGableTieRakeLengthFromRidge)
                return 0;
            leftEndAddition = this.leftRidgeExtension *(1-(distFromRidge/this.leftGableTieRakeLengthFromRidge))
        }
        else {
            // add the left extension
            leftEndAddition += this.leftEndExtensionLength;
        }

    return leftEndAddition;
    }

    addVerticalBackboard() {

        let pH = this.pitchHeight;
        let yUp = 20;
        let yDown = 20;
        let height = pH*12;        

        let cupolaWidthOffset = 36; // 36 in cupola can be flush with the end
        let length = this.length + this.leftRidgeExtension + this.rightRidgeExtension - cupolaWidthOffset;

        let backboardGeo = new THREE.BoxGeometry(length, height, .5);
        backboardGeo.name = 'vertical backboard geo'
        let backboardMesh = new THREE.Mesh(backboardGeo, CORE.materials.transparent);
        backboardMesh.name=`vertical roof mesh for cupola attachments ${this.design.id}`;
        backboardMesh.visible=false;
    
        backboardMesh.userData = {
            type: this.design.type,
            id: this.design.id,
            selectable: false
        };
        
        this.group.add(backboardMesh);
    }

    gridSnapWorldPosition(world){
        // snaps a point in the proper plane
        let w = new Vector3().copy(world);
        this.group.worldToLocal(w);                
        let localSnapped = PickHelper.getGridSnappedPosition(w, true, false); // all walls are in the XY plane in local space
        localSnapped.y = this.pitchHeight*12/2;
        localSnapped.z = 0;
        this.group.localToWorld(localSnapped);
        return localSnapped;
    }

    getRelativePosition(worldPosition){
        worldPosition = new Vector3().copy(worldPosition);    
        let relWorldPos = this.group.worldToLocal(worldPosition);
        return relWorldPos;
    }

    //happens to be the same for front and back.
    positionChildAt(design, worldPosition){
        // get this worldPosition in terms of this parent component
        let wld = new Vector3().copy(worldPosition);
        let rel = this.getRelativePosition(wld)
        // then use a static method that accepts the design to constrain the position to exactly fixed header height
        let compType = ComponentHelper.getClassOfComponentType(design.type);
        return compType.updatePosition(rel, design);
    }

    initRebuildHierarchy(){        
        this.rebuildTypeRoot = new TreeNode(null, rebuildTypes.full);
        this.rebuildTypeRoot.addChildNode(rebuildTypes.dynamicOnly);
    }

    getChildWorldPosition(childCompId){
        //let worldPosition = this.group.localToWorld(new Vector3(0,0,0))
        //console.log(worldPosition);

        let childComp = this.getComponentById(childCompId);
        if(!childComp) throw 'Error: getChildWorldPosition()'
        
            

        let componentSceneWorldPos = new Vector3();
        this.group.getWorldPosition(componentSceneWorldPos)

        let componentSceneWorldQuat = new THREE.Quaternion();
        this.group.getWorldQuaternion(componentSceneWorldQuat);
        
        let desPos = childComp.design.pos;
        // desPos is principally x,y, but z can, technically, be zero or non-zerp
        
        let relPos = new Vector3().copy(desPos);
        relPos.applyQuaternion(componentSceneWorldQuat);
        relPos = new Vector3().addVectors(relPos,componentSceneWorldPos);


        //console.log('wb rel > world', desPos, relPos )
        return relPos;        
    }

    processRebuild(type){  
        switch(type){
            case rebuildTypes.full:
                this.rebuildFull();
                break;
            case rebuildTypes.dynamicOnly:
                this.rebuildDynamic();
                break;
        }
    }

    rebuildDynamic() {
        // remove components
        let chi = this.group;
        this.components.forEach((c) => {
            chi.remove(c.group);
            c.remove();
            c = null;
        });
        let chib4 = this.group.children;
        this.components = [];
        let chib5 = this.group.children;
        this.design.components.forEach((design) => {
            let c = ComponentHelper.createComponentWithDesign(design)
            this.components.push(c);
        });
        
        this.buildComponents();

    }

    buildComponent(c){
        // build a child component of this component
        let des = c.design;

        

        if(des.type === CORE.components.null)
            return;

        if (des.type === CORE.components.cupola) {
            des.pos.y = this.pitchHeight + des.size;
            
            let pitchAdjustment = 1/2 * des.size * this.pitchRatio;
            let options = {
                panelColor: this.panelColor,
                trimColor: this.trimColor,
                roofColor: this.design.color,
                pitchAdjustment
            }

            let pos = new THREE.Vector2(des.pos.x,des.pos.y, des.pos.z)
            c.initParent(this);
            c.updatePosition(pos, c.design);
            c.build(options);
            this.group.add(c.group);
        
        }
    }
    
}


    /* Gabled tie-in thoughts

        child eave can be at or below parent eave
            - this impacts the shape of the hole in the roof near the parent eave strut

        child ridge can be at or below parent ridge
            - this impacts the shape of the hole in the roof near the child ridge intersection

        It's possible a child might have a "parent" on both ends

        Output:
         - I need a very dynamic hole in the parent roof
         - I need a very dynamic wall panel that supports notches for eaves
         - I need 

         drawing the roof transparency is easy, you're just left with invisible roof sections that might interfere with clicking when the roof layer is disabled

        eave difference determines whether the roof has 4 or 5 points, just like EW on a gable vs slope roof.
        how will this impact our goals for eave extensions/overhangs
        There's a plane for the roof. extensions affect the x, overhangs affect the y and z, contraints and eave differences impact where those points lay

        https://discourse.threejs.org/t/how-to-ignore-the-transparent-part-of-sprite-by-ray-pickup/7773/3

        
        

    */