import * as THREE from 'three';
import { Vector3 } from 'three';
import {CORE} from '../_spec.js';
import ConstraintHelper from './ConstraintHelper.js';
import SegmentHelper from './SegmentHelper.js';
import Util from '../utility.js';
import DesignHelper from './DesignHelper.js';

export default class StructureHelper{

    static getDimensionsInches(frameDesign){
        if(!frameDesign)
            return {length: 0, width: 0, height:0}
        if(!frameDesign.length) frameDesign.length = 0;
        if(!frameDesign.width) frameDesign.width = 0;
        if(!frameDesign.height) frameDesign.height = 0;
        return {
            length: frameDesign.length*12,
            width: frameDesign.width*12,
            height: frameDesign.height*12
        }
    }



    static getStructureName(side, newStructureType, i){

        const sideName =  CORE.sides.getName(side);
        const num = i > 1 ? `(${i})` : ``;
        const structType = newStructureType === CORE.components.porch? "Porch": "Lean-To";
        // get basic structure name
        return `${sideName} ${structType} ${num}`
    }

    static getUniqueStructureName(designManager, side, newStructureType){
        let i = 1;
        let name = this.getStructureName( side, newStructureType, i)
        while(!this.StructureNameIsUnique(designManager, name)){
            i++;
            name = this.getStructureName( side, newStructureType, i)
            if(i>20)
                break;
        }
        return name;      
    }

    static StructureNameIsUnique(designManager, name, excludeId) {        
        let nameIsUnique = true;
        name = name.trim();
        let renameableStructures = designManager.design.getAllStructuresOfAllSystems();
        if (!renameableStructures)
            return true;
        
            let unique = true;
        renameableStructures.forEach((s) => {
            if(!unique)
                return;
            const existingName =s.name.trim()
            
            if(
                (Util.isUndefined(excludeId) ||
                (Util.isDefined(excludeId) && s.id !== excludeId)) &&
                existingName === name
                )
                unique = false;
            })
        return unique;
    }


    static getWorldCoordinateCornersOfBuilding(compBuilding){
            
        // if center in front of front wall
        let corners = compBuilding.getCorners();
        
        let FL = new Vector3();
        let FR = new Vector3();
        let BL = new Vector3();
        let BR = new Vector3();
            
        corners[0].point.getWorldPosition(FL);
        corners[1].point.getWorldPosition(FR);
        corners[2].point.getWorldPosition(BL);
        corners[3].point.getWorldPosition(BR);

        return {FL,FR,BL,BR};
    }

    
    static getInterpolatedPointBetweenObjectsInWorldSpace(obj1, obj2, lengthFt){
        let wp1, wp2;
        wp1 = new THREE.Vector3();
        obj1.getWorldPosition(wp1);
        wp2 = new THREE.Vector3();
        obj2.getWorldPosition(wp2);
        let point = wp1.clone()
        let distance = wp1.distanceTo(wp2)
        let lengthInches = lengthFt*12;
        let ratio = lengthInches/distance        
        point = point.lerp(wp2, ratio);
        return point;
    }

    static getInterpolatedPoint(point1, point2, lengthFt){
        let wp1, wp2;
        wp1 = point1;
        wp2 = point2;
        let point = wp1.clone()
        let distance = wp1.distanceTo(wp2)
        let lengthInches = lengthFt*12;
        let ratio = lengthInches/distance
        point = point.lerp(wp2, ratio);
        return point;
    }

    static getLeftAdjacent(side){
        switch(side){
            case CORE.sides.frontSide:
                return CORE.sides.leftEnd;
            case CORE.sides.leftEnd:
                return CORE.sides.backSide;
            case CORE.sides.backSide:
                return CORE.sides.rightEnd;
            case CORE.sides.rightEnd:
                return CORE.sides.frontSide;
        }        
    }

    static getRightAdjacent(side){
        switch(side){
            case CORE.sides.frontSide:
                return CORE.sides.rightEnd;
            case CORE.sides.leftEnd:
                return CORE.sides.frontSide;
            case CORE.sides.backSide:
                return CORE.sides.leftEnd;
            case CORE.sides.rightEnd:
                return CORE.sides.backSide;
        }        
    }    

   static getAdjacentSideToCornerAndSide(corner, side){
    switch(side){
        case CORE.sides.frontSide:
            if(corner === CORE.corners.FL)
                return CORE.sides.leftEnd;
                else return CORE.sides.rightEnd;

        case CORE.sides.backSide:
            if(corner === CORE.corners.BL)
                return CORE.sides.leftEnd;
                else return CORE.sides.rightEnd;

        case CORE.sides.leftEnd:
            if(corner === CORE.corners.FL)
                return CORE.sides.frontSide;
                else return CORE.sides.backSide;

        case CORE.sides.rightEnd:
            if(corner === CORE.corners.FR)
                return CORE.sides.frontSide;
                else return CORE.sides.backSide;
    }
}

    static getCornerTrimRotation(orientation, corner){
        switch(orientation){
            case CORE.cornerTrimOrientations.outside:
                switch(corner){
                    case CORE.corners.FL: return 0;
                    case CORE.corners.BL: return 3 * Math.PI / 2;
                    case CORE.corners.BR: return Math.PI;
                    case CORE.corners.FR: return Math.PI / 2;
                }
            case CORE.cornerTrimOrientations.insideLeft:
                switch(corner){
                    case CORE.corners.FL: return 3 * Math.PI / 2;
                    case CORE.corners.BL: return Math.PI;
                    case CORE.corners.BR: return Math.PI / 2;
                    case CORE.corners.FR: return 0;
                }
            case CORE.cornerTrimOrientations.insideRight: 
                switch(corner){
                    case CORE.corners.FL: return Math.PI / 2;
                    case CORE.corners.BL: return 0;
                    case CORE.corners.BR: return 3 * Math.PI / 2;
                    case CORE.corners.FR: return Math.PI;
                }
            case CORE.cornerTrimOrientations.untrimmed:
                return null;
        }
    }
    static getSideFromCornerPair(c1,c2){        
        switch(c1){
            case CORE.corners.FR:
                switch(c2){
                    case CORE.corners.FR: throw 'no side could be determined from identical corner'
                    case CORE.corners.FL: return CORE.sides.frontSide;                        
                    case CORE.corners.BR: return CORE.sides.rightEnd;                        
                    case CORE.corners.BL:throw 'no side could be determined from diagonal corner'                        
                }
            case CORE.corners.FL:                
                switch(c2){
                    case CORE.corners.FR: return CORE.sides.frontSide;
                    case CORE.corners.FL: throw 'no side could be determined from identical corner'                        
                    case CORE.corners.BR:throw 'no side could be determined from diagonal corner'                        
                    case CORE.corners.BL: return CORE.sides.leftEnd;
                }
            case CORE.corners.BR:                
                switch(c2){
                    case CORE.corners.FR: return CORE.sides.rightEnd;
                    case CORE.corners.FL: throw 'no side could be determined from diagonal corner'                        
                    case CORE.corners.BR:throw 'no side could be determined from identical corner'
                    case CORE.corners.BL: return CORE.sides.backSide;
                }
            case CORE.corners.BL:                
                switch(c2){
                    case CORE.corners.FR: throw 'no side could be determined from diagonal corner'
                    case CORE.corners.FL: return CORE.sides.leftEnd;
                    case CORE.corners.BR: return CORE.sides.backSide;
                    case CORE.corners.BL: throw 'no side could be determined from identical corner'
                }
        }
    }

    static getLeftAndRightCornerPairFromSide(side){
        switch(side){
            case CORE.sides.frontSide:
                return [CORE.corners.FL, CORE.corners.FR];
    
            case CORE.sides.backSide:
                return [CORE.corners.BR, CORE.corners.BL];
    
            case CORE.sides.leftEnd:
                return [CORE.corners.BL, CORE.corners.FL];
    
            case CORE.sides.rightEnd:
                return [CORE.corners.FR, CORE.corners.BR];
        }
    }
    
    static getLengthOfSide(design, side){
        switch(side){
            case CORE.sides.frontSide:
            case CORE.sides.backSide:
                return design.getFrame().length;
            case CORE.sides.leftEnd:
            case CORE.sides.rightEnd:
                return design.getFrame().width;
            default:
                console.error(`invalid side ${side} requested`)
                break;
        }
    }    

    static getWallDesFromSide(design, side){
        switch(side){
            case CORE.sides.frontSide:
                return design.getFrontWall();
            case CORE.sides.backSide:
                return design.getBackWall();
            case CORE.sides.leftEnd:
                return design.getLeftWall();
            case CORE.sides.rightEnd:
                return design.getRightWall();
        }
    }    
    
    static getSkirtedInsetSideWallBayCount(startFromLeftWall, desStructure, sideWallBays)
    {
        let partitions = desStructure.getTransversePartitions();
        if(startFromLeftWall){
            let existingLewPartition = partitions.filter(p => p.sheetingLeft == true).sort((a, b) => a.frameLine < b.FrameLine).at(0);
            if(existingLewPartition)
                return existingLewPartition.frameLine
        }
        else{
            let existingRewPartition = partitions.filter(p => p.sheetingRight == true).sort((a, b) => a.frameLine < b.FrameLine).at(0);
            if(existingRewPartition)
                return (desStructure.frameLines.length-1) - existingRewPartition.frameLine
        }

       

        let sideWallHeight = -1;
        let insetSideWallBayCount = 0;
        let copySideWallBays = sideWallBays.slice(0);
        if(!startFromLeftWall)
            copySideWallBays = copySideWallBays.reverse();

        for(let b in copySideWallBays){
            let bay = copySideWallBays[b];
            if(DesignHelper.isTypeBay(bay)){
                let bayHeight = bay.openWall ? 0 : bay.height;
                if(bayHeight == 0)
                    continue;

                if (sideWallHeight == -1)
                    sideWallHeight = bayHeight;
                else if (bayHeight > sideWallHeight)
                {
                    return insetSideWallBayCount
                }
                insetSideWallBayCount++;
            }
        }

        return 0;
    }
}