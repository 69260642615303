import * as THREE from 'three';
import _3dBase from './Base.js'
import {CORE} from '../_spec.js'
import { Vector3 } from 'three';
import CollisionHelper from '../helpers/CollisionHelper.js';
import MemHelper from '../helpers/memHelper.js';
import materialHelper from '../helpers/materialHelper.js';


export default class Footer extends _3dBase{
    constructor(lod, a,b, addCollisionZone, color){
		
		let pos = new THREE.Vector3().copy(a);
		pos.lerp(b, .5); 
		super(undefined, pos, undefined, lod)
		this.group.name ='footer (base angle)';

		this.addCollisionZone = addCollisionZone;
		this.a = a;
		this.b = b;
		this.dim = {
            width: 4,
			height: 2,
			length: a.distanceTo(b)
        }
		this.color = color ? color : CORE.frame.redOxideColor;
		this.build();
    }

	build_LD(){
		let margin = 0;
		let material = materialHelper.getFrameMaterial_LD(this.color);

		//var geoWidth = new THREE.BoxGeometry(this.dim.length, CORE.steel.thickness_in, this.dim.width);
		let geoBottom = new THREE.PlaneBufferGeometry(this.dim.length, this.dim.width, 1, 1)
		geoBottom.name ='geoWidth'
		var meshWidth = new THREE.Mesh(geoBottom, material );
		meshWidth.name = 'footer horizontal'
		meshWidth.position.z=-this.dim.width/2 - margin;
		
		meshWidth.position.y=.01;
		meshWidth.rotation.x = Math.PI/2;

		//var sizeHeight = new THREE.Vector3( this.dim.length, this.dim.height, CORE.steel.thickness_in);
		//var geoHeight = new THREE.BoxGeometry(sizeHeight.x,sizeHeight.y,sizeHeight.z);
		let geoSide = new THREE.PlaneBufferGeometry(this.dim.length, this.dim.height, 1, 1)
		geoSide.name ='geoHeight'
		
        var meshHeight = new THREE.Mesh( geoSide, material );
		meshHeight.name = 'footer vertical'
		meshHeight.position.z=-margin;
		meshHeight.position.y=this.dim.height/2;
		
		this.group.add(
			meshWidth,
			meshHeight
		);
		meshWidth.castShadow=false;
		meshHeight.castShadow=false;
		meshWidth.layers.set(CORE.layers.frame)
		meshHeight.layers.set(CORE.layers.frame)
		meshHeight.layers.enable(CORE.layers.quote)
		meshWidth.layers.enable(CORE.layers.quote)
	}

	build_HD(texture){
		let margin = .5;
		let material = materialHelper.getFrameMaterial_HD(this.color, texture);

		var geoWidth = new THREE.BoxGeometry(this.dim.length, CORE.steel.thickness_in, this.dim.width);
		geoWidth.name ='geoWidth'
		var meshWidth = new THREE.Mesh( geoWidth, material );
		meshWidth.name = 'footer horizontal'
		meshWidth.position.z=-this.dim.width/2 - margin;
		meshWidth.position.y=CORE.steel.thickness_in/2;

		var sizeHeight = new THREE.Vector3( this.dim.length, this.dim.height, CORE.steel.thickness_in);
		var geoHeight = new THREE.BoxGeometry(sizeHeight.x,sizeHeight.y,sizeHeight.z);
		geoHeight.name ='geoHeight'
        var meshHeight = new THREE.Mesh( geoHeight, material );
		meshHeight.name = 'footer vertical'
		meshHeight.position.z=-margin;
		meshHeight.position.y=sizeHeight.y/2;
		
		this.group.add(
			meshWidth,
			meshHeight
		);
		meshWidth.castShadow=false;
		meshHeight.castShadow=false;
		
		meshWidth.layers.set(CORE.layers.frame)
		meshHeight.layers.set(CORE.layers.frame)
		meshHeight.layers.enable(CORE.layers.quote)
		meshWidth.layers.enable(CORE.layers.quote)
	}


    build(){
		
		// draw a beam		
		
		 // Create a custom texture for this object
         let texture = CORE.textures.beam.clone();
         texture.wrapT = texture.wrapS = THREE.MirroredRepeatWrapping;
         texture.encoding = THREE.sRGBEncoding;        
         texture.repeat.set(this.dim.length,this.dim.width); 

		 
		 if(this.isLowDetail())
		 this.build_LD()
	 else 
		 this.build_HD(this.texture)

		 
		
		let rot = 0;
		
		if(this.a.z !== this.b.z)
			rot=-Math.PI/2;

		this.group.rotation.y = rot;
        
		
		

	}

}
