export default class TreeNode{

    // IDs should be unique in the entire tree of nodes to facilitate findById
        
    constructor(parent, id, value){        
        this.parent = parent;
        this.id = id;
        this.value = value;
        this.nodes = {};
    }


    
    addChildNode(id, value){
        if(this.findDescendentNodeById(id) !== null)
            throw `node Id ${id} is already in the tree`;
        this.nodes[id] = new TreeNode(this, id, value);
    }

    removeChildNode(id){
        if(!this.hasChild(id))
            throw `child node with id ${id} does not exist under node with id ${this.id}`;
        delete this.nodes[id];
    }

    hasChildNode(id){
        return this.nodes.hasOwnProperty(id);
    }

    iterateDescendentNodes(callback, recurse){

        for (const [id, value] of Object.entries(this.nodes)) {
            const node = this.nodes[id];
            callback(this.cloneNode(node));  
            if(recurse===true)
                node.iterateDescendentNodes(callback, recurse);
        }
    }

    findNodeAtOrUnderById(id){

        if(this.id === id)
            return this.cloneNode(this);

        return this.findDescendentNodeById(id)
    }

    findDescendentNodeById(id){        
        let node = null;

        let cb= (subject) => {
            if(subject.id === id){
                node = subject;
            }
        };

        this.iterateDescendentNodes(cb, true);

        return this.cloneNode(node)
    }

    cloneNode(node){
        //return node;
        if(node == null)
            return null;
        let copy = new TreeNode(
            node.parent,
            node.id,
            node.value);
            return copy;
    }

    
    iterateAncestorNodes(callback, recurse){
        if(!this.parent)
            return;
        
        callback(this.parent, recurse);  
        if(recurse===true)
            this.parent.iterateAncestorNodes(callback, recurse);
    }

    findAncestorNodeById(id){        
        let node = null;

        let cb= (subject) => {
            if(subject.id === id){
                node = subject;
            }
        };

        this.iterateAncestorNodes(cb, true);        
        return this.cloneNode(node);
    }

    hasDescendent(id)
    {
        return this.findDescendentNodeById(id) != null;
    }

    hasAncestor(id)
    {
        return this.findAncestorNodeById(id) != null;
    }
}


