import api from '@/api';

const state = {
};

const mutations = {
};

const actions = {
  async fetchProjects({ commit }, businessId) {
    try {
      return await api.getProjects(businessId)
    } catch (error) {
      console.error('Error fetching project:', error);
    }
  },
  async fetchProject({ commit }, projectIdentifier) {
    try {
      return await api.getProject(projectIdentifier);
    } catch (error) {
      console.error('Error fetching project:', error);
    }
  },
  async createBuildingQuote({commit}, quote) {
    try {
    
      return await api.post('/project/saveProject', quote);
    } catch (error) {
      console.error('Error creating new building quote:', error);
      return error;
    }
  },
  async updateBuildingQuote({commit}, project) {
    try {
      return await api.post('/project/saveProject',project);
    } catch (error) {
      console.error('Error fetching project:', error);
    }
  },
  async copyProject({commit}, payload){
    try {
      return await api.post('/project/copyProject',payload);
    } catch (error) {
      console.error('Error fetching project:', error);
    }
  },
  async requestCsvBomExport({commit}, payload){
    try{
      return await api.post('/project/requestCsvBomExport', payload)
    } catch(error){
      console.error('Error requesting Csv Bom');
    }
  },
  async fetchMBS({commit}, payload){
    try{
      return await api.post('/project/requestMBS', payload)
    } catch(error){
      console.error('Error requesting MBS');
    }
  },
  async generateBuildingQuotePDFV3({commit}, payload){
    try{
      return await api.post(`/project/generateBuildingQuotePDFV3`, payload)
    } catch(error){
      console.error('Error requesting Bidsheet');
    }
  },
  async fetchProjectDetails({commit}, ref){
    try {
      return await api.get(`/project/getProjectDetails/${ref}`);
    } catch (error) {
      console.error('Error requesting project details:', error);
      return error;
    }
  },
  async fetchStructureDetails({commit}, ref){
    try{
      return await api.get(`/project/getStructureDetails/${ref}`)
    } catch(error){
      console.error('Error requesting structure details');
    }
  },
  async fetchFileNamesInProjectDocFolder({commit}, ref){
    try{
      return await api.get(`/project/getFileNamesInDocFolder/${ref}`)
    }
    catch(error){
      console.error(`Error fetching file names from project Doc folder`);
    }
  },
  async requestFileFromDocFolder({commit}, payload){
    try{
      return await api.post('/project/requestFileFromDocFolder', payload)
    } catch(error){
      console.error('Error requesting additional document');
    }
  },
  async updateQuoteAdjustments({commit}, payload){
    try {
      return await api.post(`/project/updateQuoteAdjustments`, payload);
    } catch (error) {
      console.error('Error updating project quote adjustments:', error);
      return error;
    }
  },
  async deleteProject({commit}, project){
    try {
      return await api.post(`/project/deleteProject`, project);
    } catch (error) {
      console.error('Error deleting project:', error);
      return error;
    }
  },
  async uploadDocuments({commit}, payload){
    try {
        const response = await api.uploadDocuments(payload, false);
        return response;
    } catch (error) {
        console.error('Error uploading documents:', error);
        throw error;
    }
  },
  async deleteAdditionalDocument({commit}, payload){
    try {
      const response = await api.post(`/project/deleteAdditionalDocument?reference=${payload.ref}`, payload.fileName);
      return response;
    } catch (error) {
        console.error('Error deleting document:', error);
        throw error;
    }
  },
  async requestAHardQuote({commit}, ref){
    try {
      const response = await api.requestHardQuote(ref);
      return response;
    } catch (error) {
        console.error('Error uploading documents:', error);
        throw error;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};