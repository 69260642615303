<template>
    <div>
      <div>
        <img :src="$auth.user.picture" />
        <h2>{{ $auth.user.name }}</h2>
        <p>{{ $auth.user.email }}</p>
      </div>
        {{ user }}
    </div>
  </template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import  {mapState} from 'vuex'

export default {
  name: 'HomeView',
  data(){
    return {
      json: {}
    }
  },
  computed:{
    ...mapState('contextModule',[
      'user'
    ]
    ),
    async getToken(){
      let token = await this.$auth.getIdTokenClaims();
      console.log(token)
      console.log(this.$auth)
      return token.userRoles[0];
    }
  },
  components: {
    HelloWorld
  },
  async mounted(){
  },
  methods: {
  }
}
</script>
