import { Vector3, Quaternion, Matrix4, Matrix3 } from 'three';
/*
    - columns built from the middle, outward
    - Eave strut runs from rafter to rafter (J1)
    - width > 60' requires tapered columns/rafters (we just need to approximate the growth of these)
    - 

    - WallEndLeft
    - WallEndRight
    - WallSideFront
    - WallSideBack

    - Uses ColumnStraight at widths < 60'
    - Uses ColumnTapered at widths >= 60'
*/
import BlueprintHelper from '../helpers/blueprintHelper.js'
import BpFrameLine from '../blueprints/bpFrameLineMain.js'
import FrameLine from './frameLine.js';
import {CORE} from '../_spec.js';
import Base from './Base'
import Util from '../utility.js'
import * as THREE from 'three';
import MemHelper from '../helpers/memHelper.js';

export default class FrameBase extends Base{
    constructor(
        des, 
        structureConfig,
        framelinePositions,
        width,
        frameType,
        lowEaveHeight,
        porchFrameLineIndexes, 
        wrapTubePorchColumns,
        roofFrameType,        
        pitch,
        roofType,
        beamColor,
        purlinColor
        )
        {
        super(des);
        this.group.name = "CompGroup FrameBase";        // component group
        this.width = width;
        this.frameType = frameType;
        this.lowEaveHeight = lowEaveHeight;
        this.type = CORE.components.frame;
        
        //columnPositions
        let framePoints = framelinePositions;

        /*
        needs to know
            - frame points
            - left wall frameLine
            - right wall frameLine

         needs to do
            -add appropriate framelines
        */
       
       this.frameLines = [];

       let onEndWallFrameLine=false;
       let iBetweenEndWallFrameLines= false; // inclusive of left and right end wall
        let halfWidth = this.width/2;
        framePoints.forEach((xFrameLine, i)=>{

            let eBetweenEndWallFrameLines;
            let leftEndFrameLines;
            let rightEndFrameLines;
            let buildIntermediateColumns;
            let frameLineType = CORE.frame.lineTypes.standard;
            /*
            if(des.isPorch){
                // a porch structure (parent of this frame) won't have left and right WallFrameLine set to anything, 
                // so assume no insets in order for the rafter openEnd logic to function as expected.
                this.design.leftWallFrameLine = 0;
                this.design.rightWallFrameLine = framePoints.length-1;
            }*/

            let leftEndWallFrameline = i === this.design.leftWallFrameLine;
            let rightEndWallFrameline = i === this.design.rightWallFrameLine;
            onEndWallFrameLine = i === this.design.leftWallFrameLine || i === this.design.rightWallFrameLine;
            iBetweenEndWallFrameLines = (i >= this.design.leftWallFrameLine && i <= this.design.rightWallFrameLine); // inclusive
            eBetweenEndWallFrameLines = (i > this.design.leftWallFrameLine && i < this.design.rightWallFrameLine); // exclusive
            leftEndFrameLines = i <= this.design.leftWallFrameLine; // inclusive of LEW whether inset or not.
            rightEndFrameLines = i >= this.design.rightWallFrameLine; // inclusive of REW whether inset or not.
            
            // only build intermediate columns if
            buildIntermediateColumns = !onEndWallFrameLine && // this is not the frameline where a wall will build intermediate columns, and
            ((i === 0 && !this.design.leftEnd.skirt) || // this is the left end and it's not skirted, or
            (i ===  framePoints.length-1 && !this.design.rightEnd.skirt)); //  this is the right end and it's not skirted
            
            if((leftEndFrameLines && this.design.leftOpenMainFrame) || 
                eBetweenEndWallFrameLines ||
                (rightEndFrameLines && this.design.rightOpenMainFrame)){
                    frameLineType = CORE.frame.lineTypes.main
                }

            if(porchFrameLineIndexes.includes(i) && frameLineType === CORE.frame.lineTypes.standard) // a front or back porch needs this frameline to be a mainframe)
                frameLineType=CORE.frame.lineTypes.postAndBeam; // upgrade from standard to postAndBeam                
            
            
            
                                    
            
            // Business Logic:
            // on or left of left wall 
                // LE main frame => main
                // not LE main-frame  => standard
            // in between walls => main
            // on or right of right wall 
                // LE main frame => main
                // not LE main-frame  => standard


            let rafterCOpenEnd = CORE.sides.rightEnd;

            if(rightEndFrameLines)
                rafterCOpenEnd = CORE.sides.leftEnd;
            
            let bpFrameLine;
            let supportsAnEndPorch=false;
// BEGIN IMPORTED FROM BLUEPRINT.js

            let pitchRatio = pitch/12;
            let halfPitchRatio = pitchRatio/2;
            let pitchAngle = Math.atan(pitchRatio) // radians
            let pitchAngleDeg = pitchAngle * 180 / Math.PI;
            let frameWidth = Util.Convert.ftToIn(des.width)
            let frameHeight = Util.Convert.ftToIn(des.height)
            let purlinDimY = CORE.roof.purlin.dim.height;// + (2*CORE.roof.purlin.dim.thickness);
            let pitchDimY = BlueprintHelper.pitchHeight(frameWidth,pitchRatio, roofType);

            let pitchedPurlinDimY = purlinDimY / Math.cos(pitchAngle); // the vertical height of a purlin pitched to match the roof pitch
// END IMPORTED FROM BLUEPRINT.js

            
            switch(frameLineType){
                case CORE.frame.lineTypes.main:
                    //Main Frames
                    let mainFrameDims = BlueprintHelper.getMainFrameMaterialSize(frameWidth, des.frameType)
                    let frameLineMain = BpFrameLine.generate(CORE.frame.lineTypes.main, mainFrameDims, frameHeight, des.frameType, pitchAngle, roofType, pitchDimY, pitchedPurlinDimY, frameWidth, pitchRatio, roofFrameType);
                    bpFrameLine = frameLineMain 
                    if((i===0 && des.leftEnd.hasPorch) ||
                        (i==framePoints.length-1 && des.rightEnd.hasPorch))
                        supportsAnEndPorch=true; // a mainFrame supporting a porch needs columns
                    break;
                case CORE.frame.lineTypes.postAndBeam:
                    //Post&Beam
                    let pAndBFrameDims = BlueprintHelper.getPostAndBeamFrameMaterialSize()
                    let frameLinePostAndBeam = BpFrameLine.generate(CORE.frame.lineTypes.postAndBeam, pAndBFrameDims, frameHeight, des.frameType, pitchAngle, roofType, pitchDimY, pitchedPurlinDimY, frameWidth, pitchRatio, roofFrameType);
                    bpFrameLine = frameLinePostAndBeam;
                    break;
                case CORE.frame.lineTypes.standard:                    
                    // Cold-Formed (Standard)
                    let standardFrameDims = BlueprintHelper.getStandardFrameMaterialSize(frameWidth, des.frameType)
                    let frameLineStandard = BpFrameLine.generate(CORE.frame.lineTypes.standard, standardFrameDims, frameHeight, des.frameType, pitchAngle, roofType, pitchDimY, pitchedPurlinDimY, frameWidth, pitchRatio, roofFrameType);
                    bpFrameLine = frameLineStandard; 
                    break;
            }



            let frontTopPosY;
            if(roofType == CORE.roof.types.gable)
                frontTopPosY = bpFrameLine.yPosBackColumnAndRafter;
            else
                frontTopPosY = bpFrameLine.yPosFrontColumnAndRafter;


            let referenceX = -des.length*12/2; // front porch
            //         
            let flip = 1;   
            if(this.design.isPorch){
                // back porch frame is built facing forward, and then rotated into position
                    // TODO: centralize and/or correct this static 4
                referenceX = (-des.length*12/2) - framelinePositions[0]+4;
            }
            
            
            let backColumnBottom = new Vector3(referenceX+(xFrameLine*flip),0,-halfWidth); // bottom of column nearer to origin

            

            let backColumnTop = new Vector3(referenceX+(xFrameLine*flip),bpFrameLine.yPosBackColumnAndRafter,-halfWidth); // top of column nearer to origin
            let frontColumnTop = new Vector3(referenceX+(xFrameLine*flip), frontTopPosY,halfWidth); // top of column further from origin
            let frontColumnBottom = new Vector3(referenceX+(xFrameLine*flip),0,halfWidth); // bottom of column further from origin

            let rafterCollisionZones = onEndWallFrameLine && !des.isPorch;
            let columnCollisionZones = iBetweenEndWallFrameLines && !des.isPorch;
            
            let baySpacing = null;
            if(leftEndFrameLines){
                baySpacing = des.leftEnd.baySpacing;
            }
            if(rightEndFrameLines){
                baySpacing = des.rightEnd.baySpacing;
            }
            let a = backColumnBottom.x
            backColumnBottom.x=0;
            backColumnTop.x=0;
            frontColumnTop.x=0;
            frontColumnBottom.x=0;


            let intermediateColumnsOpenTo;
            if(i === 0)
            {
                intermediateColumnsOpenTo = CORE.sides.rightEnd; // intermediate columns on left-most frame-line open to the right
            }
            else{
                intermediateColumnsOpenTo = CORE.sides.leftEnd; // intermediate columns on right-most frame-line open to the left
            }


            let frameLine=new FrameLine(
                structureConfig,
                bpFrameLine,
                intermediateColumnsOpenTo,                
                frameLineType, 
                des.isPorch, 
                this.frameType,
                this.width,
                this.lowEaveHeight, 
                buildIntermediateColumns,
                rafterCollisionZones,
                columnCollisionZones,
                rafterCOpenEnd,
                baySpacing,
                supportsAnEndPorch,
                leftEndWallFrameline,
                rightEndWallFrameline,
                wrapTubePorchColumns,
                roofFrameType,                
                pitchRatio,
                roofType,
                beamColor,
                purlinColor)
            frameLine.build()
            frameLine.group.position.x = BlueprintHelper.getFramelinePositionXByIndex(des.length, framelinePositions, i, this.design.isPorch);
            this.frameLines.push(frameLine);
            //frameLine.group.position.z -= frameLine.length/2;
            this.group.add(frameLine.group);
            
        });

        /*
        frameLine 
            - needs to know
                xStartPositionBottom
                xStartPositionTop
                xEndPositionTop
                xEndPositionBottom
                mainframe or not
                roofType

                
            - needs to do
                add appropriate column types
                add appropriate rafter types

            
        */

        this.build();
    }

    static migrateSides(design){
        FrameBase.migrateStatic(design);        
    }

    static migrateSideBaySpacing(design){
        // if the old bay spacing properties are present, 
        if(design.sideBaySpacing){

            let leftBaySize = design.leftMostBayOverride ? design.leftMostBaySpacing:0;
            let specialLeftSize = design.leftMostBayOverride ? leftBaySize:null; // blank it out if not used            
            // calculate what the special right must be to prevent frameLines from moving
            let fullSizeBayCount = (design.length - leftBaySize) / design.sideBaySpacing;
            
            let rightBaySize = design.length - (fullSizeBayCount * design.sideBaySpacing) - leftBaySize; // calculate the right-end remnant
            let rightSpecialSize = rightBaySize===0 ? null : rightBaySize;

            // in the new method, max size is only the max that isn't exceeded.
            // calculate the maxSize that yields the same sideBaySpacing
            
            let maxSize = (design.length - leftBaySize - rightBaySize) / fullSizeBayCount;
            
            // convert them to evenly distributed bays with LEW/REW special and Max bay
            
            design.sides = {
                baySpacing: {
                    specialLeftSize,
                    rightSpecialSize,
                    maxSize
                }
            }

            // delete the old properties
            //delete design.sideBaySpacing;
            //delete design.leftMostBaySpacing;
            //delete design.leftMostBayOverride;            
        }
    }

    static migrateStatic(design) {
        FrameBase.migrateSideBaySpacing(design);
    }

    migrate(design){
        
        //let frameDesign = design;//.components.filter((c)=>{return c.type === CORE.components.frame})[0];
        FrameBase.migrateStatic(design);
        
    }

    getFrameLineLeftEnd(){
        return this.frameLines[0];
    }
    getFrameLineRightEnd(){
        return this.frameLines[this.frameLines.length-1];
    }
    
    static canEdit(){
        return false;
    }    

    defaultDesign(){
        return {
            id:-1,
            type: CORE.components.frame
        }
    }

    getDescription(){
        return "frame"
    }

    build(){   
    }

    remove(){
        //this.frameLines.forEach((fl)=>{fl.remove();});
        MemHelper.removeAllChildren(this.group);
    }
}