<template>
  <div class="employee-manager">
    <h1>Employee Manager</h1>

    <!-- Onboard New Employee Button -->
    <button @click="showOnboardModal = true" class="onboard-btn">Onboard New Employee</button>

    <!-- Employee List -->
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Business</th>
          <th v-for="role in roles" :key="role.id">{{ role.name }}</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="employee in employees" :key="employee.id">
          <td>{{ employee.name }}</td>
          <td>{{ employee.email }}</td>
          <td>{{ employee.business }}</td>
          <td v-for="role in roles" :key="role.id">
            <input 
              type="checkbox" 
              :checked="employee.roles.includes(role.id)"
              @change="toggleRole(employee.id, role.id, $event.target.checked)"
            />
          </td>
          <td>
            <button @click="removeUser(employee)" :disabled="isCurrentUser(employee)">Remove</button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Onboard Modal -->
    <div v-if="showOnboardModal" class="modal">
      <div class="modal-content">
        <h2>Onboard New Employee</h2>
        <input v-model="newEmployee.name" type="text" placeholder="Enter name" />
        <input v-model="newEmployee.email" type="email" placeholder="Enter email" />
        <input v-model="newEmployee.business" type="text" placeholder="Enter business" />
        <div v-for="role in roles" :key="role.id" class="role-checkbox">
          <input :id="'role-' + role.id" type="checkbox" v-model="newEmployee.roles" :value="role.id" />
          <label :for="'role-' + role.id">{{ role.name }} - {{ role.description }}</label>
        </div>
        <button @click="onboardEmployee" :disabled="!isValidEmployee">Onboard</button>
        <button @click="showOnboardModal = false" class="close-btn">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import ContextLogic from '@/lib/contextLogic';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'EmployeeManagerView',
  data() {
    return {
      showOnboardModal: false,
      newEmployee: {
        name: '',
        email: '',
        business: '',
        roles: []
      },
      employees:[]
    };
  },
  computed: {
    ...mapState('contextModule', ['currentContext', 'mode']),
    isValidEmployee() {
      return this.newEmployee.name && this.newEmployee.email && this.newEmployee.business;
    }
  },
  methods: {
    ...mapActions('employeeModule', ['fetchEmployees']),
    async onboardEmployee() {
      try {
        await this.inviteEmployee(this.newEmployee);
        this.showOnboardModal = false;
        this.resetNewEmployee();
        // Show success message
      } catch (error) {
        // Show error message
      }
    },
    resetNewEmployee() {
      this.newEmployee = {
        name: '',
        email: '',
        business: '',
        roles: []
      };
    },
    async toggleRole(employeeId, roleId, hasRole) {
      try {
        await this.updateEmployeeRole({ employeeId, roleId, hasRole });
        // Show success message
      } catch (error) {
        // Show error message and revert checkbox
      }
    },
    async removeUser(employee) {
      if (confirm(`Are you sure you want to remove ${employee.name}?`)) {
        try {
          await this.removeEmployee(employee.id);
          // Show success message
        } catch (error) {
          // Show error message
        }
      }
    },
    isCurrentUser(employee) {
      return employee.id === this.currentUser.id;
    }
  },
  async created() {
    await ContextLogic.initContext(this);

    this.employees = await this.fetchEmployees();
  }
};
</script>

<style scoped>
.employee-manager {
  margin: 20px;
}

.onboard-btn {
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

button {
  margin-top: 10px;
  padding: 5px 10px;
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
}

.close-btn {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-btn:hover,
.close-btn:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

input[type="text"],
input[type="email"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.role-checkbox {
  margin: 10px 0;
}
</style>