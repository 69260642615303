import * as THREE from 'three';
import _3dBase from './Base.js'
import MemHelper from '../helpers/memHelper.js';
import { CORE } from '../_spec.js';

export default class RafterBase extends _3dBase{
	constructor(parentGroup, pos, rot, lod, ){
		super(parentGroup, pos, rot, lod)

		this.texFrame = CORE.textures.beam.clone();
	}
}