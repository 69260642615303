<template>
    <div class="inset-tool-container">
        <div style="margin:auto;text-align: center;font-weight: bold;">{{tool.name}} Tool</div>
        <TargetStructureName />
        <table>        
        <tr>
            <td><label>End:</label></td><td><input id="skirtEndLeft" type="radio" name="skirtend" value="leftEnd" v-model="end"  /><label for="skirtEndLeft" style="margin-left: 3px">Left</label><input id="skirtEndRight"  type="radio" name="skirtend" value="rightEnd" v-model="end" /><label for="skirtEndRight" style="margin-left: 3px">Right</label></td>
        </tr>
        <tr>
            <td><label>Bay count:</label></td><td><input type="number" v-model="bays" style="width:45px" /></td>
        </tr>
        <tr>
            <td><label for="addPartition">Add Partition</label></td><td><input type="checkbox" id="addPartition" v-model="addPartition" /></td>
        </tr>
        </table>
        <div style="display:flex; justify-content: space-evenly ;padding:4px">
            
            <button class="btn cancel" @click="cancel">Cancel</button>
            <button class="btn btnGreen" @click="apply">Apply</button>
        </div>

    </div>
</template>

<script>
import { CORE } from '../js/_spec';
import TargetStructureName from './targetStructureName.vue';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

export default {
    props:{
        tool: Object,
        },
    computed:{
        ...mapState('designModule',[
            'toolData',
        ]),
        ...mapGetters('designModule', [            
            'isGableTieInStructure'
        ]),
    },
    data(){
        return {
            end: CORE.sides.leftEnd,
            bays: 0,
            addPartition: true,
        }
    },
    mounted(){
        console.log('mounted tool')
        this.init();
    },
    methods:{
        ...mapActions('designModule',[
            'applyToolData',        
        ]),
    ...mapMutations('designModule',[
        'showToolModal',      
        ]),
        init(){
            this.end = this.toolData.end;
            this.bays = this.toolData.bays;
            this.addPartition = this.toolData.addPartition;
        },
        apply(){        
            this.toolData = {
                end: this.end,
                bays: this.bays,
                addPartition: this.addPartition
            }; 

            this.applyToolData()
            this.showToolModal(false)
        },
        cancel(){
            this.showToolModal(false)
        }
    },
    components:{
        TargetStructureName
    }
   
}
</script>

<style scoped>

td:nth-child(1) {
    text-align:right;
}
.main {    
    background-color: #222;
    border-color: #080808;
    width: 100%;
    left:0px;
    top:0px;
    color:white;
    display: flex;
}

.inset-tool-container {
    padding-bottom: 5px;
    border: 0px solid black;
}

.meta{
    display: inline-block;
    margin-left:10px;
    flex-grow: 2;
}

.name {
    font-size: 1em;
}

.specs {
    font-size: .7em;
}

.logo{
    width: 150px;
    position: relative;
}

.logo .img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.menuItem {
    margin:2px 10px;
    position:relative;
}

.menuItem.boxed{
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 3px;
    text-align: center;
}

.modeLabel{
    display: block;
    text-align: center;
}


.menuItem.title{
    overflow: hidden
}

.title .primary{
    font-weight: bold;
    font-style: italic;
    max-width: 95vw;
    font-size: 25px;
    display: block;
}

.title .beta{
    font-weight: bold;
    font-style: italic;
    max-width: 95vw;
    color:coral;
    font-size: 15px;
}

.title .beta{
    font-weight: bold;
    max-width: 95vw;
    font-size: 13px;
}

.btn {
    display:inline-block;
    width:auto;
    height: fit-content;
}

.quote{
  display:block;
}

input[type="number"]{
    width: 80%;
}

td {
    padding:2px 5px;
    margin:2px 0px;
    max-width: 160px;
}

label{
    margin:10px
}

input{
    padding-left:5px;
}

</style>