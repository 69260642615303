import * as THREE from 'three'
import _3dBase from './Base.js'
import {CORE} from '../_spec.js'
import MemHelper from '../helpers/memHelper.js';
import FoldedGeometryHelper from '../helpers/foldedGeometryHelper.js';
import layerHelper from '../helpers/layerHelper.js';

export default class DripTrim extends _3dBase {
//reference material:
    // https://images.squarespace-cdn.com/content/v1/569685c1df40f31bc5cd0840/1555352565020-VY5U8KGF83G8ZV7ZJ5A0/ke17ZwdGBToddI8pDm48kPqGjK6nmxeqXITg7462wvZ7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UctFYf7GJr-IHZqF3omXWQsEC_85AEqt26uVcUyCdH-FLlCCt6gcyTKcVPX2ZcI6AQ/shed.jpg?format=750w
    // https://images.squarespace-cdn.com/content/v1/569685c1df40f31bc5cd0840/1555352565493-RRCAAA4GR3735DMSVG9D/ke17ZwdGBToddI8pDm48kBw7QIHGytCKM2D_mg1UDpd7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z4YTzHvnKhyp6Da-NYroOW3ZGjoBKy3azqku80C789l0vIHRucUU7a1Vci15HXS8HIahVHGsWTVpFeh5UgVcHiBtlN7DGHTwxliKxV3q1vpYw/roll+doors.jpg?format=1000w
    // https://images.squarespace-cdn.com/content/v1/569685c1df40f31bc5cd0840/1601301987738-97YOBQTI6WCN7D6A478M/ke17ZwdGBToddI8pDm48kF9aEDQaTpZHfWEO2zppK7Z7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UX7HUUwySjcPdRBGehEKrDf5zebfiuf9u6oCHzr2lsfYZD7bBzAwq_2wCJyqgJebgg/overhang.jpg?format=1500w
    // https://www.jagmetalsllc.com/wp-content/uploads/2018/04/outside-corner-trim_catalog.png
    constructor(parentGroup, length, material){
        
        super(parentGroup)
        let folds = [            
            {deg:90}, // aim down
            {mdist:-2}, // back up two inches
            {dist:2}, // top leg            
            {deg:-45}, 
            {dist:1}, // drip angle            
        ];

        let fgh = new FoldedGeometryHelper(folds, material, false, false, false, length, undefined, undefined, Math.PI/4, Math.PI/4);
        layerHelper.setGroup(fgh.group, CORE.layers.walls);
        this.group.add(fgh.group);
        this.group.name = 'dripTrim'
    }
    
    processGeometries(material){
        this.geometries.forEach((g)=>{
            let mesh = new THREE.Mesh(g, material);
            mesh.name = 'dripTrim'
            mesh.layers.set(CORE.layers.walls)
            this.group.add(mesh)
        });        
    }

}