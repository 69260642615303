// this is the place to define all 
// constants
// constraints
// rarely-changed variables
 
export let CORE = {
    entities:{
        enterprise: 0,
        supplier: 1,
        contractor: 2
    },
    modes:{
        system: 0,
        supplier: 1,
        contractor: 2,      
    },
    projectState:{
        canceled: -1,
        new: 0,
        designed: 1,
        engreviewRequested: 2,
        engreviewPendingInfo: 3,
        engreviewDateSet: 4,
        engreviewInProgress: 5,
        pmreview: 6,
        custreview: 7,
        contracted: 8,
        complete: 9
    },
    pricingLevels:{
        1: "Wholesale",
        2: "Pro",
        3: "Contractor",
        4: "Retail",
        5: "Standard"
    },
}