
export default {



    deepCopy(value, seen = new WeakMap()) {
        // Handle null and non-object types
        if (value === null || typeof value !== 'object') {
            return value;
        }

        // Handle circular references
        if (seen.has(value)) {
            throw new Error('Circular reference detected in object structure');
        }
        seen.set(value, true);

        // Handle Date objects
        if (value instanceof Date) {
            return new Date(value.getTime());
        }

        // Handle RegExp objects
        if (value instanceof RegExp) {
            return new RegExp(value.source, value.flags);
        }

        // Handle Map objects
        if (value instanceof Map) {
            const mapCopy = new Map();
            for (const [key, val] of value) {
                mapCopy.set(this.deepCopy(key, seen), this.deepCopy(val, seen));
            }
            return mapCopy;
        }

        // Handle Set objects
        if (value instanceof Set) {
            const setCopy = new Set();
            for (const val of value) {
                setCopy.add(this.deepCopy(val, seen));
            }
            return setCopy;
        }

        // Handle Arrays
        if (Array.isArray(value)) {
            return value.map(item => this.deepCopy(item, seen));
        }

        // Handle plain Objects
        const result = Object.create(Object.getPrototypeOf(value));
        for (const key in value) {
            if (Object.prototype.hasOwnProperty.call(value, key)) {
                result[key] = this.deepCopy(value[key], seen);
            }
        }
        return result;
    }

}