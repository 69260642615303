<template>
  <div>
    
    <div v-bind:style='{"background": cssColor(getConstraint.color)}'>
      
      <div class='title'><font-awesome-icon icon="fa-duotone fa-wrench" /> <b>Edit Link</b></div>
      <div @click='done' class='close'><font-awesome-icon icon="fa-duotone fa-circle-xmark" /></div>
    </div>
    

    <div>        
        <constrainedStructureInput ref='child' :sideLabels="childSideLabels" :childIsSecondaryStruct="isSecondaryStructure" v-bind:child="true" v-bind:part="getConstraint.child" v-bind:lead="'Mate'" @change="changeChild" />
        <constrainedStructureInput ref='parent' :sideLabels="parentSideLabels" v-bind:child="false" v-bind:part="getConstraint.parent" v-bind:lead="'with'" @change="changeParent" />
    </div>

    <br>
    <div style="margin:5px"> 
      The party wall will be built by
      <select v-model="partyWallOwnerID" :disabled="isPorch" @change="updatePartyWallOwnerID"> 
        <option v-for="option in getOptions" :disabled="option.disable" :value="option.value" :key="option.value">{{ option.text }}</option>
      </select> 
    </div>
    <br>

    <button @click='done' class='btn' >Done</button>
    <!-- <button @click='cancel' class='btn' v-if="allowCancel" >Cancel</button> -->
  </div>
  
</template>


<script>

import ColorHelper from '../js/helpers/colorHelper';
import { CORE } from '../js/_spec';
import constrainedStructureInput from './constrainedStructureInput.vue';
import StructureHelper from '../js/helpers/StructureHelper';
import BlueprintHelper from '../js/helpers/blueprintHelper';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  props: {
    constraint: Object,
  },
  computed:{
    ...mapGetters('designModule', [
      'editingConstraintParentComp',
      'editingConstraintChildComp',
      'constraintGuiData',
      'editingConstraint',
      'structureNameMap'
    ]),
    isSecondaryStructure(){
      let childType = this.editingConstraintChildComp.design.type
      return (childType === CORE.components.porch || childType === CORE.components.leanTo);
    },
    isPorch(){
      return (this.editingConstraintChildComp.design.type === CORE.components.porch)
    },
    disableLeanToPWO(){
      let parent = this.editingConstraintParentComp.design;
      let child = this.editingConstraintChildComp.design;
      let c = this.getConstraint;
      let pMatingWallOpenHeight = StructureHelper.getWallDesFromSide(parent, c.parent.matingSide).openHeight;
      let cHighSideHeight = BlueprintHelper.getBackSideWallHeightInches(child.getRoof(), child.getFrame())
      if(child.type === CORE.components.leanTo && pMatingWallOpenHeight < cHighSideHeight){
        return true
      }
      return false
    },
    constraintStructureName(){
      console.log('search name')
      let constraint = this.getConstraint;
      let name = '';
      
      this.constraintGuiData.forEach((c)=>{
        console.log('result', c);
        if(c.id === constraint.id)
          name = c.child.structureName;
      })
      
      return name;
    },
    
    getConstraint(){
            let c = this.editingConstraint;
            console.log('getConstraint ',c)
            return c
    }, 
    getOptions(){
      let c = this.getConstraint;
      let child = this.editingConstraintChildComp.design;

      // Using the value from disableLeanToPWO() would help feature parity between the designer and the QT.
      // Currently (7/4/2024), the QT sets wall OH to leanTo high side if the parent wall OH < LT high side.
      let disableChild = (child.type === CORE.components.leanTo) ? this.disableLeanToPWO : false;
        return [
          {value: CORE.partyWallOwner.neither, text: 'Neither (Open Wall)', disable: false},
          {value: c.child.structureID, text: `${this.getStructureName(c.child.structureID)}`, disable: false},
          {value: c.parent.structureID, text: `${this.getStructureName(c.parent.structureID)}`, disable: false},
        ]
    },
    childSideLabels(){
      let childRoofType = this.editingConstraintChildComp.roofDesign.roofType;
      if(childRoofType === CORE.roof.types.slope)
        return {fsw:'Low Side', bsw:'High Side'}
      else
        return {fsw:'Front Side', bsw:'Back Side'}
    },
    parentSideLabels(){
      let parentRoofType = this.editingConstraintParentComp.roofDesign.roofType;
      if(parentRoofType === CORE.roof.types.slope)
        return {fsw:'Low Side', bsw:'High Side'}
      else
        return {fsw:'Front Side', bsw:'Back Side'}
    }


  },
  data: function(){
      return {   
        partyWallOwnerID: CORE.partyWallOwner.neither,          
      };
  },
  created: function(){
  },
  mounted: function(){
    this.partyWallOwnerID = this.getConstraint.partyWallOwnerID
  },
  methods: {    
...mapActions('designModule',[
        'updateConstraint',        
      ]),
...mapMutations('designModule',[
      'showConstraintEditor',      
      '',
    ]),
    cssColor(hex){
          let c =ColorHelper.hexToBang(hex) 
          return c;
      },
    done: function(){
      this.showConstraintEditor(false);
    },
    cancel: function(){
      this.showConstraintEditor(false);
    },
    changeParent: function(){
       let data = {
        id: this.constraint.id,
        parent: this.$refs.parent.getPart(),
        child: this.$refs.child.getPart(),
        partyWallOwnerID: this.partyWallOwnerID,
      }
      this.change(data);
    },
    changeChild: function(){
       let data = {
        id: this.constraint.id,
        parent: this.$refs.parent.getPart(),
        child: this.$refs.child.getPart(),
        partyWallOwnerID: this.partyWallOwnerID,
      }
      this.change(data);
    },
    updatePartyWallOwnerID: function(){
      let data = {
        id: this.constraint.id,
        parent: this.$refs.parent.getPart(),
        child: this.$refs.child.getPart(),
        partyWallOwnerID: this.partyWallOwnerID,
      }
      this.change(data);
    },
    change: function(data){
      this.updateConstraint(data);
    },
    getStructureName(id){
      if(id==0)
        return '';
      let map =  this.structureNameMap;
      console.log(map)
      let obj = map.filter((e)=> e.id == id);
      console.log(obj)
      if(!obj)
        return `structure ${id}`;
      if(obj.length!=1)
        throw `exactly one structure was not found with id ${id}` 
      else{
        console.log(`exactly one structure was found with id ${id}` )
      }
      return obj[0].name;
    },    
    hasSlopedRoof(id){
      
    }
  },
  components:{    
    constrainedStructureInput
  }
}
</script> 

<style scoped>

tr td {
  white-space: nowrap;
}

td{
  padding-top: 1px;
  padding-bottom: 1px;
}

.name{
  text-align: right;
}
.value{
  text-align: left;
}

.option{
  display:block;
  width: max-content;
  margin: 5px;
}

input{
  width: 40px
}

input[type~="radio"]{
  width: 10px
}

input[type~="color"]{
  width: 60px
}


input[type~="text"]{
  width: 100%;
}

div.header{
  overflow: auto;  
  text-align: left;
  margin-top: 10px;
  font-weight: bold;
  border-top: solid 1px black;
}

div.contextWrapper{
  display: inline-block;    
  text-align: center;
  z-index: 10001;
  background: lightgray;
  border-radius: 4px;
  border: 1px solid black;
  padding:4px;    
  /* opacity: .85; */
  position:relative;
  top: 0px;
  font-size: 12px;
}

.ib{
  display:inline-block
}

.b{
  display:block;
}

.title{
  display:inline;
}
.close{  
  float:right;
  display:inline;
}
.close svg:hover{
  color:red; 
}
button {
  display: inline;
  margin: 3px;
}

#tblOpts td * {
  margin-right:5px;
}

#tblOpts td input {
  padding-left:2px;
}

button[name='Done'] {
  background:#00a700;
}

button[name='Delete'] {
  background:#df0404;
}

button[name='Cancel'] {
  background:#df0404;
}

.leftBorder{
    border-left-width: 10px;
    border-left-style: solid;
}
</style>
